import * as yup from 'yup';

import { PasswordPolicy } from '../../../../../../types/domain/password-policy/passwordPolicy';

export type LocalLoginProviderFormValues = PasswordPolicy & { requireTwoFactorAuth: boolean };

export const LocalLoginUpdateSchema = yup.object().shape({
  minLength: yup.number().max(65).min(8).required().label('Minimum Length'),
  uniqueChars: yup.number().max(32).min(0).required().label('Required number of unique characters'),
  requireNonAlphanumeric: yup.bool().required(),
  requireLowercase: yup.bool().required(),
  requireUppercase: yup.bool().required(),
  requireDigit: yup.bool().required(),
  requireUniquePasswordOnReset: yup.bool().required(),
  passwordExpiresInDays: yup.number().required().label('Expiration'),
  requirePasswordExpiration: yup.bool().required(),
  passwordHistoryLength: yup.number().max(15).min(0).required().label('Unique Password History'),
  requireTwoFactorAuth: yup.bool().required(),
});
