import { urlUtil } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import isEqual from 'lodash/isEqual';
import React, { Fragment } from 'react';

import { Account } from '../../../../../../types/domain/account/account';
import { AzureAdConfigurationUpdate } from '../../../../../../types/domain/identity-provider/configurations/azureAd';
import { OpenIdConnectConfigurationUpdate } from '../../../../../../types/domain/identity-provider/configurations/openIdConnect';
import { Saml2PConfigurationUpdate } from '../../../../../../types/domain/identity-provider/configurations/saml2p';
import { IdentityProvider } from '../../../../../../types/domain/identity-provider/identityProvider';
import { ConfigurationConfirmDialog } from './ConfigurationConfirmDialog';
import { activeIdentityProviderCount } from './OverviewPanel.model';

type Props<TConfigUpdateType> = {
  renderPanel: (params: {
    onSubmit: (payload: TConfigUpdateType, saveAndTest: boolean) => void;
  }) => React.ReactNode;
  provider: IdentityProvider | null;
  account: Account;
  onUpdateConfig: (params: { configuration: TConfigUpdateType; saveAndTest: boolean }) => void;
};

type State = {
  showActivateSsoModal: boolean;
  showUpdatingSsoModal: boolean;
  showActivateLocalLoginModal: boolean;
  modalConfirm: Function;
};
/**
 * OverviewPanel renders the provided identity provider panel.
 * It wraps around the onUpdateConfig prop with handleOnSubmit to determine if the updated configuration
 * should display a confirmation modal to the user based on the status of the current provider and the updated configuration payload
 *
 * OverviewPanel has a generic type for the identity providers configuration update, this allows the onUpdateConfig
 * and handleSubmit methods to use the correct payload type
 */
export class OverviewPanel<
  TConfigUpdateType extends
    | AzureAdConfigurationUpdate
    | Saml2PConfigurationUpdate
    | OpenIdConnectConfigurationUpdate
> extends React.Component<Props<TConfigUpdateType>, State> {
  state = {
    showActivateSsoModal: false,
    showUpdatingSsoModal: false,
    showActivateLocalLoginModal: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    modalConfirm: () => {},
  };

  componentDidMount() {
    const { provider } = this.props;
    const queryParams = urlUtil.queryParse<{ verified?: 'true' | 'false' }>(window.location.search);
    const verifiedParam = queryParams.verified;
    // @todo add error code mapping
    // const errorCode: undefined | string = queryParams
    //   .errorCode;
    /**
     * When the user clicks the 'Test' button as an account administrator they will be redirected to the verification url and back here
     * the backend will append a query param verified=true/false and errorCode if verified=false and display a toast accordingly
     */
    if (provider) {
      if (provider.verified && verifiedParam === 'true') {
        SnackbarManager.success(`Successfully verified ${provider.name}`);
      } else if (!provider.verified && verifiedParam === 'false') {
        SnackbarManager.error({
          message:
            'We could not verify your identity provider. Please check your configuration and try again.',
          options: {
            persist: true,
          },
        });
      }
    }
  }

  hideModal = () =>
    this.setState({
      showActivateSsoModal: false,
      showUpdatingSsoModal: false,
      showActivateLocalLoginModal: false,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      modalConfirm: () => {},
    });

  showSSOConfirmation = submit =>
    this.setState({
      showActivateSsoModal: true,
      modalConfirm: submit,
    });

  showSSOInactiveWarning = submit =>
    this.setState({
      showUpdatingSsoModal: true,
      modalConfirm: submit,
    });

  showLocalLoginConfirmation = submit =>
    this.setState({
      showActivateLocalLoginModal: true,
      modalConfirm: submit,
    });

  /**
   * handleOnSubmit checks if the current identity provider status
   * is about to change and if so display a warning modal to the user
   * before proceeding with the identity provider configuration changes
   */
  handleOnSubmit = (payload: TConfigUpdateType, saveAndTest: boolean) => {
    const { account, provider, onUpdateConfig } = this.props;
    const currentActiveIdentityProviders = activeIdentityProviderCount(account);
    const isLocalLoginEnabled = account && account.localLoginEnabled;
    const isActivatingNewIdentityProvider = payload.enabled && provider && provider.verified;
    // we check if a provider is being updated and specifically check if the configuration or enabled fields were modified.
    // note: if the name field changes it will not deactivate the provider
    const updatingActiveIdentityProvider =
      currentActiveIdentityProviders === 1 &&
      provider &&
      provider.enabled &&
      provider.verified &&
      (!isEqual(provider.configuration, payload.configuration) ||
        provider.enabled !== payload.enabled);
    const willDisableLocalLogin = account && isLocalLoginEnabled && isActivatingNewIdentityProvider;
    const willEnableLocalLogin =
      currentActiveIdentityProviders === 1 &&
      !isLocalLoginEnabled &&
      !isActivatingNewIdentityProvider;

    const submit = () =>
      onUpdateConfig({
        configuration: payload,
        saveAndTest,
      });
    /** New identity provider is configured and activated
        local login is enabled and we are activating an identity provider
        which will disable local login */
    if (willDisableLocalLogin) {
      this.showSSOConfirmation(submit);

      return;
      // local login is disabled and we are deactivating the only available identity provider
      // which will activate local login again
    } else if (willEnableLocalLogin) {
      this.showLocalLoginConfirmation(submit);
      return;

      // the provider is active and being edited will cause it to be inactive
      // which will activate local login if this is the only active sso
    } else if (updatingActiveIdentityProvider) {
      this.showSSOInactiveWarning(submit);
      return;
    }

    submit();
  };

  render() {
    const {
      showActivateSsoModal,
      showUpdatingSsoModal,
      showActivateLocalLoginModal,
      modalConfirm,
    } = this.state;
    const { renderPanel } = this.props;

    return (
      <Fragment>
        {/* Renders the specific identity provider panel, provides it with props and onSubmit that handles intercepting
          the submission and determining if a configuration confirm modal should be displayed or not.
        */}
        {renderPanel({ onSubmit: this.handleOnSubmit })}
        <ConfigurationConfirmDialog
          showActivateSsoDialog={showActivateSsoModal}
          showUpdatingSsoDialog={showUpdatingSsoModal}
          showActivateLocalLoginDialog={showActivateLocalLoginModal}
          onConfirm={modalConfirm}
          onHide={this.hideModal}
        />
      </Fragment>
    );
  }
}

export default OverviewPanel;
