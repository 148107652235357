export enum FirmMatchSource {
  FACT_SET = 'FACT_SET',
  MORNING_STAR = 'MORNING_STAR',
  CMG = 'CMG',
  CUSTOMER_CREATED = 'CUSTOMER_CREATED',
}

export const firmMatchSourceLabels = {
  [FirmMatchSource.FACT_SET]: 'FactSet',
  [FirmMatchSource.MORNING_STAR]: 'Morning Star',
  [FirmMatchSource.CMG]: 'CMG',
  [FirmMatchSource.CUSTOMER_CREATED]: 'Customer Created',
};
