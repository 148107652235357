import { PageLayout } from '@cmg/design-system';
import React from 'react';

import { DescriptionListSkeleton } from '../../../design-system/body-sections/description-list/DescriptionListSkeleton';
import { PageGridSection } from '../../../design-system/body-sections/PageGridSection';
import { IdentityPageContent } from '../../../design-system/IdentityPageContent';
import { HighlightsSkeleton } from '../../../design-system/loading/HighlightsSkeleton';

export const CRMManagementRouteSkeleton: React.FC = () => {
  return (
    <PageLayout
      header={
        <HighlightsSkeleton
          withActions={false}
          withItems={false}
          aria-label="Loading CRM Management Highlights"
        />
      }
    >
      <IdentityPageContent
        gridContent={
          <PageGridSection
            size="large"
            title=""
            content={
              <DescriptionListSkeleton
                numOfListItems={4}
                aria-label="Loading CRM Files Upload Form"
              />
            }
          />
        }
      />
    </PageLayout>
  );
};
