import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import { useDocumentTitle } from '../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../../common/util/routeFactory';
import { selectAccount, selectAccountType } from '../../../account-detail/shared/ducks';
import {
  createUser,
  resetState,
  selectError,
  selectSubmitting,
} from '../../../account-detail/user-create/ducks';
import { UserCreateDialog } from './UserCreateDialog';

const mapStateToProps = state => ({
  submitting: selectSubmitting(state),
  error: selectError(state),
  account: selectAccount(state),
  accountType: selectAccountType(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createUser,
      resetState,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RouteProps = RouteComponentProps;
export type Props = RouteProps & StateProps & DispatchProps;

export const UserCreateRouteComponent: React.FC<Props> = ({
  error,
  submitting,
  actions,
  history,
  account,
  accountType,
}) => {
  useDocumentTitle(routeFactory.accountUserNew.getDocumentTitle({ accountName: account?.name }));

  useEffect(() => {
    return () => {
      actions.resetState();
    };
  }, [actions]);

  if (!account) {
    return null;
  }

  const onCancel = () =>
    history.push(routeFactory.accountUsers.getUrlPath({ accountSubdomain: account.subdomain }));

  return (
    <UserCreateDialog
      accountType={accountType}
      error={error}
      isSubmitting={submitting}
      onSubmit={values =>
        actions.createUser({
          user: {
            email: values.email,
            employeeKey: values.employeeKey?.length === 0 ? null : values.employeeKey,
            firstName: values.firstName,
            lastName: values.lastName,
            jobFunction: values.jobFunction,
            roles: values.roles,
          },
          sendInvite: values.sendInvite,
          history,
        })
      }
      onCancel={onCancel}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCreateRouteComponent);
