import { VFC } from 'react';

import { DescriptionList } from '../../../../../design-system/body-sections/description-list/DescriptionList';
import { DescriptionListItem } from '../../../../../design-system/body-sections/description-list/DescriptionListItem';
import { AccountType } from '../../../../../types/domain/account/constants';
import { Profile } from '../../../../../types/domain/self/profile';
import { getJobFunctionLabel } from '../../../../shared/model/job-function';

type Props = {
  profile: Readonly<Profile>;
  oidcUserAccountType: Readonly<AccountType | 'SYSTEM' | null>;
};

export const ProfileDetailsSectionReadonly: VFC<Props> = ({ profile, oidcUserAccountType }) => {
  return (
    <DescriptionList>
      <DescriptionListItem label="First Name" value={profile.firstName} />
      <DescriptionListItem label="Last Name" value={profile.lastName} />
      <DescriptionListItem label="Email" value={profile.email} />
      <DescriptionListItem
        label="Job Function"
        value={getJobFunctionLabel(profile.jobFunction, oidcUserAccountType)}
      />
    </DescriptionList>
  );
};
