import { Link, Typography } from '@cmg/design-system';
import { identitySelectors } from '@cmg/e2e-selectors';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { PageGridSection } from '../../../../design-system/body-sections/PageGridSection';
import { IdentityPageContent } from '../../../../design-system/IdentityPageContent';
import { selectAccount } from '../../../account-detail/shared/ducks';
import { LoginSettingsSection } from './grid-content/LoginSettingsSection';

const mapStateToProps = state => ({
  account: selectAccount(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
export type Props = StateProps & RouteComponentProps<{ accountSubdomain?: string }>;

export class AccountSecurityRouteComponent extends Component<Props> {
  render() {
    const { account, match } = this.props;
    const accountSubdomain = match.params?.accountSubdomain;

    if (!account) {
      return null;
    }

    return (
      <div data-test-id={identitySelectors.accountDetail.accountSecurityScreen.testId}>
        <IdentityPageContent
          gridContent={
            <Fragment>
              <LoginSettingsSection
                accountSubdomain={accountSubdomain}
                localLoginEnabled={account.localLoginEnabled}
                identityProviders={account.identityProviders}
              />
              <PageGridSection
                title="IP Whitelist"
                content={
                  <Typography>
                    Please contact{' '}
                    <Link href="mailto:support@cmgx.io" component="a">
                      support@cmgx.io
                    </Link>
                    .
                  </Typography>
                }
              />
            </Fragment>
          }
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(AccountSecurityRouteComponent);
