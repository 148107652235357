import { Checkbox, Icon, Popover } from '@cmg/common';
import React from 'react';

import { UUID } from '../../../../types/common';
import { firmNameTypeLabels, firmNameTypeOptions } from '../../../../types/domain/firm/constants';
import {
  recordStatusLabels,
  recordStatusOptions,
} from '../../../../types/domain/record-status/constants';
import { RecordStatus } from '../../../../types/domain/record-status/RecordStatus';
import { StyledSelectField, StyledTextInputField } from './inputs/RecordInputs.styles';

enum Field {
  VALUE = 'value',
  TYPE = 'type',
  IS_DISPLAY_NAME = 'isDisplayName',
  RECORD_STATUS = 'recordStatus',
  MARKETING_NAME = 'marketingName',
  IS_FINRA_NAME = 'IS_FINRA_NAME',
}

/**
 * The column configuration for the Firm Names Record Table
 */
export const getFirmNameColumns = ({
  isEditable,
  onSetDisplayNameClick,
  onSetMarketingNameClick,
  onSetFinraNameClick,
}: {
  isEditable: boolean;
  onSetDisplayNameClick?: (nameId: UUID) => void;
  onSetMarketingNameClick?: (nameId: UUID, isMarketingName: boolean) => void;
  onSetFinraNameClick?: (nameId: UUID, isFinraName: boolean) => void;
}) => [
  {
    field: Field.VALUE,
    headerName: 'Name',
    editCellRendererFramework: (row, disabled) => (
      <StyledTextInputField name="value" disabled={disabled} />
    ),
  },
  {
    field: Field.TYPE,
    headerName: 'Name Type',
    editCellRendererFramework: (row, disabled) => {
      return <StyledSelectField name="type" options={firmNameTypeOptions} disabled={disabled} />;
    },
    cellRendererFramework: row => firmNameTypeLabels[row.type],
  },
  {
    field: Field.IS_DISPLAY_NAME,
    headerName: 'Display Name',
    disableEditOnClick: true,
    cellRendererFramework: ({ id: nameId, isDisplayName, recordStatus }) =>
      isEditable && recordStatus !== RecordStatus.DELETED ? (
        <Checkbox
          value={isDisplayName}
          disabled={isDisplayName}
          onChange={() => {
            onSetDisplayNameClick && onSetDisplayNameClick(nameId);
          }}
        />
      ) : (
        isDisplayName && <Icon name="check" variant="solid" />
      ),
    editCellRendererFramework: ({ isDisplayName, recordStatus }) =>
      recordStatus !== RecordStatus.DELETED && (
        <Popover
          variant="TOOLTIP"
          trigger="click"
          content={
            <span>Save or cancel editing this name record to set it as the display name.</span>
          }
        >
          <span>
            <Checkbox disabled={true} value={isDisplayName} />
          </span>
        </Popover>
      ),
  },
  {
    field: Field.MARKETING_NAME,
    headerName: 'Marketing Name',
    disableEditOnClick: true,
    cellRendererFramework: ({ id: nameId, isMarketingName, recordStatus }) =>
      isEditable && recordStatus !== RecordStatus.DELETED ? (
        <Checkbox
          value={isMarketingName}
          onChange={value => {
            onSetMarketingNameClick && onSetMarketingNameClick(nameId, value);
          }}
        />
      ) : (
        isMarketingName && <Icon name="check" variant="solid" />
      ),
    editCellRendererFramework: ({ isMarketingName, recordStatus }) =>
      recordStatus !== RecordStatus.DELETED && (
        <Popover
          variant="TOOLTIP"
          trigger="click"
          content={
            <span>Save or cancel editing this name record to set it as the marketing name.</span>
          }
        >
          <span>
            <Checkbox disabled={true} value={isMarketingName} />
          </span>
        </Popover>
      ),
  },
  {
    field: Field.IS_FINRA_NAME,
    headerName: 'Finra Name',
    disableEditOnClick: true,
    cellRendererFramework: ({ id: nameId, isFinraName, recordStatus }) =>
      isEditable && recordStatus !== RecordStatus.DELETED ? (
        <Checkbox
          value={isFinraName}
          onChange={value => {
            onSetFinraNameClick && onSetFinraNameClick(nameId, value);
          }}
        />
      ) : (
        isFinraName && <Icon name="check" variant="solid" />
      ),
    editCellRendererFramework: ({ isFinraName, recordStatus }) =>
      recordStatus !== RecordStatus.DELETED && (
        <Popover
          variant="TOOLTIP"
          trigger="click"
          content={
            <span>Save or cancel editing this name record to set it as the finra name.</span>
          }
        >
          <span>
            <Checkbox disabled={true} value={isFinraName} />
          </span>
        </Popover>
      ),
  },
  {
    field: Field.RECORD_STATUS,
    headerName: 'Status',
    editCellRendererFramework: (row, disabled) => (
      <StyledSelectField name="recordStatus" options={recordStatusOptions} disabled={disabled} />
    ),
    cellRendererFramework: row => recordStatusLabels[row.recordStatus],
  },
];
