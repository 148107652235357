import { Navigation } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import routeFactory from '../../common/util/routeFactory';
import { selectAccount } from './shared/ducks';

const mapStateToProps = state => ({
  account: selectAccount(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

export const AccountBreadcrumbComponent: React.FC<Props> = ({ account }) =>
  !!account && account.subdomain !== 'system' ? (
    <Navigation.Breadcrumb>
      <Navigation.Link
        to={routeFactory.accountDetail.getUrlPath({
          accountSubdomain: account.subdomain,
        })}
      >
        {account.name}
      </Navigation.Link>
    </Navigation.Breadcrumb>
  ) : null;

const ConnectedAccountBreadcrumb = connect(mapStateToProps)(AccountBreadcrumbComponent);

/**
 * Displays an account name as a route link to account detail.
 * For use in the app Navbar when sys admin is looking at a particular account.
 */
const AccountBreadcrumb = () => (
  <Route
    exact={false}
    path={routeFactory.accountDetail.routePath}
    component={ConnectedAccountBreadcrumb}
  />
);

export default AccountBreadcrumb;
