import { useMemo } from 'react';

export type UseConfigurationConfirmDialogProps = Readonly<{
  readonly showActivateSsoDialog: boolean;
  readonly showUpdatingSsoDialog: boolean;
  readonly showActivateLocalLoginDialog: boolean;
}>;

export type UseConfigurationConfirmDialogResult = Readonly<{
  readonly isOpen: boolean;
  readonly title: string;
  readonly contentText: string;
}>;

export function useConfigurationConfirmDialog({
  showActivateLocalLoginDialog,
  showActivateSsoDialog,
  showUpdatingSsoDialog,
}: UseConfigurationConfirmDialogProps): UseConfigurationConfirmDialogResult {
  return useMemo((): UseConfigurationConfirmDialogResult => {
    if (showActivateSsoDialog) {
      return {
        isOpen: true,
        title: 'Activate SSO Identity Provider',
        contentText:
          'Enabling SSO Identity Provider will disable Local Login. Do you want to proceed?',
      };
    }

    if (showUpdatingSsoDialog) {
      return {
        isOpen: true,
        title: 'Updating SSO Identity Provider',
        contentText:
          'Updating SSO Identity Provider will deactivate the existing configuration and require you to test and activate the updated configuration. Do you want to proceed?',
      };
    }

    if (showActivateLocalLoginDialog) {
      return {
        isOpen: true,
        title: 'Activate Local Login',
        contentText:
          'Disabling SSO Identity Provider will enable Local Login. Do you want to proceed?',
      };
    }

    return {
      isOpen: false,
      title: '',
      contentText: '',
    };
  }, [showActivateLocalLoginDialog, showActivateSsoDialog, showUpdatingSsoDialog]);
}
