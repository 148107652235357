import { Box, BoxProps, Grid, Skeleton, Stack } from '@cmg/design-system';
import { VFC } from 'react';

export type HighlightsSkeletonProps = Readonly<
  {
    readonly withItems?: boolean;
    readonly withActions?: boolean;
  } & Pick<BoxProps, 'aria-label'>
>;

export const HighlightsSkeleton: VFC<HighlightsSkeletonProps> = ({
  'aria-label': ariaLabel,
  withActions = true,
  withItems = true,
}) => {
  return (
    <Box role="progressbar" aria-label={ariaLabel}>
      <Stack px={4} py={2} display={{ sm: 'none', md: 'flex' }}>
        <Grid container>
          <Skeleton component={Grid} item xs={4} height={28}></Skeleton>
          <Grid item xs={6}></Grid>
          {withItems && <Skeleton component={Grid} item xs={2} height={28}></Skeleton>}
        </Grid>
        {withActions && (
          <Grid container>
            <Skeleton component={Grid} item xs={5} height={28}></Skeleton>
            <Grid item xs={7}></Grid>
          </Grid>
        )}
      </Stack>
      <Stack px={4} py={2} display={{ sm: 'flex', md: 'none' }}>
        <Grid container>
          <Skeleton component={Grid} item xs={5} height={28}></Skeleton>
        </Grid>
        {withItems && (
          <Grid container>
            <Skeleton component={Grid} item xs={6} height={28}></Skeleton>
          </Grid>
        )}
        {withActions && (
          <Grid container>
            <Skeleton component={Grid} item xs={2} height={28}></Skeleton>
          </Grid>
        )}
      </Stack>
    </Box>
  );
};
