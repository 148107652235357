import React from 'react';

import rolodexApiClient from '../../../../common/api/rolodexApiClient';
import systemManagementApiClient from '../../../../common/api/systemManagementApiClient';
import { Account } from '../../../../types/domain/account/account';

export const useFetchAccount = () => {
  const [account, setAccount] = React.useState<Account | null>(null);
  const [loadingAccount, setLoadingAccount] = React.useState<boolean>(false);

  const fetchAccountByCmgEntityKey = React.useCallback(
    async (cmgEntityKey: string) => {
      setAccount(null);
      setLoadingAccount(true);
      const result = await systemManagementApiClient.admin.getAccounts({
        searchText: cmgEntityKey,
        page: 1,
      });
      if (result.ok) {
        const [account] = result.data.data;
        setAccount(account);
        setLoadingAccount(false);
        return account;
      } else {
        setLoadingAccount(false);
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw result.data.error;
      }
    },
    [setAccount, setLoadingAccount]
  );

  const fetchAccountBySubdomain = React.useCallback(
    async (subdomain: string) => {
      setAccount(null);
      setLoadingAccount(true);
      const response = await systemManagementApiClient.admin.getAccount(subdomain);
      if (response.ok) {
        const account = response.data;
        setAccount(account);
        setLoadingAccount(false);
        return account;
      } else {
        setLoadingAccount(false);
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response.data.error;
      }
    },
    [setAccount, setLoadingAccount]
  );

  const fetchFirmByCmgEntityKey = React.useCallback(
    async (cmgEntityKey: string) => {
      setAccount(null);
      setLoadingAccount(true);
      const response = await rolodexApiClient.getFirms({
        page: 1,
        key: cmgEntityKey,
      });
      if (response.ok) {
        const [firm] = response.data.data;
        setLoadingAccount(false);
        return firm ?? null;
      } else {
        setLoadingAccount(false);
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response.data.error;
      }
    },
    [setAccount, setLoadingAccount]
  );

  return React.useMemo(() => {
    return {
      account,
      loading: loadingAccount,
      fetchAccountByCmgEntityKey,
      fetchAccountBySubdomain,
      fetchFirmByCmgEntityKey,
    };
  }, [
    account,
    fetchAccountByCmgEntityKey,
    fetchAccountBySubdomain,
    fetchFirmByCmgEntityKey,
    loadingAccount,
  ]);
};
