import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as yup from 'yup';

import { getFirmNameColumns } from '../../../../../common/components/layout/firm-record-panel/firmNameColumns';
import FirmRecordPanel from '../../../../../common/components/layout/firm-record-panel/GenericFirmRecordPanel';
import { UUID } from '../../../../../types/common';
import { FirmName } from '../../../../../types/domain/firm/FirmName';
import {
  cancelEditRecord,
  createFirmName,
  editRecord,
  FirmDetailSection,
  selectEditingRecordIds,
  selectFirmDisplayNameError,
  selectFirmDisplayNameLoading,
  selectFirmMarketingNameError,
  selectFirmMarketingNameLoading,
  selectFirmNames,
  selectFirmNamesError,
  selectUpdatingRecordIds,
  setFirmDisplayName,
  setFirmFinraName,
  setFirmMarketingName,
  updateFirmName,
} from '../../ducks';

const mapStateToProps = state => ({
  names: selectFirmNames(state),
  error: selectFirmNamesError(state),
  editingRecordIds: selectEditingRecordIds(state),
  updatingRecordIds: selectUpdatingRecordIds(state),
  displayNameLoading: selectFirmDisplayNameLoading(state),
  displayNameError: selectFirmDisplayNameError(state),
  marketingNameLoading: selectFirmMarketingNameLoading(state),
  marketingNameError: selectFirmMarketingNameError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createFirmName,
      updateFirmName,
      editRecord,
      cancelEditRecord,
      setFirmDisplayName,
      setFirmMarketingName,
      setFirmFinraName,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  firmId: UUID;
};
type Props = OwnProps & StateProps & DispatchProps;

export const FirmNamesValidationSchema = yup.object().shape({
  value: yup.string().nullable().required('A name value is required'),
  type: yup.string().nullable().required('A name type is required'),
  isDisplayName: yup.bool().nullable(),
  isMarketingName: yup.bool().nullable(),
  recordStatus: yup.string().nullable().required('A status type is required'),
});

export const FirmNamesRecordPanelComponent: React.FC<Props> = ({
  firmId,
  error,
  editingRecordIds,
  updatingRecordIds,
  names,
  displayNameLoading,
  displayNameError,
  marketingNameLoading,
  marketingNameError,
  actions,
}) => {
  const { userPermissions } = useAuth();
  const canEdit = checkPermissions(userPermissions, [permissionsByEntity.Firm.FULL]);
  return (
    <FirmRecordPanel<FirmName>
      sectionId={FirmDetailSection.NAMES}
      title="Names"
      addRecordButtonLabel="Add Name"
      error={error || displayNameError || marketingNameError}
      editingRecordIds={editingRecordIds}
      updatingRecordIds={updatingRecordIds}
      onCreate={name => actions.createFirmName({ firmId, name })}
      onUpdate={name => actions.updateFirmName({ firmId, name })}
      onEdit={recordId => actions.editRecord({ recordId })}
      onCancelEdit={recordId => actions.cancelEditRecord({ recordId })}
      columns={getFirmNameColumns({
        isEditable: !displayNameLoading && !marketingNameLoading && canEdit,
        onSetDisplayNameClick: (nameId: UUID) => actions.setFirmDisplayName({ firmId, nameId }),
        onSetMarketingNameClick: (nameId: UUID, isMarketingName: boolean) =>
          actions.setFirmMarketingName({ firmId, nameId, isMarketingName }),
        onSetFinraNameClick: (nameId: UUID, isFinraName: boolean) =>
          actions.setFirmFinraName({ nameId, firmId, isFinraName }),
      })}
      rows={names}
      validationSchema={FirmNamesValidationSchema}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmNamesRecordPanelComponent);
