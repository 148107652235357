import { Banner, Panel } from '@cmg/common';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';

import FactSetFirmSelect from '../../../common/components/form/factset-select/FactSetFirmSelect';
import FactSetFirmHierarchyTreeGrid from '../../../common/components/lists/factset-firm-hierarchy-tree-grid/FactSetFirmHierarchyTreeGrid';
import routeFactory from '../../../common/util/routeFactory';
import FirmNewHeader from './components/firm-new-header/FirmNewHeader';
import {
  importFactSetFirm,
  previewFactSetFirmImport,
  resetState,
  selectCanImportSelectedFirm,
  selectFactSetFirmHierarchy,
  selectFactSetFirmHierarchyError,
  selectFactSetFirmHierarchyLoading,
  selectImportedFactSetFirm,
  selectImportedFactSetFirmError,
  selectImportedFactSetFirmLoading,
  selectSelectedFirm,
} from './ducks';

export const SStickyMenuListFooter = styled.div`
  display: block;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: 1px solid ${({ theme }) => theme.border.color.light};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius.medium};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius.medium};
  padding: 10px 12px;
  background-color: ${({ theme }) => theme.background.color.white};
  width: 100%;
  bottom: 0;
  text-align: center;
  position: sticky;
`;

const mapStateToProps = state => ({
  importedFirm: selectImportedFactSetFirm(state),
  canImportSelectedFirm: selectCanImportSelectedFirm(state),
  selectedFirm: selectSelectedFirm(state),
  isFirmImportInProgress: selectImportedFactSetFirmLoading(state),
  firmImportError: selectImportedFactSetFirmError(state),
  isImportingFactSetFirm: selectImportedFactSetFirmLoading(state),
  hierarchyLoading: selectFactSetFirmHierarchyLoading(state),
  hierarchy: selectFactSetFirmHierarchy(state),
  hierarchyError: selectFactSetFirmHierarchyError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetState,
      importFactSetFirm,
      previewFactSetFirmImport,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = RouteComponentProps & StateProps & DispatchProps;

export const RolodexFirmImportRouteComponent: React.FC<Props> = ({
  importedFirm,
  canImportSelectedFirm,
  selectedFirm,
  firmImportError,
  isFirmImportInProgress,
  isImportingFactSetFirm,
  hierarchy,
  hierarchyLoading,
  hierarchyError,
  actions,
}) => {
  useEffect(() => {
    return () => {
      actions.resetState();
    };
  }, [actions]);

  return (
    <React.Fragment>
      {importedFirm && (
        <Redirect to={routeFactory.rolodexFirmDetail.getUrlPath({ firmId: importedFirm.id })} />
      )}
      <FirmNewHeader
        isCreateButtonEnabled={canImportSelectedFirm}
        isLoading={hierarchyLoading || isFirmImportInProgress}
        error={firmImportError}
        onCreate={() =>
          selectedFirm && actions.importFactSetFirm({ factsetId: selectedFirm.factSetFirmId })
        }
      />
      <Panel>
        <Panel.Header title="Search FactSet for an Organization you would like to import into CMG" />
        {selectedFirm && selectedFirm.linkedToEntityMasterFirm && (
          <Banner variant="warning">
            {selectedFirm.properName} has already been linked to an Entity Master Firm (
            {selectedFirm.linkedCmgEntityId}).
          </Banner>
        )}
        <FactSetFirmSelect
          disabled={isImportingFactSetFirm}
          selectedFirmId={selectedFirm ? selectedFirm.factSetFirmId : null}
          renderMenuListFooter={() => (
            <SStickyMenuListFooter>
              Can't find what you're looking for?{' '}
              <Link to={routeFactory.rolodexFirmCreate.routePath}>Create New Organization</Link>
            </SStickyMenuListFooter>
          )}
          onChange={selectedFirmId => actions.previewFactSetFirmImport({ id: selectedFirmId })}
        />
      </Panel>
      {selectedFirm && (
        <Panel>
          <FactSetFirmHierarchyTreeGrid
            activeFirmId={selectedFirm.factSetFirmId}
            hierarchy={hierarchy}
            loading={hierarchyLoading}
            error={hierarchyError}
          />
        </Panel>
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RolodexFirmImportRouteComponent);
