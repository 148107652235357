import { apiTypes } from '@cmg/common';
import { Button, DialogContentText } from '@cmg/design-system';
import { FC, Fragment, useEffect, useState } from 'react';

import usePrevious from '../../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../../design-system/alert/ServerErrorAlert';
import { ConfirmationDialog } from '../../../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { FormDialogContent } from '../../../../../../design-system/dialog/FormDialogContent';
import { FormDialogContentItem } from '../../../../../../design-system/dialog/FormDialogContentItem';

export type Props = Readonly<{
  onReset: () => void;
  isLoading: Readonly<boolean>;
  error: Readonly<apiTypes.GenericServerError | null>;
  onResendInviteEmail: () => void;
}>;

export const ResendInviteAction: FC<Props> = ({
  onReset,
  isLoading,
  error,
  onResendInviteEmail,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const previousIsLoading = usePrevious(isLoading);
  useEffect(() => {
    if (previousIsLoading && !isLoading && !error) {
      setIsDialogOpen(false);
    }
  }, [error, isLoading, previousIsLoading]);

  const previousIsDialogOpen = usePrevious(isDialogOpen);
  useEffect(() => {
    if (previousIsDialogOpen && !isDialogOpen) {
      onReset();
    }
  }, [isDialogOpen, onReset, previousIsDialogOpen]);

  return (
    <Fragment>
      <Button
        variant="outlined"
        disabled={isLoading}
        color="primary"
        onClick={() => setIsDialogOpen(true)}
      >
        Resend Invite
      </Button>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        isLoading={isLoading}
        title="Resend Invite"
        cancelButtonLabel="Cancel"
        onCancel={() => setIsDialogOpen(false)}
        submitButtonLabel="Resend Invite"
        submitButtonColor="primary"
        onSubmit={onResendInviteEmail}
        content={
          <FormDialogContent
            error={error && <ServerErrorAlert title="Error" error={error} />}
            items={
              <FormDialogContentItem
                value={
                  <DialogContentText>
                    Invitation will be resent. Are you sure you want to proceed?
                  </DialogContentText>
                }
              />
            }
          />
        }
      />
    </Fragment>
  );
};
