import { changePasswordScreenSelector } from '@cmg/e2e-selectors';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { ChangePassword } from '../../../../../types/domain/self/ChangePassword';
import {
  changePassword,
  resetState,
  selectError,
  selectSubmitting,
} from '../../../change-password/ducks';
import { ChangePasswordDialog } from './ChangePasswordDialog';

const mapStateToProps = state => ({
  error: selectError(state),
  submitting: selectSubmitting(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePassword,
      resetState,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RouteProps = RouteComponentProps<{}>;
type Props = StateProps & DispatchProps & RouteProps;

export const ChangePasswordRoute: React.FC<Props> = ({ actions, history, submitting, error }) => {
  useEffect(() => {
    return () => {
      actions.resetState();
    };
  }, [actions]);

  const handleChangePassword = (changePassword: ChangePassword) => {
    actions.changePassword(
      {
        oldPassword: changePassword.oldPassword,
        newPassword: changePassword.newPassword,
        confirmPassword: changePassword.confirmPassword,
      },
      history
    );
  };

  const onCancel = () => {
    history.goBack();
  };

  return (
    <div data-test-id={changePasswordScreenSelector.testId}>
      <ChangePasswordDialog
        isSubmitting={submitting}
        error={error}
        onCancel={onCancel}
        onSubmit={handleChangePassword}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordRoute);
