import {
  AdminFirmBasicInformationUpdate,
  FirmCategoryType,
  IndustryType,
  InvestorFirmAdminRead as CrmInvestorFirm,
  LegalEntityType,
} from '@capital-markets-gateway/api-client-rolodex';
import { duckPartFactory, errorUtil, reduxUtil } from '@cmg/common';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import * as rolodexApiClient from '../../../common/api/rolodexApiClient';
import {
  CreateFirmNameResponse,
  CreateFirmRoleResponse,
  GetCrmInvestorFirmMatchesParams,
  GetCrmInvestorFirmMatchesResponse,
  GetCrmInvestorFirmParams,
  GetCrmInvestorFirmResponse,
  GetCustomFirmMatchesParams,
  GetCustomFirmMatchesResponse,
  GetFactSetFirmHierarchyResponse,
  GetFactSetFirmResponse,
  GetFirmHierarchyResponse,
  GetFirmMatchParams,
  GetFirmMatchResponse,
  GetFirmResponse,
  ImportFactSetFirmResponse,
  UpdateCrmInvestorNotesResponse,
  UpdateFirmDetailsResponse,
  UpdateInvestorFirmLinkResponse,
} from '../../../common/api/rolodexApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { UUID } from '../../../types/common';
import {
  FirmDataSource,
  FirmNameType,
  FirmRoleType,
  TraversalType,
} from '../../../types/domain/firm/constants';
import { FactSetFirmHierarchy } from '../../../types/domain/firm/FactSetFirmHierarchy';
import { Firm } from '../../../types/domain/firm/Firm';
import { FirmHierarchy } from '../../../types/domain/firm/FirmHierarchy';
import { TransformedFirm } from '../../../types/domain/firm/TransformedFirm';
import { FirmMatch } from '../../../types/domain/firm-match/FirmMatch';
import { FirmMatchSource } from '../../../types/domain/firm-match/FirmMatchSource';
import { RecordStatus } from '../../../types/domain/record-status/RecordStatus';
import { closeLinkOrganizationModal } from './components/link-organization-modal/LinkOrganizationModal';

export const fetchCrmInvestorFirmDuckParts = duckPartFactory.makeAPIDuckParts<
  GetCrmInvestorFirmParams,
  CrmInvestorFirm
>({
  prefix: 'ROLODEX/CRM_INVESTOR_FIRM_DETAILS',
});

/**
 * fetches FirmMatch[] from CrmInvestorFirm
 * makes two requests passing source=CMG & source=FactSet
 * concats responses into duck part data array
 */
export const fetchCrmInvestorFirmMatchesDuckParts = duckPartFactory.makeAPIDuckParts<
  GetCrmInvestorFirmMatchesParams,
  {
    data: FirmMatch[];
    totals: { [key: string]: number };
  }
>({
  prefix: 'ROLODEX/FIRM_MATCH_ENTITIES',
});

/**
 * fetches firm matches from custom search with given form values
 */
export const fetchCustomFirmMatchesDuckParts = duckPartFactory.makeAPIDuckParts<
  GetCustomFirmMatchesParams,
  {
    data: FirmMatch[];
    totals: { [key: string]: number };
  }
>({
  prefix: 'ROLODEX/CUSTOM_FIRM_MATCH_ENTITIES',
});

/**
 * fetches single FirmMatch given cmgEntityKey from crmInvestorFirm
 * displayed as 2nd row in table
 */
export const fetchLinkedFirmMatchDuckParts = duckPartFactory.makeAPIDuckParts<
  GetFirmMatchParams,
  FirmMatch
>({
  prefix: 'ROLODEX/LINKED_FIRM_MATCH',
});

export const fetchLinkTargetFirmDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    linkTargetFirmId: UUID | string;
    linkTargetFirmSource: FirmDataSource;
  },
  {
    linkTargetFirm: Firm | TransformedFirm;
  }
>({
  prefix: 'ROLODEX/FETCH_LINK_TARGET_FIRM',
});

export const updateCrmInvestorFirmLinkDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    crmIntegrationId: string;
    crmInvestorFirmId: string;
    addInvestorRoleToCMGFirm: boolean;
    addInvestorFirmNameToCMGFirmValue?: string | null;
    entityType: string | null;
    industryType: string | null;
    linkTargetFirmId?: UUID | string;
    linkTargetFirmSource?: FirmDataSource;
    firmType: string | null;
  },
  {}
>({
  prefix: 'ROLODEX/CRM_INVESTOR_FIRM_LINK',
});

export const updateCrmInvestorNotesDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    crmIntegrationId: string;
    crmInvestorFirmId: string;
    notes: string | null;
  },
  {}
>({
  prefix: 'ROLODEX/CRM_INVESTOR_NOTES',
});

export const fetchMatchFirmDetailDuckParts = duckPartFactory.makeAPIDuckParts<
  { id: string; type: FirmDataSource },
  {
    type: FirmDataSource;
    firm: Firm | TransformedFirm;
  }
>({
  prefix: 'ROLODEX/CRM_MATCH_FIRM_DETAILS',
});

export const fetchMatchFirmHierarchyDuckParts = duckPartFactory.makeAPIDuckParts<
  { id: string; type: FirmDataSource },
  {
    type: FirmDataSource;
    firmHierarchy: FirmHierarchy | FactSetFirmHierarchy;
  }
>({
  prefix: 'ROLODEX/CRM_MATCH_FIRM_HIERARCHY',
});

/**
 * ACTION TYPES
 */
enum ActionTypes {
  SET_LINK_TARGET_CMG_FIRM = 'ROLODEX/SET_LINK_TARGET_CMG_FIRM',
  SET_HAS_INVESTOR_FIRM_NAME_BEEN_ADDED_TO_CMG_FIRM = 'ROLODEX/SET_HAS_INVESTOR_FIRM_NAME_BEEN_ADDED_TO_CMG_FIRM',
  SET_HAS_INVESTOR_ROLE_BEEN_ADDED_TO_CMG_FIRM = 'ROLODEX/SET_HAS_INVESTOR_ROLE_BEEN_ADDED_TO_CMG_FIRM',
  RESET_LINK_ORGANIZATION_MODAL_STATE = 'ROLODEX/RESET_LINK_ORGANIZATION_MODAL_STATE',
  RESET_NOTES_MODAL_STATE = 'ROLODEX/RESET_NOTES_MODAL_STATE',
}

/**
 * ACTION CREATORS
 */

export const fetchCrmInvestorFirm = fetchCrmInvestorFirmDuckParts.actionCreators.request;
type fetchCrmInvestorFirmAction = ReturnType<typeof fetchCrmInvestorFirm>;

export const fetchCrmInvestorFirmMatches =
  fetchCrmInvestorFirmMatchesDuckParts.actionCreators.request;
type fetchCrmInvestorFirmMatchesAction = ReturnType<typeof fetchCrmInvestorFirmMatches>;

export const fetchCustomFirmMatches = fetchCustomFirmMatchesDuckParts.actionCreators.request;
type fetchCustomFirmMatchesAction = ReturnType<typeof fetchCustomFirmMatches>;
export const resetCustomFirmMatches = () => fetchCustomFirmMatchesDuckParts.actionCreators.reset();

export const fetchLinkedFirmMatch = fetchLinkedFirmMatchDuckParts.actionCreators.request;
type fetchLinkedFirmMatchAction = ReturnType<typeof fetchLinkedFirmMatch>;
export const resetLinkedFirmMatch = () => fetchLinkedFirmMatchDuckParts.actionCreators.reset();

export const updateCrmInvestorFirmLink = updateCrmInvestorFirmLinkDuckParts.actionCreators.request;
export type updateCrmInvestorFirmLinkAction = ReturnType<typeof updateCrmInvestorFirmLink>;

export const updateCrmInvestorNotes = updateCrmInvestorNotesDuckParts.actionCreators.request;
export type updateCrmInvestorNotesAction = ReturnType<typeof updateCrmInvestorNotes>;

export const setLinkTargetCMGFirm = (payload: { linkTargetCMGFirm: Firm | null }) => {
  return {
    type: ActionTypes.SET_LINK_TARGET_CMG_FIRM,
    payload,
  };
};

export const setHasInvestorFirmNameBeenAddedToCMGFirm = (payload: {
  hasInvestorFirmNameBeenAddedToCMGFirm: boolean;
}) => ({
  type: ActionTypes.SET_HAS_INVESTOR_FIRM_NAME_BEEN_ADDED_TO_CMG_FIRM,
  payload,
});

export const setHasInvestorRoleBeenAddedToCMGFirm = (payload: {
  hasInvestorRoleBeenAddedToCMGFirm: boolean;
}) => ({
  type: ActionTypes.SET_HAS_INVESTOR_ROLE_BEEN_ADDED_TO_CMG_FIRM,
  payload,
});

export const resetLinkOrganizationModalState = () => ({
  type: ActionTypes.RESET_LINK_ORGANIZATION_MODAL_STATE,
});
export const resetNotesModalState = () => ({
  type: ActionTypes.RESET_NOTES_MODAL_STATE,
});

export const fetchMatchFirmDetail = fetchMatchFirmDetailDuckParts.actionCreators.request;
type fetchMatchFirmDetailAction = ReturnType<typeof fetchMatchFirmDetail>;

export const fetchMatchFirmHierarchy = fetchMatchFirmHierarchyDuckParts.actionCreators.request;
type fetchMatchFirmHierarchyAction = ReturnType<typeof fetchMatchFirmHierarchy>;

export const fetchLinkTargetFirm = fetchLinkTargetFirmDuckParts.actionCreators.request;
type fetchLinkTargetFirmAction = ReturnType<typeof fetchLinkTargetFirm>;

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.SET_LINK_TARGET_CMG_FIRM]: ReturnType<typeof setLinkTargetCMGFirm>;
  [ActionTypes.SET_HAS_INVESTOR_FIRM_NAME_BEEN_ADDED_TO_CMG_FIRM]: ReturnType<
    typeof setHasInvestorFirmNameBeenAddedToCMGFirm
  >;
  [ActionTypes.SET_HAS_INVESTOR_ROLE_BEEN_ADDED_TO_CMG_FIRM]: ReturnType<
    typeof setHasInvestorRoleBeenAddedToCMGFirm
  >;
  [ActionTypes.RESET_LINK_ORGANIZATION_MODAL_STATE]: ReturnType<
    typeof resetLinkOrganizationModalState
  >;
  [ActionTypes.RESET_NOTES_MODAL_STATE]: ReturnType<typeof resetNotesModalState>;
};

/**
 * REDUCERS
 */

export const initialState = {
  crmInvestorFirm: fetchCrmInvestorFirmDuckParts.initialState,
  firmMatches: fetchCrmInvestorFirmMatchesDuckParts.initialState,
  customFirmMatches: fetchCustomFirmMatchesDuckParts.initialState,
  linkedFirmMatch: fetchLinkedFirmMatchDuckParts.initialState,
  linkTargetFirm: fetchLinkTargetFirmDuckParts.initialState,
  linkTargetCMGFirm: null,
  hasInvestorFirmNameBeenAddedToCMGFirm: false,
  hasInvestorRoleBeenAddedToCMGFirm: false,
  matchFirmDetail: fetchMatchFirmDetailDuckParts.initialState,
  matchFirmDetailHierarchy: fetchMatchFirmHierarchyDuckParts.initialState,
  updateCrmInvestorFirmLink: updateCrmInvestorFirmLinkDuckParts.initialState,
  updateCrmInvestorNotes: updateCrmInvestorNotesDuckParts.initialState,
};

export type ReducerState = {
  crmInvestorFirm: typeof initialState.crmInvestorFirm;
  firmMatches: typeof initialState.firmMatches;
  customFirmMatches: typeof initialState.customFirmMatches;
  linkedFirmMatch: typeof initialState.linkedFirmMatch;
  linkTargetFirm: typeof fetchLinkTargetFirmDuckParts.initialState;
  linkTargetCMGFirm: Firm | null;
  hasInvestorFirmNameBeenAddedToCMGFirm: boolean;
  hasInvestorRoleBeenAddedToCMGFirm: boolean;
  matchFirmDetail: typeof fetchMatchFirmDetailDuckParts.initialState;
  matchFirmDetailHierarchy: typeof fetchMatchFirmHierarchyDuckParts.initialState;
  updateCrmInvestorFirmLink: typeof updateCrmInvestorFirmLinkDuckParts.initialState;
  updateCrmInvestorNotes: typeof updateCrmInvestorNotesDuckParts.initialState;
};

export const linkTargetCMGFirmReducer = reduxUtil.createReducer<
  ReducerState['linkTargetCMGFirm'],
  Actions
>(initialState.linkTargetCMGFirm, {
  [ActionTypes.SET_LINK_TARGET_CMG_FIRM]: (
    state: ReducerState['linkTargetCMGFirm'],
    action: ReturnType<typeof setLinkTargetCMGFirm>
  ) => action.payload.linkTargetCMGFirm,
  [ActionTypes.RESET_LINK_ORGANIZATION_MODAL_STATE]: () => null,
});

export const hasInvestorFirmNameBeenAddedToCMGFirmReducer = reduxUtil.createReducer<
  ReducerState['hasInvestorFirmNameBeenAddedToCMGFirm'],
  Actions
>(initialState.hasInvestorFirmNameBeenAddedToCMGFirm, {
  [ActionTypes.SET_HAS_INVESTOR_FIRM_NAME_BEEN_ADDED_TO_CMG_FIRM]: (
    state: ReducerState['hasInvestorFirmNameBeenAddedToCMGFirm'],
    action: ReturnType<typeof setHasInvestorFirmNameBeenAddedToCMGFirm>
  ) => action.payload.hasInvestorFirmNameBeenAddedToCMGFirm,
  [ActionTypes.RESET_LINK_ORGANIZATION_MODAL_STATE]: () => false,
});

export const hasInvestorRoleBeenAddedToCMGFirmReducer = reduxUtil.createReducer<
  ReducerState['hasInvestorRoleBeenAddedToCMGFirm'],
  Actions
>(initialState.hasInvestorRoleBeenAddedToCMGFirm, {
  [ActionTypes.SET_HAS_INVESTOR_ROLE_BEEN_ADDED_TO_CMG_FIRM]: (
    state: ReducerState['hasInvestorRoleBeenAddedToCMGFirm'],
    action: ReturnType<typeof setHasInvestorRoleBeenAddedToCMGFirm>
  ) => action.payload.hasInvestorRoleBeenAddedToCMGFirm,
  [ActionTypes.RESET_LINK_ORGANIZATION_MODAL_STATE]: () => false,
});

const customLinkTargetFirmReducer = reduxUtil.createReducer<
  ReducerState['linkTargetFirm'],
  Actions
>(initialState.linkTargetFirm, {
  [ActionTypes.RESET_LINK_ORGANIZATION_MODAL_STATE]: () => initialState.linkTargetFirm,
});

const linkTargetFirmReducer = (
  linkedFirmMatchState: ReducerState['linkTargetFirm'] | undefined,
  action
): ReducerState['linkTargetFirm'] => {
  const linkedFirmReducerState = fetchLinkTargetFirmDuckParts.reducer(linkedFirmMatchState, action);
  return customLinkTargetFirmReducer(linkedFirmReducerState, action);
};

const customUpdateCrmInvestorFirmLinkReducer = reduxUtil.createReducer<
  ReducerState['updateCrmInvestorFirmLink'],
  Actions
>(initialState.updateCrmInvestorFirmLink, {
  [ActionTypes.RESET_LINK_ORGANIZATION_MODAL_STATE]: () => initialState.updateCrmInvestorFirmLink,
});

const updateCrmInvestorFirmLinkReducer = (
  crmInvestorFirmMatchState: ReducerState['updateCrmInvestorFirmLink'] | undefined,
  action
): ReducerState['updateCrmInvestorFirmLink'] => {
  const linkedFirmReducerState = updateCrmInvestorFirmLinkDuckParts.reducer(
    crmInvestorFirmMatchState,
    action
  );
  return customUpdateCrmInvestorFirmLinkReducer(linkedFirmReducerState, action);
};

const customUpdateCrmInvestorNotesReducer = reduxUtil.createReducer<
  ReducerState['updateCrmInvestorNotes'],
  Actions
>(initialState.updateCrmInvestorNotes, {
  [ActionTypes.RESET_NOTES_MODAL_STATE]: () => initialState.updateCrmInvestorNotes,
});

const updateCrmInvestorNotesReducer = (
  crmInvestorNotesState: ReducerState['updateCrmInvestorNotes'] | undefined,
  action
): ReducerState['updateCrmInvestorNotes'] => {
  const notesReducerState = updateCrmInvestorNotesDuckParts.reducer(crmInvestorNotesState, action);
  return customUpdateCrmInvestorNotesReducer(notesReducerState, action);
};

export default combineReducers<ReducerState>({
  crmInvestorFirm: fetchCrmInvestorFirmDuckParts.reducer,
  firmMatches: fetchCrmInvestorFirmMatchesDuckParts.reducer,
  customFirmMatches: fetchCustomFirmMatchesDuckParts.reducer,
  linkedFirmMatch: fetchLinkedFirmMatchDuckParts.reducer,
  linkTargetFirm: linkTargetFirmReducer,
  linkTargetCMGFirm: linkTargetCMGFirmReducer,
  hasInvestorFirmNameBeenAddedToCMGFirm: hasInvestorFirmNameBeenAddedToCMGFirmReducer,
  hasInvestorRoleBeenAddedToCMGFirm: hasInvestorRoleBeenAddedToCMGFirmReducer,
  matchFirmDetail: fetchMatchFirmDetailDuckParts.reducer,
  matchFirmDetailHierarchy: fetchMatchFirmHierarchyDuckParts.reducer,
  updateCrmInvestorFirmLink: updateCrmInvestorFirmLinkReducer,
  updateCrmInvestorNotes: updateCrmInvestorNotesReducer,
});

/**
 * SELECTORS
 */

const selectState = (state: RootState) => state.rolodexCrmMatchList;

const crmInvestorFirmSelectors = fetchCrmInvestorFirmDuckParts.makeSelectors(
  state => selectState(state).crmInvestorFirm
);
export const selectCrmInvestorFirmLoading = crmInvestorFirmSelectors.selectLoading;
export const selectCrmInvestorFirmError = crmInvestorFirmSelectors.selectError;
export const selectCrmInvestorFirm = (state: RootState) => {
  return crmInvestorFirmSelectors.selectData(state) || undefined;
};
export const selectCrmInvestorCmgEntityKey = createSelector(
  selectCrmInvestorFirm,
  state => state && state.cmgEntityKey
);

const firmMatchSelectors = fetchCrmInvestorFirmMatchesDuckParts.makeSelectors(
  state => selectState(state).firmMatches
);
export const selectFirmMatchesLoading = firmMatchSelectors.selectLoading;
export const selectFirmMatchesError = firmMatchSelectors.selectError;
export const selectFirmMatches = createSelector(
  firmMatchSelectors.selectData,
  selectCrmInvestorCmgEntityKey,
  (state, cmgEntityKey) => {
    const data = state ? state.data : [];

    // return firm matches without linkedFirmMatch (from crmInvestorFirm.cmgEntityKey)
    return cmgEntityKey ? data.filter(firmMatch => firmMatch.rolodexKey !== cmgEntityKey) : data;
  }
);
export const selectFirmMatchTotals = createSelector(firmMatchSelectors.selectData, state =>
  state ? state.totals : {}
);

const customFirmMatchSelectors = fetchCustomFirmMatchesDuckParts.makeSelectors(
  state => selectState(state).customFirmMatches
);
export const selectCustomFirmMatchesLoading = customFirmMatchSelectors.selectLoading;
export const selectCustomFirmMatchesError = customFirmMatchSelectors.selectError;
export const selectCustomFirmMatches = createSelector(
  customFirmMatchSelectors.selectData,
  selectCrmInvestorCmgEntityKey,
  (state, cmgEntityKey) => {
    const data = state ? state.data : [];

    // return custom firm matches without linkedFirmMatch (from crmInvestorFirm.cmgEntityKey)
    return cmgEntityKey ? data.filter(firmMatch => firmMatch.rolodexKey !== cmgEntityKey) : data;
  }
);
export const selectCustomFirmMatchTotals = createSelector(
  customFirmMatchSelectors.selectData,
  state => (state ? state.totals : {})
);

const linkedFirmMatchSelectors = fetchLinkedFirmMatchDuckParts.makeSelectors(
  state => selectState(state).linkedFirmMatch
);
export const selectLinkedFirmMatchLoading = linkedFirmMatchSelectors.selectLoading;
export const selectLinkedFirmMatch = (state: RootState) => {
  return linkedFirmMatchSelectors.selectData(state) || undefined;
};

const linkTargetFirmSelectors = fetchLinkTargetFirmDuckParts.makeSelectors(
  state => selectState(state).linkTargetFirm
);
export const selectLinkTargetFirmLoading = linkTargetFirmSelectors.selectLoading;
export const selectLinkTargetFirmError = linkTargetFirmSelectors.selectError;
export const selectLinkTargetFirm = createSelector(linkTargetFirmSelectors.selectData, data =>
  data ? data.linkTargetFirm : null
);

export const selectLinkTargetCMGFirm = createSelector(
  selectState,
  state => state.linkTargetCMGFirm
);

export const selectHasInvestorFirmNameBeenAddedToCMGFirm = createSelector(
  selectState,
  state => state.hasInvestorFirmNameBeenAddedToCMGFirm
);

export const selectHasInvestorRoleBeenAddedToCMGFirm = createSelector(
  selectState,
  state => state.hasInvestorRoleBeenAddedToCMGFirm
);

const matchFirmSelectors = fetchMatchFirmDetailDuckParts.makeSelectors(
  state => selectState(state).matchFirmDetail
);
export const selectMatchFirmDetailLoading = matchFirmSelectors.selectLoading;
export const selectMatchFirmDetailError = matchFirmSelectors.selectError;
export const selectMatchFirmDetail = createSelector(matchFirmSelectors.selectData, data =>
  data ? data.firm : null
);
export const selectMatchFirmType = createSelector(matchFirmSelectors.selectData, data =>
  data ? data.type : null
);

const hierarchySelectors = fetchMatchFirmHierarchyDuckParts.makeSelectors(
  state => selectState(state).matchFirmDetailHierarchy
);
export const selectMatchFirmHierarchyLoading = hierarchySelectors.selectLoading;
export const selectMatchFirmHierarchyError = hierarchySelectors.selectError;
export const selectMatchFirmHierarchy = createSelector(hierarchySelectors.selectData, data =>
  data ? data.firmHierarchy : null
);

const updateCrmInvestorFirmLinkSelectors = updateCrmInvestorFirmLinkDuckParts.makeSelectors(
  state => selectState(state).updateCrmInvestorFirmLink
);
export const selectUpdateCrmInvestorFirmLinkLoading =
  updateCrmInvestorFirmLinkSelectors.selectLoading;
export const selectUpdateCrmInvestorFirmLinkError = updateCrmInvestorFirmLinkSelectors.selectError;

const updateCrmInvestorNotesSelectors = updateCrmInvestorNotesDuckParts.makeSelectors(
  state => selectState(state).updateCrmInvestorNotes
);
export const selectUpdateCrmInvestorNotesLoading = updateCrmInvestorNotesSelectors.selectLoading;
export const selectUpdateCrmInvestorNotesError = updateCrmInvestorNotesSelectors.selectError;

/**
 * SAGAS
 */

/**
 * fetch crmInvestorFirm data
 * then fetch the linked firm if cmgEntityKey exists on crmInvestorFirm
 */
export function* fetchCrmInvestorFirmSaga({ payload }: fetchCrmInvestorFirmAction): SagaIterator {
  const response: GetCrmInvestorFirmResponse = yield call(
    rolodexApiClient.getCrmInvestorFirm,
    payload
  );

  if (response.ok) {
    // reset linked firm match
    yield put(resetLinkedFirmMatch());

    // reset any errors from custom firm match search
    yield put(resetCustomFirmMatches());

    // fetch linked firm match data if CrmInvestorFirm has linked cmgEntityKey
    const { cmgEntityKey } = response.data;
    if (cmgEntityKey) {
      yield put(fetchLinkedFirmMatch({ cmgEntityKey }));
    }

    yield put(fetchCrmInvestorFirmDuckParts.actionCreators.success(response.data));
  } else {
    yield put(fetchCrmInvestorFirmDuckParts.actionCreators.failure(response.data.error));
  }
}

/**
 * fetch linked firm match data
 * called from fetchCrmInvestorFirmSaga
 */
export function* fetchLinkedFirmMatchSaga({ payload }: fetchLinkedFirmMatchAction): SagaIterator {
  const response: GetFirmMatchResponse = yield call(rolodexApiClient.getFirmMatch, payload);

  if (response.ok) {
    yield put(fetchLinkedFirmMatchDuckParts.actionCreators.success(response.data));
  } else {
    yield put(fetchLinkedFirmMatchDuckParts.actionCreators.failure(response.data.error));
  }
}

/**
 * fetch proposed firm matches for CrmInvestorFirm without query params
 * fetch firm matches from source=CMG & source=FactSet
 * concats responses into duck part passed into this generator
 */
export function* fetchCrmInvestorFirmMatchesSaga({
  payload,
}: fetchCrmInvestorFirmMatchesAction): SagaIterator {
  // fetch same endpoint with different sources
  const [cmgMatchResponse, factSetMatchResponse]: GetCrmInvestorFirmMatchesResponse[] = yield all([
    call(rolodexApiClient.getCrmInvestorFirmMatches, {
      ...payload,
      perPage: 50,
      source: FirmMatchSource.CMG,
    }),
    call(rolodexApiClient.getCrmInvestorFirmMatches, {
      ...payload,
      perPage: 100,
      source: FirmMatchSource.FACT_SET,
    }),
  ]);

  if (cmgMatchResponse.ok && factSetMatchResponse.ok) {
    const cmgData = cmgMatchResponse.data || [];
    const factSetData = factSetMatchResponse.data || [];

    // concat response data
    const data = cmgData.concat(factSetData);

    // totals are calculated and stored alongside the data within duck parts
    const totals = {
      [FirmMatchSource.CMG]: cmgData.length,
      [FirmMatchSource.FACT_SET]: factSetData.length,
    };

    yield put(
      fetchCrmInvestorFirmMatchesDuckParts.actionCreators.success({
        data,
        totals,
      })
    );
  } else {
    // handle errors for either request respectively
    if (!cmgMatchResponse.ok) {
      yield put(
        fetchCrmInvestorFirmMatchesDuckParts.actionCreators.failure(cmgMatchResponse.data.error)
      );
    } else if (!factSetMatchResponse.ok) {
      yield put(
        fetchCrmInvestorFirmMatchesDuckParts.actionCreators.failure(factSetMatchResponse.data.error)
      );
    }
  }
}

/**
 * fetch firm matches with custom search query params like name, address, webDomain, identifiers, etc.
 * fetch firm matches from source=CMG & source=FactSet
 * concats responses into duck part passed into this generator
 */
export function* fetchCustomFirmMatchesSaga({
  payload,
}: fetchCustomFirmMatchesAction): SagaIterator {
  // request array passed to yield all()
  // always fetch source=CMG
  let requests = [
    call(rolodexApiClient.getFirmMatches, {
      ...payload,
      perPage: 50,
      source: FirmMatchSource.CMG,
    }),
  ];

  // searching by rolodexKey only needs source=CMG
  // don't include this source=FACT_SET request if rolodexKey exists
  // otherwise, add it to the paralell
  if (!payload.rolodexKey) {
    requests = [
      ...requests,
      call(rolodexApiClient.getFirmMatches, {
        ...payload,
        perPage: 100,
        source: FirmMatchSource.FACT_SET,
      }),
    ];
  }

  // execute request(s) extract response(s)
  const [cmgMatchResponse, factSetMatchResponse]: GetCustomFirmMatchesResponse[] = yield all(
    requests
  );

  // searching by rolodexKey only needs source=CMG response
  if (payload.rolodexKey) {
    // check source=CMG response
    if (cmgMatchResponse.ok) {
      // return data from this response only
      yield put(
        fetchCustomFirmMatchesDuckParts.actionCreators.success({
          data: cmgMatchResponse.data,
          totals: {
            [FirmMatchSource.CMG]: cmgMatchResponse.data.length,
            [FirmMatchSource.FACT_SET]: 0,
          },
        })
      );
    } else {
      // handle error when searching by rolodexKey
      // populates customFirmMatchSearchError in container
      yield put(
        fetchCustomFirmMatchesDuckParts.actionCreators.failure(cmgMatchResponse.data.error)
      );
    }
  } else {
    // if not searching by rolodexKey,
    // both source=CMG & source=FACT_SET are fetched
    if (cmgMatchResponse.ok && factSetMatchResponse.ok) {
      yield put(
        fetchCustomFirmMatchesDuckParts.actionCreators.success({
          // concat data for both requests + their total counts
          data: [...cmgMatchResponse.data, ...factSetMatchResponse.data],
          totals: {
            [FirmMatchSource.CMG]: cmgMatchResponse.data.length,
            [FirmMatchSource.FACT_SET]: factSetMatchResponse.data.length,
          },
        })
      );
    } else {
      // handle errors for either source CMG | FACT_SET
      if (!cmgMatchResponse.ok) {
        yield put(
          fetchCustomFirmMatchesDuckParts.actionCreators.failure(cmgMatchResponse.data.error)
        );
      } else if (!factSetMatchResponse.ok) {
        yield put(
          fetchCustomFirmMatchesDuckParts.actionCreators.failure(factSetMatchResponse.data.error)
        );
      }
    }
  }
}

/**
 * fetch crmInvestorFirm data
 * then fetch the linked firm if cmgEntityKey exists on crmInvestorFirm
 */
export function* fetchLinkTargetFirmSaga({ payload }: fetchLinkTargetFirmAction): SagaIterator {
  const response: GetFirmResponse | GetFactSetFirmResponse = yield call(
    payload.linkTargetFirmSource === FirmDataSource.FACT_SET
      ? rolodexApiClient.getFactSetFirm
      : rolodexApiClient.getFirm,
    payload.linkTargetFirmId
  );

  if (response.ok) {
    yield put(
      fetchLinkTargetFirmDuckParts.actionCreators.success({ linkTargetFirm: response.data })
    );
  } else {
    yield put(fetchLinkTargetFirmDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* importFactSetFirm({ factsetId }: { factsetId: string }): SagaIterator {
  const linkTargetCMGFirm = yield select(selectLinkTargetCMGFirm);

  // If linkTargetCMGFirm exists in state, the firm has already
  // been imported - there's no need to import again.
  if (!linkTargetCMGFirm) {
    const response: ImportFactSetFirmResponse = yield call(rolodexApiClient.importFactSetFirm, {
      factsetId,
    });

    if (response.ok) {
      yield put(
        setLinkTargetCMGFirm({
          linkTargetCMGFirm: response.data,
        })
      );
    } else {
      throw new Error(response.data.error.message);
    }
  }
}

export function* fetchCMGFirm({ firmId }: { firmId: UUID }): SagaIterator {
  const linkTargetCMGFirm = yield select(selectLinkTargetCMGFirm);

  // If linkTargetCMGFirm exists in state, the firm has already
  // been loaded - there's no need to load again.
  if (!linkTargetCMGFirm) {
    const response: GetFirmResponse = yield call(rolodexApiClient.getFirm, firmId);

    if (response.ok) {
      yield put(
        setLinkTargetCMGFirm({
          linkTargetCMGFirm: response.data,
        })
      );
    }
  }
}

export function* updateCMGFirmDetails({
  linkTargetCMGFirm,
  entityType,
  industryType,
  firmType,
}: {
  linkTargetCMGFirm: Firm;
  entityType: LegalEntityType | null;
  industryType: IndustryType | null;
  firmType: FirmCategoryType | null;
}) {
  const body: AdminFirmBasicInformationUpdate = {
    ...linkTargetCMGFirm.details,
    ...(entityType ? { entityType } : {}),
    ...(industryType ? { industryType } : {}),
    ...(firmType ? { firmType } : {}),
  };
  const response: UpdateFirmDetailsResponse = yield call(rolodexApiClient.updateFirmDetails, {
    firmId: linkTargetCMGFirm.id,
    // We can't update entity type or industry type independently.
    // These fields live within a Firm's details. To create the payload,
    // We'll want to take creat details object as it exists and append
    // entity type and/or industry type.
    id: linkTargetCMGFirm.details.id,
    body,
  });

  if (!response.ok) {
    throw new Error(
      'An error occurred adding Entity and/or Industry Types. Please re-attempt to link.'
    );
  }
}

export function* addCrmInvestorFirmNameToCMGFirm({
  linkTargetCMGFirm,
  value,
}: {
  linkTargetCMGFirm: Firm;
  value: string;
}): SagaIterator {
  const hasInvestorFirmNameBeenAddedToCMGFirm = yield select(
    selectHasInvestorFirmNameBeenAddedToCMGFirm
  );

  // If the investor firm's name has been added to the CMG Firm
  // already, an error will occur if we attempt to add it
  // again - so let's skip making the request.
  if (hasInvestorFirmNameBeenAddedToCMGFirm) {
    return true;
  }

  const response: CreateFirmNameResponse = yield call(rolodexApiClient.createFirmName, {
    firmId: linkTargetCMGFirm.id,
    name: {
      type: FirmNameType.OTHER,
      value,
      recordStatus: RecordStatus.EFFECTIVE,
    },
  });

  if (response.ok) {
    yield put(
      setHasInvestorFirmNameBeenAddedToCMGFirm({ hasInvestorFirmNameBeenAddedToCMGFirm: true })
    );
  } else {
    throw new Error(
      `An error occurred adding the "${value}" Name Record. Please re-attempt to link.`
    );
  }
}

export function* createInvestorRoleInCMGFirm({
  linkTargetCMGFirm,
}: {
  linkTargetCMGFirm: Firm;
}): SagaIterator {
  const hasInvestorRoleBeenAddedToCMGFirm = yield select(selectHasInvestorRoleBeenAddedToCMGFirm);

  // If the investor role has been added to the CMG Firm
  // already, an error will occur if we attempt to add it
  // again - so let's skip making the request.
  if (hasInvestorRoleBeenAddedToCMGFirm) {
    return true;
  }

  const response: CreateFirmRoleResponse = yield call(rolodexApiClient.createFirmRole, {
    firmId: linkTargetCMGFirm.id,
    role: {
      type: FirmRoleType.INVESTOR,
      recordStatus: RecordStatus.EFFECTIVE,
    },
  });

  if (response.ok) {
    yield put(setHasInvestorRoleBeenAddedToCMGFirm({ hasInvestorRoleBeenAddedToCMGFirm: true }));
  } else {
    throw new Error('An error occurred adding the Investor Role. Please re-attempt to link.');
  }
}

type UpdateCrmInvestorFirmLinkParams = {
  crmIntegrationId: string;
  crmInvestorFirmId: string;
  linkTargetCMGFirmKey: string | null;
};
export function* updateInvestorFirmLink({
  crmIntegrationId,
  crmInvestorFirmId,
  linkTargetCMGFirmKey,
}: UpdateCrmInvestorFirmLinkParams): SagaIterator {
  const updateInvestorFirmLinkResponse: UpdateInvestorFirmLinkResponse = yield call(
    rolodexApiClient.updateInvestorFirmLink,
    crmIntegrationId,
    crmInvestorFirmId,
    linkTargetCMGFirmKey
  );

  if (!updateInvestorFirmLinkResponse.ok) {
    yield put(
      updateCrmInvestorFirmLinkDuckParts.actionCreators.failure(
        updateInvestorFirmLinkResponse.data.error
      )
    );
  } else {
    // The firm was successfully linked.
    // Close the modal, and clear link-related state.
    yield put(closeLinkOrganizationModal());
    // Reset state used in the link modal
    yield put(resetLinkOrganizationModalState());
    // Fire the success action to reset the link status state to "not loading"
    yield put(updateCrmInvestorFirmLinkDuckParts.actionCreators.success({}));
    // Re-fetch the Investor Firm in view so that the new link status is displayed
    yield put(fetchCrmInvestorFirm({ crmIntegrationId, crmInvestorFirmId }));
    // Re-fetch matches for the Investor Firm - these may have changed with the
    // Investor Firm now being linked.
    yield put(fetchCrmInvestorFirmMatches({ crmIntegrationId, crmInvestorFirmId }));
  }
}

type UpdateCrmInvestorNotesParams = {
  crmIntegrationId: string;
  crmInvestorFirmId: string;
  notes: string | null;
};
export function* updateInvestorNotes({
  crmIntegrationId,
  crmInvestorFirmId,
  notes,
}: UpdateCrmInvestorNotesParams): SagaIterator {
  const updateInvestorNotesResponse: UpdateCrmInvestorNotesResponse = yield call(
    rolodexApiClient.updateCrmInvestorNotes,
    crmIntegrationId,
    crmInvestorFirmId,
    notes
  );

  if (!updateInvestorNotesResponse.ok) {
    yield put(
      updateCrmInvestorNotesDuckParts.actionCreators.failure(updateInvestorNotesResponse.data.error)
    );
  } else {
    yield put(resetNotesModalState());
    yield put(updateCrmInvestorNotesDuckParts.actionCreators.success({}));
    // Re-fetch the Investor Firm in view so that the new notes are available
    yield put(fetchCrmInvestorFirm({ crmIntegrationId, crmInvestorFirmId }));
  }
}

type CreateLinkedTargetCMGFirmParams = {
  linkTargetFirmSource?: FirmDataSource;
  linkTargetFirmId: string;
  addInvestorRoleToCMGFirm: boolean;
  addInvestorFirmNameToCMGFirmValue?: string | null;
  entityType: string | null;
  industryType: string | null;
  firmType: string | null;
};

export function* createLinkedTargetCMGFirm({
  linkTargetFirmSource,
  linkTargetFirmId,
  addInvestorRoleToCMGFirm,
  addInvestorFirmNameToCMGFirmValue,
  entityType,
  industryType,
  firmType,
}: CreateLinkedTargetCMGFirmParams): SagaIterator {
  if (linkTargetFirmSource === FirmDataSource.FACT_SET) {
    // If the Match Firm's source is FactSet, we first need to
    // import the FactSet Firm into the Entity Master DB/Rolodex.
    yield call(importFactSetFirm, { factsetId: linkTargetFirmId });
  } else if (linkTargetFirmSource === FirmDataSource.CMG) {
    // If the Match Firm is a CMG Firm, we need to load it.
    yield call(fetchCMGFirm, { firmId: linkTargetFirmId });
  }

  const linkTargetCMGFirm: Firm | null = yield select(selectLinkTargetCMGFirm);

  // At this point, linkTargetCMGFirm should exist in state.
  // If it does not, importing the FactSet Firm or loading
  // the CMG Firm failed.
  if (!linkTargetCMGFirm) {
    throw new Error('An error occurred loading the link target firm. Please re-attempt to link.');
  }

  // Update CMG Firm Industry Type and Entity Type if necessary
  if (linkTargetCMGFirm && (entityType || industryType || firmType)) {
    const entity: LegalEntityType | null = LegalEntityType[entityType ? entityType : ''];
    const industry: IndustryType | null = IndustryType[industryType ? industryType : ''];
    const firmTypeCategory: FirmCategoryType | null = FirmCategoryType[firmType ? firmType : ''];

    yield call(updateCMGFirmDetails, {
      linkTargetCMGFirm,
      entityType: entity,
      industryType: industry,
      firmType: firmTypeCategory,
    });
  }

  // Add the CRM Investor Firm Name to the CMG Firm if necessary
  if (addInvestorRoleToCMGFirm) {
    yield call(createInvestorRoleInCMGFirm, { linkTargetCMGFirm });
  }

  // Add the CRM Investor Firm Name to the CMG Firm if necessary
  if (addInvestorFirmNameToCMGFirmValue) {
    yield call(addCrmInvestorFirmNameToCMGFirm, {
      linkTargetCMGFirm,
      value: addInvestorFirmNameToCMGFirmValue,
    });
  }

  return linkTargetCMGFirm;
}

export function* updateCrmInvestorFirmLinkSaga({
  payload,
}: updateCrmInvestorFirmLinkAction): SagaIterator {
  const {
    crmIntegrationId,
    crmInvestorFirmId,
    linkTargetFirmId,
    linkTargetFirmSource,
    addInvestorRoleToCMGFirm,
    addInvestorFirmNameToCMGFirmValue,
    entityType,
    industryType,
    firmType,
  } = payload;
  let linkTargetCMGFirm: Firm | null = null;

  try {
    // If a link is being created, linkTargetFirmId will be truthy.
    if (linkTargetFirmId) {
      linkTargetCMGFirm = yield call(createLinkedTargetCMGFirm, {
        linkTargetFirmSource,
        linkTargetFirmId,
        addInvestorRoleToCMGFirm,
        addInvestorFirmNameToCMGFirmValue,
        entityType,
        industryType,
        firmType,
      });
    }

    // Now, we'll finally link the firms.
    yield call(updateInvestorFirmLink, {
      crmIntegrationId,
      crmInvestorFirmId,
      linkTargetCMGFirmKey: linkTargetCMGFirm ? linkTargetCMGFirm.key : null,
    });
  } catch (error) {
    errorUtil.assertIsError(error);
    yield put(
      updateCrmInvestorFirmLinkDuckParts.actionCreators.failure({
        code: '',
        message: error.message,
        details: [],
        target: '',
      })
    );
  }
}

export function* updateCrmInvestorNotesSaga({
  payload,
}: updateCrmInvestorNotesAction): SagaIterator {
  const { crmIntegrationId, crmInvestorFirmId, notes } = payload;

  try {
    yield call(updateInvestorNotes, {
      crmIntegrationId,
      crmInvestorFirmId,
      notes,
    });
  } catch (error) {
    errorUtil.assertIsError(error);
    yield put(
      updateCrmInvestorNotesDuckParts.actionCreators.failure({
        code: '',
        message: error.message,
        details: [],
        target: '',
      })
    );
  }
}

export function* fetchMatchFirmDetailSaga({ payload }: fetchMatchFirmDetailAction): SagaIterator {
  const response: GetFirmResponse | GetFactSetFirmResponse = yield call(
    payload.type === FirmDataSource.FACT_SET
      ? rolodexApiClient.getFactSetFirm
      : rolodexApiClient.getFirm,
    payload.id
  );

  if (response.ok) {
    yield put(
      fetchMatchFirmDetailDuckParts.actionCreators.success({
        type: payload.type,
        firm: response.data,
      })
    );
  } else {
    yield put(fetchMatchFirmDetailDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* fetchMatchFirmHierarchySaga({
  payload,
}: fetchMatchFirmHierarchyAction): SagaIterator {
  const params = {
    firmId: payload.id,
    ...(payload.type === FirmDataSource.CMG || payload.type === FirmDataSource.CUSTOMER_CREATED
      ? { traversalType: TraversalType.FULL }
      : {}),
  };

  const response: GetFirmHierarchyResponse | GetFactSetFirmHierarchyResponse = yield call(
    payload.type === FirmDataSource.FACT_SET
      ? rolodexApiClient.getFactSetFirmHierarchy
      : rolodexApiClient.getFirmHierarchy,
    params
  );

  if (response.ok) {
    yield put(
      fetchMatchFirmHierarchyDuckParts.actionCreators.success({
        type: payload.type,
        firmHierarchy: response.data,
      })
    );
  } else {
    yield put(fetchMatchFirmHierarchyDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* rolodexCrmMatchListSaga() {
  yield takeLatest<fetchCrmInvestorFirmAction>(
    fetchCrmInvestorFirmDuckParts.actionTypes.REQUEST,
    fetchCrmInvestorFirmSaga
  );
  yield takeLatest<fetchCrmInvestorFirmMatchesAction>(
    fetchCrmInvestorFirmMatchesDuckParts.actionTypes.REQUEST,
    fetchCrmInvestorFirmMatchesSaga
  );
  yield takeLatest<fetchCustomFirmMatchesAction>(
    fetchCustomFirmMatchesDuckParts.actionTypes.REQUEST,
    fetchCustomFirmMatchesSaga
  );
  yield takeLatest<fetchLinkedFirmMatchAction>(
    fetchLinkedFirmMatchDuckParts.actionTypes.REQUEST,
    fetchLinkedFirmMatchSaga
  );
  yield takeLatest<fetchLinkTargetFirmAction>(
    fetchLinkTargetFirmDuckParts.actionTypes.REQUEST,
    fetchLinkTargetFirmSaga
  );
  yield takeLatest<updateCrmInvestorFirmLinkAction>(
    updateCrmInvestorFirmLinkDuckParts.actionTypes.REQUEST,
    updateCrmInvestorFirmLinkSaga
  );
  yield takeLatest<updateCrmInvestorNotesAction>(
    updateCrmInvestorNotesDuckParts.actionTypes.REQUEST,
    updateCrmInvestorNotesSaga
  );
  yield takeLatest<fetchMatchFirmDetailAction>(
    fetchMatchFirmDetailDuckParts.actionTypes.REQUEST,
    fetchMatchFirmDetailSaga
  );
  yield takeLatest<fetchMatchFirmHierarchyAction>(
    fetchMatchFirmHierarchyDuckParts.actionTypes.REQUEST,
    fetchMatchFirmHierarchySaga
  );
}
