import { DotStatus, Link, TableCell, TableRow } from '@cmg/design-system';
import { VFC } from 'react';

export type Props = {
  loginTypeUrl: Readonly<string>;
  loginTypeDisplayName: Readonly<string>;
  providerName?: Readonly<string>;
  showVerificationStatus: boolean;
  isVerified: Readonly<boolean>;
  isEnabled: Readonly<boolean>;
};

export const LoginSettingsSectionTableRow: VFC<Props> = ({
  loginTypeUrl,
  loginTypeDisplayName,
  providerName,
  showVerificationStatus,
  isVerified,
  isEnabled,
}) => {
  return (
    <TableRow>
      <TableCell>
        <Link href={loginTypeUrl}>{loginTypeDisplayName}</Link>
      </TableCell>
      <TableCell>{providerName}</TableCell>
      <TableCell>
        {showVerificationStatus && (
          <DotStatus
            color={isVerified ? 'success' : 'error'}
            label={isVerified ? 'Tested' : 'Not Tested'}
          />
        )}
        <DotStatus
          color={isVerified && isEnabled ? 'success' : 'error'}
          label={isVerified && isEnabled ? 'Active' : 'Inactive'}
        />
      </TableCell>
    </TableRow>
  );
};
