import styled from 'styled-components/macro';

export const SOption = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.color.blue14 : 'none')};
`;

export const SOptionColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const SOptionFirmName = styled.div<{ isSelected: boolean }>`
  color: ${({ theme, isSelected }) => (isSelected ? theme.text.color.white : 'inherit')};
`;

export const SOptionFirmDescription = styled.div<{ isSelected: boolean }>`
  font-size: ${({ theme }) => theme.text.size.small};
  color: ${({ isSelected, theme }) => (isSelected ? theme.text.color.white : theme.color.gray8)};
`;

export const SOptionIndustryAndEntityTypes = styled.div`
  text-align: right;
  font-size: ${({ theme }) => theme.text.size.small};
`;
