import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  visuallyHidden,
} from '@cmg/design-system';
import { useCallback, VFC } from 'react';

import { Role } from '../../../../../../types/domain/role/role';

export type Props = Readonly<{
  roles: readonly Role[];
  selectedRoleIds: readonly string[];
  onRoleAdded: (roleId: string) => void;
  onRoleRemoved: (idxOfRoleToRemove: number) => void;
}>;

export const RolesSectionTableEdit: VFC<Props> = ({
  roles,
  selectedRoleIds,
  onRoleAdded,
  onRoleRemoved,
}) => {
  const changeRoleSelection = useCallback(
    (roleId: string, isSelected: boolean) => {
      if (isSelected) {
        onRoleAdded(roleId);
        return;
      }

      const selectedRoleIdx = selectedRoleIds.findIndex(
        selectedRoleId => selectedRoleId === roleId
      );
      onRoleRemoved(selectedRoleIdx);
    },
    [onRoleAdded, onRoleRemoved, selectedRoleIds]
  );

  return (
    <TableContainer>
      <Table aria-label="Edit User Roles">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography style={visuallyHidden}>Is Selected</Typography>
            </TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>Permissions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {roles.map(role => {
            const isSelected = selectedRoleIds.some(selectedRoleId => selectedRoleId === role.id);

            return (
              <TableRow key={role.id}>
                <TableCell>
                  <Switch
                    aria-label={`Switch ${role.name}`}
                    checked={isSelected}
                    onChange={e => changeRoleSelection(role.id, e.target.checked)}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {role.name}
                </TableCell>
                <TableCell>{role.description}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
