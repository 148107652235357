import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const defaultReturnUrl = '/';
const allowedReturnUrls = ['/document360'];

export const useSecuredQueryParams = () => {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const returnUrlParam = queryParams.get('returnUrl');
  const relativeUrl = decodeURIComponent(returnUrlParam ?? defaultReturnUrl);
  const isAllowedRelativeUrl =
    relativeUrl === '/' || allowedReturnUrls.some(allowedUrl => relativeUrl.startsWith(allowedUrl));

  return {
    returnUrl: isAllowedRelativeUrl ? relativeUrl : defaultReturnUrl,
  };
};
