import { apiTypes, duckPartFactory } from '@cmg/common';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import systemManagementApiClient, {
  GetAccountParams,
  GetAccountsResponse,
} from '../../../common/api/systemManagementApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { Account } from '../../../types/domain/account/account';

type SuccessValue = {
  data: Account[];
  pagination: apiTypes.Pagination;
};
const accountListDuckParts = duckPartFactory.makeAPIDuckParts<GetAccountParams, SuccessValue>({
  prefix: 'GLOBAL_MANAGEMENT/ACCOUNT_LIST',
});

const { actionTypes, actionCreators, initialState, reducer, makeSelectors } = accountListDuckParts;

/**
 * ACTION CREATORS
 */

export const fetchAccountList = actionCreators.request;
type RequestAction = ReturnType<typeof fetchAccountList>;

/**
 * REDUCERS
 */

export type ReducerState = typeof initialState;
export default reducer;
export { initialState };

/**
 * SELECTORS
 */

const selectLocalState = (state: RootState) => state.adminAccountList;
const selectors = makeSelectors(selectLocalState);

export const selectError = selectors.selectFailed;
export const selectAccounts = (state: RootState) => {
  const successBody = selectors.selectData(state);
  return successBody ? successBody.data : [];
};
export const selectPagination = (state: RootState) => {
  const successBody = selectors.selectData(state);
  return successBody ? successBody.pagination : null;
};
export const selectLoading = selectors.selectLoading;

/**
 * SAGAS
 */

export function* fetchAccountListSaga({ payload }: RequestAction): SagaIterator {
  const response: GetAccountsResponse = yield call(
    systemManagementApiClient.admin.getAccounts,
    payload
  );
  if (response.ok) {
    yield put(actionCreators.success(response.data));
  } else {
    yield put(actionCreators.failure(response.data.error));
  }
}

export function* adminAccountListSaga() {
  yield takeLatest<RequestAction>(actionTypes.REQUEST, fetchAccountListSaga);
}
