import * as yup from 'yup';

export const enableAccountFormSchema = yup.object().shape({
  cmgEntityKey: yup
    .string()
    .matches(/^[0-9]+$/, 'CMG Entity Key must be only digits')
    .nullable()
    .required()
    .label('CMG Entity Key')
    .length(9),
});
