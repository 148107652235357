import { InvestorCoverageRead } from '@capital-markets-gateway/api-client-rolodex';
import { DataGrid } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledGrid = styled(DataGrid<InvestorCoverageRead>)`
  flex: 1;
  .ag-row .ag-cell {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
`;

export const SFilters = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 0;
  width: 40%;
  ${({ theme }) => theme.mediaQuery.largeUp} {
    flex-direction: row;
  }
`;

export const SField = styled.div`
  margin: 0 10px 10px 0;
  width: 100%;
`;

export const SIconWrapper = styled.div`
  margin-left: 10px;
`;
