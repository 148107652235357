import { Panel, Table } from '@cmg/common';

import { SNoOptionWrapper } from '../../../../../common/components/layout/firm-record-panel/GenericFirmRecordPanel.styles';
import { LinkFrequencyData } from '../../../../../types/domain/firm/LinkFrequencyData';
import { SDetail, SDetailLabel, SDetailValue, STableCell } from './FirmLinkageSummaryPanel.styles';

type Props = {
  linkFrequencyData: LinkFrequencyData;
};

export const FirmLinkageSummaryPanel: React.FC<Props> = ({ linkFrequencyData }) => {
  return (
    <Panel>
      <Panel.Header title="Linkage Summary" />
      <SDetail>
        <SDetailLabel>LINK FREQUENCY</SDetailLabel>
        <SDetailValue>{linkFrequencyData.linkFrequency}</SDetailValue>
      </SDetail>
      <Table striped>
        <thead>
          <tr>
            <th>Sellside Client</th>
            <th>CMG Entity Key</th>
          </tr>
        </thead>
        <tbody>
          {linkFrequencyData.linkFrequency === 0 && (
            <tr>
              <td colSpan={2}>
                <SNoOptionWrapper>No records available</SNoOptionWrapper>
              </td>
            </tr>
          )}
          {linkFrequencyData.bankRelationships &&
            linkFrequencyData.bankRelationships.map(relationship => (
              <tr key={relationship.cmgEntityKey}>
                <td>
                  <STableCell>{relationship.name}</STableCell>
                </td>
                <td>
                  <STableCell>{relationship.cmgEntityKey}</STableCell>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Panel>
  );
};
