import { apiTypes, reduxUtil } from '@cmg/common';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as accountApiClient from '../../../../common/api/accountApiClient';
import systemManagementApiClient, {
  GetApiKeysParams,
  GetApiKeysResponse,
} from '../../../../common/api/systemManagementApiClient';
import { RootState } from '../../../../common/redux/rootReducer';
import { ApiKey } from '../../../../types/domain/api-keys/api-key';
import { selectSelfSubdomain } from '../../../shared/ducks';
import { selectAccountSubdomain } from '../../shared/ducks';

/**
 * ACTION TYPES
 */
enum ActionTypes {
  FETCH_API_KEY_LIST_REQUEST = 'global_management/api-keys/FETCH_API_KEY_LIST_REQUEST',
  FETCH_API_KEY_LIST_SUCCESS = 'global_management/api-keys/FETCH_API_KEY_LIST_SUCCESS',
  FETCH_API_KEY_LIST_FAILURE = 'global_management/api-keys/FETCH_API_KEY_LIST_FAILURE',
}

/**
 * ACTION CREATORS
 */

export const fetchApiKeyList = (params: GetApiKeysParams) => ({
  type: ActionTypes.FETCH_API_KEY_LIST_REQUEST,
  payload: {
    params,
  },
});

export const fetchApiKeyListSuccess = (params: {
  keys: ApiKey[];
  pagination: apiTypes.Pagination;
}) => ({
  type: ActionTypes.FETCH_API_KEY_LIST_SUCCESS,
  payload: {
    keys: params.keys,
    pagination: params.pagination,
  },
});

export const fetchApiKeyListFailure = () => ({
  type: ActionTypes.FETCH_API_KEY_LIST_FAILURE,
});

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.FETCH_API_KEY_LIST_REQUEST]: ReturnType<typeof fetchApiKeyList>;
  [ActionTypes.FETCH_API_KEY_LIST_SUCCESS]: ReturnType<typeof fetchApiKeyListSuccess>;
  [ActionTypes.FETCH_API_KEY_LIST_FAILURE]: ReturnType<typeof fetchApiKeyListFailure>;
};

/**
 * REDUCERS
 */
const { createReducer } = reduxUtil;

export type ReducerState = {
  error: boolean;
  keys: ApiKey[];
  loading: boolean;
  pagination: apiTypes.Pagination;
};

export const initialState = {
  error: false,
  keys: [],
  loading: false,
  pagination: {
    activePage: 1,
    perPage: 0,
    totalPages: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  },
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.FETCH_API_KEY_LIST_REQUEST]: () => false,
  [ActionTypes.FETCH_API_KEY_LIST_SUCCESS]: () => false,
  [ActionTypes.FETCH_API_KEY_LIST_FAILURE]: () => true,
});

const keysReducer = createReducer<ReducerState['keys'], Actions>(initialState.keys, {
  [ActionTypes.FETCH_API_KEY_LIST_SUCCESS]: (curState, { payload }) => payload.keys,
});

const loadingReducer = createReducer<ReducerState['loading'], Actions>(initialState.loading, {
  [ActionTypes.FETCH_API_KEY_LIST_REQUEST]: () => true,
  [ActionTypes.FETCH_API_KEY_LIST_SUCCESS]: () => false,
  [ActionTypes.FETCH_API_KEY_LIST_FAILURE]: () => false,
});

const paginationReducer = createReducer<ReducerState['pagination'], Actions>(
  initialState.pagination,
  {
    [ActionTypes.FETCH_API_KEY_LIST_SUCCESS]: (curState, { payload }) => payload.pagination,
  }
);

export default combineReducers<ReducerState>({
  error: errorReducer,
  keys: keysReducer,
  loading: loadingReducer,
  pagination: paginationReducer,
});

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.adminAccountApiKeyList;
export const selectError = state => selectState(state).error;
export const selectApiKeys = state => selectState(state).keys;
export const selectPagination = state => selectState(state).pagination;
export const selectLoading = state => selectState(state).loading;

/**
 * SAGAS
 */
export function* fetchApiKeyListSaga({
  payload,
}: Actions[ActionTypes.FETCH_API_KEY_LIST_REQUEST]): SagaIterator {
  const { params } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: GetApiKeysResponse | accountApiClient.GetMyApiKeysResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyApiKeys, params);
  } else {
    response = yield call(systemManagementApiClient.admin.getApiKeys, accountSubdomain, params);
  }

  if (response.ok) {
    const { data: keys, pagination } = response.data;
    yield put(fetchApiKeyListSuccess({ keys, pagination }));
  } else {
    yield put(fetchApiKeyListFailure());
  }
}

export function* adminAccountApiKeyListSaga() {
  yield takeLatest<Actions[ActionTypes.FETCH_API_KEY_LIST_REQUEST]>(
    ActionTypes.FETCH_API_KEY_LIST_REQUEST,
    fetchApiKeyListSaga
  );
}
