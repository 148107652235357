import { envVarUtil, urlUtil } from '@cmg/common';

const {
  getOrThrow,
  parseBoolOrThrow,
  parseFloatOrThrow,
  getLocalStorageEnvOverrides,
  getSessionEnvOverrides,
} = envVarUtil;

// Environment variables provided by build time variable injection.
// Exported for testing
export const buildTimeEnvVars = {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_ROOT_DOMAIN: process.env.REACT_APP_ROOT_DOMAIN,
  REACT_APP_CLUSTER_ENVIRONMENT: process.env.REACT_APP_CLUSTER_ENVIRONMENT,
  REACT_APP_MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
  REACT_APP_DATADOG_APPLICATION_ID: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_TRACING_ORIGIN: process.env.REACT_APP_DATADOG_TRACING_ORIGIN,
  REACT_APP_ECM_SPA_BASE_URL: process.env.REACT_APP_ECM_SPA_BASE_URL,
  REACT_APP__ENABLE_FEATURE__CRM__CUSTOMER_CRM_MANAGEMENT:
    process.env.REACT_APP__ENABLE_FEATURE__CRM__CUSTOMER_CRM_MANAGEMENT,
  REACT_APP__ENABLE_FEATURE__CRM__SYSADMIN_CRM_MANAGEMENT:
    process.env.REACT_APP__ENABLE_FEATURE__CRM__SYSADMIN_CRM_MANAGEMENT,
  REACT_APP_OIDC_AUTHORITY_BASE_URL: process.env.REACT_APP_OIDC_AUTHORITY_BASE_URL,
  REACT_APP_AUTH_TOKEN_RENEWAL_INTERVAL_IN_MINUTES:
    process.env.REACT_APP_AUTH_TOKEN_RENEWAL_INTERVAL_IN_MINUTES,
  REACT_APP_SELECT_DOMAIN_URL_MASK_REGEX: process.env.REACT_APP_SELECT_DOMAIN_URL_MASK_REGEX,
  REACT_APP_BUILD_CLIENT_APP_VERSION: process.env.REACT_APP_BUILD_CLIENT_APP_VERSION,
  REACT_APP__ENABLE_FEATURE__REMAP_CMG_ENTITY_KEY_FLOW:
    process.env.REACT_APP__ENABLE_FEATURE__REMAP_CMG_ENTITY_KEY_FLOW,
  REACT_APP_DATALAB_ADMIN_SPA_ROOT_PATH: process.env.REACT_APP_DATALAB_ADMIN_SPA_ROOT_PATH,
  REACT_APP__ENABLE_FEATURE__CERTIFICATE_LIBRARY:
    process.env.REACT_APP__ENABLE_FEATURE__CERTIFICATE_LIBRARY,
  REACT_APP__ENABLE_FEATURE__CERTIFICATE_REVIEW:
    process.env.REACT_APP__ENABLE_FEATURE__CERTIFICATE_REVIEW,
  REACT_APP__ENABLE_FEATURE__DOWNLOAD_USERS_ECM_40888:
    process.env.REACT_APP__ENABLE_FEATURE__DOWNLOAD_USERS_ECM_40888,
};

// Merge runtime variables provided by runtime-env.js which is generated in the docker entrypoint.
// Runtime variables take precedence.
const mergedVars = {
  ...buildTimeEnvVars,
  ...globalThis.env,
  ...getLocalStorageEnvOverrides(),
  ...getSessionEnvOverrides(),
};

const appSettings = {
  env: {
    clusterEnvironment: getOrThrow(mergedVars.REACT_APP_CLUSTER_ENVIRONMENT),
    rootDomain: getOrThrow(mergedVars.REACT_APP_ROOT_DOMAIN),
    secureCookies: !urlUtil.isLocalhost(getOrThrow(mergedVars.REACT_APP_ROOT_DOMAIN)),
    isDevelopment: mergedVars.NODE_ENV === 'development',
    ecmSpaBaseUrl: getOrThrow(mergedVars.REACT_APP_ECM_SPA_BASE_URL),
    developerDocsSiteUrl: 'https://docs.cmgx.io/developer-docs',
    datalabAdminRootPath: '/datalab-admin/',
  },
  features: {
    customerCrmManagement: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__CRM__CUSTOMER_CRM_MANAGEMENT,
      false
    ),
    sysAdminCrmManagement: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__CRM__SYSADMIN_CRM_MANAGEMENT,
      false
    ),
    isRemapCMGEntityKeyFlowOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__REMAP_CMG_ENTITY_KEY_FLOW,
      false
    ),
    isCertificateLibraryOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__CERTIFICATE_LIBRARY,
      false
    ),
    isCertificateReviewOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__CERTIFICATE_REVIEW,
      false
    ),
    isUserListDownloadEnabled: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__DOWNLOAD_USERS_ECM_40888,
      false
    ),
  },
  client: {
    appName: 'cmg-identity-spa',
    // The app is served at this path e.g. /app => https://{accountSubdomain}.id.alpha.oncmg.io/app/index.html
    basename: '/app',
    selectDomainUrlMaskRegex: new RegExp(
      getOrThrow(mergedVars.REACT_APP_SELECT_DOMAIN_URL_MASK_REGEX, `^(https?://)?(([^.]+).)?(id)`)
    ),
    appVersion: getOrThrow(mergedVars.REACT_APP_BUILD_CLIENT_APP_VERSION),
  },
  logging: {
    mixPanelToken: mergedVars.REACT_APP_MIXPANEL_TOKEN,
    // https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters - 'applicationId'
    datadogApplicationId: mergedVars.REACT_APP_DATADOG_APPLICATION_ID,
    // https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters - 'clientToken'
    datadogClientToken: mergedVars.REACT_APP_DATADOG_CLIENT_TOKEN,
    // https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters - 'allowedTracingOrigins' - single item of array
    datadogTracingOrigin: new RegExp(getOrThrow(mergedVars.REACT_APP_DATADOG_TRACING_ORIGIN)),
    // https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters - 'service'
    datadogServiceName: 'identity-client-web',
  },
  auth: {
    clientId: 'cmg-app', // TODO replace with correct client id for mgmt client
    oidcAuthorityBaseUrl: getOrThrow(mergedVars.REACT_APP_OIDC_AUTHORITY_BASE_URL),
    tokenRenewalIntervalInMinutes: parseFloatOrThrow(
      mergedVars.REACT_APP_AUTH_TOKEN_RENEWAL_INTERVAL_IN_MINUTES,
      30
    ),
  },
};

export type AppSettings = typeof appSettings;

export type FeatureToggles = Readonly<AppSettings['features']>;
export type BooleanFeatureToggles = {
  [K in keyof FeatureToggles]-?: FeatureToggles[K] extends boolean ? K : never;
};

export type FeatureToggleKeys = keyof FeatureToggles;
export type BooleanFeatureToggleKeys = keyof BooleanFeatureToggles;

export function getAppSettings(): Readonly<AppSettings> {
  return appSettings;
}
