import { Modal, SecondaryButton } from '@cmg/common';
import React from 'react';

import {
  SFooterContainer,
  SModalBodyContainer,
  STitleCategory,
  STitleContainer,
} from './CrmInvestorFirmNotesModal.styles';

type OwnProps = {
  investorName: string;
  notes: string | null;
  isModalVisible: boolean;
  setIsModalVisible: (val: boolean) => void;
};

export const CrmInvestorFirmNotesModal: React.FC<OwnProps> = ({
  investorName,
  notes,
  isModalVisible,
  setIsModalVisible,
}) => {
  return (
    <Modal
      show={isModalVisible}
      title={
        <STitleContainer>
          <div>{investorName}</div>
          <STitleCategory>NOTES</STitleCategory>
        </STitleContainer>
      }
      size="small"
      closeButton={true}
      onHide={() => setIsModalVisible(false)}
      footer={
        <SFooterContainer>
          <SecondaryButton onClick={() => setIsModalVisible(false)}>Close</SecondaryButton>
        </SFooterContainer>
      }
    >
      <SModalBodyContainer>{notes}</SModalBodyContainer>
    </Modal>
  );
};

export default CrmInvestorFirmNotesModal;
