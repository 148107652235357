import { apiTypes } from '@cmg/common';
import { Highlights } from '@cmg/design-system';
import { FC } from 'react';

import { getFullName, UserStatus } from '../../../../../types/domain/user/constants';
import { User } from '../../../../../types/domain/user/user';
import { UserHighlightsActions } from './actions/UserHighlightsActions';
import { UserHighlightsItems } from './UserHighlightsItems';
import { UserHighlightsTitleItems } from './UserHighlightsTitleItems';

export type Props = Readonly<{
  onReset: () => void;
  isLoading: Readonly<boolean>;
  canCreateOrEditUser: Readonly<boolean>;
  user: Readonly<User>;
  updateUserStatusError: Readonly<apiTypes.GenericServerError | null>;
  onUpdateUserStatus: (userStatus: UserStatus) => void;
  unlockUserError: Readonly<apiTypes.GenericServerError | null>;
  onUnlockUser: () => void;
  resendInviteError: Readonly<apiTypes.GenericServerError | null>;
  onResendInviteEmail: () => void;
}>;

export const UserHighlights: FC<Props> = ({
  onReset,
  canCreateOrEditUser,
  user,
  isLoading,
  updateUserStatusError,
  onUpdateUserStatus,
  unlockUserError,
  onUnlockUser,
  resendInviteError,
  onResendInviteEmail,
}) => {
  return (
    <Highlights
      title={getFullName(user)}
      titleItems={<UserHighlightsTitleItems user={user} />}
      items={<UserHighlightsItems user={user} />}
      actions={
        canCreateOrEditUser ? (
          <UserHighlightsActions
            onReset={onReset}
            user={user}
            isLoading={isLoading}
            updateUserStatusError={updateUserStatusError}
            resendInviteError={resendInviteError}
            unlockUserError={unlockUserError}
            onUnlockUser={onUnlockUser}
            onUpdateUserStatus={onUpdateUserStatus}
            onResendInviteEmail={onResendInviteEmail}
          />
        ) : undefined
      }
    />
  );
};
