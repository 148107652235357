export enum ErrorCode {
  INVALID_UNLOCK_TOKEN = 'INVALID_UNLOCK_TOKEN',
  FORBIDDEN = 'FORBIDDEN',
  UNAUTHORIZED = 'UNAUTHORIZED',
  LOGIN_PREREQUISITE = 'LOGIN_PREREQUISITE', // this code is specific to the /login-info end point
}

export const errorCodeMap = {
  [ErrorCode.INVALID_UNLOCK_TOKEN]: {
    title: 'Your unlock account link has expired',
    message:
      'You can generate a new link by using the forgot password option or by contacting us directly.',
  },
  [ErrorCode.FORBIDDEN]: {
    title: 'Access denied',
    message:
      'You do not have permission to perform this action. Access levels for your user account may not be set correctly.',
  },
  [ErrorCode.UNAUTHORIZED]: {
    title: 'Unauthorized access',
    message: 'You do not have permission to perform this action.',
  },
  [ErrorCode.LOGIN_PREREQUISITE]: {
    message:
      'There was a problem loading your account information. Please check that the URL entered is correct and try again. If the problem persists please contact your system administrator.',
  },
};
