import { DomainNav } from '@cmg/design-system';
import React from 'react';
import { connect } from 'react-redux';

import routeFactory from '../../../../common/util/routeFactory';
import { ReactRouterDomainNavTab } from '../../../../design-system/react-router/ReactRouterDomainNavTab';
import { useRoutesMatch } from '../../../../design-system/react-router/useRoutesMatch';
import { selectCrmIntegration } from '../../shared/ducks';
import { crmManagementRoutes } from './CRMManagementDomainNav.model';
import { usePermissionedCRMManagementRoutes } from './hooks/usePermissionedCRMManagementRoutes';

const mapStateToProps = state => ({
  crmIntegration: selectCrmIntegration(state),
});

export type Props = ReturnType<typeof mapStateToProps>;

export const CRMManagementDomainNavComponent: React.VFC<Props> = ({ crmIntegration }) => {
  const { matchedRouteName, routeNames } = useRoutesMatch(crmManagementRoutes);
  const { canAccessRoute } = usePermissionedCRMManagementRoutes();
  return (
    <DomainNav value={canAccessRoute(matchedRouteName) ? matchedRouteName : false}>
      {canAccessRoute('customerCrmFilesUpload') && (
        <ReactRouterDomainNavTab
          value={routeNames.customerCrmFilesUpload}
          label="CRM Files Upload"
          href={routeFactory.customerCrmFilesUpload.getUrlPath()}
        />
      )}
      {canAccessRoute('sysAdminCrmFilesUpload') && crmIntegration && crmIntegration.accountId && (
        <ReactRouterDomainNavTab
          value={routeNames.sysAdminCrmFilesUpload}
          label="CRM Files Upload"
          href={routeFactory.sysAdminCrmFilesUpload.getUrlPath({
            crmIntegrationId: crmIntegration.accountId,
          })}
        />
      )}
    </DomainNav>
  );
};

export default connect(mapStateToProps)(CRMManagementDomainNavComponent);
