import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IdentityPageContent } from '../../../../../../design-system/IdentityPageContent';
import { OpenIdConnectConfigurationUpdate } from '../../../../../../types/domain/identity-provider/configurations/openIdConnect';
import { IdentityProviderType } from '../../../../../../types/domain/identity-provider/constants';
import { IdentityProviderOpenIdConnect } from '../../../../../../types/domain/identity-provider/identityProvider';
import {
  fetchIdentityProvider,
  fetchOpenIdConnectMetadata,
  resetState,
  selectIdentityProvider,
  selectIdentityProviderLoading,
  selectOpenIdConnectConfigError,
  selectOpenIdConnectConfigSubmitting,
  selectOpenIdConnectMetadata,
  selectOpenIdConnectMetadataError,
  selectOpenIdConnectMetadataLoading,
  updateOpenIdConnectConfig,
} from '../../../../../account-detail/security/identity-providers/ducks';
import { selectAccount } from '../../../../../account-detail/shared/ducks';
import { LoginProviderHighlights } from '../shared/LoginProviderHighlights';
import OverviewPanel from '../shared/OverviewPanel';
import { ProviderRouteSkeleton } from '../shared/ProviderRouteSkeleton';
import { ProviderDetailsSection } from './ProviderDetailsSection';

const mapStateToProps = state => ({
  account: selectAccount(state),
  provider: selectIdentityProvider(state),
  loading: selectIdentityProviderLoading(state),
  metadata: selectOpenIdConnectMetadata(state),
  metadataLoading: selectOpenIdConnectMetadataLoading(state),
  metadataError: selectOpenIdConnectMetadataError(state),
  configError: selectOpenIdConnectConfigError(state),
  configSubmitting: selectOpenIdConnectConfigSubmitting(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchIdentityProvider,
      fetchOpenIdConnectMetadata,
      updateOpenIdConnectConfig,
      resetState,
    },
    dispatch
  ),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;

export type Props = StateProps & DispatchProps;

/**
 * This route allows users to update or add the open id connect identity provider configuration
 */
export const OpenIdProviderRouteComponent: React.FC<Props> = ({
  provider,
  loading,
  metadata,
  metadataLoading,
  metadataError,
  configError,
  configSubmitting,
  actions,
  account,
}) => {
  const canEditProvider = useCheckPermissions(
    [permissionsByEntity.GlobalAccount.FULL, permissionsByEntity.Account.FULL],
    false
  );

  useEffect(() => {
    if (account) {
      actions.fetchOpenIdConnectMetadata();
      actions.fetchIdentityProvider({
        identityProviderType: IdentityProviderType.OPEN_ID_CONNECT,
      });

      return () => {
        actions.resetState();
      };
    }
  }, [account, actions]);

  if (loading || metadataLoading) {
    return <ProviderRouteSkeleton />;
  }

  if (!account) {
    return null;
  }

  const openIdProvider = provider as IdentityProviderOpenIdConnect | null;

  return (
    <OverviewPanel<OpenIdConnectConfigurationUpdate>
      account={account}
      provider={openIdProvider}
      onUpdateConfig={actions.updateOpenIdConnectConfig}
      renderPanel={params => {
        return (
          <IdentityPageContent
            localHighlights={
              <LoginProviderHighlights
                canEdit={canEditProvider}
                isLoading={metadataLoading || configSubmitting}
                providerType={IdentityProviderType.OPEN_ID_CONNECT}
                configuredProvider={openIdProvider}
                onStatusToggle={provider =>
                  params.onSubmit({ ...provider, enabled: !provider.enabled }, false)
                }
              />
            }
            gridContent={
              <ProviderDetailsSection
                onReset={resetState}
                canEdit={canEditProvider}
                metadata={metadata}
                provider={openIdProvider}
                error={configError ?? metadataError}
                isSubmitting={configSubmitting}
                onSubmit={data => params.onSubmit(data, false)}
              />
            }
          />
        );
      }}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenIdProviderRouteComponent);
