import styled from 'styled-components/macro';

export const SInfoBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SInfoBox = styled.div`
  padding: 0 15px;
  margin: 15px;
  flex: 1 1 200px;
`;

export const SInfoItem = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  align-items: flex-start;
`;

export const SInfoLabel = styled.dt`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  margin-right: 15px;
  flex-wrap: wrap;
  text-align: left;
  color: ${({ theme }) => theme.text.color.light};
  width: 120px;
`;

export const SInfoValue = styled.dd`
  text-align: left;
  flex-wrap: wrap;
`;
