import { Radio } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';

export type Props = Readonly<{
  readonly isChecked: boolean;
  readonly label: string;
  readonly value: string;
  readonly name: string;
}>;

export const PermissionCellRadio: React.FC<Props> = ({ isChecked, label, value, name }) => {
  const { handleChange } = useFormikContext<{ permissions: { [key: string]: string } }>();

  const inputProps = useMemo(() => ({ 'aria-label': label }), [label]);

  return (
    <Radio
      inputProps={inputProps}
      checked={isChecked}
      value={value}
      name={name}
      onChange={handleChange}
    />
  );
};
