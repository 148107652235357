import { FirmHierarchy } from '../../../../../types/domain/firm/FirmHierarchy';
import { FirmRelation } from '../../../../../types/domain/firm/FirmRelation';

const getNodeParents = (firm: FirmRelation, relations: FirmHierarchy): string[] => {
  const parentEdge = relations.find(edge => firm.parentId === edge.id);
  if (parentEdge && parentEdge.id !== firm.id) {
    const parent = relations.find(node => node.id === parentEdge.id);
    const ancestry = getNodeParents(parent!, relations);
    return [firm.displayName || firm.id, ...ancestry];
  }
  return [firm.displayName || firm.id];
};

/**
 * Given a FirmHierarchy (an array of related firms), parses
 * the relationship structure and returns the data in the format
 * used to display relationships inside of the TreeGrid component.
 *
 * @param relations - The FirmHierarchy object
 * @return The TreeGrid relationships data structure.
 */
export const createTreeGridRowsFromHierarchy = (
  relations: FirmHierarchy
): { hierarchy: string[]; node: FirmRelation }[] => {
  return relations.map(node => ({
    hierarchy: getNodeParents(node, relations).reverse(),
    node,
  }));
};
