import {
  Banner,
  Modal,
  ModalContent,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
  ServerErrors,
} from '@cmg/common';
import { identitySelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectModal, InjectedProps, show } from 'redux-modal';
import styled from 'styled-components/macro';

import { UUID } from '../../../../../types/common';
import {
  fetchFirmDescendants,
  selectFirm,
  selectFirmDescendants,
  selectFirmDisplayName,
  selectFirmParentError,
  selectFirmParentLoading,
  setFirmParent,
  setFirmParentDuckParts,
} from '../../ducks';
import FirmSelectField from '../firm-select/FirmSelect';

export const SFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SButtonWrapper = styled.div`
  > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const SFirmDescendantsList = styled.ul`
  margin-left: 25px;
`;

const mapStateToProps = state => ({
  firm: selectFirm(state),
  firmDisplayName: selectFirmDisplayName(state),
  firmDescendants: selectFirmDescendants(state),
  error: selectFirmParentError(state),
  loading: selectFirmParentLoading(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      clearErrors: setFirmParentDuckParts.actionCreators.reset,
      fetchFirmDescendants,
      setFirmParent,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = InjectedProps & StateProps & DispatchProps;

export const ChangeParentOrganizationModalComponent: React.FC<Props> = ({
  show,
  handleHide,
  firm,
  firmDisplayName,
  firmDescendants,
  error,
  loading,
  actions,
}) => {
  React.useEffect(() => {
    firm.id && actions.fetchFirmDescendants({ firmId: firm.id });
    actions.clearErrors();
  }, [actions, firm]);

  const [selectedParentId, setSelectedParentId] = React.useState<UUID | null>(null);

  return (
    <Modal title="Change Parent Organization" size="large" show={show} onHide={handleHide}>
      {firm.id && firm.relations && (
        <React.Fragment>
          <ModalContent>
            {error && (
              <Banner variant="error" showIcon={false}>
                <ServerErrors error={error} />
              </Banner>
            )}
            <FirmSelectField
              disabled={loading}
              excludedFirmIds={[firm.id!, firm.relations.parentId!]}
              onChange={value => {
                setSelectedParentId(value);
              }}
            />
            {firmDescendants.length > 0 && (
              <React.Fragment>
                <p>
                  You are about to change the organizational structure for {firmDisplayName!.value}{' '}
                  and {firmDescendants.length} descendant{firmDescendants.length > 1 && 's'}:
                </p>
                {firmDescendants.length > 0 && (
                  <SFirmDescendantsList>
                    {firmDescendants.map(({ displayName }, index) => (
                      <li key={index}>{displayName}</li>
                    ))}
                  </SFirmDescendantsList>
                )}
              </React.Fragment>
            )}
          </ModalContent>
          <ModalFooter>
            <SFooterWrapper>
              <PrimaryButton
                testId={
                  identitySelectors.rolodex.changeParentOrganizationUnlinkFromParentButton.testId
                }
                loading={loading}
                onClick={() =>
                  actions.setFirmParent({
                    firmId: firm.id!,
                    relationId: firm.relations!.id,
                    parentId: null,
                  })
                }
              >
                Unlink from Parent
              </PrimaryButton>
              <SButtonWrapper>
                <SecondaryButton disabled={loading} onClick={handleHide}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  testId={identitySelectors.rolodex.changeParentOrganizationSaveButton.testId}
                  loading={loading}
                  disabled={!selectedParentId}
                  onClick={() =>
                    actions.setFirmParent({
                      firmId: firm.id!,
                      relationId: firm.relations!.id,
                      parentId: selectedParentId,
                    })
                  }
                >
                  Save
                </PrimaryButton>
              </SButtonWrapper>
            </SFooterWrapper>
          </ModalFooter>
        </React.Fragment>
      )}
    </Modal>
  );
};

const ConnectedModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeParentOrganizationModalComponent);

export default connectModal({ name: 'ROLODEX/FIRM/CHANGE_PARENT_ORGANIZATION' })(ConnectedModal);

export const openChangeParentOrganizationModal = () =>
  show('ROLODEX/FIRM/CHANGE_PARENT_ORGANIZATION');
