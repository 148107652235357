import { Grid, Typography } from '@cmg/design-system';
import { VFC } from 'react';

export type FormGroupHeadingProps = {
  readonly title: string;
};

export const FormGroupHeading: VFC<FormGroupHeadingProps> = ({ title }) => {
  return (
    <Grid item xs={12} paddingY={1}>
      <Typography variant="h4">{title}</Typography>
    </Grid>
  );
};
