import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';

import { ConfigurationStatus } from '../../../../types/domain/user/constants';

type InvestorCoverageCheckResult = {
  canFetchInvestorCoverage: boolean;
};

export function useInvestorCoverageCheck(
  configurationStatus: ConfigurationStatus | undefined
): InvestorCoverageCheckResult {
  const { userPermissions } = useAuth();

  const canFetchInvestorCoverage =
    configurationStatus === ConfigurationStatus.CONFIGURED &&
    checkPermissions(userPermissions, [permissionsByEntity.CoveredAccountsCrm.READ]);

  return {
    canFetchInvestorCoverage,
  };
}
