import { apiTypes, Panel } from '@cmg/common';
import { identitySelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import CrmIntegrationList from './components/CrmIntegrationList';
import {
  fetchCrmIntegrationList,
  selectClients,
  selectClientsError,
  selectClientsLoading,
  selectClientsPagination,
} from './ducks';

const mapStateToProps = state => ({
  loading: selectClientsLoading(state),
  clients: selectClients(state),
  pagination: selectClientsPagination(state),
  error: selectClientsError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchCrmIntegrationList,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = RouteComponentProps & StateProps & DispatchProps;

export const RolodexCrmIntegrationListRouteComponent: React.FC<Props> = ({
  loading,
  clients,
  pagination,
  error,
  actions,
}) => {
  React.useEffect(() => {
    actions.fetchCrmIntegrationList({ page: 1, perPage: 25 });
  }, [actions]);

  const handleChangePage = (params: apiTypes.ListParams) => {
    actions.fetchCrmIntegrationList({ ...params });
  };

  const { totalCount } = pagination || { totalCount: 0 };

  return (
    <Panel testId={identitySelectors.rolodex.crmIntegrationListScreen.testId}>
      <Panel.Header
        title={`CRM Integrations (${totalCount})`}
        testId={identitySelectors.rolodex.crmIntegrationListHeader.testId}
      />
      <CrmIntegrationList
        loading={loading}
        clients={clients}
        pagination={pagination}
        error={!!error}
        onChangePage={handleChangePage}
      />
    </Panel>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolodexCrmIntegrationListRouteComponent);
