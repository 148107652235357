import { Banner, CenteredPage, PrimaryButton } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { LoginInfo } from '../../../../../types/domain/login-info/loginInfo';

const SButtonGroup = styled.section`
  display: flex;
  flex-direction: column;
`;

const SButtonContainer = styled.div`
  margin-top: 15px;
`;

type Props = {
  identityProviders: LoginInfo['externalProviders'];
};

/**
 * IdentityProviderLogin will display as a login view to the user whenever
 * their account is configured with more than one identity provider
 *
 * note: when this view is displayed local login is disabled.
 */
const IdentityProviderLogin: React.FC<Props> = ({ identityProviders }) => (
  <CenteredPage title="Log in to your account">
    <SButtonGroup role="group">
      {identityProviders.map(provider => (
        <SButtonContainer key={provider.identityProviderType}>
          <PrimaryButton href={provider.url} size="large" fullWidth variant="high-contrast">
            {`Log in with ${provider.displayName}`}
          </PrimaryButton>
        </SButtonContainer>
      ))}
    </SButtonGroup>
    {!identityProviders.length && (
      <Banner variant="error">
        There are no identity providers available for this account. Please contact your system
        administrator
      </Banner>
    )}
  </CenteredPage>
);

export default IdentityProviderLogin;
