import { Highlights } from '@cmg/design-system';
import { VFC } from 'react';
import { connect } from 'react-redux';

import { selectAccount } from '../../../account-detail/shared/ducks';
import { selectCrmIntegration } from '../../shared/ducks';

const mapStateToProps = state => ({
  account: selectAccount(state),
  crmIntegration: selectCrmIntegration(state),
});

export type Props = ReturnType<typeof mapStateToProps>;

export const CRMManagementDomainHighlightsComponent: VFC<Props> = ({ account, crmIntegration }) => {
  if (account && account.name) {
    return <Highlights title={account.name} />;
  } else if (crmIntegration && crmIntegration.name) {
    return <Highlights title={crmIntegration.name} />;
  }
  return null;
};

export default connect(mapStateToProps)(CRMManagementDomainHighlightsComponent);
