import { CrmCsvImportResult } from '@capital-markets-gateway/api-client-rolodex';
import { apiTypes, reduxUtil } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { AnyAction, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import rolodexApiClient, {
  CustomerAdminBulkImportFromCsvFileResponse,
  SysAdminBulkImportFromCsvFileResponse,
} from '../../../../common/api/rolodexApiClient';
import { RootState } from '../../../../common/redux/rootReducer';
import { selectSelfSubdomain } from '../../../shared/ducks';
import {
  CRMFilesUploadFormValues,
  ProcessedSoFar,
} from './grid-content/CRMFilesUploadSection.model';

const createReducer = reduxUtil.createReducer;

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  BULK_UPLOAD_CSV_FILE_REQUEST = 'crm-management/crm-files-upload/BULK_UPLOAD_CSV_FILE_REQUEST',
  BULK_UPLOAD_CSV_FILE_SUCCESS = 'crm-management/crm-files-upload/BULK_UPLOAD_CSV_FILE_SUCCESS',
  BULK_UPLOAD_CSV_FILE_FAILURE = 'crm-management/crm-files-upload/BULK_UPLOAD_CSV_FILE_FAILURE',
  BULK_UPLOAD_CSV_FILE_MORE_TO_UPLOAD = 'crm-management/crm-files-upload/BULK_UPLOAD_CSV_FILE_MORE_TO_UPLOAD',
  RESET_STATE = 'crm-management/crm-files-uploads/RESET_STATE',
}

/**
 * ACTION CREATORS
 */
export const resetState = () => ({
  type: ActionTypes.RESET_STATE,
});

export const bulkImportFromCsvFile = (
  accountSubdomain: string,
  params: CRMFilesUploadFormValues
) => ({
  type: ActionTypes.BULK_UPLOAD_CSV_FILE_REQUEST,
  accountSubdomain,
  payload: { ...params },
});

export const bulkImportFromCsvFileSuccess = (params: CrmCsvImportResult['progress']) => ({
  type: ActionTypes.BULK_UPLOAD_CSV_FILE_SUCCESS,
  payload: {
    ...params,
  },
});

export const bulkImportFromCsvFileFailure = ({ error }) => ({
  type: ActionTypes.BULK_UPLOAD_CSV_FILE_FAILURE,
  payload: {
    error,
  },
});

export const stillMoreToUpload = (params: ProcessedSoFar) => ({
  type: ActionTypes.BULK_UPLOAD_CSV_FILE_MORE_TO_UPLOAD,
  payload: {
    ...params,
  },
});

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.RESET_STATE]: ReturnType<typeof resetState>;
  [ActionTypes.BULK_UPLOAD_CSV_FILE_REQUEST]: ReturnType<typeof bulkImportFromCsvFile>;
  [ActionTypes.BULK_UPLOAD_CSV_FILE_SUCCESS]: ReturnType<typeof bulkImportFromCsvFileSuccess>;
  [ActionTypes.BULK_UPLOAD_CSV_FILE_FAILURE]: ReturnType<typeof bulkImportFromCsvFileFailure>;
  [ActionTypes.BULK_UPLOAD_CSV_FILE_MORE_TO_UPLOAD]: ReturnType<typeof stillMoreToUpload>;
};

/**
 * REDUCERS
 */
export type ReducerState = {
  error: apiTypes.GenericServerError | null;
  uploading: boolean;
  processedSoFar: ProcessedSoFar;
  progress: CrmCsvImportResult['progress'];
};

export const initialState = {
  error: null,
  uploading: false,
  processedSoFar: {
    total: 0,
    moreToUpload: false,
  },
  progress: {
    rowsProcessed: 0,
    totalRows: 0,
    hasNext: false,
    nextSkip: 0,
  },
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.BULK_UPLOAD_CSV_FILE_REQUEST]: () => null,
  [ActionTypes.BULK_UPLOAD_CSV_FILE_SUCCESS]: () => null,
  [ActionTypes.BULK_UPLOAD_CSV_FILE_FAILURE]: (_curState, { payload }) => payload.error,
});

const uploadingReducer = createReducer<ReducerState['uploading'], Actions>(initialState.uploading, {
  [ActionTypes.BULK_UPLOAD_CSV_FILE_REQUEST]: () => true,
  [ActionTypes.BULK_UPLOAD_CSV_FILE_SUCCESS]: () => false,
  [ActionTypes.BULK_UPLOAD_CSV_FILE_FAILURE]: () => false,
});

const uploadSuccessfulReducer = createReducer<ReducerState['progress'], Actions>(
  initialState.progress,
  {
    [ActionTypes.BULK_UPLOAD_CSV_FILE_SUCCESS]: (_curState, { payload }) => payload,
  }
);

const moreToUploadReducer = createReducer<ReducerState['processedSoFar'], Actions>(
  initialState.processedSoFar,
  {
    [ActionTypes.BULK_UPLOAD_CSV_FILE_MORE_TO_UPLOAD]: (_curState, { payload }) => payload,
  }
);

const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_STATE]: () => ({ ...initialState }),
});

const combinedReducers = combineReducers<ReducerState>({
  error: errorReducer,
  uploading: uploadingReducer,
  progress: uploadSuccessfulReducer,
  processedSoFar: moreToUploadReducer,
});

// Combines our individual slice reducers and the cross slice reducer.
export default function duckReducer(state: ReducerState = initialState, action: AnyAction) {
  const intermediateState = combinedReducers(state, action);
  return crossSliceReducer(intermediateState, action);
}

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.bulkImportFromCsvFile;
export const selectProgress = state => selectState(state).progress;
export const selectError = state => selectState(state).error;
export const selectUploading = state => selectState(state).uploading;
export const selectProcessedSoFar = state => selectState(state).processedSoFar;

/**
 * SAGAS
 */
export function* bulkImportFromCsvFileSaga({
  accountSubdomain,
  payload,
}: Actions[ActionTypes.BULK_UPLOAD_CSV_FILE_REQUEST]): SagaIterator {
  let response: CustomerAdminBulkImportFromCsvFileResponse | SysAdminBulkImportFromCsvFileResponse;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const isSysAdmin = selfSubdomain === 'system';
  const apiRequest = isSysAdmin
    ? rolodexApiClient.sysAdminBulkImportRequest
    : rolodexApiClient.customerAdminBulkImportRequest;

  response = yield call(apiRequest, accountSubdomain, payload);

  if (response.ok) {
    let {
      data: { progress },
    } = response;
    let recordsProcessedSoFar = 0;

    while (progress.hasNext) {
      recordsProcessedSoFar += progress.rowsProcessed;
      yield put(stillMoreToUpload({ moreToUpload: true, total: recordsProcessedSoFar }));
      yield put(
        bulkImportFromCsvFileSuccess({
          ...progress,
        })
      );

      const nextPayload =
        progress.nextSkip && progress.nextSkip > 0
          ? { ...payload, skip: progress.nextSkip }
          : payload;

      response = yield call(apiRequest, accountSubdomain, nextPayload);

      if (response.ok) {
        progress = response.data.progress;
      }
    }

    recordsProcessedSoFar += progress.rowsProcessed;
    yield put(stillMoreToUpload({ moreToUpload: false, total: recordsProcessedSoFar }));
    yield put(
      bulkImportFromCsvFileSuccess({
        ...progress,
      })
    );
    SnackbarManager.success(`${payload.file?.name} successfully uploaded.`);
  } else if (response.data && response.data.error) {
    yield put(bulkImportFromCsvFileFailure({ error: response.data.error }));
  } else {
    yield put(bulkImportFromCsvFileFailure({ error: `Request Timed Out. Please Try Again.` }));
  }
}

export function* bulkCRMImportFromCsvFileCreateSaga() {
  yield takeLatest<Actions[ActionTypes.BULK_UPLOAD_CSV_FILE_REQUEST]>(
    ActionTypes.BULK_UPLOAD_CSV_FILE_REQUEST,
    bulkImportFromCsvFileSaga
  );
}
