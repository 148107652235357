import { TextField } from '@cmg/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import { Fragment, useEffect, VFC } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { bindActionCreators } from 'redux';

import usePrevious from '../../../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../../../design-system/alert/ServerErrorAlert';
import { ConfirmationDialog } from '../../../../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { FormDialogContent } from '../../../../../../../design-system/dialog/FormDialogContent';
import { FormDialogContentItem } from '../../../../../../../design-system/dialog/FormDialogContentItem';
import { AzureAdConfigurationUpdate } from '../../../../../../../types/domain/identity-provider/configurations/azureAd';
import {
  IdentityProviderType,
  identityProviderTypeDisplay,
} from '../../../../../../../types/domain/identity-provider/constants';
import {
  resetState,
  selectAzureAdConfigError,
  selectAzureAdConfigSubmitting,
  updateAzureAdConfig,
} from '../../../../../../account-detail/security/identity-providers/ducks';
import { AzureAdConfigurationUpdateSchema } from '../utils';

const mapStateToProps = state => ({
  error: selectAzureAdConfigError(state),
  isSubmitting: selectAzureAdConfigSubmitting(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateAzureAdConfig,
      resetState,
    },
    dispatch
  ),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;

export type Props = StateProps &
  DispatchProps & {
    isOpened: Readonly<boolean>;
    onClose: () => void;
  };

export const ConfigureAzureProviderDialogComponent: VFC<Props> = ({
  isOpened,
  isSubmitting,
  actions: { resetState, updateAzureAdConfig },
  error,
  onClose,
}) => {
  const history = useHistory();

  const formik = useFormik<AzureAdConfigurationUpdate>({
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: AzureAdConfigurationUpdateSchema,
    initialValues: {
      name: '',
      configuration: {
        clientId: '',
        tenantId: '',
        authorizedGroupId: '',
      },
      enabled: false,
    },
    onSubmit: val => {
      updateAzureAdConfig({
        history,
        redirectToDetailsPage: true,
        saveAndTest: false,
        configuration: {
          ...val,
          configuration: {
            ...val.configuration,
            authorizedGroupId: val.configuration.authorizedGroupId || null, // the end point requires null if no value is provided
          },
        },
      });
    },
  });

  const { resetForm } = formik;
  const previousIsOpened = usePrevious(isOpened);
  useEffect(() => {
    if (!isOpened && previousIsOpened) {
      resetForm();
      resetState();
    }
  }, [isOpened, previousIsOpened, resetForm, resetState]);

  return (
    <FormikProvider value={formik}>
      <ConfirmationDialog
        maxWidth="xs"
        title={`Configure ${identityProviderTypeDisplay[IdentityProviderType.AZURE_AD_OPEN_ID]}`}
        isOpen={isOpened}
        isLoading={isSubmitting}
        cancelButtonLabel="Cancel"
        onCancel={onClose}
        submitButtonLabel="Add Provider"
        submitButtonColor="primary"
        onSubmit={() => formik.handleSubmit()}
        content={
          <Form noValidate={true}>
            <FormDialogContent
              error={error && <ServerErrorAlert title="Error" error={error} />}
              items={
                <Fragment>
                  <FormDialogContentItem
                    value={
                      <TextField
                        required
                        label="Name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <TextField
                        required
                        label="Client ID"
                        name="configuration.clientId"
                        value={formik.values.configuration.clientId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.configuration?.clientId &&
                          Boolean(formik.errors.configuration?.clientId)
                        }
                        helperText={
                          formik.touched.configuration?.clientId &&
                          formik.errors.configuration?.clientId
                        }
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <TextField
                        required
                        label="Azure Tenant ID"
                        name="configuration.tenantId"
                        value={formik.values.configuration.tenantId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.configuration?.tenantId &&
                          Boolean(formik.errors.configuration?.tenantId)
                        }
                        helperText={
                          formik.touched.configuration?.tenantId &&
                          formik.errors.configuration?.tenantId
                        }
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <TextField
                        label="Authorized Group ID"
                        name="configuration.authorizedGroupId"
                        value={formik.values.configuration.authorizedGroupId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.configuration?.authorizedGroupId &&
                          Boolean(formik.errors.configuration?.authorizedGroupId)
                        }
                        helperText={
                          formik.touched.configuration?.authorizedGroupId &&
                          formik.errors.configuration?.authorizedGroupId
                        }
                      />
                    }
                  />
                </Fragment>
              }
            />
          </Form>
        }
      />
    </FormikProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureAzureProviderDialogComponent);
