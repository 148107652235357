import {
  Banner,
  DiffView,
  Modal,
  ModalContent,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
  ServerErrors,
} from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import { UUID } from '../../../../../types/common';
import {
  disconnectFactSetFirmFromCMGFirm,
  getDisconnectFactSetFirmFromCMGFirmPreviewDiff,
  selectDisconnectFactSetFirmPreviewDiff,
  selectDisconnectFromFactSetError,
  selectDisconnectFromFactSetLoading,
} from '../../ducks';
import { SButtonWrapper } from './DisconnectWithFactSetModal.styles';

const mapStateToProps = state => ({
  isDisconnecting: selectDisconnectFromFactSetLoading(state),
  disconnectError: selectDisconnectFromFactSetError(state),
  disconnectFirmPreviewDiff: selectDisconnectFactSetFirmPreviewDiff(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getDisconnectFactSetFirmFromCMGFirmPreviewDiff,
      disconnectFactSetFirmFromCMGFirm,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  /** The ID of the CMG Firm that is to be disconnected from FactSet. */
  firmId: UUID;
};
export type Props = OwnProps & InjectedProps & StateProps & DispatchProps;

/**
 * Displays the Disconnect With FactSet Modal experience.  A diff of the CMG
 * Firm as it exists (in JSON) when it's connected to FactSet against the CMG
 * Firm as it will look when it's disconnected is displayed - the user can
 * then confirm or cancel the disconnection.
 */
export const DisconnectWithFactSetModalComponent: React.FC<Props> = ({
  show,
  handleHide,
  firmId,
  disconnectFirmPreviewDiff,
  isDisconnecting,
  disconnectError,
  actions,
}) => {
  React.useEffect(() => {
    show && actions.getDisconnectFactSetFirmFromCMGFirmPreviewDiff({ firmId });
  }, [actions, firmId, show]);

  return (
    <Modal
      title="Disconnect from FactSet Organization"
      size="large"
      show={show}
      onHide={handleHide}
    >
      {disconnectFirmPreviewDiff && (
        <React.Fragment>
          <ModalContent>
            <DiffView
              prevVersion={disconnectFirmPreviewDiff.from}
              nextVersion={disconnectFirmPreviewDiff.to}
            />
          </ModalContent>
          <ModalFooter>
            {disconnectError && (
              <Banner variant="error" showIcon={false}>
                <ServerErrors error={disconnectError} />
              </Banner>
            )}
            <SButtonWrapper>
              <SecondaryButton onClick={handleHide}>Cancel</SecondaryButton>
              <PrimaryButton
                loading={isDisconnecting}
                onClick={() => {
                  actions.disconnectFactSetFirmFromCMGFirm({
                    firmId: firmId,
                  });
                }}
              >
                Disconnect from FactSet
              </PrimaryButton>
            </SButtonWrapper>
          </ModalFooter>
        </React.Fragment>
      )}
    </Modal>
  );
};

const ConnectedDisconnectWithFactSetModalComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(DisconnectWithFactSetModalComponent);

const modalName = 'ROLODEX/FIRM/DISCONNECT_WITH_FACTSET';

export default connectModal({ name: modalName })(ConnectedDisconnectWithFactSetModalComponent);

export const openDisconnectWithFactSetModal = () => show(modalName);
export const closeDisconnectWithFactSetModal = () => hide(modalName);
