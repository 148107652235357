import { fileUtil } from '@cmg/common';
import { TextField } from '@cmg/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import { ChangeEvent, Fragment, useCallback, useEffect, useState, VFC } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { bindActionCreators } from 'redux';

import usePrevious from '../../../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../../../design-system/alert/ServerErrorAlert';
import { ConfirmationDialog } from '../../../../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { FormDialogContent } from '../../../../../../../design-system/dialog/FormDialogContent';
import { FormDialogContentItem } from '../../../../../../../design-system/dialog/FormDialogContentItem';
import { Saml2PConfigurationUpdate } from '../../../../../../../types/domain/identity-provider/configurations/saml2p';
import {
  IdentityProviderType,
  identityProviderTypeDisplay,
} from '../../../../../../../types/domain/identity-provider/constants';
import {
  resetState,
  selectSamlConfigError,
  selectSamlConfigSubmitting,
  updateSamlConfig,
} from '../../../../../../account-detail/security/identity-providers/ducks';
import { SamlUpdateSchema } from './../utils';

const mapStateToProps = state => ({
  error: selectSamlConfigError(state),
  isSubmitting: selectSamlConfigSubmitting(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateSamlConfig,
      resetState,
    },
    dispatch
  ),
});

export type SamlProviderFormValues = Saml2PConfigurationUpdate & { certificateFile: File | null };
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;

export type Props = StateProps &
  DispatchProps & {
    isOpened: Readonly<boolean>;
    onClose: () => void;
  };

export const ConfigureSamlProviderDialogComponent: VFC<Props> = ({
  isOpened,
  isSubmitting,
  actions: { resetState, updateSamlConfig },
  error,
  onClose,
}) => {
  const history = useHistory();

  const [isUploadingCertificate, setIsUploadingCertificate] = useState(false);

  const formik = useFormik<SamlProviderFormValues>({
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: SamlUpdateSchema,
    initialValues: {
      name: '',
      certificateFile: null,
      configuration: {
        entityId: '',
        signOnUrl: '',
        logoutUrl: '',
        certificate: '',
      },
      enabled: false,
    },
    onSubmit: configuration => {
      updateSamlConfig({
        history,
        redirectToDetailsPage: true,
        saveAndTest: false,
        configuration: {
          enabled: configuration.enabled,
          name: configuration.name,
          configuration: {
            ...configuration.configuration,
            logoutUrl: configuration.configuration.logoutUrl || null, // the end point requires null if no value is provided
          },
        },
      });
    },
  });

  const { setFieldValue, resetForm } = formik;
  const changeCertificate = useCallback(
    async (file: File | null) => {
      setIsUploadingCertificate(true);

      const base64String = file ? await fileUtil.fileToBase64(file) : '';

      setFieldValue('certificateFile', file);
      setFieldValue('configuration.certificate', base64String);

      setIsUploadingCertificate(false);
    },
    [setFieldValue]
  );

  const previousIsOpened = usePrevious(isOpened);
  useEffect(() => {
    if (!isOpened && previousIsOpened) {
      resetForm();
      resetState();
    }
  }, [isOpened, previousIsOpened, resetForm, resetState]);

  return (
    <FormikProvider value={formik}>
      <ConfirmationDialog
        maxWidth="xs"
        title={`Configure ${identityProviderTypeDisplay[IdentityProviderType.SAML2P]}`}
        isOpen={isOpened}
        isLoading={isSubmitting || isUploadingCertificate}
        cancelButtonLabel="Cancel"
        onCancel={onClose}
        submitButtonLabel="Add Provider"
        submitButtonColor="primary"
        onSubmit={() => formik.handleSubmit()}
        content={
          <Form>
            <FormDialogContent
              error={error && <ServerErrorAlert title="Error" error={error} />}
              items={
                <Fragment>
                  <FormDialogContentItem
                    value={
                      <TextField
                        required
                        label="Name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <TextField
                        required
                        label="Entity ID"
                        name="configuration.entityId"
                        value={formik.values.configuration.entityId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.configuration?.entityId &&
                          Boolean(formik.errors.configuration?.entityId)
                        }
                        helperText={
                          formik.touched.configuration?.entityId &&
                          formik.errors.configuration?.entityId
                        }
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <TextField
                        required
                        label="Single SignOn Service URL"
                        name="configuration.signOnUrl"
                        value={formik.values.configuration.signOnUrl}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.configuration?.signOnUrl &&
                          Boolean(formik.errors.configuration?.signOnUrl)
                        }
                        helperText={
                          formik.touched.configuration?.signOnUrl &&
                          formik.errors.configuration?.signOnUrl
                        }
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <TextField
                        label="Single Logout Service URL"
                        name="configuration.logoutUrl"
                        value={formik.values.configuration.logoutUrl}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.configuration?.logoutUrl &&
                          Boolean(formik.errors.configuration?.logoutUrl)
                        }
                        helperText={
                          formik.touched.configuration?.logoutUrl &&
                          formik.errors.configuration?.logoutUrl
                        }
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <TextField
                        required
                        type="file"
                        label="Certificate"
                        name="certificateFile"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          const file = e.target?.files?.length ? e.target.files[0] : null;
                          void changeCertificate(file);
                        }}
                        error={
                          formik.touched.certificateFile && Boolean(formik.errors.certificateFile)
                        }
                        helperText={formik.touched.certificateFile && formik.errors.certificateFile}
                      />
                    }
                  />
                </Fragment>
              }
            />
          </Form>
        }
      />
    </FormikProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureSamlProviderDialogComponent);
