import { FactSetFirmHierarchy } from '../../../../../types/domain/firm/FactSetFirmHierarchy';
import { FactSetFirmRelation } from '../../../../../types/domain/firm/FactSetFirmRelation';

const getFactSetNodeParents = (
  firm: FactSetFirmRelation,
  relations: FactSetFirmHierarchy
): string[] => {
  const parentEdge = relations.find(edge => firm.factSetParentFirmId === edge.factSetFirmId);
  if (parentEdge && parentEdge.factSetParentFirmId !== firm.factSetFirmId) {
    const parent = relations.find(node => node.factSetFirmId === parentEdge.factSetFirmId);
    const ancestry = getFactSetNodeParents(parent!, relations);
    return [firm.properName || firm.factSetFirmId, ...ancestry];
  }
  return [firm.properName || firm.factSetFirmId];
};

/**
 * Given a FactSetFirmHierarchy (an array of related firms), parses
 * the relationship structure and returns the data in the format
 * used to display relationships inside of the TreeGrid component.
 *
 * @param relations - The FactSetFirmHierarchy object
 * @return The TreeGrid relationships data structure.
 */
export const createTreeGridRowsFromFactSetFirmHierarchy = (
  relations: FactSetFirmHierarchy
): { hierarchy: string[]; node: FactSetFirmRelation }[] => {
  return relations.map(node => ({
    hierarchy: getFactSetNodeParents(node, relations).reverse(),
    node,
  }));
};
