import { apiTypes, Banner, DataGrid, Icon, MultiSelect, Select, TextInput } from '@cmg/common';
import {
  accountListAccountTypeSelectSelector,
  accountListLicenseTypeSelectSelector,
  accountListSearchInputSelector,
  accountListStatusTypeSelectSelector,
  accountListTraitsSelectSelector,
} from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

import { AccountFilters } from '../../../../common/api/systemManagementApiClient';
import { Account } from '../../../../types/domain/account/account';
import {
  accountStatusOptions,
  accountTypeOptions,
  licenseTypeOptions,
} from '../../../../types/domain/account/constants';
import { traitOptionsAll } from '../../../../types/domain/trait/constants';
import accountListGridColumns from './AccountListGridColumns';

export const SFilters = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 0;

  ${({ theme }) => theme.mediaQuery.largeUp} {
    flex-direction: row;
  }
`;

export const SField = styled.div`
  margin: 0 10px 10px 0;
  width: 100%;

  &:not(:first-child) {
    width: 50%;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const SIconWrapper = styled.div`
  margin-left: 10px;
`;

type Props = {
  loading: boolean;
  accounts: Account[];
  pagination: apiTypes.Pagination;
  filters: AccountFilters;
  error: boolean;
  onChangeFilters: (params: AccountFilters, debounce?: boolean) => void;
  onChangePage: (params: apiTypes.ListParams) => void;
};

/**
 * Displays CMG Accounts in a table view
 */
const AccountList: React.FC<Props> = ({
  accounts,
  error,
  pagination,
  filters,
  loading,
  onChangePage,
  onChangeFilters,
}) => {
  const handleTextChange = searchText => onChangeFilters({ ...filters, searchText }, true);
  const handleStatusChange = status => onChangeFilters({ ...filters, status });
  const handleAccountTypeChange = accountType => onChangeFilters({ ...filters, accountType });
  const handleLicenseTypeChange = licenseType => onChangeFilters({ ...filters, licenseType });
  // if no traits, undefined so it doesn't show up in query string
  const handleTraitsChange = traits => onChangeFilters({ ...filters, traits: traits || undefined });

  return (
    <React.Fragment>
      {error && <Banner variant="error">Could not load accounts...</Banner>}

      <DataGrid<Account>
        renderFilters={() => (
          <SFilters>
            <SField>
              <TextInput
                data-test-id={accountListSearchInputSelector.testId}
                onChange={handleTextChange}
                placeholder="Filter by Name, Subdomain..."
                prefix={
                  <SIconWrapper>
                    <Icon name="search" />
                  </SIconWrapper>
                }
              />
            </SField>
            <SField>
              <Select
                data-test-id={accountListAccountTypeSelectSelector.testId}
                onChange={handleAccountTypeChange}
                placeholder="Filter by Account Type..."
                options={accountTypeOptions}
              />
            </SField>
            <SField>
              <Select
                data-test-id={accountListLicenseTypeSelectSelector.testId}
                onChange={handleLicenseTypeChange}
                placeholder="Filter by License Type..."
                options={licenseTypeOptions}
              />
            </SField>
            <SField>
              <MultiSelect
                data-test-id={accountListTraitsSelectSelector.testId}
                onChange={handleTraitsChange}
                placeholder="Filter by Traits..."
                value={filters.traits}
                options={traitOptionsAll}
              />
            </SField>
            <SField>
              <Select
                data-test-id={accountListStatusTypeSelectSelector.testId}
                onChange={handleStatusChange}
                value={filters.status}
                placeholder="Filter by Status..."
                options={accountStatusOptions}
              />
            </SField>
          </SFilters>
        )}
        extended={{ withMargin: false }}
        columns={accountListGridColumns}
        rows={accounts}
        resizeBy="grid"
        totalPages={pagination.totalPages}
        pagination={{
          page: pagination.activePage,
          perPage: pagination.perPage,
        }}
        onPaginationChange={({ page, perPage, orderField, orderDirection }) => {
          const params = {
            page,
            perPage,
            orderField,
            orderDirection:
              orderDirection === 'asc' ? apiTypes.SortDirection.ASC : apiTypes.SortDirection.DESC,
          };

          onChangePage(params);
        }}
        gridOptions={{
          suppressCellSelection: true,
          suppressRowClickSelection: true,
          overlayNoRowsTemplate: 'No accounts found',
        }}
        loading={loading}
      />
    </React.Fragment>
  );
};

export default AccountList;
