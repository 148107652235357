import { Icon, PrimaryButton, TextInputField } from '@cmg/common';
import { Form, Formik } from 'formik';
import React, { FC, ReactNode } from 'react';
import * as yup from 'yup';

import type {
  SelectDomainFormOnSubmitFn,
  SelectDomainFormValidateFn,
} from './SelectDomainForm.model';
import { SDomainMaskLabel, STopSection } from './SelectDomainForm.styles';
import SelectDomainFormError from './SelectDomainFormError';

const initialValues = { domain: '' };
export const SelectDomainSchema = yup.object().shape({
  domain: yup.string().required('Your CMG Subdomain is required').max(20),
});

type Props = {
  readonly redirecting?: boolean;
  readonly suffixMask: ReactNode;
  readonly onSubmit: SelectDomainFormOnSubmitFn;
  readonly validate?: SelectDomainFormValidateFn;
};

/**
 * Render a Select Domain Form
 */
export const SelectDomainForm: FC<Props> = props => {
  const { redirecting, suffixMask, validate, onSubmit } = props;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      initialErrors={false}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={SelectDomainSchema}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form role="form">
          <STopSection>
            <TextInputField
              testId="domain-text"
              name="domain"
              prefix={<Icon name="link" />}
              suffix={<SDomainMaskLabel>{suffixMask}</SDomainMaskLabel>}
              type="text"
              placeholder="your-subdomain"
              size="large"
              maxLength={20}
              disabled={redirecting}
              autoFocus
              fullWidth
            />
            <SelectDomainFormError />
          </STopSection>
          <PrimaryButton
            variant="high-contrast"
            testId="submit"
            type="submit"
            size="large"
            fullWidth
            loading={isSubmitting || redirecting}
            disabled={redirecting}
            loadingLabel={redirecting === true ? 'Redirecting...' : undefined}
          >
            Continue
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  );
};

export default SelectDomainForm;
