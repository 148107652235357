import { Panel } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createTreeGridRowsFromFactSetFirmHierarchy } from '../../../../../../common/components/lists/factset-firm-hierarchy-tree-grid/utils/hierarchyUtils';
import SimpleFirmHierarchyTreeGrid from '../../../../../../common/components/lists/simple-firm-hierarchy-tree-grid/SimpleFirmHierarchyTreeGrid';
import { createTreeGridRowsFromHierarchy } from '../../../../../../common/components/lists/simple-firm-hierarchy-tree-grid/utils/hierarchyUtils';
import { UUID } from '../../../../../../types/common';
import { FirmDataSource } from '../../../../../../types/domain/firm/constants';
import { FactSetFirmHierarchy } from '../../../../../../types/domain/firm/FactSetFirmHierarchy';
import { FactSetFirmRelation } from '../../../../../../types/domain/firm/FactSetFirmRelation';
import { FirmHierarchy } from '../../../../../../types/domain/firm/FirmHierarchy';
import { FirmRelation } from '../../../../../../types/domain/firm/FirmRelation';
import { selectMetadata } from '../../../../shared/ducks';
import {
  fetchMatchFirmHierarchy,
  selectMatchFirmHierarchy,
  selectMatchFirmHierarchyError,
  selectMatchFirmHierarchyLoading,
} from '../../../ducks';

const mapStateToProps = state => ({
  loading: selectMatchFirmHierarchyLoading(state),
  error: selectMatchFirmHierarchyError(state),
  hierarchy: selectMatchFirmHierarchy(state),
  metadata: selectMetadata(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchMatchFirmHierarchy,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  /** The ID of the Investor Firm to display the hierarchy for */
  matchFirmId: UUID | string;
  /** The Investor Firm type (either CMG or FactSet) */
  matchFirmType: FirmDataSource;
  /** Callback prop triggered when a firm name is clicked. */
  onClickFirmName?: (row: FirmRelation | FactSetFirmRelation) => void;
};
export type Props = OwnProps & StateProps & DispatchProps;

/**
 * Renders the firm hierarchy displayed on the Match Details
 * UI.
 */
export const MatchFirmHierarchyComponent: React.FC<Props> = ({
  matchFirmId,
  matchFirmType,
  hierarchy,
  metadata,
  loading,
  error,
  onClickFirmName,
  actions,
}) => {
  React.useEffect(() => {
    actions.fetchMatchFirmHierarchy({ id: matchFirmId, type: matchFirmType });
  }, [actions, matchFirmId, matchFirmType]);

  // We want to expand tree grid rows from the top of the tree down
  // to the match firm (the firm whose ID matches props.matchFirmId)
  let firmRow: { hierarchy: string[]; node: FirmRelation | FactSetFirmRelation } | undefined;
  if (hierarchy) {
    if (matchFirmType === FirmDataSource.CMG || matchFirmType === FirmDataSource.CUSTOMER_CREATED) {
      const rows = createTreeGridRowsFromHierarchy(hierarchy as FirmHierarchy);
      firmRow = rows.find(({ node }) => node.id === matchFirmId);
    }
    if (matchFirmType === FirmDataSource.FACT_SET) {
      const rows = createTreeGridRowsFromFactSetFirmHierarchy(hierarchy as FactSetFirmHierarchy);
      firmRow = rows.find(({ node }) => node.factSetFirmId === matchFirmId);
    }
  }
  const expandedGroups = firmRow ? firmRow.hierarchy.slice(0, -1) : [];

  return (
    <Panel>
      <Panel.Header title="Organization Hierarchy" />
      <SimpleFirmHierarchyTreeGrid
        error={error}
        loading={loading}
        hierarchy={hierarchy}
        metadata={metadata}
        activeFirmId={matchFirmId}
        isFactSetFirmHierarchy={matchFirmType === FirmDataSource.FACT_SET}
        expandedGroups={expandedGroups}
        isCollapsible={true}
        onClickFirmName={onClickFirmName}
        showHeader
      />
    </Panel>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchFirmHierarchyComponent);
