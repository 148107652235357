import { DangerButton, SuccessButton } from '@cmg/common';
import React from 'react';

import { RecordStatus } from '../../../../../types/domain/record-status/RecordStatus';

/**
 * Given a firm's current entity status, displays a button
 * with the opposite status.  A toggle button of sorts.
 *
 * @param recordStatus - the entity status to display
 * @param disabled - whether the button is disabled or not.
 * @param onClick - callback to be triggered when the button is clicked.
 */
export const RecordStatusButton: React.FC<{
  recordStatus: RecordStatus;
  disabled: boolean;
  onClick: () => void;
}> = ({ recordStatus, ...restProps }) => {
  return recordStatus === RecordStatus.DELETED ? (
    <SuccessButton {...restProps}>Restore Organization</SuccessButton>
  ) : (
    <DangerButton {...restProps}>Delete Organization</DangerButton>
  );
};
