import { OpenIdConnectMetadata } from '@capital-markets-gateway/api-client-identity';
import { TextField } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { FormFieldLayout } from '../../../../../../design-system/body-sections/form/FormFieldLayout';
import { FormLayout } from '../../../../../../design-system/body-sections/form/FormLayout';
import { OpenIdConnectConfigurationUpdate } from '../../../../../../types/domain/identity-provider/configurations/openIdConnect';

export type Props = Readonly<{
  readonly metadata: OpenIdConnectMetadata | null;
}>;

export const ProviderDetailsSectionEdit: FC<Props> = ({ metadata }) => {
  const formik = useFormikContext<OpenIdConnectConfigurationUpdate>();

  return (
    <FormLayout>
      <FormFieldLayout>
        <TextField disabled label="Redirect URI" value={metadata?.redirectUri} />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField disabled label="Logout URL" value={metadata?.logoutUrl} />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          disabled
          label="Post Logout Redirect URI"
          value={metadata?.postLogoutRedirectUri}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          required
          label="Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          required
          label="Client ID"
          name="configuration.clientId"
          value={formik.values.configuration.clientId}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.configuration?.clientId)}
          helperText={formik.errors.configuration?.clientId}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          required
          label="Authority"
          name="configuration.authority"
          value={formik.values.configuration.authority}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.configuration?.authority)}
          helperText={formik.errors.configuration?.authority}
        />
      </FormFieldLayout>
    </FormLayout>
  );
};
