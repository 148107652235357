import { TableCell, TableHead, TableRow } from '@cmg/design-system';
import { FC } from 'react';

import { PermissionDetail, UndefinedPermissionDetail } from '../../../../../shared/auth/constants';
import { ToggleRowCells } from '../../shared/ToggleRowCells';

export type Props = Readonly<{
  readonly availablePermissions: (PermissionDetail | UndefinedPermissionDetail)[];
  readonly onPermissionsAdd: (permissions: string[]) => void;
  readonly onPermissionsRemove: (permissions: string[]) => void;
}>;

export const ApiKeyPermissionsTableHeader: FC<Props> = ({
  availablePermissions,
  onPermissionsAdd,
  onPermissionsRemove,
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Product/Permission Group</TableCell>
        <TableCell>Description</TableCell>
        <ToggleRowCells
          isInGroupHeader={false}
          groupName="Product/Permission Group"
          availablePermissions={availablePermissions}
          onAdd={onPermissionsAdd}
          onRemove={onPermissionsRemove}
        />
      </TableRow>
    </TableHead>
  );
};
