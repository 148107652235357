import { Chip, Stack } from '@cmg/design-system';
import { VFC } from 'react';

import { DescriptionList } from '../../../../../design-system/body-sections/description-list/DescriptionList';
import { DescriptionListItem } from '../../../../../design-system/body-sections/description-list/DescriptionListItem';
import { LimitedTrait } from '../../../../../types/domain/trait/LimitedTrait';

export type Props = {
  accountTraits: readonly LimitedTrait[];
};

export const ProductTraitsSectionReadonly: VFC<Props> = ({ accountTraits }) => {
  return (
    <DescriptionList>
      <DescriptionListItem
        label="Existing Traits"
        value={
          accountTraits.length ? (
            <Stack direction="row" gap={1} flexWrap="wrap">
              {accountTraits.map(trait => (
                <Chip key={trait.code} label={trait.name} />
              ))}
            </Stack>
          ) : (
            'None'
          )
        }
      />
    </DescriptionList>
  );
};
