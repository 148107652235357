import { Account } from '../../../../../../types/domain/account/account';

/**
 * returns how many active identity providers exist on the account
 * active = enabled and verified
 * @returns {number}
 */
export const activeIdentityProviderCount = (account: Account | null) => {
  if (!!account && !!account.identityProviders) {
    return account.identityProviders.filter(idp => idp.enabled && idp.verified).length;
  }

  return 0;
};
