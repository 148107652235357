import { useContext } from 'react';

import { AppSettings } from '../appSettings';
import { AppSettingsContext } from './AppSettingsContext';

export function useAppSettings(): AppSettings {
  const appSettings = useContext(AppSettingsContext);
  if (appSettings === undefined) {
    throw new Error('useAppSettings must be used within a AppSettingsContextProvider');
  }

  return appSettings;
}
