import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { useCallback, useMemo } from 'react';

import { useFeatureToggles } from '../../../../../common/config/context/useFeatureToggles';
import { CRMManagementRouteName } from '../CRMManagementDomainNav.model';

type UsePermissionedCRMManagementRoutesResult = Readonly<{
  readonly canAccessRoute: (route?: CRMManagementRouteName) => boolean;
}>;

export function usePermissionedCRMManagementRoutes(): UsePermissionedCRMManagementRoutesResult {
  const { customerCrmManagement, sysAdminCrmManagement } = useFeatureToggles();

  const canManageFullCRM = useCheckPermissions([permissionsByEntity.CRM.FULL]);
  const canManageFullGlobalCrm = useCheckPermissions([permissionsByEntity.GlobalCrm.FULL]);

  const permissionedRoutes = useMemo(() => {
    const routes: CRMManagementRouteName[] = [];

    if (customerCrmManagement && canManageFullCRM) {
      routes.push('customerCrmFilesUpload');
    }

    if (sysAdminCrmManagement && canManageFullGlobalCrm) {
      routes.push('sysAdminCrmFilesUpload');
    }

    return routes;
  }, [customerCrmManagement, canManageFullCRM, sysAdminCrmManagement, canManageFullGlobalCrm]);

  const canAccessRoute = useCallback(
    (routeName?: CRMManagementRouteName) => {
      return routeName ? permissionedRoutes.includes(routeName) : false;
    },
    [permissionedRoutes]
  );

  return { canAccessRoute };
}
