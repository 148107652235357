import { apiTypes } from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';

import usePrevious from '../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../design-system/alert/ServerErrorAlert';
import { PageGridSection } from '../../../../../design-system/body-sections/PageGridSection';
import { PageGridSectionActions } from '../../../../../design-system/body-sections/PageGridSectionActions';
import { AccountType } from '../../../../../types/domain/account/constants';
import { User, UserDetailsUpdate } from '../../../../../types/domain/user/user';
import { UserDetailsUpdateSchema } from './UserDetailsSection.model';
import { UserDetailsSectionEdit } from './UserDetailsSectionEdit';
import { UserDetailsSectionReadonly } from './UserDetailsSectionReadonly';

export type Props = Readonly<{
  onReset: () => void;
  canEdit: Readonly<boolean>;
  isSubmitting: Readonly<boolean>;
  submitError: Readonly<apiTypes.GenericServerError | null>;
  user: Readonly<User>;
  accountType: Readonly<AccountType | 'SYSTEM' | null>;
  onUserDetailsUpdate: (userDetails: UserDetailsUpdate) => void;
}>;

export const UserDetailsSection: FC<Props> = ({
  canEdit,
  isSubmitting,
  submitError,
  user,
  accountType,
  onUserDetailsUpdate,
  onReset,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const previousIsSubmitting = usePrevious(isSubmitting);
  useEffect(() => {
    if (!isSubmitting && previousIsSubmitting && !submitError) {
      setIsEditing(false);
    }
  }, [isSubmitting, previousIsSubmitting, submitError]);

  const formik = useFormik<UserDetailsUpdate>({
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: UserDetailsUpdateSchema,
    initialValues: {
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      employeeKey: user.employeeKey ?? '',
      jobFunction: user.jobFunction,
    },
    onSubmit: val => {
      onUserDetailsUpdate({
        ...val,
        employeeKey: !val.employeeKey?.length ? null : val.employeeKey,
      });
    },
  });

  const { resetForm } = formik;
  const previousIsEditing = usePrevious(isEditing);
  useEffect(() => {
    if (previousIsEditing && !isEditing) {
      resetForm();
      onReset();
    }
  }, [isEditing, onReset, previousIsEditing, resetForm]);

  return (
    <FormikProvider value={formik}>
      <PageGridSection
        title="User Details"
        FormComponent={Form}
        formComponentProps={{ noValidate: true }}
        actions={
          canEdit ? (
            <PageGridSectionActions
              isSubmitting={isSubmitting}
              isEditing={isEditing}
              onEdit={() => setIsEditing(true)}
              onCancel={() => setIsEditing(false)}
            />
          ) : undefined
        }
        error={submitError && <ServerErrorAlert title="Error" error={submitError} />}
        content={
          isEditing ? (
            <UserDetailsSectionEdit user={user} accountType={accountType} />
          ) : (
            <UserDetailsSectionReadonly user={user} accountType={accountType} />
          )
        }
      />
    </FormikProvider>
  );
};
