import { Banner, DataGridClient } from '@cmg/common';
import { SearchTextField } from '@cmg/design-system';
import { accountRoleListSearchInputSelector } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

import { TableFilter } from '../../../../design-system/table/TableFilter';
import { TableFilterList } from '../../../../design-system/table/TableFilterList';
import { Role } from '../../../../types/domain/role/role';
import { createColumns } from './RolesListGridColumns';

export const SField = styled.div`
  margin: 10px 0;
`;

type AccountRolesFilters = {
  searchText?: string;
};

type Props = {
  loading: boolean;
  accountSubdomain: string;
  roles: Role[];
  filters: AccountRolesFilters;
  error: boolean;
  onChangeFilters: (params: AccountRolesFilters) => void;
};

/**
 * Displays Roles in a table view
 */
const RoleList: React.FC<Props> = ({
  loading,
  accountSubdomain,
  roles,
  filters,
  error,
  onChangeFilters,
}) => {
  const handleTextChange = (searchText: string | null) =>
    onChangeFilters({ ...filters, searchText: searchText || '' });

  return (
    <React.Fragment>
      {error && <Banner variant="error">Could not load account roles...</Banner>}
      <DataGridClient<Role>
        onGridReady={params => {
          params.api.resetRowHeights();
        }}
        renderFilters={() => (
          <TableFilterList
            filters={
              <TableFilter>
                <SearchTextField
                  data-test-id={accountRoleListSearchInputSelector.testId}
                  label="Search"
                  placeholder="Search by role..."
                  onChange={e => handleTextChange(e.target.value)}
                />
              </TableFilter>
            }
          />
        )}
        loading={loading}
        extended={{
          withMargin: false,
          hideColumnResize: true,
          hideColumnSelector: true,
          hidePagination: true,
          hideHeader: true,
        }}
        columns={createColumns(accountSubdomain)}
        rows={roles}
        resizeBy="grid"
        gridOptions={{
          suppressCellSelection: true,
          suppressRowClickSelection: true,
        }}
        getRowNodeId={data => data.id}
      />
    </React.Fragment>
  );
};

export default RoleList;
