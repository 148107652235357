import * as yup from 'yup';

import { UserCreate, UserCreateQuery } from '../../../../types/domain/user/user';

export type FormValues = UserCreate & UserCreateQuery;

export const CreateUserSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  jobFunction: yup.string().nullable().required('Job Function is required'),
  email: yup.string().required('Email is required').email('Email must be a valid email address'),
  employeeKey: yup.string().nullable(),
});
