import { Tag } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

export type Props = {
  // list of permissions - no mapping is done at this point, so it can be any arbitrary set of strings
  permissions?: readonly string[];
};

/**
 * Displays the permissions owned by this particular Trait.
 * However, it displays any array of strings, so there is no direct tie to traits here.
 */
const PermissionList: React.FC<Props> = ({ permissions }) => {
  const ownsPermissions = permissions && permissions.length > 0;
  const theme = useTheme();

  if (!ownsPermissions) {
    return null;
  }

  return (
    <div>
      <span>Permissions: </span>

      {permissions &&
        permissions.map(permission => (
          <Tag key={permission} outline color={theme.text.color.dark}>
            {permission}
          </Tag>
        ))}
    </div>
  );
};

export default PermissionList;
