import { apiTypes, Banner, DataGrid, FormLabel, Icon, Select, TextInput } from '@cmg/common';
import { identitySelectors } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

import { FirmListFilters } from '../../../../common/api/rolodexApiClient';
import { UUID } from '../../../../types/common';
import { FirmRoleType, firmRoleTypeOptions } from '../../../../types/domain/firm/constants';
import { FirmLimited } from '../../../../types/domain/firm/FirmLimited';
import { firmStatusOptions } from '../../../../types/domain/firm-status/constants';
import { FirmStatus } from '../../../../types/domain/firm-status/FirmStatus';
import { recordStatusOptions } from '../../../../types/domain/record-status/constants';
import { RecordStatus } from '../../../../types/domain/record-status/RecordStatus';
import { RolodexMetadata } from '../../../../types/domain/rolodex/Metadata';
import FirmHierarchyModal from './FirmHierarchyModal';
import { createColumns } from './FirmListGridColumns';

export const SFilters = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 0;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.xlargeUp} {
    flex-direction: row;
  }
`;

export const SField = styled.div`
  margin: 0 0 10px 0;
  flex: 1;

  &:last-child {
    margin-right: 0;
  }

  ${({ theme }) => theme.mediaQuery.xlargeUp} {
    margin: 0 10px 10px 0;
  }
`;

export const SIconWrapper = styled.div`
  margin-left: 10px;
`;

type Props = {
  loading: boolean;
  firms: FirmLimited[];
  pagination?: apiTypes.Pagination;
  filters: FirmListFilters;
  metadata: RolodexMetadata;
  error: boolean;
  onChangeFilters: (params: FirmListFilters, debounce?: boolean) => void;
  onChangePage: (params: apiTypes.ListParams) => void;
};

/**
 * Displays Firms in a table view
 */
const FirmList: React.FC<Props> = ({
  loading,
  firms,
  pagination,
  filters,
  error,
  metadata,
  onChangeFilters,
  onChangePage,
}) => {
  const [hierarchyModalOptions, setHierarchyModalOptions] = React.useState<{
    firmId: UUID | null;
    isVisible: boolean;
  }>({ firmId: null, isVisible: false });
  const [hasError, setHasError] = React.useState(false);

  const handleTextChange = (searchText: string | null) =>
    onChangeFilters({ ...filters, searchText: searchText || '' }, true);
  const handleIdChange = (id: string | null) => {
    // ID filter is either empty or contains 9 character number except 000000000
    const isValidId = id === null || id === '' || id.match(/(?!000000000)\b[0-9]{9}$\b/);
    setHasError(!isValidId);
    isValidId && onChangeFilters({ ...filters, key: id });
  };

  const handleStatusChange = (status: FirmStatus | null) => onChangeFilters({ ...filters, status });
  const handleEntityTypeChange = (legalEntityType: string | null) =>
    onChangeFilters({ ...filters, legalEntityType });
  const handleIndustryTypeChange = (industryType: string | null) =>
    onChangeFilters({ ...filters, industryType });
  const handleRoleTypeChange = (roleType: FirmRoleType | null) =>
    onChangeFilters({ ...filters, roleType });
  const handleRecordStatusChange = (deletionStatus: RecordStatus | null) =>
    onChangeFilters({ ...filters, deletionStatus });
  const handleCustomerCreatedChange = (firmType?: string | null) =>
    onChangeFilters({ ...filters, firmType });

  const columns = loading
    ? []
    : createColumns({
        showHierarchyModal: (firmId: UUID) => setHierarchyModalOptions({ firmId, isVisible: true }),
        metadata,
      });

  return (
    <React.Fragment>
      {error && <Banner variant="error">Could not load firms...</Banner>}
      {hasError && (
        <Banner variant="error">ID is a 9 digit code, but a non-numeric value was provided.</Banner>
      )}
      <DataGrid<FirmLimited>
        onGridReady={params => {
          params.api.resetRowHeights();
        }}
        renderFilters={() => (
          <SFilters>
            <SField>
              <FormLabel>Search by Name</FormLabel>
              <TextInput
                data-test-id={identitySelectors.rolodex.firmListSearchInput.testId}
                onChange={handleTextChange}
                placeholder="Search by Name..."
                prefix={
                  <SIconWrapper>
                    <Icon name="search" />
                  </SIconWrapper>
                }
              />
            </SField>
            <SField>
              <FormLabel>Search by ID</FormLabel>
              <TextInput
                hasError={hasError}
                data-test-id={identitySelectors.rolodex.firmListIdInput.testId}
                onChange={handleIdChange}
                placeholder="Search by ID..."
                prefix={
                  <SIconWrapper>
                    <Icon name="search" />
                  </SIconWrapper>
                }
              />
            </SField>
            <SField>
              <FormLabel>Entity Type</FormLabel>
              <Select<string>
                data-test-id={identitySelectors.rolodex.firmListEntityTypeSelect.testId}
                onChange={handleEntityTypeChange}
                placeholder="Filter by Entity Type..."
                options={metadata.entityTypes}
              />
            </SField>
            <SField>
              <FormLabel>Industry Type</FormLabel>
              <Select<string>
                data-test-id={identitySelectors.rolodex.firmListIndustryTypeSelect.testId}
                onChange={handleIndustryTypeChange}
                placeholder="Filter by Industry Type..."
                options={metadata.industryTypes}
              />
            </SField>
            <SField>
              <FormLabel>Status</FormLabel>
              <Select<FirmStatus>
                data-test-id={identitySelectors.rolodex.firmListStatusTypeSelect.testId}
                onChange={handleStatusChange}
                placeholder="Filter by Status..."
                options={firmStatusOptions}
              />
            </SField>
            <SField>
              <FormLabel>Role</FormLabel>
              <Select<FirmRoleType>
                data-test-id={identitySelectors.rolodex.firmListRoleTypeSelect.testId}
                onChange={handleRoleTypeChange}
                placeholder="Filter by Role..."
                options={firmRoleTypeOptions}
              />
            </SField>
            <SField>
              <FormLabel>Entity Status</FormLabel>
              <Select<RecordStatus>
                data-test-id={identitySelectors.rolodex.firmListRecordStatusTypeSelect.testId}
                onChange={handleRecordStatusChange}
                placeholder="Filter by Entity Status..."
                options={recordStatusOptions}
              />
            </SField>
            <SField>
              <FormLabel>Firm Category</FormLabel>
              <Select<string | undefined>
                data-test-id={identitySelectors.rolodex.firmListFirmTypeSelect.testId}
                value={filters.firmType}
                onChange={handleCustomerCreatedChange}
                isClearable={false}
                options={[
                  { label: 'All', value: undefined },
                  { label: 'Extension', value: 'Extension' },
                  { label: 'Free Text', value: 'Free_Text' },
                  { label: 'PWM', value: 'PWM' },
                ]}
              />
            </SField>
          </SFilters>
        )}
        pagination={
          pagination
            ? {
                page: pagination.activePage,
                perPage: pagination.perPage,
              }
            : undefined
        }
        totalPages={pagination ? pagination.totalPages : 0}
        onPaginationChange={({ page, perPage, orderField, orderDirection }) => {
          const params = {
            page,
            perPage,
            orderField,
            orderDirection:
              orderDirection === 'asc' ? apiTypes.SortDirection.ASC : apiTypes.SortDirection.DESC,
          };

          onChangePage(params);
        }}
        loading={loading}
        extended={{
          withMargin: false,
        }}
        columns={columns}
        rows={firms}
        resizeBy="grid"
        gridOptions={{
          suppressCellSelection: true,
          suppressRowClickSelection: true,
        }}
      />
      <FirmHierarchyModal
        firmId={hierarchyModalOptions.firmId}
        isModalVisible={hierarchyModalOptions.isVisible}
        hideModal={() => setHierarchyModalOptions({ firmId: null, isVisible: false })}
      />
    </React.Fragment>
  );
};

export default FirmList;
