import { apiTypes, Banner, CenteredPage, ServerErrors } from '@cmg/common';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { SelectDomainForm, SelectDomainFormValues } from '../components';
import {
  SelectDomainFormOnSubmitFn,
  SelectDomainFormValidateFn,
} from '../components/SelectDomainForm';
import { useSecuredQueryParams } from './SelectDomainRoute.hooks';
import {
  createSubdomainMaskFromUrl,
  replaceSubdomainFromUrl,
  validateSubdomain,
} from './SelectDomainRoute.model';
import { SLayout, STitle } from './SelectDomainRoute.style';

export const SelectDomainRoute: FC = props => {
  const { returnUrl } = useSecuredQueryParams();
  const [error, setError] = useState<Error>();
  const [redirecting, setRedirecting] = useState<boolean>();
  const pageError = useMemo<apiTypes.GenericServerError | undefined>(
    () =>
      error
        ? {
            target: '',
            details: [],
            code: error?.message ?? '',
            message: error?.message ?? '',
          }
        : undefined,
    [error]
  );
  const onSubmit = useCallback<SelectDomainFormOnSubmitFn>(
    (values: SelectDomainFormValues, helpers) => {
      helpers.setSubmitting(true);
      setRedirecting(true);
      const url = new URL(returnUrl, window.location.origin);
      const redirectUrl = replaceSubdomainFromUrl(url.toString(), values.domain);
      global.location.replace(redirectUrl);
    },
    [returnUrl]
  );
  const validate = useCallback<SelectDomainFormValidateFn>(
    async ({ domain }) => {
      if (domain == null || domain.trim() === '') {
        return undefined;
      }

      try {
        const result = await validateSubdomain(domain);
        setError(undefined);
        if (!result?.isValid) {
          return { domain: 'Invalid Subdomain' };
        }
      } catch (err) {
        setError(err as Error);
      }
    },
    [setError]
  );
  const suffixMask = createSubdomainMaskFromUrl(
    typeof window !== 'undefined' ? window.location.href : '.id.cmgecm.com'
  );

  return (
    <CenteredPage title="Login to your account" data-test-id="select-domain-page">
      {pageError && (
        <Banner variant="error" showIcon={false}>
          <ServerErrors error={pageError} />
        </Banner>
      )}
      <SLayout>
        <STitle>Please enter your organization's subdomain.</STitle>
        <SelectDomainForm
          redirecting={redirecting}
          suffixMask={suffixMask}
          validate={validate}
          onSubmit={onSubmit}
        />
      </SLayout>
    </CenteredPage>
  );
};

export default SelectDomainRoute;
