import { apiTypes, reduxUtil } from '@cmg/common';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as accountApiClient from '../../../common/api/accountApiClient';
import systemManagementApiClient, {
  GetAccountRolesParams,
  GetAccountsRolesResponse,
} from '../../../common/api/systemManagementApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { Role } from '../../../types/domain/role/role';
import { selectSelfSubdomain } from '../../shared/ducks';
import { selectAccountSubdomain } from '../shared/ducks';

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  FETCH_ACCOUNT_ROLES_LIST_REQUEST = 'global_management/FETCH_ACCOUNT_ROLES_LIST_REQUEST',
  FETCH_ACCOUNT_ROLES_LIST_SUCCESS = 'global_management/FETCH_ACCOUNT_ROLES_LIST_SUCCESS',
  FETCH_ACCOUNT_ROLES_LIST_FAILURE = 'global_management/FETCH_ACCOUNT_ROLES_LIST_FAILURE',
}

/**
 * ACTION CREATORS
 */
export const fetchAccountRolesList = (params: GetAccountRolesParams) => ({
  type: ActionTypes.FETCH_ACCOUNT_ROLES_LIST_REQUEST,
  payload: {
    params,
  },
});

export const fetchAccountRolesListSuccess = (params: {
  roles: Role[];
  pagination: apiTypes.Pagination;
}) => ({
  type: ActionTypes.FETCH_ACCOUNT_ROLES_LIST_SUCCESS,
  payload: {
    roles: params.roles,
    pagination: params.pagination,
  },
});

export const fetchAccountRolesListFailure = () => ({
  type: ActionTypes.FETCH_ACCOUNT_ROLES_LIST_FAILURE,
});

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.FETCH_ACCOUNT_ROLES_LIST_REQUEST]: ReturnType<typeof fetchAccountRolesList>;
  [ActionTypes.FETCH_ACCOUNT_ROLES_LIST_SUCCESS]: ReturnType<typeof fetchAccountRolesListSuccess>;
  [ActionTypes.FETCH_ACCOUNT_ROLES_LIST_FAILURE]: ReturnType<typeof fetchAccountRolesListFailure>;
};

/**
 * REDUCERS
 */
const { createReducer } = reduxUtil;

export type ReducerState = {
  error: boolean;
  // the most recently fetched roles response
  roles: Role[];
  loading: boolean;
  pagination: apiTypes.Pagination | null;
};

export const initialState = {
  error: false,
  roles: [],
  loading: false,
  pagination: null,
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.FETCH_ACCOUNT_ROLES_LIST_REQUEST]: () => false,
  [ActionTypes.FETCH_ACCOUNT_ROLES_LIST_SUCCESS]: () => false,
  [ActionTypes.FETCH_ACCOUNT_ROLES_LIST_FAILURE]: () => true,
});

const rolesReducer = createReducer<ReducerState['roles'], Actions>(initialState.roles, {
  [ActionTypes.FETCH_ACCOUNT_ROLES_LIST_SUCCESS]: (curState, { payload }) => payload.roles,
});

const loadingReducer = createReducer<ReducerState['loading'], Actions>(initialState.loading, {
  [ActionTypes.FETCH_ACCOUNT_ROLES_LIST_REQUEST]: () => true,
  [ActionTypes.FETCH_ACCOUNT_ROLES_LIST_SUCCESS]: () => false,
  [ActionTypes.FETCH_ACCOUNT_ROLES_LIST_FAILURE]: () => false,
});

const paginationReducer = createReducer<ReducerState['pagination'], Actions>(
  initialState.pagination,
  {
    [ActionTypes.FETCH_ACCOUNT_ROLES_LIST_SUCCESS]: (curState, { payload }) => payload.pagination,
  }
);

export default combineReducers<ReducerState>({
  error: errorReducer,
  roles: rolesReducer,
  loading: loadingReducer,
  pagination: paginationReducer,
});

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.adminAccountRolesList;
export const selectError = state => selectState(state).error;
export const selectRoles = state => selectState(state).roles || [];
export const selectPagination = state => selectState(state).pagination;
export const selectLoading = state => selectState(state).loading;

/**
 * SAGAS
 */
export function* fetchAccountRolesListSaga({
  payload,
}: Actions[ActionTypes.FETCH_ACCOUNT_ROLES_LIST_REQUEST]): SagaIterator {
  const { params } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  params.perPage = params.perPage ?? 100;

  let response: accountApiClient.GetMyAccountRolesResponse | GetAccountsRolesResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyAccountRoles, params);
  } else {
    response = yield call(
      systemManagementApiClient.admin.getAccountRoles,
      accountSubdomain,
      params
    );
  }
  if (response.ok) {
    const { data: roles, pagination } = response.data;
    yield put(fetchAccountRolesListSuccess({ roles, pagination }));
  } else {
    yield put(fetchAccountRolesListFailure());
  }
}

export function* adminAccountRolesListSaga() {
  yield takeLatest<Actions[ActionTypes.FETCH_ACCOUNT_ROLES_LIST_REQUEST]>(
    ActionTypes.FETCH_ACCOUNT_ROLES_LIST_REQUEST,
    fetchAccountRolesListSaga
  );
}
