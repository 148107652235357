import {
  SBottomContent,
  SColumn,
  SDetail,
  SDetailLabel,
  SDetailValue,
  SHeader,
  STitleContainer,
  STitleHeader,
} from './DetailHeader.styles';

/**
 * Styles used to create the header bar that
 * commonly appears on details pages.
 */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class DetailHeader {
  static Header = SHeader;
  static TitleWrapper = STitleContainer;
  static Title = STitleHeader;
  static Row = SBottomContent;
  static Column = SColumn;
  static DetailWrapper = SDetail;
  static DetailLabel = SDetailLabel;
  static DetailValue = SDetailValue;
}
