import { TextField } from '@cmg/design-system';
import { FormikSelectField } from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import { FC, useCallback } from 'react';

import { FormFieldLayout } from '../../../../../../design-system/body-sections/form/FormFieldLayout';
import { FormGroupHeading } from '../../../../../../design-system/body-sections/form/FormGroupHeading';
import { FormLayout } from '../../../../../../design-system/body-sections/form/FormLayout';
import { LocalLoginProviderFormValues } from './utils';

export const ProviderDetailsSectionEdit: FC = () => {
  const formik = useFormikContext<LocalLoginProviderFormValues>();

  const getSelectOptions = useCallback((requiredLabel: string = 'Required') => {
    return [
      { label: requiredLabel, value: true },
      { label: 'Not Required', value: false },
    ];
  }, []);

  return (
    <FormLayout>
      <FormFieldLayout>
        <FormikSelectField
          required
          label="Two Factor Auth"
          name="requireTwoFactorAuth"
          options={getSelectOptions()}
        />
      </FormFieldLayout>
      <FormGroupHeading title="Password Policy" />
      <FormFieldLayout>
        <TextField
          required
          type="number"
          label="Minimum Length"
          name="minLength"
          value={formik.values.minLength}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.minLength)}
          helperText={formik.errors.minLength}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          required
          type="number"
          label="Minimum Unique Characters"
          name="uniqueChars"
          value={formik.values.uniqueChars}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.uniqueChars)}
          helperText={formik.errors.uniqueChars}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <FormikSelectField
          required
          label="Special Characters"
          name="requireNonAlphanumeric"
          options={getSelectOptions()}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <FormikSelectField
          required
          label="Lower Case"
          name="requireLowercase"
          options={getSelectOptions('At least one lowercase')}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <FormikSelectField
          required
          label="Upper Case"
          name="requireUppercase"
          options={getSelectOptions('At least one uppercase')}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <FormikSelectField
          required
          label="Numeric"
          name="requireDigit"
          options={getSelectOptions('At least one number')}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <FormikSelectField
          required
          label="Password Reset"
          name="requireUniquePasswordOnReset"
          options={getSelectOptions('Unique Password Required')}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          required
          type="number"
          label="Expiration"
          name="passwordExpiresInDays"
          value={formik.values.passwordExpiresInDays}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.passwordExpiresInDays)}
          helperText={formik.errors.passwordExpiresInDays}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <FormikSelectField
          required
          label="Required Password Expiration"
          name="requirePasswordExpiration"
          options={getSelectOptions()}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          required
          type="number"
          label="Unique Password History"
          name="passwordHistoryLength"
          value={formik.values.passwordHistoryLength}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.passwordHistoryLength)}
          helperText={formik.errors.passwordHistoryLength}
        />
      </FormFieldLayout>
    </FormLayout>
  );
};
