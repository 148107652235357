import { SelectField, TextInputField } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledTextInputField = styled(TextInputField)`
  min-width: 150px;
`;

export const StyledSelectField = styled(SelectField)`
  min-width: 200px;
`;
