import { isSystemSubdomain } from '@cmg/auth';
import { apiTypes, reduxUtil } from '@cmg/common';
import { History } from 'history';
import { AnyAction, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as accountApiClient from '../../../../common/api/accountApiClient';
import systemManagementApiClient, {
  GetAzureAdOpenIdMetadataResponse,
  GetIdentityProviderResponse,
  GetOpenIdConnectMetadataResponse,
  GetSaml2pMetadataResponse,
  UpdateAzureAdConfigurationResponse,
  UpdateOpenIdConnectConfigurationResponse,
  UpdateSamlConfigurationResponse,
} from '../../../../common/api/systemManagementApiClient';
import { RootState } from '../../../../common/redux/rootReducer';
import {
  AzureAdConfigurationUpdate,
  AzureAdOpenIdMetadata,
} from '../../../../types/domain/identity-provider/configurations/azureAd';
import {
  OpenIdConnectConfigurationUpdate,
  OpenIdConnectMetadata,
} from '../../../../types/domain/identity-provider/configurations/openIdConnect';
import {
  Saml2PConfigurationUpdate,
  Saml2PMetadata,
} from '../../../../types/domain/identity-provider/configurations/saml2p';
import {
  IdentityProviderType,
  identityProviderTypeRoute,
} from '../../../../types/domain/identity-provider/constants';
import { IdentityProvider } from '../../../../types/domain/identity-provider/identityProvider';
import { selectSelfSubdomain } from '../../../shared/ducks';
import { fetchAccount, selectAccountId, selectAccountSubdomain } from '../../shared/ducks';
import { displayIdentityVerificationUrl, redirectToIdentityVerification } from './utils';

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  FETCH_IDENTITY_PROVIDER_REQUEST = 'global_management/FETCH_IDENTITY_PROVIDER_REQUEST',
  FETCH_IDENTITY_PROVIDER_SUCCESS = 'global_management/FETCH_IDENTITY_PROVIDER_SUCCESS',
  FETCH_IDENTITY_PROVIDER_FAILURE = 'global_management/FETCH_IDENTITY_PROVIDER_FAILURE',
  UPDATE_AZUREAD_CONFIG_REQUEST = 'global_management/UPDATE_AZUREAD_CONFIG_REQUEST',
  UPDATE_AZUREAD_CONFIG_SUCCESS = 'global_management/UPDATE_AZUREAD_CONFIG_SUCCESS',
  UPDATE_AZUREAD_CONFIG_FAILURE = 'global_management/UPDATE_AZUREAD_CONFIG_FAILURE',
  UPDATE_OPENID_CONNECT_CONFIG_REQUEST = 'global_management/UPDATE_OPENID_CONNECT_CONFIG_REQUEST',
  UPDATE_OPENID_CONNECT_CONFIG_SUCCESS = 'global_management/UPDATE_OPENID_CONNECT_CONFIG_SUCCESS',
  UPDATE_OPENID_CONNECT_CONFIG_FAILURE = 'global_management/UPDATE_OPENID_CONNECT_CONFIG_FAILURE',
  UPDATE_SAML_CONFIG_REQUEST = 'global_management/UPDATE_SAML_CONFIG_REQUEST',
  UPDATE_SAML_CONFIG_SUCCESS = 'global_management/UPDATE_SAML_CONFIG_SUCCESS',
  UPDATE_SAML_CONFIG_FAILURE = 'global_management/UPDATE_SAML_CONFIG_FAILURE',
  RESET_STATE = 'global_management/RESET_AZURE_CONFIG_STATE',
  FETCH_AZURE_AD_OPENID_METADATA_REQUEST = 'global_management/FETCH_AZURE_AD_OPENID_METADATA_REQUEST',
  FETCH_AZURE_AD_OPENID_METADATA_SUCCESS = 'global_management/FETCH_AZURE_AD_OPENID_METADATA_SUCCESS',
  FETCH_AZURE_AD_OPENID_METADATA_FAILURE = 'global_management/FETCH_AZURE_AD_OPENID_METADATA_FAILURE',
  FETCH_OPENID_CONNECT_METADATA_REQUEST = 'global_management/FETCH_OPENID_CONNECT_METADATA_REQUEST',
  FETCH_OPENID_CONNECT_METADATA_SUCCESS = 'global_management/FETCH_OPENID_CONNECT_METADATA_SUCCESS',
  FETCH_OPENID_CONNECT_METADATA_FAILURE = 'global_management/FETCH_OPENID_CONNECT_METADATA_FAILURE',
  FETCH_SAML2P_METADATA_REQUEST = 'global_management/FETCH_SAML2P_METADATA_REQUEST',
  FETCH_SAML2P_METADATA_SUCCESS = 'global_management/FETCH_SAML2P_METADATA_SUCCESS',
  FETCH_SAML2P_METADATA_FAILURE = 'global_management/FETCH_SAML2P_METADATA_FAILURE',
}

/**
 * ACTION CREATORS
 */
export const fetchIdentityProvider = (params: { identityProviderType: IdentityProviderType }) => ({
  type: ActionTypes.FETCH_IDENTITY_PROVIDER_REQUEST,
  payload: {
    identityProviderType: params.identityProviderType,
  },
});

export const fetchIdentityProviderSuccess = (params: { identityProvider: IdentityProvider }) => ({
  type: ActionTypes.FETCH_IDENTITY_PROVIDER_SUCCESS,
  payload: {
    identityProvider: params.identityProvider,
  },
});

export const fetchIdentityProviderFailure = (params: { error: apiTypes.GenericServerError }) => ({
  type: ActionTypes.FETCH_IDENTITY_PROVIDER_FAILURE,
  payload: {
    error: params.error,
  },
});

export const resetState = () => ({
  type: ActionTypes.RESET_STATE,
});

export const updateAzureAdConfig = (params: {
  configuration: AzureAdConfigurationUpdate;
  saveAndTest: boolean;
  redirectToDetailsPage?: boolean;
  history?: History;
}) => ({
  type: ActionTypes.UPDATE_AZUREAD_CONFIG_REQUEST,
  payload: {
    configuration: params.configuration,
    saveAndTest: params.saveAndTest,
    redirectToDetailsPage: params.redirectToDetailsPage,
    history: params.history,
  },
});

export const updateAzureAdConfigSuccess = () => ({
  type: ActionTypes.UPDATE_AZUREAD_CONFIG_SUCCESS,
});

export const updateAzureAdConfigFailure = (params: { error: apiTypes.GenericServerError }) => ({
  type: ActionTypes.UPDATE_AZUREAD_CONFIG_FAILURE,
  payload: {
    error: params.error,
  },
});

export const updateOpenIdConnectConfig = (params: {
  configuration: OpenIdConnectConfigurationUpdate;
  saveAndTest: boolean;
  redirectToDetailsPage?: boolean;
  history?: History;
}) => ({
  type: ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_REQUEST,
  payload: {
    configuration: params.configuration,
    saveAndTest: params.saveAndTest,
    redirectToDetailsPage: params.redirectToDetailsPage,
    history: params.history,
  },
});

export const updateOpenIdConnectConfigSuccess = () => ({
  type: ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_SUCCESS,
});

export const updateOpenIdConnectConfigFailure = (params: {
  error: apiTypes.GenericServerError;
}) => ({
  type: ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_FAILURE,
  payload: {
    error: params.error,
  },
});

export const updateSamlConfig = (params: {
  configuration: Saml2PConfigurationUpdate;
  saveAndTest: boolean;
  redirectToDetailsPage?: boolean;
  history?: History;
}) => ({
  type: ActionTypes.UPDATE_SAML_CONFIG_REQUEST,
  payload: {
    configuration: params.configuration,
    saveAndTest: params.saveAndTest,
    redirectToDetailsPage: params.redirectToDetailsPage,
    history: params.history,
  },
});

export const updateSamlConfigSuccess = () => ({
  type: ActionTypes.UPDATE_SAML_CONFIG_SUCCESS,
});

export const updateSamlConfigFailure = (params: { error: apiTypes.GenericServerError }) => ({
  type: ActionTypes.UPDATE_SAML_CONFIG_FAILURE,
  payload: {
    error: params.error,
  },
});

export const fetchAzureAdOpenIdMetadata = () => ({
  type: ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_REQUEST,
});

export const fetchAzureAdOpenIdMetadataSuccess = (params: { metadata: AzureAdOpenIdMetadata }) => ({
  type: ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_SUCCESS,
  payload: {
    metadata: params.metadata,
  },
});

export const fetchAzureAdOpenIdMetadataFailure = (params: {
  error: apiTypes.GenericServerError;
}) => ({
  type: ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_FAILURE,
  payload: {
    error: params.error,
  },
});

export const fetchOpenIdConnectMetadata = () => ({
  type: ActionTypes.FETCH_OPENID_CONNECT_METADATA_REQUEST,
});

export const fetchOpenIdConnectMetadataSuccess = (params: { metadata: OpenIdConnectMetadata }) => ({
  type: ActionTypes.FETCH_OPENID_CONNECT_METADATA_SUCCESS,
  payload: {
    metadata: params.metadata,
  },
});

export const fetchOpenIdConnectMetadataFailure = (params: {
  error: apiTypes.GenericServerError;
}) => ({
  type: ActionTypes.FETCH_OPENID_CONNECT_METADATA_FAILURE,
  payload: {
    error: params.error,
  },
});

export const fetchSaml2pMetadata = () => ({
  type: ActionTypes.FETCH_SAML2P_METADATA_REQUEST,
});

export const fetchSaml2pMetadataSuccess = (params: { metadata: Saml2PMetadata }) => ({
  type: ActionTypes.FETCH_SAML2P_METADATA_SUCCESS,
  payload: {
    metadata: params.metadata,
  },
});

export const fetchSaml2pMetadataFailure = (params: { error: apiTypes.GenericServerError }) => ({
  type: ActionTypes.FETCH_SAML2P_METADATA_FAILURE,
  payload: {
    error: params.error,
  },
});

/**
 * ACTIONS
 */

type Actions = {
  [ActionTypes.RESET_STATE]: ReturnType<typeof resetState>;
  [ActionTypes.UPDATE_AZUREAD_CONFIG_REQUEST]: ReturnType<typeof updateAzureAdConfig>;
  [ActionTypes.UPDATE_AZUREAD_CONFIG_SUCCESS]: ReturnType<typeof updateAzureAdConfigSuccess>;
  [ActionTypes.UPDATE_AZUREAD_CONFIG_FAILURE]: ReturnType<typeof updateAzureAdConfigFailure>;
  [ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_REQUEST]: ReturnType<typeof updateOpenIdConnectConfig>;
  [ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_SUCCESS]: ReturnType<
    typeof updateOpenIdConnectConfigSuccess
  >;
  [ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_FAILURE]: ReturnType<
    typeof updateOpenIdConnectConfigFailure
  >;
  [ActionTypes.UPDATE_SAML_CONFIG_REQUEST]: ReturnType<typeof updateSamlConfig>;
  [ActionTypes.UPDATE_SAML_CONFIG_SUCCESS]: ReturnType<typeof updateSamlConfigSuccess>;
  [ActionTypes.UPDATE_SAML_CONFIG_FAILURE]: ReturnType<typeof updateSamlConfigFailure>;
  [ActionTypes.FETCH_IDENTITY_PROVIDER_REQUEST]: ReturnType<typeof fetchIdentityProvider>;
  [ActionTypes.FETCH_IDENTITY_PROVIDER_SUCCESS]: ReturnType<typeof fetchIdentityProviderSuccess>;
  [ActionTypes.FETCH_IDENTITY_PROVIDER_FAILURE]: ReturnType<typeof fetchIdentityProviderFailure>;
  [ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_REQUEST]: ReturnType<
    typeof fetchAzureAdOpenIdMetadata
  >;
  [ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_SUCCESS]: ReturnType<
    typeof fetchAzureAdOpenIdMetadataSuccess
  >;
  [ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_FAILURE]: ReturnType<
    typeof fetchAzureAdOpenIdMetadataFailure
  >;
  [ActionTypes.FETCH_OPENID_CONNECT_METADATA_REQUEST]: ReturnType<
    typeof fetchOpenIdConnectMetadata
  >;
  [ActionTypes.FETCH_OPENID_CONNECT_METADATA_SUCCESS]: ReturnType<
    typeof fetchOpenIdConnectMetadataSuccess
  >;
  [ActionTypes.FETCH_OPENID_CONNECT_METADATA_FAILURE]: ReturnType<
    typeof fetchOpenIdConnectMetadataFailure
  >;
  [ActionTypes.FETCH_SAML2P_METADATA_REQUEST]: ReturnType<typeof fetchSaml2pMetadata>;
  [ActionTypes.FETCH_SAML2P_METADATA_SUCCESS]: ReturnType<typeof fetchSaml2pMetadataSuccess>;
  [ActionTypes.FETCH_SAML2P_METADATA_FAILURE]: ReturnType<typeof fetchSaml2pMetadataFailure>;
};

/**
 * REDUCERS
 */

const { createReducer } = reduxUtil;

export type ReducerState = {
  identityProvider: {
    error: apiTypes.GenericServerError | null;
    loading: boolean;
    data: IdentityProvider | null;
  };
  azureAdConfig: {
    error: apiTypes.GenericServerError | null;
    submitting: boolean;
  };
  openIdConnectConfig: {
    error: apiTypes.GenericServerError | null;
    submitting: boolean;
  };
  samlConfig: {
    error: apiTypes.GenericServerError | null;
    submitting: boolean;
  };
  azureAdMetadata: {
    error: apiTypes.GenericServerError | null;
    loading: boolean;
    metadata: AzureAdOpenIdMetadata | null;
  };
  openIdConnectMetadata: {
    error: apiTypes.GenericServerError | null;
    loading: boolean;
    metadata: OpenIdConnectMetadata | null;
  };
  samlMetadata: {
    error: apiTypes.GenericServerError | null;
    loading: boolean;
    metadata: Saml2PMetadata | null;
  };
};

export const initialState = {
  identityProvider: {
    error: null,
    data: null,
    loading: false,
  },
  azureAdConfig: {
    error: null,
    submitting: false,
  },
  openIdConnectConfig: {
    error: null,
    submitting: false,
  },
  samlConfig: {
    error: null,
    submitting: false,
  },
  azureAdMetadata: {
    error: null,
    loading: false,
    metadata: null,
  },
  openIdConnectMetadata: {
    error: null,
    loading: false,
    metadata: null,
  },
  samlMetadata: {
    error: null,
    loading: false,
    metadata: null,
  },
};

const azureAdConfigReducers = combineReducers<ReducerState['azureAdConfig']>({
  submitting: createReducer<ReducerState['azureAdConfig']['submitting'], Actions>(
    initialState.azureAdConfig.submitting,
    {
      [ActionTypes.UPDATE_AZUREAD_CONFIG_REQUEST]: () => true,
      [ActionTypes.UPDATE_AZUREAD_CONFIG_SUCCESS]: () => false,
      [ActionTypes.UPDATE_AZUREAD_CONFIG_FAILURE]: () => false,
    }
  ),
  error: createReducer<ReducerState['azureAdConfig']['error'], Actions>(
    initialState.azureAdConfig.error,
    {
      [ActionTypes.UPDATE_AZUREAD_CONFIG_REQUEST]: () => null,
      [ActionTypes.UPDATE_AZUREAD_CONFIG_SUCCESS]: () => null,
      [ActionTypes.UPDATE_AZUREAD_CONFIG_FAILURE]: (curState, { payload }) => payload.error,
    }
  ),
});

const openIdConnectConfigReducers = combineReducers<ReducerState['openIdConnectConfig']>({
  submitting: createReducer<ReducerState['openIdConnectConfig']['submitting'], Actions>(
    initialState.openIdConnectConfig.submitting,
    {
      [ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_REQUEST]: () => true,
      [ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_SUCCESS]: () => false,
      [ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_FAILURE]: () => false,
    }
  ),
  error: createReducer<ReducerState['openIdConnectConfig']['error'], Actions>(
    initialState.openIdConnectConfig.error,
    {
      [ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_REQUEST]: () => null,
      [ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_SUCCESS]: () => null,
      [ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_FAILURE]: (curState, { payload }) => payload.error,
    }
  ),
});

const samlConfigReducers = combineReducers<ReducerState['samlConfig']>({
  submitting: createReducer<ReducerState['samlConfig']['submitting'], Actions>(
    initialState.samlConfig.submitting,
    {
      [ActionTypes.UPDATE_SAML_CONFIG_REQUEST]: () => true,
      [ActionTypes.UPDATE_SAML_CONFIG_SUCCESS]: () => false,
      [ActionTypes.UPDATE_SAML_CONFIG_FAILURE]: () => false,
    }
  ),
  error: createReducer<ReducerState['samlConfig']['error'], Actions>(
    initialState.samlConfig.error,
    {
      [ActionTypes.UPDATE_SAML_CONFIG_REQUEST]: () => null,
      [ActionTypes.UPDATE_SAML_CONFIG_SUCCESS]: () => null,
      [ActionTypes.UPDATE_SAML_CONFIG_FAILURE]: (curState, { payload }) => payload.error,
    }
  ),
});

const identityProviderReducers = combineReducers<ReducerState['identityProvider']>({
  loading: createReducer<ReducerState['identityProvider']['loading'], Actions>(
    initialState.identityProvider.loading,
    {
      [ActionTypes.FETCH_IDENTITY_PROVIDER_REQUEST]: () => true,
      [ActionTypes.FETCH_IDENTITY_PROVIDER_SUCCESS]: () => false,
      [ActionTypes.FETCH_IDENTITY_PROVIDER_FAILURE]: () => false,
    }
  ),
  error: createReducer<ReducerState['identityProvider']['error'], Actions>(
    initialState.identityProvider.error,
    {
      [ActionTypes.FETCH_IDENTITY_PROVIDER_REQUEST]: () => null,
      [ActionTypes.FETCH_IDENTITY_PROVIDER_SUCCESS]: () => null,
      [ActionTypes.FETCH_IDENTITY_PROVIDER_FAILURE]: (curState, { payload }) => payload.error,
    }
  ),
  data: createReducer<ReducerState['identityProvider']['data'], Actions>(
    initialState.identityProvider.data,
    {
      [ActionTypes.FETCH_IDENTITY_PROVIDER_SUCCESS]: (curState, { payload }) =>
        payload.identityProvider,
    }
  ),
});

const samlMetadataReducers = combineReducers<ReducerState['samlMetadata']>({
  loading: createReducer<ReducerState['samlMetadata']['loading'], Actions>(
    initialState.samlMetadata.loading,
    {
      [ActionTypes.FETCH_SAML2P_METADATA_REQUEST]: () => true,
      [ActionTypes.FETCH_SAML2P_METADATA_SUCCESS]: () => false,
      [ActionTypes.FETCH_SAML2P_METADATA_FAILURE]: () => false,
    }
  ),
  error: createReducer<ReducerState['samlMetadata']['error'], Actions>(
    initialState.samlMetadata.error,
    {
      [ActionTypes.FETCH_SAML2P_METADATA_REQUEST]: () => null,
      [ActionTypes.FETCH_SAML2P_METADATA_SUCCESS]: () => null,
      [ActionTypes.FETCH_SAML2P_METADATA_FAILURE]: (curState, { payload }) => payload.error,
    }
  ),
  metadata: createReducer<ReducerState['samlMetadata']['metadata'], Actions>(
    initialState.samlMetadata.metadata,
    {
      [ActionTypes.FETCH_SAML2P_METADATA_SUCCESS]: (curState, { payload }) => payload.metadata,
    }
  ),
});

const azureMetadataReducers = combineReducers<ReducerState['azureAdMetadata']>({
  loading: createReducer<ReducerState['azureAdMetadata']['loading'], Actions>(
    initialState.azureAdMetadata.loading,
    {
      [ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_REQUEST]: () => true,
      [ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_SUCCESS]: () => false,
      [ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_FAILURE]: () => false,
    }
  ),
  error: createReducer<ReducerState['azureAdMetadata']['error'], Actions>(
    initialState.azureAdMetadata.error,
    {
      [ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_REQUEST]: () => null,
      [ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_SUCCESS]: () => null,
      [ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_FAILURE]: (curState, { payload }) =>
        payload.error,
    }
  ),
  metadata: createReducer<ReducerState['azureAdMetadata']['metadata'], Actions>(
    initialState.azureAdMetadata.metadata,
    {
      [ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_SUCCESS]: (curState, { payload }) =>
        payload.metadata,
    }
  ),
});

const openIdConnectMetadataReducers = combineReducers<ReducerState['openIdConnectMetadata']>({
  loading: createReducer<ReducerState['openIdConnectMetadata']['loading'], Actions>(
    initialState.openIdConnectMetadata.loading,
    {
      [ActionTypes.FETCH_OPENID_CONNECT_METADATA_REQUEST]: () => true,
      [ActionTypes.FETCH_OPENID_CONNECT_METADATA_SUCCESS]: () => false,
      [ActionTypes.FETCH_OPENID_CONNECT_METADATA_FAILURE]: () => false,
    }
  ),
  error: createReducer<ReducerState['openIdConnectMetadata']['error'], Actions>(
    initialState.openIdConnectMetadata.error,
    {
      [ActionTypes.FETCH_OPENID_CONNECT_METADATA_REQUEST]: () => null,
      [ActionTypes.FETCH_OPENID_CONNECT_METADATA_SUCCESS]: () => null,
      [ActionTypes.FETCH_OPENID_CONNECT_METADATA_FAILURE]: (curState, { payload }) => payload.error,
    }
  ),
  metadata: createReducer<ReducerState['openIdConnectMetadata']['metadata'], Actions>(
    initialState.openIdConnectMetadata.metadata,
    {
      [ActionTypes.FETCH_OPENID_CONNECT_METADATA_SUCCESS]: (curState, { payload }) =>
        payload.metadata,
    }
  ),
});

// This reducer acts on the entire state of this duck. Has access to duck state and must return duck state.
const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_STATE]: () => ({ ...initialState }),
});

const combinedReducers = combineReducers<ReducerState>({
  azureAdConfig: azureAdConfigReducers,
  openIdConnectConfig: openIdConnectConfigReducers,
  identityProvider: identityProviderReducers,
  samlConfig: samlConfigReducers,
  samlMetadata: samlMetadataReducers,
  azureAdMetadata: azureMetadataReducers,
  openIdConnectMetadata: openIdConnectMetadataReducers,
});

// Combines our individual slice reducers and the cross slice reducer.
export default function duckReducer(state: ReducerState = initialState, action: AnyAction) {
  const intermediateState = combinedReducers(state, action);
  return crossSliceReducer(intermediateState, action);
}

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.adminAccountIdentityProviderDetail;
export const selectAzureAdConfigError = state => selectState(state).azureAdConfig.error;
export const selectAzureAdConfigSubmitting = state => selectState(state).azureAdConfig.submitting;
export const selectOpenIdConnectConfigError = state => selectState(state).openIdConnectConfig.error;
export const selectOpenIdConnectConfigSubmitting = state =>
  selectState(state).openIdConnectConfig.submitting;
export const selectIdentityProvider = state => selectState(state).identityProvider.data;
export const selectIdentityProviderError = state => selectState(state).identityProvider.error;
export const selectIdentityProviderLoading = state => selectState(state).identityProvider.loading;
export const selectSamlConfigError = state => selectState(state).samlConfig.error;
export const selectSamlConfigSubmitting = state => selectState(state).samlConfig.submitting;
export const selectSamlMetadata = state => selectState(state).samlMetadata.metadata;
export const selectSamlMetadataLoading = state => selectState(state).samlMetadata.loading;
export const selectSamlMetadataError = state => selectState(state).samlMetadata.error;
export const selectAzureAdMetadata = state => selectState(state).azureAdMetadata.metadata;
export const selectAzureAdMetadataLoading = state => selectState(state).azureAdMetadata.loading;
export const selectAzureAdMetadataError = state => selectState(state).azureAdMetadata.error;
export const selectOpenIdConnectMetadata = state =>
  selectState(state).openIdConnectMetadata.metadata;
export const selectOpenIdConnectMetadataLoading = state =>
  selectState(state).openIdConnectMetadata.loading;
export const selectOpenIdConnectMetadataError = state =>
  selectState(state).openIdConnectMetadata.error;

/**
 * SAGAS
 */
export function* updateAzureAdConfigSaga({
  payload,
}: Actions[ActionTypes.UPDATE_AZUREAD_CONFIG_REQUEST]): SagaIterator {
  const { configuration, history, redirectToDetailsPage } = payload;

  const accountId = yield select(selectAccountId);
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response:
    | accountApiClient.UpdateMyAccountAzureAdConfigurationResponse
    | UpdateAzureAdConfigurationResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.updateMyAccountAzureAdConfiguration, configuration);
  } else {
    response = yield call(
      systemManagementApiClient.admin.updateAzureAdConfiguration,
      accountId,
      configuration
    );
  }
  if (response.ok) {
    yield put(updateAzureAdConfigSuccess());

    if (payload.saveAndTest) {
      const verificationUrl = response.data.verificationUrl;

      isSystemSubdomain()
        ? displayIdentityVerificationUrl(verificationUrl)
        : redirectToIdentityVerification(verificationUrl);
    }
    // fetchAccount to update the account with the latest identity provider data
    yield put(fetchAccount(accountSubdomain));

    if (redirectToDetailsPage && history) {
      history.push(
        identityProviderTypeRoute[IdentityProviderType.AZURE_AD_OPEN_ID].getUrlPath({
          accountSubdomain: accountSubdomain ?? '',
        })
      );
    }
  } else {
    yield put(updateAzureAdConfigFailure({ error: response.data.error }));
  }
}

export function* updateOpenIdConnectConfigSaga({
  payload,
}: Actions[ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_REQUEST]) {
  const { configuration, redirectToDetailsPage, history } = payload;

  const accountId = yield select(selectAccountId);
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response:
    | accountApiClient.UpdateMyAccountOpenIdConnectConfigurationResponse
    | UpdateOpenIdConnectConfigurationResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(
      accountApiClient.updateMyAccountOpenIdConnectConfiguration,
      configuration
    );
  } else {
    response = yield call(
      systemManagementApiClient.admin.updateOpenIdConnectConfiguration,
      accountId,
      configuration
    );
  }
  if (response.ok) {
    yield put(updateOpenIdConnectConfigSuccess());

    if (payload.saveAndTest) {
      const verificationUrl = response.data.verificationUrl;

      isSystemSubdomain()
        ? displayIdentityVerificationUrl(verificationUrl)
        : redirectToIdentityVerification(verificationUrl);
    }
    // fetchAccount to update the account with the latest identity provider data
    yield put(fetchAccount(accountSubdomain));

    if (redirectToDetailsPage && history) {
      history.push(
        identityProviderTypeRoute[IdentityProviderType.OPEN_ID_CONNECT].getUrlPath({
          accountSubdomain: accountSubdomain ?? '',
        })
      );
    }
  } else {
    yield put(updateOpenIdConnectConfigFailure({ error: response.data.error }));
  }
}

export function* updateSamlConfigSaga({
  payload,
}: Actions[ActionTypes.UPDATE_SAML_CONFIG_REQUEST]): SagaIterator {
  const { configuration, redirectToDetailsPage, history } = payload;

  const accountId = yield select(selectAccountId);
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response:
    | accountApiClient.UpdateMyAccountSamlConfigurationResponse
    | UpdateSamlConfigurationResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.updateMyAccountSamlConfiguration, configuration);
  } else {
    response = yield call(
      systemManagementApiClient.admin.updateSamlConfiguration,
      accountId,
      configuration
    );
  }
  if (response.ok) {
    yield put(updateSamlConfigSuccess());

    if (payload.saveAndTest) {
      const verificationUrl = response.data.verificationUrl;

      isSystemSubdomain()
        ? displayIdentityVerificationUrl(verificationUrl)
        : redirectToIdentityVerification(verificationUrl);
    }
    // fetchAccount to update the account with the latest identity provider data
    yield put(fetchAccount(accountSubdomain));

    if (redirectToDetailsPage && history) {
      history.push(
        identityProviderTypeRoute[IdentityProviderType.SAML2P].getUrlPath({
          accountSubdomain: accountSubdomain ?? '',
        })
      );
    }
  } else {
    yield put(updateSamlConfigFailure({ error: response.data.error }));
  }
}

export function* fetchIdentityProviderSaga({
  payload,
}: Actions[ActionTypes.FETCH_IDENTITY_PROVIDER_REQUEST]) {
  const { identityProviderType } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: accountApiClient.GetMyAccountIdentityProviderResponse | GetIdentityProviderResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyAccountIdentityProvider, identityProviderType);
  } else {
    const accountId = yield select(selectAccountId);
    response = yield call(
      systemManagementApiClient.admin.getIdentityProvider,
      accountId,
      identityProviderType
    );
  }

  if (response.ok) {
    yield put(fetchIdentityProviderSuccess({ identityProvider: response.data }));
  } else {
    yield put(fetchIdentityProviderFailure({ error: response.data.error }));
  }
}

export function* fetchAzureAdOpenIdMetadataSaga(): SagaIterator {
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response:
    | accountApiClient.GetMyAccountAzureAdOpenIdMetadataResponse
    | GetAzureAdOpenIdMetadataResponse;

  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyAccountAzureAdOpenIdMetadata);
  } else {
    const accountId = yield select(selectAccountId);
    response = yield call(systemManagementApiClient.admin.getAzureAdOpenIdMetadata, accountId);
  }
  if (response.ok) {
    yield put(fetchAzureAdOpenIdMetadataSuccess({ metadata: response.data }));
  } else {
    yield put(fetchAzureAdOpenIdMetadataFailure({ error: response.data.error }));
  }
}

export function* fetchOpenIdConnectMetadataSaga() {
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response:
    | accountApiClient.GetMyAccountOpenIdConnectMetadataResponse
    | GetOpenIdConnectMetadataResponse;

  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyAccountOpenIdConnectMetadata);
  } else {
    const accountId = yield select(selectAccountId);

    response = yield call(systemManagementApiClient.admin.getOpenIdConnectMetadata, accountId);
  }
  if (response.ok) {
    yield put(fetchOpenIdConnectMetadataSuccess({ metadata: response.data }));
  } else {
    yield put(fetchOpenIdConnectMetadataFailure({ error: response.data.error }));
  }
}

export function* fetchSaml2pMetadataSaga(): SagaIterator {
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: accountApiClient.GetMyAccountSaml2pMetadataResponse | GetSaml2pMetadataResponse;

  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyAccountSaml2pMetadata);
  } else {
    const accountId = yield select(selectAccountId);

    response = yield call(systemManagementApiClient.admin.getSaml2pMetadata, accountId);
  }
  if (response.ok) {
    yield put(fetchSaml2pMetadataSuccess({ metadata: response.data }));
  } else {
    yield put(fetchSaml2pMetadataFailure({ error: response.data.error }));
  }
}

export function* adminAccountIdentityProvidersSaga() {
  yield takeLatest<Actions[ActionTypes.UPDATE_AZUREAD_CONFIG_REQUEST]>(
    ActionTypes.UPDATE_AZUREAD_CONFIG_REQUEST,
    updateAzureAdConfigSaga
  );
  yield takeLatest<Actions[ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_REQUEST]>(
    ActionTypes.UPDATE_OPENID_CONNECT_CONFIG_REQUEST,
    updateOpenIdConnectConfigSaga
  );
  yield takeLatest<Actions[ActionTypes.UPDATE_SAML_CONFIG_REQUEST]>(
    ActionTypes.UPDATE_SAML_CONFIG_REQUEST,
    updateSamlConfigSaga
  );
  yield takeLatest<Actions[ActionTypes.FETCH_IDENTITY_PROVIDER_REQUEST]>(
    ActionTypes.FETCH_IDENTITY_PROVIDER_REQUEST,
    fetchIdentityProviderSaga
  );
  yield takeLatest<Actions[ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_REQUEST]>(
    ActionTypes.FETCH_AZURE_AD_OPENID_METADATA_REQUEST,
    fetchAzureAdOpenIdMetadataSaga
  );
  yield takeLatest<Actions[ActionTypes.FETCH_SAML2P_METADATA_REQUEST]>(
    ActionTypes.FETCH_SAML2P_METADATA_REQUEST,
    fetchSaml2pMetadataSaga
  );
  yield takeLatest<Actions[ActionTypes.FETCH_OPENID_CONNECT_METADATA_REQUEST]>(
    ActionTypes.FETCH_OPENID_CONNECT_METADATA_REQUEST,
    fetchOpenIdConnectMetadataSaga
  );
}
