import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IdentityPageContent } from '../../../../../../design-system/IdentityPageContent';
import { Saml2PConfigurationUpdate } from '../../../../../../types/domain/identity-provider/configurations/saml2p';
import { IdentityProviderType } from '../../../../../../types/domain/identity-provider/constants';
import { IdentityProviderSaml } from '../../../../../../types/domain/identity-provider/identityProvider';
import {
  fetchIdentityProvider,
  fetchSaml2pMetadata,
  resetState,
  selectIdentityProvider,
  selectIdentityProviderLoading,
  selectSamlConfigError,
  selectSamlConfigSubmitting,
  selectSamlMetadata,
  selectSamlMetadataError,
  selectSamlMetadataLoading,
  updateSamlConfig,
} from '../../../../../account-detail/security/identity-providers/ducks';
import { selectAccount } from '../../../../../account-detail/shared/ducks';
import { LoginProviderHighlights } from '../shared/LoginProviderHighlights';
import OverviewPanel from '../shared/OverviewPanel';
import { ProviderRouteSkeleton } from '../shared/ProviderRouteSkeleton';
import { ProviderDetailsSection } from './ProviderDetailsSection';

const mapStateToProps = state => ({
  account: selectAccount(state),
  provider: selectIdentityProvider(state),
  loading: selectIdentityProviderLoading(state),
  metadata: selectSamlMetadata(state),
  metadataLoading: selectSamlMetadataLoading(state),
  metadataError: selectSamlMetadataError(state),
  configError: selectSamlConfigError(state),
  configSubmitting: selectSamlConfigSubmitting(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchIdentityProvider,
      fetchSaml2pMetadata,
      updateSamlConfig,
      resetState,
    },
    dispatch
  ),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;

export type Props = StateProps & DispatchProps;
/**
 * This route allows users to update or add the saml2p identity provider configuration
 */
export const SamlProviderRouteComponent: React.FC<Props> = ({
  provider,
  loading,
  metadata,
  metadataLoading,
  metadataError,
  configError,
  configSubmitting,
  actions,
  account,
}) => {
  const canEditProvider = useCheckPermissions(
    [permissionsByEntity.GlobalAccount.FULL, permissionsByEntity.Account.FULL],
    false
  );

  useEffect(() => {
    if (account) {
      actions.fetchSaml2pMetadata();
      actions.fetchIdentityProvider({
        identityProviderType: IdentityProviderType.SAML2P,
      });

      return () => {
        actions.resetState();
      };
    }
  }, [account, actions]);

  if (loading || metadataLoading) {
    return <ProviderRouteSkeleton />;
  }

  if (!account) {
    return null;
  }

  const samlProvider = provider as IdentityProviderSaml | null;

  return (
    <OverviewPanel<Saml2PConfigurationUpdate>
      account={account}
      provider={samlProvider}
      onUpdateConfig={actions.updateSamlConfig}
      renderPanel={params => {
        return (
          <IdentityPageContent
            localHighlights={
              <LoginProviderHighlights
                canEdit={canEditProvider}
                isLoading={metadataLoading || configSubmitting}
                providerType={IdentityProviderType.SAML2P}
                configuredProvider={samlProvider}
                onStatusToggle={provider =>
                  params.onSubmit({ ...provider, enabled: !provider.enabled }, false)
                }
              />
            }
            gridContent={
              <ProviderDetailsSection
                onReset={resetState}
                canEdit={canEditProvider}
                metadata={metadata}
                provider={samlProvider}
                error={configError ?? metadataError}
                isSubmitting={configSubmitting}
                onSubmit={data => params.onSubmit(data, false)}
              />
            }
          />
        );
      }}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SamlProviderRouteComponent);
