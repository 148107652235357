import { duckPartFactory } from '@cmg/common';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import * as rolodexApiClient from '../../../common/api/rolodexApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { AdminFirmLimitedRead } from '../../../types/domain/firm/Firm';

/**
 * DUCK PARTS DEFINITIONS
 */
export const {
  actionCreators: {
    request: fetchRolodexFirmRequest,
    success: fetchRolodexFirmSucceeded,
    failure: fetchRolodexFirmFailed,
  },
  initialState: rolodexFirmInitialState,
  reducer: rolodexFirmReducer,
  actionTypes: { REQUEST: fetchRolodexFirmRequestType },
  makeSelectors: makeRolodexFirmSelectors,
} = duckPartFactory.makeAPIDuckParts<{ accountId: string }, AdminFirmLimitedRead>({
  prefix: 'global_management/FETCH_ROLODEX_FIRM',
});

export type FetchRolodexFirmAction = ReturnType<typeof fetchRolodexFirmRequest>;

/**
 * REDUCERS
 */

export type ReducerState = {
  rolodexFirm: typeof rolodexFirmInitialState;
};

export const initialState: ReducerState = {
  rolodexFirm: rolodexFirmInitialState,
};

export const {
  selectLoading: selectRolodexFirmLoading,
  selectError: selectRolodexFirmError,
  selectData: selectRolodexFirm,
} = makeRolodexFirmSelectors((state: RootState) => state.adminAccountRolodex.rolodexFirm);

export default combineReducers<ReducerState>({
  rolodexFirm: rolodexFirmReducer,
});

/**
 * SAGAS
 */
export function* fetchAccountRolodexSaga({ payload }: FetchRolodexFirmAction): SagaIterator {
  const response: rolodexApiClient.GetFirmByEntityKeyResponse = yield call(
    rolodexApiClient.getFirmByEntityKey,
    payload.accountId
  );

  if (response.ok && response.data.data && response.data.data[0]) {
    yield put(fetchRolodexFirmSucceeded(response.data.data[0]));
  } else if (!response.ok) {
    yield put(fetchRolodexFirmFailed(response.data.error));
  }
}

export function* adminAccountRolodexSaga() {
  yield takeLatest<FetchRolodexFirmAction>(fetchRolodexFirmRequestType, fetchAccountRolodexSaga);
}
