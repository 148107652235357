import { apiTypes } from '@cmg/common';
import { TextField } from '@cmg/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import { Fragment, VFC } from 'react';

import { ServerErrorAlert } from '../../../../../design-system/alert/ServerErrorAlert';
import { ConfirmationDialog } from '../../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { FormDialogContent } from '../../../../../design-system/dialog/FormDialogContent';
import { FormDialogContentItem } from '../../../../../design-system/dialog/FormDialogContentItem';
import { ChangePassword } from '../../../../../types/domain/self/ChangePassword';
import { ChangePasswordSchema } from './ChangePasswordDialog.model';

export type Props = {
  onCancel: () => void;
  onSubmit: (changePassword: ChangePassword) => void;
  error: Readonly<apiTypes.GenericServerError> | null;
  isSubmitting: Readonly<boolean>;
};

export const ChangePasswordDialog: VFC<Props> = ({ onCancel, onSubmit, error, isSubmitting }) => {
  const formik = useFormik<ChangePassword>({
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: ChangePasswordSchema,
    enableReinitialize: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: val => onSubmit(val),
  });

  return (
    <FormikProvider value={formik}>
      <ConfirmationDialog
        maxWidth="xs"
        title="Change Password"
        isOpen={true}
        isLoading={isSubmitting}
        cancelButtonLabel="Cancel"
        onCancel={onCancel}
        submitButtonLabel="Change Password"
        submitButtonColor="primary"
        onSubmit={() => formik.handleSubmit()}
        content={
          <Form noValidate>
            <FormDialogContent
              error={error && <ServerErrorAlert title="Error" error={error} />}
              items={
                <Fragment>
                  <FormDialogContentItem
                    value={
                      <TextField
                        label="Old Password"
                        type="password"
                        name="oldPassword"
                        placeholder="Enter your old password..."
                        value={formik.values.oldPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                        helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <TextField
                        label="New Password"
                        type="password"
                        name="newPassword"
                        placeholder="Enter your new password..."
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                        helperText={formik.touched.newPassword && formik.errors.newPassword}
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <TextField
                        label="Confirm Password"
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm new password..."
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)
                        }
                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                      />
                    }
                  />
                </Fragment>
              }
            />
          </Form>
        }
      />
    </FormikProvider>
  );
};
