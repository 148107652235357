import { AccessControl, permissionsByEntity } from '@cmg/auth';
import { apiTypes, Panel } from '@cmg/common';
import { draftAccountHeaderSelector, draftAccountListScreenSelector } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useDebouncedCallback } from 'use-debounce';

import { DraftAccountFilters } from '../../../common/api/systemManagementApiClient';
import routeFactory from '../../../common/util/routeFactory';
import AccountSubNavigation from '../../account-detail/AccountSubNavigation';
import DraftAccountList from '../draft-account-list/components/DraftAccountList';
import {
  fetchDraftAccountList,
  selectDraftAccounts,
  selectError,
  selectLoading,
  selectPagination,
  selectUpdateInvestorSubdomainError,
  updateInvestorSubdomain,
} from './ducks';

const mapStateToProps = state => ({
  loading: selectLoading(state),
  draftAccounts: selectDraftAccounts(state),
  pagination: selectPagination(state),
  error: selectError(state),
  investorSubdomainError: selectUpdateInvestorSubdomainError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchDraftAccountList,
      updateInvestorSubdomain,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = RouteComponentProps & StateProps & DispatchProps;

export const DraftAccountListRouteComponent: React.FC<Props> = ({
  loading,
  draftAccounts,
  pagination,
  error,
  investorSubdomainError,
  actions,
}) => {
  const [filters, setFilters] = React.useState<DraftAccountFilters>({});

  const debouncedFetch = useDebouncedCallback(actions.fetchDraftAccountList, 300);

  React.useEffect(() => {
    actions.fetchDraftAccountList({ page: 1, perPage: 25 });
  }, [actions]);

  const handleChangePage = (params: apiTypes.ListParams) => {
    actions.fetchDraftAccountList({
      ...filters,
      ...params,
    });
  };

  const handleChangeFilter = (filters: DraftAccountFilters, debounce?: boolean) => {
    debounce
      ? debouncedFetch({ page: 1, ...filters })
      : actions.fetchDraftAccountList({ page: 1, ...filters });
    setFilters(filters);
  };

  const handleSubdomainChange = (draftAccountId: string, investorSubdomain: string | null) => {
    actions.updateInvestorSubdomain({
      draftAccountId: draftAccountId,
      investorSubdomain: investorSubdomain,
    });
  };

  return (
    <React.Fragment>
      <AccessControl requiredPermissions={[permissionsByEntity.GlobalAccount.FULL]}>
        <Route path={routeFactory.adminAllAccounts.routePath} component={AccountSubNavigation} />
      </AccessControl>
      <Panel testId={draftAccountListScreenSelector.testId}>
        <Panel.Header title="Draft Accounts" testId={draftAccountHeaderSelector.testId} />
        {pagination && (
          <DraftAccountList
            loading={loading}
            draftAccounts={draftAccounts}
            pagination={pagination}
            filters={filters}
            error={error}
            investorSubdomainError={investorSubdomainError}
            onSubdomainChange={handleSubdomainChange}
            onChangeFilters={handleChangeFilter}
            onChangePage={handleChangePage}
          />
        )}
      </Panel>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftAccountListRouteComponent);
