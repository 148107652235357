import { CheckboxField, ModalContent } from '@cmg/common';
import styled from 'styled-components/macro';

export const SModalContentWrapper = styled(ModalContent)`
  margin-bottom: 30px;
`;

export const SButtonGroup = styled.div`
  > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const SRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SFlex = styled.div`
  flex: 1;
`;

export const StyledCheckboxField = styled(CheckboxField)`
  margin: 20px 0 0 2px;
`;

export const SLoadingIndicatorOuterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SLoadingIndicatorInnerWrapper = styled.div`
  width: 140px;
`;
