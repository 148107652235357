import { assets } from '@cmg/common';
import styled from 'styled-components/macro';

export type OnPlatformIconSize = 'S' | 'M';

const sizingMap: { [size in OnPlatformIconSize]: number } = {
  S: 15,
  M: 22,
};

export const SOnPlatformIcon = styled.img.attrs({ src: assets.logoNoText })<{
  isOnPlatform: boolean;
  size?: OnPlatformIconSize;
}>`
  width: ${({ size = 'S' }) => sizingMap[size]}px;
  height: ${({ size = 'S' }) => sizingMap[size]}px;
  visibility: ${({ isOnPlatform }) => (isOnPlatform ? 'visible' : 'hidden')};
`;
