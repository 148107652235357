import { PageLayout } from '@cmg/design-system';
import React from 'react';

import { DescriptionListSkeleton } from '../../../design-system/body-sections/description-list/DescriptionListSkeleton';
import { PageGridSection } from '../../../design-system/body-sections/PageGridSection';
import { IdentityPageContent } from '../../../design-system/IdentityPageContent';
import { HighlightsSkeleton } from '../../../design-system/loading/HighlightsSkeleton';

export const AccountDetailRouteSkeleton: React.FC = () => {
  return (
    <PageLayout header={<HighlightsSkeleton aria-label="Loading Account Highlights" />}>
      <IdentityPageContent
        gridContent={
          <PageGridSection
            size="large"
            title=""
            content={<DescriptionListSkeleton aria-label="Loading Account Details" />}
          />
        }
      />
    </PageLayout>
  );
};
