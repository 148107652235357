import {
  DraftAccountRead as DraftAccount,
  DraftAccountStatus,
} from '@capital-markets-gateway/api-client-identity';
import { TextAgEditor } from '@cmg/common';
import {
  draftAccountListAccountNameSelector,
  draftAccountListInvestorNameSelector,
  draftAccountListInvestorSubdomainSelector,
  draftAccountListStatusSelector,
} from '@cmg/e2e-selectors';
import { ColDef, RowNode } from 'ag-grid-community';
import React from 'react';

export const InvestorNameCellRendererFramework: React.FC<{ data: DraftAccount }> = ({
  data: draftAccount,
}) => (
  <span data-test-id={draftAccountListInvestorNameSelector.testId}>
    {draftAccount.crmInvestorFirmName}
  </span>
);

export const AccountNameCellRendererFramework: React.FC<{ data: DraftAccount }> = ({
  data: draftAccount,
}) => (
  <span data-test-id={draftAccountListAccountNameSelector.testId}>
    {draftAccount.entityMasterInvestorName}
  </span>
);

export const InvestorSubdomainCellRendererFramework: React.FC<{ data: DraftAccount }> = ({
  data: draftAccount,
}) => {
  return (
    <span data-test-id={draftAccountListInvestorSubdomainSelector.testId}>
      {draftAccount.investorSubdomain}
    </span>
  );
};

export const draftAccountStatusComparator = (
  valueA: DraftAccountStatus,
  valueB: DraftAccountStatus,
  nodeA: RowNode,
  nodeB: RowNode,
  _isInverted: Boolean
) => {
  // keep draft status accounts above all other accounts
  if (
    (valueA === DraftAccountStatus.Duplicate || valueA === DraftAccountStatus.Created) &&
    valueB !== DraftAccountStatus.Duplicate &&
    valueA !== DraftAccountStatus.Created
  ) {
    return 1;
  } else if (
    (valueA === DraftAccountStatus.Draft || valueA === DraftAccountStatus.Initiated) &&
    (valueB === DraftAccountStatus.Duplicate || valueB === DraftAccountStatus.Created)
  ) {
    return -1;
  }
  // if both status are same then sort by crmInvestorFirmName
  else if (valueA === valueB) {
    return nodeB.data.crmInvestorFirmName - nodeA.data.crmInvestorFirmName;
  }
  return 0;
};

export const isSubDomainEditable = (params: { data: { status: DraftAccountStatus } }) =>
  params.data.status === DraftAccountStatus.Draft ||
  params.data.status === DraftAccountStatus.Initiated;

export const DraftAccountStatusCellRendererFramework: React.FC<{ data: DraftAccount }> = ({
  data: draftAccount,
}) => {
  return <span data-test-id={draftAccountListStatusSelector.testId}>{draftAccount.status}</span>;
};

export const createColumns = ({ draftAccounts }: { draftAccounts: DraftAccount[] }): ColDef[] => [
  {
    field: 'crmInvestorFirmName',
    headerName: 'Investor Name',
    cellRendererFramework: InvestorNameCellRendererFramework,
    suppressColumnsToolPanel: true,
  },
  {
    field: 'entityMasterInvestorName',
    headerName: 'Account Name',
    cellRendererFramework: AccountNameCellRendererFramework,
  },
  {
    field: 'investorSubdomain',
    headerName: 'Account Subdomain',
    width: 120,
    editable: isSubDomainEditable,
    singleClickEdit: true,
    cellRendererFramework: InvestorSubdomainCellRendererFramework,
    cellEditorFramework: TextAgEditor,
  },
  { field: 'investorCmgEntityKey', headerName: 'CMG Entity Key', width: 120 },
  {
    field: 'status',
    headerName: 'Status',
    sort: 'desc',
    comparator: draftAccountStatusComparator,
    cellRendererFramework: DraftAccountStatusCellRendererFramework,
    width: 120,
  },
  {
    field: 'entityMasterFirmEmails',
    headerName: 'Entity Master Firm Emails',
    maxWidth: 250,
  },
  {
    field: 'crmInvestorSuggestedSubdomain',
    headerName: 'Suggested Subdomain',
    maxWidth: 200,
  },
  { field: 'bankAccountSubdomain', headerName: 'Invited By', width: 150 },
];
