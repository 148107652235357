import { Grid, Stack } from '@cmg/design-system';
import { ElementType, FormHTMLAttributes, ReactNode } from 'react';

import { PageGridSectionSize, usePageGridSectionSizes } from './PageGridSection.model';
import { PageGridSectionHeader } from './PageGridSectionHeader';

export type PageGridSectionProps = {
  /**
   * Size of the section used to determine how much space should section take on different screen sizes.
   * @default 'small'
   */
  size?: Readonly<PageGridSectionSize>;
  /**
   * Section title
   */
  title: Readonly<string>;
  /**
   * Specify when the grid section should be wrapper in Form, otherwise leave undefined
   */
  FormComponent?: ElementType<FormHTMLAttributes<HTMLFormElement>>;
  /**
   * Props to be passed to wrapping Form
   */
  formComponentProps?: Readonly<FormHTMLAttributes<HTMLFormElement>>;
  /**
   * Actions available in the section
   */
  actions?: Readonly<ReactNode>;
  /**
   * Error content
   */
  error?: Readonly<ReactNode>;
  /**
   * Content of the section
   */
  content?: Readonly<ReactNode>;
};

export function PageGridSection({
  title,
  FormComponent,
  formComponentProps,
  actions,
  error,
  content,
  size = 'small',
  ...gridProps
}: PageGridSectionProps): JSX.Element {
  const { xs, sm, md, lg, xl } = usePageGridSectionSizes(size);

  return (
    <Grid
      {...gridProps}
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      component={FormComponent ?? 'div'}
      {...formComponentProps}
    >
      <Stack rowGap={1} role="region" aria-label={title}>
        <PageGridSectionHeader title={title} actions={actions} />
        {error}
        {content}
      </Stack>
    </Grid>
  );
}
