import { apiTypes } from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';

import usePrevious from '../../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../../design-system/alert/ServerErrorAlert';
import { PageGridSection } from '../../../../../../design-system/body-sections/PageGridSection';
import { PageGridSectionActions } from '../../../../../../design-system/body-sections/PageGridSectionActions';
import { PasswordPolicy } from '../../../../../../types/domain/password-policy/passwordPolicy';
import { ProviderDetailsSectionEdit } from './ProviderDetailsSectionEdit';
import { ProviderDetailsSectionReadonly } from './ProviderDetailsSectionReadonly';
import { LocalLoginProviderFormValues, LocalLoginUpdateSchema } from './utils';

export type Props = Readonly<{
  readonly requireTwoFactorAuth: boolean;
  readonly passwordPolicy: PasswordPolicy;
  readonly canEdit: boolean;
  readonly error: apiTypes.GenericServerError | null;
  readonly isSubmitting: boolean;
  readonly onSubmit: (data: LocalLoginProviderFormValues) => void;
}>;

export const ProviderDetailsSection: FC<Props> = ({
  requireTwoFactorAuth,
  passwordPolicy,
  canEdit,
  error,
  isSubmitting,
  onSubmit,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const formik = useFormik<LocalLoginProviderFormValues>({
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: LocalLoginUpdateSchema,
    initialValues: {
      minLength: passwordPolicy.minLength || 8,
      uniqueChars: passwordPolicy.uniqueChars || 0,
      requireNonAlphanumeric: passwordPolicy.requireNonAlphanumeric,
      requireLowercase: passwordPolicy.requireLowercase,
      requireUppercase: passwordPolicy.requireUppercase,
      requireDigit: passwordPolicy.requireDigit,
      requireUniquePasswordOnReset: passwordPolicy.requireUniquePasswordOnReset,
      passwordExpiresInDays: passwordPolicy.passwordExpiresInDays,
      requirePasswordExpiration: passwordPolicy.requirePasswordExpiration,
      passwordHistoryLength: passwordPolicy.passwordHistoryLength || 0,
      requireTwoFactorAuth: requireTwoFactorAuth,
    },
    onSubmit: val => onSubmit(val),
  });

  const { resetForm } = formik;
  const previousIsEditing = usePrevious(isEditing);
  useEffect(() => {
    if (previousIsEditing && !isEditing) {
      resetForm();
    }
  }, [isEditing, previousIsEditing, resetForm]);

  const previousIsSubmitting = usePrevious(isSubmitting);
  useEffect(() => {
    if (previousIsSubmitting && !isSubmitting && !error) {
      setIsEditing(false);
    }
  }, [error, isSubmitting, previousIsSubmitting, resetForm]);

  return (
    <FormikProvider value={formik}>
      <PageGridSection
        size="medium"
        title="Provider Details"
        FormComponent={Form}
        formComponentProps={{ noValidate: true }}
        actions={
          canEdit ? (
            <PageGridSectionActions
              isSubmitting={isSubmitting}
              isEditing={isEditing}
              onEdit={() => setIsEditing(true)}
              onCancel={() => setIsEditing(false)}
            />
          ) : undefined
        }
        error={error && <ServerErrorAlert title="Error" error={error} />}
        content={
          isEditing ? (
            <ProviderDetailsSectionEdit />
          ) : (
            <ProviderDetailsSectionReadonly
              passwordPolicy={passwordPolicy}
              requireTwoFactorAuth={requireTwoFactorAuth}
            />
          )
        }
      />
    </FormikProvider>
  );
};
