import { Table, TableBody, TableContainer } from '@cmg/design-system';
import React from 'react';

import { AccountPermission } from '../../../../../types/domain/account/account';
import { usePermissionsTable } from '../../../../shared/components/permissions-table/hooks/usePermissionsTable';
import PermissionGroup from '../../../../shared/components/permissions-table/PermissionGroup';

export type Props = Readonly<{
  readonly selectedPermissions: string[] | null;
  readonly accountPermissions: AccountPermission[];
  readonly renderRow: (params: {
    entity: string;
    description: string;
    entityPermissions: string[];
    sanitizedSelectedPermissions: { [key: string]: string };
    accountPermissions: AccountPermission[];
  }) => React.ReactNode;
  readonly renderGroupHeader: (groupHeader: string) => React.ReactNode;
  readonly renderTableHeader: () => React.ReactNode;
}>;

export const PermissionsTable: React.FC<Props> = ({
  selectedPermissions,
  accountPermissions,
  renderRow,
  renderGroupHeader,
  renderTableHeader,
}) => {
  const { accountEntities, groups, permissionsByGroup, sanitizedSelectedPermissions } =
    usePermissionsTable({
      selectedPermissions,
      accountPermissions,
    });

  return (
    <TableContainer>
      <Table aria-label="Permissions">
        {renderTableHeader()}
        {groups.map(group => (
          <TableBody key={group}>
            <PermissionGroup
              groupName={group}
              accountEntities={accountEntities}
              permissionsByGroup={permissionsByGroup}
              renderGroupHeader={renderGroupHeader}
              renderRow={params =>
                renderRow({
                  ...params,
                  accountPermissions,
                  sanitizedSelectedPermissions,
                })
              }
            />
          </TableBody>
        ))}
      </Table>
    </TableContainer>
  );
};
