import { DotStatus } from '@cmg/design-system';
import { FC, Fragment } from 'react';

import {
  userAccountConfirmationLabels,
  UserStatus,
  userStatusLabels,
} from '../../../../../types/domain/user/constants';
import { User } from '../../../../../types/domain/user/user';

type Props = Readonly<{
  user: Readonly<User>;
}>;

export const UserHighlightsTitleItems: FC<Props> = ({ user }) => {
  return (
    <Fragment>
      <DotStatus
        color={user.status === UserStatus.ACTIVE ? 'success' : 'error'}
        label={
          user.status === UserStatus.ACTIVE
            ? userStatusLabels[UserStatus.ACTIVE]
            : userStatusLabels[UserStatus.DISABLED]
        }
      />
      <DotStatus
        color={user.accountConfirmed ? 'success' : 'error'}
        label={
          user.accountConfirmed
            ? userAccountConfirmationLabels.CONFIRMED
            : userAccountConfirmationLabels.UNCONFIRMED
        }
      />
    </Fragment>
  );
};
