import { AccessControl, permissionsByEntity } from '@cmg/auth';
import { apiTypes } from '@cmg/common';
import { Button } from '@cmg/design-system';
import { accountUserListScreenSelector } from '@cmg/e2e-selectors';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useDebouncedCallback } from 'use-debounce';

import { AccountUserFilters } from '../../../../common/api/types/AccountUserFilters';
import { useDocumentTitle } from '../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../../common/util/routeFactory';
import { PageGridSection } from '../../../../design-system/body-sections/PageGridSection';
import { IdentityPageContent } from '../../../../design-system/IdentityPageContent';
import { UserStatus } from '../../../../types/domain/user/constants';
import UserList from '../../../account-detail/shared/components/user-list/UserList';
import {
  selectAccount,
  selectAccountType,
  selectLoading,
} from '../../../account-detail/shared/ducks';
import {
  downloadUserList,
  fetchUserList,
  selectError,
  selectPagination,
  selectUsers,
} from '../../../account-detail/user-list/ducks';
import UserCreateRoute from '../user-create/UserCreateRoute';

const mapStateToProps = state => ({
  loading: selectLoading(state),
  account: selectAccount(state),
  accountType: selectAccountType(state),
  users: selectUsers(state),
  pagination: selectPagination(state),
  error: selectError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUserList,
      downloadUserList,
    },
    dispatch
  ),
});

const initialFilters = { userStatus: UserStatus.ACTIVE };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = StateProps &
  DispatchProps & {
    canCreateOrEditUser?: boolean;
  };

export const AccountUserListRouteComponent: FC<Props> = ({
  loading,
  account,
  accountType,
  users,
  pagination,
  error,
  actions,
  canCreateOrEditUser,
}) => {
  const [filters, setFilters] = useState<AccountUserFilters>(initialFilters);

  useDocumentTitle(routeFactory.accountUsers.getDocumentTitle({ accountName: account?.name }));

  const debouncedFetch = useDebouncedCallback(actions.fetchUserList, 300);

  useEffect(() => {
    actions.fetchUserList({ page: 1, perPage: 25, ...initialFilters });
  }, [actions]);

  const handleChangePage = (params: apiTypes.ListParams) => {
    actions.fetchUserList({
      ...filters,
      ...params,
    });
  };

  const handleChangeFilter = (filters: AccountUserFilters, debounce?: boolean) => {
    debounce
      ? debouncedFetch({ page: 1, ...filters })
      : actions.fetchUserList({ page: 1, ...filters });
    setFilters(filters);
  };

  if (!account) {
    return null;
  }

  return (
    <div data-test-id={accountUserListScreenSelector.testId}>
      <AccessControl
        requireAllPermissions={false}
        requiredPermissions={[permissionsByEntity.User.FULL, permissionsByEntity.GlobalUser.FULL]}
      >
        <Route exact path={routeFactory.accountUserNew.routePath} component={UserCreateRoute} />
      </AccessControl>
      <IdentityPageContent
        gridContent={
          <PageGridSection
            size="large"
            title={`Users - ${account ? account.name : ''}`}
            actions={
              canCreateOrEditUser ? (
                <Button
                  variant="contained"
                  href={routeFactory.accountUserNew.getUrlPath({
                    accountSubdomain: account.subdomain,
                  })}
                >
                  Create New User
                </Button>
              ) : undefined
            }
            content={
              account &&
              pagination && (
                <UserList
                  loading={loading}
                  account={account}
                  accountType={accountType}
                  users={users}
                  pagination={pagination}
                  error={error}
                  filters={filters}
                  onChangeFilters={handleChangeFilter}
                  onChangePage={handleChangePage}
                  onDownload={actions.downloadUserList}
                />
              )
            }
          />
        }
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountUserListRouteComponent);
