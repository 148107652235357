import { checkPermissions, getUserPermissions, permissionsByEntity } from '@cmg/auth';
import { apiTypes, Banner, Panel, ServerErrors, SuccessButton, Table } from '@cmg/common';
import React from 'react';
import { ObjectSchema } from 'yup';

import { FirmDetailSection, NewRecordTempId } from '../../../../features/rolodex/firm-detail/ducks';
import { UUID } from '../../../../types/common';
import { SNoOptionWrapper } from './GenericFirmRecordPanel.styles';
import GenericFirmRecordRow from './GenericFirmRecordRow';
import { FirmRecordColDef, FirmRecordRowData } from './GenericPanelTypes';

type EditingProps<TValues> =
  | {
      isEditable?: true | undefined;
      addRecordButtonLabel?: string;
      error?: (() => React.ReactElement) | apiTypes.GenericServerError | null;
      editingRecordIds?: UUID[];
      updatingRecordIds?: UUID[];
      onEdit?: (recordId: UUID) => void;
      onCancelEdit?: (recordId: UUID) => void;
      onCreate?: (values: TValues) => void;
      onUpdate?: (values: TValues) => void;
      validationSchema?: ObjectSchema<{}>;
    }
  | {
      isEditable?: false;
      addRecordButtonLabel?: never;
      error?: never;
      editingRecordIds?: never;
      updatingRecordIds?: never;
      onEdit?: never;
      onCancelEdit?: never;
      onCreate?: never;
      onUpdate?: never;
      validationSchema?: never;
    };

export type Props<TValues> = {
  sectionId?: FirmDetailSection;
  title: string;
  rows: FirmRecordRowData<TValues>[];
  columns: FirmRecordColDef<TValues>[];
} & EditingProps<TValues>;

/**
 * This is the abstract record table used in Firm Detail UIs.
 * It primarily handles rendering each existing record and the creation of new
 * records.
 *
 * @isEditable - whether or not the record row is editable.
 * @param sectionId - the FirmDetailSection ID for the section.
 * @param title - the title displayed on the panel.
 * @addRecordButtonLabel - the label of the button that creates a new record.
 * @rows - the record set.
 * @error - the server error returned from a record CRUD request.
 * @editingRecordIds - the list of record IDs that are currently in edit mode.
 * @updatingRecordIds - the list of record IDs that have active API requests.
 * @columns - a data representation of the table columns/record fields, along with
 *  how they should render.
 * @onEdit - callback prop that's triggered when a record row enters edit state.
 * @onCancelEdit - callback prop that's triggered when a record row exits edit state.
 * @onCreate - callback prop that's triggered when a record is to be created.
 * @onUpdate - callback prop that's triggered when a record is to be updated.
 * @validationSchema - the yup validation schema to use in record forms.
 */
class GenericFirmRecordPanel<TValues extends { id: UUID }> extends React.Component<Props<TValues>> {
  render() {
    const {
      isEditable = true,
      sectionId,
      title,
      error,
      editingRecordIds = [],
      updatingRecordIds = [],
      addRecordButtonLabel,
      columns,
      rows,
      onCreate,
      onUpdate,
      onEdit,
      onCancelEdit,
      validationSchema,
    } = this.props;
    const newRecordTempId = sectionId ? NewRecordTempId[sectionId] : null;
    const isAddingRecord = editingRecordIds.includes(newRecordTempId);
    const emptyRecordRow = columns.reduce(
      (acc, column) => ({
        ...acc,
        [column.field]: '',
      }),
      {}
    ) as TValues;
    const canEdit = checkPermissions(getUserPermissions(), [permissionsByEntity.Firm.FULL]);
    const canBeEdited = isEditable && canEdit;

    return (
      <Panel>
        <Panel.Header
          title={title}
          rightContent={
            canBeEdited &&
            !isAddingRecord && (
              <SuccessButton onClick={() => onEdit && onEdit(newRecordTempId)}>
                {addRecordButtonLabel}
              </SuccessButton>
            )
          }
        />
        {error && (
          <Banner variant="error" showIcon={false}>
            {error instanceof Function ? (
              <React.Fragment>{error()}</React.Fragment>
            ) : (
              <ServerErrors error={error} />
            )}
          </Banner>
        )}
        <Table striped responsive={false}>
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.field}>{column.headerName}</th>
              ))}
              <td />
              {canBeEdited && <td />}
            </tr>
          </thead>
          <tbody>
            {!rows.length && !isAddingRecord && (
              <tr>
                <td colSpan={columns.length}>
                  <SNoOptionWrapper>No records available</SNoOptionWrapper>
                </td>
                <td />
                {canBeEdited && <td />}
              </tr>
            )}
            {rows.map(row => (
              <GenericFirmRecordRow<TValues>
                key={row.id}
                isEditable={canBeEdited}
                row={row}
                columns={columns}
                isEditing={editingRecordIds.includes(row.id) || false}
                isUpdating={updatingRecordIds.includes(row.id) || false}
                onEdit={() => canEdit && onEdit && onEdit(row.id)}
                onCancelEdit={() => onCancelEdit && onCancelEdit(row.id)}
                onSubmit={values => onUpdate && onUpdate({ ...values, id: row.id })}
                validationSchema={validationSchema}
              />
            ))}
            {isAddingRecord && (
              <GenericFirmRecordRow<TValues>
                row={emptyRecordRow}
                columns={columns}
                isEditing
                isNewRecord
                isUpdating={updatingRecordIds.includes(newRecordTempId) || undefined}
                onCancelEdit={() => onCancelEdit && onCancelEdit(newRecordTempId)}
                onSubmit={values => onCreate && onCreate({ ...values, id: newRecordTempId })}
                validationSchema={validationSchema}
              />
            )}
          </tbody>
        </Table>
      </Panel>
    );
  }
}

export default GenericFirmRecordPanel;
