import React from 'react';
import styled from 'styled-components/macro';

import {
  cmgSectorSchemeOptions,
  firmSectorSchemeTypeLabels,
  firmSectorSchemeTypeOptions,
} from '../../../../types/domain/firm/constants';
import {
  recordStatusLabels,
  recordStatusOptions,
} from '../../../../types/domain/record-status/constants';
import { StyledSelectField } from './inputs/RecordInputs.styles';

export const SGroupLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text.color.dark};
  background-color: ${({ theme }) => theme.background.color.light};
  padding: 8px 12px;
`;

/**
 * The column configuration for the Firm Sectors Record Table
 */
export const getFirmSectorColumns = () => [
  {
    field: 'scheme',
    headerName: 'Type',
    editCellRendererFramework: (row, disabled) => (
      <StyledSelectField name="scheme" options={firmSectorSchemeTypeOptions} disabled={disabled} />
    ),
    cellRendererFramework: row => firmSectorSchemeTypeLabels[row.scheme],
  },
  {
    field: 'code',
    headerName: 'Sector',
    editCellRendererFramework: (row, disabled) => (
      <StyledSelectField
        name="code"
        options={cmgSectorSchemeOptions}
        renderGroupLabel={data => <SGroupLabel>{data.label}</SGroupLabel>}
        disabled={disabled}
      />
    ),
    cellRendererFramework: row => {
      const sectorCode = row.code;
      const superSector = cmgSectorSchemeOptions.find(item => {
        return item.options.some(option => option.value === sectorCode);
      });
      const subSector = superSector
        ? superSector.options.find(option => option.value === sectorCode)
        : null;

      if (!superSector || !subSector) {
        return sectorCode;
      }

      return <span>{`${superSector.label} > ${subSector.label}`}</span>;
    },
  },
  {
    field: 'recordStatus',
    headerName: 'Status',
    editCellRendererFramework: (row, disabled) => (
      <StyledSelectField name="recordStatus" options={recordStatusOptions} disabled={disabled} />
    ),
    cellRendererFramework: row => recordStatusLabels[row.recordStatus],
  },
];
