import { apiTypes, reduxUtil } from '@cmg/common';
import { AnyAction, combineReducers } from 'redux';
import { show } from 'redux-modal';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as accountApiClient from '../../../../common/api/accountApiClient';
import systemManagementApiClient, {
  CreateApiKeyResponse,
  GetAccountsRolesResponse,
} from '../../../../common/api/systemManagementApiClient';
import { RootState } from '../../../../common/redux/rootReducer';
import { ApiKeyCreate } from '../../../../types/domain/api-keys/api-key';
import { Role } from '../../../../types/domain/role/role';
import { selectSelfSubdomain } from '../../../shared/ducks';
import { selectAccountSubdomain } from '../../shared/ducks';
import { API_KEY_MODAL_ID } from '../shared/ApiKeyModal';

const createReducer = reduxUtil.createReducer;

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  CREATE_API_KEY_REQUEST = 'global_management/api-keys/CREATE_API_KEY_REQUEST',
  CREATE_API_KEY_SUCCESS = 'global_management/api-keys/CREATE_API_KEY_SUCCESS',
  CREATE_API_KEY_FAILURE = 'global_management/api-keys/CREATE_API_KEY_FAILURE',
  FETCH_ACCOUNT_ROLES_REQUEST = 'global_management/api-keys/FETCH_ACCOUNT_ROLES_REQUEST',
  FETCH_ACCOUNT_ROLES_SUCCESS = 'global_management/api-keys/FETCH_ACCOUNT_ROLES_SUCCESS',
  FETCH_ACCOUNT_ROLES_FAILURE = 'global_management/api-keys/FETCH_ACCOUNT_ROLES_FAILURE',
  RESET_STATE = 'global_management/api-keys/RESET_STATE',
}

/**
 * ACTION CREATORS
 */
export const resetState = () => ({
  type: ActionTypes.RESET_STATE,
});

export const createApiKey = (params: { apiKey: ApiKeyCreate }) => ({
  type: ActionTypes.CREATE_API_KEY_REQUEST,
  payload: { ...params },
});

export const createApiKeySuccess = () => ({
  type: ActionTypes.CREATE_API_KEY_SUCCESS,
});

export const createApiKeyFailure = ({ error }) => ({
  type: ActionTypes.CREATE_API_KEY_FAILURE,
  payload: {
    error,
  },
});

export const fetchAccountRoles = (params: { page: number; perPage: number }) => ({
  type: ActionTypes.FETCH_ACCOUNT_ROLES_REQUEST,
  payload: params,
});

export const fetchAccountRolesSuccess = (params: {
  roles: Role[];
  pagination: apiTypes.Pagination;
}) => ({
  type: ActionTypes.FETCH_ACCOUNT_ROLES_SUCCESS,
  payload: {
    roles: params.roles,
    pagination: params.pagination,
  },
});

export const fetchAccountRolesFailure = () => ({
  type: ActionTypes.FETCH_ACCOUNT_ROLES_FAILURE,
});

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.RESET_STATE]: ReturnType<typeof resetState>;
  [ActionTypes.CREATE_API_KEY_REQUEST]: ReturnType<typeof createApiKey>;
  [ActionTypes.CREATE_API_KEY_SUCCESS]: ReturnType<typeof createApiKeySuccess>;
  [ActionTypes.CREATE_API_KEY_FAILURE]: ReturnType<typeof createApiKeyFailure>;
  [ActionTypes.FETCH_ACCOUNT_ROLES_REQUEST]: ReturnType<typeof fetchAccountRoles>;
  [ActionTypes.FETCH_ACCOUNT_ROLES_SUCCESS]: ReturnType<typeof fetchAccountRolesSuccess>;
  [ActionTypes.FETCH_ACCOUNT_ROLES_FAILURE]: ReturnType<typeof fetchAccountRolesFailure>;
};

/**
 * REDUCERS
 */
export type ReducerState = {
  error: apiTypes.GenericServerError | null;
  submitting: boolean;
  roles: Role[];
};

export const initialState = {
  error: null,
  submitting: false,
  roles: [],
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.CREATE_API_KEY_REQUEST]: () => null,
  [ActionTypes.CREATE_API_KEY_SUCCESS]: () => null,
  [ActionTypes.CREATE_API_KEY_FAILURE]: (curState, { payload }) => payload.error,
});

const submittingReducer = createReducer<ReducerState['submitting'], Actions>(
  initialState.submitting,
  {
    [ActionTypes.CREATE_API_KEY_REQUEST]: () => true,
    [ActionTypes.CREATE_API_KEY_SUCCESS]: () => false,
    [ActionTypes.CREATE_API_KEY_FAILURE]: () => false,
  }
);

const rolesReducer = createReducer<ReducerState['roles'], Actions>(initialState.roles, {
  [ActionTypes.FETCH_ACCOUNT_ROLES_SUCCESS]: (curState, { payload }) => payload.roles,
});

// This reducer acts on the entire state of this duck. Has access to duck state and must return duck state.
const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_STATE]: () => ({ ...initialState }),
});

const combinedReducers = combineReducers<ReducerState>({
  error: errorReducer,
  submitting: submittingReducer,
  roles: rolesReducer,
});

// Combines our individual slice reducers and the cross slice reducer.
export default function duckReducer(state: ReducerState = initialState, action: AnyAction) {
  const intermediateState = combinedReducers(state, action);
  return crossSliceReducer(intermediateState, action);
}

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.adminAccountApiKeyCreate;
export const selectAccountRoles = state => selectState(state).roles;
export const selectError = state => selectState(state).error;
export const selectSubmitting = state => selectState(state).submitting;

/**
 * SAGAS
 */
export function* createApiKeySaga({
  payload,
}: Actions[ActionTypes.CREATE_API_KEY_REQUEST]): SagaIterator {
  const { apiKey } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: accountApiClient.CreateMyApiKeyResponse | CreateApiKeyResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.createMyApiKey, apiKey);
  } else {
    response = yield call(systemManagementApiClient.admin.createApiKey, apiKey, accountSubdomain);
  }

  if (response.ok) {
    yield put(createApiKeySuccess());
    const { accessToken } = response.data;
    yield put(show(API_KEY_MODAL_ID, { accessToken }));
  } else {
    yield put(createApiKeyFailure({ error: response.data.error }));
  }
}

export function* fetchAccountRolesSaga({
  payload,
}: Actions[ActionTypes.FETCH_ACCOUNT_ROLES_REQUEST]): SagaIterator {
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: accountApiClient.GetMyAccountRolesResponse | GetAccountsRolesResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyAccountRoles, payload);
  } else {
    response = yield call(
      systemManagementApiClient.admin.getAccountRoles,
      accountSubdomain,
      payload
    );
  }

  if (response.ok) {
    const { data: roles, pagination } = response.data;
    yield put(fetchAccountRolesSuccess({ roles, pagination }));
  } else {
    yield put(fetchAccountRolesFailure());
  }
}

export function* adminAccountApiKeyCreateSaga() {
  yield takeLatest<Actions[ActionTypes.CREATE_API_KEY_REQUEST]>(
    ActionTypes.CREATE_API_KEY_REQUEST,
    createApiKeySaga
  );

  yield takeLatest<Actions[ActionTypes.FETCH_ACCOUNT_ROLES_REQUEST]>(
    ActionTypes.FETCH_ACCOUNT_ROLES_REQUEST,
    fetchAccountRolesSaga
  );
}
