import { DraftAccountRead as DraftAccount } from '@capital-markets-gateway/api-client-identity';
import { apiTypes, duckPartFactory } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import systemManagementApiClient, {
  GetDraftAccountParams,
  GetDraftAccountsResponse,
  UpdateInvestorSubdomainResponse,
} from '../../../common/api/systemManagementApiClient';
import { RootState } from '../../../common/redux/rootReducer';

type SuccessValue = {
  data: DraftAccount[];
  pagination: apiTypes.Pagination;
};

const draftAccountListDuckParts = duckPartFactory.makeAPIDuckParts<
  GetDraftAccountParams,
  SuccessValue
>({
  prefix: 'GLOBAL_MANAGEMENT/DRAFT_ACCOUNT_LIST',
});

export const updateInvestorSubdomainDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    investorSubdomain: string | null;
    draftAccountId: string;
  },
  DraftAccount
>({
  prefix: 'GLOBAL_MANAGEMENT/UPDATE_INVESTOR_SUBDOMAIN',
});

/**
 * ACTION CREATORS
 */

export const fetchDraftAccountList = draftAccountListDuckParts.actionCreators.request;
type RequestAction = ReturnType<typeof fetchDraftAccountList>;
export const updateInvestorSubdomain = updateInvestorSubdomainDuckParts.actionCreators.request;
type UpdateInvestorSubdomainAction = ReturnType<typeof updateInvestorSubdomain>;
/**
 * REDUCERS
 */

export const initialState = {
  draftAccountsPart: draftAccountListDuckParts.initialState,
  updateInvestorSubdomainPart: updateInvestorSubdomainDuckParts.initialState,
};
export type ReducerState = typeof initialState;
export default combineReducers<ReducerState>({
  draftAccountsPart: draftAccountListDuckParts.reducer,
  updateInvestorSubdomainPart: updateInvestorSubdomainDuckParts.reducer,
});

/**
 * SELECTORS
 */

const selectLocalState = (state: RootState) => state.draftAccountList;
const draftAccountSelectors = draftAccountListDuckParts.makeSelectors(
  state => selectLocalState(state).draftAccountsPart
);
const updateInvestorSubdomainSelectors = updateInvestorSubdomainDuckParts.makeSelectors(
  state => selectLocalState(state).updateInvestorSubdomainPart
);

export const selectError = draftAccountSelectors.selectFailed;
export const selectDraftAccounts = (state: RootState) => {
  const successBody = draftAccountSelectors.selectData(state);
  return successBody ? successBody.data : [];
};
export const selectPagination = (state: RootState) => {
  const successBody = draftAccountSelectors.selectData(state);
  return successBody ? successBody.pagination : null;
};
export const selectLoading = draftAccountSelectors.selectLoading;

export const selectUpdateInvestorSubdomainError = updateInvestorSubdomainSelectors.selectError;
/**
 * SAGAS
 */

export function* fetchDraftAccountListSaga({ payload }: RequestAction): SagaIterator {
  const response: GetDraftAccountsResponse = yield call(
    systemManagementApiClient.admin.getDraftAccounts,
    payload
  );
  if (response.ok) {
    yield put(draftAccountListDuckParts.actionCreators.success(response.data));
  } else {
    yield put(draftAccountListDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* updateInvestorSubdomainSaga({ payload }: UpdateInvestorSubdomainAction) {
  const { draftAccountId, investorSubdomain } = payload;

  const response: UpdateInvestorSubdomainResponse = yield call(
    systemManagementApiClient.admin.updateInvestorSubdomain,
    draftAccountId,
    investorSubdomain !== '' ? investorSubdomain : null
  );

  if (response.ok) {
    yield put(updateInvestorSubdomainDuckParts.actionCreators.success(response.data));
    SnackbarManager.success('Investor Subdomain successfully updated');
  } else {
    yield put(updateInvestorSubdomainDuckParts.actionCreators.failure(response.data.error));
    const errorMessage =
      response.data.error.details.length > 0
        ? response.data.error.details[0].message
        : ['Cannot Update Investor Subdomain'];
    SnackbarManager.error(...errorMessage);
  }
}

export function* draftAccountListSaga() {
  yield takeLatest<RequestAction>(
    draftAccountListDuckParts.actionTypes.REQUEST,
    fetchDraftAccountListSaga
  );
  yield takeLatest<UpdateInvestorSubdomainAction>(
    updateInvestorSubdomainDuckParts.actionTypes.REQUEST,
    updateInvestorSubdomainSaga
  );
}
