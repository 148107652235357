import { apiTypes, DataGrid } from '@cmg/common';
import React from 'react';

import { ApiKey } from '../../../../../types/domain/api-keys/api-key';
import { getApiKeyListGridColumns } from './ApiKeyListGridColumns';

type Props = Readonly<{
  readonly keys: ApiKey[];
  readonly pagination: apiTypes.Pagination;
  readonly loading: boolean;
  readonly onChangePage: (params: apiTypes.ListParams) => void;
  readonly accountSubdomain: string;
}>;

/**
 * Displays Account's API Keys in a table view
 */

export const ApiKeyListGrid: React.FC<Props> = ({
  keys,
  loading,
  pagination,
  onChangePage,
  accountSubdomain,
}) => {
  return (
    <DataGrid<ApiKey>
      extended={{ withMargin: false }}
      columns={getApiKeyListGridColumns(accountSubdomain)}
      rows={keys}
      resizeBy="grid"
      totalPages={pagination.totalPages}
      pagination={{
        page: pagination.activePage,
        perPage: pagination.perPage,
      }}
      onPaginationChange={({ page, perPage, orderField, orderDirection }) => {
        const params = {
          page,
          perPage,
          orderField,
          orderDirection:
            orderDirection === 'asc' ? apiTypes.SortDirection.ASC : apiTypes.SortDirection.DESC,
        };

        onChangePage(params);
      }}
      gridOptions={{
        suppressCellSelection: true,
        suppressRowClickSelection: true,
        overlayNoRowsTemplate: 'No API Keys found',
      }}
      loading={loading}
    />
  );
};
