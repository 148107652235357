import { InvestorFirmAdminRead as CrmInvestorFirm } from '@capital-markets-gateway/api-client-rolodex';

import { FirmAddressType } from '../../../../../types/domain/firm/constants';
import { FirmMatchRow } from '../../../../../types/domain/firm-match/FirmMatch';
import { FirmMatchAddress } from '../../../../../types/domain/firm-match/FirmMatchAddress';
import { FirmMatchIdentifiers } from '../../../../../types/domain/firm-match/FirmMatchIdentifiers';
import { FirmMatchName } from '../../../../../types/domain/firm-match/FirmMatchName';

export const getFirmMatchFromCrmInvestorFirm = (crmInvestorFirm: CrmInvestorFirm) => {
  const { id, name, address1, address2, webDomains, lei, cik1, cik2, crd, mpid, factSet } =
    crmInvestorFirm;

  const firmMatchIdentifiers: FirmMatchIdentifiers = {
    lei: lei ? [lei] : null,
    cik: [...(cik1 ? [cik1] : []), ...(cik2 ? [cik2] : [])],
    ticker: null,
    crd: crd ? [crd] : null,
    mpid,
    morningStar: null,
    isin: null,
    cusip: null,
    factSet,
  };

  const firmMatchAddresses: FirmMatchAddress[] = [
    {
      type: FirmAddressType.OTHER,
      address: address1,
    },
    ...(address2
      ? [
          {
            type: FirmAddressType.OTHER,
            address: address2,
          },
        ]
      : []),
  ];

  const firmMatchName: FirmMatchName = {
    type: null,
    value: name,
    isDisplayName: true,
  };

  const firmMatch: FirmMatchRow = {
    id,
    isOnPlatform: false,
    linkFrequencyData: null,
    score: null,
    rolodexKey: null,
    firmSource: null,
    isConnected: false,
    webDomains,
    sectors: null,
    roles: null,
    parentId: null,
    ultimateParentId: null,
    parentName: null,
    ultimateParentName: null,
    entityType: null,
    industryType: null,
    status: null,
    identifiers: firmMatchIdentifiers,
    addresses: firmMatchAddresses,
    names: [firmMatchName],
  };

  return firmMatch;
};

export default getFirmMatchFromCrmInvestorFirm;
