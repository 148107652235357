import { UserStatus } from '@capital-markets-gateway/api-client-identity';
import { apiTypes } from '@cmg/common';
import { Button, DialogContentText } from '@cmg/design-system';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';

import usePrevious from '../../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../../design-system/alert/ServerErrorAlert';
import { ConfirmationDialog } from '../../../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { FormDialogContent } from '../../../../../../design-system/dialog/FormDialogContent';
import { FormDialogContentItem } from '../../../../../../design-system/dialog/FormDialogContentItem';

export type Props = Readonly<{
  onReset: () => void;
  userStatus: Readonly<UserStatus>;
  isLoading: Readonly<boolean>;
  error: Readonly<apiTypes.GenericServerError | null>;
  onUpdateUserStatus: (userStatus: UserStatus) => void;
}>;

export const UpdateStatusAction: FC<Props> = ({
  onReset,
  userStatus,
  isLoading,
  error,
  onUpdateUserStatus,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const previousIsLoading = usePrevious(isLoading);
  useEffect(() => {
    if (previousIsLoading && !isLoading && !error) {
      setIsDialogOpen(false);
    }
  }, [error, isLoading, previousIsLoading]);

  const previousIsDialogOpen = usePrevious(isDialogOpen);
  useEffect(() => {
    if (previousIsDialogOpen && !isDialogOpen) {
      onReset();
    }
  }, [isDialogOpen, onReset, previousIsDialogOpen]);

  const actionLabel = useMemo(() => {
    return userStatus === UserStatus.DISABLED ? 'Enable User' : 'Disable User';
  }, [userStatus]);

  const actionColor = useMemo(() => {
    return userStatus === UserStatus.DISABLED ? 'primary' : 'error';
  }, [userStatus]);

  const dialogContentText = useMemo(() => {
    return `User will be ${
      userStatus === UserStatus.DISABLED ? 'enabled' : 'disabled'
    }. Are you sure you want to proceed?`;
  }, [userStatus]);

  return (
    <Fragment>
      <Button
        variant="contained"
        color={actionColor}
        disabled={isLoading}
        onClick={() => setIsDialogOpen(true)}
      >
        {actionLabel}
      </Button>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        isLoading={isLoading}
        title={actionLabel}
        cancelButtonLabel="Cancel"
        onCancel={() => setIsDialogOpen(false)}
        submitButtonLabel={actionLabel}
        submitButtonColor={actionColor}
        onSubmit={() =>
          onUpdateUserStatus(
            userStatus === UserStatus.DISABLED ? UserStatus.ACTIVE : UserStatus.DISABLED
          )
        }
        content={
          <FormDialogContent
            error={error && <ServerErrorAlert title="Error" error={error} />}
            items={
              <FormDialogContentItem
                value={<DialogContentText>{dialogContentText}</DialogContentText>}
              />
            }
          />
        }
      />
    </Fragment>
  );
};
