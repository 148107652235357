import { SubNavigation } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import routeFactory from '../../common/util/routeFactory';
import AccountBreadcrumb from './AccountBreadcrumb';

export const StyledSubNavigation = styled(SubNavigation)`
  padding-top: 8px;
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};
`;

const AccountSubNavigation = () => (
  <StyledSubNavigation>
    <SubNavigation.Item>
      <SubNavigation.Link exact to={routeFactory.adminAccounts.getUrlPath()}>
        Accounts
      </SubNavigation.Link>
      <AccountBreadcrumb />
    </SubNavigation.Item>
    <SubNavigation.Item>
      <SubNavigation.Link exact to={routeFactory.adminDraftAccounts.getUrlPath()}>
        Draft Accounts
      </SubNavigation.Link>
      <AccountBreadcrumb />
    </SubNavigation.Item>
  </StyledSubNavigation>
);

export default AccountSubNavigation;
