import { CenteredPage } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import ForgotPasswordForm from './components/ForgotPasswordForm';
import { forgotPassword, resetState, selectError, selectSubmitting, selectSuccess } from './ducks';

const mapStateToProps = state => ({
  submitting: selectSubmitting(state),
  error: selectError(state),
  success: selectSuccess(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      forgotPassword,
      resetState,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RouteProps = RouteComponentProps;
type Props = RouteProps & StateProps & DispatchProps;

export class ForgotPasswordRouteComponent extends React.Component<Props> {
  componentWillUnmount() {
    this.props.actions.resetState();
  }

  render() {
    const { error, submitting, success, actions } = this.props;

    return (
      <CenteredPage title={success ? 'Password reset' : 'Forgot password'}>
        <ForgotPasswordForm
          error={error}
          submitting={submitting}
          success={success}
          onSubmit={values =>
            actions.forgotPassword({
              email: values.email,
            })
          }
        />
      </CenteredPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordRouteComponent);
