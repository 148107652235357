import { DialogContentText } from '@cmg/design-system';
import { FC } from 'react';

import { ConfirmationDialog } from '../../../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { FormDialogContent } from '../../../../../../design-system/dialog/FormDialogContent';
import { FormDialogContentItem } from '../../../../../../design-system/dialog/FormDialogContentItem';

export type Props = Readonly<{
  readonly isOpen: boolean;
  readonly isLoading: boolean;
  readonly onClose: () => void;
  readonly onRevokeApiKey: () => void;
  readonly apiKeyName: string;
}>;

export const RevokeApiKeyConfirmationDialog: FC<Props> = ({
  isOpen,
  isLoading,
  onClose,
  onRevokeApiKey,
  apiKeyName,
}) => {
  return (
    <ConfirmationDialog
      isOpen={isOpen}
      isLoading={isLoading}
      title="Revoke API Key"
      cancelButtonLabel="Cancel"
      onCancel={onClose}
      submitButtonLabel="Revoke API Key"
      submitButtonColor="primary"
      onSubmit={onRevokeApiKey}
      content={
        <FormDialogContent
          items={
            <FormDialogContentItem
              value={
                <DialogContentText>
                  Are you sure you want to revoke <b>{apiKeyName}</b> API Key?
                </DialogContentText>
              }
            />
          }
        />
      }
    />
  );
};
