import styled from 'styled-components/macro';

export const STitle = styled.h3`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  font-weight: ${({ theme }) => theme.text.weight.light};
  margin: 20px 0 25px;
`;

export const SLayout = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  font-weight: ${({ theme }) => theme.text.weight.light};
  margin: 20px 0 25px;
  padding: 10px;
`;
