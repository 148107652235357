import { apiTypes, PageError, Panel } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import LoadingIndicator from '../../../common/components/indicators/loading-indicator/LoadingIndicator';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../common/util/routeFactory';
import PermissionList from './components/permission-list/PermissionList';
import TraitAccountList from './components/trait-account-list/TraitAccountList';
import { fetchAccountList, fetchTrait, selectAccountListParts, selectTraitParts } from './ducks';

const mapStateToProps = state => ({
  traitParts: selectTraitParts(state),
  accountListParts: selectAccountListParts(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchTrait,
      fetchAccountList,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RouteProps = RouteComponentProps<{
  traitCode: string;
}>;
export type Props = RouteProps & StateProps & DispatchProps;

/**
 * Page to view a specific Trait's details (Trait metadata, trait permissions, accounts that have the trait).
 *
 * Potential future enhancement - ability to assign and revoke traits on accounts directly from this page.
 */
export const TraitDetailRouteComponent: React.FC<Props> = ({
  traitParts,
  accountListParts,
  actions,
  match,
}) => {
  useDocumentTitle(
    routeFactory.adminTraitDetail.getDocumentTitle({
      traitName: traitParts.data?.name,
    })
  );

  React.useEffect(() => {
    actions.fetchTrait({ traitCode: match.params.traitCode });
    actions.fetchAccountList({ page: 1, perPage: 25, traits: [match.params.traitCode] });
  }, [actions, match.params.traitCode]);

  const handleOnChangePage = (params: apiTypes.ListParams) => {
    actions.fetchAccountList({ ...params, traits: [match.params.traitCode] });
  };

  // Loading the trait
  if (traitParts.loading) {
    return (
      <Panel>
        <LoadingIndicator>Loading trait...</LoadingIndicator>
      </Panel>
    );
  }

  // Error on retreiving the trait or account list - non recoverable
  const error = traitParts.error || accountListParts.error || null;
  if (error) {
    return <PageError error={error} />;
  }

  return (
    <Panel>
      <Panel.Header
        title={`Trait - ${traitParts.data?.name || ''}`}
        bottomContent={
          <div>
            <div>{traitParts.data?.description || ''}</div>
            <PermissionList permissions={traitParts.data?.permissions} />
          </div>
        }
      />

      <TraitAccountList
        accounts={accountListParts?.data?.data}
        loading={accountListParts.loading}
        pagination={accountListParts.data?.pagination}
        onChangePage={handleOnChangePage}
      />
    </Panel>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TraitDetailRouteComponent);
