import {
  apiTypes,
  Banner,
  ConfirmModal,
  SelectField,
  ServerErrors,
  TextInputField,
} from '@cmg/common';
import { CircularProgress } from '@cmg/design-system';
import { accountListNewAccountFormSelector } from '@cmg/e2e-selectors';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import {
  InfoItem,
  InfoLabel,
  InfoValue,
} from '../../../../common/components/layout/info-box/InfoBoxes';
import { AccountCreate } from '../../../../types/domain/account/account';
import { AccountType, accountTypeLabels } from '../../../../types/domain/account/constants';

// exported for testing only
export const CreateAccountSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  subdomain: yup.string().required('Subdomain is required'),
  accountType: yup.string().nullable().required('Account Type is required'),
  cmgEntityKey: yup
    .string()
    .nullable()
    .required('Cmg Entity Key is required')
    .typeError('Cmg Entity Key must be a number of length 9')
    .label('Cmg Entity Key')
    .matches(/^$|^[0-9]{9}$/, 'Cmg Entity Key must be a number of length 9'),
});

export type OwnProps = {
  onSubmit: (values: AccountCreate) => void;
  error: apiTypes.GenericServerError | null;
  submitting: boolean;
  onCancel: () => void;
  cmgEntitySearch: {
    loading: boolean;
    cmgEntityName: string;
  };
  onCmgEntityKeyChange: (cmgEntityKey: string | null | undefined) => void;
};
type Props = OwnProps & FormikProps<AccountCreate>;

export const CreateAccountFormModalComponent: React.FC<Props> = ({
  error,
  submitting,
  handleSubmit,
  onCancel,
  dirty,
  values,
  cmgEntitySearch,
  onCmgEntityKeyChange,
}) => {
  React.useEffect(() => {
    onCmgEntityKeyChange(values.cmgEntityKey);
  }, [values.cmgEntityKey, onCmgEntityKeyChange]);

  return (
    <ConfirmModal
      show
      title="Create Account"
      isLoading={submitting}
      onSubmitForm={handleSubmit}
      confirmButtonCaption="Create Account"
      confirmButtonEnabled={dirty}
      onHide={onCancel}
      testId={accountListNewAccountFormSelector.testId}
    >
      {error && (
        <Banner variant="error" showIcon={false}>
          <ServerErrors error={error} />
        </Banner>
      )}
      <InfoItem>
        <InfoLabel>Account Name</InfoLabel>
        <InfoValue>
          <TextInputField name="name" placeholder="e.g. Acme Bank" />
        </InfoValue>
      </InfoItem>
      <InfoItem>
        <InfoLabel>Subdomain</InfoLabel>
        <InfoValue>
          <TextInputField name="subdomain" placeholder="e.g. acme" />
        </InfoValue>
      </InfoItem>
      <InfoItem>
        <InfoLabel>Account Type</InfoLabel>
        <InfoValue>
          <SelectField
            name="accountType"
            placeholder="Select account type..."
            isClearable={false}
            options={[
              {
                label: accountTypeLabels[AccountType.BUY_SIDE],
                value: AccountType.BUY_SIDE,
              },
              {
                label: accountTypeLabels[AccountType.SELL_SIDE],
                value: AccountType.SELL_SIDE,
              },
            ]}
          />
        </InfoValue>
      </InfoItem>
      <InfoItem>
        <InfoLabel>Cmg Entity Key</InfoLabel>
        <InfoValue>
          <TextInputField name="cmgEntityKey" placeholder="e.g. 000131787" />
        </InfoValue>
      </InfoItem>
      {values.cmgEntityKey && (
        <InfoItem>
          <InfoLabel>Cmg Entity Name</InfoLabel>
          {cmgEntitySearch.loading ? (
            <CircularProgress size={14} />
          ) : (
            <InfoValue>{cmgEntitySearch.cmgEntityName || '-'}</InfoValue>
          )}
        </InfoItem>
      )}
      {values.cmgEntityKey && (
        <Banner variant="warning">
          Once populated, the CmgEntityKey for an account cannot be changed.
        </Banner>
      )}
    </ConfirmModal>
  );
};

export default withFormik<OwnProps, AccountCreate>({
  enableReinitialize: false,
  isInitialValid: false,
  validateOnChange: false,
  validateOnBlur: true,
  validationSchema: CreateAccountSchema,
  mapPropsToValues: () => ({
    name: '',
    subdomain: '',
    accountType: undefined,
    cmgEntityKey: null,
  }),
  handleSubmit: (values, formikBag) =>
    formikBag.props.onSubmit({
      ...values,
      cmgEntityKey: values.cmgEntityKey !== '' ? values.cmgEntityKey : undefined, // don't send cmgEntityKey if empty
    }),
})(CreateAccountFormModalComponent);
