import { PasswordPolicy } from '@capital-markets-gateway/api-client-identity';
import { FC } from 'react';

import { DescriptionList } from '../../../../../../design-system/body-sections/description-list/DescriptionList';
import { DescriptionListHeading } from '../../../../../../design-system/body-sections/description-list/DescriptionListHeading';
import { DescriptionListItem } from '../../../../../../design-system/body-sections/description-list/DescriptionListItem';

export type Props = Readonly<{
  readonly requireTwoFactorAuth: boolean;
  readonly passwordPolicy: PasswordPolicy;
}>;

const NOT_REQUIRED = 'Not Required';

export const ProviderDetailsSectionReadonly: FC<Props> = ({
  requireTwoFactorAuth,
  passwordPolicy,
}) => {
  return (
    <DescriptionList label="Provider Details">
      <DescriptionListItem
        label="Two Factor Auth"
        value={requireTwoFactorAuth ? 'Required' : NOT_REQUIRED}
      />
      <DescriptionListHeading title="Password Policy" />
      <DescriptionListItem label="Minimum Length" value={passwordPolicy.minLength} />
      <DescriptionListItem label="Minimum Unique Characters" value={passwordPolicy.uniqueChars} />
      <DescriptionListItem
        label="Special Characters"
        value={passwordPolicy.requireNonAlphanumeric ? 'Required' : NOT_REQUIRED}
      />
      <DescriptionListItem
        label="Lower Case"
        value={passwordPolicy.requireLowercase ? 'At least one lowercase' : NOT_REQUIRED}
      />
      <DescriptionListItem
        label="Upper Case"
        value={passwordPolicy.requireUppercase ? 'At least one uppercase' : NOT_REQUIRED}
      />
      <DescriptionListItem
        label="Numeric"
        value={passwordPolicy.requireDigit ? 'At least one number' : NOT_REQUIRED}
      />
      <DescriptionListItem
        label="Password Reset"
        value={
          passwordPolicy.requireUniquePasswordOnReset ? 'Unique Password Required' : NOT_REQUIRED
        }
      />
      <DescriptionListItem label="Expiration" value={passwordPolicy.passwordExpiresInDays} />
      <DescriptionListItem
        label="Required Password Expiration"
        value={passwordPolicy.requirePasswordExpiration ? 'Required' : NOT_REQUIRED}
      />
      <DescriptionListItem
        label="Unique Password History"
        value={`Last ${passwordPolicy.passwordHistoryLength} passwords`}
      />
    </DescriptionList>
  );
};
