import { PageLayout } from '@cmg/design-system';
import { FC, Fragment } from 'react';

import { DescriptionListSkeleton } from '../../../../design-system/body-sections/description-list/DescriptionListSkeleton';
import { PageGridSection } from '../../../../design-system/body-sections/PageGridSection';
import { IdentityPageContent } from '../../../../design-system/IdentityPageContent';
import { HighlightsSkeleton } from '../../../../design-system/loading/HighlightsSkeleton';

export const ProfileRouteSkeleton: FC = () => {
  return (
    <PageLayout header={<HighlightsSkeleton aria-label="Loading Profile Highlights" />}>
      <IdentityPageContent
        gridContent={
          <Fragment>
            <PageGridSection
              title="Profile Details"
              content={<DescriptionListSkeleton aria-label="Loading Profile Details" />}
            />
            <PageGridSection
              title="Application Links"
              content={
                <DescriptionListSkeleton
                  aria-label="Loading Application Links"
                  numOfListItems={2}
                />
              }
            />
            <PageGridSection
              title="Login Details"
              content={<DescriptionListSkeleton aria-label="Loading Login Details" />}
            />
          </Fragment>
        }
      />
    </PageLayout>
  );
};
