import { Skeleton } from '@cmg/design-system';
import { HTMLAttributes, useMemo, VFC } from 'react';

import { DescriptionList } from './DescriptionList';
import { DescriptionListItem } from './DescriptionListItem';

export type DescriptionListSkeletonProps = Readonly<
  {
    readonly numOfListItems?: number;
  } & Pick<HTMLAttributes<'div'>, 'aria-label'>
>;

export const DescriptionListSkeleton: VFC<DescriptionListSkeletonProps> = ({
  numOfListItems = 5,
  'aria-label': ariaLabel,
}) => {
  const listItems = useMemo(() => Array.from(Array(numOfListItems).keys()), [numOfListItems]);

  return (
    <div role="progressbar" aria-label={ariaLabel}>
      <DescriptionList>
        {listItems.map(item => (
          <DescriptionListItem key={item} label={<Skeleton />} value={<Skeleton />} />
        ))}
      </DescriptionList>
    </div>
  );
};
