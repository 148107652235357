import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { UUID } from '../../../../../../types/common';
import { FirmDataSource } from '../../../../../../types/domain/firm/constants';
import { FirmMatchRow } from '../../../../../../types/domain/firm-match/FirmMatch';
import { FirmMatchSource } from '../../../../../../types/domain/firm-match/FirmMatchSource';
import { openLinkOrganizationModal } from '../../link-organization-modal/LinkOrganizationModal';
import {
  StyledDangerButton,
  StyledSecondaryButton,
  StyledSuccessButton,
} from './CrmFirmMatchListCellRenderer.styles';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openLinkOrganizationModal,
    },
    dispatch
  ),
});
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  /**
   * The ID for the CRM Integration that the CRM
   * Investor Firm belongs to.
   */
  crmIntegrationId: UUID;
  /**
   * The ID for the CRM Investor Firm contained in this
   * DataGrid row.
   */
  crmInvestorFirmId: UUID;
  /**
   * The CMG Entity Key of the CRM Investor Firm
   * If it exists, the CRM Investor Firm is linked
   * to a CMG Firm - otherwise, it is not linked.
   */
  cmgEntityKey?: string;
  /**
   * The row data - a Firm Match Row object.
   */
  data: FirmMatchRow;
};
type Props = OwnProps & DispatchProps;

/**
 * render link button and its specific states based on match status
 */
export const LinkButtonCellRenderer: React.FC<Props> = ({
  data: firmMatch,
  cmgEntityKey,
  actions,
}) => {
  const { userPermissions } = useAuth();
  const canLink = checkPermissions(userPermissions, [permissionsByEntity.Firm.FULL]);

  // CrmInvestorFirm top row
  if (!firmMatch.firmSource || !canLink) {
    return <div />;
  }

  // already linked red button
  if (firmMatch.rolodexKey === cmgEntityKey) {
    return (
      <StyledDangerButton onClick={() => actions.openLinkOrganizationModal({})}>
        Unlink
      </StyledDangerButton>
    );
  }

  // green button if origin is FactSet
  if (firmMatch.firmSource === FirmMatchSource.FACT_SET) {
    return (
      <StyledSuccessButton
        onClick={() =>
          actions.openLinkOrganizationModal({
            linkTargetFirmId: firmMatch.id,
            linkTargetFirmSource: FirmDataSource.FACT_SET,
          })
        }
      >
        Create &amp; Link
      </StyledSuccessButton>
    );
  }

  // otherwise white button
  return (
    <StyledSecondaryButton
      onClick={() =>
        actions.openLinkOrganizationModal({
          linkTargetFirmId: firmMatch.id,
          linkTargetFirmSource: FirmDataSource.CMG,
        })
      }
    >
      Link
    </StyledSecondaryButton>
  );
};

export default connect(null, mapDispatchToProps)(LinkButtonCellRenderer);
