import flatten from 'lodash/flatten';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

export type UseRoutesMatchResult<TRoutes extends Record<string, string | string[]>> = {
  readonly matchedRouteName: keyof TRoutes | undefined;
  readonly routeNames: { [x in keyof TRoutes]: x };
};

export type UseRoutesMatchOptions = {
  readonly exact: boolean;
};

export function useRoutesMatch<TRoutes extends Record<string, string | string[]>>(
  routeDefinitions: TRoutes,
  { exact }: UseRoutesMatchOptions = { exact: false }
): UseRoutesMatchResult<TRoutes> {
  const routeDeifnitionKeys = Object.keys(routeDefinitions);

  const routeMatch = useRouteMatch({
    path: flatten(Object.values(routeDefinitions)),
    exact,
  });

  const matchedRouteName = React.useMemo(() => {
    if (!routeMatch) {
      return routeDeifnitionKeys[0];
    }

    return routeDeifnitionKeys.find(key => routeDefinitions[key].includes(routeMatch.path));
  }, [routeDeifnitionKeys, routeMatch, routeDefinitions]);

  const routeNames = React.useMemo(
    () =>
      routeDeifnitionKeys.reduce((result, nextKey) => {
        return {
          ...result,
          [nextKey]: nextKey,
        };
      }, {}) as { [p in keyof TRoutes]: p },
    [routeDeifnitionKeys]
  );

  return {
    matchedRouteName,
    routeNames,
  };
}
