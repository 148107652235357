import { Box, CircularProgress, Typography } from '@cmg/design-system';
import { FormikAutocompleteField, FormikTextField } from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import React from 'react';

import { DescriptionList } from '../../../../design-system/body-sections/description-list/DescriptionList';
import { DescriptionListItem } from '../../../../design-system/body-sections/description-list/DescriptionListItem';
import {
  changeReasonSelectOptions,
  RemapConfig,
  RemapFormValues,
} from './AccountRemappingModal.model';

export type Props = Readonly<{
  index: number;
  baseFieldName: string;
  accountConfig: RemapConfig | null;
  onChangeEntityKey: (entityKey: string, baseFieldName: string) => void;
}>;
export const CurrentAccountRemapSection: React.FC<Props> = ({
  index,
  baseFieldName,
  accountConfig,
  onChangeEntityKey,
}) => {
  const { values } = useFormikContext<RemapFormValues>();
  const { cmgEntityKey, loadingEntityName, cmgEntityName } = React.useMemo(
    () => values.accountConfigs[index]?.newConfig,
    [values, index]
  );

  const handleOnChangeEntityKey = React.useCallback(
    (entityKey: string) => {
      if (!entityKey) {
        return;
      }
      onChangeEntityKey(entityKey, baseFieldName);
    },
    [onChangeEntityKey, baseFieldName]
  );

  return (
    <React.Fragment>
      <div>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          height={theme => theme.spacing(4)}
          padding={theme => theme.spacing(0.5, 0)}
        >
          <Typography variant="h4">Current</Typography>
        </Box>
        <DescriptionList>
          <DescriptionListItem
            label="Subdomain"
            value={accountConfig?.currentConfig?.subdomain ?? ''}
          />
          <DescriptionListItem
            label="CMG Entity Key"
            value={accountConfig?.currentConfig.cmgEntityKey ?? ''}
          />
          <DescriptionListItem
            label="CMG Entity Name"
            value={accountConfig?.currentConfig.cmgEntityName ?? ''}
          />
        </DescriptionList>
        <div></div>
      </div>
      <div>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          height={theme => theme.spacing(4)}
          padding={theme => theme.spacing(1, 0)}
        >
          <Typography variant="h4">New</Typography>
        </Box>
        <div>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            padding={theme => theme.spacing(1, 0)}
          >
            <FormikAutocompleteField
              name={`${baseFieldName}.newConfig.changeReason`}
              label="Change Reason*"
              placeholder="Select"
              options={changeReasonSelectOptions}
              parseToFormikValue={option => option?.value}
              parseFormikValue={(option, options) => options.find(opt => opt.value === option)}
              isOptionEqualToValue={isEqual}
              defaultValue={null}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            padding={theme => theme.spacing(1, 0)}
          >
            <FormikTextField
              showHelperTextInTooltip={false}
              name={`${baseFieldName}.newConfig.cmgEntityKey`}
              label="CMG Entity Key*"
              placeholder="e.g. 000131787"
              onChange={handleOnChangeEntityKey}
            />
          </Box>
          {!!cmgEntityKey && (
            <React.Fragment>
              <DescriptionListItem
                label="CMG Entity Name"
                value={cmgEntityName || (loadingEntityName ? <CircularProgress size={14} /> : '-')}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
