import routeFactory from '../../../common/util/routeFactory';

export enum IdentityProviderType {
  AZURE_AD_OPEN_ID = 'AzureAdOpenId',
  SAML2P = 'Saml2P',
  OPEN_ID_CONNECT = 'OpenIdConnect',
  // WS_FEDERATION = 'WsFederation',
}

export const identityProviderTypeDisplay = {
  [IdentityProviderType.AZURE_AD_OPEN_ID]: 'Azure Active Directory (OIDC)',
  [IdentityProviderType.SAML2P]: 'SAML 2.0',
  [IdentityProviderType.OPEN_ID_CONNECT]: 'OpenID Connect',
};

export const identityProviderTypeUrl = {
  [IdentityProviderType.AZURE_AD_OPEN_ID]: 'azureIdOpenId',
  [IdentityProviderType.SAML2P]: 'saml2p',
  [IdentityProviderType.OPEN_ID_CONNECT]: 'openIdConnect',
};

export const identityProviderTypeRoute = {
  [IdentityProviderType.AZURE_AD_OPEN_ID]: routeFactory.accountSecurityAzureIdOpenIdProvider,
  [IdentityProviderType.SAML2P]: routeFactory.accountSecuritySaml2pProvider,
  [IdentityProviderType.OPEN_ID_CONNECT]: routeFactory.accountSecurityOpenIdConnectProvider,
};
