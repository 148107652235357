import { apiTypes, reduxUtil } from '@cmg/common';
import { combineReducers } from 'redux';
import { show } from 'redux-modal';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as accountApiClient from '../../../common/api/accountApiClient';
import systemManagementApiClient, {
  UpdateAccountResponse,
  UpdatePasswordPolicyResponse,
} from '../../../common/api/systemManagementApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { AccountIpSettingsUpdate, MyAccountUpdate } from '../../../types/domain/account/account';
import { PasswordPolicyUpdate } from '../../../types/domain/password-policy/passwordPolicy';
import { selectSelfSubdomain } from '../../shared/ducks';
import {
  accountPasswordPolicyUpdated,
  accountUpdated,
  selectAccount,
  selectAccountSubdomain,
} from '../shared/ducks';

const IP_WHITELIST_CONFIRMATION_MODAL_ID = 'IP_WHITELIST_CONFIRMATION_MODAL_ID';
/**
 * ACTION TYPES
 */
export enum ActionTypes {
  UPDATE_IP_SETTINGS_REQUEST = 'global_management/UPDATE_IP_SETTINGS_REQUEST',
  UPDATE_IP_SETTINGS_SUCCESS = 'global_management/UPDATE_IP_SETTINGS_SUCCESS',
  UPDATE_IP_SETTINGS_FAILURE = 'global_management/UPDATE_IP_SETTINGS_FAILURE',
  UPDATE_PASSWORD_POLICY_REQUEST = 'global_management/UPDATE_PASSWORD_POLICY_REQUEST',
  UPDATE_PASSWORD_POLICY_SUCCESS = 'global_management/UPDATE_PASSWORD_POLICY_SUCCESS',
  UPDATE_PASSWORD_POLICY_FAILURE = 'global_management/UPDATE_PASSWORD_POLICY_FAILURE',
}

/**
 * ACTION CREATORS
 */
export const confirmUpdateIpSettingsStatus = (props: {
  currentStatus: boolean;
  onConfirm: Function;
}) => show(IP_WHITELIST_CONFIRMATION_MODAL_ID, props);

export const updateIpSettings = (ipSettings: AccountIpSettingsUpdate) => ({
  type: ActionTypes.UPDATE_IP_SETTINGS_REQUEST,
  payload: {
    ipSettings,
  },
});

export const updateIpSettingsSuccess = () => ({
  type: ActionTypes.UPDATE_IP_SETTINGS_SUCCESS,
});

export const updateIpSettingsFailure = ({ error }) => ({
  type: ActionTypes.UPDATE_IP_SETTINGS_FAILURE,
  payload: {
    error,
  },
});

export const updatePasswordPolicy = (params: {
  accountId: string;
  passwordPolicy: PasswordPolicyUpdate;
}) => ({
  type: ActionTypes.UPDATE_PASSWORD_POLICY_REQUEST,
  payload: params,
});

export const updatePasswordPolicySuccess = () => ({
  type: ActionTypes.UPDATE_PASSWORD_POLICY_SUCCESS,
});

export const updatePasswordPolicyFailure = ({ error }) => ({
  type: ActionTypes.UPDATE_PASSWORD_POLICY_FAILURE,
  payload: {
    error,
  },
});

/**
 * ACTIONS
 */

type Actions = {
  [ActionTypes.UPDATE_IP_SETTINGS_REQUEST]: ReturnType<typeof updateIpSettings>;
  [ActionTypes.UPDATE_IP_SETTINGS_SUCCESS]: ReturnType<typeof updateIpSettingsSuccess>;
  [ActionTypes.UPDATE_IP_SETTINGS_FAILURE]: ReturnType<typeof updateIpSettingsFailure>;
  [ActionTypes.UPDATE_PASSWORD_POLICY_REQUEST]: ReturnType<typeof updatePasswordPolicy>;
  [ActionTypes.UPDATE_PASSWORD_POLICY_SUCCESS]: ReturnType<typeof updatePasswordPolicySuccess>;
  [ActionTypes.UPDATE_PASSWORD_POLICY_FAILURE]: ReturnType<typeof updatePasswordPolicyFailure>;
};

/**
 * REDUCERS
 */

const { createReducer } = reduxUtil;

export type ReducerState = {
  ipSettings: {
    error: apiTypes.GenericServerError | null;
    submitting: boolean;
  };
  passwordPolicy: {
    submitting: boolean;
    error: apiTypes.GenericServerError | null;
  };
};

export const initialState = {
  ipSettings: {
    error: null,
    submitting: false,
  },
  passwordPolicy: {
    submitting: false,
    error: null,
  },
};

const ipSettingsReducer = combineReducers<ReducerState['ipSettings']>({
  submitting: createReducer<ReducerState['ipSettings']['submitting'], Actions>(
    initialState.ipSettings.submitting,
    {
      [ActionTypes.UPDATE_IP_SETTINGS_REQUEST]: () => true,
      [ActionTypes.UPDATE_IP_SETTINGS_SUCCESS]: () => false,
      [ActionTypes.UPDATE_IP_SETTINGS_FAILURE]: () => false,
    }
  ),
  error: createReducer<ReducerState['ipSettings']['error'], Actions>(
    initialState.ipSettings.error,
    {
      [ActionTypes.UPDATE_IP_SETTINGS_REQUEST]: () => null,
      [ActionTypes.UPDATE_IP_SETTINGS_SUCCESS]: () => null,
      [ActionTypes.UPDATE_IP_SETTINGS_FAILURE]: (curState, { payload }) => payload.error,
    }
  ),
});

const passwordPolicyReducer = combineReducers<ReducerState['passwordPolicy']>({
  submitting: createReducer<ReducerState['passwordPolicy']['submitting'], Actions>(
    initialState.passwordPolicy.submitting,
    {
      [ActionTypes.UPDATE_PASSWORD_POLICY_REQUEST]: () => true,
      [ActionTypes.UPDATE_PASSWORD_POLICY_SUCCESS]: () => false,
      [ActionTypes.UPDATE_PASSWORD_POLICY_FAILURE]: () => false,
    }
  ),
  error: createReducer<ReducerState['passwordPolicy']['error'], Actions>(
    initialState.passwordPolicy.error,
    {
      [ActionTypes.UPDATE_PASSWORD_POLICY_REQUEST]: () => null,
      [ActionTypes.UPDATE_PASSWORD_POLICY_SUCCESS]: () => null,
      [ActionTypes.UPDATE_PASSWORD_POLICY_FAILURE]: (curState, { payload }) => payload.error,
    }
  ),
});

export default combineReducers<ReducerState>({
  ipSettings: ipSettingsReducer,
  passwordPolicy: passwordPolicyReducer,
});

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.adminAccountSecurity;
export const selectIpSettingsError = state => selectState(state).ipSettings.error;
export const selectIpSettingsSubmitting = state => selectState(state).ipSettings.submitting;
export const selectPasswordPolicyError = state => selectState(state).passwordPolicy.error;
export const selectPasswordPolicySubmitting = state => selectState(state).passwordPolicy.submitting;

/**
 * SAGAS
 */
export function* updateIpSettingsSaga({
  payload,
}: Actions[ActionTypes.UPDATE_IP_SETTINGS_REQUEST]): SagaIterator {
  const { ipSettings } = payload;
  const account = yield select(selectAccount);
  const accountSubdomain = yield select(selectAccountSubdomain);
  const selfSubdomain = yield select(selectSelfSubdomain);

  let response: accountApiClient.UpdateMyAccountResponse | UpdateAccountResponse;

  if (accountSubdomain === selfSubdomain) {
    const { enforceIpWhitelist, ipWhitelist } = ipSettings;
    response = yield call(accountApiClient.updateMyAccount, {
      enforceIpWhitelist,
      ipWhitelist,
      requireTwoFactorAuth: account.requireTwoFactorAuth,
    } as MyAccountUpdate);
  } else {
    response = yield call(systemManagementApiClient.admin.updateAccount, ipSettings);
  }

  if (response.ok) {
    yield put(accountUpdated({ account: response.data }));
    yield put(updateIpSettingsSuccess());
  } else {
    yield put(updateIpSettingsFailure({ error: response.data.error }));
  }
}

export function* updatePasswordPolicySaga({
  payload,
}: Actions[ActionTypes.UPDATE_PASSWORD_POLICY_REQUEST]): SagaIterator {
  const { accountId, passwordPolicy } = payload;
  const accountSubdomain = yield select(selectAccountSubdomain);
  const selfSubdomain = yield select(selectSelfSubdomain);

  let response:
    | accountApiClient.UpdateMyAccountPasswordPolicyResponse
    | UpdatePasswordPolicyResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.updateMyAccountPasswordPolicy, passwordPolicy);
  } else {
    response = yield call(
      systemManagementApiClient.admin.updatePasswordPolicy,
      accountId,
      passwordPolicy
    );
  }

  if (response.ok) {
    yield put(accountPasswordPolicyUpdated({ passwordPolicy: response.data }));
    yield put(updatePasswordPolicySuccess());
  } else {
    yield put(updatePasswordPolicyFailure({ error: response.data.error }));
  }
}

export function* adminAccountSecuritySaga() {
  yield takeLatest<Actions[ActionTypes.UPDATE_IP_SETTINGS_REQUEST]>(
    ActionTypes.UPDATE_IP_SETTINGS_REQUEST,
    updateIpSettingsSaga
  );
  yield takeLatest<Actions[ActionTypes.UPDATE_PASSWORD_POLICY_REQUEST]>(
    ActionTypes.UPDATE_PASSWORD_POLICY_REQUEST,
    updatePasswordPolicySaga
  );
}
