import { Autocomplete, AutocompleteTextFieldProps, TextField, Tooltip } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';

import { FormFieldLayout } from '../../../../../design-system/body-sections/form/FormFieldLayout';
import { FormLayout } from '../../../../../design-system/body-sections/form/FormLayout';
import { AccountType } from '../../../../../types/domain/account/constants';
import { User, UserDetailsUpdate } from '../../../../../types/domain/user/user';
import { getJobFunctionLabel, getJobFunctionOptions } from '../../../../shared/model/job-function';
import { getEmployeeKeyTooltipLabel, getInvestorCoverageStatusTooltipLabel } from '../utils';

export type Props = Readonly<{
  user: Readonly<User>;
  accountType: Readonly<AccountType | 'SYSTEM' | null>;
}>;

export const UserDetailsSectionEdit: FC<Props> = ({ user, accountType }) => {
  const formik = useFormikContext<UserDetailsUpdate>();

  const jobFunctionOptions = useMemo(() => getJobFunctionOptions(accountType), [accountType]);

  const jobFunctionTextFieldProps: AutocompleteTextFieldProps = useMemo(
    () => ({
      label: 'Job Function',
      required: true,
      name: 'jobFunction',
      placeholder: 'e.g. Analyst',
      error: Boolean(formik.errors.jobFunction),
      helperText: formik.errors.jobFunction,
    }),
    [formik.errors.jobFunction]
  );

  return (
    <FormLayout>
      <FormFieldLayout>
        <TextField
          label="First Name"
          required
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.firstName)}
          helperText={formik.errors.firstName}
          placeholder="e.g. Tim"
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          label="Last Name"
          required
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.lastName)}
          helperText={formik.errors.lastName}
          placeholder="e.g. Smith"
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <Autocomplete
          options={jobFunctionOptions.map(x => x.value)}
          getOptionLabel={option => getJobFunctionLabel(option, accountType)}
          value={formik.values.jobFunction}
          onChange={(_e, value) => formik.setFieldValue('jobFunction', value)}
          TextFieldProps={jobFunctionTextFieldProps}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          label={
            <Tooltip title={getEmployeeKeyTooltipLabel()} placement="top-start" describeChild>
              <span aria-label="Employee Key">Employee Key ⓘ</span>
            </Tooltip>
          }
          name="employeeKey"
          value={formik.values.employeeKey}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.employeeKey)}
          helperText={formik.errors.employeeKey}
        />
      </FormFieldLayout>
      {accountType === AccountType.SELL_SIDE && (
        <FormFieldLayout>
          <TextField
            disabled
            label={
              <Tooltip
                title={getInvestorCoverageStatusTooltipLabel()}
                placement="top-start"
                describeChild
              >
                <span aria-label="Investor Coverage Status">Investor Coverage Status ⓘ</span>
              </Tooltip>
            }
            name="configurationStatus"
            value={user.configurationStatus}
          />
        </FormFieldLayout>
      )}
    </FormLayout>
  );
};
