import { apiTypes, Banner, FlexContainer, LinkButton, ServerErrors, TreeGrid } from '@cmg/common';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import LoadingIndicator from '../../../../common/components/indicators/loading-indicator/LoadingIndicator';
import { OnPlatformIcon } from '../../../../features/rolodex/shared/OnPlatformIcon';
import { UUID } from '../../../../types/common';
import { firmRoleTypeLabels } from '../../../../types/domain/firm/constants';
import { FactSetFirmHierarchy } from '../../../../types/domain/firm/FactSetFirmHierarchy';
import { FactSetFirmRelation } from '../../../../types/domain/firm/FactSetFirmRelation';
import { FirmHierarchy } from '../../../../types/domain/firm/FirmHierarchy';
import { FirmRelation } from '../../../../types/domain/firm/FirmRelation';
import { RolodexMetadata } from '../../../../types/domain/rolodex/Metadata';
import { getRows } from './SimpleFirmHierarchyTreeGrid.model';

export const STreeGridWrapper = styled.div<{ showHeader?: boolean }>`
  ${({ showHeader }) =>
    !showHeader &&
    css`
      thead {
        display: none;
      }
    `}
`;

export const STreeGridCellWrapper = styled.div<{ small?: boolean }>`
  height: 34px;
  display: flex;
  align-items: center;
  font-size: ${({ theme, small }) => (small ? theme.text.size.small : 'inherit')};
`;

export const SNameWrapper = styled.span<{ isActive: boolean }>`
  font-weight: ${({ isActive, theme }) =>
    isActive ? theme.text.weight.bold : theme.text.weight.regular};
`;

type Props = {
  /**
   * A FactSet or CMG firm hierarchy object
   */
  hierarchy: FirmHierarchy | FactSetFirmHierarchy | null;
  /**
   * Metadata labels for industryType + entityType
   */
  metadata?: RolodexMetadata;
  /**
   * Used to highlight the Firm Relation in the hierarchy
   * with the matching ID.
   */
  activeFirmId: UUID | string | null;
  /**
   * Whether or not the UI should display a loading indicator
   * */
  loading: boolean;
  /**
   * An error to display
   */
  error: apiTypes.GenericServerError | null;
  /**
   * Whether or not to expand all rows
   */
  groupDefaultExpanded?: boolean;
  /**
   * A boolean that controls whether rows can be collapsed or not - defaults to true.
   */
  isCollapsible?: boolean;
  /**
   * An array of groups that should be expanded on mount
   */
  expandedGroups?: string[];
  /**
   * hether or not the provided hierarchy object is a FactSet firm or not
   */
  isFactSetFirmHierarchy?: boolean;
  /**
   * Callback prop triggered when a firm name is clicked.
   */
  onClickFirmName?: (row: FirmRelation | FactSetFirmRelation) => void;
  /**
   * show table/grid header (hidden by default)
   */
  showHeader?: boolean;
};

type OrganizationNameCellRendererProps = {
  hierarchy: FirmHierarchy;

  isActive: boolean;

  isOnPlatform: boolean;
};

export const OrganizationNameCellRenderer: React.FC<OrganizationNameCellRendererProps> = ({
  hierarchy,
  isActive,
  isOnPlatform,
}) => {
  const name = hierarchy[hierarchy.length - 1];
  return (
    <FlexContainer gap={4} direction="row">
      <OnPlatformIcon isOnPlatform={isOnPlatform} />
      <SNameWrapper isActive={isActive}>{name}</SNameWrapper>
    </FlexContainer>
  );
};

/**
 * A Firm Hierarchy TreeGrid that displays firm names only.
 */
const SimpleFirmHierarchyTreeGrid: React.FC<Props> = ({
  hierarchy,
  metadata,
  activeFirmId,
  loading,
  error,
  groupDefaultExpanded,
  isCollapsible,
  expandedGroups,
  isFactSetFirmHierarchy,
  onClickFirmName,
  showHeader,
}) => {
  return (
    <React.Fragment>
      {loading && <LoadingIndicator />}
      {error && (
        <Banner variant="error" showIcon={false}>
          <ServerErrors error={error} />
        </Banner>
      )}
      {!loading && !error && (
        <STreeGridWrapper showHeader={showHeader}>
          <TreeGrid
            striped={true}
            columns={[
              {
                field: 'hierarchy',
                headerName: 'Organization Name',
                cellRendererFramework: rowData => {
                  const isActive = rowData.node.id === activeFirmId;
                  return (
                    <STreeGridCellWrapper>
                      {onClickFirmName ? (
                        <LinkButton onClick={() => onClickFirmName(rowData.node)}>
                          <OrganizationNameCellRenderer
                            isActive={isActive}
                            hierarchy={rowData.hierarchy}
                            isOnPlatform={rowData.node.isOnPlatform}
                          />
                        </LinkButton>
                      ) : (
                        <OrganizationNameCellRenderer
                          isActive={isActive}
                          hierarchy={rowData.hierarchy}
                          isOnPlatform={rowData.node.isOnPlatform}
                        />
                      )}
                    </STreeGridCellWrapper>
                  );
                },
              },
              {
                field: 'entityType',
                headerName: 'Entity Type',
                cellRendererFramework: ({ node }) => {
                  const type =
                    metadata && metadata.entityTypes.find(({ value }) => value === node.entityType);
                  return <STreeGridCellWrapper small>{type && type.label}</STreeGridCellWrapper>;
                },
              },
              {
                field: 'industryType',
                headerName: 'Industry Type',
                cellRendererFramework: ({ node }) => {
                  const type =
                    metadata &&
                    metadata.industryTypes.find(({ value }) => value === node.industryType);
                  return <STreeGridCellWrapper small>{type && type.label}</STreeGridCellWrapper>;
                },
              },
              {
                field: 'role',
                headerName: 'Role',
                cellRendererFramework: ({ node }) => (
                  <STreeGridCellWrapper small>
                    {node.roles && node.roles.map(role => firmRoleTypeLabels[role]).join(', ')}
                  </STreeGridCellWrapper>
                ),
              },
              {
                field: 'linkFrequency',
                headerName: 'Link Frequency',
                cellRendererFramework: ({ node: { linkFrequencyData } }) => {
                  return (
                    <STreeGridCellWrapper small>
                      <span data-test-id="link-frequency">
                        {linkFrequencyData && linkFrequencyData.linkFrequency > 0
                          ? linkFrequencyData.linkFrequency
                          : null}
                      </span>
                    </STreeGridCellWrapper>
                  );
                },
              },
            ]}
            rows={getRows(hierarchy, !!isFactSetFirmHierarchy)}
            getDataPath={data => data.hierarchy}
            groupDefaultExpanded={groupDefaultExpanded}
            isCollapsible={isCollapsible}
            expandedGroups={expandedGroups}
          />
        </STreeGridWrapper>
      )}
    </React.Fragment>
  );
};

export default SimpleFirmHierarchyTreeGrid;
