import { Icon } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { UUID } from '../../../../types/common';

export const SExistsInCMGDBWrapper = styled.div`
  margin-left: 60px;
`;

export const SDescriptionWrapper = styled.div`
  max-width: 300px;
`;

export const SNameWrapper = styled.span<{ isActive: boolean }>`
  font-weight: ${({ isActive, theme }) =>
    isActive ? theme.text.weight.bold : theme.text.weight.regular};
`;

export const createColumns = ({ activeFirmId }: { activeFirmId: UUID | string | null }) => [
  {
    field: 'hierarchy',
    headerName: 'Organization Name',
    cellRendererFramework: rowData => {
      return (
        <SNameWrapper isActive={rowData.node.factSetFirmId === activeFirmId}>
          {rowData.hierarchy[rowData.hierarchy.length - 1]}
        </SNameWrapper>
      );
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    cellRendererFramework: rowData => (
      <SDescriptionWrapper>{rowData.node.description}</SDescriptionWrapper>
    ),
  },
  {
    field: 'entityType',
    headerName: 'Entity Type',
    cellRendererFramework: rowData => rowData.node.entityType,
  },
  {
    field: 'industryType',
    headerName: 'Industry Type',
    cellRendererFramework: rowData => rowData.node.industryType,
  },
  {
    field: 'linkedToEntityMasterFirm',
    headerName: 'Exists in CMG DB',
    cellRendererFramework: rowData => {
      return (
        <SExistsInCMGDBWrapper>
          {rowData.node.linkedToEntityMasterFirm && <Icon name="check" />}
        </SExistsInCMGDBWrapper>
      );
    },
  },
];
