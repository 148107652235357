import { Panel, SubNavigation, SuccessButton } from '@cmg/common';
import { identitySelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import {
  InfoItem,
  InfoLabel,
  InfoValue,
} from '../../../../common/components/layout/info-box/InfoBoxes';
import { useFeatureToggles } from '../../../../common/config/context/useFeatureToggles';
import routeFactory from '../../../../common/util/routeFactory';
import { Account } from '../../../../types/domain/account/account';
import { AdminFirmLimitedRead } from '../../../../types/domain/firm/Firm';
import { FirmDetailsComponent } from './FirmDetails';

const StyledInfoItem = styled(InfoItem)`
  min-height: 34px;
`;

type OwnProps = {
  firm: AdminFirmLimitedRead;
  account: Account;
};

const RolodexPanel: React.FC<OwnProps> = ({ firm, account }) => {
  const { isRemapCMGEntityKeyFlowOn } = useFeatureToggles();
  if (firm && account) {
    const rolodexUrl = routeFactory.rolodexFirmDetail.getUrlPath({ firmId: firm.id });

    return (
      <Panel testId={identitySelectors.accountDetail.accountRolodexScreen.testId}>
        <Panel.Header
          rightContent={
            isRemapCMGEntityKeyFlowOn && (
              <SuccessButton
                asComponent={Link}
                to={routeFactory.accountRemap.getUrlPath(account.subdomain)}
              >
                Edit Entity Key
              </SuccessButton>
            )
          }
        />
        <StyledInfoItem>
          <InfoLabel>CMG Entity Name</InfoLabel>
          <InfoValue>{firm.displayName}</InfoValue>
        </StyledInfoItem>
        <StyledInfoItem>
          <InfoLabel>CMG Entity Key</InfoLabel>
          <InfoValue>{firm.key}</InfoValue>
        </StyledInfoItem>
        <StyledInfoItem>
          <InfoLabel>Rolodex link</InfoLabel>
          <InfoValue>
            <SubNavigation.Link exact to={rolodexUrl}>
              {rolodexUrl}
            </SubNavigation.Link>
          </InfoValue>
        </StyledInfoItem>

        <FirmDetailsComponent firm={firm} />
      </Panel>
    );
  } else {
    return (
      <Panel testId={identitySelectors.accountDetail.accountRolodexScreen.testId}>
        <Panel.Header rightContent={[]} />
        <StyledInfoItem>
          <InfoLabel>CMG Entity Name</InfoLabel>
          <InfoValue>{firm}</InfoValue>
        </StyledInfoItem>
        <StyledInfoItem>
          <InfoLabel>Rolodex link</InfoLabel>
        </StyledInfoItem>
      </Panel>
    );
  }
};

export default React.memo(RolodexPanel);
