import {
  apiTypes,
  Banner,
  Icon,
  PrimaryButton,
  ServerErrors,
  StackedInputGroup,
  TextInput,
  TextInputField,
} from '@cmg/common';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { ActivateUser } from '../../../../types/domain/self-settings/ActivateUser';
import { SButtonsSection, SHeading, StyledForm } from './FirstTimeActivationForm.styles';

// exported for testing only
export const FirstTimeActivationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  newPassword: yup.string().required('A password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

export type OwnProps = {
  onSubmit: (values: ActivateUser) => void;
  error: apiTypes.GenericServerError | null;
  success: boolean;
  submitting: boolean;
  token: string;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
};
type Props = OwnProps & FormikProps<ActivateUser>;

export class FirstTimeActivationFormComponent extends React.Component<Props> {
  render() {
    const { error, submitting, success, email } = this.props;

    if (success) {
      return (
        <SHeading>
          You’ve successfully activated your account. Check your email to log in. If you don’t hear
          from us in a few minutes, please check your spam folder or email{' '}
          <a href="mailto:support@cmgx.io">support@cmgx.io</a>
        </SHeading>
      );
    }

    return (
      <StyledForm autoComplete="off">
        <StackedInputGroup>
          <TextInput size="large" prefix={<Icon name="envelope" />} value={email} disabled />
          <TextInputField
            size="large"
            prefix={<Icon name="user-circle" />}
            name="firstName"
            placeholder="First Name"
            fullWidth
          />
          <TextInputField
            size="large"
            prefix={<Icon name="user-circle" />}
            name="lastName"
            placeholder="Last Name"
            fullWidth
          />
          <TextInputField
            size="large"
            prefix={<Icon name="lock-alt" />}
            type="password"
            name="newPassword"
            placeholder="Create Password"
            fullWidth
            autoComplete="new-password"
          />
          <TextInputField
            size="large"
            prefix={<Icon name="lock-alt" />}
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            fullWidth
            autoComplete="new-password"
          />
        </StackedInputGroup>
        <SButtonsSection>
          {error && (
            <Banner variant="error" showIcon={false}>
              <ServerErrors error={error} />
            </Banner>
          )}
          <PrimaryButton
            size="large"
            loading={submitting}
            type="submit"
            fullWidth
            variant="high-contrast"
          >
            Activate Account
          </PrimaryButton>
        </SButtonsSection>
      </StyledForm>
    );
  }
}

export default withFormik<OwnProps, ActivateUser>({
  enableReinitialize: false,
  isInitialValid: false,
  validateOnChange: false,
  validateOnBlur: true,
  validationSchema: FirstTimeActivationSchema,
  mapPropsToValues: ({ token, userId, firstName, lastName }) => ({
    newPassword: '',
    confirmPassword: '',
    token,
    userId,
    firstName,
    lastName,
  }),
  handleSubmit: (values, formikBag) => formikBag.props.onSubmit(values),
})(FirstTimeActivationFormComponent);
