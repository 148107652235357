import React from 'react';

import { FirmMatchRow } from '../../../../../../types/domain/firm-match/FirmMatch';
import { SWebDomainsWrapper } from './CrmFirmMatchListCellRenderer.styles';

/**
 * Renders internet domain names
 */
export const WebDomainsCellRenderer: React.FC<{ data: FirmMatchRow }> = ({ data: firmMatch }) => {
  if (!firmMatch.webDomains) {
    return <div />;
  }

  return <SWebDomainsWrapper>{firmMatch.webDomains.slice(0, 3).join(', ')}</SWebDomainsWrapper>;
};

export default WebDomainsCellRenderer;
