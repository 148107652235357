import { Option } from '@cmg/common';

import { RecordStatus } from './RecordStatus';

export const recordStatusLabels = {
  [RecordStatus.EFFECTIVE]: 'Effective',
  [RecordStatus.DELETED]: 'Deleted',
};

export const recordStatusOptions = Object.keys(RecordStatus).map(
  recordStatus =>
    ({
      label: recordStatusLabels[recordStatus],
      value: recordStatus,
    } as Option<RecordStatus>)
);
