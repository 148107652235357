import { Banner } from '@cmg/common';
import { useFormikContext } from 'formik';
import React, { FC, Fragment, useMemo } from 'react';

import type { SelectDomainFormValues } from './SelectDomainForm.model';

export const SelectDomainFormError: FC = () => {
  const { errors } = useFormikContext<SelectDomainFormValues>();
  const errorArray = useMemo(() => Object.entries(errors), [errors]);

  return (
    <Fragment>
      {errorArray.map(([key, value]) => (
        <Banner key={key} variant="error" testId={`error-field-${key}`}>
          {value}
        </Banner>
      ))}
    </Fragment>
  );
};

export default SelectDomainFormError;
