import { isSystemSubdomain, permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { urlUtil } from '@cmg/common';
import { PageLayout } from '@cmg/design-system';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { bindActionCreators } from 'redux';

import routeFactory from '../../../common/util/routeFactory';
import { ServerErrorAlert } from '../../../design-system/alert/ServerErrorAlert';
import AccountRolodexRoute from '../../account-detail/rolodex/AccountRolodexRoute';
import {
  fetchAccount,
  resetAccountDetailDuck,
  selectAccount,
  selectError,
  selectLoading,
} from '../../account-detail/shared/ducks';
import { selectSelfSubdomain } from '../../shared/ducks';
import AccountDetailDomainObject from './AccountDetailDomainObject';
import { AccountDetailRouteSkeleton } from './AccountDetailRouteSkeleton';
import ApiKeyCreateRoute from './api-keys/api-keys-create/ApiKeyCreateRoute';
import ApiKeyDetailRoute from './api-keys/api-keys-detail/ApiKeyDetailRoute';
import ApiKeyListRoute from './api-keys/api-keys-list/ApiKeyListRoute';
import AccountRoleDetailRoute from './role-detail/AccountRoleDetailRoute';
import AccountRoleListRoute from './role-list/AccountRoleListRoute';
import AccountSecurityRoute from './security/AccountSecurityRoute';
import AzureProviderRoute from './security/identity-providers/azure/AzureProviderRoute';
import LocalLoginProviderRoute from './security/identity-providers/local-login/LocalLoginProviderRoute';
import OpenIdProviderRoute from './security/identity-providers/open-id/OpenIdProviderRoute';
import SamlProviderRoute from './security/identity-providers/saml/SamlProviderRoute';
import AccountSubscriptionRoute from './subscription/AccountSubscriptionRoute';
import AccountUserDetailRoute from './user-detail/AccountUserDetailRoute';
import AccountUserListRoute from './user-list/AccountUserListRoute';

const mapStateToProps = state => ({
  account: selectAccount(state),
  loading: selectLoading(state),
  error: selectError(state),
  selfSubdomain: selectSelfSubdomain(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchAccount,
      resetAccountDetailDuck,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type RouteProps = RouteComponentProps<{
  accountSubdomain: string;
}>;

type Props = StateProps & DispatchProps & RouteProps;

/**
 * Entry point for accessing account detail. All routes associated with viewing/managing a particular account stem from here.
 * Responsibilities:
 *  1. Fetch the account from the id in the url. This comes from the assumption that all routes underneath this will most likely require some info from that account entity.
 *  2. Render all account child routes, which are co-located and nested under the account-detail feature as sub-directories.
 */
export const AccountDetailRoute: React.FC<Props> = ({
  account,
  actions,
  match,
  error,
  loading,
  selfSubdomain,
}) => {
  const accountSubdomainFromPath = match.params.accountSubdomain;

  useEffect(() => {
    actions.fetchAccount(accountSubdomainFromPath || selfSubdomain);
    return () => {
      // Start with fresh redux state for the next account the user navigates too
      actions.resetAccountDetailDuck();
    };
  }, [accountSubdomainFromPath, actions, selfSubdomain]);

  /* @todo remove these once permissions restricted routes use PrivateRouteContent */
  const hasFullApiKeyAccess = useCheckPermissions(
    [permissionsByEntity.ApiKey.FULL, permissionsByEntity.GlobalApiKey.FULL],
    false
  );

  const hasReadUserAccess = useCheckPermissions(
    [permissionsByEntity.GlobalUser.READ, permissionsByEntity.User.READ],
    false
  );

  const hasReadRoleAccess = useCheckPermissions(
    [permissionsByEntity.GlobalRole.READ, permissionsByEntity.Role.READ],
    false
  );

  const hasReadAccountAccess = useCheckPermissions([permissionsByEntity.GlobalAccount.READ], false);

  const hasFullUserAccess = useCheckPermissions([permissionsByEntity.User.FULL], true);

  const hasFullGlobalUserAccess = useCheckPermissions([permissionsByEntity.GlobalUser.FULL], true);

  const hasAccountAccess = useCheckPermissions([permissionsByEntity.Account.READ], true);

  if (loading) {
    return <AccountDetailRouteSkeleton />;
  }

  if (error) {
    return <ServerErrorAlert error={error} title="Error" />;
  }

  if (!account) {
    return null;
  }

  /**
   * can create user if
   * - logged in user is creating a user in an account within the same subdomain and hasFullUserAccess
   * - or logged in user is creating a user in an account in different subdomain and hasFullGlobalUserAccess
   */

  const canCreateOrEditUser =
    account.subdomain === urlUtil.getAccountSubdomain(window.location)
      ? hasFullUserAccess
      : hasFullGlobalUserAccess;

  const canViewCoverage = !isSystemSubdomain() && hasAccountAccess;

  return (
    <PageLayout header={<AccountDetailDomainObject />}>
      <Switch>
        {/* @todo update permissions restricted routes to use PrivateRouteContent */}
        {hasReadUserAccess && (
          <Route
            path={routeFactory.accountUsers.routePath}
            render={routeProps => (
              <AccountUserListRoute {...routeProps} canCreateOrEditUser={canCreateOrEditUser} />
            )}
          />
        )}
        {/* @todo update permissions restricted routes to use PrivateRouteContent */}
        {hasReadUserAccess && (
          <Route
            exact
            path={routeFactory.accountUserDetail.routePath}
            render={routeProps => (
              <AccountUserDetailRoute
                {...routeProps}
                canViewCoverage={canViewCoverage}
                canCreateOrEditUser={canCreateOrEditUser}
              />
            )}
          />
        )}
        {/* @todo update permissions restricted routes to use PrivateRouteContent */}
        {hasReadRoleAccess && (
          <Route
            exact
            path={routeFactory.accountRoles.routePath}
            render={params => <AccountRoleListRoute {...params} />}
          />
        )}
        {/* @todo update permissions restricted routes to use PrivateRouteContent */}
        {hasReadRoleAccess && (
          <Route
            exact
            path={routeFactory.accountRoleDetail.routePath}
            component={AccountRoleDetailRoute}
          />
        )}
        {/* @todo update permissions restricted routes to use PrivateRouteContent */}
        {hasReadRoleAccess && (
          <Route
            exact
            path={routeFactory.accountRoleUsersDetail.routePath}
            component={AccountRoleDetailRoute}
          />
        )}
        <Route
          exact
          path={routeFactory.accountSecurityProviderLocalLogin.routePath}
          component={LocalLoginProviderRoute}
        />
        {hasReadAccountAccess && (
          <Route path={routeFactory.accountRolodex.routePath} component={AccountRolodexRoute} />
        )}
        <Route
          exact
          path={routeFactory.accountSubscription.routePath}
          component={AccountSubscriptionRoute}
        />
        <Route
          exact
          path={routeFactory.accountSecurity.routePath}
          render={params => <AccountSecurityRoute {...params} />}
        />
        <Route
          exact
          path={routeFactory.accountSecurityAzureIdOpenIdProvider.routePath}
          component={AzureProviderRoute}
        />

        <Route
          exact
          path={routeFactory.accountSecuritySaml2pProvider.routePath}
          component={SamlProviderRoute}
        />
        <Route
          exact
          path={routeFactory.accountSecurityOpenIdConnectProvider.routePath}
          component={OpenIdProviderRoute}
        />
        <Route exact path={routeFactory.accountApiKeys.routePath} component={ApiKeyListRoute} />
        {/* @todo update permissions restricted routes to use PrivateRouteContent */}
        {/* @todo remove the ability to create api keys from system admins once this feature is turned on for the account admins */}
        {hasFullApiKeyAccess && (
          <Route
            exact
            path={routeFactory.accountApiKeysNew.routePath}
            render={routeProps => <ApiKeyCreateRoute {...(routeProps as RouteProps)} />}
          />
        )}
        <Route
          exact
          path={routeFactory.accountApiKeyDetail.routePath}
          render={routeProps => (
            <ApiKeyDetailRoute
              {...(routeProps as RouteComponentProps<{
                apiKeyId: string;
              }>)}
            />
          )}
        />
        {/* Default to account subscription when no other routes match */}
        <Route
          render={() => (
            <Redirect
              to={routeFactory.accountSubscription.getUrlPath({
                accountSubdomain: accountSubdomainFromPath,
              })}
            />
          )}
        />
      </Switch>
    </PageLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailRoute);
