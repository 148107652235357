import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import { apiTypes, Panel, SuccessButton } from '@cmg/common';
import { identitySelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useDebouncedCallback } from 'use-debounce';

import { FirmListFilters } from '../../../common/api/rolodexApiClient';
import routeFactory from '../../../common/util/routeFactory';
import {
  fetchFirmList,
  fetchMetadata,
  selectFirms,
  selectFirmsError,
  selectFirmsLoading,
  selectFirmsPagination,
  selectMetadata,
  selectMetadataLoading,
} from '../shared/ducks';
import FirmList from './components/FirmList';

const mapStateToProps = state => ({
  loading: selectFirmsLoading(state),
  firms: selectFirms(state),
  pagination: selectFirmsPagination(state),
  error: selectFirmsError(state),
  metadata: selectMetadata(state),
  metadataLoading: selectMetadataLoading(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchFirmList,
      fetchMetadata,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = RouteComponentProps & StateProps & DispatchProps;

export const RolodexFirmListRouteComponent: React.FC<Props> = ({
  loading,
  firms,
  pagination,
  error,
  metadata,
  metadataLoading,
  actions,
}) => {
  const [filters, setFilters] = React.useState<FirmListFilters>({});
  const { userPermissions } = useAuth();
  const canEdit = checkPermissions(userPermissions, [permissionsByEntity.Firm.FULL]);

  const debouncedFetch = useDebouncedCallback(actions.fetchFirmList, 300);

  React.useEffect(() => {
    actions.fetchFirmList({ page: 1, perPage: 25 });
    actions.fetchMetadata();
  }, [actions]);

  const handleChangePage = (params: apiTypes.ListParams) => {
    actions.fetchFirmList({
      ...filters,
      ...params,
    });
  };

  const handleChangeFilter = (filters: FirmListFilters, debounce?: boolean) => {
    debounce
      ? debouncedFetch({ page: 1, ...filters })
      : actions.fetchFirmList({ page: 1, ...filters });
    setFilters(filters);
  };

  return (
    <Panel testId={identitySelectors.rolodex.firmListScreen.testId}>
      <Panel.Header
        title="Organizations"
        rightContent={
          canEdit && (
            <SuccessButton
              asComponent={Link}
              to={routeFactory.rolodexFirmImport.getUrlPath()}
              testId={identitySelectors.rolodex.firmListCreateFirm.testId}
            >
              Create Organization
            </SuccessButton>
          )
        }
        testId={identitySelectors.rolodex.firmListHeader.testId}
      />
      <FirmList
        loading={loading || metadataLoading}
        firms={!loading && !metadataLoading ? firms : []}
        pagination={pagination}
        filters={filters}
        error={!!error}
        metadata={metadata}
        onChangeFilters={handleChangeFilter}
        onChangePage={handleChangePage}
      />
    </Panel>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RolodexFirmListRouteComponent);
