import { Panel } from '@cmg/common';
import styled from 'styled-components/macro';

import { InfoLabel, InfoValue } from '../../layout/info-box/InfoBoxes';

export const StyledPanelHeader = styled(Panel.Header)`
  margin-bottom: 25px;
`;

export const SFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 34px;
  margin: 5px 0;
`;

export const SButtonWrapper = styled.div`
  > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const StyledInfoLabel = styled(InfoLabel)`
  width: 120px;
`;

export const StyledInfoValue = styled(InfoValue)`
  flex: 1;
`;
