import { CenteredPage, urlUtil } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import externalRouteFactory from '../../../common/util/externalRouteFactory';
import FirstTimeActivationForm from './components/FirstTimeActivationForm';
import { activateUser, resetState, selectError, selectSubmitting, selectSuccess } from './ducks';

type QueryParams = {
  userId?: string;
  code?: string;
  firstName: string;
  lastName: string;
  email?: string;
};

const mapStateToProps = (state, ownProps: RouteComponentProps) => {
  const queryParams = urlUtil.queryParse<QueryParams>(ownProps.location.search);

  return {
    error: selectError(state),
    success: selectSuccess(state),
    submitting: selectSubmitting(state),
    userId: queryParams.userId, // user we are activating, pairs with the token
    token: queryParams.code, // activation token for this user
    firstName: queryParams.firstName, // users name as it was entered by whoever created their account
    lastName: queryParams.lastName,
    email: queryParams.email, // for read only display
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      activateUser,
      resetState,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RouteProps = RouteComponentProps;
type Props = RouteProps & StateProps & DispatchProps;

/**
 * Displays a centered user activation form. This route is accessed from an activate
 * user account email.
 * If you come here without having the necessary info to activate, then it
 * redirects to login.
 */
export class FirstTimeActivationRouteComponent extends React.Component<Props> {
  componentWillUnmount() {
    this.props.actions.resetState();
  }

  render() {
    const { error, submitting, success, actions, userId, token, email, firstName, lastName } =
      this.props;

    // Didn't come here directly from email, so kick em out
    if (!userId || !token || !email) {
      return <Redirect to={externalRouteFactory.ecmSpa.getUrl()} />;
    }

    return (
      <CenteredPage title="Activate Account">
        <FirstTimeActivationForm
          onSubmit={actions.activateUser}
          error={error}
          success={success}
          submitting={submitting}
          token={token}
          userId={userId}
          firstName={firstName}
          lastName={lastName}
          email={email}
        />
      </CenteredPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstTimeActivationRouteComponent);
