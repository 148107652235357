import { Autocomplete, AutocompleteTextFieldProps, TextField } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import { useMemo, VFC } from 'react';

import { FormFieldLayout } from '../../../../../design-system/body-sections/form/FormFieldLayout';
import { FormLayout } from '../../../../../design-system/body-sections/form/FormLayout';
import { AccountType } from '../../../../../types/domain/account/constants';
import { Profile, ProfileUpdate } from '../../../../../types/domain/self/profile';
import { getJobFunctionLabel, getJobFunctionOptions } from '../../../../shared/model/job-function';

type Props = {
  profile: Readonly<Profile>;
  oidcUserAccountType: Readonly<AccountType | 'SYSTEM' | null>;
};

export const ProfileDetailsSectionEdit: VFC<Props> = ({ oidcUserAccountType, profile }) => {
  const formik = useFormikContext<ProfileUpdate>();

  const jobFunctionOptions = useMemo(
    () => getJobFunctionOptions(oidcUserAccountType),
    [oidcUserAccountType]
  );

  const jobFunctionTextFieldProps: AutocompleteTextFieldProps = useMemo(
    () => ({
      label: 'Job Function',
      required: true,
      name: 'jobFunction',
      placeholder: 'e.g. Analyst',
      error: Boolean(formik.errors.jobFunction),
      helperText: formik.errors.jobFunction,
    }),
    [formik.errors.jobFunction]
  );

  return (
    <FormLayout>
      <FormFieldLayout>
        <TextField
          label="First Name"
          required
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.firstName)}
          helperText={formik.errors.firstName}
          placeholder="e.g. Tim"
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          label="Last Name"
          required
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.lastName)}
          helperText={formik.errors.lastName}
          placeholder="e.g. Smith"
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField label="Email" name="email" value={profile.email} disabled />
      </FormFieldLayout>
      <FormFieldLayout>
        <Autocomplete
          options={jobFunctionOptions.map(x => x.value)}
          getOptionLabel={option => getJobFunctionLabel(option, oidcUserAccountType)}
          value={formik.values.jobFunction}
          onChange={(_e, value) => formik.setFieldValue('jobFunction', value)}
          TextFieldProps={jobFunctionTextFieldProps}
        />
      </FormFieldLayout>
    </FormLayout>
  );
};
