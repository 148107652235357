import { InvestorFirmAdminRead as CrmInvestorFirm } from '@capital-markets-gateway/api-client-rolodex';
import { apiTypes, duckPartFactory } from '@cmg/common';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  GetCrmInvestorFirmsParams,
  GetCrmInvestorFirmsResponse,
} from '../../../common/api/rolodexApiClient';
import * as rolodexApiClient from '../../../common/api/rolodexApiClient';
import { RootState } from '../../../common/redux/rootReducer';

export const fetchCrmInvestorFirmsDuckParts = duckPartFactory.makeAPIDuckParts<
  GetCrmInvestorFirmsParams,
  {
    data: CrmInvestorFirm[];
    pagination: apiTypes.Pagination;
  }
>({
  prefix: 'ROLODEX/CRM_INVESTOR_FIRMS_LIST',
});

/**
 * ACTION CREATORS
 */

export const fetchCrmInvestorFirms = fetchCrmInvestorFirmsDuckParts.actionCreators.request;
type fetchCrmInvestorFirmsAction = ReturnType<typeof fetchCrmInvestorFirms>;

/**
 * REDUCERS
 */

export const initialState = {
  crmInvestorFirms: fetchCrmInvestorFirmsDuckParts.initialState,
};

export type ReducerState = typeof initialState;

export default combineReducers<ReducerState>({
  crmInvestorFirms: fetchCrmInvestorFirmsDuckParts.reducer,
});

/**
 * SELECTORS
 */

const selectState = (state: RootState) => state.rolodexCrmIntegrationDetail;

const crmInvestorFirmsSelectors = fetchCrmInvestorFirmsDuckParts.makeSelectors(
  state => selectState(state).crmInvestorFirms
);
export const selectCrmInvestorFirmsLoading = crmInvestorFirmsSelectors.selectLoading;
export const selectCrmInvestorFirmsError = crmInvestorFirmsSelectors.selectError;

export const selectCrmInvestorFirms = (state: RootState) => {
  const successBody = crmInvestorFirmsSelectors.selectData(state);
  return successBody ? successBody.data : [];
};
export const selectCrmInvestorFirmsPagination = (state: RootState) => {
  const successBody = crmInvestorFirmsSelectors.selectData(state);
  return successBody ? successBody.pagination : undefined;
};

/**
 * SAGAS
 */

export function* fetchCrmInvestorFirmsSaga({ payload }: fetchCrmInvestorFirmsAction): SagaIterator {
  const response: GetCrmInvestorFirmsResponse = yield call(
    rolodexApiClient.getCrmInvestorFirms,
    payload
  );

  if (response.ok) {
    yield put(fetchCrmInvestorFirmsDuckParts.actionCreators.success(response.data));
  } else {
    yield put(fetchCrmInvestorFirmsDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* rolodexCrmIntegrationDetailSaga() {
  yield takeLatest<fetchCrmInvestorFirmsAction>(
    fetchCrmInvestorFirmsDuckParts.actionTypes.REQUEST,
    fetchCrmInvestorFirmsSaga
  );
}
