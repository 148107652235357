import { apiTypes, ApplicationError, urlUtil } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { getAppSettings } from '../../../common/config/appSettings';
import routeFactory from '../../../common/util/routeFactory';
import { ErrorCode, errorCodeMap } from './constants';

type Props = RouteComponentProps;

/**
 * Displays a centered error display letting the user know why they landed here
 * and allowing them to go back to the ECM homepage.
 */
const ErrorRoute = (props: Props) => {
  const appSettings = getAppSettings();
  const query = urlUtil.queryParse<{
    errorCode?: apiTypes.ServiceErrorCode | ErrorCode;
  }>(props.location.search);

  return (
    <div data-testid="error-page">
      <ApplicationError
        errorCode={query.errorCode}
        customErrorDetails={errorCodeMap}
        returnUrl={`${appSettings.client.basename}${routeFactory.root.getUrlPath()}`}
      />
    </div>
  );
};

export default ErrorRoute;
