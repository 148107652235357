import { apiTypes } from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';

import usePrevious from '../../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../../design-system/alert/ServerErrorAlert';
import { PageGridSection } from '../../../../../../design-system/body-sections/PageGridSection';
import { PageGridSectionActions } from '../../../../../../design-system/body-sections/PageGridSectionActions';
import {
  OpenIdConnectConfigurationUpdate,
  OpenIdConnectMetadata,
} from '../../../../../../types/domain/identity-provider/configurations/openIdConnect';
import { IdentityProviderOpenIdConnect } from '../../../../../../types/domain/identity-provider/identityProvider';
import { ProviderDetailsSectionEdit } from './ProviderDetailsSectionEdit';
import { ProviderDetailsSectionReadonly } from './ProviderDetailsSectionReadonly';
import { OpenIdConfigurationUpdateSchema } from './utils';

export type Props = Readonly<{
  readonly canEdit: boolean;
  readonly provider: IdentityProviderOpenIdConnect | null;
  readonly metadata: OpenIdConnectMetadata | null;
  readonly error: apiTypes.GenericServerError | null;
  readonly isSubmitting: boolean;
  readonly onSubmit: (data: OpenIdConnectConfigurationUpdate) => void;
  readonly onReset: () => void;
}>;

export const ProviderDetailsSection: FC<Props> = ({
  canEdit,
  provider,
  metadata,
  error,
  isSubmitting,
  onSubmit,
  onReset,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const formik = useFormik<OpenIdConnectConfigurationUpdate>({
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: OpenIdConfigurationUpdateSchema,
    initialValues: {
      enabled: provider?.enabled ?? false,
      name: provider?.name ?? '',
      configuration: {
        clientId: provider?.configuration.clientId ?? '',
        authority: provider?.configuration.authority ?? '',
      },
    },
    onSubmit: val => onSubmit(val),
  });

  const { resetForm } = formik;
  const previousIsEditing = usePrevious(isEditing);
  useEffect(() => {
    if (previousIsEditing && !isEditing) {
      resetForm();
      onReset();
    }
  }, [isEditing, onReset, previousIsEditing, resetForm]);

  return (
    <FormikProvider value={formik}>
      <PageGridSection
        size="medium"
        title="Provider Details"
        FormComponent={Form}
        formComponentProps={{ noValidate: true }}
        actions={
          canEdit ? (
            <PageGridSectionActions
              isSubmitting={isSubmitting}
              isEditing={isEditing}
              onEdit={() => setIsEditing(true)}
              onCancel={() => setIsEditing(false)}
            />
          ) : undefined
        }
        error={error && <ServerErrorAlert title="Error" error={error} />}
        content={
          isEditing ? (
            <ProviderDetailsSectionEdit metadata={metadata} />
          ) : (
            <ProviderDetailsSectionReadonly provider={provider} metadata={metadata} />
          )
        }
      />
    </FormikProvider>
  );
};
