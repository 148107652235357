import { timeUtil } from '@cmg/common';
import { HighlightsTextItem } from '@cmg/design-system';
import React from 'react';

import { Profile } from '../../../../../types/domain/self/profile';

export type Props = {
  profile: Profile;
};

export const ProfileDomainHighlightsItems: React.VFC<Props> = ({ profile }) => {
  return (
    <React.Fragment>
      <HighlightsTextItem
        name="Created On:"
        text={timeUtil.formatAsDisplayDate(profile.createdOn)!}
      />
      <HighlightsTextItem
        name="Last Login:"
        text={timeUtil.formatAsDisplayDate(profile.lastLoginTime)!}
      />
      <HighlightsTextItem name="Last IP Address:" text={profile.lastIpAddress} />
    </React.Fragment>
  );
};
