import { Banner, DataGrid, Icon, Panel, ServerErrors, TextInput } from '@cmg/common';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';

import routeFactory from '../../../common/util/routeFactory';
import { FirmNameType } from '../../../types/domain/firm/constants';
import { FirmLimited } from '../../../types/domain/firm/FirmLimited';
import {
  fetchFirmList,
  fetchMetadata,
  selectFirms,
  selectFirmsError,
  selectFirmsLoading,
  selectFirmsPagination,
  selectMetadata,
} from '../shared/ducks';
import FirmNewHeader from './components/firm-new-header/FirmNewHeader';
import {
  createFirm,
  resetState,
  selectCreatedFirm,
  selectCreatedFirmError,
  selectCreatedFirmLoading,
} from './ducks';

export const StyledLoadingIcon = styled(Icon)`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.text.color.light};

  &&& {
    width: 45px;
  }
`;

const mapStateToProps = state => ({
  newFirm: selectCreatedFirm(state),
  isNewFirmLoading: selectCreatedFirmLoading(state),
  firmCreateError: selectCreatedFirmError(state),
  similarFirmsLoading: selectFirmsLoading(state),
  similarFirms: selectFirms(state),
  similarFirmsPagination: selectFirmsPagination(state),
  similarFirmsError: selectFirmsError(state),
  metadata: selectMetadata(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetState,
      fetchFirmList,
      createFirm,
      fetchMetadata,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = RouteComponentProps & StateProps & DispatchProps;

export const RolodexFirmCreateRouteComponent: React.FC<Props> = ({
  newFirm,
  firmCreateError,
  isNewFirmLoading,
  similarFirms,
  similarFirmsError,
  similarFirmsLoading,
  similarFirmsPagination,
  metadata,
  actions,
}) => {
  const [firmName, setFirmName] = React.useState<string | null>(null);

  useEffect(() => {
    actions.fetchMetadata();

    return () => {
      actions.resetState();
    };
  }, [actions]);

  return (
    <React.Fragment>
      {newFirm && (
        <Redirect to={routeFactory.rolodexFirmDetail.getUrlPath({ firmId: newFirm.id })} />
      )}
      <FirmNewHeader
        isCreateButtonEnabled={!!firmName && !similarFirmsLoading}
        isLoading={isNewFirmLoading}
        error={firmCreateError}
        onCreate={() =>
          firmName && actions.createFirm({ value: firmName, type: FirmNameType.COMMON })
        }
      />
      <Panel>
        <TextInput
          autoFocus={true}
          disabled={isNewFirmLoading}
          placeholder="Organization Name"
          suffix={
            similarFirmsLoading && <StyledLoadingIcon name="spinner-third" size="lg" spin={true} />
          }
          onChange={value => {
            setFirmName(value);
            if (value) {
              actions.fetchFirmList({ searchText: value, page: 1 });
            }
          }}
        />
        {similarFirmsError && (
          <Banner variant="error" showIcon={false}>
            <ServerErrors error={similarFirmsError} />
          </Banner>
        )}
        {firmName && !similarFirmsLoading && similarFirmsPagination && similarFirms.length > 0 && (
          <React.Fragment>
            <div>
              <Banner variant="warning">
                {similarFirmsPagination.totalCount === 1 && (
                  <span>There is 1 organization in the CMG Database with a similar name.</span>
                )}
                {similarFirmsPagination.totalCount > 1 && (
                  <span>
                    There are {similarFirmsPagination.totalCount} organizations in the CMG Database
                    with similar names.
                  </span>
                )}
                <span>&nbsp;Are you sure you want to create a new organization?</span>
              </Banner>
            </div>
            <DataGrid<FirmLimited>
              extended={{
                hideColumnResize: true,
                hideColumnSelector: true,
                hidePagination: true,
                withMargin: false,
              }}
              onGridReady={params => {
                params.api.resetRowHeights();
              }}
              pagination={
                similarFirmsPagination
                  ? {
                      page: similarFirmsPagination.activePage,
                      perPage: similarFirmsPagination.perPage,
                    }
                  : undefined
              }
              columns={[
                { field: 'displayName', sortable: false },
                { field: 'description', sortable: false },
                {
                  field: 'entityType',
                  headerName: 'Entity Type',
                  sortable: false,
                  valueFormatter: data => {
                    const entityType = metadata.entityTypes.find(type => type.value === data.value);

                    return entityType ? entityType.label : data.value;
                  },
                },
                {
                  field: 'industryType',
                  headerName: 'Industry Type',
                  sortable: false,
                  valueFormatter: data => {
                    const industryType = metadata.industryTypes.find(
                      type => type.value === data.value
                    );

                    return industryType ? industryType.label : data.value;
                  },
                },
              ]}
              rows={similarFirms}
              resizeBy="grid"
              gridOptions={{
                suppressCellSelection: true,
                suppressRowClickSelection: true,
              }}
            />
          </React.Fragment>
        )}
      </Panel>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RolodexFirmCreateRouteComponent);
