import { numericUtil } from '@cmg/common';
import { Chip } from '@cmg/design-system';
import { VFC } from 'react';

import { DescriptionList } from '../../../../../design-system/body-sections/description-list/DescriptionList';
import { DescriptionListItem } from '../../../../../design-system/body-sections/description-list/DescriptionListItem';
import { Account } from '../../../../../types/domain/account/account';
import {
  accountTypeLabels,
  externalLicenseLabels,
  licenseTypeLabels,
} from '../../../../../types/domain/account/constants';

export type Props = {
  account: Readonly<Account>;
};

export const SubscriptionSectionReadonly: VFC<Props> = ({ account }) => {
  return (
    <DescriptionList>
      <DescriptionListItem label="Name" value={account.name} />
      <DescriptionListItem label="Subdomain" value={account.subdomain} />
      <DescriptionListItem
        label="Account Type"
        value={account.accountType && accountTypeLabels[account.accountType]}
      />
      <DescriptionListItem label="License Type" value={licenseTypeLabels[account.licenseType]} />
      <DescriptionListItem
        label="License Limit"
        value={numericUtil.getDisplayValueForInteger(account.licenseCount)}
      />
      <DescriptionListItem
        label="Remaining License Count"
        value={numericUtil.getDisplayValueForInteger(account.remainingLicenseCount)}
      />
      <DescriptionListItem
        label="External Licenses"
        value={
          account.externalLicenses?.length
            ? account.externalLicenses.map(license => (
                <Chip key={license} label={externalLicenseLabels[license]} />
              ))
            : 'None'
        }
      />
    </DescriptionList>
  );
};
