import { CrmCsvImportResult } from '@capital-markets-gateway/api-client-rolodex';
import { apiTypes } from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import { VFC } from 'react';

import { ServerErrorAlert } from '../../../../../design-system/alert/ServerErrorAlert';
import { PageGridSection } from '../../../../../design-system/body-sections/PageGridSection';
import { CRMFileTypes } from '../../../../../types/domain/crm/constants';
import { CRMFilesUploadForm } from './CRMFilesUploadForm';
import { CRMFilesUploadFormValues, ProcessedSoFar } from './CRMFilesUploadSection.model';

export type Props = {
  readonly importResult: CrmCsvImportResult['progress'];
  readonly onUpload: (formValues: CRMFilesUploadFormValues) => void;
  readonly isUploading: boolean;
  readonly uploadError: apiTypes.GenericServerError | null;
  readonly processedSoFar: ProcessedSoFar;
};

export const CRMFilesUploadSection: VFC<Props> = ({
  importResult,
  isUploading,
  uploadError,
  onUpload,
  processedSoFar,
}) => {
  const formik = useFormik<CRMFilesUploadFormValues>({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      crmFileType: CRMFileTypes.INVESTORS,
      file: null,
      skip: 0,
    },
    onSubmit: formValues => {
      onUpload(formValues);
    },
  });

  return (
    <FormikProvider value={formik}>
      <PageGridSection
        title="Select CRM File To Upload"
        FormComponent={Form}
        formComponentProps={{ noValidate: true }}
        error={uploadError && <ServerErrorAlert title="Error!" error={uploadError} />}
        content={
          <CRMFilesUploadForm
            importResult={importResult}
            isUploading={isUploading}
            processedSoFar={processedSoFar}
          />
        }
      />
    </FormikProvider>
  );
};
