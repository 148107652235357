import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as yup from 'yup';

import { getFirmSectorColumns } from '../../../../../common/components/layout/firm-record-panel/firmSectorColumns';
import FirmRecordPanel from '../../../../../common/components/layout/firm-record-panel/GenericFirmRecordPanel';
import { UUID } from '../../../../../types/common';
import { FirmSector } from '../../../../../types/domain/firm/FirmSector';
import {
  cancelEditRecord,
  createFirmSector,
  editRecord,
  FirmDetailSection,
  selectEditingRecordIds,
  selectFirmSectors,
  selectFirmSectorsError,
  selectUpdatingRecordIds,
  updateFirmSector,
} from '../../ducks';

const mapStateToProps = state => ({
  sectors: selectFirmSectors(state),
  error: selectFirmSectorsError(state),
  editingRecordIds: selectEditingRecordIds(state),
  updatingRecordIds: selectUpdatingRecordIds(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createFirmSector,
      updateFirmSector,
      editRecord,
      cancelEditRecord,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  firmId: UUID;
};
type Props = OwnProps & StateProps & DispatchProps;

export const FirmSectorsValidationSchema = yup.object().shape({
  scheme: yup.string().nullable().required('A sector type is required'),
  code: yup.string().nullable().required('A sector is required'),
  recordStatus: yup.string().nullable().required('A status type is required'),
});

export const FirmSectorsRecordPanelComponent: React.FC<Props> = ({
  firmId,
  error,
  editingRecordIds,
  updatingRecordIds,
  sectors,
  actions,
}) => {
  return (
    <FirmRecordPanel<FirmSector>
      sectionId={FirmDetailSection.SECTORS}
      title="Sector Classification Schemes"
      addRecordButtonLabel="Add Scheme"
      error={error}
      editingRecordIds={editingRecordIds}
      updatingRecordIds={updatingRecordIds}
      onCreate={sector => actions.createFirmSector({ firmId, sector })}
      onUpdate={sector => actions.updateFirmSector({ firmId, sector })}
      onEdit={recordId => actions.editRecord({ recordId })}
      onCancelEdit={recordId => actions.cancelEditRecord({ recordId })}
      columns={getFirmSectorColumns()}
      rows={sectors}
      validationSchema={FirmSectorsValidationSchema}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmSectorsRecordPanelComponent);
