import { FirmCategoryType } from '@capital-markets-gateway/api-client-rolodex';
import { Icon, SecondaryButton, SolidIconName, timeUtil } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';

import Status from '../../../../../common/components/indicators/status/Status';
import DetailHeader from '../../../../../common/components/layout/detail-header/DetailHeader';
import {
  LinkedStatus,
  linkedStatusLabels,
} from '../../../../../types/domain/entity-matcher/constants';
import { selectCrmInvestorFirm } from '../../ducks';
import CrmInvestorFirmNotesEditModal from './crm-investor-frim-detail-notes/CrmInvestorFirmNotesEditDialog';
import {
  SHeaderDiv,
  SIconWrapper,
  SInfoIconWrapper,
  StyledDetailHeader,
  StyledDetailHeaderTitleWrapper,
  StyledTitleDetailHeaderColumn,
} from './CrmInvestorFirmDetailHeader.styles';

const mapStateToProps = state => ({
  crmInvestorFirm: selectCrmInvestorFirm(state),
});

export type OwnProps = {
  onSave: (notes: string | null) => void;
  loading: boolean;
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

export const IconWrapper: React.FC<{ name: SolidIconName }> = ({ name }) => {
  return (
    <SInfoIconWrapper>
      <SHeaderDiv>
        <Icon name={name} variant="solid" size="sm" color="white" fixedWidth={true} />
      </SHeaderDiv>
    </SInfoIconWrapper>
  );
};

export const InvestorIcon: React.FC<{ firmType: string }> = ({ firmType }) => {
  switch (firmType) {
    case FirmCategoryType.FREE_TEXT:
      return (
        <SIconWrapper>
          <IconWrapper name="poll-h" />
          <div>Free Text Investor</div>
        </SIconWrapper>
      );
    case FirmCategoryType.PWM:
      return (
        <SIconWrapper>
          <IconWrapper name="user-tie" />
          <div>PWM Investor</div>
        </SIconWrapper>
      );
    default:
      return null;
  }
};

/**
 * The header for the CRM Record page.
 */
export const CrmInvestorFirmDetailHeaderComponent: React.FC<Props> = ({
  crmInvestorFirm,
  onSave,
  loading,
}) => {
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  if (!crmInvestorFirm) {
    return null;
  }

  return (
    <StyledDetailHeader>
      <StyledDetailHeaderTitleWrapper>
        <StyledTitleDetailHeaderColumn>
          <DetailHeader.Row>
            <DetailHeader.Title>{crmInvestorFirm.name}</DetailHeader.Title>
            <React.Fragment>
              <InvestorIcon firmType={crmInvestorFirm.firmType} />
              <Status
                isActive={crmInvestorFirm.linkedStatus === LinkedStatus.LINKED}
                activeLabel={linkedStatusLabels[crmInvestorFirm.linkedStatus]}
                inactiveLabel={linkedStatusLabels[crmInvestorFirm.linkedStatus]}
                inactiveIsNeutral={true}
              />
            </React.Fragment>
          </DetailHeader.Row>
          <DetailHeader.Row>
            <DetailHeader.DetailWrapper>
              <DetailHeader.DetailLabel>Last Modified:</DetailHeader.DetailLabel>
              <DetailHeader.DetailValue>
                {timeUtil.formatAsDisplayDateTime(crmInvestorFirm.modifiedOn)}
              </DetailHeader.DetailValue>
            </DetailHeader.DetailWrapper>
            <DetailHeader.DetailWrapper>
              <DetailHeader.DetailLabel>Modified By:</DetailHeader.DetailLabel>
              <DetailHeader.DetailValue>
                {crmInvestorFirm.modifiedByUserName}
              </DetailHeader.DetailValue>
            </DetailHeader.DetailWrapper>
          </DetailHeader.Row>
        </StyledTitleDetailHeaderColumn>
      </StyledDetailHeaderTitleWrapper>
      <StyledDetailHeaderTitleWrapper>
        <SecondaryButton onClick={() => setIsModalVisible(true)}>View/Edit Notes</SecondaryButton>
        {isModalVisible && (
          <CrmInvestorFirmNotesEditModal
            notes={crmInvestorFirm.notes}
            isOpen={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            onSave={onSave}
            loading={loading}
          />
        )}
      </StyledDetailHeaderTitleWrapper>
    </StyledDetailHeader>
  );
};

export default connect(mapStateToProps)(CrmInvestorFirmDetailHeaderComponent);
