import { Box, Divider, Stack, Typography } from '@cmg/design-system';
import { ReactNode, VFC } from 'react';

export type PageGridSectionHeaderProps = {
  title: Readonly<string>;
  actions?: Readonly<ReactNode>;
};

export const PageGridSectionHeader: VFC<PageGridSectionHeaderProps> = ({ title, actions }) => {
  return (
    <Box>
      <Stack direction="row" alignItems="center" px={1}>
        <Box flexGrow={1} py={2}>
          <Typography variant="h3">{title}</Typography>
        </Box>
        {actions && (
          <Stack direction="row" alignItems="center" gap={1}>
            {actions}
          </Stack>
        )}
      </Stack>
      <Divider />
    </Box>
  );
};
