import { FirmCategoryType, RecordStatus } from '@capital-markets-gateway/api-client-rolodex';
import { apiTypes, urlUtil } from '@cmg/common';

import { LinkedStatus } from '../../../types/domain/entity-matcher/constants';

export const getQueryParams = (queryString: string) => {
  const {
    searchText = null,
    linkedStatus,
    firmType,
    page = '1',
    perPage = '25',
    orderField,
    orderDirection,
    firmStatus = RecordStatus.EFFECTIVE,
  } = urlUtil.queryParse<{
    searchText?: string | null;
    firmType?: FirmCategoryType | null;
    linkedStatus?: LinkedStatus | null;
    page?: string | null;
    perPage?: string | null;
    orderField?: string | null;
    orderDirection?: apiTypes.SortDirection | null;
    firmStatus: RecordStatus | null;
  }>(queryString);

  return {
    searchText,
    linkedStatus: linkedStatus && LinkedStatus[linkedStatus] ? LinkedStatus[linkedStatus] : null,
    firmType: firmType && FirmCategoryType[firmType] ? FirmCategoryType[firmType] : null,
    page,
    perPage,
    orderField,
    orderDirection,
    firmStatus,
  };
};
