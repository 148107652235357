import { PrimaryButton, SelectField, TextInputField } from '@cmg/common';
import styled from 'styled-components/macro';

export const SFormFields = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10px 0;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 5px;
`;

export const StyledTextInputField = styled(TextInputField)`
  min-width: 70px;
  padding-right: 5px;
  flex: 1;

  &:last-child {
    padding: 0;
  }
`;

export const StyledSelectField = styled(SelectField)`
  min-width: 100px;
  margin-right: 1px;
`;

export const SButtons = styled.div`
  text-align: right;
  margin-bottom: 10px;
`;

export const SClearIcon = styled.div`
  padding: 10px;
  cursor: pointer;
`;

export const SKeyPreview = styled.span`
  padding-left: 15px;
  color: ${({ theme }) => theme.text.color.light};
`;
