import { DataGridClient } from '@cmg/common';
import React from 'react';

import { AccountTrait } from './TraitList.model';
import traitListGridColumns from './TraitListGridColumns';

export type Props<TTrait extends AccountTrait = AccountTrait> = {
  loading: boolean;
  traits?: readonly TTrait[];
};

/**
 * Displays Product Traits in a grid. There is no pagination, the exhaustive list must be passed in.
 */
function TraitList<TTrait extends AccountTrait = AccountTrait>({
  traits = [],
  loading,
}: Props<TTrait>): React.ReactElement {
  return (
    <React.Fragment>
      <DataGridClient<TTrait>
        columns={traitListGridColumns}
        rows={traits as TTrait[]}
        resizeBy="grid"
        extended={{
          hidePagination: true,
        }}
        getRowNodeId={(trait: TTrait) => trait.code}
        gridOptions={{
          suppressCellSelection: true,
          suppressRowClickSelection: true,
          overlayNoRowsTemplate: 'No traits found',
        }}
        loading={loading}
      />
    </React.Fragment>
  );
}

export default TraitList;
