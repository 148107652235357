import { Grid, GridProps } from '@cmg/design-system';
import { ReactNode, VFC } from 'react';

export type FormDialogContentItemProps = Pick<GridProps, 'xs' | 'sm' | 'md' | 'lg'> & {
  value: Readonly<ReactNode>;
};

export const FormDialogContentItem: VFC<FormDialogContentItemProps> = ({
  xs = 12,
  sm,
  md,
  lg,
  value,
}) => {
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      {value}
    </Grid>
  );
};
