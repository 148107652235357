import {
  apiTypes,
  Banner,
  Icon,
  PrimaryButton,
  ServerErrors,
  StackedInputGroup,
  TextInputField,
} from '@cmg/common';
import { resetPasswordScreenSelector } from '@cmg/e2e-selectors';
import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { PasswordReset } from '../../../../types/domain/self/PasswordReset';
import { SButtonsSection, SHeading } from './ResetPasswordForm.styles';

// exported for testing only
export const ResetPasswordSchema = yup.object().shape({
  newPassword: yup.string().required('A password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

type OwnProps = {
  onSubmit: (values: PasswordReset) => void;
  error: apiTypes.GenericServerError | null;
  success: boolean;
  submitting: boolean;
  token: string;
  userId: string;
  // TODO add email once ECM-1997 is complete
};
type Props = OwnProps & FormikProps<PasswordReset>;

export class ResetPasswordFormComponent extends React.Component<Props> {
  render() {
    const { error, submitting, success } = this.props;

    if (success) {
      return (
        <SHeading>
          Your password was updated. Check your email to log in. If you don’t hear from us in a few
          minutes, please check your spam folder or email{' '}
          <a href="mailto:support@cmgx.io">support@cmgx.io</a>
        </SHeading>
      );
    }

    return (
      <Form data-test-id={resetPasswordScreenSelector.testId}>
        <StackedInputGroup>
          <TextInputField
            size="large"
            prefix={<Icon name="lock-alt" />}
            type="password"
            name="newPassword"
            placeholder="New Password"
            fullWidth
          />
          <TextInputField
            size="large"
            prefix={<Icon name="lock-alt" />}
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            fullWidth
          />
        </StackedInputGroup>
        <SButtonsSection>
          {error && (
            <Banner variant="error" showIcon={false}>
              <ServerErrors error={error} />
            </Banner>
          )}
          <PrimaryButton
            variant="high-contrast"
            size="large"
            loading={submitting}
            type="submit"
            fullWidth
          >
            Reset Password
          </PrimaryButton>
        </SButtonsSection>
      </Form>
    );
  }
}

export default withFormik<OwnProps, PasswordReset>({
  enableReinitialize: false,
  isInitialValid: false,
  validateOnChange: false,
  validateOnBlur: true,
  validationSchema: ResetPasswordSchema,
  mapPropsToValues: ({ token, userId }) => ({
    newPassword: '',
    confirmPassword: '',
    token,
    userId,
  }),
  handleSubmit: (values, formikBag) => formikBag.props.onSubmit(values),
})(ResetPasswordFormComponent);
