import { apiTypes, duckPartFactory } from '@cmg/common';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import systemManagementApiClient, {
  GetAccountParams,
  GetAccountsResponse,
  GetTraitResponse,
} from '../../../common/api/systemManagementApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { Account } from '../../../types/domain/account/account';
import { Trait } from '../../../types/domain/trait/Trait';

const traitDuckParts = duckPartFactory.makeAPIDuckParts<{ traitCode: string }, Trait>({
  prefix: 'GLOBAL_MANAGEMENT/TRAIT_DETAIL/TRAIT',
});

type AccountsSuccessValue = {
  data: Account[];
  pagination: apiTypes.Pagination;
};
const accountDuckParts = duckPartFactory.makeAPIDuckParts<GetAccountParams, AccountsSuccessValue>({
  prefix: 'GLOBAL_MANAGEMENT/TRAIT_DETAIL/ACCOUNT_LIST',
});

/**
 * ACTION CREATORS
 */

export const fetchTrait = traitDuckParts.actionCreators.request;
type FetchTraitAction = ReturnType<typeof fetchTrait>;

export const fetchAccountList = accountDuckParts.actionCreators.request;
type FetchAccountListAction = ReturnType<typeof fetchAccountList>;

/**
 * REDUCERS
 */

export const initialState = {
  traitPart: traitDuckParts.initialState,
  accountsPart: accountDuckParts.initialState,
};

export type ReducerState = typeof initialState;

export default combineReducers<ReducerState>({
  traitPart: traitDuckParts.reducer,
  accountsPart: accountDuckParts.reducer,
});

/**
 * SELECTORS
 */

const selectLocalState = (state: RootState) => state.adminTraitDetail;
const traitSelectors = traitDuckParts.makeSelectors(state => selectLocalState(state).traitPart);
const accountListSelectors = accountDuckParts.makeSelectors(
  state => selectLocalState(state).accountsPart
);

export const selectTraitParts = traitSelectors.selectAll;
export const selectAccountListParts = accountListSelectors.selectAll;

/**
 * SAGAS
 */

export function* fetchTraitSaga({ payload }: FetchTraitAction): SagaIterator {
  const response: GetTraitResponse = yield call(
    systemManagementApiClient.admin.getTrait,
    payload.traitCode
  );
  if (response.ok) {
    yield put(traitDuckParts.actionCreators.success(response.data));
  } else {
    yield put(traitDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* fetchAccountListSaga({ payload }: FetchAccountListAction): SagaIterator {
  const response: GetAccountsResponse = yield call(
    systemManagementApiClient.admin.getAccounts,
    payload
  );
  if (response.ok) {
    yield put(accountDuckParts.actionCreators.success(response.data));
  } else {
    yield put(accountDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* adminTraitDetailSaga() {
  yield takeLatest<FetchTraitAction>(traitDuckParts.actionTypes.REQUEST, fetchTraitSaga);
  yield takeLatest<FetchAccountListAction>(
    accountDuckParts.actionTypes.REQUEST,
    fetchAccountListSaga
  );
}
