import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SnackbarManager,
} from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import { OwnProps as CrmInvestorFirmDetailHeaderProps } from '../CrmInvestorFirmDetailHeader';
import { validationSchema, Values } from './CrmInvestorFirmNotesEditDialog.model';

type OwnProps = {
  notes: string | null;
  isOpen: boolean;
  onClose: () => void;
};

type Props = OwnProps & CrmInvestorFirmDetailHeaderProps;
export const CrmInvestorFirmNotesEditDialog: React.FC<Props> = ({
  notes,
  isOpen,
  onClose,
  onSave,
  loading,
}) => {
  const formik = useFormik<Values>({
    validationSchema,
    initialValues: { notes },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: values => {
      try {
        onSave(values.notes ?? null);

        SnackbarManager.success('Successfully updated notes');
        handleCloseDialog();
      } catch (error) {
        SnackbarManager.error('Failed to update notes');
      }
    },
  });

  const { resetForm } = formik;

  const handleCloseDialog = () => {
    resetForm();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen}>
      <DialogTitle>Notes</DialogTitle>
      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form>
            <FormikTextField
              name="notes"
              multiline
              placeholder="Enter Notes…"
              rows={6}
              showHelperTextInTooltip={false}
            />
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading}
          onClick={() => formik.handleSubmit()}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CrmInvestorFirmNotesEditDialog;
