import { FactSetFirmHierarchy } from '../../../../types/domain/firm/FactSetFirmHierarchy';
import { FirmHierarchy } from '../../../../types/domain/firm/FirmHierarchy';
import { createTreeGridRowsFromFactSetFirmHierarchy } from '../factset-firm-hierarchy-tree-grid/utils/hierarchyUtils';
import { createTreeGridRowsFromHierarchy } from './utils/hierarchyUtils';

export const getRows = (
  hierarchy: FirmHierarchy | FactSetFirmHierarchy | null,
  isFactSetFirmHierarchy: boolean
) => {
  if (!hierarchy) {
    return [];
  }

  return isFactSetFirmHierarchy
    ? createTreeGridRowsFromFactSetFirmHierarchy(hierarchy as FactSetFirmHierarchy)
    : createTreeGridRowsFromHierarchy(hierarchy as FirmHierarchy);
};
