import { TextField } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { FormFieldLayout } from '../../../../../design-system/body-sections/form/FormFieldLayout';
import { FormLayout } from '../../../../../design-system/body-sections/form/FormLayout';
import { UserEmailUpdate } from '../../../../../types/domain/user/user';

export const LoginDetailsSectionEdit: FC = () => {
  const formik = useFormikContext<UserEmailUpdate>();

  return (
    <FormLayout>
      <FormFieldLayout>
        <TextField
          label="Email"
          required
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email}
        />
      </FormFieldLayout>
    </FormLayout>
  );
};
