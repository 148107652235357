import { Icon } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { LinkedStatus } from '../../../../../types/domain/entity-matcher/constants';

export type Props = {
  /** A description of the linked status change */
  description: string;
  /** The name of the firm whose linked status change is originating from */
  from: string;
  /** The name of the firm impacted by the linked status change. */
  to: string;
  /** The linked status change value. */
  pendingLinkedStatus: LinkedStatus;
};

export const SWrapper = styled.div`
  margin: 0 0 15px 0;
`;

export const SLinkStatusChangeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const SLinkStatusFirmNameLabel = styled.div`
  color: ${({ theme }) => theme.text.color.light};
  font-size: ${({ theme }) => theme.text.size.small};
  text-transform: uppercase;
  margin-right: 5px;
`;

export const SLinkStatusFirmName = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const StyledIcon = styled(Icon)`
  margin: 0 15px;
`;

/**
 * Displays a change in Linked Status between two firms.
 */
export const FirmLinkedStatusChange: React.FC<Props> = ({
  description,
  from,
  to,
  pendingLinkedStatus,
}) => (
  <SWrapper>
    <div>{description}</div>
    <SLinkStatusChangeWrapper>
      <React.Fragment>
        <SLinkStatusFirmNameLabel>CRM Org:</SLinkStatusFirmNameLabel>
        <SLinkStatusFirmName>{from}</SLinkStatusFirmName>
      </React.Fragment>
      <StyledIcon
        name={pendingLinkedStatus === LinkedStatus.NOT_LINKED ? 'unlink' : 'link'}
        variant="light"
        size="lg"
      />
      <React.Fragment>
        <SLinkStatusFirmNameLabel>CMG Org:</SLinkStatusFirmNameLabel>
        <SLinkStatusFirmName>{to}</SLinkStatusFirmName>
      </React.Fragment>
    </SLinkStatusChangeWrapper>
  </SWrapper>
);

export default FirmLinkedStatusChange;
