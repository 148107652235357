import styled from 'styled-components/macro';

export const STopSection = styled.div`
  margin: 32px 0 22px;
`;

export const SDomainMaskLabel = styled.strong`
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.text.color.dark};
  margin-right: 20px;
`;
