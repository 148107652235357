import { TableSkeleton } from '@cmg/design-system';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ServerErrorAlert } from '../../../../design-system/alert/ServerErrorAlert';
import { Role } from '../../../../types/domain/role/role';
import {
  fetchAccountPermissions,
  selectAccountPermissions,
  selectAccountPermissionsError,
  selectAccountPermissionsLoading,
} from '../../../account-detail/shared/ducks';
import { PermissionsTable } from '../api-keys/shared/PermissionsTable';
import { PermissionsTableGroupHeader } from '../api-keys/shared/PermissionsTableGroupHeader';
import { PermissionsTableHeader } from '../api-keys/shared/PermissionsTableHeader';
import { PermissionsTableRow } from '../api-keys/shared/PermissionsTableRow';

const mapStateToProps = state => ({
  error: selectAccountPermissionsError(state),
  loading: selectAccountPermissionsLoading(state),
  accountPermissions: selectAccountPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchAccountPermissions,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = Readonly<{
  readonly role: Role;
}>;

export type Props = OwnProps & StateProps & DispatchProps;

export const RolePermissionsTable: React.FC<Props> = ({
  role,
  accountPermissions,
  error,
  loading,
  actions,
}) => {
  React.useEffect(() => {
    actions.fetchAccountPermissions(undefined);
  }, [actions]);

  if (loading) {
    return <TableSkeleton aria-label="Loading Permissions" />;
  }

  return (
    <React.Fragment>
      {error && <ServerErrorAlert title="Error" error="Could not load permissions" />}
      {!error && !loading && (
        <PermissionsTable
          accountPermissions={accountPermissions}
          selectedPermissions={role.permissions}
          renderTableHeader={() => <PermissionsTableHeader />}
          renderGroupHeader={groupHeader => (
            <PermissionsTableGroupHeader groupHeader={groupHeader} />
          )}
          renderRow={props => <PermissionsTableRow {...props} isInEditMode={false} />}
        />
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RolePermissionsTable);
