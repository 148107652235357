import { ColDef } from 'ag-grid-community';
import React from 'react';

import { UUID } from '../../../../../types/common';
import { FirmMatchRow } from '../../../../../types/domain/firm-match/FirmMatch';
import {
  AddressCellRenderer,
  IdentifiersCellRenderer,
  LinkButtonCellRenderer,
  NameCellRenderer,
  OriginCellRenderer,
  OtherNamesCellRenderer,
  ScoreCellRenderer,
  WebDomainsCellRenderer,
} from './cell-renderers';
import LinkFrequencyCellRenderer from './cell-renderers/LinkFrequencyCellRenderer';

export const createColumns = ({
  crmIntegrationId,
  crmInvestorFirmId,
  cmgEntityKey,
  firmType,
}: {
  crmIntegrationId: UUID;
  crmInvestorFirmId: UUID;
  cmgEntityKey?: string;
  firmType: string;
}): ColDef[] =>
  [
    {
      field: 'origin',
      headerName: 'Origin',
      sortable: false,
      minWidth: 60,
      maxWidth: 80,
      cellRendererFramework: OriginCellRenderer,
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      minWidth: 180,
      maxWidth: 220,
      cellRendererFramework: ({ data }: { data: FirmMatchRow }) => (
        <NameCellRenderer
          data={data}
          crmIntegrationId={crmIntegrationId}
          crmInvestorFirmId={crmInvestorFirmId}
          crmInvestorFirmType={firmType}
        />
      ),
      suppressColumnsToolPanel: true,
      cellStyle: {
        overflow: 'visible',
        width: '100%',
      },
    },
    {
      field: 'score',
      headerName: 'Score',
      sortable: false,
      minWidth: 100,
      maxWidth: 100,
      cellRendererFramework: ({ data }) => (
        <ScoreCellRenderer data={data} cmgEntityKey={cmgEntityKey} />
      ),
    },
    {
      field: 'link',
      headerName: 'Link Status',
      sortable: false,
      minWidth: 120,
      maxWidth: 120,
      cellRendererFramework: ({ data }: { data: FirmMatchRow }) => (
        <LinkButtonCellRenderer
          data={data}
          crmIntegrationId={crmIntegrationId}
          crmInvestorFirmId={crmInvestorFirmId}
          cmgEntityKey={cmgEntityKey}
        />
      ),
    },
    {
      field: 'linkFrequency',
      headerName: 'Link Frequency',
      sortable: false,
      minWidth: 120,
      maxWidth: 120,
      cellRendererFramework: LinkFrequencyCellRenderer,
    },
    {
      field: 'names',
      headerName: 'Other Names',
      sortable: false,
      minWidth: 370,
      cellRendererFramework: OtherNamesCellRenderer,
    },
    {
      field: 'ultimateParentName',
      headerName: 'Ultimate Parent Name',
      sortable: false,
      minWidth: 120,
    },
    {
      field: 'identifiers',
      headerName: 'Identifier',
      sortable: false,
      cellRendererFramework: IdentifiersCellRenderer,
      minWidth: 120,
    },
    {
      field: 'webDomain',
      headerName: 'Internet Domains',
      sortable: false,
      cellRendererFramework: WebDomainsCellRenderer,
      minWidth: 120,
    },
    {
      field: 'address',
      headerName: 'Address',
      sortable: false,
      cellRendererFramework: ({ data }: { data: FirmMatchRow }) => (
        <AddressCellRenderer data={data} />
      ),
      minWidth: 515,
    },
  ].map(colDef => ({
    ...colDef,
    pinnedRowCellRendererFramework: colDef.cellRendererFramework,
    cellStyle: {
      ...colDef.cellStyle,
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'normal',
      lineHeight: 1.2,
    },
  }));
