import { Form } from 'formik';
import styled from 'styled-components/macro';

export const StyledForm = styled(Form)`
  margin-top: 30px;
`;

export const SButtonsSection = styled.div`
  padding-top: 30px;
`;

export const SHeading = styled.div`
  font-size: ${({ theme }) => theme.text.size.large};
  margin: 25px 0 40px;
`;
