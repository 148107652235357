import { Alert, PrimaryButton, Progressbar, SecondaryButton } from '@cmg/common';
import {
  AlertTitle,
  Autocomplete,
  AutocompleteTextFieldProps,
  Chip,
  Typography,
} from '@cmg/design-system';
import { useFormikContext } from 'formik';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState, VFC } from 'react';

import { FormFieldLayout } from '../../../../../design-system/body-sections/form/FormFieldLayout';
import { FormLayout } from '../../../../../design-system/body-sections/form/FormLayout';
import { crmFileTypeLabels, crmFileTypeOptions } from '../../../../../types/domain/crm/constants';
import { Props } from './CRMFilesUploadSection';
import {
  CRMFilesUploadFormValues,
  crmFileTypeAcceptableColumnHeaders,
  crmFileTypeColumnNote,
  errorLabels,
  FileInputError,
  FileInputErrorType,
  FILENAME_REGEX,
  MAX_FILE_SIZE,
} from './CRMFilesUploadSection.model';

export const CRMFilesUploadForm: VFC<
  Pick<Props, 'importResult' | 'isUploading' | 'processedSoFar'>
> = ({ importResult, isUploading, processedSoFar }) => {
  const formik = useFormikContext<CRMFilesUploadFormValues>();
  const [fileError, setFileError] = useState<FileInputError | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const { resetForm } = formik;
  const crmFileTypeTextFieldProps: AutocompleteTextFieldProps = useMemo(
    () => ({
      label: 'CRM File Type',
      required: true,
      name: 'crmFileType',
      placeholder: 'e.g. Investor Firms',
      error: formik.touched.crmFileType && Boolean(formik.errors.crmFileType),
      helperText: formik.touched.crmFileType && formik.errors.crmFileType,
    }),
    [formik.errors.crmFileType, formik.touched.crmFileType]
  );

  useEffect(() => {
    if (!isUploading && !processedSoFar.moreToUpload && importResult.totalRows > 0) {
      setFileName(null);
      resetForm();
    }
  }, [resetForm, isUploading, processedSoFar.moreToUpload, importResult.totalRows]);

  const validateFileInput = useCallback((file: File) => {
    const fileName = file.name.toLowerCase();
    const isUnsupportedFilename = !FILENAME_REGEX.test(fileName);

    if (isUnsupportedFilename) {
      setFileError({
        fileName: file.name,
        errorType: FileInputErrorType.UNSUPPORTED_FILENAME,
      });
      return false;
    }

    // check for file size
    if (file.size > MAX_FILE_SIZE) {
      setFileError({
        fileName: file.name,
        errorType: FileInputErrorType.FILE_SIZE,
      });
      return false;
    }

    const isCsv = fileName.endsWith('.csv');
    if (!isCsv) {
      setFileError({
        fileName: file.name,
        errorType: FileInputErrorType.FILE_TYPE,
      });
      return false;
    }

    return true;
  }, []);

  const { setFieldValue } = formik;
  const changeHandler = useCallback(
    (file: File | null) => {
      if (!file) {
        return;
      }

      if (!validateFileInput(file)) {
        setFileName(file.name);
        return;
      }

      setFileError(null);
      setFieldValue('file', file);
      setFileName(file.name);
    },
    [validateFileInput, setFieldValue]
  );

  return (
    <FormLayout>
      {fileError && (
        <Alert severity="error" withMargin>
          <AlertTitle>Error - Upload File Again</AlertTitle>
          {errorLabels[fileError.errorType](fileError.fileName)}
        </Alert>
      )}
      <FormFieldLayout>
        <Autocomplete
          options={crmFileTypeOptions.map(x => x.value)}
          getOptionLabel={option => crmFileTypeLabels[option]}
          value={formik.values.crmFileType}
          onChange={(_e, value) => setFieldValue('crmFileType', value)}
          TextFieldProps={crmFileTypeTextFieldProps}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        {formik.values.crmFileType && (
          <Alert severity="info">
            <Typography variant="subtitle2" gutterBottom>
              Limited to .csv files of maximum 30MB.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Acceptable CSV Column Headers (case-insensitive):
            </Typography>
            <Typography variant="body1" gutterBottom>
              {crmFileTypeAcceptableColumnHeaders[formik.values.crmFileType]}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Please Note:
            </Typography>
            {crmFileTypeColumnNote[formik.values.crmFileType].map((note: string, i) => (
              <Typography key={formik.values.crmFileType + i} variant="body1" gutterBottom>
                {note}
              </Typography>
            ))}
          </Alert>
        )}
      </FormFieldLayout>
      {!isUploading && processedSoFar.total > 0 && importResult.totalRows > 0 && (
        <Chip
          label={`${processedSoFar.total} / ${importResult.totalRows} records processed`}
          color="default"
        />
      )}
      <FormFieldLayout>
        <input
          hidden
          type="file"
          data-testid="rolodex-crm-management-csv-file-input"
          accept="application/csv"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const file = e.target?.files?.length ? e.target.files[0] : null;
            changeHandler(file);
          }}
          ref={fileInputRef}
        ></input>
        <SecondaryButton
          onClick={() => {
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
              fileInputRef.current.click();
            }
          }}
        >
          Choose file...
        </SecondaryButton>
        {fileName && <Chip label={fileName} color="default" />}
      </FormFieldLayout>
      <FormFieldLayout>
        <PrimaryButton
          disabled={fileError !== null || !fileName || isUploading || processedSoFar.moreToUpload}
          loadingLabel="Uploading..."
          loading={isUploading || processedSoFar.moreToUpload}
          type="submit"
        >
          Upload
        </PrimaryButton>
      </FormFieldLayout>
      {(isUploading || processedSoFar.moreToUpload) && (
        <FormFieldLayout>
          <Progressbar
            height={15}
            progress={Math.round((processedSoFar.total / importResult.totalRows) * 100)}
            total={100}
          />
        </FormFieldLayout>
      )}
    </FormLayout>
  );
};
