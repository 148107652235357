export enum CRMFileTypes {
  INVESTORS = 'INVESTORS',
  COVERAGES = 'COVERAGES',
  CONTACTS = 'CONTACTS',
}

export const crmFileTypeLabels = {
  [CRMFileTypes.INVESTORS]: 'Investor Firms',
  [CRMFileTypes.COVERAGES]: 'Sales Coverage',
  [CRMFileTypes.CONTACTS]: 'Investor Contacts',
};

export const crmFileTypeLabelsLowerCase = {
  [CRMFileTypes.INVESTORS]: 'investors',
  [CRMFileTypes.COVERAGES]: 'coverages',
  [CRMFileTypes.CONTACTS]: 'contacts',
};

export const crmFileTypeOptions = [
  {
    value: CRMFileTypes.INVESTORS,
    label: crmFileTypeLabels[CRMFileTypes.INVESTORS],
  },
  {
    value: CRMFileTypes.COVERAGES,
    label: crmFileTypeLabels[CRMFileTypes.COVERAGES],
  },
  {
    value: CRMFileTypes.CONTACTS,
    label: crmFileTypeLabels[CRMFileTypes.CONTACTS],
  },
];
