import { timeUtil } from '@cmg/common';
import { HighlightsTextItem } from '@cmg/design-system';
import { FC, Fragment } from 'react';

import { User } from '../../../../../types/domain/user/user';

type Props = Readonly<{
  user: Readonly<User>;
}>;

export const UserHighlightsItems: FC<Props> = ({ user }) => {
  return (
    <Fragment>
      <HighlightsTextItem
        name="Created On"
        text={timeUtil.formatAsDisplayDateTime(user.createdOn) ?? ''}
      />
      <HighlightsTextItem
        name="Last Login"
        text={
          user.lastLoginTime
            ? timeUtil.formatAsDisplayDateTime(user.lastLoginTime) ?? ''
            : 'Unknown'
        }
      />
      <HighlightsTextItem name="Last IP Address" text={user.lastIpAddress} />
    </Fragment>
  );
};
