import { UserStatus } from '@capital-markets-gateway/api-client-identity';
import { apiTypes } from '@cmg/common';
import { FC, Fragment } from 'react';

import { User } from '../../../../../../types/domain/user/user';
import { ResendInviteAction } from './ResendInviteAction';
import { UnlockUserAction } from './UnlockUserAction';
import { UpdateStatusAction } from './UpdateStatusAction';

export type Props = Readonly<{
  onReset: () => void;
  isLoading: Readonly<boolean>;
  user: Readonly<User>;
  updateUserStatusError: Readonly<apiTypes.GenericServerError | null>;
  onUpdateUserStatus: (userStatus: UserStatus) => void;
  unlockUserError: Readonly<apiTypes.GenericServerError | null>;
  onUnlockUser: () => void;
  resendInviteError: Readonly<apiTypes.GenericServerError | null>;
  onResendInviteEmail: () => void;
}>;

export const UserHighlightsActions: FC<Props> = ({
  onReset,
  isLoading,
  user,
  updateUserStatusError,
  onUpdateUserStatus,
  unlockUserError,
  onUnlockUser,
  resendInviteError,
  onResendInviteEmail,
}) => {
  return (
    <Fragment>
      {!user.accountConfirmed && (
        <ResendInviteAction
          onReset={onReset}
          isLoading={isLoading}
          error={resendInviteError}
          onResendInviteEmail={onResendInviteEmail}
        />
      )}
      {user.isLockedOut && user.status === UserStatus.ACTIVE && (
        <UnlockUserAction
          onReset={onReset}
          isLoading={isLoading}
          error={unlockUserError}
          onUnlockUser={onUnlockUser}
        />
      )}
      <UpdateStatusAction
        onReset={onReset}
        userStatus={user.status}
        isLoading={isLoading}
        error={updateUserStatusError}
        onUpdateUserStatus={onUpdateUserStatus}
      />
    </Fragment>
  );
};
