import { AccessControl, permissionsByEntity } from '@cmg/auth';
import { apiTypes, Banner, Panel, PrimaryButton, ServerErrors, TextInputField } from '@cmg/common';
import { identitySelectors } from '@cmg/e2e-selectors';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import {
  InfoItem,
  InfoLabel,
  InfoValue,
} from '../../../../common/components/layout/info-box/InfoBoxes';
import { AccountUpdate } from '../../../../types/domain/account/account';

// exported for testing only
export const UpdateAccountSchema = yup.object().shape({
  cmgEntityKey: yup
    .string()
    .typeError('Cmg Entity Key must be a number of length 9')
    .label('Cmg Entity Key')
    .matches(/^[0-9]{9}$/, 'Cmg Entity Key must be a number of length 9'),
});

type OwnProps = {
  accountId: string;
  onSubmit: (values: AccountUpdate) => void;
  error: apiTypes.GenericServerError | null;
  isSubmitting: boolean;
};
type Props = OwnProps & FormikProps<AccountUpdate>;

export const RolodexMappingPanelComponent: React.FC<Props> = ({
  handleSubmit,
  error,
  isSubmitting,
}) => {
  return (
    <Panel testId={identitySelectors.accountDetail.accountRolodexScreen.testId}>
      <Panel.Header rightContent={[]} />
      {error && (
        <Banner variant="error" showIcon={false}>
          <ServerErrors error={error} />
        </Banner>
      )}
      <InfoItem>
        <InfoLabel>CMG Entity Key</InfoLabel>
        <InfoValue>
          <TextInputField name="cmgEntityKey" placeholder="e.g. 000131787" />
        </InfoValue>
      </InfoItem>
      <Banner variant="warning">
        Once populated, the CmgEntityKey for an account cannot be changed.
      </Banner>
      <AccessControl requiredPermissions={[permissionsByEntity.GlobalAccount.FULL]}>
        <PrimaryButton onClick={() => handleSubmit()} loading={isSubmitting}>
          Save
        </PrimaryButton>
      </AccessControl>
    </Panel>
  );
};

export default withFormik<OwnProps, AccountUpdate>({
  enableReinitialize: false,
  isInitialValid: false,
  validateOnChange: false,
  validateOnBlur: true,
  validationSchema: UpdateAccountSchema,
  mapPropsToValues: props => ({
    id: props.accountId,
    cmgEntityKey: null,
  }),
  handleSubmit: (values, formikBag) => formikBag.props.onSubmit(values),
})(RolodexMappingPanelComponent);
