import sortBy from 'lodash/sortBy';

export enum AccountType {
  SELL_SIDE = 'SELL_SIDE',
  BUY_SIDE = 'BUY_SIDE',
}

export const accountTypeLabels = {
  [AccountType.SELL_SIDE]: 'Sell Side',
  [AccountType.BUY_SIDE]: 'Buy Side',
};

export const accountTypeOptions = [
  {
    value: AccountType.BUY_SIDE,
    label: accountTypeLabels[AccountType.BUY_SIDE],
  },
  {
    value: AccountType.SELL_SIDE,
    label: accountTypeLabels[AccountType.SELL_SIDE],
  },
];

export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export const accountStatusLabels = {
  [AccountStatus.ACTIVE]: 'Active',
  [AccountStatus.DISABLED]: 'Disabled',
};

export const accountStatusOptions = [
  {
    value: AccountStatus.ACTIVE,
    label: accountStatusLabels[AccountStatus.ACTIVE],
  },
  {
    value: AccountStatus.DISABLED,
    label: accountStatusLabels[AccountStatus.DISABLED],
  },
];

export enum LicenseTypes {
  TRIAL = 'TRIAL',
  PAID = 'PAID',
}

export const licenseTypeLabels = {
  [LicenseTypes.TRIAL]: 'Trial',
  [LicenseTypes.PAID]: 'Paid',
};

export const licenseTypeOptions = [
  {
    value: LicenseTypes.TRIAL,
    label: licenseTypeLabels[LicenseTypes.TRIAL],
  },
  {
    value: LicenseTypes.PAID,
    label: licenseTypeLabels[LicenseTypes.PAID],
  },
];

export enum ExternalLicense {
  CUSIP = 'CUSIP',
}

export const externalLicenseLabels = {
  [ExternalLicense.CUSIP]: 'CUSIP',
};

export const externalLicenseOptions = sortBy(
  [
    {
      value: ExternalLicense.CUSIP,
      label: externalLicenseLabels[ExternalLicense.CUSIP],
    },
  ],
  ['value']
);
