import { DraftAccountRead as DraftAccount } from '@capital-markets-gateway/api-client-identity';
import { apiTypes, Banner, DataGrid, Icon, TextInput } from '@cmg/common';
import {
  draftAccountListAccountNameSearchInputSelector,
  draftAccountListAccountSubdomainSearchInputSelector,
  draftAccountListCmgEntityKeySearchInputSelector,
  draftAccountListInvestorNameSearchInputSelector,
  draftAccountListInviteBySearchInputSelector,
} from '@cmg/e2e-selectors';
import { CellValueChangedEvent } from 'ag-grid-community';
import React from 'react';

import { DraftAccountFilters } from '../../../../common/api/systemManagementApiClient';
import { SField, SFilters, SIconWrapper } from '../../account-list/components/AccountList';
import { createColumns } from './DraftAccountListGridColumns';

type Props = {
  loading: boolean;
  draftAccounts: DraftAccount[];
  pagination: apiTypes.Pagination;
  filters: DraftAccountFilters;
  error: boolean;
  investorSubdomainError: apiTypes.GenericServerError | null;
  onSubdomainChange: (draftAccountId: string, investorSubdomain: string | null) => void;
  onChangeFilters: (params: DraftAccountFilters, debounce?: boolean) => void;
  onChangePage: (params: apiTypes.ListParams) => void;
};

/**
 * Displays CMG Draft Accounts in a grid view
 */
const DraftAccountList: React.FC<Props> = ({
  draftAccounts,
  error,
  pagination,
  filters,
  loading,
  onChangePage,
  onChangeFilters,
  onSubdomainChange,
  investorSubdomainError,
}) => {
  const [cellChangeEvent, setCellChangeEvent] = React.useState<CellValueChangedEvent | null>(null);

  React.useEffect(() => {
    // if there is a server error while changing the investor subdomain,
    // use the value before edit and refresh the cell that was edited
    if (cellChangeEvent) {
      if (investorSubdomainError) {
        cellChangeEvent.data.investorSubdomain = cellChangeEvent?.oldValue;
      } else {
        cellChangeEvent.data.investorSubdomain = cellChangeEvent?.newValue.toLowerCase();
      }
      cellChangeEvent?.api.refreshCells({
        force: true,
        columns: ['investorSubdomain'],
        rowNodes: [cellChangeEvent?.node],
      });
    }
  }, [investorSubdomainError, cellChangeEvent]);

  const columns = createColumns({
    draftAccounts,
  });

  return (
    <React.Fragment>
      {error && <Banner variant="error">Could not load draft accounts...</Banner>}

      <DataGrid<DraftAccount>
        renderFilters={() => (
          <SFilters>
            <SField>
              <TextInput
                data-test-id={draftAccountListInvestorNameSearchInputSelector.testId}
                onChange={(investorName: string | null) =>
                  onChangeFilters({ ...filters, investorName }, true)
                }
                placeholder="Filter by Investor Name..."
                prefix={
                  <SIconWrapper>
                    <Icon name="search" />
                  </SIconWrapper>
                }
              />
            </SField>
            <SField>
              <TextInput
                data-test-id={draftAccountListAccountNameSearchInputSelector.testId}
                onChange={(accountName: string | null) =>
                  onChangeFilters({ ...filters, accountName }, true)
                }
                placeholder="Filter by Account Name..."
                prefix={
                  <SIconWrapper>
                    <Icon name="search" />
                  </SIconWrapper>
                }
              />
            </SField>
            <SField>
              <TextInput
                data-test-id={draftAccountListAccountSubdomainSearchInputSelector.testId}
                onChange={(accountSubdomain: string | null) =>
                  onChangeFilters({ ...filters, accountSubdomain }, true)
                }
                placeholder="Filter by Account Subdomain..."
                prefix={
                  <SIconWrapper>
                    <Icon name="search" />
                  </SIconWrapper>
                }
              />
            </SField>
            <SField>
              <TextInput
                data-test-id={draftAccountListCmgEntityKeySearchInputSelector.testId}
                onChange={(cmgEntityKey: string | null) =>
                  onChangeFilters({ ...filters, cmgEntityKey }, true)
                }
                placeholder="Filter by CMG Entity Key..."
                prefix={
                  <SIconWrapper>
                    <Icon name="search" />
                  </SIconWrapper>
                }
              />
            </SField>
            <SField>
              <TextInput
                data-test-id={draftAccountListInviteBySearchInputSelector.testId}
                onChange={(invitedBy: string | null) =>
                  onChangeFilters({ ...filters, invitedBy }, true)
                }
                placeholder="Filter on Invited By..."
                prefix={
                  <SIconWrapper>
                    <Icon name="search" />
                  </SIconWrapper>
                }
              />
            </SField>
          </SFilters>
        )}
        onCellValueChange={cellChangeEvnt => {
          onSubdomainChange(cellChangeEvnt.data.id, cellChangeEvnt.data.investorSubdomain);
          setCellChangeEvent(cellChangeEvnt);
        }}
        extended={{ withMargin: false }}
        columns={columns}
        rows={draftAccounts}
        resizeBy="grid"
        totalPages={pagination.totalPages}
        pagination={{
          page: pagination.activePage,
          perPage: pagination.perPage,
        }}
        onPaginationChange={({ page, perPage, orderField, orderDirection }) => {
          const params = {
            page,
            perPage,
            orderField,
            orderDirection:
              orderDirection === 'asc' ? apiTypes.SortDirection.ASC : apiTypes.SortDirection.DESC,
          };

          onChangePage(params);
        }}
        gridOptions={{
          suppressCellSelection: true,
          suppressRowClickSelection: true,
          overlayNoRowsTemplate: 'No draft accounts found',
          enableCellChangeFlash: true,
        }}
        loading={loading}
      />
    </React.Fragment>
  );
};

export default DraftAccountList;
