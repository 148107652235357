import { TableGroupHeader, TableGroupHeaderCell } from '@cmg/design-system';
import { FC } from 'react';

import { PermissionDetail, UndefinedPermissionDetail } from '../../../../../shared/auth/constants';
import { ToggleRowCells } from '../../shared/ToggleRowCells';

export type Props = Readonly<{
  readonly groupHeader: string;
  readonly availablePermissions: (PermissionDetail | UndefinedPermissionDetail)[];
  readonly onPermissionsAdd: (permissions: string[]) => void;
  readonly onPermissionsRemove: (permissions: string[]) => void;
}>;

export const ApiKeyPermissionsTableGroupHeader: FC<Props> = ({
  groupHeader,
  availablePermissions,
  onPermissionsAdd,
  onPermissionsRemove,
}) => {
  return (
    <TableGroupHeader>
      <TableGroupHeaderCell colSpan={2}>{groupHeader}</TableGroupHeaderCell>
      <ToggleRowCells
        isInGroupHeader={true}
        groupName={groupHeader}
        availablePermissions={availablePermissions}
        onAdd={onPermissionsAdd}
        onRemove={onPermissionsRemove}
      />
    </TableGroupHeader>
  );
};
