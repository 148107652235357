import { DomainNav } from '@cmg/design-system';
import { identitySelectors } from '@cmg/e2e-selectors';
import React from 'react';

import routeFactory from '../../../../common/util/routeFactory';
import { ReactRouterDomainNavTab } from '../../../../design-system/react-router/ReactRouterDomainNavTab';
import { useRoutesMatch } from '../../../../design-system/react-router/useRoutesMatch';
import { accountDetailRoutes } from './AccountDetailDomainNav.model';
import { usePermissionedAccountDetailRoutes } from './hooks/usePermissionedAccountDetailRoutes';

export type Props = {
  accountSubdomain: string;
};

export const AccountDetailDomainNav: React.VFC<Props> = ({ accountSubdomain }) => {
  const { matchedRouteName, routeNames } = useRoutesMatch(accountDetailRoutes);
  const { canAccessRoute } = usePermissionedAccountDetailRoutes();

  return (
    <DomainNav
      value={canAccessRoute(matchedRouteName) ? matchedRouteName : false}
      data-test-id={identitySelectors.accountNavBarSelector.testId}
    >
      {canAccessRoute('subscription') && (
        <ReactRouterDomainNavTab
          value={routeNames.subscription}
          data-test-id={identitySelectors.accountNavBarSubscriptionSelector.testId}
          label="Subscription"
          href={routeFactory.accountSubscription.getUrlPath({
            accountSubdomain,
          })}
        />
      )}
      {canAccessRoute('users') && (
        <ReactRouterDomainNavTab
          value={routeNames.users}
          data-test-id={identitySelectors.accountNavBarUsersSelector.testId}
          label="Users"
          href={routeFactory.accountUsers.getUrlPath({
            accountSubdomain,
          })}
        />
      )}

      {canAccessRoute('roles') && (
        <ReactRouterDomainNavTab
          value={routeNames.roles}
          label="Roles"
          exact
          data-test-id={identitySelectors.accountNavBarRolesSelector.testId}
          href={routeFactory.accountRoles.getUrlPath({
            accountSubdomain,
          })}
        />
      )}
      {canAccessRoute('security') && (
        <ReactRouterDomainNavTab
          value={routeNames.security}
          label="Security"
          exact
          data-test-id={identitySelectors.accountNavBarSecuritySelector.testId}
          href={routeFactory.accountSecurity.getUrlPath({
            accountSubdomain,
          })}
        />
      )}
      {canAccessRoute('apiKeys') && (
        <ReactRouterDomainNavTab
          value={routeNames.apiKeys}
          label="API Keys"
          exact
          data-test-id={identitySelectors.accountNavBarApiKeysSelector.testId}
          href={routeFactory.accountApiKeys.getUrlPath({
            accountSubdomain,
          })}
        />
      )}
      {canAccessRoute('rolodex') && (
        <ReactRouterDomainNavTab
          value={routeNames.rolodex}
          label="Rolodex"
          exact
          data-test-id={identitySelectors.accountNavBarRolodexSelector.testId}
          href={routeFactory.accountRolodex.getUrlPath({
            accountSubdomain,
          })}
        />
      )}
    </DomainNav>
  );
};
