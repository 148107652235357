import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { numericUtil } from '@cmg/common';
import { Button, DotStatus, Highlights, HighlightsTextItem } from '@cmg/design-system';
import { Fragment, useMemo, useState, VFC } from 'react';

import { Account } from '../../../../types/domain/account/account';
import {
  AccountStatus,
  accountTypeLabels,
  licenseTypeLabels,
} from '../../../../types/domain/account/constants';
import { getSsoStatusText } from './AccountDetailDomainHighlights.model';
import ChangeAccountStatusDialog from './ChangeAccountStatusDialog';

export type Props = {
  account: Readonly<Account>;
};

export const AccountDetailDomainHighlights: VFC<Props> = ({ account }) => {
  const [isAccountStatusDialogOpened, setAccountStatusDialogOpened] = useState(false);

  const canReadGlobalAccount = useCheckPermissions([permissionsByEntity.GlobalAccount.READ]);
  const canWriteGlobalAccount = useCheckPermissions([permissionsByEntity.GlobalAccount.FULL]);

  const isAccountActive = account.status === AccountStatus.ACTIVE;

  const licenseCount = useMemo(() => {
    return `${numericUtil.getDisplayValueForInteger(
      account.remainingLicenseCount
    )} of ${numericUtil.getDisplayValueForInteger(account.licenseCount)}`;
  }, [account.licenseCount, account.remainingLicenseCount]);

  return (
    <Highlights
      title={account.name}
      titleItems={
        <Fragment>
          {account.status && (
            <DotStatus
              label={isAccountActive ? 'Active' : 'Disabled'}
              color={isAccountActive ? 'success' : 'error'}
            />
          )}
          <DotStatus
            label={getSsoStatusText(account.ssoEnabled, account.requireTwoFactorAuth)}
            color={account.ssoEnabled || account.requireTwoFactorAuth ? 'success' : 'error'}
          />
        </Fragment>
      }
      actions={
        canWriteGlobalAccount && account.status ? (
          <Fragment>
            <Button
              variant="contained"
              color={isAccountActive ? 'error' : 'primary'}
              onClick={() => setAccountStatusDialogOpened(true)}
            >
              {isAccountActive ? 'Disable Account' : 'Enable Account'}
            </Button>
            {isAccountStatusDialogOpened && (
              <ChangeAccountStatusDialog
                isOpen
                accountId={account.id}
                accountStatus={account.status}
                onClose={() => setAccountStatusDialogOpened(false)}
              />
            )}
          </Fragment>
        ) : undefined
      }
      items={
        canReadGlobalAccount ? (
          <Fragment>
            <HighlightsTextItem name="Subdomain" text={account.subdomain} />
            <HighlightsTextItem
              name="Account Type"
              text={account.accountType ? accountTypeLabels[account.accountType] : ''}
            />
            <HighlightsTextItem name="License Type" text={licenseTypeLabels[account.licenseType]} />
            <HighlightsTextItem name="License Count" text={licenseCount} />
          </Fragment>
        ) : undefined
      }
    />
  );
};
