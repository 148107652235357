import { InvestorFirmAdminRead as CrmInvestorFirm } from '@capital-markets-gateway/api-client-rolodex';

import { FirmRoleType } from '../../../../../types/domain/firm/constants';
import { Firm } from '../../../../../types/domain/firm/Firm';
import { FirmRole } from '../../../../../types/domain/firm/FirmRole';
import { TransformedFirm } from '../../../../../types/domain/firm/TransformedFirm';
import { FirmMatchName } from '../../../../../types/domain/firm-match/FirmMatchName';

/**
 * Given two names/strings, returns whether or not the values
 * are equal - excluding special characters and casing.
 */
export const areNamesEqual = (nameA: string, nameB: string): boolean => {
  const nonAlphaNumericRegex = /[^a-zA-Z0-9 ]/g;
  const nA = nameA.toLowerCase().replace(nonAlphaNumericRegex, '');
  const nB = nameB.toLowerCase().replace(nonAlphaNumericRegex, '');
  return nA === nB;
};

/**
 * Given a firm, returns an array of FirmName Entities or an empty array.
 */
const getFirmNames = (firm: Firm | TransformedFirm): FirmMatchName[] => {
  return firm.names || [];
};

/**
 * Given a Firm Entity, returns the display name - or the firm ID if
 * a display name does not exist.
 *
 * @firmMatch A Firm Match entity.
 */
export const getDisplayName = (firm: Firm | TransformedFirm): string => {
  const firmNames = getFirmNames(firm);
  const displayName = firmNames.find(name => name.isDisplayName);

  if (displayName && displayName.value) {
    return displayName.value;
  }

  return (firm as Firm).id || 'Unknown';
};

/**
 * Given a CRM Investor Firm and a Firm Entity, returns whether
 * or not the CRM Investor Firm's name exists in the Firm.
 *
 * @crmInvestorFirm A CRM Investor Firm Entity
 * @firmMatch A Firm Match Entity
 */
export const doesInvestorFirmNameExistInLinkTargetFirm = (
  crmInvestorFirm: CrmInvestorFirm,
  firmMatch: Firm | TransformedFirm
): boolean =>
  getFirmNames(firmMatch).some(
    name => name.value && areNamesEqual(name.value, crmInvestorFirm.name)
  );

const getLinkTargetFirmRoles = (firmMatch: Firm | TransformedFirm): FirmRole[] =>
  firmMatch && (firmMatch as Firm).roles ? (firmMatch as Firm).roles! : [];

/**
 * Given a Firm Entity, returns whether or not the firm has
 * an Investor role.
 *
 * @firmMatch A Firm Match Entity
 */
export const doesFirmHaveInvestorRole = (firm: Firm | TransformedFirm): boolean =>
  getLinkTargetFirmRoles(firm).some(role => role.type === FirmRoleType.INVESTOR);
