import { IconButton } from '@cmg/common';
import styled, { CSSProperties } from 'styled-components/macro';

export const STableRow = styled.tr<{ withHighlight: boolean; withPointer: boolean }>`
  cursor: ${({ withPointer }) => (withPointer ? `pointer` : 'inherit')};
  background-color: ${({ theme, withHighlight }) =>
    withHighlight ? `${theme.color.blue6} !important` : 'unset'};
`;

export const STableCell = styled.td<{ width: CSSProperties['width'] }>`
  width: ${({ width }) => width};

  &&& {
    vertical-align: middle;
  }
`;

export const STableCellContent = styled.div`
  display: flex;
  align-items: center;
  min-height: 34px;
`;

export const SButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 70px;
`;

export const StyledRecordSubmitIconButton = styled(IconButton)`
  margin: 0 5px;
  color: ${({ theme }) => theme.text.color.positive};

  &:hover,
  &:focus {
    box-shadow: none;
    color: ${({ theme }) => theme.text.color.positive};
  }
`;

export const StyledRecordCancelEditIconButton = styled(IconButton)`
  margin: 0 5px;
  color: ${({ theme }) => theme.text.color.negative};

  &:hover,
  &:focus {
    box-shadow: none;
    color: ${({ theme }) => theme.text.color.negative};
  }
`;
