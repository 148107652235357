import { apiTypes, CenteredPage, urlUtil } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import routeFactory from '../../common/util/routeFactory';
import {
  CreateUserSignupDetailsDto,
  selectEmailParts,
  selectInvestorSignupParts,
  selectTokenParts,
  submitInvestorSignupForm,
  validateInvestorSignupEmail,
  validateInvestorSignupToken,
} from './ducks';
import InvestorSignupForm from './form/InvestorSignupForm';
import {
  CMGBarImage,
  SDiv,
  SFooter,
  SLeft,
  SLinkButton,
  SRight,
  SSectionSubtitle,
  SSectionSubtitleDescription,
  SSectionTitle,
  SSectionTitleDescription,
} from './InvestorSignupRoute.styles';

const mapStateToProps = (state, ownProps: RouteComponentProps) => ({
  loading: selectInvestorSignupParts(state).loading,
  investorSignupStatus: selectInvestorSignupParts(state).status,
  isTokenValid: selectTokenParts(state).data?.isValid,
  isEmailValid: selectEmailParts(state).data?.isMatchFound,
  token: urlUtil.queryParse<{ token: string }>(ownProps.location.search).token,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      validateInvestorSignupEmail,
      validateInvestorSignupToken,
      submitInvestorSignupForm,
    },
    dispatch
  ),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
export type Props = RouteComponentProps & DispatchProps & StateProps;

export const InvestorSignupRouteComponent: React.FC<Props> = ({
  actions,
  isEmailValid,
  token,
  isTokenValid,
  investorSignupStatus,
}) => {
  const [investorSignupEmail, setInvestorSignupEmail] = React.useState<string>('');
  const history = useHistory();

  React.useEffect(() => {
    actions.validateInvestorSignupToken({
      token: token,
    });
    if (isTokenValid === false) {
      history.push(
        routeFactory.error.getUrlPath({ errorCode: apiTypes.ServiceErrorCode.INVALID_TOKEN })
      );
    }
  }, [actions, history, isTokenValid, token]);

  const validateEmail = () => {
    investorSignupEmail &&
      actions.validateInvestorSignupEmail({
        token: token,
        emailAddress: investorSignupEmail,
      });
  };

  const handleSubmit = (values: CreateUserSignupDetailsDto) => {
    actions.submitInvestorSignupForm({ values: values });
  };

  if (investorSignupStatus === 'SUCCEEDED') {
    SnackbarManager.success('Successfully submitted form');
    return <Redirect to={routeFactory.investorSignupSuccess.getUrlPath()} />;
  } else if (investorSignupStatus === 'FAILED') {
    return (
      <Redirect
        to={routeFactory.error.getUrlPath({
          errorCode: apiTypes.ServiceErrorCode.VALIDATION_ERROR,
        })}
      />
    );
  }

  return (
    <SDiv>
      <SLeft>
        <CenteredPage title="Register" hideFooter hideBackground>
          <InvestorSignupForm
            isEmailValid={isEmailValid}
            validateEmail={validateEmail}
            onEmailChange={setInvestorSignupEmail}
            handleSubmit={handleSubmit}
            token={token}
          />
          <SFooter>
            Contact CMG <SLinkButton href="mailto:support@cmgx.io">here</SLinkButton>
          </SFooter>
        </CenteredPage>
      </SLeft>
      <SRight>
        <SSectionTitle>CMG, the digital network for capital</SSectionTitle>
        <SSectionTitleDescription>
          CMG is modernizing the capital formation process by connecting buy- and sell-side market
          participants
        </SSectionTitleDescription>
        <CMGBarImage />
        <SSectionSubtitle>Market Intelligence</SSectionSubtitle>
        <SSectionSubtitleDescription>
          Monitor real-time ECM deal launches supporting IPO, Secondary, and Block offerings via the
          XC calendar, powered by the premier source of structured deal data.
        </SSectionSubtitleDescription>
        <SSectionSubtitle>Enhanced Collaboration</SSectionSubtitle>
        <SSectionSubtitleDescription>
          Standardized ECM workflows allow institutional investors to optimize the investment
          decision process while increasing transparency and minimizing risk.
        </SSectionSubtitleDescription>
        <SSectionSubtitle>Streamlined Communication</SSectionSubtitle>
        <SSectionSubtitleDescription>
          CMG improves a fragmented and inefficient process by enabling investors and underwriters
          to communicate directly on offering terms, meeting requests, indications and allocations
          via a purpose-built ECM platform.
        </SSectionSubtitleDescription>
      </SRight>
    </SDiv>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorSignupRouteComponent);
