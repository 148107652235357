import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  LoginUserPassPasswordExpiredError,
  LoginUserPassResponseCode,
} from '../../../../../common/api/identityApiClient';
import {
  loginUserPass,
  mfaLoginUserPass,
  resendCode,
  resetPassword,
  selectError,
  selectMfaEnabled,
  selectMfaError,
  selectMfaSubmitting,
  selectResetPasswordError,
  selectResetPasswordSubmitting,
  selectResetPasswordSuccess,
  selectSubmitting,
} from '../../ducks';
import ExpiredPasswordScreen from './expiredPasswordScreen/ExpiredPasswordScreen';
import LoginScreen from './loginScreen/LoginScreen';
import MFAScreen from './mfaScreen/MFAScreen';

const mapStateToProps = state => ({
  loginError: selectError(state),
  submitting: selectSubmitting(state),
  resetPasswordError: selectResetPasswordError(state),
  resetPasswordSubmitting: selectResetPasswordSubmitting(state),
  resetPasswordSuccess: selectResetPasswordSuccess(state),
  mfaEnabled: selectMfaEnabled(state),
  mfaError: selectMfaError(state),
  mfaSubmitting: selectMfaSubmitting(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loginUserPass,
      resetPassword,
      mfaLoginUserPass,
      resendCode,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  returnUrl?: string;
};
type Props = OwnProps & StateProps & DispatchProps;
type State = {
  username: string;
  password: string;
};

/**
 * LocalLogin will display as a login view to the user whenever
 * their account is configured with local login being enabled.
 */
export class LocalLoginComponent extends React.Component<Props, State> {
  handleLoginUserPass = ({ username, password }) => {
    const { actions, returnUrl } = this.props;
    // check is because query-string lib correctly flags that this value may not exist or may be a string array
    if (typeof returnUrl === 'string') {
      actions.loginUserPass({ username, password, returnUrl });
    }
  };

  handleMfaLoginPass = ({ securityCode, rememberDevice }) => {
    // check is because query-string lib correctly flags that this value may not exist or may be a string array
    const { actions, returnUrl } = this.props;
    // check is because query-string lib correctly flags that this value may not exist or may be a string array
    if (typeof returnUrl === 'string') {
      actions.mfaLoginUserPass({ code: securityCode, rememberDevice, returnUrl });
    }
  };

  handleResendCode = () => {
    // check is because query-string lib correctly flags that this value may not exist or may be a string array
    const { actions } = this.props;
    actions.resendCode();
  };

  render() {
    const {
      loginError,
      submitting,
      resetPasswordError,
      resetPasswordSubmitting,
      resetPasswordSuccess,
      mfaEnabled,
      mfaError,
      mfaSubmitting,
      actions,
    } = this.props;

    if (loginError && loginError.code === LoginUserPassResponseCode.PASSWORD_EXPIRED) {
      const passwordExpiredError = loginError as LoginUserPassPasswordExpiredError;

      return (
        <ExpiredPasswordScreen
          onSubmitResetPasswordForm={actions.resetPassword}
          submitting={resetPasswordSubmitting}
          error={resetPasswordError}
          success={resetPasswordSuccess}
          token={passwordExpiredError.token}
          userId={passwordExpiredError.userId}
        />
      );
    }

    if (mfaEnabled) {
      return (
        <MFAScreen
          mfaError={mfaError}
          onSubmitMFAForm={this.handleMfaLoginPass}
          submitting={mfaSubmitting}
          resendCode={this.handleResendCode}
        />
      );
    }

    return (
      <LoginScreen
        onSubmitDBLoginForm={this.handleLoginUserPass}
        loginError={loginError}
        submitting={submitting}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalLoginComponent);
