import { DialogContentText } from '@cmg/design-system';
import React, { useCallback } from 'react';

import { ConfirmationDialog } from '../../../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { FormDialogContent } from '../../../../../../design-system/dialog/FormDialogContent';
import { FormDialogContentItem } from '../../../../../../design-system/dialog/FormDialogContentItem';
import { useConfigurationConfirmDialog } from './hooks/useConfigurationConfirmDialog';

export type Props = Readonly<{
  readonly onConfirm: () => void;
  readonly onHide: () => void;
  readonly showActivateSsoDialog: boolean;
  readonly showUpdatingSsoDialog: boolean;
  readonly showActivateLocalLoginDialog: boolean;
}>;

export const ConfigurationConfirmDialog: React.FC<Props> = ({
  showActivateSsoDialog,
  showUpdatingSsoDialog,
  showActivateLocalLoginDialog,
  onConfirm,
  onHide,
}) => {
  const handleSubmit = useCallback(() => {
    onConfirm();
    onHide();
  }, [onConfirm, onHide]);

  const { isOpen, title, contentText } = useConfigurationConfirmDialog({
    showActivateSsoDialog,
    showUpdatingSsoDialog,
    showActivateLocalLoginDialog,
  });

  return (
    <React.Fragment>
      <ConfirmationDialog
        isOpen={isOpen}
        title={title}
        cancelButtonLabel="No"
        onCancel={onHide}
        submitButtonLabel="Yes"
        submitButtonColor="primary"
        onSubmit={handleSubmit}
        content={
          <FormDialogContent
            items={
              <FormDialogContentItem value={<DialogContentText>{contentText}</DialogContentText>} />
            }
          />
        }
      />
    </React.Fragment>
  );
};
