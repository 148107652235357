import { getAccessToken, getUserProfileLogContext } from '@cmg/auth';
import { apiTypes, loggerUtil } from '@cmg/common';
import { AxiosError, AxiosResponse } from 'axios';

import routeFactory from '../util/routeFactory';

/**
 * Add as a request interceptor to an axios client to have the user's access token
 * automatically appended as the 'Authorization' header.
 */
export const accessTokenRequestInterceptor = config => {
  const accessToken = getAccessToken();

  if (accessToken) {
    return {
      ...config,
      headers: { ...(config.headers ?? {}), Authorization: `Bearer ${accessToken}` },
    };
  }

  return config;
};

type InterceptorError = {
  response: AxiosResponse<apiTypes.GenericResponseDataFailure>;
} & AxiosError<apiTypes.GenericResponseDataFailure>;

/**
 * A redirect on 403's with errorCode handling for blocked IP
 * mirrors ECM SPA's error interceptor
 */
export const responseErrorInterceptor = (error: InterceptorError) => {
  // Capture unexpected errors in our monitoring systems
  if (!error.response) {
    // An Axios internal error
    loggerUtil.error(error, getUserProfileLogContext());
  } else if (error.response) {
    const { status } = error.response;
    // 409 - Multiple users may have edited the same entity
    // 404 - Not found
    // 422 - Should be handled by sagas
    const ignoredStatusCodes = [404, 409, 422];
    if (!ignoredStatusCodes.includes(status)) {
      loggerUtil.error(error, getUserProfileLogContext());
    }
  }

  if (
    error.response &&
    error.response.status === 403 &&
    (((error.response || {}).data || {}).error || {}).code === apiTypes.ServiceErrorCode.IP_BLOCKED
  ) {
    window.location.href = routeFactory.error.getUrlPath({
      errorCode: apiTypes.ServiceErrorCode.IP_BLOCKED,
    });
  }

  return Promise.reject(error);
};
