import { Autocomplete, AutocompleteTextFieldProps, Grid, Stack } from '@cmg/design-system';
import { useEffect, useMemo, useState } from 'react';

import usePrevious from '../../../../../../common/util/usePrevious';
import { ConfirmationDialog } from '../../../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { AccountPermission } from '../../../../../../types/domain/account/account';
import { Role } from '../../../../../../types/domain/role/role';
import { PermissionsTable } from '../../shared/PermissionsTable';
import { PermissionsTableGroupHeader } from '../../shared/PermissionsTableGroupHeader';
import { PermissionsTableHeader } from '../../shared/PermissionsTableHeader';
import { PermissionsTableRow } from '../../shared/PermissionsTableRow';

export type Props = Readonly<{
  readonly accountPermissions: AccountPermission[];
  readonly roles: Role[];
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onApplyPermissions: (permissions: string[]) => void;
}>;

export const RoleBasedPermissionsDialog: React.FC<Props> = ({
  roles,
  accountPermissions,
  isOpen,
  onClose,
  onApplyPermissions,
}) => {
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);

  const selectedPermissions = useMemo(() => {
    return selectedRoles.reduce<string[]>((currentResult, nextRole) => {
      return [...currentResult, ...nextRole.permissions];
    }, []);
  }, [selectedRoles]);

  const previousIsOpen = usePrevious(isOpen);
  useEffect(() => {
    if (!isOpen && previousIsOpen) {
      setSelectedRoles([]);
    }
  }, [isOpen, previousIsOpen]);

  const selectRoleTextFieldProps: AutocompleteTextFieldProps = useMemo(
    () => ({ label: 'Select Role' }),
    []
  );

  return (
    <ConfirmationDialog
      fullWidth={true}
      maxWidth="md"
      isOpen={isOpen}
      title="Select Role-Based Permissions"
      cancelButtonLabel="Cancel"
      onCancel={onClose}
      submitButtonLabel="Add Selected Permissions"
      submitButtonColor="primary"
      onSubmit={() => onApplyPermissions(selectedPermissions)}
      content={
        <Stack>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                limitTags={2}
                options={roles}
                value={selectedRoles}
                getOptionLabel={option => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_e, value) => setSelectedRoles(value)}
                TextFieldProps={selectRoleTextFieldProps}
              />
            </Grid>
          </Grid>
          <PermissionsTable
            accountPermissions={accountPermissions}
            selectedPermissions={selectedPermissions}
            renderTableHeader={() => <PermissionsTableHeader />}
            renderGroupHeader={groupHeader => (
              <PermissionsTableGroupHeader groupHeader={groupHeader} />
            )}
            renderRow={props => <PermissionsTableRow {...props} isInEditMode={false} />}
          />
        </Stack>
      }
    />
  );
};
