import { Box, CircularProgress, Typography } from '@cmg/design-system';

import { ServerErrorAlert } from '../../../../design-system/alert/ServerErrorAlert';
import { DescriptionList } from '../../../../design-system/body-sections/description-list/DescriptionList';
import { DescriptionListItem } from '../../../../design-system/body-sections/description-list/DescriptionListItem';
import { RemapConfig } from './AccountRemappingModal.model';

type Props = Readonly<{
  accountConfigs: (RemapConfig | null)[];
  error?: string | null;
  isLoading: boolean;
}>;
export const EntityKeyAssignments: React.FC<Props> = ({ accountConfigs, error, isLoading }) => {
  return (
    <div>
      {error && <ServerErrorAlert title="" error={error} />}
      <Typography
        variant="h4"
        marginTop={theme => theme.spacing(1)}
        marginBottom={theme => theme.spacing(1)}
      >
        New entity key assignment
      </Typography>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={theme => theme.spacing(12)}
        >
          <CircularProgress />
        </Box>
      ) : (
        accountConfigs.map(config => (
          <DescriptionList key={config?.currentConfig.subdomain}>
            <DescriptionListItem label="Subdomain" value={config?.newConfig?.subdomain || '-'} />
            <DescriptionListItem
              label="CMG Entity Key"
              value={config?.newConfig.cmgEntityKey || '-'}
            />
            <DescriptionListItem
              label="CMG Entity Name"
              value={config?.newConfig.cmgEntityName || '-'}
            />
          </DescriptionList>
        ))
      )}
    </div>
  );
};
