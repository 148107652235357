import { Grid, GridProps } from '@cmg/design-system';

export type TableFilterProps = Pick<GridProps, 'xs' | 'sm' | 'md' | 'lg'>;

export const TableFilter: React.FC<TableFilterProps> = ({
  xs = 12,
  sm = 6,
  md = 4,
  lg = 2,
  children,
}) => {
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      {children}
    </Grid>
  );
};
