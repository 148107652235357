import {
  ArrowBackIcon,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@cmg/design-system';
import { FieldArray, FormikProvider } from 'formik';
import React from 'react';

import { ServerErrorAlert } from '../../../../design-system/alert/ServerErrorAlert';
import { useRemapAccountFlow } from '../hooks/useRemapAccountFlow';
import { CurrentAccountRemapSection } from './CurrentAccountRemapSection';
import { EntityKeyAssignments } from './EntityKeyAssignments';

export type Props = Readonly<{
  onClose: () => void;
  currentAccountSubdomain: string;
}>;

enum AccountRemappingStep {
  REMAP_SELECTION = 'REMAP_SELECTION',
  CONFIRM_CHANGES = 'CONFIRM_CHANGES',
}

export const AccountRemappingModal: React.FC<Props> = ({ onClose, currentAccountSubdomain }) => {
  const [section, setSection] = React.useState(AccountRemappingStep.REMAP_SELECTION);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmitForm = React.useCallback(() => {
    setIsSubmitting(false);
    if (section === AccountRemappingStep.REMAP_SELECTION) {
      setSection(AccountRemappingStep.CONFIRM_CHANGES);
    } else {
      onClose();
    }
  }, [section, setSection, onClose]);

  const {
    formik,
    error,
    onEntityKeyChange,
    onRemapAccountConfigs,
    remapRequestError,
    isLoadingRemapRequest,
    clearError,
  } = useRemapAccountFlow({
    currentAccountSubdomain,
    onSubmit: handleSubmitForm,
  });
  const { values } = formik;
  const goBack = React.useCallback(() => {
    clearError();
    setSection(AccountRemappingStep.REMAP_SELECTION);
  }, [setSection, clearError]);

  const confirmButtonCaption =
    section === AccountRemappingStep.REMAP_SELECTION ? 'Preview Changes' : 'Confirm Changes';
  const isRemapSelectionFlow = section === AccountRemappingStep.REMAP_SELECTION;
  const isConfirmChangesFlow = section === AccountRemappingStep.CONFIRM_CHANGES;

  return (
    <FormikProvider value={formik}>
      <Dialog
        open
        onClose={onClose}
        scroll="paper"
        maxWidth="xs"
        aria-describedby="change-entity-key-dialog-title"
      >
        <DialogTitle id="change-entity-key-dialog-title">
          <Typography variant="h1" margin={theme => theme.spacing(0.5, 0.5, 0, 0.5)}>
            Change Entity Key
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Box margin={theme => theme.spacing(0, 0.5)}>
            {isRemapSelectionFlow && (
              <FieldArray name="accountConfigs">
                {() => (
                  <React.Fragment>
                    {error && isSubmitting && <ServerErrorAlert title="" error={error} />}
                    {values.accountConfigs.map((accountConfig, index) => (
                      <React.Fragment key={accountConfig?.currentConfig.subdomain ?? 'new'}>
                        {currentAccountSubdomain === accountConfig?.currentConfig.subdomain ? (
                          <CurrentAccountRemapSection
                            index={index}
                            baseFieldName={`accountConfigs.${index}`}
                            accountConfig={accountConfig}
                            onChangeEntityKey={onEntityKeyChange}
                          />
                        ) : null}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                )}
              </FieldArray>
            )}
            {isConfirmChangesFlow && (
              <EntityKeyAssignments
                accountConfigs={values.accountConfigs}
                error={remapRequestError}
                isLoading={isLoadingRemapRequest}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            justifyContent={isRemapSelectionFlow ? 'flex-end' : 'space-between'}
            gap={1}
            width="100%"
          >
            {isConfirmChangesFlow && (
              <Button variant="outlined" onClick={goBack} name="Back">
                <ArrowBackIcon />
                Back
              </Button>
            )}
            <Box display="flex" gap={1}>
              <Button variant="outlined" onClick={onClose} name="Close">
                Close
              </Button>
              <Button
                variant="contained"
                aria-label="confirm"
                onClick={() => {
                  setIsSubmitting(true);
                  if (isConfirmChangesFlow) {
                    void onRemapAccountConfigs();
                  } else {
                    formik.handleSubmit();
                  }
                }}
              >
                {confirmButtonCaption}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
};
