import { apiTypes, Banner, PrimaryButton, SecondaryButton, ServerErrors } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';

import DetailHeader from '../../../../../common/components/layout/detail-header/DetailHeader';
import routeFactory from '../../../../../common/util/routeFactory';
import {
  SDetailHeaderButtonWrapper,
  StyledDetailHeaderColumn,
  StyledDetailHeaderTitle,
  StyledDetailHeaderTitleWrapper,
} from './FirmNewHeader.styles';

type Props = {
  isCreateButtonEnabled: boolean;
  isLoading: boolean;
  error: apiTypes.GenericServerError | null;
  onCreate: () => void;
};

export const FirmNewHeader: React.FC<Props> = ({
  isCreateButtonEnabled,
  isLoading,
  error,
  onCreate,
}) => {
  return (
    <DetailHeader.Header>
      {error && (
        <Banner variant="error" showIcon={false}>
          <ServerErrors error={error} />
        </Banner>
      )}
      <StyledDetailHeaderTitleWrapper>
        <StyledDetailHeaderTitle>New Organization</StyledDetailHeaderTitle>
        <StyledDetailHeaderColumn>
          <SDetailHeaderButtonWrapper>
            <SecondaryButton
              disabled={isLoading}
              asComponent={Link}
              to={routeFactory.rolodexFirms.routePath}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton disabled={!isCreateButtonEnabled} loading={isLoading} onClick={onCreate}>
              Create
            </PrimaryButton>
          </SDetailHeaderButtonWrapper>
        </StyledDetailHeaderColumn>
      </StyledDetailHeaderTitleWrapper>
    </DetailHeader.Header>
  );
};

export default FirmNewHeader;
