import { Icon, PrimaryButton, StackedInputGroup, TextInputField } from '@cmg/common';
import {
  loginFormSelector,
  loginPasswordSelector,
  loginSubmitSelector,
  loginUsernameSelector,
} from '@cmg/e2e-selectors';
import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import routeFactory from '../../../../../../common/util/routeFactory';
import { SForgotPassLinkWrapper, STopSection } from './DBLoginForm.styles';

export const DBLoginSchema = yup.object().shape({
  username: yup
    .string()
    .email('Username must be a valid email address')
    .required('Username is required'),
  password: yup.string().required('Password is required'),
});

type DBLoginFormValues = {
  username: string;
  password: string;
};

type OwnProps = { onSubmitDBLoginForm: (values: DBLoginFormValues) => void; submitting: boolean };
type State = { username: string; password: string };
type Props = OwnProps & FormikProps<DBLoginFormValues>;

/**
 * Render login form for username and password (database login)
 */
export class DBLoginFormComponent extends React.Component<Props, State> {
  render() {
    const { submitting } = this.props;

    return (
      <Form data-test-id={loginFormSelector.testId}>
        <STopSection>
          <StackedInputGroup>
            <TextInputField
              name="username"
              prefix={<Icon name="envelope" />}
              data-test-id={loginUsernameSelector.testId}
              placeholder="Email"
              size="large"
              autoFocus
              fullWidth
            />
            <TextInputField
              name="password"
              prefix={<Icon name="lock-alt" />}
              data-test-id={loginPasswordSelector.testId}
              type="password"
              placeholder="Password"
              size="large"
              fullWidth
            />
          </StackedInputGroup>
          <SForgotPassLinkWrapper>
            <Link to={routeFactory.forgotPassword.getUrlPath()}>Forgot Password?</Link>
          </SForgotPassLinkWrapper>
        </STopSection>

        <PrimaryButton
          variant="high-contrast"
          type="submit"
          size="large"
          fullWidth
          loading={submitting}
          testId={loginSubmitSelector.testId}
        >
          Log in
        </PrimaryButton>
      </Form>
    );
  }
}

/**
 * Form that captures username and password for a database style oidc login.
 */
export default withFormik<OwnProps, DBLoginFormValues>({
  enableReinitialize: false,
  isInitialValid: false,
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: DBLoginSchema,
  mapPropsToValues: () => ({
    username: '',
    password: '',
  }),
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmitDBLoginForm(values);
  },
})(DBLoginFormComponent);
