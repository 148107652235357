import { apiTypes, reduxUtil } from '@cmg/common';
import { AnyAction, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import identityApiClient, { ResetPasswordResponse } from '../../../common/api/identityApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { PasswordReset } from '../../../types/domain/self/PasswordReset';

const createReducer = reduxUtil.createReducer;

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  RESET_PASSWORD_REQUEST = 'identity/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'identity/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'identity/RESET_PASSWORD_FAILURE',
  RESET_STATE = 'identity/RESET_RESET_PASSWORD_STATE',
}

/**
 * ACTION CREATORS
 */
export const resetState = () => ({
  type: ActionTypes.RESET_STATE,
});

export const resetPassword = (params: PasswordReset) => ({
  type: ActionTypes.RESET_PASSWORD_REQUEST,
  payload: params,
});

export const resetPasswordSuccess = () => ({
  type: ActionTypes.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = (error: apiTypes.GenericServerError) => ({
  type: ActionTypes.RESET_PASSWORD_FAILURE,
  payload: {
    error,
  },
});

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.RESET_STATE]: ReturnType<typeof resetState>;
  [ActionTypes.RESET_PASSWORD_REQUEST]: ReturnType<typeof resetPassword>;
  [ActionTypes.RESET_PASSWORD_SUCCESS]: ReturnType<typeof resetPasswordSuccess>;
  [ActionTypes.RESET_PASSWORD_FAILURE]: ReturnType<typeof resetPasswordFailure>;
};

/**
 * REDUCERS
 */
export type ReducerState = {
  error: apiTypes.GenericServerError | null; // what error including password validations
  submitting: boolean; // is it submitting?
  success: boolean; // did the forgot password submision succeed?
};

export const initialState = {
  error: null,
  success: false,
  submitting: false,
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.RESET_PASSWORD_REQUEST]: () => null,
  [ActionTypes.RESET_PASSWORD_SUCCESS]: () => null,
  [ActionTypes.RESET_PASSWORD_FAILURE]: (curState, { payload }) => payload.error,
});

const submittingReducer = createReducer<ReducerState['submitting'], Actions>(
  initialState.submitting,
  {
    [ActionTypes.RESET_PASSWORD_REQUEST]: () => true,
    [ActionTypes.RESET_PASSWORD_SUCCESS]: () => false,
    [ActionTypes.RESET_PASSWORD_FAILURE]: () => false,
  }
);

const successReducer = createReducer<ReducerState['success'], Actions>(initialState.success, {
  [ActionTypes.RESET_PASSWORD_REQUEST]: () => false,
  [ActionTypes.RESET_PASSWORD_SUCCESS]: () => true,
  [ActionTypes.RESET_PASSWORD_FAILURE]: () => false,
});

// This reducer acts on the entire state of this duck. Has access to duck state and must return duck state.
const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_STATE]: () => ({ ...initialState }),
});

const combinedReducers = combineReducers<ReducerState>({
  error: errorReducer,
  submitting: submittingReducer,
  success: successReducer,
});

export default createDucksReducer(combinedReducers);

// Combines our individual slice reducers and the cross slice reducer.
export function createDucksReducer(combinedReducers) {
  return (state: ReducerState = initialState, action: AnyAction) =>
    crossSliceReducer(combinedReducers(state, action), action);
}
/**
 * SELECTORS
 */
const selectState = (state: RootState) => state.resetPassword;
export const selectError = state => selectState(state).error;
export const selectSubmitting = state => selectState(state).submitting;
export const selectSuccess = state => selectState(state).success;

/**
 * SAGAS
 */
export function* resetPasswordSubmitSaga({
  payload,
}: Actions[ActionTypes.RESET_PASSWORD_REQUEST]): SagaIterator {
  const response: ResetPasswordResponse = yield call(identityApiClient.resetPassword, payload);
  if (response.ok) {
    yield put(resetPasswordSuccess());
  } else {
    yield put(resetPasswordFailure(response.data.error));
  }
}

export function* resetPasswordSaga() {
  yield takeLatest<Actions[ActionTypes.RESET_PASSWORD_REQUEST]>(
    ActionTypes.RESET_PASSWORD_REQUEST,
    resetPasswordSubmitSaga
  );
}
