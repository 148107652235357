import styled from 'styled-components/macro';

export const STopSection = styled.div`
  margin: 32px 0 22px;
`;

export const SForgotPassLinkWrapper = styled.div`
  font-size: ${({ theme }) => theme.text.size.medium};
  margin: 6px 0;
  text-align: right;
  > a {
    color: ${({ theme }) => theme.text.color.white};
  }
`;
