import { errorUtil, loggerUtil } from '@cmg/common';
import React from 'react';

import systemManagementApiClient from '../../../../common/api/systemManagementApiClient';
import { RemapConfig } from '../components/AccountRemappingModal.model';

export const useRemapAccountsRequest = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const onRemapAccounts = React.useCallback(
    async (accountConfigs: RemapConfig[]) => {
      setIsLoading(true);
      setError(null);
      const configs = accountConfigs.reduce<
        Parameters<typeof systemManagementApiClient.admin.remapAccounts>[0]
      >((acc, currentConfig) => {
        return [
          ...acc,
          {
            accountId: currentConfig.currentConfig.id,
            reason: currentConfig.newConfig.changeReason,
            cmgEntityKey: currentConfig.newConfig.cmgEntityKey,
          },
        ];
      }, []);

      try {
        const response = await systemManagementApiClient.admin.remapAccounts(configs);
        if (!response.ok) {
          setError(response.data.error.message);
          setIsLoading(false);
        }
        setIsLoading(false);
        return response.data;
      } catch (error) {
        errorUtil.assertIsError(error);
        loggerUtil.error(error);
        setError(error.message);
        setIsLoading(false);
      }
    },
    [setIsLoading, setError]
  );

  const clearError = React.useCallback(() => setError(null), [setError]);

  return { onRemapAccounts, error, isLoading, clearError };
};
