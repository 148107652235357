import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useDocumentTitle } from '../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../../common/util/routeFactory';
import { IdentityPageContent } from '../../../../design-system/IdentityPageContent';
import { selectAccount } from '../../../account-detail/shared/ducks';
import { selectCrmIntegration } from '../../shared/ducks';
import {
  bulkImportFromCsvFile,
  resetState,
  selectError,
  selectProcessedSoFar,
  selectProgress,
  selectUploading,
} from './ducks';
import { CRMFilesUploadSection } from './grid-content/CRMFilesUploadSection';

const mapStateToProps = state => ({
  account: selectAccount(state),
  crmIntegration: selectCrmIntegration(state),
  importResult: selectProgress(state),
  fileUploadError: selectError(state),
  fileUploading: selectUploading(state),
  processedSoFar: selectProcessedSoFar(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      bulkImportFromCsvFile,
      resetState,
    },
    dispatch
  ),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & DispatchProps;

export const CRMFilesUploadRouteComponent: FC<Props> = ({
  account,
  crmIntegration,
  importResult,
  actions,
  fileUploadError,
  fileUploading,
  processedSoFar,
}) => {
  useDocumentTitle(
    account?.name
      ? routeFactory.customerCrmFilesUpload.getDocumentTitle({ accountName: account?.name })
      : routeFactory.sysAdminCrmFilesUpload.getDocumentTitle({
          crmIntegrationName: crmIntegration?.name,
        })
  );

  useEffect(() => {
    if (!processedSoFar.moreToUpload && !fileUploading && importResult.rowsProcessed > 0) {
      actions.resetState();
    }
  }, [actions, fileUploading, importResult.rowsProcessed, processedSoFar.moreToUpload]);

  return (
    <IdentityPageContent
      gridContent={
        <CRMFilesUploadSection
          importResult={importResult}
          onUpload={formValues => {
            if (account && account.subdomain) {
              actions.bulkImportFromCsvFile(account.subdomain, formValues);
            } else if (crmIntegration && crmIntegration.subdomain) {
              actions.bulkImportFromCsvFile(crmIntegration.subdomain, formValues);
            }
          }}
          uploadError={fileUploadError}
          isUploading={fileUploading}
          processedSoFar={processedSoFar}
        />
      }
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CRMFilesUploadRouteComponent);
