import { apiTypes, reduxUtil } from '@cmg/common';
import { AnyAction, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import identityApiClient, { ActivateUserResponse } from '../../../common/api/identityApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { ActivateUser } from '../../../types/domain/self-settings/ActivateUser';

const createReducer = reduxUtil.createReducer;

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  ACTIVATE_USER_REQUEST = 'identity/ACTIVATE_USER_REQUEST',
  ACTIVATE_USER_SUCCESS = 'identity/ACTIVATE_USER_SUCCESS',
  ACTIVATE_USER_FAILURE = 'identity/ACTIVATE_USER_FAILURE',
  RESET_STATE = 'identity/RESET_FIRST_TIME_ACTIVATION_STATE',
}

/**
 * ACTION CREATORS
 */
export const resetState = () => ({
  type: ActionTypes.RESET_STATE,
});

export const activateUser = (params: ActivateUser) => ({
  type: ActionTypes.ACTIVATE_USER_REQUEST,
  payload: params,
});

export const activateUserSuccess = () => ({
  type: ActionTypes.ACTIVATE_USER_SUCCESS,
});

export const activateUserFailure = (error: apiTypes.GenericServerError) => ({
  type: ActionTypes.ACTIVATE_USER_FAILURE,
  payload: {
    error,
  },
});

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.RESET_STATE]: ReturnType<typeof resetState>;
  [ActionTypes.ACTIVATE_USER_REQUEST]: ReturnType<typeof activateUser>;
  [ActionTypes.ACTIVATE_USER_SUCCESS]: ReturnType<typeof activateUserSuccess>;
  [ActionTypes.ACTIVATE_USER_FAILURE]: ReturnType<typeof activateUserFailure>;
};

/**
 * REDUCERS
 */
export type ReducerState = {
  error: apiTypes.GenericServerError | null;
  submitting: boolean; // is it submitting?
  success: boolean; // did the user successfully activate
};

export const initialState = {
  error: null,
  success: false,
  submitting: false,
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.ACTIVATE_USER_REQUEST]: () => null,
  [ActionTypes.ACTIVATE_USER_SUCCESS]: () => null,
  [ActionTypes.ACTIVATE_USER_FAILURE]: (curState, { payload }) => payload.error,
});

const submittingReducer = createReducer<ReducerState['submitting'], Actions>(
  initialState.submitting,
  {
    [ActionTypes.ACTIVATE_USER_REQUEST]: () => true,
    [ActionTypes.ACTIVATE_USER_SUCCESS]: () => false,
    [ActionTypes.ACTIVATE_USER_FAILURE]: () => false,
  }
);

const successReducer = createReducer<ReducerState['success'], Actions>(initialState.success, {
  [ActionTypes.ACTIVATE_USER_REQUEST]: () => false,
  [ActionTypes.ACTIVATE_USER_SUCCESS]: () => true,
  [ActionTypes.ACTIVATE_USER_FAILURE]: () => false,
});

// This reducer acts on the entire state of this duck. Has access to duck state and must return duck state.
const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_STATE]: () => ({ ...initialState }),
});

const combinedReducers = combineReducers<ReducerState>({
  error: errorReducer,
  submitting: submittingReducer,
  success: successReducer,
});

export default createDucksReducer(combinedReducers);

// Combines our individual slice reducers and the cross slice reducer.
export function createDucksReducer(combinedReducers) {
  return (state: ReducerState = initialState, action: AnyAction) =>
    crossSliceReducer(combinedReducers(state, action), action);
}
/**
 * SELECTORS
 */
const selectState = (state: RootState) => state.firstTimeActivation;
export const selectError = state => selectState(state).error;
export const selectSubmitting = state => selectState(state).submitting;
export const selectSuccess = state => selectState(state).success;

/**
 * SAGAS
 */
export function* activateUserSubmitSaga({
  payload,
}: Actions[ActionTypes.ACTIVATE_USER_REQUEST]): SagaIterator {
  const response: ActivateUserResponse = yield call(identityApiClient.activateUser, payload);
  if (response.ok) {
    yield put(activateUserSuccess());
  } else {
    yield put(activateUserFailure(response.data.error));
  }
}

export function* firstTimeActivationSaga() {
  yield takeLatest<Actions[ActionTypes.ACTIVATE_USER_REQUEST]>(
    ActionTypes.ACTIVATE_USER_REQUEST,
    activateUserSubmitSaga
  );
}
