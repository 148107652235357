import { Option } from '@cmg/common';

import { FirmStatus } from './FirmStatus';

export const firmStatusLabels = {
  [FirmStatus.ACTIVE]: 'Active',
  [FirmStatus.DEFUNCT]: 'Defunct',
};

export const firmStatusOptions = Object.keys(FirmStatus).map(
  firmStatus =>
    ({
      label: firmStatusLabels[firmStatus],
      value: firmStatus,
    } as Option<FirmStatus>)
);
