import { PageError, Panel } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../common/util/routeFactory';
import useCertificatesAccess from '../../shared/hooks/useCertificatesAccess';
import { useTraitList_AccountTraitsQuery } from './components/trait-list/graphql';
import TraitList from './components/trait-list/TraitList';

export type Props = RouteComponentProps;
export const TraitListRouteComponent: React.FC<Props> = () => {
  useDocumentTitle(routeFactory.adminTraits.getDocumentTitle());
  const { data, error, loading } = useTraitList_AccountTraitsQuery();
  const { shouldShowTrait } = useCertificatesAccess();

  if (error) {
    // TODO: update PageError to take ApolloError as prop
    const errObj = {
      code: '',
      message: error.message,
      target: '',
      details: [],
    };
    return <PageError error={errObj} />;
  }

  const accountTraits = data?.accountTraits.filter(trait => shouldShowTrait(trait.code));

  return (
    <React.Fragment>
      <Panel>
        <Panel.Header title="Traits" />
        <TraitList loading={loading} traits={accountTraits} />
      </Panel>
    </React.Fragment>
  );
};

export default TraitListRouteComponent;
