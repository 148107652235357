import { Stack, TableSkeleton } from '@cmg/design-system';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import { useDocumentTitle } from '../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../../common/util/routeFactory';
import { ServerErrorAlert } from '../../../../design-system/alert/ServerErrorAlert';
import { PageGridSection } from '../../../../design-system/body-sections/PageGridSection';
import { IdentityPageContent } from '../../../../design-system/IdentityPageContent';
import UsersForRoleList from '../../../account-detail/role-detail/components/UsersForRoleList';
import {
  fetchAccountRole,
  selectError,
  selectLoading,
  selectRole,
} from '../../../account-detail/role-detail/ducks';
import { selectAccount } from '../../../account-detail/shared/ducks';
import { AccountRoleDetailTabs } from './AccountRoleDetailTabs';
import RolePermissionsTable from './RolePermissionsTable';

const mapStateToProps = state => ({
  role: selectRole(state),
  error: selectError(state),
  loading: selectLoading(state),
  account: selectAccount(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchAccountRole,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RouteProps = RouteComponentProps<{
  readonly roleId: string;
}>;
export type Props = StateProps & DispatchProps & RouteProps;

export const AccountRoleDetailRoute: React.FC<Props> = props => {
  const { role, error, match, loading, account, actions } = props;
  const { roleId } = match.params;

  useDocumentTitle(
    routeFactory.accountRoleDetail.getDocumentTitle({
      accountName: account?.name,
      roleName: role?.name,
    })
  );

  useEffect(() => {
    actions.fetchAccountRole({
      roleId: match.params.roleId,
    });
  }, [actions, match]);

  if (error) {
    return <ServerErrorAlert title="Error" error="Could not load role details..." />;
  }

  if (!account) {
    return null;
  }

  return (
    <IdentityPageContent
      gridContent={
        <PageGridSection
          size="large"
          title={`${role ? role.name : ''}`}
          content={
            <Stack>
              <AccountRoleDetailTabs account={account} roleId={roleId} />
              <div>
                <Route exact path={routeFactory.accountRoleDetail.routePath}>
                  {role &&
                    (loading ? (
                      <TableSkeleton aria-label="Loading Permissions" />
                    ) : (
                      <RolePermissionsTable role={role} />
                    ))}
                </Route>
                <Route exact path={routeFactory.accountRoleUsersDetail.routePath}>
                  <UsersForRoleList roleId={roleId} />
                </Route>
              </div>
            </Stack>
          }
        />
      }
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountRoleDetailRoute);
