import { Modal } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import SimpleFirmHierarchyTreeGrid from '../../../../common/components/lists/simple-firm-hierarchy-tree-grid/SimpleFirmHierarchyTreeGrid';
import { createTreeGridRowsFromHierarchy } from '../../../../common/components/lists/simple-firm-hierarchy-tree-grid/utils/hierarchyUtils';
import routeFactory from '../../../../common/util/routeFactory';
import { UUID } from '../../../../types/common';
import { TraversalType } from '../../../../types/domain/firm/constants';
import { FirmRelation } from '../../../../types/domain/firm/FirmRelation';
import {
  fetchFirmHierarchy,
  selectFirmHierarchy,
  selectFirmHierarchyError,
  selectFirmHierarchyLoading,
  selectMetadata,
} from '../../shared/ducks';

const mapStateToProps = state => ({
  hierarchy: selectFirmHierarchy(state),
  metadata: selectMetadata(state),
  error: selectFirmHierarchyError(state),
  loading: selectFirmHierarchyLoading(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchFirmHierarchy,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  firmId: UUID | null;
  isModalVisible: boolean;
  hideModal: () => void;
};
type Props = OwnProps & StateProps & DispatchProps;

export const FirmHierarchyModalComponent: React.FC<Props> = ({
  firmId,
  loading,
  hierarchy,
  metadata,
  error,
  isModalVisible,
  hideModal,
  actions,
}) => {
  React.useEffect(() => {
    if (firmId) {
      actions.fetchFirmHierarchy({ firmId, traversalType: TraversalType.FULL });
    }
  }, [actions, firmId]);

  const history = useHistory();
  const rows = hierarchy ? createTreeGridRowsFromHierarchy(hierarchy) : [];
  const firmRow = rows.find(row => row.node.id === firmId);

  // We want to expand tree grid rows from the top of the tree down
  // to the target firm (the firm whose ID matches props.firmId)
  const expandedGroups = firmRow ? firmRow.hierarchy.slice(0, -1) : [];

  return (
    <Modal
      show={isModalVisible}
      title="Organization Hierarchy"
      size="large"
      closeButton={true}
      onHide={() => hideModal()}
    >
      <SimpleFirmHierarchyTreeGrid
        loading={loading}
        error={error}
        onClickFirmName={firm =>
          history.push(
            routeFactory.rolodexFirmDetail.getUrlPath({ firmId: (firm as FirmRelation).id })
          )
        }
        hierarchy={hierarchy}
        metadata={metadata}
        activeFirmId={firmId}
        expandedGroups={expandedGroups}
        isCollapsible={true}
        showHeader
      />
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmHierarchyModalComponent);
