import { InvestorCoverageRead } from '@capital-markets-gateway/api-client-rolodex';
import { ColDef } from '@cmg/common';
import React from 'react';

import { recordStatusLabels } from '../../../../types/domain/record-status/constants';

export const CoverageStatusCellRenderer: React.FC<{ data: InvestorCoverageRead }> = ({ data }) => (
  <span>{recordStatusLabels[data.coverageStatus]}</span>
);

export const RendererWithPlaceholder: React.FC<{ data: string | null }> = ({ data }) => (
  <span>{!data || data.trim().length === 0 ? '-' : data}</span>
);

export const LocationCellRenderer: React.FC<{ data: InvestorCoverageRead }> = ({ data }) => {
  const isAddress1ForDisplay = data.address1?.trim().length === 0 ? null : data.address1;
  const isAddress2ForDisplay = data.address2?.trim().length === 0 ? null : data.address2;
  if (isAddress1ForDisplay !== null && isAddress2ForDisplay !== null) {
    return (
      <span>
        {isAddress1ForDisplay}
        <br />
        {isAddress2ForDisplay}
      </span>
    );
  } else {
    return <span>{(isAddress1ForDisplay || isAddress2ForDisplay) ?? '-'}</span>;
  }
};

const columns: ColDef[] = [
  {
    field: 'investorFirmName',
    headerName: 'CRM Name',
    cellRendererFramework: ({ data }) => <RendererWithPlaceholder data={data.investorFirmName} />,
  },
  { field: 'investorFirmKey', headerName: 'Firm Key' },
  {
    field: 'coverageStatus',
    headerName: 'Coverage Status',
    cellRendererFramework: CoverageStatusCellRenderer,
  },
  {
    field: 'cmgEntityName',
    headerName: 'CMG Entity Name',
    cellRendererFramework: ({ data }) => <RendererWithPlaceholder data={data.cmgEntityName} />,
  },
  {
    field: 'cmgEntityKey',
    headerName: 'CMG Entity Key',
    cellRendererFramework: ({ data }) => <RendererWithPlaceholder data={data.cmgEntityKey} />,
  },
  {
    field: 'location',
    headerName: 'Location',
    cellRendererFramework: LocationCellRenderer,
    sortable: false,
  },
  {
    field: 'lei',
    headerName: 'LEI',
    cellRendererFramework: ({ data }) => <RendererWithPlaceholder data={data.lei} />,
  },
];

export default columns;
