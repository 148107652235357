import styled from 'styled-components/macro';

import DetailHeader from '../../../../../common/components/layout/detail-header/DetailHeader';

export const StyledDetailHeader = styled(DetailHeader.Header)`
  flex: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledDetailHeaderTitleWrapper = styled(DetailHeader.TitleWrapper)`
  align-items: center;
`;

export const StyledTitleDetailHeaderColumn = styled(DetailHeader.Column)`
  flex: 0 1 auto;
`;

export const SIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

export const SInfoIconWrapper = styled.div`
  display: inline;
  margin: 0 5px;
  float: right;
`;

export const SSpan = styled.span`
  background-color: black;
  padding: 2px 3px 2px 3px;
`;

export const SHeaderDiv = styled.div`
  background-color: ${({ theme }) => theme.background.color.dark};
  padding: 2px 3px 2px 3px;
  border-radius: 5px;
`;
