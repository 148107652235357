import { DomainObject } from '@cmg/design-system';
import React from 'react';
import { connect } from 'react-redux';

import { selectAccount } from '../../account-detail/shared/ducks';
import { AccountDetailDomainHighlights } from './domain-highlights/AccountDetailDomainHighlights';
import { AccountDetailDomainNav } from './domain-nav/AccountDetailDomainNav';

const mapStateToProps = state => ({
  account: selectAccount(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

/**
 * Displays an account submenu.
 * For use in the app nav bar when looking at a particular account.
 */
export const AccountDetailDomainObject: React.FC<Props> = ({ account }) => {
  if (!account) {
    return null;
  }

  return (
    <DomainObject
      domainHighlights={<AccountDetailDomainHighlights account={account} />}
      domainNav={<AccountDetailDomainNav accountSubdomain={account.subdomain} />}
    />
  );
};

export default connect(mapStateToProps)(AccountDetailDomainObject);
