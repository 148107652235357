import * as yup from 'yup';

import { Saml2PConfigurationUpdate } from '../../../../../../types/domain/identity-provider/configurations/saml2p';

export type SamlProviderFormValues = Saml2PConfigurationUpdate & { certificateFile: File | null };

const MAX_FILE_KB_SIZE = 20;
const X509_CERT_MIME_TYPE = 'application/x-x509-ca-cert';

export const SamlUpdateSchema = yup.object().shape({
  certificateFile: yup
    .mixed<File>()
    .test(
      'FILE_SIZE_LIMIT_ERROR',
      `Certificate file is larger than ${MAX_FILE_KB_SIZE}kb`,
      file => !file || file.size < MAX_FILE_KB_SIZE * 1024
    )
    .test(
      'FILE_TYPE_ERROR',
      'The file you selected is not a valid x.509 certificate',
      file => !file || file.type === X509_CERT_MIME_TYPE
    )
    .required('Certificate is required'),
  name: yup.string().required('Name is required'),
  enabled: yup.bool().required(),
  configuration: yup.object().shape({
    entityId: yup.string().required('Entity ID is required'),
    signOnUrl: yup
      .string()
      .url('Single SignOn Service url must be a valid url')
      .required('Single SignOn Service url is required'),
    logoutUrl: yup
      .string()
      .url('Single Logout Service url must be a valid url')
      .notRequired()
      .nullable(),
    certificate: yup.string().required('Certificate is required'),
  }),
});
