import { numericUtil, timeUtil } from '@cmg/common';
import { ColDef } from 'ag-grid-community';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';
import { CrmIntegration } from '../../../../types/domain/entity-matcher/CrmIntegration';

export const AccountNameCellRenderer: React.FC<{
  data: CrmIntegration;
}> = ({ data: crmIntegration }) => (
  <Link
    to={routeFactory.rolodexCrmIntegrationDetail.getUrlPath({
      crmIntegrationId: crmIntegration.accountId,
    })}
  >
    {crmIntegration.name}
  </Link>
);

export const LinkedFirmsCellRenderer: React.FC<{
  data: CrmIntegration;
}> = ({ data: account }) => {
  return <React.Fragment>{numericUtil.formatInteger(account.linkedFirms)}</React.Fragment>;
};

export const NotLinkedFirmsCellRenderer: React.FC<{
  data: CrmIntegration;
}> = ({ data: account }) => {
  return <React.Fragment>{numericUtil.formatInteger(account.unLinkedFirms)}</React.Fragment>;
};

export const UnprocessedFirmsCellRenderer: React.FC<{
  data: CrmIntegration;
}> = ({ data: account }) => {
  return <React.Fragment>{numericUtil.formatInteger(account.newFirms)}</React.Fragment>;
};

export const TotalFirmsCellRenderer: React.FC<{
  data: CrmIntegration;
}> = ({ data: account }) => {
  return <React.Fragment>{numericUtil.formatInteger(account.totalFirms)}</React.Fragment>;
};

export const LastUpdatedCellRenderer: React.FC<{
  data: CrmIntegration;
}> = ({ data: account }) => {
  return (
    <React.Fragment>
      {account.modifiedOn ? timeUtil.formatAsDisplayDateTime(account.modifiedOn) : 'Never'}
    </React.Fragment>
  );
};

export const createColumns = (): ColDef[] => [
  {
    field: 'name',
    headerName: 'Name',
    cellRendererFramework: AccountNameCellRenderer,
    suppressColumnsToolPanel: true,
  },
  {
    field: 'linkedFirms',
    headerName: 'Number of Linked Organizations',
    sortable: false,
    cellRendererFramework: LinkedFirmsCellRenderer,
  },
  {
    field: 'unLinkedFirms',
    headerName: 'Number of Not Linked Organizations',
    sortable: false,
    cellRendererFramework: NotLinkedFirmsCellRenderer,
  },
  {
    field: 'newFirms',
    headerName: 'New Organizations',
    sortable: false,
    cellRendererFramework: UnprocessedFirmsCellRenderer,
  },
  {
    field: 'totalFirms',
    headerName: 'Total Number of Organizations',
    sortable: false,
    cellRendererFramework: TotalFirmsCellRenderer,
  },
  {
    field: 'modifiedOn',
    headerName: 'Last CRM Update',
    sortable: false,
    cellRendererFramework: LastUpdatedCellRenderer,
  },
];
