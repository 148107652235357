import { InterstitialPage, urlUtil } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import cookieManager from '../../../common/util/cookieManager';
import routeFactory from '../../../common/util/routeFactory';
import { ErrorCode } from '../error/constants';
import IdentityProviderLogin from './components/identityProviderLogin/IdentityProviderLogin';
import LocalLogin from './components/localLogin/LocalLogin';
import { fetchLoginInfo, selectLoginInfo, selectLoginInfoError } from './ducks';

const mapStateToProps = (state, ownProps: RouteComponentProps) => ({
  xsrfToken: cookieManager.readXsrfToken(),
  returnUrl: urlUtil.queryParse<{ returnUrl?: string }>(ownProps.location.search).returnUrl,
  loginInfo: selectLoginInfo(state),
  loginInfoError: selectLoginInfoError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchLoginInfo,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = StateProps & DispatchProps & RouteComponentProps;

export class LoginRouteComponent extends React.Component<Props> {
  componentDidMount() {
    const { actions, returnUrl } = this.props;

    if (returnUrl) {
      actions.fetchLoginInfo({ returnUrl });
    }
  }

  render() {
    const { xsrfToken, returnUrl, loginInfo, loginInfoError } = this.props;

    // Somehow the user ended up here without first redirecting to the identity service. Taking them to a private route that will trigger the oidc flow.
    if (!xsrfToken || !returnUrl) {
      return <Redirect to={routeFactory.profile.getUrlPath()} />;
    }

    if (!loginInfo) {
      return <InterstitialPage />;
    }

    if (loginInfoError) {
      return (
        <Redirect to={routeFactory.error.getUrlPath({ errorCode: ErrorCode.LOGIN_PREREQUISITE })} />
      );
    }

    if (loginInfo.localLoginEnabled) {
      return <LocalLogin returnUrl={returnUrl} />;
    } else {
      return <IdentityProviderLogin identityProviders={loginInfo.externalProviders} />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginRouteComponent);
