import styled from 'styled-components/macro';

export const STableCell = styled.div`
  display: flex;
  align-items: center;
  min-height: 34px;
`;

export const SDetail = styled.div`
  margin: 8px 16px 8px 0;
  display: flex;
`;

export const SDetailLabel = styled.div`
  color: ${({ theme }) => theme.text.color.light};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.small};
`;

export const SDetailValue = styled.div`
  margin-left: 16px;
  font-size: ${({ theme }) => theme.text.size.small};
`;
