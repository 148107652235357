import { all } from 'redux-saga/effects';

import { adminAccountApiKeyCreateSaga } from '../../features/account-detail/api-keys/api-keys-create/ducks';
import { adminAccountApiKeyDetailSaga } from '../../features/account-detail/api-keys/api-keys-detail/ducks';
import { adminAccountApiKeyListSaga } from '../../features/account-detail/api-keys/api-keys-list/ducks';
import { adminAccountRoleDetailSaga } from '../../features/account-detail/role-detail/ducks';
import { adminAccountRolesListSaga } from '../../features/account-detail/role-list/ducks';
import { adminAccountRolodexSaga } from '../../features/account-detail/rolodex/ducks';
import { adminAccountSecuritySaga } from '../../features/account-detail/security/ducks';
import { adminAccountIdentityProvidersSaga } from '../../features/account-detail/security/identity-providers/ducks';
import { adminAccountDetailSaga } from '../../features/account-detail/shared/ducks';
import { adminAccountUserCreateSaga } from '../../features/account-detail/user-create/ducks';
import { adminAccountUserDetailSaga } from '../../features/account-detail/user-detail/ducks';
import { adminAccountUserListSaga } from '../../features/account-detail/user-list/ducks';
import { adminAccountCreateSaga } from '../../features/global-management/account-create/ducks';
import { adminAccountListSaga } from '../../features/global-management/account-list/ducks';
import { draftAccountListSaga } from '../../features/global-management/draft-account-list/ducks';
import { adminTraitDetailSaga } from '../../features/global-management/trait-detail/ducks';
import { adminTraitListSaga } from '../../features/global-management/trait-list/ducks';
import { changePasswordSaga } from '../../features/identity-management/change-password/ducks';
import { firstTimeActivationSaga } from '../../features/identity-management/first-time-activation/ducks';
import { forgotPasswordSaga } from '../../features/identity-management/forgot-password/ducks';
import { loginSaga } from '../../features/identity-management/login/ducks';
import {
  investorCoverageSaga,
  profileSaga,
} from '../../features/identity-management/profile/ducks';
import { resetPasswordSaga } from '../../features/identity-management/reset-password/ducks';
import { investorSignupSaga } from '../../features/investor-signup/ducks';
import { rolodexCrmIntegrationDetailSaga } from '../../features/rolodex/crm-integration-detail/ducks';
import { rolodexCrmIntegrationListSaga } from '../../features/rolodex/crm-integration-list/ducks';
import { bulkCRMImportFromCsvFileCreateSaga } from '../../features/rolodex/crm-management/crm-files-upload/ducks';
import { rolodexCrmMatchListSaga } from '../../features/rolodex/crm-match-list/ducks';
import { rolodexDetailSaga } from '../../features/rolodex/firm-detail/ducks';
import { rolodexFirmNewSaga } from '../../features/rolodex/firm-new/ducks';
import { rolodexSharedSaga } from '../../features/rolodex/shared/ducks';

export default function* rootSaga() {
  yield all([
    loginSaga(),
    adminAccountCreateSaga(),
    adminAccountListSaga(),
    adminAccountDetailSaga(),
    adminAccountUserListSaga(),
    adminAccountUserDetailSaga(),
    adminAccountSecuritySaga(),
    adminAccountUserCreateSaga(),
    adminAccountRolesListSaga(),
    adminAccountRoleDetailSaga(),
    adminTraitListSaga(),
    adminTraitDetailSaga(),
    draftAccountListSaga(),
    forgotPasswordSaga(),
    resetPasswordSaga(),
    firstTimeActivationSaga(),
    changePasswordSaga(),
    adminAccountIdentityProvidersSaga(),
    profileSaga(),
    adminAccountApiKeyListSaga(),
    adminAccountApiKeyDetailSaga(),
    adminAccountApiKeyCreateSaga(),
    adminAccountRolodexSaga(),
    rolodexDetailSaga(),
    rolodexFirmNewSaga(),
    rolodexSharedSaga(),
    rolodexCrmIntegrationListSaga(),
    rolodexCrmIntegrationDetailSaga(),
    rolodexCrmMatchListSaga(),
    investorSignupSaga(),
    investorCoverageSaga(),
    bulkCRMImportFromCsvFileCreateSaga(),
  ]);
}
