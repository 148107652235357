import { duckPartFactory } from '@cmg/common';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import systemManagementApiClient, {
  InvestorSignupEmailMatchResponse,
  InvestorSignupTokenValidResponse,
  InvestorSignupValidResponse,
} from '../../common/api/systemManagementApiClient';
import { RootState } from '../../common/redux/rootReducer';

export const validateInvestorSignupEmailDuckParts = duckPartFactory.makeAPIDuckParts<
  { token: string; emailAddress: string },
  { isMatchFound: boolean }
>({
  prefix: 'GLOBAL_MANAGEMENT/VALIDATE_INVESTOR_SIGNUP_EMAIL',
});

export const validateInvestorSignupTokenDuckParts = duckPartFactory.makeAPIDuckParts<
  { token: string },
  { isValid: boolean }
>({
  prefix: 'GLOBAL_MANAGEMENT/VALIDATE_INVESTOR_SIGNUP_TOKEN',
});

/** TODO: use exported type from the BE */
export type CreateUserSignupDetailsDto = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  firmName: string;
  isEmailVerified: boolean;
  isTermsAndConditionsAccepted: boolean;
  isIoiTermsAccepted: boolean;
  token: string;
};

export const submitInvestorSignupFormDuckParts = duckPartFactory.makeAPIDuckParts<
  { values: CreateUserSignupDetailsDto },
  null
>({
  prefix: 'GLOBAL_MANAGEMENT/SUBMIT_INVESTOR_SIGNUP_FORM',
});

/**
 * ACTION CREATORS
 */
export const validateInvestorSignupEmail =
  validateInvestorSignupEmailDuckParts.actionCreators.request;
type ValidateInvestorSignupEmailAction = ReturnType<typeof validateInvestorSignupEmail>;
export const validateInvestorSignupToken =
  validateInvestorSignupTokenDuckParts.actionCreators.request;
type ValidateInvestorSignupTokenAction = ReturnType<typeof validateInvestorSignupToken>;
export const submitInvestorSignupForm = submitInvestorSignupFormDuckParts.actionCreators.request;
type SubmitInvestorSignupFormAction = ReturnType<typeof submitInvestorSignupForm>;

/**
 * REDUCERS
 */

export const initialState = {
  emailPart: validateInvestorSignupEmailDuckParts.initialState,
  tokenPart: validateInvestorSignupTokenDuckParts.initialState,
  investorSignup: submitInvestorSignupFormDuckParts.initialState,
};

export type ReducerState = typeof initialState;

export default combineReducers<ReducerState>({
  emailPart: validateInvestorSignupEmailDuckParts.reducer,
  tokenPart: validateInvestorSignupTokenDuckParts.reducer,
  investorSignup: submitInvestorSignupFormDuckParts.reducer,
});

/**
 * SELECTORS
 */

const selectLocalState = (state: RootState) => state.validateInvestorSignup;
const emailSelectors = validateInvestorSignupEmailDuckParts.makeSelectors(
  state => selectLocalState(state).emailPart
);
const tokenSelectors = validateInvestorSignupTokenDuckParts.makeSelectors(
  state => selectLocalState(state).tokenPart
);
const investorSignupSelectors = submitInvestorSignupFormDuckParts.makeSelectors(
  state => selectLocalState(state).investorSignup
);

export const selectEmailParts = emailSelectors.selectAll;
export const selectTokenParts = tokenSelectors.selectAll;
export const selectInvestorSignupParts = investorSignupSelectors.selectAll;
/**
 * SAGAS
 */

export function* validateInvestorSignupEmailSaga({
  payload,
}: ValidateInvestorSignupEmailAction): SagaIterator {
  const { token, emailAddress } = payload;
  const response: InvestorSignupEmailMatchResponse = yield call(
    systemManagementApiClient.investorSignup.validateInvestorSignupEmail,
    token,
    emailAddress
  );
  if (response.ok) {
    yield put(validateInvestorSignupEmailDuckParts.actionCreators.success(response.data));
  } else {
    yield put(validateInvestorSignupEmailDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* validateInvestorSignupTokenSaga({
  payload,
}: ValidateInvestorSignupTokenAction): SagaIterator {
  const { token } = payload;
  const response: InvestorSignupTokenValidResponse = yield call(
    systemManagementApiClient.investorSignup.validateInvestorSignupToken,
    token
  );
  if (response.ok) {
    yield put(validateInvestorSignupTokenDuckParts.actionCreators.success(response.data));
  } else {
    yield put(validateInvestorSignupTokenDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* submitInvestorSignupFormSaga({
  payload,
}: SubmitInvestorSignupFormAction): SagaIterator {
  const { values } = payload;
  const response: InvestorSignupValidResponse = yield call(
    systemManagementApiClient.investorSignup.submitInvestorSignupForm,
    values
  );
  if (response.ok) {
    yield put(submitInvestorSignupFormDuckParts.actionCreators.success(response.data));
  } else {
    yield put(submitInvestorSignupFormDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* investorSignupSaga() {
  yield takeLatest<ValidateInvestorSignupEmailAction>(
    validateInvestorSignupEmailDuckParts.actionTypes.REQUEST,
    validateInvestorSignupEmailSaga
  );
  yield takeLatest<ValidateInvestorSignupTokenAction>(
    validateInvestorSignupTokenDuckParts.actionTypes.REQUEST,
    validateInvestorSignupTokenSaga
  );
  yield takeLatest<SubmitInvestorSignupFormAction>(
    submitInvestorSignupFormDuckParts.actionTypes.REQUEST,
    submitInvestorSignupFormSaga
  );
}
