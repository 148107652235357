import { apiTypes, reduxUtil } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { History } from 'history';
import { AnyAction, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import systemManagementApiClient, {
  CreateAccountResponse,
} from '../../../common/api/systemManagementApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import routeFactory from '../../../common/util/routeFactory';
import { AccountCreate } from '../../../types/domain/account/account';

const createReducer = reduxUtil.createReducer;

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  CREATE_ACCOUNT_REQUEST = 'global_management/CREATE_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_SUCCESS = 'global_management/CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILURE = 'global_management/CREATE_ACCOUNT_FAILURE',
  RESET_STATE = 'global_management/RESET_STATE',
}

/**
 * ACTION CREATORS
 */
export const resetState = () => ({
  type: ActionTypes.RESET_STATE,
});

export const createAccount = (params: { account: AccountCreate; history: History }) => ({
  type: ActionTypes.CREATE_ACCOUNT_REQUEST,
  payload: { ...params },
});

export const createAccountSuccess = () => ({
  type: ActionTypes.CREATE_ACCOUNT_SUCCESS,
});

export const createAccountFailure = ({ error }) => ({
  type: ActionTypes.CREATE_ACCOUNT_FAILURE,
  payload: {
    error,
  },
});

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.RESET_STATE]: ReturnType<typeof resetState>;
  [ActionTypes.CREATE_ACCOUNT_REQUEST]: ReturnType<typeof createAccount>;
  [ActionTypes.CREATE_ACCOUNT_SUCCESS]: ReturnType<typeof createAccountSuccess>;
  [ActionTypes.CREATE_ACCOUNT_FAILURE]: ReturnType<typeof createAccountFailure>;
};

/**
 * REDUCERS
 */
export type ReducerState = {
  error: apiTypes.GenericServerError | null; // was there an error submitting?
  submitting: boolean; // is it submitting?
};

export const initialState = {
  error: null,
  submitting: false,
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.CREATE_ACCOUNT_REQUEST]: () => null,
  [ActionTypes.CREATE_ACCOUNT_SUCCESS]: () => null,
  [ActionTypes.CREATE_ACCOUNT_FAILURE]: (curState, { payload }) => payload.error,
});

const submittingReducer = createReducer<ReducerState['submitting'], Actions>(
  initialState.submitting,
  {
    [ActionTypes.CREATE_ACCOUNT_REQUEST]: () => true,
    [ActionTypes.CREATE_ACCOUNT_SUCCESS]: () => false,
    [ActionTypes.CREATE_ACCOUNT_FAILURE]: () => false,
  }
);

// This reducer acts on the entire state of this duck. Has access to duck state and must return duck state.
const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_STATE]: () => ({ ...initialState }),
});

const combinedReducers = combineReducers<ReducerState>({
  error: errorReducer,
  submitting: submittingReducer,
});

// Combines our individual slice reducers and the cross slice reducer.
export default function duckReducer(state: ReducerState = initialState, action: AnyAction) {
  const intermediateState = combinedReducers(state, action);
  return crossSliceReducer(intermediateState, action);
}

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.adminAccountCreate;
export const selectError = state => selectState(state).error;
export const selectSubmitting = state => selectState(state).submitting;

/**
 * SAGAS
 */
export function* createAccountSaga({
  payload,
}: Actions[ActionTypes.CREATE_ACCOUNT_REQUEST]): SagaIterator {
  const { account, history } = payload;
  const response: CreateAccountResponse = yield call(
    systemManagementApiClient.admin.createAccount,
    {
      ...account,
      requireTwoFactorAuth: true,
    }
  );
  if (response.ok) {
    yield put(createAccountSuccess());
    const accountSubdomain = response.data.subdomain;
    history.push(routeFactory.accountDetail.getUrlPath({ accountSubdomain }));
    SnackbarManager.success(`Account created successfully.`);
  } else {
    yield put(createAccountFailure({ error: response.data.error }));
  }
}

export function* adminAccountCreateSaga() {
  yield takeLatest<Actions[ActionTypes.CREATE_ACCOUNT_REQUEST]>(
    ActionTypes.CREATE_ACCOUNT_REQUEST,
    createAccountSaga
  );
}
