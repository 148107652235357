import * as yup from 'yup';

export const AzureAdConfigurationUpdateSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  configuration: yup.object().shape({
    clientId: yup.string().required('Client ID is required'),
    tenantId: yup.string().required('Azure Tenant ID is required'),
    authorizedGroupId: yup.string().notRequired().nullable(),
  }),
});
