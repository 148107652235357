import { apiUtil } from '@cmg/common';
import { AxiosInstance, AxiosRequestConfig } from 'axios';

type AxiosExcelDownloadArgs = {
  client: AxiosInstance;
  url: string;
  axiosConfig?: AxiosRequestConfig;
};

/**
 * Convenience function for requesting an excel download that all CMG REST APIs adhere too
 */
export const getExcelDownload = ({ client, url, axiosConfig }: AxiosExcelDownloadArgs) => {
  return client
    .get(url, {
      responseType: 'blob',
      ...axiosConfig,
      headers: { Accept: apiUtil.FILE_MIME_TYPES.XLSX, ...axiosConfig?.headers },
    })
    .then(response => {
      return {
        ...apiUtil.transformSuccessResponse(response),
        headers: response.headers,
      };
    })
    .catch(apiUtil.transformFailureResponse);
};
