import { UserStatus } from '@capital-markets-gateway/api-client-identity';
import { apiTypes } from '@cmg/common';
import { Button, DialogContentText } from '@cmg/design-system';
import { useEffect, useState, VFC } from 'react';

import usePrevious from '../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../design-system/alert/ServerErrorAlert';
import { DescriptionList } from '../../../../../design-system/body-sections/description-list/DescriptionList';
import { DescriptionListItem } from '../../../../../design-system/body-sections/description-list/DescriptionListItem';
import { ConfirmationDialog } from '../../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { FormDialogContent } from '../../../../../design-system/dialog/FormDialogContent';
import { FormDialogContentItem } from '../../../../../design-system/dialog/FormDialogContentItem';
import { getFullName } from '../../../../../types/domain/user/constants';
import { User } from '../../../../../types/domain/user/user';

export type Props = Readonly<{
  onReset: () => void;
  canEdit: Readonly<boolean>;
  user: Readonly<User>;
  isResettingPassword: Readonly<boolean>;
  resetPasswordError: Readonly<apiTypes.GenericServerError | null>;
  onResetPassword: () => void;
}>;

export const LoginDetailsSectionReadonly: VFC<Props> = ({
  onReset,
  canEdit,
  user,
  isResettingPassword,
  resetPasswordError,
  onResetPassword,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const previousIsResettingPassword = usePrevious(isResettingPassword);
  useEffect(() => {
    if (previousIsResettingPassword && !isResettingPassword && !resetPasswordError) {
      setIsDialogOpen(false);
      onReset();
    }
  }, [resetPasswordError, isResettingPassword, previousIsResettingPassword, onReset]);

  return (
    <DescriptionList>
      <DescriptionListItem label="Email" value={user.email} />
      <DescriptionListItem
        label="Password"
        value={
          <Button
            variant="text"
            onClick={() => setIsDialogOpen(true)}
            disabled={!canEdit || !user.accountConfirmed || user.status !== UserStatus.ACTIVE}
          >
            Reset Password
          </Button>
        }
      />
      <ConfirmationDialog
        isOpen={isDialogOpen}
        isLoading={isResettingPassword}
        title="Reset Password"
        cancelButtonLabel="Cancel"
        onCancel={() => setIsDialogOpen(false)}
        submitButtonLabel="Reset Password"
        submitButtonColor="primary"
        onSubmit={() => onResetPassword()}
        content={
          <FormDialogContent
            error={
              resetPasswordError && <ServerErrorAlert title="Error" error={resetPasswordError} />
            }
            items={
              <FormDialogContentItem
                value={
                  <DialogContentText>
                    {`Reset password email will be sent to user ${getFullName(
                      user
                    )}. Are you sure you want to proceed?`}
                  </DialogContentText>
                }
              />
            }
          />
        }
      />
    </DescriptionList>
  );
};
