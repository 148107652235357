import { apiTypes, DataGrid } from '@cmg/common';
import React from 'react';

import LoadingIndicator from '../../../../../common/components/indicators/loading-indicator/LoadingIndicator';
import { Account } from '../../../../../types/domain/account/account';
import traitAccountListGridColumns from './TraitAccountListGridColumns';

export type Props = {
  loading: boolean;
  accounts?: Account[];
  pagination?: apiTypes.Pagination;
  onChangePage: (params: apiTypes.ListParams) => void;
};

/**
 * Displays CMG Accounts with limited info relevant to the Trait Detail Page
 */
const TraitAccountList: React.FC<Props> = ({ accounts, pagination, loading, onChangePage }) => {
  if (loading && (!accounts || !pagination)) {
    return <LoadingIndicator>Loading accounts with Trait...</LoadingIndicator>;
  }

  return (
    <React.Fragment>
      {accounts && pagination && (
        <DataGrid<Account>
          extended={{ withMargin: false }}
          columns={traitAccountListGridColumns}
          rows={accounts}
          resizeBy="grid"
          totalPages={pagination.totalPages}
          pagination={{
            page: pagination.activePage,
            perPage: pagination.perPage,
          }}
          // TODO - why is their a mismatch for orderDirection between DataGrid and our ListParams?
          onPaginationChange={params => {
            onChangePage({
              ...params,
              orderDirection:
                params.orderDirection === 'asc'
                  ? apiTypes.SortDirection.ASC
                  : apiTypes.SortDirection.DESC,
            });
          }}
          gridOptions={{
            suppressCellSelection: true,
            suppressRowClickSelection: true,
            overlayNoRowsTemplate: 'No accounts have been assigned this Trait yet',
          }}
          loading={loading}
        />
      )}
    </React.Fragment>
  );
};

export default TraitAccountList;
