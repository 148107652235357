import { Grid } from '@cmg/design-system';

export type TableFilterListProps = {
  filters: React.ReactNode;
};

export const TableFilterList: React.VFC<TableFilterListProps> = ({ filters }) => {
  return (
    <Grid container spacing={2} paddingTop={2}>
      {filters}
    </Grid>
  );
};
