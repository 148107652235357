import { AccessControl, permissionsByEntity } from '@cmg/auth';
import { accountCreateScreenSelector } from '@cmg/e2e-selectors';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../common/util/routeFactory';
import { fetchFirmList, selectFirms } from '../../rolodex/shared/ducks';
import CreateAccountFormModal from './components/CreateAccountFormModal';
import { createAccount, resetState, selectError, selectSubmitting } from './ducks';

const mapStateToProps = state => ({
  submitting: selectSubmitting(state),
  error: selectError(state),
  firms: selectFirms(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createAccount,
      resetState,
      fetchFirmList,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = RouteComponentProps & StateProps & DispatchProps;

export const AccountCreateRouteComponent: React.FC<Props> = ({
  actions,
  history,
  submitting,
  error,
  firms,
}) => {
  useDocumentTitle(routeFactory.adminAccountNew.getDocumentTitle());
  const [cmgEntitySearch, setCMGEntitySearch] = useState({ loading: false, cmgEntityName: '' });

  useEffect(() => {
    return () => {
      actions.resetState();
    };
  }, [actions]);

  useEffect(() => {
    const [firm] = firms;
    if (firm && firm.displayName) {
      setCMGEntitySearch({
        loading: false,
        cmgEntityName: firm.displayName,
      });
    } else {
      setCMGEntitySearch({
        loading: false,
        cmgEntityName: '',
      });
    }
  }, [firms]);

  const handleCmgEntityKeyChange = React.useCallback(
    (cmgEntityKey?: string | null) => {
      if (cmgEntityKey && cmgEntityKey.length === 9) {
        setCMGEntitySearch({ loading: true, cmgEntityName: '' });
        actions.fetchFirmList({
          page: 1,
          key: cmgEntityKey,
        });
      } else {
        setCMGEntitySearch({
          loading: false,
          cmgEntityName: '',
        });
      }
    },
    [setCMGEntitySearch, actions]
  );

  const onCancel = () => history.push(routeFactory.adminAccounts.getUrlPath());

  return (
    <div data-test-id={accountCreateScreenSelector.testId}>
      <AccessControl requiredPermissions={[permissionsByEntity.GlobalAccount.FULL]}>
        <CreateAccountFormModal
          error={error}
          submitting={submitting}
          onSubmit={values => actions.createAccount({ account: values, history })}
          cmgEntitySearch={cmgEntitySearch}
          onCmgEntityKeyChange={handleCmgEntityKeyChange}
          onCancel={onCancel}
        />
      </AccessControl>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountCreateRouteComponent);
