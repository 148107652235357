import { getAppSettings } from '../../../../common/config/appSettings';

type SubdomainValidation = {
  readonly isValid?: boolean;
};

export const replaceSubdomainFromUrl = (url: string, subdomain: string): string => {
  const appSettings = getAppSettings();
  const matches = appSettings.client.selectDomainUrlMaskRegex.exec(url);
  if (matches == null) {
    return url;
  }

  const [fullMatch, protocolMatch, , , appDomainMatch] = matches;
  const protocol = protocolMatch ?? '';
  const appDomain = appDomainMatch ? `.${appDomainMatch}` : '';
  return url.replace(fullMatch, `${protocol}${subdomain}${appDomain}`);
};

export const createSubdomainMaskFromUrl = (url: string): string => {
  const appSettings = getAppSettings();
  const matches = appSettings.client.selectDomainUrlMaskRegex.exec(url);
  if (matches == null) {
    return url;
  }

  const [fullMatch] = matches;
  return url.replace(fullMatch, '').split('/')[0];
};

export const validateSubdomain = async (subdomain: string): Promise<SubdomainValidation> => {
  const appSettings = getAppSettings();
  //! TODO: migrate this to a GraphQL Lazy Query execute function, but at the moment the GraphQL server
  //! is not CORS ready, will discuss further to apply the correct CORS policy to the GraphQL server.
  const url = new URL(
    `/api/domain?validate=${encodeURIComponent(subdomain)}`,
    appSettings.auth.oidcAuthorityBaseUrl
  );
  return await fetch(url.toString()).then<SubdomainValidation>(response => response.json());
};
