import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import routeFactory from '../../../common/util/routeFactory';
import { selectAccount } from '../shared/ducks';
import { AccountRemappingModal } from './components/AccountRemappingModal';

type RouteProps = RouteComponentProps<{
  accountSubdomain: string;
}>;
export type Props = RouteProps;

export const AccountRemapRoute: React.FC<Props> = ({ history, match }) => {
  const currentAccountSubdomain = match.params.accountSubdomain;
  const account = useSelector(selectAccount);

  if (!account) {
    return null;
  }
  const onCancel = () => {
    history.push(routeFactory.accountRolodex.getUrlPath({ accountSubdomain: account.subdomain }));
  };

  return (
    <AccountRemappingModal onClose={onCancel} currentAccountSubdomain={currentAccountSubdomain} />
  );
};
