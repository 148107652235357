import { Grid } from '@cmg/design-system';
import { FC } from 'react';

export type DescriptionListProps = {
  label?: string;
};

export const DescriptionList: FC<DescriptionListProps> = ({ children, label }) => {
  return (
    <Grid container role="table" aria-label={label} px={1}>
      {children}
    </Grid>
  );
};
