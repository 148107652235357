import styled from 'styled-components/macro';

export const SHeader = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
`;

export const SSummary = styled.div`
  padding: 0 15px;
  color: ${({ theme }) => theme.text.color.light};
  span {
    padding-right: 5px;
  }
`;
