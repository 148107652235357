import { TextField } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { FormFieldLayout } from '../../../../../../design-system/body-sections/form/FormFieldLayout';
import { FormLayout } from '../../../../../../design-system/body-sections/form/FormLayout';
import {
  AzureAdConfigurationUpdate,
  AzureAdOpenIdMetadata,
} from '../../../../../../types/domain/identity-provider/configurations/azureAd';

export type Props = Readonly<{
  readonly metadata: AzureAdOpenIdMetadata | null;
}>;

export const ProviderDetailsSectionEdit: FC<Props> = ({ metadata }) => {
  const formik = useFormikContext<AzureAdConfigurationUpdate>();

  return (
    <FormLayout>
      <FormFieldLayout>
        <TextField disabled label="Redirect URI" value={metadata?.redirectUri} />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField disabled label="Logout URL" value={metadata?.logoutUrl} />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          required
          label="Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          required
          label="Client ID"
          name="configuration.clientId"
          value={formik.values.configuration.clientId}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.configuration?.clientId)}
          helperText={formik.errors.configuration?.clientId}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          required
          label="Azure Tenant ID"
          name="configuration.tenantId"
          value={formik.values.configuration.tenantId}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.configuration?.tenantId)}
          helperText={formik.errors.configuration?.tenantId}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          required
          label="Authorized Group ID"
          name="configuration.authorizedGroupId"
          value={formik.values.configuration.authorizedGroupId}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.configuration?.authorizedGroupId)}
          helperText={formik.errors.configuration?.authorizedGroupId}
        />
      </FormFieldLayout>
    </FormLayout>
  );
};
