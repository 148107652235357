import { Option } from '@cmg/common';

import { AccountType } from '../../../types/domain/account/constants';
import { jobFunctionLabels } from '../../../types/domain/user/constants';

export const getJobFunctionLabel = (
  jobFunction: string,
  accountType: AccountType | 'SYSTEM' | null
): string => {
  if (!accountType) {
    return jobFunction;
  }

  const labels = jobFunctionLabels[accountType];

  if (!labels) {
    return jobFunction;
  }

  return labels[jobFunction];
};

export const getJobFunctionOptions = (accountType: AccountType | 'SYSTEM' | null): Option[] => {
  if (!accountType) {
    return [];
  }

  const jobFunctions = jobFunctionLabels[accountType];

  if (!jobFunctions) {
    return [];
  }

  return Object.entries(jobFunctions)
    .map(
      ([jobKey, jobLabel]): Option<string> => ({
        label: jobLabel,
        value: jobKey,
      })
    )
    .sort((a: Option<string>, b: Option<string>) => (a.label >= b.label ? 1 : -1));
};
