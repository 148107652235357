import { accessTokenExpirationRequestInterceptor } from '@cmg/auth';
import { apiUtil, UnpackPromise, urlUtil } from '@cmg/common';
import axios, { AxiosResponse } from 'axios';

import { LoginInfo } from '../../types/domain/login-info/loginInfo';
import { ChangePassword } from '../../types/domain/self/ChangePassword';
import { PasswordReset } from '../../types/domain/self/PasswordReset';
import { Profile, ProfileUpdate } from '../../types/domain/self/profile';
import { ActivateUser } from '../../types/domain/self-settings/ActivateUser';
import { accessTokenRequestInterceptor, responseErrorInterceptor } from './interceptors';

/**
 * The identity client
 **/
const client = axios.create({
  baseURL: '/api/v1',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

client.interceptors.request.use(accessTokenExpirationRequestInterceptor);
client.interceptors.request.use(accessTokenRequestInterceptor);
client.interceptors.response.use(undefined, responseErrorInterceptor);

/**
 * API Calls
 */
export enum LoginUserPassResponseCode {
  SUCCESS = 'SUCCESS',
  REQUIRE_TWO_FACTOR = 'REQUIRE_TWO_FACTOR',
  PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
  LOCKED_OUT = 'LOCKED_OUT',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  USER_INACTIVE = 'USER_INACTIVE',
  ACCOUNT_DISABLED = 'ACCOUNT_DISABLED',
  TRIAL_EXPIRED = 'TRIAL_EXPIRED',
  NOT_ALLOWED = 'NOT_ALLOWED',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  IP_BLOCKED = 'IP_BLOCKED',
  EDIT_CONFLICT = 'EDIT_CONFLICT',
  DUPLICATE = 'DUPLICATE',
  UNHANDLED_ERR0R = 'UNHANDLED_ERROR',
  NETWORK_FAILURE = 'NETWORK_FAILURE',
  FORBIDDEN = 'FORBIDDEN',
}

type LoginUserPassSuccessBody = {
  code: LoginUserPassResponseCode.SUCCESS | LoginUserPassResponseCode.REQUIRE_TWO_FACTOR;
  message: string;
  returnUrl: string;
};

type MFALoginUserPassSuccessBody = {
  code: LoginUserPassResponseCode.SUCCESS;
  message: string;
  returnUrl: string;
};

export type LoginUserPassPasswordExpiredError = {
  code: LoginUserPassResponseCode.PASSWORD_EXPIRED;
  message: string;
  token: string;
  userId: string;
};

export type LoginUserPassFailureBody =
  | {
      code:
        | LoginUserPassResponseCode.INVALID_CREDENTIALS
        | LoginUserPassResponseCode.LOCKED_OUT
        | LoginUserPassResponseCode.USER_INACTIVE
        | LoginUserPassResponseCode.ACCOUNT_DISABLED
        | LoginUserPassResponseCode.TRIAL_EXPIRED
        | LoginUserPassResponseCode.NOT_ALLOWED;
      message: string;
    }
  | {
      code: LoginUserPassResponseCode.REQUIRE_TWO_FACTOR;
      message: string;
      sharedKey: string;
      authenticatorUri: string;
      firstTimeSetup: boolean;
    }
  | LoginUserPassPasswordExpiredError;

export type MFALoginUserPassFailureBody =
  | {
      code:
        | LoginUserPassResponseCode.VALIDATION_ERROR
        | LoginUserPassResponseCode.NOT_FOUND
        | LoginUserPassResponseCode.EDIT_CONFLICT
        | LoginUserPassResponseCode.INVALID_CREDENTIALS
        | LoginUserPassResponseCode.LOCKED_OUT
        | LoginUserPassResponseCode.PASSWORD_EXPIRED
        | LoginUserPassResponseCode.DUPLICATE
        | LoginUserPassResponseCode.IP_BLOCKED
        | LoginUserPassResponseCode.UNHANDLED_ERR0R
        | LoginUserPassResponseCode.NETWORK_FAILURE
        | LoginUserPassResponseCode.FORBIDDEN;
      message: string;
    }
  | {
      code: LoginUserPassResponseCode.REQUIRE_TWO_FACTOR;
      message: string;
      sharedKey: string;
      authenticatorUri: string;
      firstTimeSetup: boolean;
    }
  | LoginUserPassPasswordExpiredError;

/**
 * Login to the identity service with username and password
 */
const loginUserPass = (params: { username: string; password: string; returnUrl: string }) =>
  client
    .post<typeof params, AxiosResponse<LoginUserPassSuccessBody>>('/login', {
      username: params.username,
      password: params.password,
      // rememberLogin: false // todo what does this actually do... ?
      returnUrl: params.returnUrl,
    })
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.makeTransformFailureResponse<LoginUserPassFailureBody>());
export type LoginUserPassResponse = UnpackPromise<ReturnType<typeof loginUserPass>>;

/**
 * MFALogin to the identity service with securityCode and rememberDevice
 */
const mfaLoginUserPass = (params: { code: string; rememberDevice: boolean; returnUrl: string }) =>
  client
    .post<typeof params, AxiosResponse<MFALoginUserPassSuccessBody>>('/verifyCode', {
      code: params.code,
      rememberDevice: params.rememberDevice,
      returnUrl: params.returnUrl,
    })
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.makeTransformFailureResponse<MFALoginUserPassFailureBody>());
export type MFALoginUserPassResponse = UnpackPromise<ReturnType<typeof mfaLoginUserPass>>;

/**
 * Submits a Forgot Password request which will send the user an email with a reset password link
 */
const sendPasswordResetEmail = (email: string) => {
  return client
    .post<{ email: string }, AxiosResponse<void>>('/self/password/resetRequests', { email })
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type ResetPasswordRequestResponse = UnpackPromise<ReturnType<typeof sendPasswordResetEmail>>;

/**
 * Resets a password with a token that was provided from the forgot password email or an login failure due to an expired password
 */
const resetPassword = (params: PasswordReset) => {
  return client
    .put<PasswordReset, AxiosResponse<void>>('/self/password/reset', params)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type ResetPasswordResponse = UnpackPromise<ReturnType<typeof resetPassword>>;

/**
 * Activates a user with a token that was provided from the welcome email
 */
const activateUser = (params: ActivateUser) => {
  return client
    .post<ActivateUser, AxiosResponse<void>>('/self/profile/activate', params)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type ActivateUserResponse = UnpackPromise<ReturnType<typeof activateUser>>;

/**
 * Activates a user with a token that was provided from the welcome email
 */
const changePassword = (params: ChangePassword) => {
  return client
    .put<ChangePassword, AxiosResponse<void>>('/self/password/change', params)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type ChangePasswordResponse = UnpackPromise<ReturnType<typeof changePassword>>;

/**
 * Fetch login info
 * Contains the login information to determine which mechanisms the user can login with
 */
const fetchLoginInfo = (params: { returnUrl: string }) => {
  const queryString = urlUtil.queryStringify({
    returnUrl: params.returnUrl,
  });
  return client
    .get<LoginInfo>(`/loginInfo${queryString}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type FetchLoginInfoResponse = UnpackPromise<ReturnType<typeof fetchLoginInfo>>;

/**
 * fetch user profile information
 * Contains profile details to be displayed in the profile page
 */
const fetchProfile = () => {
  return client
    .get<Profile>('/self/profile')
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type FetchProfileResponse = UnpackPromise<ReturnType<typeof fetchProfile>>;

/**
 * updates user profile information
 */
const updateProfile = (params: ProfileUpdate) => {
  return client
    .put<ProfileUpdate, AxiosResponse<Profile>>('/self/profile', params)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateProfileResponse = UnpackPromise<ReturnType<typeof updateProfile>>;

/**
 * fetch user profile information
 * Contains profile details to be displayed in the profile page
 */
const resendCode = () => {
  return client
    .post('/resendCode')
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.makeTransformFailureResponse<MFALoginUserPassFailureBody>());
};
export type ResendCodeResponse = UnpackPromise<ReturnType<typeof resendCode>>;

const identityApiClient = {
  loginUserPass,
  sendPasswordResetEmail,
  resetPassword,
  activateUser,
  changePassword,
  fetchLoginInfo,
  fetchProfile,
  updateProfile,
  mfaLoginUserPass,
  resendCode,
};

export default identityApiClient;
