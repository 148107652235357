import { Box, Button, Stack, TextField } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import { FC, useCallback, useState } from 'react';

import { AccountPermission } from '../../../../../../types/domain/account/account';
import { Role } from '../../../../../../types/domain/role/role';
import { RoleBasedPermissionsDialog } from '../role-based-permissions/RoleBasedPermissionsDialog';
import { CreateApiKeyFormValues } from './../ApiKeyCreateRoute.model';

export type Props = Readonly<{
  readonly accountPermissions: AccountPermission[];
  readonly roles: Role[];
  readonly onApplyPermissions: (permissions: string[]) => void;
}>;

export const ApiKeyPermissionsTableActions: FC<Props> = ({
  accountPermissions,
  roles,
  onApplyPermissions,
}) => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const applyPermissions = useCallback(
    (permissions: string[]) => {
      onApplyPermissions(permissions);
      setIsDialogOpened(false);
    },
    [onApplyPermissions]
  );

  const formik = useFormikContext<CreateApiKeyFormValues>();

  return (
    <Stack direction="row" justifyContent="space-between" paddingTop={1}>
      <Box>
        <TextField
          name="name"
          label="Key Label"
          placeholder="e.g. Alice's API Key"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      </Box>
      <Box>
        <Button variant="text" color="primary" onClick={() => setIsDialogOpened(true)}>
          Set Role-Based Permissions
        </Button>
        <RoleBasedPermissionsDialog
          onApplyPermissions={applyPermissions}
          accountPermissions={accountPermissions}
          roles={roles}
          isOpen={isDialogOpened}
          onClose={() => setIsDialogOpened(false)}
        />
      </Box>
    </Stack>
  );
};
