import {
  InvestorCoverageRead,
  PaginationMetadata,
} from '@capital-markets-gateway/api-client-rolodex';
import { apiTypes } from '@cmg/common';
import { VFC } from 'react';

import { InvestorCoverageFilters } from '../../../../common/api/rolodexApiClient';
import { PageGridSection } from '../../../../design-system/body-sections/PageGridSection';
import { InvestorCoverageGrid } from '../../../shared/components/investor-coverage/InvestorCoverageGrid';

export type Props = {
  isLoading: Readonly<boolean>;
  investorCoverage: readonly InvestorCoverageRead[];
  pagination: Readonly<PaginationMetadata>;
  filters: Readonly<InvestorCoverageFilters>;
  onChangePage: (params: apiTypes.ListParams) => void;
  onChangeFilters: (params: InvestorCoverageFilters, debounce?: boolean) => void;
};

export const InvestorCoverageSection: VFC<Props> = ({
  isLoading,
  investorCoverage,
  pagination,
  filters,
  onChangePage,
  onChangeFilters,
}) => {
  return (
    <PageGridSection
      size="large"
      title="Investor Coverage"
      content={
        <InvestorCoverageGrid
          investorCoverage={investorCoverage}
          loading={isLoading}
          pagination={pagination}
          filters={filters}
          onChangeFilters={onChangeFilters}
          onChangePage={onChangePage}
        />
      }
    />
  );
};
