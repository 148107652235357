import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { apiTypes } from '@cmg/common';
import { Button } from '@cmg/design-system';
import { FC, Fragment, useEffect, useState } from 'react';

import usePrevious from '../../../../../../common/util/usePrevious';
import { RegenerateApiKeyConfirmationDialog } from './RegenerateApiKeyConfirmationDialog';
import { RevokeApiKeyConfirmationDialog } from './RevokeApiKeyConfirmationDialog';

export type Props = Readonly<{
  readonly apiKeyName: string;
  readonly isLoading: boolean;
  readonly canKeyBeRegenerated: boolean;
  readonly regenerateApiKeyError: apiTypes.GenericServerError | null;
  readonly onRevokeApiKey: () => void;
  readonly onRegenerateApiKey: () => void;
  readonly onResetRegenerateApiKeyErrors: () => void;
}>;

export const ApiKeyDetailHighlightsActions: FC<Props> = ({
  apiKeyName,
  isLoading,
  canKeyBeRegenerated,
  regenerateApiKeyError,
  onRevokeApiKey,
  onRegenerateApiKey,
  onResetRegenerateApiKeyErrors,
}) => {
  const [isRevokeKeyDialogOpen, setIsRevokeKeyDialogOpen] = useState(false);
  const [isRegenerateKeyDialogOpen, setIsRegenerateKeyDialogOpen] = useState(false);

  const previousIsRegenerateKeyDialogOpen = usePrevious(isRegenerateKeyDialogOpen);
  useEffect(() => {
    if (previousIsRegenerateKeyDialogOpen && !isRegenerateKeyDialogOpen) {
      onResetRegenerateApiKeyErrors();
    }
  }, [isRegenerateKeyDialogOpen, onResetRegenerateApiKeyErrors, previousIsRegenerateKeyDialogOpen]);

  const canUpdateKey = useCheckPermissions(
    [
      permissionsByEntity.ApiKey.FULL,
      // @todo remove the ability to create api keys from system admins once this feature is turned on for the account admins
      permissionsByEntity.GlobalApiKey.FULL,
    ],
    false
  );

  if (!canUpdateKey) {
    return null;
  }

  return (
    <Fragment>
      <Button
        disabled={isLoading}
        variant="outlined"
        onClick={() => setIsRevokeKeyDialogOpen(true)}
      >
        Revoke Key
      </Button>
      <Button
        disabled={isLoading || !canKeyBeRegenerated}
        variant="contained"
        onClick={() => setIsRegenerateKeyDialogOpen(true)}
      >
        Re-Generate Key
      </Button>
      <RevokeApiKeyConfirmationDialog
        isOpen={isRevokeKeyDialogOpen}
        isLoading={isLoading}
        onClose={() => setIsRevokeKeyDialogOpen(false)}
        onRevokeApiKey={onRevokeApiKey}
        apiKeyName={apiKeyName}
      />
      <RegenerateApiKeyConfirmationDialog
        isOpen={isRegenerateKeyDialogOpen}
        isLoading={isLoading}
        error={regenerateApiKeyError}
        onClose={() => setIsRegenerateKeyDialogOpen(false)}
        onRegenerateApiKey={onRegenerateApiKey}
      />
    </Fragment>
  );
};
