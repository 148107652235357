import { RecordStatus } from '@capital-markets-gateway/api-client-rolodex';
import { Autocomplete, AutocompleteTextFieldProps, SearchTextField } from '@cmg/design-system';
import { FC, Fragment, useMemo } from 'react';

import { InvestorCoverageFilters } from '../../../../common/api/rolodexApiClient';
import { TableFilter } from '../../../../design-system/table/TableFilter';
import { TableFilterList } from '../../../../design-system/table/TableFilterList';
import { recordStatusOptions } from '../../../../types/domain/record-status/constants';

export type Props = Readonly<{
  filters: Readonly<InvestorCoverageFilters>;
  onChangeFilters: (params: InvestorCoverageFilters, debounce?: boolean) => void;
}>;

export const InvestorCoverageGridFilters: FC<Props> = ({ onChangeFilters, filters }) => {
  const handleTextChange = (searchText: string) => {
    onChangeFilters({ ...filters, searchText }, true);
  };

  const handleCoverageStatusChange = (status: RecordStatus | null) => {
    onChangeFilters({ ...filters, coverageStatus: status ?? undefined });
  };

  const coverageStatusTextFieldProps: AutocompleteTextFieldProps = useMemo(
    () => ({
      label: 'Coverage Status',
      placeholder: 'Filter by Coverage Status...',
    }),
    []
  );

  return (
    <TableFilterList
      filters={
        <Fragment>
          <TableFilter>
            <SearchTextField
              label="Search"
              placeholder="Search for..."
              value={filters.searchText ?? ''}
              onChange={e => handleTextChange(e.target.value)}
            />
          </TableFilter>
          <TableFilter>
            <Autocomplete
              options={recordStatusOptions.map(option => option.value)}
              getOptionLabel={optionValue =>
                recordStatusOptions.find(o => o.value === optionValue)?.label ?? ''
              }
              value={filters.coverageStatus ?? null}
              onChange={(_e, value) => handleCoverageStatusChange(value)}
              TextFieldProps={coverageStatusTextFieldProps}
            />
          </TableFilter>
        </Fragment>
      }
    />
  );
};
