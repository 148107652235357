import { urlUtil } from '@cmg/common';

import { getAppSettings } from '../config/appSettings';

/**
 * Instead of hard coding external URLs that our app links to, we define them in
 * a common location.
 */
const externalRouteFactory = {
  ecmSpa: {
    getUrl: () => {
      const accountSubdomain = urlUtil.getAccountSubdomain(window.location);
      return urlUtil.addSubdomainToUrl(getAppSettings().env.ecmSpaBaseUrl, accountSubdomain);
    },
  },
  developerDocsSite: {
    getUrl: () => {
      const appSettings = getAppSettings();
      return appSettings.env.developerDocsSiteUrl;
    },
  },
};

export default externalRouteFactory;
