import { TableCell, TableHead, TableRow } from '@cmg/design-system';

export const PermissionsTableHeader: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Product/Permission Group</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>No Access</TableCell>
        <TableCell>Read Access</TableCell>
        <TableCell>Full Access</TableCell>
      </TableRow>
    </TableHead>
  );
};
