import { IconButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledIconButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.interactive.primary};
  border-radius: 0;
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  &,
  &:hover {
    color: ${({ theme }) => theme.text.color.white};
  }
`;

export const SBannerWrapper = styled.div`
  margin-top: 20px;
  padding: 20px;
`;
