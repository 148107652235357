import { isSystemSubdomain } from '@cmg/auth';
import { Button, DotStatus, Highlights } from '@cmg/design-system';
import { Fragment, useCallback, useMemo } from 'react';

import {
  IdentityProviderType,
  identityProviderTypeDisplay,
} from '../../../../../../types/domain/identity-provider/constants';
import { IdentityProvider } from '../../../../../../types/domain/identity-provider/identityProvider';
import {
  displayIdentityVerificationUrl,
  redirectToIdentityVerification,
} from '../../../../../account-detail/security/identity-providers/utils';

export type Props<TIdentityProvider extends IdentityProvider> = {
  readonly canEdit: boolean;
  readonly isLoading: boolean;
  readonly providerType: IdentityProviderType;
  readonly configuredProvider: TIdentityProvider | null;
  readonly onStatusToggle: (provider: TIdentityProvider) => void;
};

export function LoginProviderHighlights<TIdentityProvider extends IdentityProvider>({
  canEdit,
  isLoading,
  providerType,
  configuredProvider,
  onStatusToggle,
}: Props<TIdentityProvider>) {
  const isVerified = useMemo(() => {
    return configuredProvider != null && configuredProvider.verified;
  }, [configuredProvider]);

  const isEnabled = useMemo(() => {
    return isVerified && configuredProvider != null && configuredProvider.enabled;
  }, [configuredProvider, isVerified]);

  const test = useCallback(() => {
    const verificationUrl = configuredProvider ? configuredProvider.verificationUrl : null;
    if (!verificationUrl) {
      return;
    }

    if (isSystemSubdomain()) {
      displayIdentityVerificationUrl(verificationUrl);
    } else {
      redirectToIdentityVerification(verificationUrl);
    }
  }, [configuredProvider]);

  const toggleStatus = useCallback(() => {
    if (!configuredProvider) {
      return;
    }

    onStatusToggle(configuredProvider);
  }, [configuredProvider, onStatusToggle]);

  return (
    <Highlights
      title={identityProviderTypeDisplay[providerType]}
      titleItems={
        <Fragment>
          <DotStatus
            label={isVerified ? 'Tested' : 'Not Tested'}
            color={isVerified ? 'success' : 'error'}
          />
          <DotStatus
            label={isEnabled ? 'Active' : 'Inactive'}
            color={isEnabled ? 'success' : 'error'}
          />
        </Fragment>
      }
      actions={
        configuredProvider && canEdit ? (
          <Fragment>
            <Button disabled={!isVerified || isLoading} variant="contained" onClick={toggleStatus}>
              {isEnabled ? 'Deactivate' : 'Activate'}
            </Button>
            <Button disabled={isVerified || isLoading} variant="contained" onClick={test}>
              Test
            </Button>
          </Fragment>
        ) : undefined
      }
    />
  );
}
