import { Button, EditIcon, LoadingButton } from '@cmg/design-system';
import { Fragment, VFC } from 'react';

export type PageGridSectionActionsProps = {
  readonly isSubmitting: boolean;
  readonly isEditing: boolean;
  readonly onEdit: () => void;
  readonly onCancel: () => void;
};

export const PageGridSectionActions: VFC<PageGridSectionActionsProps> = ({
  isSubmitting,
  isEditing,
  onEdit,
  onCancel,
}) => {
  return (
    <Fragment>
      {isEditing && (
        <Fragment>
          <Button variant="outlined" onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Fragment>
      )}
      {!isEditing && (
        <Button variant="text" startIcon={<EditIcon />} onClick={onEdit}>
          Edit
        </Button>
      )}
    </Fragment>
  );
};
