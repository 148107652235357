import { FC } from 'react';

import { AccountPermission } from '../../../../../../types/domain/account/account';
import { PermissionDetail, UndefinedPermissionDetail } from '../../../../../shared/auth/constants';
import { mapPermissionDetails } from '../../../../../shared/components/permissions-table/PermissionsTable.model';
import { PermissionsTable } from '../../shared/PermissionsTable';
import { PermissionsTableRow } from '../../shared/PermissionsTableRow';
import { ApiKeyPermissionsTableGroupHeader } from './ApiKeyPermissionsTableGroupHeader';
import { ApiKeyPermissionsTableHeader } from './ApiKeyPermissionsTableHeader';

export type Props = Readonly<{
  readonly selectedPermissions: string[];
  readonly accountPermissions: AccountPermission[];
  readonly onPermissionsAdd: (permissions: string[]) => void;
  readonly onPermissionsRemove: (permissions: string[]) => void;
}>;

export const ApiKeyPermissionsTable: FC<Props> = ({
  selectedPermissions,
  accountPermissions,
  onPermissionsAdd,
  onPermissionsRemove,
}) => {
  const availablePermissions: (PermissionDetail | UndefinedPermissionDetail)[] =
    mapPermissionDetails(accountPermissions);

  return (
    <PermissionsTable
      selectedPermissions={selectedPermissions}
      accountPermissions={accountPermissions}
      renderRow={props => <PermissionsTableRow {...props} isInEditMode={true} />}
      renderGroupHeader={(groupHeader: string) => (
        <ApiKeyPermissionsTableGroupHeader
          groupHeader={groupHeader}
          availablePermissions={availablePermissions}
          onPermissionsAdd={onPermissionsAdd}
          onPermissionsRemove={onPermissionsRemove}
        />
      )}
      renderTableHeader={() => (
        <ApiKeyPermissionsTableHeader
          availablePermissions={availablePermissions}
          onPermissionsAdd={onPermissionsAdd}
          onPermissionsRemove={onPermissionsRemove}
        />
      )}
    />
  );
};
