import { useCallback, useMemo } from 'react';

import {
  AccessLevel,
  GroupName,
  PermissionDetail,
  UndefinedPermissionDetail,
} from '../../../../../../shared/auth/constants';
import { sanitizePermissionsByAccessLevel } from '../../../../../../shared/components/permissions-table/PermissionsTable.model';
import { filterPermissionsByAccessLevels } from '../ApiKeyPermissionsTable.model';

export type UseToggleRowCellsProps = Readonly<{
  readonly groupName: string;
  readonly availablePermissions: (PermissionDetail | UndefinedPermissionDetail)[];
  readonly onAdd?: (permissions: string[]) => void;
  readonly onRemove?: (permissions: string[]) => void;
}>;
export type UseToggleRowCellsResult = Readonly<{
  readonly selectByAccessLevel: (accessLevels: AccessLevel[] | null) => void;
}>;

export function useToggleRowCells({
  groupName,
  availablePermissions,
  onRemove,
  onAdd,
}: UseToggleRowCellsProps): UseToggleRowCellsResult {
  const groupNames = useMemo(
    () => new Set<string>(Object.entries(GroupName).map(([, label]) => label)),
    []
  );

  // filter all available permissions by this group, otherwise if !group this must be table header
  const permissionsToToggle = groupNames.has(groupName)
    ? availablePermissions.filter(p => p.groupName === groupName)
    : availablePermissions;

  const selectByAccessLevel = useCallback(
    (accessLevels: AccessLevel[] | null) => {
      // filter permissions for this access level
      const permissionsByAccessLevel: (PermissionDetail | UndefinedPermissionDetail)[] =
        filterPermissionsByAccessLevels(accessLevels, permissionsToToggle);
      // props for an input below

      const permissionNames = permissionsByAccessLevel.map(p => p.name);
      // toggling a column adds permissions
      // no access column should filter/remove permissions

      const removeAccessLevels = !accessLevels;
      if (removeAccessLevels) {
        onRemove?.(permissionNames);
      } else {
        const permissionsToAdd: string[] = Object.values(
          sanitizePermissionsByAccessLevel(permissionNames)
        );
        onAdd?.(permissionsToAdd);
      }
    },
    [onAdd, onRemove, permissionsToToggle]
  );

  return { selectByAccessLevel };
}
