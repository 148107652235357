import React from 'react';
import styled from 'styled-components/macro';

type Props = {
  /** Whether the panel should be visible or not. */
  show: boolean;
  width: number;
  children: React.ReactNode;
};

/**
 * A Slideout Sidebar panel
 */
export const SSlideoutSidebarOuterWrapper = styled.div<{ width: number; show: boolean }>`
  position: absolute;
  height: 100%;
  right: 0;
  background: ${({ theme }) => theme.background.color.white};
  overflow: scroll;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  width: ${({ show, width }) => (show ? `${width}px` : `0px`)};
  transition: ${({ show }) => (show ? `width 0.3s ease-out` : `width 0s none`)};
`;

export const SSlideoutSidebarInnerWrapper = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}px`};
`;

const SlideoutSidebar = ({ show, width, children }: Props) => (
  <SSlideoutSidebarOuterWrapper show={show} width={width}>
    <SSlideoutSidebarInnerWrapper width={width}>{children}</SSlideoutSidebarInnerWrapper>
  </SSlideoutSidebarOuterWrapper>
);

export default SlideoutSidebar;
