import { CheckboxField, TextInputField } from '@cmg/common';
import styled from 'styled-components/macro';

export const SError = styled.span`
  color: ${({ theme }) => theme.text.color.error};
`;

export const SFormWrapper = styled.div`
  padding: 16px 0;
  color: ${({ theme }) => theme.text.color.darkGray};
`;

export const SCheckboxField = styled(CheckboxField)`
  margin-bottom: 12px;
  label {
    align-items: baseline;
  }
`;

export const STextInputField = styled(TextInputField)`
  position: relative;
  input {
    font-size: 14px;
    position: relative;
    padding-top: 20px;
    line-height: 46px;
    padding-bottom: 4px;
    padding-left: 8px;
    height: 46px;
    background: #f3f3f3;
    color: ${({ theme }) => theme.color.gray5};
    &:-webkit-autofill {
      color: ${({ theme }) => theme.color.gray5} !important;
    }
  }
  label {
    color: ${({ theme }) => theme.designSystem.colors.gray[500]};
    position: absolute;
    top: 4px;
    left: 8px;
    z-index: 999;
    margin-bottom: 2px;
    font-size: 12px;
  }
  margin-bottom: 14px;
`;
