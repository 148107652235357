import { apiTypes, Banner, Panel, ServerErrors, urlUtil } from '@cmg/common';
import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import { CrmInvestorFirmsFilters } from '../../../common/api/rolodexApiClient';
import LoadingOverlay from '../../../common/components/indicators/loading-overlay/LoadingOverlay';
import { UUID } from '../../../types/common';
import { fetchCrmIntegration, selectCrmIntegration } from '../shared/ducks';
import CrmIntegrationDetailHeader from './components/crm-integration-detail-header/CrmIntegrationDetailHeader';
import CrmInvestorFirmList from './components/crm-investor-firm-list/CrmInvestorFirmList';
import {
  fetchCrmInvestorFirms,
  selectCrmInvestorFirms,
  selectCrmInvestorFirmsError,
  selectCrmInvestorFirmsLoading,
  selectCrmInvestorFirmsPagination,
} from './ducks';
import { getQueryParams } from './RolodexCrmIntegrationDetailRoute.model';

const mapStateToProps = state => ({
  loading: selectCrmInvestorFirmsLoading(state),
  clientFirms: selectCrmInvestorFirms(state),
  crmIntegration: selectCrmIntegration(state),
  pagination: selectCrmInvestorFirmsPagination(state),
  error: selectCrmInvestorFirmsError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchCrmIntegration,
      fetchCrmInvestorFirms,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = RouteComponentProps<{ crmIntegrationId: UUID }> & StateProps & DispatchProps;

const getListParams = memoize(
  (
    totalPages: number,
    page: string | null,
    perPage: string | null,
    orderField: string | null | undefined,
    orderDirection: apiTypes.SortDirection | null | undefined
  ) => ({
    page: totalPages > 0 && totalPages < Number(page) ? totalPages : Number(page),
    perPage: perPage && !isNaN(Number(perPage)) ? Number(perPage) : undefined,
    orderField,
    orderDirection: orderDirection ?? undefined,
  })
);

/**
 * Displays the details for an Account with CRM Integration,
 * including a summary of the firms in the CRM data and a list
 * of the firms in the CRM data.
 */
export const RolodexCrmIntegrationDetailsRouteComponent: React.FC<Props> = ({
  loading,
  clientFirms,
  crmIntegration,
  pagination,
  error,
  match,
  location,
  history,
  actions,
}) => {
  const {
    searchText,
    linkedStatus,
    firmType,
    page,
    perPage,
    orderField,
    orderDirection,
    firmStatus,
  } = getQueryParams(location.search);

  const queryListParams = getListParams(
    pagination ? pagination.totalPages : 1,
    page,
    perPage,
    orderField,
    orderDirection
  );

  React.useEffect(() => {
    actions.fetchCrmIntegration({
      crmIntegrationId: match.params.crmIntegrationId,
    });

    actions.fetchCrmInvestorFirms({
      crmIntegrationId: match.params.crmIntegrationId,
      ...queryListParams,
      searchText,
      linkedStatus,
      firmType,
      firmStatus,
    });
  }, [
    actions,
    match.params.crmIntegrationId,
    queryListParams,
    searchText,
    linkedStatus,
    firmType,
    firmStatus,
  ]);

  const handleChangeFilter = (filters: CrmInvestorFirmsFilters) => {
    history.push({
      pathname: history.location.pathname,
      search: urlUtil.queryStringify({ ...queryListParams, ...filters }),
    });
  };

  const handleChangePage = (params: apiTypes.ListParams) => {
    history.push({
      pathname: history.location.pathname,
      search: urlUtil.queryStringify({
        searchText,
        linkedStatus,
        firmType,
        firmStatus,
        ...params,
      }),
    });
  };

  if (loading && !crmIntegration) {
    return <LoadingOverlay message="Loading..." />;
  }

  if (error) {
    return (
      <Banner variant="error" showIcon={false}>
        <ServerErrors error={error} />
      </Banner>
    );
  }

  const filters = { searchText, linkedStatus, firmType, firmStatus };

  return (
    <React.Fragment>
      <CrmIntegrationDetailHeader />
      <Panel>
        <CrmInvestorFirmList
          crmIntegrationId={match.params.crmIntegrationId}
          loading={loading}
          clients={clientFirms}
          pagination={pagination}
          filters={filters}
          onChangeFilters={handleChangeFilter}
          onChangePage={handleChangePage}
        />
      </Panel>
    </React.Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolodexCrmIntegrationDetailsRouteComponent);
