import {
  FirmCategoryType,
  InvestorFirmAdminRead as CrmInvestorFirm,
} from '@capital-markets-gateway/api-client-rolodex';
import { FlexContainer, Icon, LinkButton, Popover, SolidIconName, timeUtil } from '@cmg/common';
import { ColDef } from 'ag-grid-community';
import isString from 'lodash/isString';
import React from 'react';
import { Link } from 'react-router-dom';

import Status from '../../../../../common/components/indicators/status/Status';
import routeFactory from '../../../../../common/util/routeFactory';
import { ISODateTime, UUID } from '../../../../../types/common';
import {
  firmStatusLabels,
  LinkedStatus,
  linkedStatusLabels,
} from '../../../../../types/domain/entity-matcher/constants';
import { OnPlatformIcon } from '../../../shared/OnPlatformIcon';
import {
  SContent,
  SInfoIconWrapper,
  SSpan,
  SText,
  STextWrapper,
  STopRatingMatchWrapper,
  STopRatingNumber,
} from './CrmInvestorFirmListGridColumns.styles';
import CrmInvestorFirmNotesModal from './CrmInvestorFirmNotesModal';

export const DYNAMIC_COLUMN_MAX_LENGTH = 200;

export const IconPopover: React.FC<{ content: string; name: SolidIconName }> = ({
  content,
  name,
}) => {
  return (
    <Popover trigger="hover" content={content} variant="DARK" placement="top">
      <SInfoIconWrapper>
        <SSpan>
          <Icon name={name} variant="solid" size="sm" color="white" fixedWidth={true} />
        </SSpan>
      </SInfoIconWrapper>
    </Popover>
  );
};

export const RenderIconPopOver: React.FC<{ firmType: FirmCategoryType }> = ({ firmType }) => {
  switch (firmType) {
    case FirmCategoryType.FREE_TEXT:
      return <IconPopover content="Free Text Investor" name="poll-h" />;
    case FirmCategoryType.PWM:
      return <IconPopover content="PWM Investor" name="user-tie" />;
    default:
      return null;
  }
};

export const ClientNameCellRenderer: React.FC<{
  crmIntegrationId: UUID;
  data: CrmInvestorFirm;
}> = ({ crmIntegrationId, data: crmInvestorFirm }) => {
  return (
    <Link
      to={routeFactory.rolodexCrmEntityMatcher.getUrlPath({
        crmIntegrationId: crmIntegrationId,
        crmInvestorFirmId: crmInvestorFirm.id,
      })}
    >
      {crmInvestorFirm.name}
    </Link>
  );
};

export const LinkedCMGFirmNameRenderer: React.FC<{ data: CrmInvestorFirm }> = ({
  data: crmInvestorFirm,
}) => {
  return (
    <FlexContainer direction="row" gap={4} alignItems="center" justifyContent="space-between">
      <FlexContainer direction="row" gap={4} alignItems="center">
        <OnPlatformIcon isOnPlatform={crmInvestorFirm.isOnPlatform} />
        {crmInvestorFirm.rolodexFirmName}
      </FlexContainer>
      {crmInvestorFirm.rolodexFirmCategory && (
        <RenderIconPopOver firmType={crmInvestorFirm.rolodexFirmCategory} />
      )}
    </FlexContainer>
  );
};

export const LinkedStatusCellRenderer: React.FC<{ data: CrmInvestorFirm }> = ({
  data: crmInvestorFirm,
}) => (
  <Status
    isActive={crmInvestorFirm.linkedStatus === LinkedStatus.LINKED}
    activeLabel={linkedStatusLabels[crmInvestorFirm.linkedStatus]}
    inactiveLabel={linkedStatusLabels[crmInvestorFirm.linkedStatus]}
    inactiveIsNeutral={true}
  />
);

export const NotesCellRenderer: React.FC<{ data: CrmInvestorFirm }> = ({
  data: crmInvestorFirm,
}) => {
  const hasMoreOption =
    isString(crmInvestorFirm.notes) && crmInvestorFirm.notes.length > DYNAMIC_COLUMN_MAX_LENGTH;
  const value = hasMoreOption ? `${crmInvestorFirm.notes}...` : crmInvestorFirm.notes;
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

  return (
    <Popover
      visible={isVisible}
      variant="DARK"
      transparentBackground
      disabled={!isString(value)}
      onVisibleChange={value => setIsVisible(value)}
      content={
        <SContent>
          <SText>{value}</SText>
          {hasMoreOption && (
            <React.Fragment>
              <LinkButton
                inline
                onClick={() => {
                  setIsModalVisible(true);
                  setIsVisible(false);
                }}
              >
                More
              </LinkButton>
              <CrmInvestorFirmNotesModal
                investorName={crmInvestorFirm.name}
                notes={crmInvestorFirm.notes}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
              />
            </React.Fragment>
          )}
        </SContent>
      }
    >
      {value ? <STextWrapper>{value}</STextWrapper> : '-'}
    </Popover>
  );
};

export const DateTimeFormat: React.FC<{ datetime: ISODateTime | null }> = ({ datetime }) => (
  <span>{datetime ? timeUtil.formatAsDisplayDateTime(datetime) : null}</span>
);

export const TopMatchRatingCellRenderer: React.FC<{
  data: CrmInvestorFirm;
}> = ({ data: crmInvestorFirm }) => {
  return crmInvestorFirm.matchScore ? (
    <STopRatingMatchWrapper>
      <STopRatingNumber>{crmInvestorFirm.matchScore}%</STopRatingNumber>
    </STopRatingMatchWrapper>
  ) : null;
};

export const createColumns = (crmIntegrationId: UUID): ColDef[] => [
  {
    field: 'firmKey',
    headerName: 'CRM Key',
    minWidth: 150,
  },
  {
    field: 'name',
    headerName: 'CRM Name',
    cellRendererFramework: props => {
      const { data } = props;
      const { firmType } = data;
      return (
        <React.Fragment>
          <ClientNameCellRenderer {...props} crmIntegrationId={crmIntegrationId} />
          <RenderIconPopOver firmType={firmType} />
        </React.Fragment>
      );
    },
    minWidth: 300,
    suppressColumnsToolPanel: true,
  },
  {
    field: 'status',
    headerName: 'CRM Status',
    valueGetter: props => {
      const {
        data: { status },
      } = props;
      return firmStatusLabels[status];
    },
    minWidth: 96,
    sortable: false,
  },
  {
    field: 'rolodexFirmName',
    headerName: 'Linked CMG Org. Name',
    minWidth: 300,
    cellRendererFramework: LinkedCMGFirmNameRenderer,
  },
  {
    field: 'cmgEntityKey',
    headerName: 'Linked CMG Entity ID',
    minWidth: 112,
  },
  {
    field: 'linkedStatus',
    headerName: 'Link Status',
    cellRendererFramework: LinkedStatusCellRenderer,
    minWidth: 102,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    cellRendererFramework: NotesCellRenderer,
    minWidth: 207,
    sortable: false,
  },
  {
    field: 'matchScore',
    headerName: 'Top Match Rating',
    minWidth: 80,
    maxWidth: 180,
    cellRendererFramework: TopMatchRatingCellRenderer,
  },
  {
    field: 'createdOn',
    headerName: 'CRM Creation',
    cellRendererFramework: ({ data: crmInvestorFirm }: { data: CrmInvestorFirm }) => (
      <DateTimeFormat datetime={crmInvestorFirm.createdOn} />
    ),
    minWidth: 134,
  },
  {
    field: 'modifiedOn',
    headerName: 'CRM Update',
    cellRendererFramework: ({ data: crmInvestorFirm }: { data: CrmInvestorFirm }) =>
      crmInvestorFirm.modifiedOn ? (
        <DateTimeFormat datetime={crmInvestorFirm.modifiedOn} />
      ) : (
        <span>Never</span>
      ),
    minWidth: 134,
  },
  {
    field: 'modifiedBySystemOn',
    headerName: 'User Update',
    cellRendererFramework: ({ data: crmInvestorFirm }: { data: CrmInvestorFirm }) => (
      <DateTimeFormat datetime={crmInvestorFirm.modifiedBySystemOn} />
    ),
    minWidth: 134,
  },
  {
    field: 'modifiedBySystemUserName',
    headerName: 'Updated By',
    minWidth: 200,
  },
];
