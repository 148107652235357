import { FC, Fragment } from 'react';

import { DescriptionListSkeleton } from '../../../../design-system/body-sections/description-list/DescriptionListSkeleton';
import { PageGridSection } from '../../../../design-system/body-sections/PageGridSection';
import { IdentityPageContent } from '../../../../design-system/IdentityPageContent';
import { HighlightsSkeleton } from '../../../../design-system/loading/HighlightsSkeleton';

export const AccountUserDetailRouteSkeleton: FC = () => {
  return (
    <IdentityPageContent
      localHighlights={<HighlightsSkeleton aria-label="Loading User Highlights" />}
      gridContent={
        <Fragment>
          <PageGridSection
            title="User Details"
            content={<DescriptionListSkeleton aria-label="Loading User Details" />}
          />
          <PageGridSection
            title="Login Details"
            content={<DescriptionListSkeleton aria-label="Loading Login Details" />}
          />
          <PageGridSection
            size="medium"
            title="Roles"
            content={<DescriptionListSkeleton aria-label="Loading Roles" />}
          />
        </Fragment>
      }
    />
  );
};
