import React from 'react';

import { FirmMatchRow } from '../../../../../../types/domain/firm-match/FirmMatch';
import { sortFirmMathAddressesByHQ } from './AddressCellRenderer.model';

/**
 * Renders a FirmMatch Address (only the first one)
 */
export const AddressCellRenderer: React.FC<{ data: FirmMatchRow }> = ({ data: firmMatch }) => {
  if (!firmMatch.addresses || firmMatch.addresses.length === 0) {
    return <div />;
  }

  // For the CRM Investor Firm Row (aka when firmSource is null),
  // we'll show up to 2 addresses.
  // For all other rows, we'll show one.
  const addressCount = firmMatch.firmSource ? 1 : 2;

  // We want to see always the address from the HQ's first.
  const firmMatchAddresses = sortFirmMathAddressesByHQ(firmMatch.addresses);
  const addressRecords = firmMatchAddresses.slice(0, addressCount);
  return (
    <React.Fragment>
      {addressRecords.map((addressRecord, index) => (
        <div key={index}>
          {addressRecord.address}
          {index === 0 && addressRecords.length > 1 && ';'}
        </div>
      ))}
    </React.Fragment>
  );
};

export default AddressCellRenderer;
