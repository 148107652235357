import { apiTypes } from '@cmg/common';
import { SearchTextField } from '@cmg/design-system';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { FC, Fragment, useEffect } from 'react';

import usePrevious from '../../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../../design-system/alert/ServerErrorAlert';
import { ConfirmationDialog } from '../../../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { FormDialogContent } from '../../../../../../design-system/dialog/FormDialogContent';
import { FormDialogContentItem } from '../../../../../../design-system/dialog/FormDialogContentItem';
import { Role } from '../../../../../../types/domain/role/role';
import { UserRolesUpdate } from '../../../../../../types/domain/user/user';
import { useRolesFilter } from './hooks/useRolesFilter';
import { RolesEditFormValues } from './RolesSectionEditDialog.model';
import { RolesSectionTableEdit } from './RolesSectionTableEdit';

export type Props = Readonly<{
  isOpen: Readonly<boolean>;
  isLoading: Readonly<boolean>;
  error: Readonly<apiTypes.GenericServerError | null>;
  allRoles: readonly Role[];
  defaultSelectedRoleIds: readonly string[];
  onClose: () => void;
  onSubmit: (userRolesUpdate: UserRolesUpdate) => void;
}>;

export const RolesSectionEditDialog: FC<Props> = ({
  isOpen,
  isLoading,
  error,
  allRoles,
  defaultSelectedRoleIds,
  onClose,
  onSubmit,
}) => {
  const formik = useFormik<RolesEditFormValues>({
    enableReinitialize: true,
    initialValues: {
      selectedRoleIds: defaultSelectedRoleIds,
    },
    onSubmit: val => {
      onSubmit({
        roles: val.selectedRoleIds.concat(),
      });
    },
  });

  const { filterText, setFilterText, filteredRoles } = useRolesFilter({
    roles: allRoles,
  });

  const { resetForm } = formik;
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [resetForm, isOpen]);

  const previousIsLoading = usePrevious(isLoading);
  useEffect(() => {
    if (previousIsLoading && !isLoading && !error) {
      onClose();
    }
  }, [error, isLoading, onClose, previousIsLoading]);

  return (
    <FormikProvider value={formik}>
      <ConfirmationDialog
        maxWidth="md"
        isOpen={isOpen}
        isLoading={isLoading}
        title="Edit User Roles"
        cancelButtonLabel="Cancel"
        onCancel={() => onClose()}
        submitButtonLabel="Save"
        submitButtonColor="primary"
        onSubmit={() => formik.handleSubmit()}
        content={
          <Form>
            <FormDialogContent
              error={error && <ServerErrorAlert title="Error" error={error} />}
              items={
                <Fragment>
                  <FormDialogContentItem
                    sm={6}
                    value={
                      <SearchTextField
                        label="Search Roles"
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                      />
                    }
                  />
                  <FieldArray
                    name="selectedRoleIds"
                    render={({ push, remove, form }) => (
                      <FormDialogContentItem
                        value={
                          <RolesSectionTableEdit
                            roles={filteredRoles}
                            selectedRoleIds={form.values.selectedRoleIds}
                            onRoleAdded={push}
                            onRoleRemoved={remove}
                          />
                        }
                      />
                    )}
                  />
                </Fragment>
              }
            />
          </Form>
        }
        fullWidth
        fullHeight
      />
    </FormikProvider>
  );
};
