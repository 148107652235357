import {
  FirmCategoryType,
  InvestorFirmAdminRead as CrmInvestorFirm,
} from '@capital-markets-gateway/api-client-rolodex';
import {
  Banner,
  FormLabel,
  Modal,
  ModalContent,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
  SelectField,
  ServerErrors,
} from '@cmg/common';
import { identitySelectors } from '@cmg/e2e-selectors';
import { Form, FormikProps, validateYupSchema, withFormik, yupToFormErrors } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectModal, hide, InjectedProps as InjectedModalProps, show } from 'redux-modal';
import * as yup from 'yup';

import LoadingIndicator from '../../../../../common/components/indicators/loading-indicator/LoadingIndicator';
import { UUID } from '../../../../../types/common';
import { LinkedStatus } from '../../../../../types/domain/entity-matcher/constants';
import { FirmDataSource } from '../../../../../types/domain/firm/constants';
import { Firm } from '../../../../../types/domain/firm/Firm';
import { fetchMetadata, selectMetadata, selectMetadataLoading } from '../../../shared/ducks';
import {
  fetchLinkTargetFirm,
  resetLinkOrganizationModalState,
  selectLinkTargetFirm,
  selectLinkTargetFirmError,
  selectLinkTargetFirmLoading,
  selectUpdateCrmInvestorFirmLinkError,
  selectUpdateCrmInvestorFirmLinkLoading,
  updateCrmInvestorFirmLink,
  updateCrmInvestorFirmLinkAction,
} from '../../ducks';
import FirmLinkedStatusChange from './FirmLinkedStatusChange';
import {
  SButtonGroup,
  SFlex,
  SLoadingIndicatorInnerWrapper,
  SLoadingIndicatorOuterWrapper,
  SModalContentWrapper,
  SRow,
  StyledCheckboxField,
} from './LinkOrganizationModal.styles';
import * as utils from './utils';

const getLinkOrganizationModalWithFormikSchema = (isEntityTypeRequired: boolean) => {
  return yup.object().shape({
    ...(isEntityTypeRequired
      ? {
          entityType: yup.string().nullable().required('An Entity Type is required'),
        }
      : {
          entityType: yup.string().nullable(),
        }),
    industryType: yup.string().nullable(),
    addInvestorFirmNameToCMGFirm: yup.boolean().nullable(),
  });
};

const mapStateToProps = state => ({
  linkTargetFirm: selectLinkTargetFirm(state),
  linkTargetFirmLoading: selectLinkTargetFirmLoading(state),
  linkTargetFirmError: selectLinkTargetFirmError(state),
  metadata: selectMetadata(state),
  metadataLoading: selectMetadataLoading(state),
  updateCrmInvestorFirmLinkLoading: selectUpdateCrmInvestorFirmLinkLoading(state),
  updateCrmInvestorFirmLinkError: selectUpdateCrmInvestorFirmLinkError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchMetadata,
      fetchLinkTargetFirm,
      updateCrmInvestorFirmLink,
      resetLinkOrganizationModalState,
    },
    dispatch
  ),
});

export type Values = {
  entityType: string | null;
  industryType: string | null;
  firmType: FirmCategoryType | null;
  addInvestorFirmNameToCMGFirm: boolean;
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  /**
   *  The ID of the CRM Integration that the Investor Firm belongs to.
   */
  crmIntegrationId: string;
  /**
   * The CRM Investor Firm being linked
   */
  crmInvestorFirm: CrmInvestorFirm;
} & (
  | {
      /**
       * The ID of the Firm the CRM Investor Firm is to be linked to
       * (either a CMG Firm or FactSet ID).
       */
      linkTargetFirmId: UUID | string;
      /**
       * The source of the Firm to be linked (either CMG or FactSet)
       */
      linkTargetFirmSource: FirmDataSource;
    }
  | { linkTargetFirmId?: null; linkTargetFirmSource?: null }
);
export type OuterProps = OwnProps & StateProps & DispatchProps & InjectedModalProps;
export type Props = OuterProps & FormikProps<Values>;

export const validate = async (values: Values, { linkTargetFirm }: OuterProps) => {
  // If the link target firm's entity type isn't set,
  // a value for entity type must be supplied - so this
  // field should be required.
  const isEntityTypeRequired = !!linkTargetFirm && !linkTargetFirm.details.entityType;
  try {
    const validationSchema = getLinkOrganizationModalWithFormikSchema(isEntityTypeRequired);
    await validateYupSchema(values, validationSchema, true);
  } catch (err) {
    return yupToFormErrors(err);
  }

  return {};
};

/**
 * A modal that allows management of CRM Investor Firm
 * links to CMG Firms
 */
export const LinkOrganizationModalComponent: React.FC<Props> = ({
  show,
  handleHide,
  crmInvestorFirm,
  linkTargetFirm,
  linkTargetFirmLoading,
  linkTargetFirmError,
  linkTargetFirmId,
  linkTargetFirmSource,
  isValid,
  metadata,
  metadataLoading,
  updateCrmInvestorFirmLinkLoading,
  updateCrmInvestorFirmLinkError,
  actions,
}) => {
  React.useEffect(() => {
    // If entity types or industry types are empty, we can assume
    // that metadata information has not been loaded yet.  Metadata
    // will likely be loaded at this point, but we'll check to be sure.
    (!metadata.entityTypes.length || !metadata.industryTypes.length) && actions.fetchMetadata();

    return () => {
      actions.resetLinkOrganizationModalState();
    };
  }, [actions, metadata.entityTypes, metadata.industryTypes]);

  React.useEffect(() => {
    if (linkTargetFirmId && linkTargetFirmSource) {
      actions.fetchLinkTargetFirm({ linkTargetFirmId, linkTargetFirmSource });
    }
  }, [actions, linkTargetFirmId, linkTargetFirmSource]);

  const isInvestorFirmLinked =
    crmInvestorFirm && crmInvestorFirm.linkedStatus === LinkedStatus.LINKED;
  const linkTargetDisplayName = linkTargetFirm ? utils.getDisplayName(linkTargetFirm) : null;
  const firm = linkTargetFirm as Firm;

  if (linkTargetFirmLoading) {
    return (
      <Modal show={show} onHide={handleHide}>
        <LoadingIndicator />
      </Modal>
    );
  }

  return (
    <Modal
      title={linkTargetFirm ? 'Link CRM Organization' : 'Unlink CRM Organization'}
      show={show}
      onHide={handleHide}
    >
      {firm && firm.isFirmMappedToCmgAccount && (
        <Banner variant="warning">
          This CMG Entity is linked to a CMG Account. Once linked, it cannot be revoked.
        </Banner>
      )}
      {(linkTargetFirmError || updateCrmInvestorFirmLinkError) && (
        <Banner variant="error" showIcon={false}>
          {updateCrmInvestorFirmLinkError && (
            <ServerErrors error={updateCrmInvestorFirmLinkError} />
          )}
          {linkTargetFirmError && <ServerErrors error={linkTargetFirmError} />}
        </Banner>
      )}
      {!linkTargetFirmError && (
        <Form>
          <ModalContent>
            <SModalContentWrapper>
              {crmInvestorFirm && (
                <React.Fragment>
                  {isInvestorFirmLinked && crmInvestorFirm.rolodexFirmName && (
                    <FirmLinkedStatusChange
                      description="Are you sure you want to unlink the following organizations:"
                      from={crmInvestorFirm.name}
                      to={crmInvestorFirm.rolodexFirmName!}
                      pendingLinkedStatus={LinkedStatus.NOT_LINKED}
                    />
                  )}
                  {linkTargetFirm && (
                    <React.Fragment>
                      <FirmLinkedStatusChange
                        description={
                          isInvestorFirmLinked
                            ? 'And link the following organizations:'
                            : 'Are you sure you want to link the following organizations:'
                        }
                        from={crmInvestorFirm.name || crmInvestorFirm.id}
                        to={linkTargetDisplayName!}
                        pendingLinkedStatus={LinkedStatus.LINKED}
                      />
                      {!utils.doesFirmHaveInvestorRole(linkTargetFirm) && (
                        <p>
                          Linking these organizations will add the <b>Investor Role</b> to{' '}
                          <b>{linkTargetDisplayName}</b>
                        </p>
                      )}
                      {!linkTargetFirm.details.entityType && (
                        <div>
                          <p>
                            Linking these organizations requires an <b>Entity Type</b> to be added
                            to <b>{linkTargetDisplayName}</b>. Consider adding an{' '}
                            <b>Industry Type</b> (optional), as well.
                          </p>
                          <SRow>
                            <SFlex>
                              <FormLabel required={true}>Entity Type</FormLabel>
                              <SelectField
                                name="entityType"
                                disabled={metadataLoading}
                                options={metadata.entityTypes}
                                maxMenuHeight={110}
                                placeholder={
                                  metadataLoading ? 'Loading...' : 'Select Entity Type...'
                                }
                              />
                            </SFlex>
                            {!linkTargetFirm.details.industryType && (
                              <SFlex>
                                <FormLabel>Industry Type</FormLabel>
                                <SelectField
                                  name="industryType"
                                  disabled={metadataLoading}
                                  options={metadata.industryTypes}
                                  placeholder={
                                    metadataLoading ? 'Loading...' : 'Select Industry Type...'
                                  }
                                  maxMenuHeight={110}
                                />
                              </SFlex>
                            )}
                          </SRow>
                        </div>
                      )}
                      {!utils.doesInvestorFirmNameExistInLinkTargetFirm(
                        crmInvestorFirm,
                        linkTargetFirm
                      ) && (
                        <SRow>
                          <StyledCheckboxField name="addInvestorFirmNameToCMGFirm">
                            <span>
                              <b>{crmInvestorFirm.name}</b> does not exist as a name record in{' '}
                              <b>{linkTargetDisplayName}</b>. Would you like to add it?
                            </span>
                          </StyledCheckboxField>
                        </SRow>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </SModalContentWrapper>
          </ModalContent>
          <ModalFooter>
            <SButtonGroup>
              {updateCrmInvestorFirmLinkLoading ? (
                <SLoadingIndicatorOuterWrapper>
                  <SLoadingIndicatorInnerWrapper>
                    <LoadingIndicator />
                  </SLoadingIndicatorInnerWrapper>
                </SLoadingIndicatorOuterWrapper>
              ) : (
                <React.Fragment>
                  <SecondaryButton onClick={handleHide}>Cancel</SecondaryButton>
                  <PrimaryButton
                    disabled={!isValid}
                    testId={
                      identitySelectors.rolodex.crmMatchListLinkOrganizationModalLinkButton.testId
                    }
                    type="submit"
                  >
                    {linkTargetFirm ? 'Link' : 'Unlink'}
                  </PrimaryButton>
                  {/*
                  TODO: ECM-4071
                  <PrimaryButton
                    disabled={!isValid}
                    testId={
                      identitySelectors.rolodex
                        .crmMatchListLinkOrganizationModalLinkAndReturnToInvestorFirmsListButton
                        .testId
                    }
                    type="submit"
                  >
                    {linkTargetFirm ? 'Link and Return To List' : 'Unlink and Return To List'}
                  </PrimaryButton> */}
                </React.Fragment>
              )}
            </SButtonGroup>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

export const LinkOrganizationModalWithFormik = withFormik<OuterProps, Values>({
  enableReinitialize: true,
  // If a link target firm is provided and the link target firm does
  // not have an entity type, the form is invalid initially.
  // Otherwise, it is valid.
  isInitialValid: ({ linkTargetFirm }) =>
    linkTargetFirm ? !!linkTargetFirm.details.entityType : true,
  handleSubmit: (values, formikBag) => {
    const payload: updateCrmInvestorFirmLinkAction['payload'] = {
      crmIntegrationId: formikBag.props.crmIntegrationId,
      crmInvestorFirmId: formikBag.props.crmInvestorFirm.id,
      ...(formikBag.props.linkTargetFirmId && formikBag.props.linkTargetFirmSource
        ? {
            linkTargetFirmId: formikBag.props.linkTargetFirmId,
            linkTargetFirmSource: formikBag.props.linkTargetFirmSource,
          }
        : {}),
      addInvestorRoleToCMGFirm: formikBag.props.linkTargetFirm
        ? !utils.doesFirmHaveInvestorRole(formikBag.props.linkTargetFirm)
        : false,
      addInvestorFirmNameToCMGFirmValue:
        values.addInvestorFirmNameToCMGFirm && formikBag.props.crmInvestorFirm
          ? formikBag.props.crmInvestorFirm.name
          : null,
      // Detect if the entity type and industry type values have changed.
      // If not, they should be null.
      firmType:
        formikBag.props.linkTargetFirm &&
        formikBag.props.linkTargetFirm.details.firmCategoryType === values.entityType
          ? null
          : values.firmType,
      entityType:
        formikBag.props.linkTargetFirm &&
        formikBag.props.linkTargetFirm.details.entityType === values.entityType
          ? null
          : values.entityType,
      industryType:
        formikBag.props.linkTargetFirm &&
        formikBag.props.linkTargetFirm.details.industryType === values.industryType
          ? null
          : values.industryType,
    };

    formikBag.props.actions.updateCrmInvestorFirmLink(payload);
  },
  mapPropsToValues: ({ linkTargetFirm, crmInvestorFirm }) => {
    // Default addInvestorFirmNameToCMGFirm to true if
    // the option to add the investor firm name exists.
    const addInvestorFirmNameToCMGFirm =
      !!crmInvestorFirm &&
      !!linkTargetFirm &&
      !utils.doesInvestorFirmNameExistInLinkTargetFirm(crmInvestorFirm, linkTargetFirm);

    return {
      entityType: linkTargetFirm ? linkTargetFirm.details.entityType : null,
      industryType: linkTargetFirm ? linkTargetFirm.details.industryType : null,
      firmType: linkTargetFirm ? linkTargetFirm.details.firmCategoryType : null,
      addInvestorFirmNameToCMGFirm,
    };
  },
  validate,
})(LinkOrganizationModalComponent);

const name = 'ROLODEX/CRM_INVESTOR_FIRM_DETAILS/LINK_ORGANIZATION_MODAL';
export const openLinkOrganizationModal = (
  params?:
    | { linkTargetFirmId: UUID | string; linkTargetFirmSource: FirmDataSource }
    | { linkTargetFirmId?: null; linkTargetFirmSource?: null }
) => show(name, params);
export const closeLinkOrganizationModal = () => hide(name);

const LinkOrganizationModal = connectModal({ name: name })(LinkOrganizationModalWithFormik);

export default connect<
  StateProps,
  DispatchProps,
  { crmIntegrationId: UUID; crmInvestorFirm: CrmInvestorFirm }
>(
  mapStateToProps,
  mapDispatchToProps
)(LinkOrganizationModal);
