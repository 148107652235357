import React from 'react';

import { FirmMatchRow } from '../../../../../../types/domain/firm-match/FirmMatch';
import { firmMatchSourceLabels } from '../../../../../../types/domain/firm-match/FirmMatchSource';

/**
 * Renders firmSource label or 'CRM' depending on type of row
 */
export const OriginCellRenderer: React.FC<{ data: FirmMatchRow }> = ({ data: firmMatch }) => {
  // if this row is crmInvestorFirm, then display CRM
  // otherwise render firm source label
  return (
    <React.Fragment>
      {firmMatch.firmSource ? firmMatchSourceLabels[firmMatch.firmSource] : 'CRM'}
    </React.Fragment>
  );
};

export default OriginCellRenderer;
