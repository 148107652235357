import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@cmg/design-system';
import { VFC } from 'react';

import { Role } from '../../../../../../types/domain/role/role';
import UserRole from '../../../../../../types/domain/role/UserRole';

export type Props = Readonly<{
  userRoles: readonly UserRole[];
  allRoles: readonly Role[];
}>;

export const RolesSectionTable: VFC<Props> = ({ allRoles, userRoles }) => {
  const selectedRoles = allRoles.filter(role =>
    userRoles.some(userRole => userRole.id === role.id)
  );

  return (
    <TableContainer>
      <Table aria-label="User Roles">
        <TableHead>
          <TableRow>
            <TableCell>Roles</TableCell>
            <TableCell>Permissions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedRoles.map(selectedRole => (
            <TableRow key={selectedRole.id}>
              <TableCell>{selectedRole.name}</TableCell>
              <TableCell>{selectedRole.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
