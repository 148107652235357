import {
  InvestorCoverageRead,
  PaginationMetadata,
} from '@capital-markets-gateway/api-client-rolodex';
import { apiTypes } from '@cmg/common';
import { FC, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { InvestorCoverageFilters } from '../../../../common/api/rolodexApiClient';
import { StyledGrid } from './InvestorCoverageGrid.styles';
import columns from './InvestorCoverageGridColumns';
import { InvestorCoverageGridFilters } from './InvestorCoverageGridFilters';

type Props = {
  investorCoverage: readonly InvestorCoverageRead[];
  pagination: Readonly<PaginationMetadata>;
  onChangePage: (params: apiTypes.ListParams) => void;
  filters: Readonly<InvestorCoverageFilters>;
  onChangeFilters: (params: InvestorCoverageFilters, debounce?: boolean) => void;
  loading: Readonly<boolean>;
};

export const InvestorCoverageGrid: FC<Props> = ({
  investorCoverage,
  pagination,
  onChangePage,
  onChangeFilters,
  filters,
  loading,
}) => {
  const investorCoverageWithIds = useMemo(() => {
    /**
     * Investor Coverage does not have the id in the back end
     * and it is needed for the grid to work properly
     */
    return investorCoverage.map(coverage => ({ id: uuidv4(), ...coverage }));
  }, [investorCoverage]);

  return (
    <StyledGrid
      renderFilters={() => (
        <InvestorCoverageGridFilters filters={filters} onChangeFilters={onChangeFilters} />
      )}
      extended={{ withMargin: false }}
      columns={columns}
      rows={investorCoverageWithIds}
      resizeBy="text"
      totalPages={pagination.totalPages!}
      pagination={{
        page: pagination.activePage!,
        perPage: pagination.perPage!,
      }}
      onPaginationChange={({ page, perPage, orderField, orderDirection }) => {
        onChangePage({
          page,
          perPage,
          orderField,
          orderDirection:
            orderDirection === 'asc' ? apiTypes.SortDirection.ASC : apiTypes.SortDirection.DESC,
        });
      }}
      gridOptions={{
        suppressCellSelection: true,
        suppressRowClickSelection: true,
        overlayNoRowsTemplate: 'No firms found',
        rowHeight: 50,
      }}
      loading={loading}
    />
  );
};
