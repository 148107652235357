import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as yup from 'yup';

import { getFirmAddressColumns } from '../../../../../common/components/layout/firm-record-panel/firmAddressColumns';
import FirmRecordPanel from '../../../../../common/components/layout/firm-record-panel/GenericFirmRecordPanel';
import { UUID } from '../../../../../types/common';
import { FirmAddress } from '../../../../../types/domain/firm/FirmAddress';
import {
  cancelEditRecord,
  createFirmAddress,
  editRecord,
  FirmDetailSection,
  selectEditingRecordIds,
  selectFirmAddresses,
  selectFirmAddressesError,
  selectUpdatingRecordIds,
  updateFirmAddress,
} from '../../ducks';

const mapStateToProps = state => ({
  addresses: selectFirmAddresses(state),
  error: selectFirmAddressesError(state),
  updatingRecordIds: selectUpdatingRecordIds(state),
  editingRecordIds: selectEditingRecordIds(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createFirmAddress,
      updateFirmAddress,
      editRecord,
      cancelEditRecord,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  firmId: UUID;
};
export type Props = OwnProps & StateProps & DispatchProps;

export const FirmAddressesValidationSchema = yup.object().shape({
  type: yup.string().nullable().required('An address type is required'),
  streetLine1: yup.string().nullable().required('A street address is required'),
  streetLine2: yup.string().nullable(),
  streetLine3: yup.string().nullable(),
  city: yup.string().nullable().required('A city is required'),
  state: yup.string().nullable().required('A state is required'),
  postalCode: yup.string().nullable().required('A postal code is required'),
  country: yup.string().nullable().required('A country is required'),
  phoneNumber: yup.string().nullable(),
  faxNumber: yup.string().nullable(),
  recordStatus: yup.string().nullable().required('A status type is required'),
});

export const FirmAddressesRecordPanelComponent: React.FC<Props> = ({
  firmId,
  error,
  editingRecordIds,
  updatingRecordIds,
  addresses,
  actions,
}) => {
  return (
    <FirmRecordPanel<FirmAddress>
      sectionId={FirmDetailSection.ADDRESSES}
      title="Addresses"
      addRecordButtonLabel="Add Address"
      error={error}
      editingRecordIds={editingRecordIds}
      updatingRecordIds={updatingRecordIds}
      onCreate={address => actions.createFirmAddress({ firmId, address })}
      onUpdate={address => actions.updateFirmAddress({ firmId, address })}
      onEdit={recordId => actions.editRecord({ recordId })}
      onCancelEdit={recordId => actions.cancelEditRecord({ recordId })}
      columns={getFirmAddressColumns()}
      rows={addresses}
      validationSchema={FirmAddressesValidationSchema}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmAddressesRecordPanelComponent);
