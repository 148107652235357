import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { PageLayout } from '@cmg/design-system';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { bindActionCreators } from 'redux';

import routeFactory from '../../../common/util/routeFactory';
import { ServerErrorAlert } from '../../../design-system/alert/ServerErrorAlert';
import { UUID } from '../../../types/common';
import {
  fetchAccount,
  selectAccount,
  selectError,
  selectLoading,
} from '../../account-detail/shared/ducks';
import { selectSelfSubdomain } from '../../shared/ducks';
import { fetchCrmIntegration, selectCrmIntegration } from '../shared/ducks';
import CRMFilesUploadRoute from './crm-files-upload/CRMFilesUploadRoute';
import { CRMManagementDomainObject } from './CRMManagementDomainObject';
import { CRMManagementRouteSkeleton } from './CRMManagementRouteSkeleton';

const mapStateToProps = state => ({
  account: selectAccount(state),
  loading: selectLoading(state),
  error: selectError(state),
  selfSubdomain: selectSelfSubdomain(state),
  crmIntegration: selectCrmIntegration(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchAccount,
      fetchCrmIntegration,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type RouteProps = RouteComponentProps<{ crmIntegrationId?: UUID }>;

type Props = StateProps & DispatchProps & RouteProps;
/**
 * Entry point for accessing CRM Management. All routes associated with viewing/managing crm data stem from here.
 */
export const CRMManagementRoute: React.FC<Props> = ({
  account,
  actions,
  error,
  crmIntegration,
  loading,
  match,
  selfSubdomain,
}) => {
  useEffect(() => {
    async function fetchCrmIntegrationAndAccount() {
      if (match.params.crmIntegrationId) {
        await actions.fetchCrmIntegration({ crmIntegrationId: match.params.crmIntegrationId });
      }
      if (selfSubdomain !== 'system') {
        actions.fetchAccount(selfSubdomain);
      }
    }

    void fetchCrmIntegrationAndAccount();
  }, [actions, match.params.crmIntegrationId, selfSubdomain]);

  const hasFullCRMAccess = useCheckPermissions([permissionsByEntity.CRM.FULL]);
  const hasFullGlobalCrmAccess = useCheckPermissions([permissionsByEntity.GlobalCrm.FULL]);

  if (loading) {
    return <CRMManagementRouteSkeleton />;
  }

  if (error) {
    return <ServerErrorAlert error={error} title="Error" />;
  }

  if (selfSubdomain !== 'system' && !account) {
    return null;
  }

  if (selfSubdomain === 'system' && !crmIntegration) {
    return null;
  }

  return (
    <PageLayout header={<CRMManagementDomainObject />}>
      <Switch>
        {hasFullCRMAccess && (
          <React.Fragment>
            <Route
              exact
              path={routeFactory.customerCrmFilesUpload.routePath}
              component={CRMFilesUploadRoute}
            />
            {/* Default to customer crm file uploads when no other routes match */}
            <Route
              render={() => <Redirect to={routeFactory.customerCrmFilesUpload.getUrlPath()} />}
            />
          </React.Fragment>
        )}
        {hasFullGlobalCrmAccess && (
          <React.Fragment>
            <Route
              exact
              path={routeFactory.sysAdminCrmFilesUpload.routePath}
              component={CRMFilesUploadRoute}
            />
            {/* Default to system admin crm file uploads when no other routes match */}
            <Route
              render={() => (
                <Redirect to={routeFactory.sysAdminCrmFilesUpload.getUrlPath(match.params)} />
              )}
            />
          </React.Fragment>
        )}
      </Switch>
    </PageLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CRMManagementRoute);
