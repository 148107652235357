import styled from 'styled-components/macro';

export const STopRatingMatchWrapper = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
`;

export const STopRatingNumber = styled.div`
  margin-left: 8px;
`;

export const SInfoIconWrapper = styled.div`
  display: inline;
  margin: 0 5px;
  float: right;
`;

export const SSpan = styled.span`
  background-color: ${({ theme }) => theme.background.color.dark};
  padding: 2px 3px 2px 3px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  border-radius: 5px;
`;

export const SHeaderDiv = styled.div`
  background-color: ${({ theme }) => theme.background.color.dark};
  padding: 2px 3px 2px 3px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  border-radius: 5px;
  display: inline-block;
  width: 17px;
  height: 18px;
`;

export const STextWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  text-decoration: underline dotted;
`;

export const SContent = styled.div`
  display: block;
  white-space: pre-wrap;
`;

export const SText = styled.div`
  max-width: 500px;
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
