import { Grid } from '@cmg/design-system';
import { FC, ReactNode } from 'react';

export type DescriptionListItemProps = {
  label: Readonly<ReactNode>;
  value: Readonly<ReactNode>;
};

export const DescriptionListItem: FC<DescriptionListItemProps> = ({ label, value }) => {
  return (
    <Grid item xs={12} paddingY={1}>
      <Grid container item alignItems="baseline" columnSpacing={2} role="row">
        <Grid
          item
          xs={5}
          role="rowheader"
          typography={theme => ({ color: theme.palette.text.secondary })}
        >
          {label}
        </Grid>
        <Grid item xs={7} role="cell" typography={theme => ({ color: theme.palette.text.primary })}>
          {value}
        </Grid>
      </Grid>
    </Grid>
  );
};
