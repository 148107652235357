import { Button } from '@cmg/design-system';
import React, { Fragment } from 'react';

export type Props = Readonly<{
  readonly submitting: boolean;
  readonly handleRedirectToList: () => void;
}>;

export const SectionActions: React.FC<Props> = ({ submitting, handleRedirectToList }) => {
  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        disabled={submitting}
        onClick={handleRedirectToList}
      >
        Cancel
      </Button>
      <Button variant="contained" color="primary" disabled={submitting} type="submit">
        Generate Key
      </Button>
    </Fragment>
  );
};
