import { CrmLinkedStatus } from '@capital-markets-gateway/api-client-identity';
import { checkPermissions, permissionsByEntity } from '@cmg/auth';
import { apiTypes } from '@cmg/common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { InvestorCoverageFilters } from '../../../../../common/api/rolodexApiClient';
import { User } from '../../../../../types/domain/user/user';
import { fetchEmployeeInvestorCoverage } from '../../../../account-detail/user-detail/ducks';

export type UseEmployeeInvestorCoverageFetchProps = Readonly<{
  canViewCoverage: Readonly<boolean>;
  user: Readonly<User | null>;
  onFetchEmployeeInvestorCoverage: typeof fetchEmployeeInvestorCoverage;
}>;

export type UseEmployeeInvestorCoverageFetchResult = {
  investorCoverageFilters: InvestorCoverageFilters;
  onCoverageFiltersChange: (filters: InvestorCoverageFilters, debounce?: boolean) => void;
  onCoveragePaginationChange: (pagination: apiTypes.ListParams) => void;
};

export function useEmployeeInvestorCoverageFetch({
  canViewCoverage,
  user,
  onFetchEmployeeInvestorCoverage,
}: UseEmployeeInvestorCoverageFetchProps): UseEmployeeInvestorCoverageFetchResult {
  const [investorCoverageFilters, setInvestorCoverageFilters] = useState<InvestorCoverageFilters>(
    {}
  );

  const selectedUserHasPermissions = useMemo(() => {
    return checkPermissions(user?.roles?.flatMap(({ permissions }) => permissions) ?? [], [
      permissionsByEntity.CoveredAccountsCrm.READ,
    ]);
  }, [user?.roles]);

  const isUserConfigured = user?.configurationStatus === CrmLinkedStatus.Configured;

  const { employeeKey } = user ?? {};
  const fetchCoverage = useCallback(
    (filters?: InvestorCoverageFilters, pagination?: apiTypes.ListParams) => {
      if (!employeeKey || !isUserConfigured) {
        return;
      }

      onFetchEmployeeInvestorCoverage({
        employeeKeyOrId: employeeKey,
        hasPermission: selectedUserHasPermissions && canViewCoverage,
        page: 1,
        ...pagination,
        ...filters,
      });
    },
    [
      canViewCoverage,
      isUserConfigured,
      onFetchEmployeeInvestorCoverage,
      selectedUserHasPermissions,
      employeeKey,
    ]
  );

  const debouncedFetchCoverage = useDebouncedCallback(fetchCoverage, 300);

  const onCoverageFiltersChange = useCallback(
    (filters: InvestorCoverageFilters, debounce?: boolean) => {
      setInvestorCoverageFilters(filters);
      if (debounce) {
        debouncedFetchCoverage(filters);
      } else {
        fetchCoverage(filters);
      }
    },
    [debouncedFetchCoverage, fetchCoverage]
  );

  const onCoveragePaginationChange = useCallback(
    (pagination: apiTypes.ListParams) => {
      fetchCoverage(investorCoverageFilters, pagination);
    },
    [fetchCoverage, investorCoverageFilters]
  );

  useEffect(() => {
    fetchCoverage();
  }, [fetchCoverage]);

  return {
    investorCoverageFilters,
    onCoverageFiltersChange,
    onCoveragePaginationChange,
  };
}
