import { Modal } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import { UUID } from '../../../../../types/common';
import {
  fetchFactSetFirmHierarchy,
  resetConnectWithFactSetModalState,
  selectConnectFactSetFirmPreviewDiff,
  selectFactSetFirmHierarchy,
} from '../../ducks';
import ChooseFactSetFirm from './ChooseFactSetFirm';
import ConnectFactSetFirmWithCMGFirm from './ConnectFactSetFirmWithCMGFirm';
import { getFirmFromFactSetHierarchy } from './getFirmFromFactSetHierarchy';

const mapStateToProps = state => ({
  factSetFirmHierarchy: selectFactSetFirmHierarchy(state),
  connectFirmPreview: selectConnectFactSetFirmPreviewDiff(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchFactSetFirmHierarchy,
      resetConnectWithFactSetModalState,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  /** The ID of the CMG Firm that is to be linked. */
  firmId: UUID;
};
type Props = OwnProps & InjectedProps & StateProps & DispatchProps;

/**
 * Displays the Connect With FactSet Modal experience.  Is triggered to
 * show via a button that appears on the firm details page of CMG firms
 * that aren't connected to a FactSet firm.
 *
 * First, the user must search and select the FactSet firm to link/connect
 * the firm on screen to.  Second, the user reviews the incoming changes from
 * FactSet and confirms the connection.
 */
export const ConnectWithFactSetModalComponent: React.FC<Props> = ({
  show,
  handleHide,
  firmId,
  factSetFirmHierarchy,
  connectFirmPreview,
  actions,
}) => {
  const [selectedFactSetFirmId, setSelectedFactSetFirmId] = React.useState<string | null>(null);

  const selectedFactSetFirm = getFirmFromFactSetHierarchy(
    selectedFactSetFirmId,
    factSetFirmHierarchy
  );

  const handleCancel = () => {
    actions.resetConnectWithFactSetModalState();
    handleHide();
  };

  return (
    <Modal title="Connect to FactSet Organization" size="large" show={show} onHide={handleCancel}>
      {!connectFirmPreview && (
        <ChooseFactSetFirm
          selectedFirmId={firmId}
          selectedFactSetFirmId={selectedFactSetFirmId}
          onChange={id => setSelectedFactSetFirmId(id)}
          onCancel={handleCancel}
        />
      )}
      {selectedFactSetFirm && connectFirmPreview && (
        <ConnectFactSetFirmWithCMGFirm
          connectFirmPreview={connectFirmPreview}
          selectedFactSetFirm={selectedFactSetFirm}
          cmgFirmId={firmId}
          onCancel={handleCancel}
        />
      )}
    </Modal>
  );
};

const ConnectedConnectWithFactSetModalComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectWithFactSetModalComponent);

const modalName = 'ROLODEX/FIRM/CONNECT_WITH_FACTSET';

export default connectModal({ name: modalName })(ConnectedConnectWithFactSetModalComponent);

export const openConnectWithFactSetModal = () => show(modalName);
export const closeConnectWithFactSetModal = () => hide(modalName);
