import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

export const ReactRouterMUILink = React.forwardRef<
  HTMLAnchorElement,
  Omit<LinkProps, 'to'> & { href: LinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;

  if (/^https?:\/\//i.test(href)) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a ref={ref} href={href} {...other} />;
  }

  return <Link ref={ref} to={href} {...other} />;
});
