import { apiTypes, duckPartFactory } from '@cmg/common';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as accountApiClient from '../../../common/api/accountApiClient';
import systemManagementApiClient, {
  GetAccountRoleResponse,
  GetAccountRoleUsersParams,
  GetAccountRoleUsersResponse,
} from '../../../common/api/systemManagementApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { Role } from '../../../types/domain/role/role';
import UserBasic from '../../../types/domain/user/UserBasic';
import { selectSelfSubdomain } from '../../shared/ducks';
import { selectAccountSubdomain } from '../shared/ducks';

export const fetchRoleUsersListDuckParts = duckPartFactory.makeAPIDuckParts<
  GetAccountRoleUsersParams & { roleId: string },
  {
    users: UserBasic[];
    pagination: apiTypes.Pagination;
  }
>({
  prefix: 'global_management/ACCOUNT_ROLE_USER_LIST',
});

export const fetchAccountRoleUserList = fetchRoleUsersListDuckParts.actionCreators.request;
type fetchAccountRoleUserListAction = ReturnType<typeof fetchAccountRoleUserList>;

export const fetchAccountRoleDuckParts = duckPartFactory.makeAPIDuckParts<
  { roleId: string },
  {
    role: Role;
  }
>({
  prefix: 'global_management/FETCH_ACCOUNT_ROLE',
});

export const fetchAccountRole = fetchAccountRoleDuckParts.actionCreators.request;
type FetchRoleAction = ReturnType<typeof fetchAccountRole>;

/**
 * REDUCERS
 */
export const initialState = {
  role: fetchAccountRoleDuckParts.initialState,
  users: fetchRoleUsersListDuckParts.initialState,
};

export type ReducerState = typeof initialState;

export default combineReducers<ReducerState>({
  role: fetchAccountRoleDuckParts.reducer,
  users: fetchRoleUsersListDuckParts.reducer,
});

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.adminAccountRoleDetail;
const fetchAccountRoleSelectors = fetchAccountRoleDuckParts.makeSelectors(
  state => selectState(state).role
);
export const selectRoleData = fetchAccountRoleSelectors.selectData;
export const selectRole = state => (selectRoleData(state) ? selectRoleData(state)!.role : null);
export const selectLoading = fetchAccountRoleSelectors.selectLoading;
export const selectError = fetchAccountRoleSelectors.selectError;
const fetchAccountRoleUserListSelectors = fetchRoleUsersListDuckParts.makeSelectors(
  state => selectState(state).users
);
export const selectRoleUserList = fetchAccountRoleUserListSelectors.selectData;
export const selectRoleUserListLoading = fetchAccountRoleUserListSelectors.selectLoading;
export const selectRoleUserListError = fetchAccountRoleUserListSelectors.selectError;

/**
 * SAGAS
 */
export function* fetchAccountRoleSaga({ payload }: FetchRoleAction): SagaIterator {
  const { roleId } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: accountApiClient.GetMyAccountRoleResponse | GetAccountRoleResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyAccountRole, roleId);
  } else {
    response = yield call(systemManagementApiClient.admin.getAccountRole, accountSubdomain, roleId);
  }

  if (response.ok) {
    yield put(fetchAccountRoleDuckParts.actionCreators.success({ role: response.data }));
  } else {
    yield put(fetchAccountRoleDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* fetchAccountRoleUserListSaga({
  payload,
}: fetchAccountRoleUserListAction): SagaIterator {
  const { roleId, ...params } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: GetAccountRoleUsersResponse | accountApiClient.GetMyAccountRoleUsersResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyAccountRoleUsers, roleId, params);
  } else {
    response = yield call(
      systemManagementApiClient.admin.getAccountRoleUsers,
      accountSubdomain,
      roleId,
      params
    );
  }

  if (response.ok) {
    const { data: users, pagination } = response.data;
    yield put(fetchRoleUsersListDuckParts.actionCreators.success({ users, pagination }));
  } else {
    yield put(
      fetchRoleUsersListDuckParts.actionCreators.failure(
        response.data
          ? response.data.error
          : {
              code: '',
              message: 'Account users could not be loaded.',
              target: '',
              details: [],
            }
      )
    );
  }
}

export function* adminAccountRoleDetailSaga() {
  yield takeLatest<FetchRoleAction>(
    fetchAccountRoleDuckParts.actionTypes.REQUEST,
    fetchAccountRoleSaga
  );
  yield takeLatest<fetchAccountRoleUserListAction>(
    fetchRoleUsersListDuckParts.actionTypes.REQUEST,
    fetchAccountRoleUserListSaga
  );
}
