import styled from 'styled-components/macro';

export const STitleContainer = styled.div`
  margin: 0px 10px 0px 10px;
`;

export const SFooterContainer = styled.div`
  margin-right: 15px;
`;

export const SModalBodyContainer = styled.div`
  margin: 0px 10px 0px 10px;
  height: 100%;
  width: auto;
  overflow-y: auto;
  max-height: 50vh;
  white-space: pre-wrap;
`;

export const STitleCategory = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.text.color.light};
  font-size: ${({ theme }) => theme.text.size.small};
`;
