import React from 'react';

import { firmRoleTypeLabels, firmRoleTypeOptions } from '../../../../types/domain/firm/constants';
import {
  recordStatusLabels,
  recordStatusOptions,
} from '../../../../types/domain/record-status/constants';
import { StyledSelectField } from './inputs/RecordInputs.styles';

/**
 * The column configuration for the Firm Roles Record Table
 */
export const getFirmRoleColumns = () => [
  {
    field: 'type',
    headerName: 'Type',
    editCellRendererFramework: (row, disabled) => (
      <StyledSelectField name="type" options={firmRoleTypeOptions} disabled={disabled} />
    ),
    cellRendererFramework: row => <span>{firmRoleTypeLabels[row.type]}</span>,
  },
  {
    field: 'recordStatus',
    headerName: 'Status',
    editCellRendererFramework: (row, disabled) => (
      <StyledSelectField name="recordStatus" options={recordStatusOptions} disabled={disabled} />
    ),
    cellRendererFramework: row => recordStatusLabels[row.recordStatus],
  },
];
