import { CenteredPage, PrimaryButton } from '@cmg/common';
import { backToLoginButtonSelector, loggedOutScreenSelector } from '@cmg/e2e-selectors';
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import routeFactory from '../../../common/util/routeFactory';

export const SMessage = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  margin: 15px 0 40px 0;
`;
export const SHeading = styled.div`
  font-size: ${({ theme }) => theme.text.size.large};
  margin: 25px 0 12px;
`;

type Props = RouteComponentProps;

/**
 * The user will arrive at this route whenever they logout of IDSPA (automatically or purposefully).
 * There is a separate ECM logged out page used by ECMASPA when logging out of that.
 */
const LoggedOutRoute: React.FC<Props> = () => (
  <CenteredPage testId={loggedOutScreenSelector.testId}>
    <SHeading>You have been successfully logged out</SHeading>
    <SMessage>For your security, we recommend you close your browser.</SMessage>
    <PrimaryButton
      variant="high-contrast"
      testId={backToLoginButtonSelector.testId}
      asComponent={Link}
      to={routeFactory.login.getUrlPath()}
      size="large"
      fullWidth
    >
      Return to Login Page
    </PrimaryButton>
  </CenteredPage>
);

// TODO - we could connect this and redirect if user is logged in so no one accidentally lands on this route
export default LoggedOutRoute;
