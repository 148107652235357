import * as Yup from 'yup';

export type InvestorSignupValues = {
  firstName: string;
  lastName: string;
  firmName: string;
  email: string;
  phoneNumber: string;
  termsAgreed: boolean;
  isAuthorized: boolean;
  marketingAgreed: boolean;
};

export const initialValues: InvestorSignupValues = {
  firstName: '',
  lastName: '',
  firmName: '',
  email: '',
  phoneNumber: '',
  termsAgreed: false,
  isAuthorized: false,
  marketingAgreed: false,
};

const alphabetsOnlyRegex = /^[A-Za-z.\-'’\s]+$/;
const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

export const formSchema = Yup.object().shape({
  firstName: Yup.string()
    .label('First name')
    .matches(alphabetsOnlyRegex, 'Only alphabets are allowed for this field')
    .required(),
  lastName: Yup.string()
    .label('Last name')
    .matches(alphabetsOnlyRegex, 'Only alphabets are allowed for this field')
    .required(),
  firmName: Yup.string().label('Firm name').required(),
  email: Yup.string().label('Email').email().required(),
  phoneNumber: Yup.string()
    .label('Phone number')
    .matches(phoneRegExp, 'Phone number has to be of the form xxx-xxx-xxxx')
    .required(),
  termsAgreed: Yup.bool().default(false),
  isAuthorized: Yup.bool().default(false),
  marketingAgreed: Yup.bool().default(false),
});

export const mapFormValues = (values: InvestorSignupValues) => ({
  email: values.email,
  firmName: values.firmName,
  firstName: values.firstName,
  lastName: values.lastName,
  phoneNumber: values.phoneNumber,
  termsAgreed: values.termsAgreed,
  isAuthorized: values.isAuthorized,
  marketingAgreed: values.marketingAgreed,
});
