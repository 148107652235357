import {
  traitDetailAccountTypeSelector,
  traitDetailNameSelector,
  traitDetailSubdomainSelector,
} from '@cmg/e2e-selectors';
import { ColDef } from 'ag-grid-community';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../../common/util/routeFactory';
import { Account } from '../../../../../types/domain/account/account';
import { accountTypeLabels } from '../../../../../types/domain/account/constants';

export const NameCellRenderer: React.FC<{ data: Account }> = ({ data: account }) => (
  <Link
    to={routeFactory.accountDetail.getUrlPath({
      accountSubdomain: account.subdomain,
    })}
    data-test-id={traitDetailNameSelector}
  >
    {account.name}
  </Link>
);

export const AccountTypeCellRenderer: React.FC<{ data: Account }> = ({ data: account }) => (
  <span data-test-id={traitDetailAccountTypeSelector.testId}>
    {account.accountType ? accountTypeLabels[account.accountType] : 'None'}
  </span>
);

export const SubdomainRenderer: React.FC<{ data: Account }> = ({ data }) => {
  return <span data-test-id={traitDetailSubdomainSelector.testId}>{data.subdomain}</span>;
};

const columns: ColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    sort: 'asc',
    cellRendererFramework: NameCellRenderer,
    suppressColumnsToolPanel: true,
  },
  { field: 'subdomain', headerName: 'Subdomain', cellRendererFramework: SubdomainRenderer },
  {
    field: 'accountType',
    headerName: 'Account Type',
    cellRendererFramework: AccountTypeCellRenderer,
  },
];

export default columns;
