import { numericUtil, timeUtil } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';

import DetailHeader from '../../../../../common/components/layout/detail-header/DetailHeader';
import { selectCrmIntegration } from '../../../shared/ducks';
import {
  SRecordCountWrapper,
  StyledDetailHeaderTitleWrapper,
  StyledRecordCountDetailHeaderTitle,
  StyledRecordSectionWrapper,
  StyledTitleDetailHeaderColumn,
} from './CrmIntegrationDetailHeader.styles';

const mapStateToProps = state => ({
  crmIntegration: selectCrmIntegration(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

/**
 * The header for the CRM Integration Detail page.
 */
export const CrmIntegrationDetailHeaderComponent: React.FC<Props> = ({ crmIntegration }) => {
  if (!crmIntegration) {
    return null;
  }

  return (
    <DetailHeader.Header>
      <StyledDetailHeaderTitleWrapper>
        <StyledTitleDetailHeaderColumn>
          <DetailHeader.Row>
            <DetailHeader.Title>{crmIntegration.name}</DetailHeader.Title>
          </DetailHeader.Row>
          <DetailHeader.Row>
            <DetailHeader.DetailWrapper>
              <DetailHeader.DetailLabel>Last CRM Update</DetailHeader.DetailLabel>
              <DetailHeader.DetailValue>
                {crmIntegration.modifiedOn
                  ? timeUtil.formatAsDisplayDateTime(crmIntegration.modifiedOn)
                  : 'Never'}
              </DetailHeader.DetailValue>
            </DetailHeader.DetailWrapper>
          </DetailHeader.Row>
        </StyledTitleDetailHeaderColumn>
        <StyledRecordSectionWrapper>
          <SRecordCountWrapper>
            <StyledRecordCountDetailHeaderTitle>
              {numericUtil.formatInteger(crmIntegration.linkedFirms)}
            </StyledRecordCountDetailHeaderTitle>
            <div>Linked Records</div>
          </SRecordCountWrapper>
          <SRecordCountWrapper>
            <StyledRecordCountDetailHeaderTitle>
              {numericUtil.formatInteger(crmIntegration.unLinkedFirms)}
            </StyledRecordCountDetailHeaderTitle>
            <div>Not Linked Records</div>
          </SRecordCountWrapper>
          <SRecordCountWrapper>
            <StyledRecordCountDetailHeaderTitle>
              {numericUtil.formatInteger(crmIntegration.newFirms)}
            </StyledRecordCountDetailHeaderTitle>
            <div>New Records</div>
          </SRecordCountWrapper>
          <SRecordCountWrapper>
            <StyledRecordCountDetailHeaderTitle>
              {numericUtil.formatInteger(crmIntegration.totalFirms)}
            </StyledRecordCountDetailHeaderTitle>
            <div>Total Records</div>
          </SRecordCountWrapper>
        </StyledRecordSectionWrapper>
      </StyledDetailHeaderTitleWrapper>
    </DetailHeader.Header>
  );
};

export default connect(mapStateToProps)(CrmIntegrationDetailHeaderComponent);
