import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { useCallback, useMemo } from 'react';

import { AccountDetailRouteName } from '../AccountDetailDomainNav.model';

export type UsePermissionedAccountDetailRoutesResult = Readonly<{
  readonly canAccessRoute: (route?: AccountDetailRouteName) => boolean;
}>;

export function usePermissionedAccountDetailRoutes(): UsePermissionedAccountDetailRoutesResult {
  const canReadGlobalUser = useCheckPermissions([permissionsByEntity.GlobalUser.READ]);
  const canReadUser = useCheckPermissions([permissionsByEntity.User.READ]);
  const canReadGlobalRole = useCheckPermissions([permissionsByEntity.GlobalRole.READ]);
  const canReadRole = useCheckPermissions([permissionsByEntity.Role.READ]);
  const canReadGlobalApiKey = useCheckPermissions([permissionsByEntity.GlobalApiKey.READ]);
  const canReadApiKey = useCheckPermissions([permissionsByEntity.ApiKey.READ]);

  const permissionedRoutes = useMemo(() => {
    const routes: AccountDetailRouteName[] = ['subscription', 'security'];

    if (canReadGlobalUser || canReadUser) {
      routes.push('users');
    }

    if (canReadGlobalRole || canReadRole) {
      routes.push('roles');
    }

    if (canReadGlobalApiKey || canReadApiKey) {
      routes.push('apiKeys');
    }

    if (canReadGlobalRole) {
      routes.push('rolodex');
    }

    return routes;
  }, [
    canReadApiKey,
    canReadGlobalApiKey,
    canReadGlobalRole,
    canReadGlobalUser,
    canReadRole,
    canReadUser,
  ]);

  const canAccessRoute = useCallback(
    (routeName?: AccountDetailRouteName) => {
      return routeName ? permissionedRoutes.includes(routeName) : false;
    },
    [permissionedRoutes]
  );

  return { canAccessRoute };
}
