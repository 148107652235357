import { AccessControl } from '@cmg/auth';
import {
  AppBar,
  AppBarLogo,
  AppBarNavigationLink,
  AppBarNavigationMenu,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@cmg/design-system';
import { Fragment, VFC } from 'react';
import { connect } from 'react-redux';

import { selectSelfSubdomain } from '../../../../../features/shared/ducks';
import IdentityDomainLogo from '../../../../assets/IdentityDomainLogo.svg';
import { useAppBarNavigation } from './hooks/useAppBarNavigation';
import { IdentityAppBarSecondarySection } from './IdentityAppBarSecondarySection';

const mapStateToProps = state => ({
  selfSubdomain: selectSelfSubdomain(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
export type Props = StateProps;

export const IdentityAppBarComponent: VFC<Props> = ({ selfSubdomain }) => {
  const { selectedDefinitionName, navDefinitions } = useAppBarNavigation({
    selfSubdomain,
  });

  return (
    <AccessControl>
      <AppBar
        logoSection={<AppBarLogo imgSrc={IdentityDomainLogo} imgAlt="CMG Admin Logo" />}
        navigationSectionLinks={
          <Fragment>
            {navDefinitions.map(navDefinition => (
              <AppBarNavigationLink
                key={navDefinition.name}
                isActive={selectedDefinitionName === navDefinition.name}
                href={navDefinition.href}
                target={navDefinition.target}
              >
                {navDefinition.label}
              </AppBarNavigationLink>
            ))}
          </Fragment>
        }
        navigationSectionMenu={
          <AppBarNavigationMenu
            label="Main Menu"
            renderItems={({ closeMenu }) =>
              navDefinitions.map(navDefinition => (
                <ListItem
                  disablePadding
                  role="menuitem"
                  key={navDefinition.name}
                  onClick={closeMenu}
                >
                  <ListItemButton href={navDefinition.href} target={navDefinition.target}>
                    <ListItemText primary={navDefinition.label} />
                  </ListItemButton>
                </ListItem>
              ))
            }
          />
        }
        secondarySection={<IdentityAppBarSecondarySection />}
      />
    </AccessControl>
  );
};

export default connect(mapStateToProps)(IdentityAppBarComponent);
