import React, { Component } from 'react';
import { connectModal, InjectedProps } from 'redux-modal';

import { ApiKeyDialog } from '../../../design-system/account-detail/api-keys/shared/ApiKeyDialog';

type OwnProps = {
  accessToken?: string;
  onHide?: () => void;
};

type Props = OwnProps & InjectedProps;
type State = {
  copied: boolean;
};

export class ApiKeyModalComponent extends Component<Props, State> {
  state = {
    copied: false,
  };

  copyTimer: ReturnType<typeof setTimeout> | undefined = undefined;

  componentWillUnmount() {
    this.copyTimer && clearTimeout(this.copyTimer);
  }

  onCopy = () => {
    this.setState({ copied: true });
    this.copyTimer = setTimeout(() => this.setState({ copied: false }), 2000);
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide && onHide();
    this.props.handleHide();
  };

  render() {
    const { copied } = this.state;
    const { accessToken = '' } = this.props;

    return (
      <ApiKeyDialog
        accessToken={accessToken}
        isCopied={copied}
        onCopy={this.onCopy}
        onClose={this.handleClose}
      />
    );
  }
}
export const API_KEY_MODAL_ID = 'API_KEY_MODAL_ID';

export default connectModal({
  name: API_KEY_MODAL_ID,
  destroyOnHide: true,
})(ApiKeyModalComponent);
