import { Tooltip } from '@cmg/design-system';
import { FC } from 'react';

import { DescriptionList } from '../../../../../design-system/body-sections/description-list/DescriptionList';
import { DescriptionListItem } from '../../../../../design-system/body-sections/description-list/DescriptionListItem';
import { AccountType } from '../../../../../types/domain/account/constants';
import { User } from '../../../../../types/domain/user/user';
import { getJobFunctionLabel } from '../../../../shared/model/job-function';
import { getEmployeeKeyTooltipLabel, getInvestorCoverageStatusTooltipLabel } from '../utils';

export type Props = Readonly<{
  user: Readonly<User>;
  accountType: Readonly<AccountType | 'SYSTEM' | null>;
}>;

export const UserDetailsSectionReadonly: FC<Props> = ({ user, accountType }) => {
  return (
    <DescriptionList label="User Details">
      <DescriptionListItem label="First Name" value={user.firstName} />
      <DescriptionListItem label="Last Name" value={user.lastName} />
      <DescriptionListItem
        label="Job Function"
        value={getJobFunctionLabel(user.jobFunction, accountType)}
      />
      <DescriptionListItem
        label={
          <Tooltip title={getEmployeeKeyTooltipLabel()} placement="top-start" describeChild>
            <span aria-label="Employee Key">Employee Key ⓘ</span>
          </Tooltip>
        }
        value={user.employeeKey}
      />
      {accountType === AccountType.SELL_SIDE && (
        <DescriptionListItem
          label={
            <Tooltip
              title={getInvestorCoverageStatusTooltipLabel()}
              placement="top-start"
              describeChild
            >
              <span aria-label="Investor Coverage Status">Investor Coverage Status ⓘ</span>
            </Tooltip>
          }
          value={user.configurationStatus}
        />
      )}
    </DescriptionList>
  );
};
