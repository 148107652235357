import { PrimaryButton } from '@cmg/common';
import { detect } from 'detect-browser';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import { CreateUserSignupDetailsDto } from '../ducks';
import { SLinkButton } from '../InvestorSignupRoute.styles';
import { formSchema, initialValues, InvestorSignupValues } from './InvestorSignupForm.model';
import { SCheckboxField, SFormWrapper, STextInputField } from './InvestorSignupForm.styles';

export const formatPhoneNumber = value => {
  if (!value) {
    return value;
  }
  const phoneNumber = value.toString().replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) {
    return phoneNumber;
  } else if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  } else {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
};

type Props = {
  isEmailValid: boolean | undefined;
  validateEmail: (event: React.FocusEvent) => void;
  onEmailChange: (email: string) => void;
  handleSubmit: (values: CreateUserSignupDetailsDto) => void;
  token: string;
};

const InvestorSignupForm: React.FC<Props> = ({
  isEmailValid,
  validateEmail,
  onEmailChange,
  handleSubmit,
  token,
}) => {
  const formik = useFormik<InvestorSignupValues>({
    initialValues: initialValues,
    onSubmit: values => {
      const formValues: CreateUserSignupDetailsDto = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phoneNumber,
        firmName: values.firmName,
        isEmailVerified: isEmailValid ?? false,
        isIoiTermsAccepted: values.isAuthorized,
        isTermsAndConditionsAccepted: values.termsAgreed,
        token: token,
      };
      handleSubmit(formValues);
    },
    validationSchema: formSchema,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleInput = (value: string) => {
    const phoneNumberValue = formatPhoneNumber(value);
    formik.setFieldValue('phoneNumber', phoneNumberValue);
  };

  const browser = detect();

  return (
    <FormikProvider value={formik}>
      <Form>
        <SFormWrapper>
          <STextInputField
            fullWidth
            name="email"
            size="medium"
            aria-label="Email*"
            label="Email*"
            onChange={onEmailChange}
            onBlur={validateEmail}
            autoComplete="off"
          />
          <STextInputField
            fullWidth
            name="firstName"
            aria-label="First Name*"
            label="First Name*"
            size="medium"
            autoComplete="off"
          />
          <STextInputField
            fullWidth
            name="lastName"
            aria-label="Last Name*"
            label="Last Name*"
            size="medium"
            autoComplete="off"
          />
          <STextInputField
            fullWidth
            name="phoneNumber"
            aria-label="Phone*"
            label="Phone*"
            onChange={handleInput}
            size="medium"
            autoComplete="off"
          />
          <STextInputField
            fullWidth
            name="firmName"
            aria-label="Firm Name*"
            label="Firm Name*"
            size="medium"
            autoComplete="off"
          />
        </SFormWrapper>
        {isEmailValid ? (
          <React.Fragment>
            <SCheckboxField withMargin fullWidth name="termsAgreed">
              By selecting this checkbox and clicking Submit below, you hereby certify that you have
              reviewed and agree to{' '}
              {browser && browser.name === 'safari' ? (
                <SLinkButton
                  href="investor-portal-terms-and-conditions-final.pdf"
                  target="_blank"
                  download="investor-portal-terms-and-conditions-final.pdf"
                >
                  CMG’s Terms and Conditions
                </SLinkButton>
              ) : (
                <SLinkButton href="investor-portal-terms-and-conditions-final.pdf" target="_blank">
                  CMG’s Terms and Conditions
                </SLinkButton>
              )}{' '}
              on behalf of your organization for the use of CMG’s products and services
            </SCheckboxField>
            <SCheckboxField withMargin fullWidth name="isAuthorized">
              I am authorized to provide indications of interest and acknowledge allocations on new
              issues on behalf of my firm
            </SCheckboxField>
          </React.Fragment>
        ) : null}
        <PrimaryButton onClick={() => formik.handleSubmit()} fullWidth size="large">
          Submit
        </PrimaryButton>
      </Form>
    </FormikProvider>
  );
};

export default InvestorSignupForm;
