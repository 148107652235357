import { duckPartFactory } from '@cmg/common';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import systemManagementApiClient, {
  GetTraitsResponse,
} from '../../../common/api/systemManagementApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { Trait } from '../../../types/domain/trait/Trait';

const traitDuckParts = duckPartFactory.makeAPIDuckParts<undefined, Trait[]>({
  prefix: 'GLOBAL_MANAGEMENT/TRAIT_LIST',
});

/**
 * ACTION CREATORS
 */

export const fetchTraitList = traitDuckParts.actionCreators.request;
type FetchTraitListAction = ReturnType<typeof fetchTraitList>;

/**
 * REDUCERS
 */

export type ReducerState = typeof traitDuckParts.initialState;
export default traitDuckParts.reducer;
export const initialState = traitDuckParts.initialState;

/**
 * SELECTORS
 */

const selectLocalState = (state: RootState) => state.adminTraitList;
const selectors = traitDuckParts.makeSelectors(selectLocalState);

export const selectError = selectors.selectError;
export const selectTraits = createSelector(selectors.selectData, traits => traits || []);
export const selectLoading = selectors.selectLoading;

/**
 * SAGAS
 */

export function* fetchTraitListSaga({ payload }: FetchTraitListAction): SagaIterator {
  const response: GetTraitsResponse = yield call(systemManagementApiClient.admin.getTraits);
  if (response.ok) {
    yield put(traitDuckParts.actionCreators.success(response.data));
  } else {
    yield put(traitDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* adminTraitListSaga() {
  yield takeLatest<FetchTraitListAction>(traitDuckParts.actionTypes.REQUEST, fetchTraitListSaga);
}
