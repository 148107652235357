import { apiTypes, apiUtil, reduxUtil } from '@cmg/common';
import saveAs from 'file-saver';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as accountApiClient from '../../../common/api/accountApiClient';
import systemManagementApiClient, {
  GetAccountUsersParams,
  GetAccountUsersResponse,
} from '../../../common/api/systemManagementApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import UserBasic from '../../../types/domain/user/UserBasic';
import { selectSelfSubdomain } from '../../shared/ducks';
import { selectAccountSubdomain } from '../shared/ducks';

/**
 * ACTION TYPES
 */
enum ActionTypes {
  FETCH_USER_LIST_REQUEST = 'global_management/FETCH_USER_LIST_REQUEST',
  FETCH_USER_LIST_SUCCESS = 'global_management/FETCH_USER_LIST_SUCCESS',
  FETCH_USER_LIST_FAILURE = 'global_management/FETCH_USER_LIST_FAILURE',
  DOWNLOAD_USER_LIST_REQUEST = 'global_management/DOWNLOAD_USER_LIST_REQUEST',
}

/**
 * ACTION CREATORS
 */
const { createReducer } = reduxUtil;

export const fetchUserList = (params: GetAccountUsersParams) => ({
  type: ActionTypes.FETCH_USER_LIST_REQUEST,
  payload: {
    params,
  },
});

const fetchUserListSucceeded = (params: {
  users: UserBasic[];
  pagination: apiTypes.Pagination;
}) => ({
  type: ActionTypes.FETCH_USER_LIST_SUCCESS,
  payload: {
    users: params.users,
    pagination: params.pagination,
  },
});

const fetchUserListFailed = () => ({
  type: ActionTypes.FETCH_USER_LIST_FAILURE,
});

export const downloadUserList = () => ({
  type: ActionTypes.DOWNLOAD_USER_LIST_REQUEST,
});

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.FETCH_USER_LIST_REQUEST]: ReturnType<typeof fetchUserList>;
  [ActionTypes.FETCH_USER_LIST_SUCCESS]: ReturnType<typeof fetchUserListSucceeded>;
  [ActionTypes.FETCH_USER_LIST_FAILURE]: ReturnType<typeof fetchUserListFailed>;
  [ActionTypes.DOWNLOAD_USER_LIST_REQUEST]: ReturnType<typeof downloadUserList>;
};

/**
 * REDUCERS
 */
export type ReducerState = {
  error: boolean;
  users: UserBasic[];
  loading: boolean;
  pagination: apiTypes.Pagination | null;
};

export const initialState: ReducerState = {
  error: false,
  users: [],
  loading: false,
  pagination: null,
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.FETCH_USER_LIST_REQUEST]: () => false,
  [ActionTypes.FETCH_USER_LIST_SUCCESS]: () => false,
  [ActionTypes.FETCH_USER_LIST_FAILURE]: () => true,
});

const usersReducer = createReducer<ReducerState['users'], Actions>(initialState.users, {
  [ActionTypes.FETCH_USER_LIST_SUCCESS]: (curState, { payload }) => payload.users,
});

const loadingReducer = createReducer<ReducerState['loading'], Actions>(initialState.loading, {
  [ActionTypes.FETCH_USER_LIST_REQUEST]: () => true,
  [ActionTypes.FETCH_USER_LIST_SUCCESS]: () => false,
  [ActionTypes.FETCH_USER_LIST_FAILURE]: () => false,
});

const paginationReducer = createReducer<ReducerState['pagination'], Actions>(
  initialState.pagination,
  {
    [ActionTypes.FETCH_USER_LIST_SUCCESS]: (curState, { payload }) => payload.pagination,
  }
);

export default combineReducers<ReducerState>({
  error: errorReducer,
  users: usersReducer,
  loading: loadingReducer,
  pagination: paginationReducer,
});

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.adminAccountUserList;
export const selectError = state => selectState(state).error;
export const selectUsers = state => selectState(state).users;
export const selectPagination = state => selectState(state).pagination;
export const selectLoading = state => selectState(state).loading;

/**
 * SAGAS
 */
function* fetchUserListSaga({ payload }: Actions[ActionTypes.FETCH_USER_LIST_REQUEST]) {
  const { params } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: accountApiClient.GetMyAccountUsersResponse | GetAccountUsersResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyAccountUsers, params);
  } else {
    response = yield call(
      systemManagementApiClient.admin.getAccountUsers,
      accountSubdomain,
      params
    );
  }

  if (response.ok) {
    const { data: users, pagination } = response.data;
    yield put(fetchUserListSucceeded({ users, pagination }));
  } else {
    yield put(fetchUserListFailed());
  }
}

function* downloadUserListSaga(action: Actions[ActionTypes.DOWNLOAD_USER_LIST_REQUEST]) {
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: accountApiClient.DownloadMyAccountUsersResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.downloadMyAccountUsers);
  } else {
    response = yield call(systemManagementApiClient.admin.downloadAccountUsers, accountSubdomain);
  }

  if (response.ok) {
    const filename = apiUtil.getFilenameFromContentDisposition(
      response.headers['content-disposition'],
      'UserList.xlsx'
    );

    saveAs(response.data, filename);
  }
}

export function* adminAccountUserListSaga() {
  yield takeLatest<Actions[ActionTypes.FETCH_USER_LIST_REQUEST]>(
    ActionTypes.FETCH_USER_LIST_REQUEST,
    fetchUserListSaga
  );
  yield takeLatest<Actions[ActionTypes.DOWNLOAD_USER_LIST_REQUEST]>(
    ActionTypes.DOWNLOAD_USER_LIST_REQUEST,
    downloadUserListSaga
  );
}
