import groupBy from 'lodash/groupBy';
import { useMemo } from 'react';

import { AccountPermission } from '../../../../../types/domain/account/account';
import { PermissionDetail, UndefinedPermissionDetail } from '../../../auth/constants';
import { mapPermissionDetails, sanitizePermissionsByAccessLevel } from '../PermissionsTable.model';

export type UsePermissionsTableProps = Readonly<{
  readonly selectedPermissions: string[] | null;
  readonly accountPermissions: AccountPermission[];
}>;

type UsePermissionsTableResult = Readonly<{
  readonly accountEntities: string[];
  readonly groups: string[];
  readonly permissionsByGroup: {
    [key: string]: (PermissionDetail | UndefinedPermissionDetail)[];
  };
  readonly sanitizedSelectedPermissions: { [key: string]: string };
}>;

export function usePermissionsTable({
  selectedPermissions,
  accountPermissions,
}: UsePermissionsTableProps): UsePermissionsTableResult {
  // map all accountPermissions to permissionsDisplay, or if undefined in permissionsDisplay
  const availablePermissions = useMemo(
    () => mapPermissionDetails(accountPermissions),
    [accountPermissions]
  );

  // map list of allowed permission entities, then filter those in permissionDisplay
  const accountEntities = useMemo(
    () => availablePermissions.map(p => p.entity),
    [availablePermissions]
  );

  // iterate over all groups in permissionDisplay
  const permissionsByGroup = useMemo(
    () =>
      groupBy(
        availablePermissions,
        (p: PermissionDetail | UndefinedPermissionDetail) => p.groupName
      ),
    [availablePermissions]
  );

  const groups: string[] = Object.keys(permissionsByGroup);
  const sanitizedSelectedPermissions = useMemo(
    () => sanitizePermissionsByAccessLevel(selectedPermissions),
    [selectedPermissions]
  );

  return {
    accountEntities,
    groups,
    permissionsByGroup,
    sanitizedSelectedPermissions,
  };
}
