import { Popover } from '@cmg/common';
import React from 'react';

import { FirmMatchRow } from '../../../../../../types/domain/firm-match/FirmMatch';
import { SOtherNamesWrapper } from './CrmFirmMatchListCellRenderer.styles';

/**
 * Renders names where isDisplayName=false (aka other names)
 */
export const OtherNamesCellRenderer: React.FC<{ data: FirmMatchRow }> = ({ data: firmMatch }) => {
  if (!firmMatch.names) {
    return <div />;
  }

  const otherNames = firmMatch.names.filter(n => !n.isDisplayName);

  if (!otherNames.length) {
    return <div />;
  }

  const displayOtherNames = otherNames.slice(0, 3);

  return (
    <SOtherNamesWrapper>
      <Popover
        variant="TOOLTIP"
        placement="right"
        content={otherNames.map(({ value }, i) => (
          <div key={i}>{value}</div>
        ))}
      >
        <ul>
          {displayOtherNames.map(({ value }, i) => (
            <li key={i}>
              {value}
              {i === displayOtherNames.length - 1 ? '' : ','}
            </li>
          ))}
        </ul>
      </Popover>
    </SOtherNamesWrapper>
  );
};

export default OtherNamesCellRenderer;
