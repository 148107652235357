import {
  Banner,
  ModalContent,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
  ServerErrors,
} from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FactSetFirmSelect from '../../../../../common/components/form/factset-select/FactSetFirmSelect';
import FactSetFirmHierarchyTreeGrid from '../../../../../common/components/lists/factset-firm-hierarchy-tree-grid/FactSetFirmHierarchyTreeGrid';
import { UUID } from '../../../../../types/common';
import {
  fetchFactSetFirmHierarchy,
  getConnectFactSetFirmWithCMGFirmPreviewDiff,
  selectConnectFactSetFirmPreviewDiffError,
  selectConnectFactSetFirmPreviewDiffLoading,
  selectFactSetFirmHierarchy,
  selectFactSetFirmHierarchyError,
  selectFactSetFirmHierarchyLoading,
  selectFirmDisplayName,
} from '../../ducks';
import {
  SButtonWrapper,
  SFactSetFirmHierarchyTreeGridWrapper,
  SLabel,
} from './ConnectWithFactSetModal.styles';
import { getFirmFromFactSetHierarchy } from './getFirmFromFactSetHierarchy';

const mapStateToProps = state => ({
  firmDisplayName: selectFirmDisplayName(state),
  factSetFirmHierarchy: selectFactSetFirmHierarchy(state),
  factSetFirmHierarchyLoading: selectFactSetFirmHierarchyLoading(state),
  factSetFirmHierarchyError: selectFactSetFirmHierarchyError(state),
  connectFirmPreviewLoading: selectConnectFactSetFirmPreviewDiffLoading(state),
  connectFirmPreviewError: selectConnectFactSetFirmPreviewDiffError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchFactSetFirmHierarchy,
      getConnectFactSetFirmWithCMGFirmPreviewDiff,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  /** The ID of the firm to be linked with FactSet */
  selectedFirmId: UUID;
  /** The selected FactSet Firm ID value from the child FactSetFirmSelect input */
  selectedFactSetFirmId: string | null;
  /** Callback prop triggered when FactSetFirmSelect input changes */
  onChange: (id: string | null) => void;
  /** Callback prop triggered when "Cancel" button in footer content is clicked. */
  onCancel: () => void;
};
export type Props = OwnProps & StateProps & DispatchProps;

/**
 * Displays the first step of the Connect With FactSet
 * experience - the process of selecting a FactSet firm
 * to connect the CMG firm with
 */
export const ChooseFactSetFirmComponent: React.FC<Props> = ({
  firmDisplayName,
  factSetFirmHierarchy,
  factSetFirmHierarchyError,
  factSetFirmHierarchyLoading,
  connectFirmPreviewLoading,
  connectFirmPreviewError,
  selectedFirmId,
  selectedFactSetFirmId,
  onChange,
  onCancel,
  actions,
}) => {
  const selectedFactSetFirm = getFirmFromFactSetHierarchy(
    selectedFactSetFirmId,
    factSetFirmHierarchy
  );
  const isPreviewButtonVisible =
    selectedFactSetFirm && !selectedFactSetFirm.linkedToEntityMasterFirm;

  return (
    <React.Fragment>
      <ModalContent>
        {factSetFirmHierarchyError && (
          <Banner variant="error" showIcon={false}>
            <ServerErrors error={factSetFirmHierarchyError} />
          </Banner>
        )}
        {connectFirmPreviewError && (
          <Banner variant="error" showIcon={false}>
            <ServerErrors error={connectFirmPreviewError} />
          </Banner>
        )}
        {selectedFactSetFirm && selectedFactSetFirm.linkedToEntityMasterFirm && (
          <Banner variant="warning">
            {selectedFactSetFirm.properName} has already been linked to an Entity Master Firm (
            {selectedFactSetFirm.linkedCmgEntityId}).
          </Banner>
        )}
        {firmDisplayName && (
          <SLabel>
            Connect <b>{firmDisplayName.value}</b> to the following FactSet organization:
          </SLabel>
        )}
        <FactSetFirmSelect
          selectedFirmId={null}
          onChange={value => {
            onChange(value);
            value && actions.fetchFactSetFirmHierarchy({ id: value });
          }}
        />
        {selectedFactSetFirmId && (
          <SFactSetFirmHierarchyTreeGridWrapper>
            <FactSetFirmHierarchyTreeGrid
              activeFirmId={selectedFactSetFirmId}
              hierarchy={factSetFirmHierarchy}
              loading={factSetFirmHierarchyLoading}
              error={factSetFirmHierarchyError}
            />
          </SFactSetFirmHierarchyTreeGridWrapper>
        )}
      </ModalContent>
      <ModalFooter>
        <SButtonWrapper>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          {isPreviewButtonVisible && (
            <PrimaryButton
              loading={connectFirmPreviewLoading}
              onClick={() => {
                actions.getConnectFactSetFirmWithCMGFirmPreviewDiff({
                  firmId: selectedFirmId,
                  factSetId: selectedFactSetFirmId!,
                });
              }}
            >
              Preview Merge Changes
            </PrimaryButton>
          )}
        </SButtonWrapper>
      </ModalFooter>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseFactSetFirmComponent);
