import styled from 'styled-components/macro';

export const SFormSection = styled.div`
  margin-bottom: 15px;
`;

export const SButtonsSection = styled.div`
  padding-top: 15px;
`;

export const SHeading = styled.div`
  font-size: ${({ theme }) => theme.text.size.large};
  margin: 20px 0 25px;
`;

export const SMessage = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  margin: 20px 0 25px;
`;
