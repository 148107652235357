import { FirmIdentifierType } from '@capital-markets-gateway/api-client-rolodex';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as yup from 'yup';

import { getFirmIdentifierColumns } from '../../../../../common/components/layout/firm-record-panel/firmIdentifierColumns';
import FirmRecordPanel from '../../../../../common/components/layout/firm-record-panel/GenericFirmRecordPanel';
import { UUID } from '../../../../../types/common';
import { FirmIdentifier } from '../../../../../types/domain/firm/FirmIdentifier';
import {
  cancelEditRecord,
  createFirmIdentifier,
  editRecord,
  FirmDetailSection,
  selectEditingRecordIds,
  selectFirmIdentifiers,
  selectFirmIdentifiersError,
  selectUpdatingRecordIds,
  updateFirmIdentifier,
} from '../../ducks';

const mapStateToProps = state => ({
  identifiers: selectFirmIdentifiers(state),
  error: selectFirmIdentifiersError(state),
  editingRecordIds: selectEditingRecordIds(state),
  updatingRecordIds: selectUpdatingRecordIds(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createFirmIdentifier,
      updateFirmIdentifier,
      editRecord,
      cancelEditRecord,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  firmId: UUID;
};
type Props = OwnProps & StateProps & DispatchProps;

export const FirmIdentifiersValidationSchema = yup.object().shape({
  type: yup.string().nullable().required('An identifier type is required'),
  value: yup
    .string()
    .nullable()
    .required('An identifier value is required')
    .test({
      name: 'identifier-value-test',
      message: 'DTC must be of fixed length of 4 characters and contain only numbers',
      test: (value, context) => {
        const type = context.parent.type;
        if (type === FirmIdentifierType.DTC) {
          return /^\d{4}$/.test(value);
        }
        return true;
      },
    }),
  recordStatus: yup.string().nullable().required('A status type is required'),
});

export const FirmIdentifiersRecordPanelComponent: React.FC<Props> = ({
  firmId,
  error,
  editingRecordIds,
  updatingRecordIds,
  identifiers,
  actions,
}) => {
  return (
    <FirmRecordPanel<FirmIdentifier>
      sectionId={FirmDetailSection.IDENTIFIERS}
      title="Identifiers"
      addRecordButtonLabel="Add Identifier"
      error={error}
      editingRecordIds={editingRecordIds}
      updatingRecordIds={updatingRecordIds}
      onCreate={identifier => actions.createFirmIdentifier({ firmId, identifier })}
      onUpdate={identifier => {
        actions.updateFirmIdentifier({ firmId, identifier });
      }}
      onEdit={recordId => actions.editRecord({ recordId })}
      onCancelEdit={recordId => actions.cancelEditRecord({ recordId })}
      columns={getFirmIdentifierColumns()}
      rows={identifiers}
      validationSchema={FirmIdentifiersValidationSchema}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmIdentifiersRecordPanelComponent);
