import { FirmDataSource } from '../../../../../../types/domain/firm/constants';
import { Firm } from '../../../../../../types/domain/firm/Firm';
import { TransformedFirm } from '../../../../../../types/domain/firm/TransformedFirm';

export const isCmgOrCustomerCreatedFirmType = (firmType: FirmDataSource | null) => {
  return firmType === FirmDataSource.CMG || firmType === FirmDataSource.CUSTOMER_CREATED;
};

export const isFirmStoreLocally = (
  firmType: FirmDataSource | null,
  firm: Firm | TransformedFirm
) => {
  return isCmgOrCustomerCreatedFirmType(firmType) && !(firm as Firm).isFactSetConnected;
};
