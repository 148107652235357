import * as yup from 'yup';

export type CreateApiKeyFormValues = Readonly<{
  readonly name: string;
  readonly permissions: { [key: string]: string };
}>;

export const CreateApiKeySchema = yup.object().shape({
  name: yup.string().required('Label is Required'),
});
