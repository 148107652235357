import { DotStatus, TableCell } from '@cmg/design-system';
import React from 'react';

import { AccountPermission } from '../../../../../types/domain/account/account';
import { usePermissionCell } from '../../../../shared/components/permissions-table/hooks/usePermissionCell';
import { PermissionCellRadio } from './PermissionCellRadio';

export type Props = Readonly<{
  readonly isInEditMode: boolean;
  readonly name: string;
  readonly selectedPermissions: { [key: string]: string };
  readonly accountPermissions: AccountPermission[];
}>;

export const PermissionCell: React.FC<Props> = ({
  isInEditMode,
  name,
  selectedPermissions,
  accountPermissions,
}) => {
  const cellProps = usePermissionCell({
    accountPermissions,
    name,
    selectedPermissions,
  });

  if (cellProps.isHidden) {
    return null;
  }

  const { entity, accessLevel, isChecked, label } = cellProps;

  return (
    <TableCell>
      {isInEditMode ? (
        <PermissionCellRadio
          name={`permissions.${entity}`}
          isChecked={isChecked}
          label={label}
          value={accessLevel ? name : ''}
        />
      ) : (
        isChecked && <DotStatus titleAccess="selected" color="primary" />
      )}
    </TableCell>
  );
};
