import { SnackbarManager } from '@cmg/design-system';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components/macro';

import privacyPolicyMarkdown from '../../content/privacy-policy.md';

export const SMarkdownWrapper = styled.div`
  box-sizing: border-box;
  margin: 1rem;
  padding: 1rem;
  li {
    margin-left: 2rem;
  }
`;

const PrivacyPolicyDoc: React.FC = () => {
  const [content, setContent] = useState<string>('');

  React.useEffect(() => {
    fetch(privacyPolicyMarkdown)
      .then(response => {
        if (response.ok) {
          return response.text();
        }

        return '';
      })
      .then(content => {
        setContent(content);
      })
      .catch(_error => {
        SnackbarManager.error(`Error fetching privacy policy markdown file!`);
      });
  }, []);

  return (
    <React.Fragment>
      <SMarkdownWrapper>
        <ReactMarkdown children={content} />
      </SMarkdownWrapper>
    </React.Fragment>
  );
};

export default PrivacyPolicyDoc;
