import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { DropdownButton, Menu, MenuItem, Typography } from '@cmg/design-system';
import { Fragment, useRef, useState, VFC } from 'react';

import { PageGridSection } from '../../../../../design-system/body-sections/PageGridSection';
import { IdentityProviderType } from '../../../../../types/domain/identity-provider/constants';
import { AccountIdentityProvider } from '../../../../../types/domain/identity-provider/identityProvider';
import { ConfigureProviderDialog } from './ConfigureProviderDialog';
import { useUnconfiguredProviderOptions } from './LoginSettingsSection.model';
import { LoginSettingsSectionTable } from './LoginSettingsSectionTable';

export type Props = {
  localLoginEnabled: Readonly<boolean>;
  identityProviders: readonly AccountIdentityProvider[] | null;
  accountSubdomain?: Readonly<string>;
};

export const LoginSettingsSection: VFC<Props> = ({
  localLoginEnabled,
  identityProviders,
  accountSubdomain,
}) => {
  const dropdownBtnRef = useRef<null | HTMLButtonElement>(null);
  const [isDropdownBtnExpanded, setIsDropdownBtnExpanded] = useState(false);

  const [openedDialogProviderType, setOpenedDialogProviderType] =
    useState<IdentityProviderType | null>(null);

  const canAddItentityProvider = useCheckPermissions(
    [permissionsByEntity.Account.FULL, permissionsByEntity.GlobalAccount.FULL],
    false
  );

  const unconfiguredProviderOptions = useUnconfiguredProviderOptions(identityProviders);

  return (
    <PageGridSection
      size="medium"
      title="Login Settings"
      actions={
        canAddItentityProvider && unconfiguredProviderOptions.length ? (
          <Fragment>
            <DropdownButton
              ref={dropdownBtnRef}
              aria-controls="login-setting-section-providers-menu"
              isExpanded={isDropdownBtnExpanded}
              onClick={() => setIsDropdownBtnExpanded(isExpanded => !isExpanded)}
            >
              Add Identity Provider
            </DropdownButton>
            <Menu
              open={isDropdownBtnExpanded}
              onClose={() => setIsDropdownBtnExpanded(false)}
              id="login-setting-section-providers-menu"
              anchorEl={dropdownBtnRef.current}
              MenuListProps={{
                'aria-label': 'Available Identity Providers',
              }}
            >
              {unconfiguredProviderOptions.map(option => (
                <MenuItem
                  key={option.type}
                  onClick={() => {
                    setOpenedDialogProviderType(option.type);
                    setIsDropdownBtnExpanded(false);
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </Fragment>
        ) : undefined
      }
      content={
        <Fragment>
          <Typography>
            You cannot have both Local Login and SSO Provider Login enabled at the same time.
          </Typography>
          <LoginSettingsSectionTable
            accountSubdomain={accountSubdomain}
            identityProviders={identityProviders}
            localLoginEnabled={localLoginEnabled}
          />
          <ConfigureProviderDialog
            openedDialogProviderType={openedDialogProviderType}
            onClose={() => setOpenedDialogProviderType(null)}
          />
        </Fragment>
      }
    />
  );
};
