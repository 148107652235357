import { FC, Fragment, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import { IdentityPageContent } from '../../../../design-system/IdentityPageContent';
import { selectAccountType } from '../../../account-detail/shared/ducks';
import { InvestorCoverageSection } from '../../shared/investor-coverage/InvestorCoverageSection';
import {
  changeUsername,
  changeUsernameDuckParts,
  fetchAccountRoles,
  fetchEmployeeInvestorCoverage,
  fetchUser,
  resendInviteEmail,
  resendInviteEmailDuckParts,
  resetUserDetailState,
  resetUserPassword,
  resetUserPasswordDuckParts,
  selectChangeUsernameError,
  selectChangeUsernameLoading,
  selectEmployeeInvestorCoverage,
  selectEmployeeInvestorCoverageError,
  selectEmployeeInvestorCoverageLoading,
  selectResendInviteEmailError,
  selectResendInviteEmailLoading,
  selectResetPasswordError,
  selectResetPasswordLoading,
  selectRoles,
  selectUnlockUserError,
  selectUnlockUserLoading,
  selectUpdateUserDetailsError,
  selectUpdateUserDetailsLoading,
  selectUpdateUserRolesError,
  selectUpdateUserRolesLoading,
  selectUpdateUserStatusError,
  selectUpdateUserStatusLoading,
  selectUser,
  selectUserLoading,
  selectUserUpdating,
  unlockUser,
  unlockUserDuckParts,
  updateUserDetails,
  updateUserDetailsDuckParts,
  updateUserRoles,
  updateUserRolesDuckParts,
  updateUserStatus,
  updateUserStatusDuckParts,
} from './../../../account-detail/user-detail/ducks';
import { AccountUserDetailRouteSkeleton } from './AccountUserDetailRouteSkeleton';
import { LoginDetailsSection } from './grid-content/LoginDetailsSection';
import { RolesSection } from './grid-content/roles/RolesSection';
import { UserDetailsSection } from './grid-content/UserDetailsSection';
import { UserHighlights } from './highlights/UserHighlights';
import { useEmployeeInvestorCoverageFetch } from './hooks/useEmployeeInvestorCoverageFetch';

const mapStateToProps = state => ({
  accountType: selectAccountType(state),
  userLoading: selectUserLoading(state),
  userUpdating: selectUserUpdating(state),
  updateUserDetailsLoading: selectUpdateUserDetailsLoading(state),
  updateUserDetailsError: selectUpdateUserDetailsError(state),
  updateUserRolesLoading: selectUpdateUserRolesLoading(state),
  updateUserRolesError: selectUpdateUserRolesError(state),
  resendInviteLoading: selectResendInviteEmailLoading(state),
  resendInviteError: selectResendInviteEmailError(state),
  resetPasswordLoading: selectResetPasswordLoading(state),
  resetPasswordError: selectResetPasswordError(state),
  unlockUserLoading: selectUnlockUserLoading(state),
  unlockUserError: selectUnlockUserError(state),
  employeeInvestorCoverage: selectEmployeeInvestorCoverage(state),
  employeeInvestorCoverageLoading: selectEmployeeInvestorCoverageLoading(state),
  employeeInvestorCoverageError: selectEmployeeInvestorCoverageError(state),
  changeUsernameError: selectChangeUsernameError(state),
  changeUsernameLoading: selectChangeUsernameLoading(state),
  updateUserStatusError: selectUpdateUserStatusError(state),
  updateUserStatusLoading: selectUpdateUserStatusLoading(state),
  roles: selectRoles(state),
  user: selectUser(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUser,
      fetchAccountRoles,
      fetchEmployeeInvestorCoverage,
      updateUserStatus,
      resetUserStatusUpdate: updateUserStatusDuckParts.actionCreators.reset,
      updateUserDetails,
      resetUserDetailsUpdate: updateUserDetailsDuckParts.actionCreators.reset,
      updateUserRoles,
      resetUserRolesUpdate: updateUserRolesDuckParts.actionCreators.reset,
      unlockUser,
      resetUserUnlock: unlockUserDuckParts.actionCreators.reset,
      resendInviteEmail,
      resetInviteEmailResend: resendInviteEmailDuckParts.actionCreators.reset,
      resetUserPassword,
      resetUserPasswordReset: resetUserPasswordDuckParts.actionCreators.reset,
      resetUserDetailState,
      changeUsername,
      resetUsernameChange: changeUsernameDuckParts.actionCreators.reset,
    },
    dispatch
  ),
});

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<
    Readonly<{
      userId?: Readonly<string>;
    }>
  > &
  Readonly<{
    canViewCoverage: Readonly<boolean>;
    canCreateOrEditUser?: Readonly<boolean>;
  }>;

export const AccountUserDetailRouteComponent: FC<Props> = ({
  accountType,
  userLoading,
  user,
  userUpdating,
  resendInviteError,
  resendInviteLoading,
  resetPasswordError,
  resetPasswordLoading,
  updateUserDetailsError,
  updateUserDetailsLoading,
  updateUserRolesError,
  updateUserRolesLoading,
  unlockUserLoading,
  unlockUserError,
  employeeInvestorCoverage,
  employeeInvestorCoverageLoading,
  changeUsernameError,
  changeUsernameLoading,
  updateUserStatusError,
  updateUserStatusLoading,
  canViewCoverage,
  canCreateOrEditUser,
  roles,
  actions: {
    fetchUser,
    fetchAccountRoles,
    fetchEmployeeInvestorCoverage,
    updateUserStatus,
    resetUserStatusUpdate,
    resendInviteEmail,
    resetInviteEmailResend,
    unlockUser,
    resetUserUnlock,
    updateUserDetails,
    resetUserDetailsUpdate,
    updateUserRoles,
    resetUserRolesUpdate,
    resetUserDetailState,
    resetUserPassword,
    resetUserPasswordReset,
    changeUsername,
    resetUsernameChange,
  },
  match,
}) => {
  useEffect(() => {
    if (!match.params.userId) {
      return;
    }

    fetchUser({
      userId: match.params.userId,
    });
  }, [fetchUser, match.params.userId]);

  useEffect(() => {
    fetchAccountRoles(undefined);
  }, [fetchAccountRoles]);

  const { investorCoverageFilters, onCoverageFiltersChange, onCoveragePaginationChange } =
    useEmployeeInvestorCoverageFetch({
      canViewCoverage,
      user,
      onFetchEmployeeInvestorCoverage: fetchEmployeeInvestorCoverage,
    });

  useEffect(() => {
    return () => {
      resetUserDetailState();
    };
  }, [resetUserDetailState]);

  const resetLoginDetailsSection = useCallback(() => {
    resetUsernameChange();
    resetUserPasswordReset();
  }, [resetUserPasswordReset, resetUsernameChange]);

  const resetHighlightsActions = useCallback(() => {
    resetUserStatusUpdate();
    resetUserUnlock();
    resetInviteEmailResend();
  }, [resetInviteEmailResend, resetUserStatusUpdate, resetUserUnlock]);

  if (userLoading) {
    return <AccountUserDetailRouteSkeleton />;
  }

  if (!user) {
    return null;
  }

  return (
    <IdentityPageContent
      localHighlights={
        <UserHighlights
          onReset={resetHighlightsActions}
          isLoading={
            userUpdating || resendInviteLoading || unlockUserLoading || updateUserStatusLoading
          }
          user={user}
          canCreateOrEditUser={!!canCreateOrEditUser}
          unlockUserError={unlockUserError}
          onUnlockUser={() => unlockUser({ userId: user.id })}
          updateUserStatusError={updateUserStatusError}
          onUpdateUserStatus={status =>
            updateUserStatus({
              userId: user.id,
              status,
            })
          }
          resendInviteError={resendInviteError}
          onResendInviteEmail={() => resendInviteEmail({ userId: user.id })}
        />
      }
      gridContent={
        <Fragment>
          <UserDetailsSection
            canEdit={!!canCreateOrEditUser}
            isSubmitting={updateUserDetailsLoading}
            submitError={updateUserDetailsError}
            user={user}
            accountType={accountType}
            onUserDetailsUpdate={userDetails => updateUserDetails({ userId: user.id, userDetails })}
            onReset={resetUserDetailsUpdate}
          />
          <LoginDetailsSection
            onReset={resetLoginDetailsSection}
            canEdit={!!canCreateOrEditUser}
            user={user}
            isResettingPassword={resetPasswordLoading}
            resetPasswordError={resetPasswordError}
            onResetPassword={() => resetUserPassword({ userId: user.id })}
            isUpdatingEmail={changeUsernameLoading}
            updateEmailError={changeUsernameError}
            onUpdateEmail={email => changeUsername({ userId: user.id, email })}
          />
          <RolesSection
            canEdit={!!canCreateOrEditUser}
            userRoles={user.roles}
            allRoles={roles}
            isLoading={updateUserRolesLoading}
            error={updateUserRolesError}
            onUserRolesUpdate={userRoles => updateUserRoles({ userId: user.id, userRoles })}
            onReset={() => resetUserRolesUpdate()}
          />
          {employeeInvestorCoverage && (
            <InvestorCoverageSection
              isLoading={employeeInvestorCoverageLoading}
              investorCoverage={employeeInvestorCoverage.data ?? []}
              pagination={employeeInvestorCoverage.pagination ?? {}}
              filters={investorCoverageFilters}
              onChangeFilters={onCoverageFiltersChange}
              onChangePage={onCoveragePaginationChange}
            />
          )}
        </Fragment>
      }
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountUserDetailRouteComponent);
