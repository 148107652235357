import { FirmCategoryType, RecordStatus } from '@capital-markets-gateway/api-client-rolodex';
import { Option } from '@cmg/common';

export enum LinkedStatus {
  'LINKED' = 'LINKED',
  'NOT_LINKED' = 'NOT_LINKED',
}

export const linkedStatusLabels = {
  [LinkedStatus.LINKED]: 'Linked',
  [LinkedStatus.NOT_LINKED]: 'Not Linked',
};

export const linkedStatusOptions = Object.keys(LinkedStatus).map(
  status =>
    ({
      label: linkedStatusLabels[status],
      value: status,
    } as Option<LinkedStatus>)
);

export const firmCategoryLabels = {
  [FirmCategoryType.PWM]: 'PWM Investor',
  [FirmCategoryType.FREE_TEXT]: 'Free Text Investor',
  [FirmCategoryType.EXTENSION]: 'Extension Investor',
  [FirmCategoryType.REGULAR]: 'Regular Investor',
};

export const firmCategoryOptions = Object.keys(FirmCategoryType).map<Option<FirmCategoryType>>(
  status =>
    ({
      label: firmCategoryLabels[status],
      value: status,
    } as Option<FirmCategoryType>)
);

export const firmCategoryOrganizationsLabels = {
  [FirmCategoryType.PWM]: 'PWM',
  [FirmCategoryType.FREE_TEXT]: 'Free Text',
  [FirmCategoryType.EXTENSION]: 'Extension',
  [FirmCategoryType.REGULAR]: 'Regular',
};

export enum firmCategoryOrganizations {
  EXTENSION = 'Extension',
  REGULAR = 'Regular',
}

export const firmCategoryOrganizationsOptions = Object.keys(firmCategoryOrganizations).map<
  Option<firmCategoryOrganizations>
>(
  firmType =>
    ({
      label: firmCategoryOrganizationsLabels[firmType],
      value: firmType,
    } as Option<firmCategoryOrganizations>)
);

export const firmStatusLabels = {
  [RecordStatus.DELETED]: 'Deleted',
  [RecordStatus.EFFECTIVE]: 'Effective',
};

export const firmStatusOptions = Object.keys(RecordStatus).map<Option<RecordStatus>>(
  statusType =>
    ({
      label: firmStatusLabels[statusType],
      value: statusType,
    } as Option<RecordStatus>)
);
