import { ApolloProvider } from '@apollo/client';
import { AuthProvider, AuthProviderConfig } from '@cmg/auth';
import { ErrorBoundary, GlobalStyle, ThemeProvider, ToastManager } from '@cmg/common';
import { DesignSystemProvider, DesignSystemProviderProps } from '@cmg/design-system';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import graphqlApiClient from '../../common/api/graphqlApiClient';
import { IdentityAppLayout } from '../../common/components/layout/design-system/app-layout/IdentityAppLayout';
import { getAppSettings } from '../../common/config/appSettings';
import { AppSettingsContextProvider } from '../../common/config/context/AppSettingsContext';
import store from '../../common/redux/store';
import routeFactory from '../../common/util/routeFactory';
import { ReactRouterMUILink } from '../../design-system/react-router/ReactRouterMUILink';

const appSettings = getAppSettings();

const designSystemProviderProps: DesignSystemProviderProps = {
  themeConfig: { LinkComponent: ReactRouterMUILink },
};

const authConfig: AuthProviderConfig = {
  env: {
    isDevelopment: appSettings.env.isDevelopment,
    rootDomain: appSettings.env.rootDomain,
    secureCookies: appSettings.env.secureCookies,
  },
  client: {
    basename: appSettings.client.basename,
  },
  logging: {
    appName: 'Identity',
    mixPanelToken: appSettings.logging.mixPanelToken,
  },
  auth: {
    clientId: appSettings.auth.clientId,
    oidcAuthorityBaseUrl: appSettings.auth.oidcAuthorityBaseUrl,
    tokenRenewalInterval: appSettings.auth.tokenRenewalIntervalInMinutes,
    scopes: ['mgmt_api:full', 'xc:full'],
  },
  actions: {
    onLogout: () => {
      window.location.href = routeFactory.loggedOut.getAbsoluteUrlPath();
    },
  },
};

const App: React.FC = () => (
  <AuthProvider authConfig={authConfig}>
    <Provider store={store}>
      <AppSettingsContextProvider>
        <GlobalStyle />
        <ApolloProvider client={graphqlApiClient}>
          <DesignSystemProvider {...designSystemProviderProps}>
            <ThemeProvider>
              <BrowserRouter basename={appSettings.client.basename}>
                <ErrorBoundary>
                  <IdentityAppLayout />
                  {/* ToastManager is only included because useIdleMonitor is coupled to it */}
                  <ToastManager />
                </ErrorBoundary>
              </BrowserRouter>
            </ThemeProvider>
          </DesignSystemProvider>
        </ApolloProvider>
      </AppSettingsContextProvider>
    </Provider>
  </AuthProvider>
);

export default App;
