import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import { FlexContainer, Popover, SuccessButton, Tag } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Status from '../../../../../../common/components/indicators/status/Status';
import DetailHeader from '../../../../../../common/components/layout/detail-header/DetailHeader';
import routeFactory from '../../../../../../common/util/routeFactory';
import { FirmDataSource } from '../../../../../../types/domain/firm/constants';
import { Firm } from '../../../../../../types/domain/firm/Firm';
import { firmStatusLabels } from '../../../../../../types/domain/firm-status/constants';
import { FirmStatus } from '../../../../../../types/domain/firm-status/FirmStatus';
import { recordStatusLabels } from '../../../../../../types/domain/record-status/constants';
import { RecordStatus } from '../../../../../../types/domain/record-status/RecordStatus';
import { OnPlatformIcon } from '../../../../shared/OnPlatformIcon';
import { selectMatchFirmDetail, selectMatchFirmType } from '../../../ducks';
import { IconWrapper } from '../../crm-investor-firm-detail-header/CrmInvestorFirmDetailHeader';
import { openLinkOrganizationModal } from '../../link-organization-modal/LinkOrganizationModal';
import { StyledIconButton } from '../MatchDetailPanel.styles';
import { isCmgOrCustomerCreatedFirmType, isFirmStoreLocally } from './MatchDetailHeader.model';
import {
  SButtonWrapper,
  SIconWrapper,
  SSticky,
  StyledDangerButton,
  StyledDetailHeaderColumn,
  StyledDetailHeaderTitleWrapper,
  StyledPrimaryLinkButton,
  StyledSecondaryOpenButton,
} from './MatchDetailHeader.styles';

const mapStateToProps = state => ({
  firm: selectMatchFirmDetail(state),
  firmType: selectMatchFirmType(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openLinkOrganizationModal,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  /**
   *  The ID of the CRM Integration that the Investor Firm belongs to.
   */
  crmIntegrationId: string;
  /**
   * The ID of the CRM Investor Firm being viewed
   */
  crmInvestorFirmId: string;
  /**
   * The ID of the Match Firm whose details are being viewed
   */
  matchFirmId: string;
  cmgEntityKey?: string | null;
};
export type Props = OwnProps & StateProps & DispatchProps;

/**
 * The header for the CRM Match Detail.
 */
export const MatchDetailHeaderComponent: React.FC<Props> = ({
  crmIntegrationId,
  crmInvestorFirmId,
  matchFirmId,
  firm,
  firmType,
  actions,
  cmgEntityKey,
}) => {
  const name = firm && firm.names && firm.names.find(name => name.isDisplayName);
  const { userPermissions } = useAuth();
  const canLink = checkPermissions(userPermissions, [permissionsByEntity.Firm.FULL]);
  return (
    firm && (
      <SSticky>
        <DetailHeader.Header>
          <DetailHeader.Row>
            <StyledIconButton
              asComponent={Link}
              size="large"
              icon={{ name: 'times', variant: 'solid' }}
              to={routeFactory.rolodexCrmEntityMatcher.getUrlPath({
                crmIntegrationId,
                crmInvestorFirmId,
              })}
            />
            <StyledDetailHeaderColumn>
              <StyledDetailHeaderTitleWrapper>
                <DetailHeader.Column>
                  <DetailHeader.Title>
                    <FlexContainer direction="row" gap={4} alignItems="baseline">
                      <OnPlatformIcon isOnPlatform={firm.isOnPlatform} size="M" />
                      {name ? name.value : 'Unknown'}
                    </FlexContainer>
                  </DetailHeader.Title>
                  {(firm as Firm).key && (
                    <DetailHeader.DetailWrapper>
                      <SIconWrapper>
                        <IconWrapper name="chart-line" />
                        <span>Investor</span>
                      </SIconWrapper>
                      <DetailHeader.DetailLabel>ID</DetailHeader.DetailLabel>
                      <DetailHeader.DetailValue>{(firm as Firm).key}</DetailHeader.DetailValue>
                    </DetailHeader.DetailWrapper>
                  )}
                </DetailHeader.Column>
                <div>
                  {isFirmStoreLocally(firmType, firm) && (
                    <DetailHeader.Row>
                      <Popover
                        variant="TOOLTIP"
                        content="This organization is not connected to the FactSet Database"
                      >
                        <Tag>Local</Tag>
                      </Popover>
                    </DetailHeader.Row>
                  )}
                  <DetailHeader.Row>
                    <Status
                      isActive={firm.details.status === FirmStatus.ACTIVE}
                      activeLabel={firmStatusLabels[FirmStatus.ACTIVE]}
                      inactiveLabel={firmStatusLabels[FirmStatus.DEFUNCT]}
                    />
                  </DetailHeader.Row>
                  <DetailHeader.Row>
                    <Status
                      isActive={firm.details.recordStatus === RecordStatus.EFFECTIVE}
                      activeLabel={recordStatusLabels[RecordStatus.EFFECTIVE]}
                      inactiveLabel={recordStatusLabels[RecordStatus.DELETED]}
                    />
                  </DetailHeader.Row>
                </div>
                <div>
                  <SButtonWrapper>
                    {canLink &&
                      isCmgOrCustomerCreatedFirmType(firmType) &&
                      cmgEntityKey !== firm.key && (
                        <StyledPrimaryLinkButton
                          onClick={() =>
                            actions.openLinkOrganizationModal({
                              linkTargetFirmId: matchFirmId,
                              linkTargetFirmSource: FirmDataSource.CMG,
                            })
                          }
                        >
                          Link
                        </StyledPrimaryLinkButton>
                      )}
                    {canLink &&
                      isCmgOrCustomerCreatedFirmType(firmType) &&
                      cmgEntityKey === firm.key && (
                        <StyledDangerButton onClick={() => actions.openLinkOrganizationModal({})}>
                          Unlink
                        </StyledDangerButton>
                      )}
                    {canLink && firmType === FirmDataSource.FACT_SET && (
                      <SuccessButton
                        onClick={() =>
                          actions.openLinkOrganizationModal({
                            linkTargetFirmId: matchFirmId,
                            linkTargetFirmSource: FirmDataSource.FACT_SET,
                          })
                        }
                      >
                        Create &amp; Link
                      </SuccessButton>
                    )}
                    {isCmgOrCustomerCreatedFirmType(firmType) && (
                      <StyledSecondaryOpenButton
                        asComponent={Link}
                        to={routeFactory.rolodexFirmDetail.getUrlPath({
                          firmId: (firm as Firm).id,
                        })}
                      >
                        Open
                      </StyledSecondaryOpenButton>
                    )}
                  </SButtonWrapper>
                </div>
              </StyledDetailHeaderTitleWrapper>
            </StyledDetailHeaderColumn>
          </DetailHeader.Row>
        </DetailHeader.Header>
      </SSticky>
    )
  );
};

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(MatchDetailHeaderComponent);
