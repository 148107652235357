export enum ApiKeyStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  REVOKED = 'REVOKED',
}
export const ApiKeyStatusLabels = {
  [ApiKeyStatus.ACTIVE]: 'Active',
  [ApiKeyStatus.EXPIRED]: 'Expired',
  [ApiKeyStatus.REVOKED]: 'Revoked',
};
