import { apiTypes, Banner, DataGrid } from '@cmg/common';
import { Autocomplete, AutocompleteTextFieldProps, SearchTextField } from '@cmg/design-system';
import {
  accountUserListSelector,
  userListAccountConfirmedTypeSelectSelector,
  userListJobFunctionTypeSelectSelector,
  userListSearchInputSelector,
  userListStatusTypeSelectSelector,
} from '@cmg/e2e-selectors';
import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

import { AccountUserFilters } from '../../../../../common/api/types/AccountUserFilters';
import { useFeatureToggles } from '../../../../../common/config/context/useFeatureToggles';
import { TableFilter } from '../../../../../design-system/table/TableFilter';
import { TableFilterList } from '../../../../../design-system/table/TableFilterList';
import { Account } from '../../../../../types/domain/account/account';
import { AccountType } from '../../../../../types/domain/account/constants';
import {
  accountConfirmedOptions,
  JobFunction,
  userStatusOptions,
} from '../../../../../types/domain/user/constants';
import UserBasic from '../../../../../types/domain/user/UserBasic';
import { getFlatJobFunctionOptions } from './UserList.model';
import { SUserListWrapper } from './UserList.styles';
import UserListGridColumns from './UserListGridColumns';

export const SFilters = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 0;

  ${({ theme }) => theme.mediaQuery.largeUp} {
    flex-direction: row;
  }
`;

export const SField = styled.div`
  margin: 0 10px 10px 0;
  width: 100%;

  &:not(:first-child) {
    width: 50%;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const SSelectGroupLabel = styled.div`
  padding: 10px 8px;
`;

type Props = {
  loading: boolean;
  account: Account;
  accountType: AccountType | 'SYSTEM' | null;
  users: UserBasic[];
  filters?: AccountUserFilters;
  pagination: apiTypes.Pagination;
  error: boolean;
  onChangeFilters?: (params: AccountUserFilters, debounce?: boolean) => void;
  onChangePage: (params: apiTypes.ListParams) => void;
  onDownload?: () => void;
};

/**
 * Displays Users for a particular Account in a table view
 */
const UserList: React.FC<Props> = ({
  loading,
  account,
  accountType,
  users,
  filters,
  error,
  pagination,
  onChangePage,
  onChangeFilters,
  onDownload,
}) => {
  const { isUserListDownloadEnabled } = useFeatureToggles();
  const handleTextChange = searchText =>
    onChangeFilters && onChangeFilters({ ...filters, searchText }, true);
  const handleStatusChange = userStatus =>
    onChangeFilters && onChangeFilters({ ...filters, userStatus });
  const handleJobFunctionChange = (jobFunction: JobFunction | null) =>
    onChangeFilters && onChangeFilters({ ...filters, jobFunction });

  const handleAccountConfirmedChange = (accountConfirmed: boolean | null) => {
    onChangeFilters?.({ ...filters, accountConfirmed });
  };
  // Temporaly fix for Ag-Grid Cell Renderer Framework
  const rows = users.map((user: UserBasic) => ({
    user: user,
    account: account,
    accountType: accountType,
    firstName: user.firstName,
    lastName: user.lastName,
    employeeKey: user.employeeKey,
    jobFunction: user.jobFunction,
    email: user.email,
  }));

  const flatJobFunctionOptions = useMemo(() => {
    return getFlatJobFunctionOptions(accountType);
  }, [accountType]);

  const statusTextFieldProps: AutocompleteTextFieldProps = useMemo(
    () => ({
      label: 'Status',
      placeholder: 'Select Status',
    }),
    []
  );

  const jobFunctionTextFieldProps: AutocompleteTextFieldProps = useMemo(
    () => ({
      label: 'Job Function',
      placeholder: 'Filter by Job Function...',
    }),
    []
  );

  return (
    <SUserListWrapper data-test-id={accountUserListSelector.testId}>
      {error && <Banner variant="error">Could not load users...</Banner>}

      <DataGrid<{
        user: UserBasic;
        account: Account;
        accountType: AccountType | 'SYSTEM' | null;
        firstName: string | null;
        lastName: string | null;
        employeeKey: string | null;
        jobFunction: string | null;
        email: string | null;
      }>
        renderFilters={() => (
          <React.Fragment>
            {onChangeFilters && (
              <TableFilterList
                filters={
                  <React.Fragment>
                    <TableFilter sm={12}>
                      <SearchTextField
                        data-test-id={userListSearchInputSelector.testId}
                        label="Search"
                        placeholder="Filter by Name, Email, Employee Key..."
                        onChange={e => handleTextChange(e.target.value)}
                      />
                    </TableFilter>
                    <TableFilter>
                      <Autocomplete
                        data-test-id={userListStatusTypeSelectSelector.testId}
                        options={userStatusOptions.map(option => option.value)}
                        getOptionLabel={optionValue =>
                          userStatusOptions.find(o => o.value === optionValue)?.label ?? ''
                        }
                        value={filters?.userStatus ?? null}
                        onChange={(_e, value) => handleStatusChange(value)}
                        TextFieldProps={statusTextFieldProps}
                      />
                    </TableFilter>
                    <TableFilter>
                      <Autocomplete
                        data-test-id={userListAccountConfirmedTypeSelectSelector.testId}
                        onChange={(e, value) => handleAccountConfirmedChange(value ?? null)}
                        options={accountConfirmedOptions.map(option => option.value)}
                        getOptionLabel={optionValue =>
                          accountConfirmedOptions.find(opt => opt.value === optionValue)?.label ??
                          ''
                        }
                        value={filters?.accountConfirmed ?? null}
                        TextFieldProps={{
                          label: 'Account Confirmation',
                          placeholder: 'Filter by Account Confirmation...',
                        }}
                      />
                    </TableFilter>
                    <TableFilter>
                      <Autocomplete
                        data-test-id={userListJobFunctionTypeSelectSelector.testId}
                        options={flatJobFunctionOptions.map(option => option.value)}
                        groupBy={optionValue =>
                          flatJobFunctionOptions.find(o => o.value === optionValue)?.group ?? ''
                        }
                        getOptionLabel={optionValue =>
                          flatJobFunctionOptions.find(o => o.value === optionValue)?.label ?? ''
                        }
                        value={filters?.jobFunction ?? null}
                        onChange={(_e, value) => handleJobFunctionChange(value)}
                        TextFieldProps={jobFunctionTextFieldProps}
                      />
                    </TableFilter>
                  </React.Fragment>
                }
              />
            )}
          </React.Fragment>
        )}
        extended={{
          withMargin: false,
          onDownload: isUserListDownloadEnabled ? onDownload : undefined,
          downloadTitle: 'Download Users',
        }}
        columns={UserListGridColumns}
        rows={rows}
        resizeBy="grid"
        totalPages={pagination.totalPages}
        pagination={{
          page: pagination.activePage,
          perPage: pagination.perPage,
        }}
        onPaginationChange={({ page, perPage, orderField, orderDirection }) => {
          const params = {
            page,
            perPage,
            orderField,
            orderDirection:
              orderDirection === 'asc' ? apiTypes.SortDirection.ASC : apiTypes.SortDirection.DESC,
          };

          onChangePage(params);
        }}
        gridOptions={{
          suppressCellSelection: true,
          suppressRowClickSelection: true,
          overlayNoRowsTemplate: 'No users found',
        }}
        loading={loading}
        getRowNodeId={data => data.user.id}
      />
    </SUserListWrapper>
  );
};

export default UserList;
