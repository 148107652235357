import { apiTypes, Banner, CenteredPage, ServerErrors } from '@cmg/common';
import { loginScreenSelector } from '@cmg/e2e-selectors';
import React from 'react';

import { LoginUserPassFailureBody } from '../../../../../../common/api/identityApiClient';
import DBLoginForm from '../dbLoginForm/DBLoginForm';

type Props = {
  onSubmitDBLoginForm: (values: { username: string; password: string }) => void;
  loginError: LoginUserPassFailureBody | apiTypes.GenericServerError | null;
  submitting: boolean;
};

/**
 * Styles and lays out the the various login options in a centered box.
 */
const LoginScreen: React.FC<Props> = ({ onSubmitDBLoginForm, loginError, submitting }) => (
  <CenteredPage title="Log in to your account" data-test-id={loginScreenSelector.testId}>
    {loginError && (
      <Banner variant="error" showIcon={false}>
        {/* note: the login response varies based on the code that is returned from the server,
          details and target are not returned so we add them here as a one-off */}
        <ServerErrors
          error={{
            target: '',
            details: [],
            ...loginError,
          }}
        />
      </Banner>
    )}
    {/* TODO - as we introduce more login options, we will add them here */}
    <DBLoginForm onSubmitDBLoginForm={onSubmitDBLoginForm} submitting={submitting} />
  </CenteredPage>
);

export default LoginScreen;
