import {
  InvestorCoverageRead,
  InvestorCoverageReadPagedList,
  PaginationMetadata,
} from '@capital-markets-gateway/api-client-rolodex';
import { apiTypes, reduxUtil } from '@cmg/common';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import identityApiClient, {
  FetchProfileResponse,
  UpdateProfileResponse,
} from '../../../common/api/identityApiClient';
import rolodexApiClient, {
  FetchInvestorCoverageResponse,
  GetInvestorCoverageParams,
} from '../../../common/api/rolodexApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { Profile, ProfileUpdate } from '../../../types/domain/self/profile';

const createReducer = reduxUtil.createReducer;

/**
 * ACTION TYPES
 */
enum ActionTypes {
  FETCH_PROFILE_REQUEST = 'identity/FETCH_PROFILE_REQUEST',
  FETCH_PROFILE_SUCCESS = 'identity/FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_FAILURE = 'identity/FETCH_PROFILE_FAILURE',
  UPDATE_PROFILE_REQUEST = 'identity/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS = 'identity/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE = 'identity/UPDATE_PROFILE_FAILURE',
  FETCH_INVESTOR_COVERAGE_REQUEST = 'rolodex/FETCH_INVESTOR_COVERAGE_REQUEST',
  FETCH_INVESTOR_COVERAGE_SUCCESS = 'rolodex/FETCH_INVESTOR_COVERAGE_SUCCESS',
  FETCH_INVESTOR_COVERAGE_FAILURE = 'rolodex/FETCH_INVESTOR_COVERAGE_FAILURE',
}

/**
 * ACTION CREATORS
 */
export const fetchProfile = () => ({
  type: ActionTypes.FETCH_PROFILE_REQUEST,
});

export const fetchProfileSuccess = (params: { profile: Profile }) => ({
  type: ActionTypes.FETCH_PROFILE_SUCCESS,
  payload: {
    profile: params.profile,
  },
});

export const fetchProfileFailure = (error: apiTypes.GenericServerError) => ({
  type: ActionTypes.FETCH_PROFILE_FAILURE,
  payload: {
    error,
  },
});

export const updateProfile = (params: { profile: ProfileUpdate }) => ({
  type: ActionTypes.UPDATE_PROFILE_REQUEST,
  payload: {
    profile: params.profile,
  },
});

export const updateProfileSuccess = (params: { profile: Profile }) => ({
  type: ActionTypes.UPDATE_PROFILE_SUCCESS,
  payload: {
    profile: params.profile,
  },
});

export const updateProfileFailure = (error: apiTypes.GenericServerError) => ({
  type: ActionTypes.UPDATE_PROFILE_FAILURE,
  payload: {
    error,
  },
});

export const fetchInvestorCoverage = (params: GetInvestorCoverageParams) => ({
  type: ActionTypes.FETCH_INVESTOR_COVERAGE_REQUEST,
  payload: params,
});

export const fetchInvestorCoverageSuccess = (params: {
  investorCoverage: InvestorCoverageReadPagedList;
}) => ({
  type: ActionTypes.FETCH_INVESTOR_COVERAGE_SUCCESS,
  payload: {
    investorCoverage: params.investorCoverage,
  },
});

export const fetchInvestorCoverageFailure = (error: apiTypes.GenericServerError | null) => ({
  type: ActionTypes.FETCH_INVESTOR_COVERAGE_FAILURE,
  payload: {
    error,
  },
});

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.FETCH_PROFILE_REQUEST]: ReturnType<typeof fetchProfile>;
  [ActionTypes.FETCH_PROFILE_SUCCESS]: ReturnType<typeof fetchProfileSuccess>;
  [ActionTypes.FETCH_PROFILE_FAILURE]: ReturnType<typeof fetchProfileFailure>;
  [ActionTypes.UPDATE_PROFILE_REQUEST]: ReturnType<typeof updateProfile>;
  [ActionTypes.UPDATE_PROFILE_SUCCESS]: ReturnType<typeof updateProfileSuccess>;
  [ActionTypes.UPDATE_PROFILE_FAILURE]: ReturnType<typeof updateProfileFailure>;
  [ActionTypes.FETCH_INVESTOR_COVERAGE_REQUEST]: ReturnType<typeof fetchInvestorCoverage>;
  [ActionTypes.FETCH_INVESTOR_COVERAGE_SUCCESS]: ReturnType<typeof fetchInvestorCoverageSuccess>;
  [ActionTypes.FETCH_INVESTOR_COVERAGE_FAILURE]: ReturnType<typeof fetchInvestorCoverageFailure>;
};

/**
 * REDUCERS
 */
export type ReducerState = {
  error: apiTypes.GenericServerError | null;
  isProfileLoading: boolean;
  isInvestorCoverageLoading: boolean;
  submitting: boolean;
  profile: Profile | null;
  investorCoverage: InvestorCoverageRead[];
  pagination: PaginationMetadata;
};

export const initialState = {
  error: null,
  isProfileLoading: false,
  isInvestorCoverageLoading: false,
  submitting: false,
  profile: null,
  investorCoverage: [],
  pagination: {},
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.FETCH_PROFILE_REQUEST]: () => null,
  [ActionTypes.FETCH_PROFILE_SUCCESS]: () => null,
  [ActionTypes.FETCH_PROFILE_FAILURE]: (curState, { payload }) => payload.error,
  [ActionTypes.UPDATE_PROFILE_REQUEST]: () => null,
  [ActionTypes.UPDATE_PROFILE_SUCCESS]: () => null,
  [ActionTypes.UPDATE_PROFILE_FAILURE]: (curState, { payload }) => payload.error,
  [ActionTypes.FETCH_INVESTOR_COVERAGE_REQUEST]: () => null,
  [ActionTypes.FETCH_INVESTOR_COVERAGE_SUCCESS]: () => null,
  [ActionTypes.FETCH_INVESTOR_COVERAGE_FAILURE]: (curState, { payload }) => payload.error,
});

const profileReducer = createReducer<ReducerState['profile'], Actions>(initialState.profile, {
  [ActionTypes.FETCH_PROFILE_SUCCESS]: (curState, { payload }) => payload.profile,
  [ActionTypes.UPDATE_PROFILE_SUCCESS]: (curState, { payload }) => payload.profile,
});

const investorCoverageReducer = createReducer<ReducerState['investorCoverage'], Actions>(
  initialState.investorCoverage,
  {
    [ActionTypes.FETCH_INVESTOR_COVERAGE_SUCCESS]: (curState, { payload }) =>
      payload.investorCoverage.data ?? [],
  }
);

const profileLoadingReducer = createReducer<ReducerState['isProfileLoading'], Actions>(
  initialState.isProfileLoading,
  {
    [ActionTypes.FETCH_PROFILE_REQUEST]: () => true,
    [ActionTypes.FETCH_PROFILE_SUCCESS]: () => false,
    [ActionTypes.FETCH_PROFILE_FAILURE]: () => false,
  }
);

const investorCoverageLoadingReducer = createReducer<
  ReducerState['isInvestorCoverageLoading'],
  Actions
>(initialState.isInvestorCoverageLoading, {
  [ActionTypes.FETCH_INVESTOR_COVERAGE_REQUEST]: () => true,
  [ActionTypes.FETCH_INVESTOR_COVERAGE_SUCCESS]: () => false,
  [ActionTypes.FETCH_INVESTOR_COVERAGE_FAILURE]: () => false,
});

const submittingReducer = createReducer<ReducerState['submitting'], Actions>(
  initialState.submitting,
  {
    [ActionTypes.UPDATE_PROFILE_REQUEST]: () => true,
    [ActionTypes.UPDATE_PROFILE_SUCCESS]: () => false,
    [ActionTypes.UPDATE_PROFILE_FAILURE]: () => false,
  }
);

const paginationReducer = createReducer<ReducerState['pagination'], Actions>(
  initialState.pagination,
  {
    [ActionTypes.FETCH_INVESTOR_COVERAGE_SUCCESS]: (curState, { payload }) =>
      payload.investorCoverage.pagination ?? {},
  }
);

const combinedReducers = combineReducers<ReducerState>({
  error: errorReducer,
  isProfileLoading: profileLoadingReducer,
  isInvestorCoverageLoading: investorCoverageLoadingReducer,
  profile: profileReducer,
  submitting: submittingReducer,
  investorCoverage: investorCoverageReducer,
  pagination: paginationReducer,
});

export default combinedReducers;

/**
 * SELECTORS
 */
const selectState = (state: RootState) => state.profile;
export const selectError = state => selectState(state).error;
export const selectProfileLoading = state => selectState(state).isProfileLoading;
export const selectInvestorCoverageLoading = state => selectState(state).isInvestorCoverageLoading;
export const selectSubmitting = state => selectState(state).submitting;
export const selectProfile = state => selectState(state).profile;
export const selectInvestorCoverage = state => selectState(state).investorCoverage;
export const selectPagination = state => selectState(state).pagination;

/**
 * SAGAS
 */
export function* fetchProfileSaga(): SagaIterator {
  const response: FetchProfileResponse = yield call(identityApiClient.fetchProfile);
  if (response.ok) {
    yield put(fetchProfileSuccess({ profile: response.data }));
  } else {
    yield put(fetchProfileFailure(response.data.error));
  }
}

export function* updateProfileSaga({
  payload,
}: Actions[ActionTypes.UPDATE_PROFILE_REQUEST]): SagaIterator {
  const { profile } = payload;

  const response: UpdateProfileResponse = yield call(identityApiClient.updateProfile, profile);
  if (response.ok) {
    yield put(updateProfileSuccess({ profile: response.data }));
  } else {
    yield put(updateProfileFailure(response.data.error));
  }
}

export function* profileSaga() {
  yield takeLatest<Actions[ActionTypes.FETCH_PROFILE_REQUEST]>(
    ActionTypes.FETCH_PROFILE_REQUEST,
    fetchProfileSaga
  );
  yield takeLatest<Actions[ActionTypes.UPDATE_PROFILE_REQUEST]>(
    ActionTypes.UPDATE_PROFILE_REQUEST,
    updateProfileSaga
  );
}

export function* fetchInvestorCoverageSaga({ payload }): SagaIterator {
  const response: FetchInvestorCoverageResponse = yield call(
    rolodexApiClient.fetchInvestorCoverage,
    payload
  );
  if (response.ok) {
    yield put(
      fetchInvestorCoverageSuccess({
        investorCoverage: response.data,
      })
    );
  } else {
    yield put(fetchInvestorCoverageFailure(response.data.error ?? null));
  }
}

export function* investorCoverageSaga() {
  yield takeLatest<Actions[ActionTypes.FETCH_INVESTOR_COVERAGE_REQUEST]>(
    ActionTypes.FETCH_INVESTOR_COVERAGE_REQUEST,
    fetchInvestorCoverageSaga
  );
}
