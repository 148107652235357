import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { DotStatus, Highlights } from '@cmg/design-system';
import isEqual from 'lodash/isEqual';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IdentityPageContent } from '../../../../../../design-system/IdentityPageContent';
import {
  selectPasswordPolicyError,
  selectPasswordPolicySubmitting,
  updatePasswordPolicy,
} from '../../../../../account-detail/security/ducks';
import {
  selectAccount,
  selectUpdateError,
  selectUpdateSubmitting,
  updateAccount,
} from '../../../../../account-detail/shared/ducks';
import { ProviderDetailsSection } from './ProviderDetailsSection';
import { LocalLoginProviderFormValues } from './utils';

const mapStateToProps = state => ({
  account: selectAccount(state),
  updateAccountError: selectUpdateError(state),
  updateAccountSubmitting: selectUpdateSubmitting(state),
  passwordPolicyError: selectPasswordPolicyError(state),
  passwordPolicySubmitting: selectPasswordPolicySubmitting(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updatePasswordPolicy,
      updateAccount,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = StateProps & DispatchProps;

/**
 * Displays general auth settings for a particular account, like two factor enforcement and account password policy.
 * This will end up having a nested form to update this information.
 */
export const LocalLoginProviderRouteComponent: React.FC<Props> = ({
  passwordPolicyError,
  passwordPolicySubmitting,
  updateAccountError,
  updateAccountSubmitting,
  account,
  actions,
}) => {
  const canEditProvider = useCheckPermissions(
    [permissionsByEntity.GlobalAccount.FULL, permissionsByEntity.Account.FULL],
    false
  );

  const handleUpdatePasswordPolicy = useCallback(
    (formValues: LocalLoginProviderFormValues) => {
      if (!account) {
        return;
      }

      const { requireTwoFactorAuth, ...passwordPolicy } = formValues;
      // update the account when requireTwoFactorAuth changes
      if (requireTwoFactorAuth !== account.requireTwoFactorAuth) {
        actions.updateAccount({
          ...account,
          requireTwoFactorAuth,
        });
      }

      if (!isEqual(passwordPolicy, account.passwordPolicy)) {
        actions.updatePasswordPolicy({
          accountId: account.id,
          passwordPolicy,
        });
      }
    },
    [account, actions]
  );

  if (!account) {
    return null;
  }

  const { localLoginEnabled, passwordPolicy, requireTwoFactorAuth } = account;

  return (
    <IdentityPageContent
      localHighlights={
        <Highlights
          title="Local Login"
          titleItems={
            <DotStatus
              label={localLoginEnabled ? 'Enabled' : 'Inactive'}
              color={localLoginEnabled ? 'success' : 'error'}
            />
          }
        />
      }
      gridContent={
        <ProviderDetailsSection
          requireTwoFactorAuth={requireTwoFactorAuth}
          passwordPolicy={passwordPolicy}
          canEdit={canEditProvider}
          isSubmitting={passwordPolicySubmitting || updateAccountSubmitting}
          error={passwordPolicyError || updateAccountError}
          onSubmit={handleUpdatePasswordPolicy}
        />
      }
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalLoginProviderRouteComponent);
