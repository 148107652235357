import { combineReducers } from 'redux';
import { reducer as modal, ReduxModalState } from 'redux-modal';

import adminAccountApiKeyCreate, {
  ReducerState as ApiKeyCreateReducerState,
} from '../../features/account-detail/api-keys/api-keys-create/ducks';
import adminAccountApiKeyDetail, {
  ReducerState as ApiKeyDetailReducerState,
} from '../../features/account-detail/api-keys/api-keys-detail/ducks';
import adminAccountApiKeyList, {
  ReducerState as ApiKeyListReducerState,
} from '../../features/account-detail/api-keys/api-keys-list/ducks';
import adminAccountRoleDetail, {
  ReducerState as AdminAccountRoleDetailReducerState,
} from '../../features/account-detail/role-detail/ducks';
import adminAccountRolesList, {
  ReducerState as AdminAccountRoleListReducerState,
} from '../../features/account-detail/role-list/ducks';
import adminAccountRolodex, {
  ReducerState as AccountRolodexReducerState,
} from '../../features/account-detail/rolodex/ducks';
import adminAccountSecurity, {
  ReducerState as AdminAccountSecurityReducerState,
} from '../../features/account-detail/security/ducks';
import adminAccountIdentityProviderDetail, {
  ReducerState as AdminIdentityProviderReducerState,
} from '../../features/account-detail/security/identity-providers/ducks';
import adminAccountDetail, {
  ReducerState as AdminAccountReducerState,
} from '../../features/account-detail/shared/ducks';
import adminAccountUserCreate, {
  ReducerState as AdminAccountUserCreateReducerState,
} from '../../features/account-detail/user-create/ducks';
import adminAccountUserDetail, {
  ReducerState as AdminAccountUserDetailReducerState,
} from '../../features/account-detail/user-detail/ducks';
import adminAccountUserList, {
  ReducerState as AdminAccountUserListReducerState,
} from '../../features/account-detail/user-list/ducks';
import adminAccountCreate, {
  ReducerState as AdminAccountCreateReducerState,
} from '../../features/global-management/account-create/ducks';
import adminAccountList, {
  ReducerState as AdminAccountListReducerState,
} from '../../features/global-management/account-list/ducks';
import draftAccountList, {
  ReducerState as DraftAccountListReducerState,
} from '../../features/global-management/draft-account-list/ducks';
import adminTraitDetail, {
  ReducerState as AdminTraitDetailReducerState,
} from '../../features/global-management/trait-detail/ducks';
import adminTraitList, {
  ReducerState as AdminTraitListReducerState,
} from '../../features/global-management/trait-list/ducks';
import changePassword, {
  ReducerState as ChangePasswordReducerState,
} from '../../features/identity-management/change-password/ducks';
import firstTimeActivation, {
  ReducerState as FirstTimeActivationReducerState,
} from '../../features/identity-management/first-time-activation/ducks';
import forgotPassword, {
  ReducerState as ForgotPasswordReducerState,
} from '../../features/identity-management/forgot-password/ducks';
import login, {
  ReducerState as LoginReducerState,
} from '../../features/identity-management/login/ducks';
import profile, {
  ReducerState as ProfileReducerState,
} from '../../features/identity-management/profile/ducks';
import resetPassword, {
  ReducerState as ResetPasswordReducerState,
} from '../../features/identity-management/reset-password/ducks';
import validateInvestorSignup, {
  ReducerState as ValidateInvestorSignupReducerState,
} from '../../features/investor-signup/ducks';
import rolodexCrmIntegrationDetail, {
  ReducerState as RolodexCrmIntegrationDetailReducerState,
} from '../../features/rolodex/crm-integration-detail/ducks';
import rolodexCrmIntegrationList, {
  ReducerState as RolodexCrmIntegrationListReducerState,
} from '../../features/rolodex/crm-integration-list/ducks';
import bulkImportFromCsvFile, {
  ReducerState as BulkImportFromCsvFileReducerState,
} from '../../features/rolodex/crm-management/crm-files-upload/ducks';
import rolodexCrmMatchList, {
  ReducerState as RolodexCrmMatchListReducerState,
} from '../../features/rolodex/crm-match-list/ducks';
import rolodexFirmDetail, {
  ReducerState as RolodexFirmDetailReducerState,
} from '../../features/rolodex/firm-detail/ducks';
import rolodexFirmNew, {
  ReducerState as RolodexFirmNewReducerState,
} from '../../features/rolodex/firm-new/ducks';
import rolodexShared, {
  ReducerState as RolodexSharedReducerState,
} from '../../features/rolodex/shared/ducks';

export type RootState = {
  login: LoginReducerState;
  modal: ReduxModalState;
  adminAccountCreate: AdminAccountCreateReducerState;
  adminAccountList: AdminAccountListReducerState;
  adminAccountDetail: AdminAccountReducerState;
  adminAccountUserList: AdminAccountUserListReducerState;
  adminAccountUserDetail: AdminAccountUserDetailReducerState;
  adminAccountSecurity: AdminAccountSecurityReducerState;
  adminAccountUserCreate: AdminAccountUserCreateReducerState;
  adminAccountRolesList: AdminAccountRoleListReducerState;
  adminAccountRoleDetail: AdminAccountRoleDetailReducerState;
  adminTraitList: AdminTraitListReducerState;
  adminTraitDetail: AdminTraitDetailReducerState;
  draftAccountList: DraftAccountListReducerState;
  firstTimeActivation: FirstTimeActivationReducerState;
  forgotPassword: ForgotPasswordReducerState;
  resetPassword: ResetPasswordReducerState;
  changePassword: ChangePasswordReducerState;
  adminAccountIdentityProviderDetail: AdminIdentityProviderReducerState;
  profile: ProfileReducerState;
  adminAccountApiKeyList: ApiKeyListReducerState;
  adminAccountApiKeyDetail: ApiKeyDetailReducerState;
  adminAccountApiKeyCreate: ApiKeyCreateReducerState;
  adminAccountRolodex: AccountRolodexReducerState;
  rolodexFirmDetail: RolodexFirmDetailReducerState;
  rolodexFirmNew: RolodexFirmNewReducerState;
  rolodexShared: RolodexSharedReducerState;
  rolodexCrmIntegrationList: RolodexCrmIntegrationListReducerState;
  rolodexCrmIntegrationDetail: RolodexCrmIntegrationDetailReducerState;
  rolodexCrmMatchList: RolodexCrmMatchListReducerState;
  validateInvestorSignup: ValidateInvestorSignupReducerState;
  bulkImportFromCsvFile: BulkImportFromCsvFileReducerState;
};

const appReducer = combineReducers<RootState>({
  login,
  modal,
  adminAccountCreate,
  adminAccountList,
  adminAccountDetail,
  adminAccountUserList,
  adminAccountUserDetail,
  adminAccountSecurity,
  adminAccountUserCreate,
  adminAccountRolesList,
  adminAccountRoleDetail,
  adminTraitList,
  adminTraitDetail,
  draftAccountList,
  firstTimeActivation,
  forgotPassword,
  resetPassword,
  changePassword,
  adminAccountIdentityProviderDetail,
  profile,
  adminAccountApiKeyList,
  adminAccountApiKeyDetail,
  adminAccountApiKeyCreate,
  adminAccountRolodex,
  rolodexFirmDetail,
  rolodexFirmNew,
  rolodexShared,
  rolodexCrmIntegrationList,
  rolodexCrmIntegrationDetail,
  rolodexCrmMatchList,
  validateInvestorSignup,
  bulkImportFromCsvFile,
});

export default appReducer;
