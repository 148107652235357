import { LinkButton } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { firmDetailsSectionElementId } from '../panels/FirmDetailsPanel';

export const StyledEntityTypeErrorDetailsLinkButton = styled(LinkButton)`
  color: ${({ theme }) => theme.text.color.white};
  padding: 0;
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.text.color.white};
    text-decoration: none;
  }
`;

const EntityTypeRequiredErrorMessage = () => (
  <span>
    A{' '}
    <StyledEntityTypeErrorDetailsLinkButton
      type="button"
      onClick={() => {
        const detailsSectionElement = document.getElementById(firmDetailsSectionElementId);
        if (detailsSectionElement) {
          detailsSectionElement.scrollIntoView();
        }
      }}
    >
      Firm Entity
    </StyledEntityTypeErrorDetailsLinkButton>{' '}
    type must be defined for this organization in order to add an Investor or Sponsor role.
  </span>
);

export default EntityTypeRequiredErrorMessage;
