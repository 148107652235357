import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as yup from 'yup';

import { getFirmRoleColumns } from '../../../../../common/components/layout/firm-record-panel/firmRoleColumns';
import FirmRecordPanel from '../../../../../common/components/layout/firm-record-panel/GenericFirmRecordPanel';
import { UUID } from '../../../../../types/common';
import { FirmRoleType } from '../../../../../types/domain/firm/constants';
import { FirmRole } from '../../../../../types/domain/firm/FirmRole';
import {
  cancelEditRecord,
  createFirmRole,
  editRecord,
  FirmDetailSection,
  selectEditingRecordIds,
  selectFirmDetails,
  selectFirmRoles,
  selectFirmRolesError,
  selectUpdatingRecordIds,
  updateFirmRole,
} from '../../ducks';
import EntityTypeRequiredErrorMessage from '../entity-type-required-error-message/EntityTypeRequiredErrorMessage';

const mapStateToProps = state => ({
  roles: selectFirmRoles(state),
  details: selectFirmDetails(state),
  error: selectFirmRolesError(state),
  editingRecordIds: selectEditingRecordIds(state),
  updatingRecordIds: selectUpdatingRecordIds(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createFirmRole,
      updateFirmRole,
      editRecord,
      cancelEditRecord,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  firmId: UUID;
};
type Props = OwnProps & StateProps & DispatchProps;

export const FirmRolesValidationSchema = yup.object().shape({
  type: yup.string().nullable().required('A role type is required'),
  recordStatus: yup.string().nullable().required('A status type is required'),
});

export const FirmRolesRecordPanelComponent: React.FC<Props> = ({
  firmId,
  error,
  editingRecordIds,
  updatingRecordIds,
  roles,
  details,
  actions,
}) => {
  const [hasEntityRequiredValidationError, setHasEntityRequiredValidationError] =
    React.useState<boolean>(false);

  const entityRequiredError = hasEntityRequiredValidationError && EntityTypeRequiredErrorMessage;

  return (
    <FirmRecordPanel<FirmRole>
      sectionId={FirmDetailSection.ROLES}
      title="Roles"
      addRecordButtonLabel="Add Role"
      error={entityRequiredError || error}
      editingRecordIds={editingRecordIds}
      updatingRecordIds={updatingRecordIds}
      onCreate={role => {
        if (!details) {
          return null;
        }

        if (
          !details.entityType &&
          (role.type === FirmRoleType.INVESTOR || role.type === FirmRoleType.SPONSOR)
        ) {
          // A Firm Details Entity Type is required to create an Investor or Sponsor role
          setHasEntityRequiredValidationError(true);
        } else {
          actions.createFirmRole({ firmId, role });
        }
      }}
      onUpdate={role => {
        if (!details) {
          return null;
        }

        if (
          !details.entityType &&
          (role.type === FirmRoleType.INVESTOR || role.type === FirmRoleType.SPONSOR)
        ) {
          // A Firm Details Entity Type is required to update a role to Investor or Sponsor
          setHasEntityRequiredValidationError(true);
        } else {
          setHasEntityRequiredValidationError(false);
          actions.updateFirmRole({ firmId, role });
        }
      }}
      onEdit={recordId => actions.editRecord({ recordId })}
      onCancelEdit={recordId => {
        setHasEntityRequiredValidationError(false);
        actions.cancelEditRecord({ recordId });
      }}
      columns={getFirmRoleColumns()}
      rows={roles}
      validationSchema={FirmRolesValidationSchema}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmRolesRecordPanelComponent);
