import { apiTypes } from '@cmg/common';
import { DialogContentText } from '@cmg/design-system';
import { FC, useEffect } from 'react';

import usePrevious from '../../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../../design-system/alert/ServerErrorAlert';
import { ConfirmationDialog } from '../../../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { FormDialogContent } from '../../../../../../design-system/dialog/FormDialogContent';
import { FormDialogContentItem } from '../../../../../../design-system/dialog/FormDialogContentItem';

export type Props = Readonly<{
  readonly isOpen: boolean;
  readonly isLoading: boolean;
  readonly error: apiTypes.GenericServerError | null;
  readonly onClose: () => void;
  readonly onRegenerateApiKey: () => void;
}>;

export const RegenerateApiKeyConfirmationDialog: FC<Props> = ({
  isOpen,
  isLoading,
  error,
  onClose,
  onRegenerateApiKey,
}) => {
  const previousIsLoading = usePrevious(isLoading);
  useEffect(() => {
    if (previousIsLoading && !isLoading && !error) {
      onClose();
    }
  }, [error, isLoading, onClose, previousIsLoading]);

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      isLoading={isLoading}
      title="Regenerate API Key"
      cancelButtonLabel="Cancel"
      onCancel={onClose}
      submitButtonLabel="Regenerate API Key"
      submitButtonColor="primary"
      onSubmit={onRegenerateApiKey}
      content={
        <FormDialogContent
          error={error && <ServerErrorAlert title="Error" error={error} />}
          items={
            <FormDialogContentItem
              value={
                <DialogContentText>
                  Are you sure you want to regenerate a new API Key?
                </DialogContentText>
              }
            />
          }
        />
      }
    />
  );
};
