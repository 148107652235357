import { duckPartFactory, reduxUtil } from '@cmg/common';
import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import identityApiClient, {
  ResetPasswordRequestResponse,
} from '../../../common/api/identityApiClient';
import { RootState } from '../../../common/redux/rootReducer';

const createReducer = reduxUtil.createReducer;

const forgotPasswordDuckParts = duckPartFactory.makeAPIDuckParts<{ email: string }>({
  prefix: 'IDENTITY/FORGOT_PASSWORD',
});

const { actionTypes, actionCreators, initialState, reducer, makeSelectors } =
  forgotPasswordDuckParts;

/**
 * ACTION TYPES
 */
enum ActionTypes {
  RESET_STATE = 'identity/RESET_FORGOT_PASSWORD_STATE',
}

/**
 * ACTION CREATORS
 */
export const resetState = () => ({
  type: ActionTypes.RESET_STATE,
});

export const forgotPassword = actionCreators.request;
type RequestAction = ReturnType<typeof forgotPassword>;

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.RESET_STATE]: ReturnType<typeof resetState>;
};

/**
 * REDUCERS
 */
export { initialState };
export type ReducerState = typeof initialState;

// This reducer acts on the entire state of this duck. Has access to duck state and must return duck state.
const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_STATE]: () => ({ ...initialState }),
});

export default createDucksReducer(reducer);

// Combines our individual slice reducers and the cross slice reducer.
export function createDucksReducer(combinedReducers) {
  return (state: ReducerState = initialState, action: AnyAction) =>
    crossSliceReducer(combinedReducers(state, action), action);
}
/**
 * SELECTORS
 */
const selectLocalState = (state: RootState) => state.forgotPassword;
const selectors = makeSelectors(selectLocalState);

export const selectError = selectors.selectFailed;
export const selectSubmitting = selectors.selectLoading;
export const selectSuccess = selectors.selectSucceeded;

/**
 * SAGAS
 */
export function* forgotPasswordSubmitSaga({ payload }: RequestAction): SagaIterator {
  const { email } = payload;
  const response: ResetPasswordRequestResponse = yield call(
    identityApiClient.sendPasswordResetEmail,
    email
  );
  if (response.ok) {
    yield put(actionCreators.success(undefined));
  } else {
    yield put(actionCreators.failure(response.data.error));
  }
}

export function* forgotPasswordSaga() {
  yield takeLatest<RequestAction>(actionTypes.REQUEST, forgotPasswordSubmitSaga);
}
