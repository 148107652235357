import { apiTypes } from '@cmg/common';
import {
  Autocomplete,
  AutocompleteTextFieldProps,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
} from '@cmg/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import { Fragment, useMemo, VFC } from 'react';

import { ServerErrorAlert } from '../../../../design-system/alert/ServerErrorAlert';
import { ConfirmationDialog } from '../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { FormDialogContent } from '../../../../design-system/dialog/FormDialogContent';
import { FormDialogContentItem } from '../../../../design-system/dialog/FormDialogContentItem';
import { AccountType } from '../../../../types/domain/account/constants';
import { getJobFunctionOptions } from '../../../shared/model/job-function';
import { getEmployeeKeyTooltipLabel } from '../user-detail/utils';
import { CreateUserSchema, FormValues } from './UserCreateDialog.model';

export type Props = Readonly<{
  accountType: Readonly<AccountType | 'SYSTEM' | null>;
  onCancel: () => void;
  onSubmit: (form: FormValues) => void;
  error: Readonly<apiTypes.GenericServerError> | null;
  isSubmitting: Readonly<boolean>;
}>;

export const UserCreateDialog: VFC<Props> = ({
  accountType,
  onCancel,
  onSubmit,
  error,
  isSubmitting,
}) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: false,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: CreateUserSchema,
    initialValues: {
      jobFunction: null,
      firstName: '',
      lastName: '',
      email: '',
      employeeKey: '',
      sendInvite: false,
    },
    onSubmit: val => onSubmit(val),
  });

  const jobFunctionTextFieldProps: AutocompleteTextFieldProps = useMemo(
    () => ({
      required: true,
      label: 'Job Function',
      placeholder: 'Select Job Function',
      error: formik.touched.jobFunction && Boolean(formik.errors.jobFunction),
      helperText: formik.touched.jobFunction && formik.errors.jobFunction,
    }),
    [formik.errors.jobFunction, formik.touched.jobFunction]
  );

  return (
    <FormikProvider value={formik}>
      <ConfirmationDialog
        maxWidth="xs"
        title="Create New User"
        isOpen={true}
        isLoading={isSubmitting}
        cancelButtonLabel="Cancel"
        onCancel={onCancel}
        submitButtonLabel="Create User"
        submitButtonColor="primary"
        onSubmit={() => formik.handleSubmit()}
        content={
          <Form noValidate>
            <FormDialogContent
              error={error && <ServerErrorAlert title="Error" error={error} />}
              items={
                <Fragment>
                  <FormDialogContentItem
                    value={
                      <TextField
                        required
                        label="First Name"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <TextField
                        required
                        label="Last Name"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <TextField
                        required
                        label="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <Autocomplete
                        options={getJobFunctionOptions(accountType).map(o => o.value)}
                        getOptionLabel={optionValue =>
                          getJobFunctionOptions(accountType).find(o => o.value === optionValue)
                            ?.label ?? ''
                        }
                        value={formik.values.jobFunction}
                        onChange={(_e, value) => formik.setFieldValue('jobFunction', value)}
                        TextFieldProps={jobFunctionTextFieldProps}
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <TextField
                        label={
                          <Tooltip
                            title={getEmployeeKeyTooltipLabel()}
                            placement="top-start"
                            describeChild
                          >
                            <span aria-label="Employee Key">Employee Key ⓘ</span>
                          </Tooltip>
                        }
                        placeholder="Enter employee's unique key"
                        name="employeeKey"
                        value={formik.values.employeeKey}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.employeeKey && Boolean(formik.errors.employeeKey)}
                        helperText={formik.touched.employeeKey && formik.errors.employeeKey}
                      />
                    }
                  />
                  <FormDialogContentItem
                    value={
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="sendInvite"
                            checked={formik.values.sendInvite ?? false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        }
                        label="Send invite via email"
                      />
                    }
                  />
                </Fragment>
              }
            />
          </Form>
        }
      />
    </FormikProvider>
  );
};
