import { Option } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';

import { FirmLimited } from '../../../../../types/domain/firm/FirmLimited';
import { selectMetadata } from '../../../shared/ducks';
import {
  SOption,
  SOptionColumn,
  SOptionFirmDescription,
  SOptionFirmName,
  SOptionIndustryAndEntityTypes,
} from './FirmSelectOption.styles';

const mapStateToProps = state => ({
  metadata: selectMetadata(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type OwnProps = Option & { firm: FirmLimited };
type Props = OwnProps & StateProps;

export const FirmSelectOptionComponent: React.FC<Props> = props => {
  const { label, value, firm, selectedFirmId, metadata } = props;
  const isSelectedOption = selectedFirmId ? value === selectedFirmId : false;
  const entityType = metadata.entityTypes.find(type => type.value === firm.entityType);
  const industryType = metadata.industryTypes.find(type => type.value === firm.industryType);

  return (
    <SOption isSelected={isSelectedOption}>
      <SOptionColumn>
        <SOptionFirmName isSelected={isSelectedOption}>{label}</SOptionFirmName>
        {firm.description && (
          <SOptionFirmDescription isSelected={isSelectedOption}>
            <span>{firm.description.substr(0, 100).trimRight()}...</span>
          </SOptionFirmDescription>
        )}
      </SOptionColumn>
      <SOptionColumn>
        <SOptionIndustryAndEntityTypes>
          {[entityType, industryType]
            .filter(val => !!val)
            .map(val => val!.label)
            .join(' ● ')}
        </SOptionIndustryAndEntityTypes>
      </SOptionColumn>
    </SOption>
  );
};

export default connect(mapStateToProps)(FirmSelectOptionComponent);
