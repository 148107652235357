import styled from 'styled-components/macro';

import DetailHeader from '../../../../../common/components/layout/detail-header/DetailHeader';

export const SDetailHeaderButtonWrapper = styled.div`
  text-align: right;

  > * {
    margin: 0px 5px;
  }
`;

export const StyledDetailHeaderTitleWrapper = styled(DetailHeader.TitleWrapper)`
  display: flex;
  align-items: center;
`;

export const StyledDetailHeaderTitle = styled(DetailHeader.Title)`
  margin: 0;
`;

export const StyledDetailHeaderColumn = styled(DetailHeader.Column)`
  margin-right: 0;
`;
