import { Typography } from '@cmg/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { Fragment, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import { useDocumentTitle } from '../../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../../../common/util/routeFactory';
import { ServerErrorAlert } from '../../../../../design-system/alert/ServerErrorAlert';
import { PageGridSection } from '../../../../../design-system/body-sections/PageGridSection';
import { IdentityPageContent } from '../../../../../design-system/IdentityPageContent';
import {
  createApiKey,
  fetchAccountRoles,
  resetState,
  selectAccountRoles,
  selectSubmitting,
} from '../../../../account-detail/api-keys/api-keys-create/ducks';
import ApiKeyModal from '../../../../account-detail/api-keys/shared/ApiKeyModal';
import {
  fetchAccountPermissions,
  selectAccount,
  selectAccountPermissions,
  selectAccountPermissionsLoading,
  selectError,
} from '../../../../account-detail/shared/ducks';
import { sanitizePermissionsByAccessLevel } from '../../../../shared/components/permissions-table/PermissionsTable.model';
import { CreateApiKeyFormValues, CreateApiKeySchema } from './ApiKeyCreateRoute.model';
import { SectionActions } from './SectionActions';
import { ApiKeyPermissionsTable } from './table/ApiKeyPermissionsTable';
import { ApiKeyPermissionsTableActions } from './table/ApiKeyPermissionsTableActions';

const mapStateToProps = state => {
  return {
    accountPermissions: selectAccountPermissions(state),
    roles: selectAccountRoles(state),
    submitting: selectSubmitting(state),
    loading: selectAccountPermissionsLoading(state),
    error: selectError(state),
    account: selectAccount(state),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createApiKey,
      resetState,
      fetchAccountPermissions,
      fetchAccountRoles,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RouteProps = RouteComponentProps<{
  accountSubdomain: string;
}>;
export type Props = RouteProps & StateProps & DispatchProps;

export const ApiKeyCreateRouteComponent: React.FC<Props> = ({
  loading,
  accountPermissions,
  roles,
  error,
  submitting,
  actions,
  history,
  account,
}) => {
  useDocumentTitle(routeFactory.accountApiKeysNew.getDocumentTitle({ accountName: account?.name }));

  useEffect(() => {
    actions.fetchAccountPermissions(undefined);
    actions.fetchAccountRoles({ page: 1, perPage: 50 });

    return () => {
      actions.resetState();
    };
  }, [actions]);

  const handleRedirectToList = () => {
    history.push(
      routeFactory.accountApiKeys.getUrlPath({
        accountSubdomain: account!.subdomain,
      })
    );
  };

  const formik = useFormik<CreateApiKeyFormValues>({
    enableReinitialize: false,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: CreateApiKeySchema,
    initialValues: {
      name: '',
      permissions: {},
    },
    onSubmit: values => {
      actions.createApiKey({
        apiKey: {
          name: values.name,
          permissions: Object.values(values.permissions).filter(p => !!p),
          description: '',
        },
      });
    },
  });

  const { values, setFieldValue } = formik;

  const onPermissionAdd = useCallback(
    (permissions: string[]) => {
      // update/add selected permissions when user toggles column in permissions table
      setFieldValue('permissions', {
        ...values.permissions,
        ...sanitizePermissionsByAccessLevel(permissions),
      });
    },
    [setFieldValue, values.permissions]
  );

  const onPermissionRemove = useCallback(
    (permissions: string[]) => {
      const filteredPermissions: string[] = Object.values(values.permissions).filter(
        p => !permissions.includes(p)
      );

      setFieldValue('permissions', sanitizePermissionsByAccessLevel(filteredPermissions));
    },
    [setFieldValue, values.permissions]
  );

  if (loading || !accountPermissions || !account) {
    return null;
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <IdentityPageContent
          gridContent={
            <PageGridSection
              size="large"
              title="New API Key"
              actions={
                <SectionActions
                  handleRedirectToList={handleRedirectToList}
                  submitting={submitting}
                />
              }
              error={error && <ServerErrorAlert title="Error" error={error} />}
              content={
                <Fragment>
                  {accountPermissions.length === 0 && (
                    <Typography>
                      There are no available permissions for this account. Please contact your
                      administrator.
                    </Typography>
                  )}
                  {accountPermissions.length > 0 && (
                    <Fragment>
                      <ApiKeyPermissionsTableActions
                        accountPermissions={accountPermissions}
                        roles={roles}
                        onApplyPermissions={permissions =>
                          formik.setFieldValue('permissions', permissions)
                        }
                      />
                      <ApiKeyPermissionsTable
                        selectedPermissions={Object.values(values.permissions ?? []).filter(
                          p => !!p
                        )}
                        accountPermissions={accountPermissions}
                        onPermissionsAdd={onPermissionAdd}
                        onPermissionsRemove={onPermissionRemove}
                      />
                    </Fragment>
                  )}
                </Fragment>
              }
            />
          }
        />
      </Form>
      <ApiKeyModal onHide={handleRedirectToList} />
    </FormikProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeyCreateRouteComponent);
