import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoadingButton,
  RegularBreakpoints,
} from '@cmg/design-system';
import { ReactNode, useMemo, VFC } from 'react';

export type ConfirmationDialogProps = Readonly<{
  fullWidth?: Readonly<boolean>;
  maxWidth?: Readonly<keyof RegularBreakpoints>;
  isOpen: Readonly<boolean>;
  isLoading?: Readonly<boolean>;
  title: Readonly<string>;
  content: Readonly<ReactNode>;
  cancelButtonLabel?: Readonly<string>;
  onCancel?: () => void;
  submitButtonLabel: Readonly<string>;
  submitButtonColor: Readonly<ButtonProps['color']>;
  onSubmit: () => void;
  fullHeight?: boolean;
}>;

export const ConfirmationDialog: VFC<ConfirmationDialogProps> = ({
  fullWidth,
  maxWidth,
  isOpen,
  title,
  content,
  isLoading,
  cancelButtonLabel,
  onCancel,
  submitButtonLabel,
  submitButtonColor,
  fullHeight,
  onSubmit,
}) => {
  const PaperProps = useMemo<DialogProps['PaperProps']>(
    () => (fullHeight ? { sx: { height: '95%' } } : undefined),
    [fullHeight]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperProps={PaperProps}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        {onCancel && (
          <Button onClick={onCancel} variant="outlined" color="primary" disabled={isLoading}>
            {cancelButtonLabel}
          </Button>
        )}
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color={submitButtonColor}
          type="submit"
          disabled={isLoading}
          onClick={onSubmit}
        >
          {submitButtonLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
