import * as yup from 'yup';

export const changeReasonSelectOptions = [
  { value: 'CORP_STRUCTURE_CHANGE', label: 'Corp Structure Change' },
  { value: 'MAPPING_CORRECTION', label: 'Mapping Correction' },
];

export const createRemapConfig = (
  account: RemapConfig['currentConfig'],
  remapConfig?: RemapConfig['newConfig']
): RemapConfig => {
  return {
    currentConfig: {
      cmgEntityName: account.cmgEntityName,
      subdomain: account.subdomain,
      cmgEntityKey: account.cmgEntityKey,
      id: account.id,
    },
    newConfig: {
      loadingEntityName: remapConfig?.loadingEntityName ?? false,
      cmgEntityName: remapConfig?.cmgEntityName ?? '',
      subdomain: remapConfig?.subdomain ?? '',
      cmgEntityKey: remapConfig?.cmgEntityKey ?? '',
      changeReason: remapConfig?.changeReason ?? '',
    },
  };
};

export type RemapConfig = {
  currentConfig: {
    cmgEntityKey: string;
    cmgEntityName: string;
    subdomain: string;
    id: string;
  };
  newConfig: {
    loadingEntityName?: boolean;
    cmgEntityKey: string;
    cmgEntityName: string;
    subdomain: string;
    changeReason: string;
  };
};

export type RemapFormValues = {
  accountConfigs: RemapConfig[];
};

export const validateEntityKeyTest = {
  test: function (this: typeof AccountRemappingSchema) {
    const accountConfigs: RemapConfig[] = this.from[2].value.accountConfigs;
    const accountNewConfig: RemapConfig['newConfig'] = this.from[0].value;
    // Check if there is a firm associated with the entity key if not the flow is invalid

    const isANumber = /^-?\d+$/.test(accountNewConfig.cmgEntityKey);
    if (!isANumber) {
      return this.createError({
        message: `CMG Entity Key must be a number`,
      });
    }

    if (
      (accountNewConfig.cmgEntityName === '' || !accountNewConfig.cmgEntityName) &&
      accountNewConfig.cmgEntityKey?.length === 9
    ) {
      return this.createError({
        message: `Entity Key is not found`,
      });
    }

    if (!accountNewConfig.subdomain) {
      return true;
    }
    const targetSubdomainDiffersFromCurrent =
      accountNewConfig.subdomain !== '' &&
      !!accountConfigs.find(config => {
        return config.currentConfig.subdomain !== accountNewConfig.subdomain;
      });
    if (targetSubdomainDiffersFromCurrent) {
      return this.createError({
        message: `Entity key is already in use by ${accountNewConfig.subdomain}`,
      });
    } else {
      return true;
    }
  },
};

export const AccountRemappingSchema = yup.object().shape({
  accountConfigs: yup.array().of(
    yup.object().shape({
      newConfig: yup.object({
        changeReason: yup.string().nullable().required().label('Change Reason'),
        cmgEntityKey: yup
          .string()
          .nullable()
          .required()
          .length(9, 'CMG Entity Key must be a number of length 9')
          .label('CMG Entity Key')
          .test(validateEntityKeyTest),
      }),
    })
  ),
});
