import { FirmCategoryType } from '@capital-markets-gateway/api-client-rolodex';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FirmDetailsEditor from '../../../../../common/components/form/firm-details-editor/FirmDetailsEditor';
import { UUID } from '../../../../../types/common';
import { FirmRoleType } from '../../../../../types/domain/firm/constants';
import { UpdateFirmDetails } from '../../../../../types/domain/firm/FirmDetails';
import { RecordStatus } from '../../../../../types/domain/record-status/RecordStatus';
import {
  fetchMetadata,
  selectMetadata,
  selectMetadataError,
  selectMetadataLoading,
} from '../../../shared/ducks';
import {
  cancelEditRecord,
  editRecord,
  FirmDetailSection,
  selectEditingRecordIds,
  selectFirmDetails,
  selectFirmDetailsError,
  selectFirmDetailsLoading,
  selectFirmRoles,
  updateFirmDetails,
  updateFirmDetailsDuckParts,
} from '../../ducks';

const mapStateToProps = state => ({
  firmDetails: selectFirmDetails(state),
  loading: selectFirmDetailsLoading(state),
  error: selectFirmDetailsError(state),
  editingRecordIds: selectEditingRecordIds(state),
  metadata: selectMetadata(state),
  metadataLoading: selectMetadataLoading(state),
  metadataError: selectMetadataError(state),
  roles: selectFirmRoles(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      clearErrors: updateFirmDetailsDuckParts.actionCreators.clearError,
      updateFirmDetails,
      fetchMetadata,
      editRecord,
      cancelEditRecord,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  /** The ID of the firm whose details are to be displayed and edited. */
  firmId: string;
  firmType: FirmCategoryType | null;
};
type Props = StateProps & DispatchProps & OwnProps;

export const firmDetailsSectionElementId = FirmDetailSection.DETAILS;

/**
 * Container component that allows editing of firm details
 */
export const FirmDetailsPanelComponent: React.FC<Props> = ({
  firmId,
  firmType,
  firmDetails,
  loading,
  error,
  editingRecordIds,
  metadata,
  roles,
  actions,
}) => {
  React.useEffect(() => {
    actions.fetchMetadata();
  }, [actions]);

  const handleEditButtonClick = () => actions.editRecord({ recordId: FirmDetailSection.DETAILS });
  const handleCancelEditButtonClick = () => {
    actions.cancelEditRecord({ recordId: FirmDetailSection.DETAILS });
    actions.clearErrors();
  };
  const handleSubmit = (details: UpdateFirmDetails, detailsId: UUID) =>
    actions.updateFirmDetails({
      firmId: firmId,
      id: detailsId,
      body: {
        ...details,
      },
    });

  return (
    <React.Fragment>
      <span id={firmDetailsSectionElementId} />
      <FirmDetailsEditor
        isEditing={editingRecordIds.includes(FirmDetailSection.DETAILS)}
        loading={loading}
        error={error}
        firmDetails={firmDetails}
        firmType={firmType}
        metadata={metadata}
        isEntityTypeRequired={roles.some(
          role =>
            (role.type === FirmRoleType.INVESTOR && role.recordStatus === RecordStatus.EFFECTIVE) ||
            (role.type === FirmRoleType.SPONSOR && role.recordStatus === RecordStatus.EFFECTIVE)
        )}
        onEditButtonClick={handleEditButtonClick}
        onCancelEditButtonClick={handleCancelEditButtonClick}
        onSubmit={handleSubmit}
      />
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmDetailsPanelComponent);
