// todo - any reason we need to use a cookie package, or is this good enough?
const readCookie = (name: string) => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let c of ca) {
    while (c.startsWith(' ')) {
      c = c.substring(1, c.length);
    }

    if (c.startsWith(nameEQ)) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

const cookieManager = {
  readXsrfToken: () => readCookie('XSRF-TOKEN'),
};

export default cookieManager;
