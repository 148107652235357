import { accountRoleListNameLinkSelector } from '@cmg/e2e-selectors';
import { ColDef } from 'ag-grid-community';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';
import { Role } from '../../../../types/domain/role/role';

export const RoleNameCellRendererFramework: React.FC<{
  data: Role;
  accountSubdomain: string;
}> = ({ data: role, accountSubdomain }) => (
  <Link
    data-test-id={accountRoleListNameLinkSelector.testId}
    to={routeFactory.accountRoleDetail.getUrlPath({
      accountSubdomain,
      roleId: role.id,
    })}
  >
    {role.name}
  </Link>
);

export const createColumns = (accountSubdomain: string): ColDef[] => [
  {
    field: 'name',
    headerName: 'Role',
    sort: 'asc',
    cellRendererFramework: (props: { data: Role }) => (
      <RoleNameCellRendererFramework {...props} accountSubdomain={accountSubdomain} />
    ),
    suppressColumnsToolPanel: true,
  },
  {
    field: 'description',
    headerName: 'Role Description',
    width: 600,
    valueFormatter: ({ data: role }: { data: Role }) => role.description,
    autoHeight: true,
    cellStyle: { 'white-space': 'normal', 'line-height': 1.5 },
  },
];
