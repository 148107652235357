import { FirmAddressType } from '../../../../../../types/domain/firm/constants';
import { FirmMatchAddress } from '../../../../../../types/domain/firm-match/FirmMatchAddress';

export const sortFirmMathAddressesByHQ = (addresses: FirmMatchAddress[]) => {
  return addresses.sort((a, b) => {
    if (a.type === FirmAddressType.HEADQUARTERS) {
      return -1;
    }

    if (b.type === FirmAddressType.HEADQUARTERS) {
      return 1;
    }

    return 0;
  });
};
