import { Tab, Tabs } from '@cmg/design-system';
import React from 'react';

import routeFactory from '../../../../common/util/routeFactory';
import { useRoutesMatch } from '../../../../design-system/react-router/useRoutesMatch';
import { Account } from '../../../../types/domain/account/account';
import { accountRoleDetailRoutes } from './AccountRoleDetailTabs.model';

export type Props = Readonly<{
  readonly account: Account;
  readonly roleId: string;
}>;

export const AccountRoleDetailTabs: React.FC<Props> = ({ account, roleId }) => {
  const { matchedRouteName, routeNames } = useRoutesMatch(accountRoleDetailRoutes, { exact: true });

  return (
    <Tabs value={matchedRouteName}>
      <Tab
        value={routeNames.accountRoleDetail}
        href={routeFactory.accountRoleDetail.getUrlPath({
          roleId,
          accountSubdomain: account.subdomain,
        })}
        label={<span>Permissions</span>}
      />
      <Tab
        value={routeNames.accountRoleUsersDetail}
        href={routeFactory.accountRoleUsersDetail.getUrlPath({
          roleId,
          accountSubdomain: account.subdomain,
        })}
        label={<span>Users</span>}
      />
    </Tabs>
  );
};
