import { apiTypes, Banner, DataGrid } from '@cmg/common';
import React from 'react';

import { CrmIntegration } from '../../../../types/domain/entity-matcher/CrmIntegration';
import { createColumns } from './CrmIntegrationListGridColumns';

type Props = {
  loading: boolean;
  clients: CrmIntegration[];
  pagination?: apiTypes.Pagination;
  error: boolean;
  onChangePage: (params: apiTypes.ListParams) => void;
};

/**
 * Displays CRM Integrations in a table view
 */
const CrmIntegrationList: React.FC<Props> = ({
  loading,
  clients,
  pagination,
  error,
  onChangePage,
}) => {
  return (
    <React.Fragment>
      {error && <Banner variant="error">Could not load firms...</Banner>}
      <DataGrid<CrmIntegration>
        onGridReady={params => {
          params.api.resetRowHeights();
        }}
        pagination={
          pagination
            ? {
                page: pagination.activePage,
                perPage: pagination.perPage,
              }
            : undefined
        }
        totalPages={pagination ? pagination.totalPages : 0}
        onPaginationChange={({ page, perPage, orderField, orderDirection }) => {
          const params = {
            page,
            perPage,
            orderField,
            orderDirection:
              orderDirection === 'asc' ? apiTypes.SortDirection.ASC : apiTypes.SortDirection.DESC,
          };

          onChangePage(params);
        }}
        loading={loading}
        extended={{
          withMargin: false,
        }}
        columns={createColumns()}
        rows={clients}
        resizeBy="grid"
        gridOptions={{
          suppressCellSelection: true,
          suppressRowClickSelection: true,
        }}
        getRowNodeId={crmIntegration => crmIntegration.accountId}
      />
    </React.Fragment>
  );
};

export default CrmIntegrationList;
