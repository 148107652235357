import { Button } from '@cmg/design-system';
import { VFC } from 'react';

import routeFactory from '../../../../../common/util/routeFactory';
import { DescriptionList } from '../../../../../design-system/body-sections/description-list/DescriptionList';
import { DescriptionListItem } from '../../../../../design-system/body-sections/description-list/DescriptionListItem';
import { PageGridSection } from '../../../../../design-system/body-sections/PageGridSection';

export const LoginDetailsSection: VFC = () => {
  return (
    <PageGridSection
      title="Login Details"
      content={
        <DescriptionList>
          <DescriptionListItem
            label="Password"
            value={
              <Button variant="text" href={routeFactory.changePassword.getUrlPath()}>
                Change Password
              </Button>
            }
          />
        </DescriptionList>
      }
    />
  );
};
