import { Icon } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

const SLoadingIndicator = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.background.color.dark};
`;

const StyledSpinner = styled(Icon)`
  margin: 10px;
`;

const LoadingIndicator: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <SLoadingIndicator>
    <StyledSpinner name="spinner-third" size="lg" fixedWidth spin />
    {children || 'Loading...'}
  </SLoadingIndicator>
);

export default LoadingIndicator;
