import { apiTypes, CenteredPage } from '@cmg/common';
import { passwordExpiredScreenSelector } from '@cmg/e2e-selectors';
import React from 'react';

import { PasswordReset } from '../../../../../../types/domain/self/PasswordReset';
import ExpiredPasswordForm from '../expiredPasswordForm/ExpiredPasswordForm';

type Props = {
  onSubmitResetPasswordForm: (values: PasswordReset) => void;
  error: apiTypes.GenericServerError | null;
  success: boolean;
  submitting: boolean;
  token: string;
  userId: string;
};

/**
 * Styles and lays out the the various login options in a centered box.
 */
const ExpiredPasswordScreen: React.FC<Props> = ({
  onSubmitResetPasswordForm,
  error,
  submitting,
  success,
  token,
  userId,
}) => (
  <CenteredPage data-test-id={passwordExpiredScreenSelector.testId}>
    <ExpiredPasswordForm
      onSubmit={onSubmitResetPasswordForm}
      submitting={submitting}
      success={success}
      error={error}
      token={token}
      userId={userId}
    />
  </CenteredPage>
);

export default ExpiredPasswordScreen;
