import { Button, CheckIcon, ContentCopyIcon, DialogContentText } from '@cmg/design-system';
import { FC, Fragment } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ConfirmationDialog } from '../../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { FormDialogContent } from '../../../../../design-system/dialog/FormDialogContent';
import { FormDialogContentItem } from '../../../../../design-system/dialog/FormDialogContentItem';

export type Props = Readonly<{
  readonly accessToken: string;
  readonly isCopied: boolean;
  readonly onCopy: () => void;
  readonly onClose: () => void;
}>;

export const ApiKeyDialog: FC<Props> = ({ accessToken, isCopied, onCopy, onClose }) => {
  return (
    <ConfirmationDialog
      isOpen={true}
      title="API Key Created"
      submitButtonLabel="Close"
      submitButtonColor="primary"
      onSubmit={onClose}
      content={
        <FormDialogContent
          items={
            <Fragment>
              <FormDialogContentItem
                value={
                  <DialogContentText>
                    Here is your API Key. You will not be able to view it again once you close this
                    window, so be sure to record it.
                  </DialogContentText>
                }
              />
              <FormDialogContentItem
                value={
                  <DialogContentText>
                    <b>{accessToken}</b>
                  </DialogContentText>
                }
              />
              <FormDialogContentItem
                value={
                  <CopyToClipboard onCopy={onCopy} text={accessToken}>
                    <Button endIcon={isCopied ? <CheckIcon /> : <ContentCopyIcon />}>
                      {isCopied ? 'Key Copied' : 'Copy Key'}
                    </Button>
                  </CopyToClipboard>
                }
              />
            </Fragment>
          }
        />
      }
    />
  );
};
