import { VFC } from 'react';

import { DescriptionListSkeleton } from '../../../../../../design-system/body-sections/description-list/DescriptionListSkeleton';
import { PageGridSection } from '../../../../../../design-system/body-sections/PageGridSection';
import { IdentityPageContent } from '../../../../../../design-system/IdentityPageContent';
import { HighlightsSkeleton } from '../../../../../../design-system/loading/HighlightsSkeleton';

export const ProviderRouteSkeleton: VFC = () => {
  return (
    <IdentityPageContent
      localHighlights={<HighlightsSkeleton aria-label="Loading Provider Highlights" />}
      gridContent={
        <PageGridSection
          size="medium"
          title="Provider Details"
          content={<DescriptionListSkeleton aria-label="Loading Provider Details" />}
        />
      }
    />
  );
};
