import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Route } from 'react-router-dom';

import routeFactory from '../../../common/util/routeFactory';
import RolodexFirmCreateRoute from './RolodexFirmCreateRoute';
import RolodexFirmImportRoute from './RolodexFirmImportRoute';

type Props = RouteComponentProps;

const RolodexFirmNewRoute: React.FC<Props> = () => {
  return (
    <React.Fragment>
      <Route
        exact
        path={routeFactory.rolodexFirmImport.routePath}
        component={RolodexFirmImportRoute}
      />
      <Route
        exact
        path={routeFactory.rolodexFirmCreate.routePath}
        component={RolodexFirmCreateRoute}
      />
    </React.Fragment>
  );
};

export default RolodexFirmNewRoute;
