import { useMemo } from 'react';

import { useFeatureToggles } from '../../../common/config/context/useFeatureToggles';
import { Trait } from '../../../types/domain/trait/constants';

const TraitsToToggle = {
  [Trait.CERTIFICATE_LIBRARY]: 'isCertificateLibraryOn',
  [Trait.CERTIFICATE_REVIEW]: 'isCertificateReviewOn',
};

const shouldShowTraitFn =
  (featureToggles: ReturnType<typeof useFeatureToggles>) => (trait: Trait | string) => {
    const featureToggleKey = TraitsToToggle[trait];

    if (!featureToggleKey) {
      return true;
    }

    return featureToggles[featureToggleKey];
  };
// #TODO: Remove this hook once the rollout plan ECM-36280 is completed
const useCertificatesAccess = () => {
  const fetureToggles = useFeatureToggles();
  const shouldShowTrait = useMemo(() => shouldShowTraitFn(fetureToggles), [fetureToggles]);

  return { shouldShowTrait };
};

export default useCertificatesAccess;
