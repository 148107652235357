import { Grid } from '@cmg/design-system';
import { FC } from 'react';

export const FormFieldLayout: FC = ({ children }) => {
  return (
    <Grid item xs={12} paddingY={1}>
      {children}
    </Grid>
  );
};
