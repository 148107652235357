import { Grid } from '@cmg/design-system';
import { ReactNode, VFC } from 'react';

export type FormDialogContentProps = {
  /**
   * Error content
   */
  readonly error?: Readonly<ReactNode>;
  /**
   * Form items
   */
  readonly items?: Readonly<ReactNode>;
};

export const FormDialogContent: VFC<FormDialogContentProps> = ({ error, items }) => {
  return (
    <Grid container spacing={2} alignItems="center" alignContent="flex-start">
      {error && (
        <Grid item xs={12}>
          {error}
        </Grid>
      )}
      {items}
    </Grid>
  );
};
