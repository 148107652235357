import { DiffView, Modal, ModalContent, ModalFooter, SecondaryButton } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectModal, InjectedProps, show } from 'redux-modal';

import { selectFirmVersionDiff } from '../../ducks';

const mapStateToProps = state => ({
  diff: selectFirmVersionDiff(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type Props = InjectedProps & StateProps & DispatchProps;

export const FirmVersionDiffModalComponent: React.FC<Props> = ({ show, diff, handleHide }) => {
  const handleOnClose = () => {
    handleHide();
  };

  return (
    <Modal title="Change History" size="large" show={show} onHide={handleHide}>
      <ModalContent>
        {diff && <DiffView prevVersion={diff.from} nextVersion={diff.to} />}
      </ModalContent>
      <ModalFooter>
        <SecondaryButton onClick={handleOnClose}>Close</SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

const ConnectedModal = connect(mapStateToProps, mapDispatchToProps)(FirmVersionDiffModalComponent);

export default connectModal({ name: 'ROLODEX/FIRM/SHOW_VERSION_DIFF' })(ConnectedModal);

export const openFirmVersionDiffModal = () => show('ROLODEX/FIRM/SHOW_VERSION_DIFF');
