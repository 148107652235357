import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { accountSubscriptionScreenSelector } from '@cmg/e2e-selectors';
import { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useDocumentTitle } from '../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../../common/util/routeFactory';
import { IdentityPageContent } from '../../../../design-system/IdentityPageContent';
import {
  assignTraits,
  selectAccount,
  selectAssignTraitsParts,
  selectUpdateError,
  selectUpdateSubmitting,
  updateAccount,
  updateAccountStatus,
} from '../../../account-detail/shared/ducks';
import { ProductTraitsSection } from './grid-content/ProductTraitsSection';
import { SubscriptionSection } from './grid-content/SubscriptionSection';

const mapStateToProps = state => ({
  account: selectAccount(state),
  accountUpdateError: selectUpdateError(state),
  accountUpdateSubmitting: selectUpdateSubmitting(state),
  assignTraitsParts: selectAssignTraitsParts(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateAccountStatus,
      updateAccount,
      assignTraits,
    },
    dispatch
  ),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
export type Props = StateProps & DispatchProps;

export const AccountSubscriptionRoute: FC<Props> = props => {
  const { account, actions, accountUpdateError, accountUpdateSubmitting, assignTraitsParts } =
    props;

  const canEdit = useCheckPermissions([permissionsByEntity.GlobalAccount.FULL]);

  useDocumentTitle(
    routeFactory.accountSubscription.getDocumentTitle({ accountName: account?.name })
  );

  if (!account) {
    return null;
  }

  const handleAssignTraits = (traitCodes: string[]) => {
    actions.assignTraits({
      accountId: account.id,
      traitCodes: traitCodes,
    });
  };

  return (
    <div
      data-test-id={accountSubscriptionScreenSelector.testId}
      data-testid="identity-subscription-screen"
    >
      <IdentityPageContent
        gridContent={
          <Fragment>
            <SubscriptionSection
              canEdit={canEdit}
              account={account}
              onSubmit={formValues => actions.updateAccount({ ...formValues, id: account.id })}
              submitError={accountUpdateError}
              isSubmitting={accountUpdateSubmitting}
            />
            <ProductTraitsSection
              canEdit={canEdit}
              accountTraits={account.traits ?? []}
              accountType={account.accountType}
              onSubmit={formValues => handleAssignTraits(formValues.traitCodes)}
              submitError={assignTraitsParts.error}
              isSubmitting={assignTraitsParts.loading}
            />
          </Fragment>
        }
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSubscriptionRoute);
