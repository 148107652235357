import { Progressbar } from '@cmg/common';
import React from 'react';

import { FirmMatchRow } from '../../../../../../types/domain/firm-match/FirmMatch';
import { SMatchScoreNumber, SMatchScoreWrapper } from './CrmFirmMatchListCellRenderer.styles';

type Props = {
  /**
   * The CMG Entity Key of the CRM Investor Firm
   * If it exists, the CRM Investor Firm is linked
   * to a CMG Firm - otherwise, it is not linked.
   */
  cmgEntityKey?: string;
  /**
   * The row data - a Firm Match object.
   */
  data: FirmMatchRow;
};
/**
 * Renders progress bar for FirmMatch score
 */
export const ScoreCellRenderer: React.FC<Props> = ({ cmgEntityKey, data: firmMatch }) => {
  // if CrmInvestorFirm top row data, don't render score
  if (firmMatch.score === null) {
    return <div />;
  }

  // if this is a linked firm match, don't render a score of 100
  if (cmgEntityKey && firmMatch.rolodexKey === cmgEntityKey) {
    return <div />;
  }

  return (
    <SMatchScoreWrapper>
      <Progressbar height={15} progress={firmMatch.score} total={100} />
      <SMatchScoreNumber>{firmMatch.score}</SMatchScoreNumber>
    </SMatchScoreWrapper>
  );
};

export default ScoreCellRenderer;
