import { Fragment, VFC } from 'react';

import { IdentityProviderType } from '../../../../../types/domain/identity-provider/constants';
import ConfigureAzureProviderDialog from '../identity-providers/azure/dialog/ConfigureAzureProviderDialog';
import ConfigureOpenIdProviderDialog from '../identity-providers/open-id/dialog/ConfigureOpenIdProviderDialog';
import ConfigureSamlProviderDialog from '../identity-providers/saml/dialog/ConfigureSamlProviderDialog';

export type Props = {
  openedDialogProviderType: Readonly<IdentityProviderType | null>;
  onClose: () => void;
};

export const ConfigureProviderDialog: VFC<Props> = ({
  openedDialogProviderType: configuringProviderType,
  onClose,
}) => {
  return (
    <Fragment>
      <ConfigureAzureProviderDialog
        isOpened={configuringProviderType === IdentityProviderType.AZURE_AD_OPEN_ID}
        onClose={onClose}
      />
      <ConfigureOpenIdProviderDialog
        isOpened={configuringProviderType === IdentityProviderType.OPEN_ID_CONNECT}
        onClose={onClose}
      />
      <ConfigureSamlProviderDialog
        isOpened={configuringProviderType === IdentityProviderType.SAML2P}
        onClose={onClose}
      />
    </Fragment>
  );
};
