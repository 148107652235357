import { accountListNameLinkSelector, accountListNameSelector } from '@cmg/e2e-selectors';
import { ColDef } from 'ag-grid-community';
import React from 'react';
import { Link } from 'react-router-dom';

import Status from '../../../../common/components/indicators/status/Status';
import routeFactory from '../../../../common/util/routeFactory';
import { Account } from '../../../../types/domain/account/account';
import {
  AccountStatus,
  accountStatusLabels,
  accountTypeLabels,
  licenseTypeLabels,
} from '../../../../types/domain/account/constants';

export const NameCellRendererFramework: React.FC<{ data: Account }> = ({ data: account }) => (
  <Link
    to={routeFactory.accountDetail.getUrlPath({
      accountSubdomain: account.subdomain,
    })}
    data-test-id={accountListNameLinkSelector.testId}
  >
    <span data-test-id={accountListNameSelector.testId}>{account.name}</span>
  </Link>
);

export const AccountTypeCellRenderer: React.FC<{ data: Account }> = ({ data: account }) => (
  <span>{account.accountType ? accountTypeLabels[account.accountType] : 'None'}</span>
);

export const LicenseTypeCellRenderer: React.FC<{ data: Account }> = ({ data: account }) => (
  <span>{licenseTypeLabels[account.licenseType]}</span>
);

export const StatusCellRendererFramework: React.FC<{ data: Account }> = ({ data: account }) => (
  <Status
    isActive={account.status === AccountStatus.ACTIVE}
    activeLabel={account.status ? accountStatusLabels[account.status] : undefined}
    inactiveLabel={account.status ? accountStatusLabels[account.status] : undefined}
  />
);

const columns: ColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    sort: 'asc',
    cellRendererFramework: NameCellRendererFramework,
    suppressColumnsToolPanel: true,
  },
  { field: 'subdomain', headerName: 'Subdomain', width: 120 },
  { field: 'cmgEntityKey', headerName: 'CMG Entity Key', width: 120 },
  {
    field: 'accountType',
    headerName: 'Account Type',
    cellRendererFramework: AccountTypeCellRenderer,
    maxWidth: 120,
  },
  {
    field: 'licenseType',
    headerName: 'License Type',
    cellRendererFramework: LicenseTypeCellRenderer,
    maxWidth: 110,
  },
  { field: 'licenseCount', headerName: 'License Limit', width: 100 },
  {
    field: 'traits',
    headerName: 'Traits',
    sortable: false,
    valueFormatter: ({ value: traits }) => traits.map(trait => trait.name).join(', '),
  },
  {
    field: 'status',
    headerName: 'Status',
    cellRendererFramework: StatusCellRendererFramework,
    maxWidth: 90,
  },
];

export default columns;
