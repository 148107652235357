import { AsyncSelect, Option } from '@cmg/common';
import debounce from 'lodash/debounce';
import React from 'react';

import * as rolodexApiClient from '../../../../../common/api/rolodexApiClient';
import { GetFirmsResponse } from '../../../../../common/api/rolodexApiClient';
import { UUID } from '../../../../../types/common';
import { FirmLimited } from '../../../../../types/domain/firm/FirmLimited';
import FirmSelectOption from './FirmSelectOption';

type Props = {
  disabled?: boolean;
  excludedFirmIds?: UUID[];
  onChange: (firmId: UUID | null) => void;
};

const fromFirmLimitedToOption = (firm: FirmLimited): Option => ({
  firm: firm,
  value: firm.id,
  label: firm.displayName || firm.id,
});

const handleLoadOptions = debounce(
  (
    inputValue: string,
    callback: (options: Option[]) => void | Promise<void>,
    excludedFirmIds: UUID[]
  ) => {
    const loadOptions = async () => {
      const paginationParams = {
        perPage: 100,
        page: 1,
      };

      const [nameSearchResponse, idSearchResponse]: [GetFirmsResponse, GetFirmsResponse] =
        await Promise.all([
          rolodexApiClient.getFirms({
            searchText: inputValue,
            ...paginationParams,
          }),
          rolodexApiClient.getFirms({
            key: inputValue,
            ...paginationParams,
          }),
        ]);

      if (nameSearchResponse.ok || idSearchResponse.ok) {
        const firms = [
          ...(idSearchResponse.ok ? idSearchResponse.data.data : []),
          ...(nameSearchResponse.ok ? nameSearchResponse.data.data : []),
        ]
          .filter(firm => !excludedFirmIds.includes(firm.id))
          .map(fromFirmLimitedToOption);

        await callback(firms);
      } else {
        await callback([]);
      }
    };

    void loadOptions();
  },
  350
);

export const FirmSelectField: React.FC<Props> = ({ disabled, excludedFirmIds = [], onChange }) => {
  const [selectedFirmId, setSelectedFirmId] = React.useState<UUID | null>(null);

  return (
    <AsyncSelect
      disabled={disabled}
      isSearchable
      styledConfig={{
        option: (args, controlProps, styles) => ({
          ...styles,
          padding: 0,
        }),
      }}
      openMenuOnClick={false}
      placeholder="Search by Name or ID..."
      loadOptions={(inputValue, callback) =>
        handleLoadOptions(inputValue, callback, excludedFirmIds)
      }
      onChange={value => {
        setSelectedFirmId(value);
        onChange(value);
      }}
      noOptionsMessage={({ inputValue }: { inputValue: string }) => {
        if (inputValue) {
          return `No organizations matching ${inputValue} found.`;
        }

        return 'No organizations found.';
      }}
      renderOption={(props: Option) => (
        <FirmSelectOption {...props} selectedFirmId={selectedFirmId} />
      )}
      renderSingleSelectedOption={selectedOption => <span>{selectedOption.label}</span>}
    />
  );
};

export default FirmSelectField;
