import styled from 'styled-components/macro';

export const SButtonWrapper = styled.div`
  > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const SLabel = styled.p`
  margin-top: 0;
`;

export const SFactSetFirmHierarchyTreeGridWrapper = styled.div`
  margin-top: 10px;
`;
