import {
  ColDef,
  DataGrid,
  Modal,
  ModalContent,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
  selectColumn,
} from '@cmg/common';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectModal, InjectedProps, show } from 'redux-modal';
import styled from 'styled-components/macro';

import { firmDataSourceLabels } from '../../../../../types/domain/firm/constants';
import { FirmVersion } from '../../../../../types/domain/firm/FirmVersion';
import {
  fetchFirmVersionDiff,
  selectFirm,
  selectFirmHistory,
  selectFirmHistoryLoading,
} from '../../ducks';

const SButtonContainer = styled.div`
  > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

/**
 * cell renderer for source label
 */
export const SourceCellRenderer = ({ data }: { data: FirmVersion }) => (
  <div>{firmDataSourceLabels[data.source]}</div>
);

const columns: ColDef[] = [
  {
    ...selectColumn,
    headerCheckboxSelection: false,
  },
  {
    field: 'versionNumber',
    headerName: '#',
    sortable: false,
  },
  {
    field: 'createdOn',
    headerName: 'Date',
    sortable: false,
  },
  {
    field: 'source',
    headerName: 'Change Source',
    sortable: false,
    maxWidth: 140,
    cellRendererFramework: SourceCellRenderer,
  },
  {
    field: 'userName',
    headerName: 'Changed By',
    sortable: false,
  },
];

const mapStateToProps = state => ({
  firm: selectFirm(state),
  loading: selectFirmHistoryLoading(state),
  versions: selectFirmHistory(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      showDiff: fetchFirmVersionDiff,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type Props = InjectedProps & StateProps & DispatchProps;

export const FirmHistoryModalComponent: React.FC<Props> = ({
  actions,
  firm,
  loading,
  show,
  versions,
  handleHide,
}) => {
  // Declare a new state variable, which we'll call "count"
  const [selectedRows, setSelectedRows] = useState<FirmVersion[]>([]);

  const handleOnCompare = () => {
    actions.showDiff({
      id: firm.id!,
      fromVersion: selectedRows[0].versionNumber,
      toVersion: selectedRows[1].versionNumber,
    });
  };

  const handleOnClose = () => {
    handleHide();
  };

  const handleOnSelectionChange = (rows: FirmVersion[]) => {
    setSelectedRows(rows);
  };

  return (
    <Modal title="Change History" size="large" show={show} onHide={handleHide}>
      <ModalContent>
        <p>Select any two changes to compare (selected: {selectedRows.length})</p>
        <DataGrid<FirmVersion>
          extended={{
            withMargin: false,
            hideColumnResize: true,
            hideColumnSelector: true,
            hidePagination: true,
          }}
          columns={columns}
          rows={versions}
          resizeBy="grid"
          gridOptions={{
            suppressCellSelection: true,
            suppressRowClickSelection: true,
            overlayNoRowsTemplate: 'No records found',
          }}
          loading={loading}
          getRowNodeId={row => row.versionNumber}
          onSelectionChange={handleOnSelectionChange}
        />
      </ModalContent>
      <ModalFooter>
        <SButtonContainer>
          <SecondaryButton onClick={handleOnClose}>Close</SecondaryButton>
          <PrimaryButton disabled={selectedRows.length !== 2} onClick={handleOnCompare}>
            Compare
          </PrimaryButton>
        </SButtonContainer>
      </ModalFooter>
    </Modal>
  );
};

const ConnectedModal = connect(mapStateToProps, mapDispatchToProps)(FirmHistoryModalComponent);

export default connectModal({ name: 'ROLODEX/FIRM/SHOW_HISTORY' })(ConnectedModal);

export const openFirmHistoryModal = () => show('ROLODEX/FIRM/SHOW_HISTORY');
