import { Icon, IconButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledAdditionalActionsIconButton = styled(IconButton)`
  vertical-align: middle;
  margin: 0 5px;

  &:hover,
  &:focus {
    box-shadow: none;
  }
`;

export const StyledUltimateParentIcon = styled(Icon)`
  color: ${({ theme }) => theme.background.color.positive};
`;

export const SPopoverContentWrapper = styled.div`
  max-width: 200px;
`;
