import { DotStatus, Highlights } from '@cmg/design-system';
import { Fragment, VFC } from 'react';
import { connect } from 'react-redux';

import { getFullName, UserStatus } from '../../../../../types/domain/user/constants';
import { selectProfile } from '../../../profile/ducks';
import { ProfileDomainHighlightsItems } from './ProfileDomainHighlightsItems';

const mapStateToProps = state => ({
  profile: selectProfile(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

export const ProfileDomainHighlights: VFC<Props> = ({ profile }) => {
  if (!profile) {
    return null;
  }

  return (
    <Highlights
      title={getFullName(profile)}
      titleItems={
        <Fragment>
          <DotStatus
            label={profile.status === UserStatus.ACTIVE ? 'Active' : 'Disabled'}
            color={profile.status === UserStatus.ACTIVE ? 'success' : 'error'}
          />
          <DotStatus
            label={profile.accountConfirmed ? 'Confirmed' : 'Not Confirmed'}
            color={profile.status === UserStatus.ACTIVE ? 'success' : 'error'}
          />
        </Fragment>
      }
      items={<ProfileDomainHighlightsItems profile={profile} />}
    />
  );
};

export default connect(mapStateToProps)(ProfileDomainHighlights);
