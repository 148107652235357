import { Banner, Panel, ServerErrors } from '@cmg/common';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';

import LoadingOverlay from '../../../common/components/indicators/loading-overlay/LoadingOverlay';
import routeFactory from '../../../common/util/routeFactory';
import { UUID } from '../../../types/common';
import { FirmDataSource } from '../../../types/domain/firm/constants';
import { FirmMatch } from '../../../types/domain/firm-match/FirmMatch';
import { fetchCrmIntegration } from '../shared/ducks';
import CrmCustomFirmMatchForm from './components/crm-custom-firm-match-form/CrmCustomFirmMatchForm';
import CrmFirmMatchList from './components/crm-firm-match-list/CrmFirmMatchList';
import CrmFirmMatchListHeader from './components/crm-firm-match-list/CrmFirmMatchListHeader';
import CrmInvestorFirmDetailHeader from './components/crm-investor-firm-detail-header/CrmInvestorFirmDetailHeader';
import LinkOrganizationModal from './components/link-organization-modal/LinkOrganizationModal';
import MatchDetailPanel from './components/match-detail-panel/MatchDetailPanel';
import {
  fetchCrmInvestorFirm,
  fetchCrmInvestorFirmMatches,
  fetchCustomFirmMatches,
  selectCrmInvestorFirm,
  selectCrmInvestorFirmLoading,
  selectCustomFirmMatches,
  selectCustomFirmMatchesError,
  selectCustomFirmMatchesLoading,
  selectCustomFirmMatchTotals,
  selectFirmMatches,
  selectFirmMatchesError,
  selectFirmMatchesLoading,
  selectFirmMatchTotals,
  selectLinkedFirmMatch,
  selectLinkedFirmMatchLoading,
  selectUpdateCrmInvestorNotesLoading,
  updateCrmInvestorNotes,
} from './ducks';

const MatchDetailSlideoutWidth = 650;

export const SOuterWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export const SInnerWrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

export const SMainPanelWrapper = styled.div<{ isDetailsPanelVisible: boolean }>`
  width: ${({ isDetailsPanelVisible }) =>
    isDetailsPanelVisible ? `calc(100% - ${MatchDetailSlideoutWidth}px)` : '100%'};
  transition: ${({ isDetailsPanelVisible }) =>
    isDetailsPanelVisible ? `width 0.3s ease-out` : `width 0s none`};
  display: flex;
  flex-direction: column;
`;

export const StyledPanel = styled(Panel)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = state => ({
  crmInvestorFirm: selectCrmInvestorFirm(state),
  crmInvestorFirmLoading: selectCrmInvestorFirmLoading(state),
  firmMatches: selectFirmMatches(state),
  firmMatchTotals: selectFirmMatchTotals(state),
  customFirmMatches: selectCustomFirmMatches(state),
  customFirmMatchTotals: selectCustomFirmMatchTotals(state),
  customFirmMatchSearchError: selectCustomFirmMatchesError(state),
  loading: selectFirmMatchesLoading(state) || selectCustomFirmMatchesLoading(state),
  error: selectFirmMatchesError(state),
  linkedFirmMatch: selectLinkedFirmMatch(state),
  linkedFirmMatchLoading: selectLinkedFirmMatchLoading(state),
  updateCrmInvestorNotesLoading: selectUpdateCrmInvestorNotesLoading(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchCrmIntegration,
      fetchCrmInvestorFirm,
      fetchCrmInvestorFirmMatches,
      fetchCustomFirmMatches,
      updateCrmInvestorNotes,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = RouteComponentProps<{
  crmIntegrationId: UUID;
  crmInvestorFirmId: UUID;
  matchFirmId?: UUID | string;
  matchFirmType?: FirmDataSource;
}> &
  StateProps &
  DispatchProps;

/**
 * Show a CRM Record of an Account with CRM Integration,
 */
export const RolodexCrmMatchListRouteComponent: React.FC<Props> = ({
  loading,
  error,
  match,
  actions,
  firmMatches,
  firmMatchTotals,
  customFirmMatches,
  customFirmMatchTotals,
  customFirmMatchSearchError,
  linkedFirmMatch,
  linkedFirmMatchLoading,
  crmInvestorFirm,
  crmInvestorFirmLoading,
  updateCrmInvestorNotesLoading,
}) => {
  const [isCustom, setCustom] = useState(false);
  const matchDetailsRouteMatch = useRouteMatch<{
    matchFirmId?: UUID;
    matchFirmType?: FirmDataSource;
  }>(routeFactory.rolodexCrmEntityMatcherWithMatchDetails.routePath);
  const matchDetailsRouteParams = matchDetailsRouteMatch
    ? matchDetailsRouteMatch.params
    : { matchFirmId: undefined, matchFirmType: undefined };
  const { matchFirmId, matchFirmType } = matchDetailsRouteParams;
  const { crmIntegrationId, crmInvestorFirmId } = match.params;

  React.useEffect(() => {
    // needed for crm integration breadcrumb
    actions.fetchCrmIntegration({ crmIntegrationId });

    // fetch single investor firm detail from route params
    actions.fetchCrmInvestorFirm({
      crmIntegrationId,
      crmInvestorFirmId,
    });

    // fetch firm matcher from each source
    actions.fetchCrmInvestorFirmMatches({
      crmIntegrationId,
      crmInvestorFirmId,
    });
  }, [actions, crmIntegrationId, crmInvestorFirmId]);

  const handleCustomSearch = formValues => {
    actions.fetchCustomFirmMatches({
      crmIntegrationId,
      crmInvestorFirmId,
      ...formValues,
    });
  };

  if (error) {
    return (
      <Banner variant="error" showIcon={false}>
        <ServerErrors error={error} />
      </Banner>
    );
  }

  if (crmInvestorFirmLoading || !crmInvestorFirm) {
    return <LoadingOverlay message="Loading..." />;
  }

  const matches: FirmMatch[] = isCustom ? customFirmMatches : firmMatches;
  const isDetailsPanelVisible = !!matchFirmId && !!matchFirmType;

  return (
    <React.Fragment>
      <SOuterWrapper>
        <SInnerWrapper>
          <SMainPanelWrapper isDetailsPanelVisible={isDetailsPanelVisible}>
            <CrmInvestorFirmDetailHeader
              onSave={notes =>
                actions.updateCrmInvestorNotes({ crmIntegrationId, crmInvestorFirmId, notes })
              }
              loading={updateCrmInvestorNotesLoading}
            />
            <StyledPanel>
              <CrmFirmMatchListHeader
                isCustom={isCustom}
                toggleCustom={setCustom}
                totals={isCustom ? customFirmMatchTotals : firmMatchTotals}
              />
              {isCustom && (
                <React.Fragment>
                  {customFirmMatchSearchError && (
                    <Banner variant="error" showIcon={false}>
                      <ServerErrors error={customFirmMatchSearchError} />
                    </Banner>
                  )}
                  <CrmCustomFirmMatchForm onSubmit={handleCustomSearch} />
                </React.Fragment>
              )}
              <CrmFirmMatchList
                crmIntegrationId={crmIntegrationId}
                crmInvestorFirmId={crmInvestorFirmId}
                crmInvestorFirm={crmInvestorFirm}
                firmMatches={matches}
                linkedFirmMatch={linkedFirmMatch}
                loading={linkedFirmMatchLoading || loading}
              />
            </StyledPanel>
          </SMainPanelWrapper>
          <MatchDetailPanel
            show={isDetailsPanelVisible}
            width={MatchDetailSlideoutWidth}
            crmIntegrationId={crmIntegrationId}
            crmInvestorFirmId={crmInvestorFirmId}
            matchFirmId={matchFirmId}
            matchFirmType={matchFirmType}
            cmgEntityKey={crmInvestorFirm.cmgEntityKey}
          />
        </SInnerWrapper>
      </SOuterWrapper>
      <LinkOrganizationModal
        crmIntegrationId={crmIntegrationId}
        crmInvestorFirm={crmInvestorFirm}
      />
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RolodexCrmMatchListRouteComponent);
