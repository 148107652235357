import {
  Banner,
  DiffView,
  ModalContent,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
  ServerErrors,
} from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { UUID } from '../../../../../types/common';
import { FactSetFirmRelation } from '../../../../../types/domain/firm/FactSetFirmRelation';
import { Firm } from '../../../../../types/domain/firm/Firm';
import {
  connectFactSetFirmWithCMGFirm,
  selectConnectFactSetFirmError,
  selectConnectFactSetFirmLoading,
} from '../../ducks';
import { SButtonWrapper } from './ConnectWithFactSetModal.styles';

const mapStateToProps = state => ({
  isConnecting: selectConnectFactSetFirmLoading(state),
  connectionError: selectConnectFactSetFirmError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      connectFactSetFirmWithCMGFirm,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  /** The ID of the CMG Firm that is to be linked to the selected FactSet Firm. */
  cmgFirmId: UUID;
  /** The FactSet firm that is to be linked to the CMG Firm. */
  selectedFactSetFirm: FactSetFirmRelation;
  /** Representation of what the FactSet firm data would look like as a CMG Firm entity. */
  connectFirmPreview: { from: Firm; to: Firm };
  /** Callback prop triggered when "Cancel" button in footer content is clicked. */
  onCancel: () => void;
};
export type Props = OwnProps & StateProps & DispatchProps;

/**
 * Displays the second step of the Connect With FactSet
 * experience.  Displays a JSON diff of the CMG firm against the
 * FactSet firm, exhibiting the incoming changes from the FactSet connection.
 */
export const ConnectFactSetFirmWithCMGFirmComponent: React.FC<Props> = ({
  cmgFirmId,
  selectedFactSetFirm,
  onCancel,
  connectFirmPreview,
  isConnecting,
  connectionError,
  actions,
}) => {
  const cmgFirmDisplayName =
    connectFirmPreview &&
    connectFirmPreview.from &&
    connectFirmPreview.from.names &&
    connectFirmPreview.from.names.find(name => name.isDisplayName);

  return (
    <React.Fragment>
      <ModalContent>
        <DiffView prevVersion={connectFirmPreview.from} nextVersion={connectFirmPreview.to} />
      </ModalContent>
      <ModalFooter>
        {connectionError && (
          <Banner variant="error" showIcon={false}>
            <ServerErrors error={connectionError} />
          </Banner>
        )}
        <SButtonWrapper>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton
            loading={isConnecting}
            onClick={() => {
              actions.connectFactSetFirmWithCMGFirm({
                factSetFirmId: selectedFactSetFirm.factSetFirmId,
                cmgFirmId,
              });
            }}
          >
            Connect {selectedFactSetFirm.properName} to{' '}
            {cmgFirmDisplayName ? cmgFirmDisplayName.value : cmgFirmId}
          </PrimaryButton>
        </SButtonWrapper>
      </ModalFooter>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectFactSetFirmWithCMGFirmComponent);
