import { apiTypes, reduxUtil } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { History } from 'history';
import { AnyAction, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as accountApiClient from '../../../common/api/accountApiClient';
import systemManagementApiClient, {
  CreateAccountUserResponse,
} from '../../../common/api/systemManagementApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import routeFactory from '../../../common/util/routeFactory';
import { UserCreate, UserCreateQuery } from '../../../types/domain/user/user';
import { selectSelfSubdomain } from '../../shared/ducks';
import { selectAccountSubdomain } from '../shared/ducks';

const createReducer = reduxUtil.createReducer;

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  CREATE_USER_REQUEST = 'global_management/CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS = 'global_management/CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE = 'global_management/CREATE_USER_FAILURE',
  RESET_STATE = 'global_management/RESET_STATE',
}

/**
 * ACTION CREATORS
 */
export const resetState = () => ({
  type: ActionTypes.RESET_STATE,
});

export const createUser = (
  params: {
    user: UserCreate;
    history: History;
  } & UserCreateQuery
) => ({
  type: ActionTypes.CREATE_USER_REQUEST,
  payload: params,
});

export const createUserSuccess = () => ({
  type: ActionTypes.CREATE_USER_SUCCESS,
});

export const createUserFailure = ({ error }) => ({
  type: ActionTypes.CREATE_USER_FAILURE,
  payload: {
    error,
  },
});

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.RESET_STATE]: ReturnType<typeof resetState>;
  [ActionTypes.CREATE_USER_REQUEST]: ReturnType<typeof createUser>;
  [ActionTypes.CREATE_USER_SUCCESS]: ReturnType<typeof createUserSuccess>;
  [ActionTypes.CREATE_USER_FAILURE]: ReturnType<typeof createUserFailure>;
};

/**
 * REDUCERS
 */
export type ReducerState = {
  error: apiTypes.GenericServerError | null; // was there an error submitting?
  submitting: boolean; // is it submitting?
};

export const initialState = {
  error: null,
  submitting: false,
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.CREATE_USER_REQUEST]: () => null,
  [ActionTypes.CREATE_USER_SUCCESS]: () => null,
  [ActionTypes.CREATE_USER_FAILURE]: (curState, { payload }) => payload.error,
});

const submittingReducer = createReducer<ReducerState['submitting'], Actions>(
  initialState.submitting,
  {
    [ActionTypes.CREATE_USER_REQUEST]: () => true,
    [ActionTypes.CREATE_USER_SUCCESS]: () => false,
    [ActionTypes.CREATE_USER_FAILURE]: () => false,
  }
);

// This reducer acts on the entire state of this duck. Has access to duck state and must return duck state.
const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_STATE]: () => ({ ...initialState }),
});

const combinedReducers = combineReducers<ReducerState>({
  error: errorReducer,
  submitting: submittingReducer,
});

export default createDucksReducer(combinedReducers);

// Combines our individual slice reducers and the cross slice reducer.
export function createDucksReducer(combinedReducers) {
  return (state: ReducerState = initialState, action: AnyAction) =>
    crossSliceReducer(combinedReducers(state, action), action);
}
/**
 * SELECTORS
 */
const selectState = (state: RootState) => state.adminAccountUserCreate;
export const selectError = state => selectState(state).error;
export const selectSubmitting = state => selectState(state).submitting;

/**
 * SAGAS
 */
export function* createUserSaga({
  payload,
}: Actions[ActionTypes.CREATE_USER_REQUEST]): SagaIterator {
  const { user, sendInvite, history } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: accountApiClient.CreateMyAccountUserResponse | CreateAccountUserResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.createMyAccountUser, user, sendInvite);
  } else {
    response = yield call(
      systemManagementApiClient.admin.createAccountUser,
      user,
      sendInvite,
      accountSubdomain
    );
  }
  if (response.ok) {
    yield put(createUserSuccess());
    history.push(
      routeFactory.accountUserDetail.getUrlPath({ accountSubdomain, userId: response.data.id })
    );
    SnackbarManager.success(`Successfully added ${user.firstName} ${user.lastName}`);
  } else {
    yield put(createUserFailure({ error: response.data.error }));
  }
}

export function* adminAccountUserCreateSaga() {
  yield takeLatest<Actions[ActionTypes.CREATE_USER_REQUEST]>(
    ActionTypes.CREATE_USER_REQUEST,
    createUserSaga
  );
}
