import { FirmCategoryType } from '@capital-markets-gateway/api-client-rolodex';
import { Popover } from '@cmg/common';
import React from 'react';

import routeFactory from '../../../../../../common/util/routeFactory';
import { UUID } from '../../../../../../types/common';
import { FirmDataSource, FirmRoleType } from '../../../../../../types/domain/firm/constants';
import { FirmMatchRow } from '../../../../../../types/domain/firm-match/FirmMatch';
import { FirmStatus } from '../../../../../../types/domain/firm-status/FirmStatus';
import {
  IconPopover,
  RenderIconPopOver,
} from '../../../../crm-integration-detail/components/crm-investor-firm-list/CrmInvestorFirmListGridColumns';
import { OnPlatformIcon } from '../../../../shared/OnPlatformIcon';
import {
  SInvestorRole,
  SName,
  SNameWrapper,
  StyledLink,
  StyledStatusIcon,
} from './CrmFirmMatchListCellRenderer.styles';

/**
 * Renders a link to FirmMatch details slideout with FirmMatch name
 */
export const NameCellRenderer: React.FC<{
  crmIntegrationId: UUID;
  crmInvestorFirmId: UUID;
  data: FirmMatchRow;
  crmInvestorFirmType: string;
}> = ({ crmIntegrationId, crmInvestorFirmId, data: firmMatch, crmInvestorFirmType }) => {
  const { names, roles, status, isOnPlatform } = firmMatch;

  // only get name where isDisplayName=true
  const name = (names && names.find(n => n.isDisplayName)) || { value: undefined };

  const isCrmInvestorFirmRow = firmMatch.id === crmInvestorFirmId;
  const firmCategoryType =
    crmInvestorFirmType && FirmCategoryType[crmInvestorFirmType]
      ? FirmCategoryType[crmInvestorFirmType]
      : null;

  if (isCrmInvestorFirmRow) {
    return (
      <SNameWrapper>
        <OnPlatformIcon isOnPlatform={isOnPlatform} />
        <SName>{name.value}</SName>
        <RenderIconPopOver firmType={firmCategoryType}></RenderIconPopOver>
      </SNameWrapper>
    );
  }

  // renders a money bag icon (sack-dollar)
  const hasInvestorRole = roles && roles.includes(FirmRoleType.INVESTOR);

  // renders a red dot if firm is defunct
  const isDefunct = status === FirmStatus.DEFUNCT;

  return (
    <SNameWrapper>
      {isDefunct && (
        <Popover content={<div>Defunct</div>} variant="DARK">
          <span>
            <StyledStatusIcon fixedWidth name="circle" variant="solid" size="1x" />
          </span>
        </Popover>
      )}
      <OnPlatformIcon isOnPlatform={isOnPlatform} />
      <StyledLink
        to={routeFactory.rolodexCrmEntityMatcherWithMatchDetails.getUrlPath({
          crmIntegrationId,
          crmInvestorFirmId,
          matchFirmId: firmMatch.id,
          matchFirmType: FirmDataSource[firmMatch.firmSource!],
        })}
      >
        {name.value}
      </StyledLink>
      <SInvestorRole>
        {hasInvestorRole && <IconPopover content="Investor" name="chart-line" />}
      </SInvestorRole>
    </SNameWrapper>
  );
};

export default NameCellRenderer;
