import { apiTypes } from '@cmg/common';
import { Button, EditIcon } from '@cmg/design-system';
import { FC, Fragment, useCallback, useState } from 'react';

import { PageGridSection } from '../../../../../../design-system/body-sections/PageGridSection';
import { Role } from '../../../../../../types/domain/role/role';
import UserRole from '../../../../../../types/domain/role/UserRole';
import { UserRolesUpdate } from '../../../../../../types/domain/user/user';
import { RolesSectionEditDialog } from './RolesSectionEditDialog';
import { RolesSectionTable } from './RolesSectionTable';

export type Props = Readonly<{
  canEdit: Readonly<boolean>;
  userRoles: readonly UserRole[];
  allRoles: readonly Role[];
  isLoading: Readonly<boolean>;
  error: Readonly<apiTypes.GenericServerError | null>;
  onUserRolesUpdate: (userRoles: UserRolesUpdate) => void;
  onReset: () => void;
}>;

export const RolesSection: FC<Props> = ({
  canEdit,
  userRoles,
  allRoles,
  isLoading,
  error,
  onUserRolesUpdate,
  onReset,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const closeEditDialog = useCallback(() => {
    setIsEditing(false);
    onReset();
  }, [onReset]);

  return (
    <PageGridSection
      size="medium"
      title="Roles"
      actions={
        canEdit ? (
          <Button variant="text" startIcon={<EditIcon />} onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        ) : undefined
      }
      content={
        <Fragment>
          <RolesSectionTable userRoles={userRoles} allRoles={allRoles} />
          <RolesSectionEditDialog
            defaultSelectedRoleIds={userRoles.map(role => role.id)}
            allRoles={allRoles}
            isOpen={isEditing}
            isLoading={isLoading}
            error={error}
            onClose={() => closeEditDialog()}
            onSubmit={onUserRolesUpdate}
          />
        </Fragment>
      }
    />
  );
};
