import { apiTypes, Banner, PrimaryButton, ServerErrors, TextInputField } from '@cmg/common';
import {
  passwordExpiredConfirmPasswordSelector,
  passwordExpiredFormSelector,
  passwordExpiredPasswordSelector,
  passwordExpiredSubmitSelector,
} from '@cmg/e2e-selectors';
import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import externalRouteFactory from '../../../../../../common/util/externalRouteFactory';
import { SButtonsSection, SFormSection } from './ExpiredPasswordForm.styles';

export const ExpiredPasswordSchema = yup.object().shape({
  newPassword: yup.string().required('A password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

export type ExpiredPasswordFormValues = {
  newPassword: string;
  confirmPassword: string;
  token: string;
  userId: string;
};

type OwnProps = {
  onSubmit: (values: ExpiredPasswordFormValues) => void;
  error: apiTypes.GenericServerError | null;
  success: boolean;
  submitting: boolean;
  token: string;
  userId: string;
};
type State = { confirmPassword: string; password: string };
type Props = OwnProps & FormikProps<ExpiredPasswordFormValues>;

/**
 * Render expired password form for password and confirmPassword
 */
export class ExpiredPasswordFormComponent extends React.Component<Props, State> {
  render() {
    const { error, submitting, success } = this.props;

    if (success) {
      return (
        <React.Fragment>
          <h1>Password is Expired</h1>
          <Banner variant="success">You've successfully set a new password. Please log in.</Banner>
          <PrimaryButton variant="high-contrast" href={externalRouteFactory.ecmSpa.getUrl()}>
            Log in
          </PrimaryButton>
        </React.Fragment>
      );
    }

    return (
      <Form data-test-id={passwordExpiredFormSelector.testId}>
        <h1>Password is Expired</h1>
        <p>Please set a new password to continue.</p>
        <SFormSection>
          <TextInputField
            type="password"
            name="newPassword"
            placeholder="Enter a new password..."
            data-test-id={passwordExpiredPasswordSelector.testId}
            fullWidth
          />
        </SFormSection>
        <SFormSection>
          <TextInputField
            type="password"
            name="confirmPassword"
            placeholder="Confirm new password..."
            data-test-id={passwordExpiredConfirmPasswordSelector.testId}
            fullWidth
          />
        </SFormSection>
        <SButtonsSection>
          {error && (
            <Banner variant="error" showIcon={false}>
              <ServerErrors error={error} />
            </Banner>
          )}
          <PrimaryButton
            variant="high-contrast"
            loading={submitting}
            type="submit"
            fullWidth
            data-test-id={passwordExpiredSubmitSelector.testId}
          >
            Set new password
          </PrimaryButton>
        </SButtonsSection>
      </Form>
    );
  }
}

/**
 * Form that captures username and password for a database style oidc login.
 */
export default withFormik<OwnProps, ExpiredPasswordFormValues>({
  enableReinitialize: false,
  isInitialValid: false,
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: ExpiredPasswordSchema,
  mapPropsToValues: ({ token, userId }) => ({
    newPassword: '',
    confirmPassword: '',
    token,
    userId,
  }),
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit(values);
  },
})(ExpiredPasswordFormComponent);
