import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@cmg/design-system';
import { VFC } from 'react';

import routeFactory from '../../../../../common/util/routeFactory';
import {
  identityProviderTypeDisplay,
  identityProviderTypeRoute,
} from '../../../../../types/domain/identity-provider/constants';
import { AccountIdentityProvider } from '../../../../../types/domain/identity-provider/identityProvider';
import { LoginSettingsSectionTableRow } from './LoginSettingsSectionTableRow';

export type Props = {
  localLoginEnabled: Readonly<boolean>;
  identityProviders: readonly AccountIdentityProvider[] | null;
  accountSubdomain?: Readonly<string>;
};

export const LoginSettingsSectionTable: VFC<Props> = ({
  localLoginEnabled,
  identityProviders,
  accountSubdomain = '',
}) => {
  return (
    <TableContainer>
      <Table aria-label="Configured Login Types">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <LoginSettingsSectionTableRow
            loginTypeUrl={routeFactory.accountSecurityProviderLocalLogin.getUrlPath({
              accountSubdomain,
            })}
            loginTypeDisplayName="Local Login"
            showVerificationStatus={false}
            isVerified={true}
            isEnabled={localLoginEnabled}
          />
          {identityProviders &&
            identityProviders.map(provider => (
              <LoginSettingsSectionTableRow
                key={provider.name}
                loginTypeUrl={identityProviderTypeRoute[provider.providerType].getUrlPath({
                  accountSubdomain,
                })}
                loginTypeDisplayName={identityProviderTypeDisplay[provider.providerType]}
                providerName={provider.name}
                showVerificationStatus={true}
                isVerified={provider.verified}
                isEnabled={provider.enabled}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
