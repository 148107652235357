import React from 'react';
import styled from 'styled-components/macro';

import {
  firmAddressTypeLabels,
  firmAddressTypeOptions,
} from '../../../../types/domain/firm/constants';
import {
  recordStatusLabels,
  recordStatusOptions,
} from '../../../../types/domain/record-status/constants';
import { StyledSelectField, StyledTextInputField } from './inputs/RecordInputs.styles';

export const SCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SRow = styled.div<{ withMargins?: boolean }>`
  display: flex;
  flex-direction: row;

  &:not(:first-child) {
    margin: ${({ withMargins }) => (withMargins ? '10px 0 0 0' : '0')};
  }

  > * {
    &:not(:first-child) {
      margin: 0 0 0 5px;
    }
  }
`;

/**
 * The column configuration for the Firm Address Record Table
 */
export const getFirmAddressColumns = () => [
  {
    field: 'type',
    headerName: 'Type',
    editCellRendererFramework: (row, disabled) => (
      <StyledSelectField name="type" options={firmAddressTypeOptions} disabled={disabled} />
    ),
    cellRendererFramework: row => firmAddressTypeLabels[row.type],
    values: row => ({
      type: row.type,
    }),
  },
  {
    field: 'address',
    headerName: 'Address',
    editCellRendererFramework: (row, disabled) => (
      <SCol>
        <SRow withMargins>
          <StyledTextInputField
            name="streetLine1"
            placeholder="Street Line 1"
            disabled={disabled}
          />
          <StyledTextInputField
            name="streetLine2"
            placeholder="Street Line 2"
            disabled={disabled}
          />
          <StyledTextInputField
            name="streetLine3"
            placeholder="Street Line 3"
            disabled={disabled}
          />
        </SRow>
        <SRow withMargins>
          <StyledTextInputField name="city" placeholder="City" disabled={disabled} />
          <StyledTextInputField name="state" placeholder="State" disabled={disabled} />
          <StyledTextInputField name="postalCode" placeholder="Postal Code" disabled={disabled} />
        </SRow>
        <SRow withMargins>
          <StyledTextInputField name="country" placeholder="Country" disabled={disabled} />
          <StyledTextInputField name="phoneNumber" placeholder="Phone Number" disabled={disabled} />
          <StyledTextInputField name="faxNumber" placeholder="Fax Number" disabled={disabled} />
        </SRow>
      </SCol>
    ),
    cellRendererFramework: row => (
      <SCol>
        <SRow>
          {[row.streetLine1, row.streetLine2, row.streetLine3].filter(val => !!val).join(', ')}
        </SRow>
        <SRow>{[row.city, row.state, row.postalCode].filter(val => !!val).join(', ')}</SRow>
        <SRow>
          <span>{row.country} / </span>
          {row.phoneNumber && <span>Phone: {row.phoneNumber} / </span>}
          {row.faxNumber && <span>Fax: {row.faxNumber}</span>}
        </SRow>
      </SCol>
    ),
    values: ({
      streetLine1,
      streetLine2,
      streetLine3,
      city,
      state,
      postalCode,
      country,
      phoneNumber,
      faxNumber,
    }) => ({
      streetLine1,
      streetLine2,
      streetLine3,
      city,
      state,
      postalCode,
      country,
      phoneNumber,
      faxNumber,
    }),
  },
  {
    field: 'recordStatus',
    headerName: 'Status',
    editCellRendererFramework: (row, disabled) => (
      <StyledSelectField name="recordStatus" options={recordStatusOptions} disabled={disabled} />
    ),
    cellRendererFramework: row => recordStatusLabels[row.recordStatus],
    values: ({ recordStatus }) => ({
      recordStatus,
    }),
  },
];
