import { TableCell, TableRow } from '@cmg/design-system';
import { FC } from 'react';

import { AccountPermission } from '../../../../../types/domain/account/account';
import { PermissionCell } from './PermissionCell';

export type Props = Readonly<{
  readonly isInEditMode: boolean;
  readonly entity: string;
  readonly description: string;
  readonly entityPermissions: string[];
  readonly sanitizedSelectedPermissions: { [key: string]: string };
  readonly accountPermissions: AccountPermission[];
}>;

export const PermissionsTableRow: FC<Props> = ({
  isInEditMode,
  entity,
  description,
  entityPermissions,
  sanitizedSelectedPermissions,
  accountPermissions,
}) => {
  return (
    <TableRow>
      <TableCell>{entity}</TableCell>
      <TableCell>{description}</TableCell>
      {entityPermissions.map(permissionName => {
        return (
          <PermissionCell
            isInEditMode={isInEditMode}
            key={permissionName}
            name={permissionName}
            selectedPermissions={sanitizedSelectedPermissions}
            accountPermissions={accountPermissions}
          />
        );
      })}
      {entityPermissions.length === 2 && <TableCell />}
    </TableRow>
  );
};
