import { AdminFirmBasicInformationRead } from '@capital-markets-gateway/api-client-rolodex';
import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import { FlexContainer, Popover, SecondaryButton, Tag } from '@cmg/common';
import { identitySelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Status from '../../../../../common/components/indicators/status/Status';
import DetailHeader from '../../../../../common/components/layout/detail-header/DetailHeader';
import { firmStatusLabels } from '../../../../../types/domain/firm-status/constants';
import { FirmStatus } from '../../../../../types/domain/firm-status/FirmStatus';
import { recordStatusLabels } from '../../../../../types/domain/record-status/constants';
import { RecordStatus } from '../../../../../types/domain/record-status/RecordStatus';
import { OnPlatformIcon } from '../../../shared/OnPlatformIcon';
import { openConnectWithFactSetModal } from '../../components/connect-with-factset-modal/ConnectWithFactSetModal';
import { openDisconnectWithFactSetModal } from '../../components/disconnect-with-factset-modal/DisconnectWithFactSetModal';
import {
  fetchFirmHistory,
  selectFirm,
  selectFirmDetails,
  selectFirmDetailsError,
  selectFirmDetailsLoading,
  selectFirmDisplayName,
  updateFirmDetails,
} from '../../ducks';
import { SDetailHeaderButtonWrapper } from './FirmDetailHeader.styles';
import { RecordStatusButton } from './RecordStatusButton';

const mapStateToProps = state => ({
  firm: selectFirm(state),
  name: selectFirmDisplayName(state),
  details: selectFirmDetails(state),
  detailsLoading: selectFirmDetailsLoading(state),
  detailsError: selectFirmDetailsError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateFirmDetails,
      showFirmHistory: fetchFirmHistory,
      openConnectWithFactSetModal,
      openDisconnectWithFactSetModal,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = StateProps & DispatchProps;

export const FirmDetailHeaderComponent: React.FC<Props> = ({
  firm,
  name,
  details,
  detailsLoading,
  actions,
}) => {
  const { userPermissions } = useAuth();
  const canEdit = checkPermissions(userPermissions, [permissionsByEntity.Firm.FULL]);

  const handleUpdateFirm = (params: Partial<AdminFirmBasicInformationRead>) => {
    firm.id &&
      details &&
      actions.updateFirmDetails({
        firmId: firm.id,
        id: details.id,
        body: { ...params },
      });
  };

  const handleRecordStatusButtonClick = () =>
    details &&
    handleUpdateFirm({
      recordStatus:
        details.recordStatus === RecordStatus.EFFECTIVE
          ? RecordStatus.DELETED
          : RecordStatus.EFFECTIVE,
    });

  const handleFirmStatusButtonClick = () =>
    details &&
    handleUpdateFirm({
      status: details.status === FirmStatus.DEFUNCT ? FirmStatus.ACTIVE : FirmStatus.DEFUNCT,
    });

  const handleShowFirmHistory = () => {
    actions.showFirmHistory({
      id: firm.id!,
    });
  };

  return (
    <DetailHeader.Header>
      <DetailHeader.TitleWrapper>
        {name && (
          <DetailHeader.Title>
            <FlexContainer direction="row" gap={4} alignItems="baseline">
              <OnPlatformIcon isOnPlatform={firm.isOnPlatform ?? false} size="M" />
              {name.value}
            </FlexContainer>
          </DetailHeader.Title>
        )}
        {details && (
          <React.Fragment>
            <Status
              isActive={details.status === FirmStatus.ACTIVE}
              activeLabel={firmStatusLabels[FirmStatus.ACTIVE]}
              inactiveLabel={firmStatusLabels[FirmStatus.DEFUNCT]}
            />
            <Status
              isActive={details.recordStatus === RecordStatus.EFFECTIVE}
              activeLabel={recordStatusLabels[RecordStatus.EFFECTIVE]}
              inactiveLabel={recordStatusLabels[RecordStatus.DELETED]}
            />
            {!firm.isFactSetConnected && (
              <Popover
                variant="TOOLTIP"
                content="This organization is not connected to the FactSet Database"
              >
                <Tag>Local</Tag>
              </Popover>
            )}
          </React.Fragment>
        )}
        <DetailHeader.Column>
          <SDetailHeaderButtonWrapper>
            {canEdit && details && (
              <React.Fragment>
                <RecordStatusButton
                  recordStatus={details.recordStatus}
                  disabled={detailsLoading}
                  onClick={handleRecordStatusButtonClick}
                />
                <SecondaryButton disabled={detailsLoading} onClick={handleFirmStatusButtonClick}>
                  {details.status === FirmStatus.ACTIVE
                    ? 'Defunct Organization'
                    : 'Activate Organization'}
                </SecondaryButton>
              </React.Fragment>
            )}
            <SecondaryButton onClick={handleShowFirmHistory}>View History</SecondaryButton>
            {canEdit &&
              (firm.isFactSetConnected ? (
                <SecondaryButton
                  testId={identitySelectors.rolodex.connectWithFactSetButton.testId}
                  onClick={actions.openDisconnectWithFactSetModal}
                >
                  Disconnect with FactSet
                </SecondaryButton>
              ) : (
                <SecondaryButton
                  testId={identitySelectors.rolodex.disconnectWithFactSetButton.testId}
                  onClick={actions.openConnectWithFactSetModal}
                >
                  Connect to FactSet
                </SecondaryButton>
              ))}
          </SDetailHeaderButtonWrapper>
        </DetailHeader.Column>
      </DetailHeader.TitleWrapper>
      <DetailHeader.Row>
        <DetailHeader.Column>
          <DetailHeader.DetailWrapper>
            <DetailHeader.DetailLabel>ID</DetailHeader.DetailLabel>
            {details && <DetailHeader.DetailValue>{firm.key}</DetailHeader.DetailValue>}
          </DetailHeader.DetailWrapper>
        </DetailHeader.Column>
      </DetailHeader.Row>
    </DetailHeader.Header>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmDetailHeaderComponent);
