export function getSsoStatusText(isSsoEnabled: boolean, doesAccountRequire2FA: boolean): string {
  if (isSsoEnabled) {
    return 'SSO Enabled';
  }

  if (doesAccountRequire2FA) {
    return '2FA Enabled';
  }

  return 'SSO & 2FA Disabled';
}
