import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { SubNavigation } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useFeatureToggles } from '../../common/config/context/useFeatureToggles';
import routeFactory from '../../common/util/routeFactory';
import { UUID } from '../../types/common';
import { selectCrmInvestorFirm } from './crm-match-list/ducks';
import { selectCrmIntegration } from './shared/ducks';

const mapStateToProps = state => ({
  crmIntegration: selectCrmIntegration(state),
  crmInvestorFirm: selectCrmInvestorFirm(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;

export const StyledSubNavigationLink = styled(SubNavigation.Link)`
  margin-left: 2rem;
`;

/**
 * Rolodex CRM Integration Breadcrumb
 */
export const RolodexCrmIntegrationBreadcrumbComponent: React.FC<
  StateProps &
    RouteComponentProps<{
      crmIntegrationId: UUID;
    }>
> = ({ match, crmIntegration }) => {
  if (!crmIntegration) {
    return null;
  }

  return (
    <React.Fragment>
      <SubNavigation.Breadcrumb>
        <SubNavigation.Link
          exact
          to={routeFactory.rolodexCrmIntegrationDetail.getUrlPath(match.params)}
        >
          {crmIntegration.name}
        </SubNavigation.Link>
      </SubNavigation.Breadcrumb>
    </React.Fragment>
  );
};

const ConnectedIntegrationBreadcrumb = connect(mapStateToProps)(
  RolodexCrmIntegrationBreadcrumbComponent
);

/**
 * Rolodex CRM Management Breadcrumb
 */
export const RolodexCrmManagementBreadcrumbComponent: React.FC<
  StateProps &
    RouteComponentProps<{
      crmIntegrationId: UUID;
    }>
> = ({ match, crmIntegration }) => {
  const canManageGlobalCrm = useCheckPermissions([permissionsByEntity.GlobalCrm.FULL]);
  const { sysAdminCrmManagement } = useFeatureToggles();

  if (!crmIntegration) {
    return null;
  }

  return (
    <React.Fragment>
      {sysAdminCrmManagement && canManageGlobalCrm && match.params.crmIntegrationId && (
        <StyledSubNavigationLink to={routeFactory.sysAdminCrmManagement.getUrlPath(match.params)}>
          CRM Management
        </StyledSubNavigationLink>
      )}
    </React.Fragment>
  );
};

const ConnectedCRMManagementBreadcrumb = connect(mapStateToProps)(
  RolodexCrmManagementBreadcrumbComponent
);

/**
 * Rolodex CRM Investor Firm Breadcrumb
 */
export const RolodexCrmInvestorFirmBreadcrumbComponent: React.FC<
  StateProps &
    RouteComponentProps<{
      crmIntegrationId: UUID;
      crmInvestorFirmId: UUID;
    }>
> = ({ match, crmInvestorFirm }) => {
  if (!crmInvestorFirm) {
    return null;
  }

  return (
    <React.Fragment>
      <SubNavigation.Breadcrumb>
        <SubNavigation.Link to={routeFactory.rolodexCrmEntityMatcher.getUrlPath(match.params)}>
          {crmInvestorFirm.name}
        </SubNavigation.Link>
      </SubNavigation.Breadcrumb>
    </React.Fragment>
  );
};

const ConnectedInvestorFirmBreadcrumb = connect(mapStateToProps)(
  RolodexCrmInvestorFirmBreadcrumbComponent
);

/**
 * Displays the CRM Integration's name as breadcrumb on CRM Integration Details
 * Displays the CRM Investor Firm name when on CRM Entity Matcher (Investor Firm Details)
 */

const RolodexCrmBreadcrumb = () => (
  <React.Fragment>
    <Route
      path={routeFactory.rolodexCrmIntegrationDetail.routePath}
      component={ConnectedIntegrationBreadcrumb}
    />
    <Route
      path={routeFactory.rolodexCrmEntityMatcher.routePath}
      component={ConnectedIntegrationBreadcrumb}
    />
    <Route
      path={routeFactory.rolodexCrmEntityMatcher.routePath}
      component={ConnectedInvestorFirmBreadcrumb}
    />
    <Route
      path={[
        routeFactory.rolodexCrmIntegrationDetail.routePath,
        routeFactory.rolodexCrmEntityMatcher.routePath,
      ]}
      component={ConnectedCRMManagementBreadcrumb}
    />
  </React.Fragment>
);

export default RolodexCrmBreadcrumb;
