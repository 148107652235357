import { Popover } from '@cmg/common';
import React from 'react';

import { OnPlatformIconSize, SOnPlatformIcon } from './OnPlatformIcon.styles';

export const OnPlatformIcon: React.FC<{ isOnPlatform: boolean; size?: OnPlatformIconSize }> = ({
  isOnPlatform,
  size,
}) => (
  <Popover
    disabled={!isOnPlatform}
    content="This investor is on the CMG platform."
    variant="DARK"
    placement="topRight"
    arrowPointAtCenter
  >
    <SOnPlatformIcon isOnPlatform={isOnPlatform} size={size} />
  </Popover>
);
