import { permissionsByEntity, useAuth, useCheckPermissions } from '@cmg/auth';
import { HTMLAttributeAnchorTarget } from 'react';

import { useRoutesMatch } from '../../../../../../design-system/react-router/useRoutesMatch';
import { useAppSettings } from '../../../../../config/context/useAppSettings';
import { useFeatureToggles } from '../../../../../config/context/useFeatureToggles';
import routeFactory from '../../../../../util/routeFactory';

export type UseAppBarNavigationProps = {
  readonly selfSubdomain: string;
};

export type AppBarNavigationDefinition = {
  readonly name: string;
  readonly href: string;
  readonly target?: HTMLAttributeAnchorTarget;
  readonly label: string;
};

export type UseAppBarNavigationResult = {
  readonly selectedDefinitionName?: string;
  readonly navDefinitions: AppBarNavigationDefinition[];
};

export function useAppBarNavigation({
  selfSubdomain,
}: UseAppBarNavigationProps): UseAppBarNavigationResult {
  const { isSystemUser } = useAuth();
  const { datalabAdminRootPath } = useAppSettings().env;
  const { customerCrmManagement } = useFeatureToggles();

  const canReadAccount = useCheckPermissions([permissionsByEntity.Account.READ]);
  const canManageCRM = useCheckPermissions([permissionsByEntity.CRM.FULL]);
  const canReadGlobalAccount = useCheckPermissions([permissionsByEntity.GlobalAccount.READ]);
  const canReadFirm = useCheckPermissions([permissionsByEntity.Firm.READ]);
  const canWriteOffPlatformOffering = useCheckPermissions([
    permissionsByEntity.OffPlatformOffering.FULL,
  ]);

  const { matchedRouteName, routeNames } = useRoutesMatch({
    profile: routeFactory.profile.routePath,
    systemTenant: isSystemUser ? routeFactory.systemTenant.routePath : [],
    accountSettings: !isSystemUser ? routeFactory.accountDetail.routePath : [],
    customerCrmManagement: routeFactory.customerCrmManagement.routePath,
    customerAccounts: isSystemUser
      ? [
          routeFactory.adminAccounts.routePath,
          routeFactory.accountDetail.routePath,
          routeFactory.adminDraftAccounts.routePath,
        ]
      : [],
    adminTraits: routeFactory.adminTraits.routePath,
    rolodexFirms: [
      routeFactory.rolodexFirms.routePath,
      routeFactory.rolodexCrmIntegrations.routePath,
    ],
    privacyPolicy: routeFactory.privacyPolicy.routePath,
  });

  const navDefinitions: AppBarNavigationDefinition[] = [
    {
      name: routeNames.profile,
      href: routeFactory.profile.getUrlPath(),
      label: 'My Profile',
    },
  ];

  if (canReadAccount && isSystemUser) {
    navDefinitions.push({
      name: routeNames.systemTenant,
      href: routeFactory.systemTenant.getUrlPath(),
      label: 'System Tenant',
    });
  }

  if (canReadAccount && !isSystemUser) {
    navDefinitions.push({
      name: routeNames.accountSettings,
      href: routeFactory.accountDetail.getUrlPath({
        accountSubdomain: selfSubdomain,
      }),
      label: 'Account Settings',
    });
  }

  if (customerCrmManagement && canManageCRM) {
    navDefinitions.push({
      name: routeNames.customerCrmManagement,
      href: routeFactory.customerCrmManagement.getUrlPath(),
      label: 'CRM Management',
    });
  }

  if (canReadGlobalAccount) {
    navDefinitions.push({
      name: routeNames.customerAccounts,
      href: routeFactory.adminAccounts.getUrlPath(),
      label: 'Customer Accounts',
    });
  }

  if (canReadGlobalAccount) {
    navDefinitions.push({
      name: routeNames.adminTraits,
      href: routeFactory.adminTraits.getUrlPath(),
      label: 'Traits',
    });
  }

  if (canReadFirm) {
    navDefinitions.push({
      name: routeNames.rolodexFirms,
      href: routeFactory.rolodexFirms.getUrlPath(),
      label: 'Rolodex',
    });
  }

  if (canWriteOffPlatformOffering) {
    navDefinitions.push({
      name: 'datalabAdminV3',
      href: datalabAdminRootPath,
      label: 'Datalab Admin',
      target: '_blank',
    });
  }

  return {
    selectedDefinitionName: matchedRouteName,
    navDefinitions,
  };
}
