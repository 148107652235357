import { useMemo, useState } from 'react';

import { Role } from '../../../../../../../types/domain/role/role';

export type UseRolesFilterProps = Readonly<{
  roles: readonly Role[];
}>;

export type UseRolesFilterResult = Readonly<{
  filterText: Readonly<string>;
  setFilterText: (filterText: string) => void;
  filteredRoles: readonly Role[];
}>;

export function useRolesFilter({ roles }: UseRolesFilterProps): UseRolesFilterResult {
  const [filterText, setFilterText] = useState('');

  const filteredRoles = useMemo(() => {
    return roles.filter(role => {
      return role.name.toLowerCase().includes(filterText.toLowerCase());
    });
  }, [roles, filterText]);

  return {
    filterText,
    setFilterText,
    filteredRoles,
  };
}
