import { SubNavigation } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import routeFactory from '../../common/util/routeFactory';
import RolodexCrmBreadcrumb from './RolodexCrmBreadcrumb';
import RolodexFirmBreadcrumb from './RolodexFirmBreadcrumb';

export const StyledSubNavigation = styled(SubNavigation)`
  align-items: flex-start;
  display: flex;
  padding-top: 8px;
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};
`;

const RolodexSubNavigation = () => (
  <StyledSubNavigation>
    <SubNavigation.Item>
      <SubNavigation.Link exact to={routeFactory.rolodexFirms.getUrlPath()}>
        Organizations
      </SubNavigation.Link>
      <RolodexFirmBreadcrumb />
    </SubNavigation.Item>
    <SubNavigation.Item>
      <SubNavigation.Link exact to={routeFactory.rolodexCrmIntegrations.getUrlPath()}>
        CRM Integrations
      </SubNavigation.Link>
      <RolodexCrmBreadcrumb />
    </SubNavigation.Item>
  </StyledSubNavigation>
);

export default RolodexSubNavigation;
