import { apiTypes, timeUtil } from '@cmg/common';
import { DotStatus, Highlights, HighlightsTextItem } from '@cmg/design-system';
import { FC, Fragment, useMemo } from 'react';

import { ApiKey } from '../../../../../../types/domain/api-keys/api-key';
import {
  ApiKeyStatus,
  ApiKeyStatusLabels,
} from '../../../../../../types/domain/api-keys/constants';
import { isExpired } from '../../../../../account-detail/api-keys/shared/helpers';
import { ApiKeyDetailHighlightsActions } from './ApiKeyDetailHighlightsActions';

export type Props = Readonly<{
  readonly apiKey: ApiKey;
  readonly isLoading: boolean;
  readonly rengenerateApiKeyError: apiTypes.GenericServerError | null;
  readonly onRevokeApiKey: () => void;
  readonly onRegenerateApiKey: () => void;
  readonly onResetRegenerateApiKeyErrors: () => void;
}>;

export const ApiKeyDetailHighlights: FC<Props> = ({
  apiKey,
  isLoading,
  rengenerateApiKeyError,
  onRevokeApiKey,
  onRegenerateApiKey,
  onResetRegenerateApiKeyErrors,
}) => {
  const isApiKeyExpired = useMemo(
    () => !apiKey.expiresOn || isExpired(apiKey.expiresOn),
    [apiKey.expiresOn]
  );

  // Some legacy keys may have had their tokens deleted on the back-end as part of cleanup. For these keys we don't know
  // the original permissions to be able to regenerate the key.
  const canKeyBeRegenerated = useMemo(() => !!apiKey.permissions, [apiKey.permissions]);

  return (
    <Highlights
      title={`API Key (${apiKey.name})`}
      titleItems={
        <DotStatus
          label={
            !isApiKeyExpired
              ? ApiKeyStatusLabels[ApiKeyStatus.ACTIVE]
              : ApiKeyStatusLabels[ApiKeyStatus.EXPIRED]
          }
          color={!isApiKeyExpired ? 'success' : 'error'}
        />
      }
      items={
        <Fragment>
          <HighlightsTextItem
            name="Created"
            text={
              (apiKey.generatedOn && timeUtil.formatAsDisplayDateTime(apiKey.generatedOn)) || ''
            }
          />
          <HighlightsTextItem
            name="Expires"
            text={(apiKey.expiresOn && timeUtil.formatAsDisplayDateTime(apiKey.expiresOn)) || ''}
          />
        </Fragment>
      }
      actions={
        <ApiKeyDetailHighlightsActions
          apiKeyName={apiKey.name}
          canKeyBeRegenerated={canKeyBeRegenerated}
          isLoading={isLoading}
          regenerateApiKeyError={rengenerateApiKeyError}
          onRevokeApiKey={onRevokeApiKey}
          onRegenerateApiKey={onRegenerateApiKey}
          onResetRegenerateApiKeyErrors={onResetRegenerateApiKeyErrors}
        />
      }
    />
  );
};
