import { Option } from '@cmg/common';

export enum FirmMatchIdentifierType {
  LEI = 'LEI',
  CIK = 'CIK',
  CRD = 'CRD',
}

export const firmMatchIdentifierTypeLabels = {
  [FirmMatchIdentifierType.LEI]: 'LEI',
  [FirmMatchIdentifierType.CIK]: 'CIK',
  [FirmMatchIdentifierType.CRD]: 'CRD',
};

export const firmMatchIdentifierTypeOptions = Object.keys(FirmMatchIdentifierType).map(
  identifier =>
    ({
      label: firmMatchIdentifierTypeLabels[identifier],
      value: identifier,
    } as Option<FirmMatchIdentifierType>)
);
