import { AsyncSelect, Option } from '@cmg/common';
import debounce from 'lodash/debounce';
import React from 'react';

import { UUID } from '../../../../types/common';
import { FactSetFirmLimited } from '../../../../types/domain/firm/FactSetFirmLimited';
import * as rolodexApiClient from '../../../api/rolodexApiClient';
import { GetFactSetFirmsResponse } from '../../../api/rolodexApiClient';
import {
  SOption,
  SOptionColumn,
  SOptionFactSetFirmDescription,
  SOptionFactSetFirmName,
  SOptionIndustryAndEntityTypes,
} from './FactSetFirmSelect.styles';

type Props = {
  selectedFirmId: UUID | null;
  disabled?: boolean;
  renderMenuListFooter?: () => React.ReactElement;
  onChange: (selectedFactSetFirmId: UUID | null) => void;
};

const fromFactSetFirmDetailsToOption = (firm: FactSetFirmLimited): Option => ({
  firm: firm,
  value: firm.factSetFirmId,
  label: firm.properName,
});

const handleLoadOptions = debounce((inputValue: string, callback: (options: Option[]) => void) => {
  const loadOptions = async () => {
    const paginationParams = {
      perPage: 20,
      page: 1,
    };

    const searchResponse: GetFactSetFirmsResponse = await rolodexApiClient.getFactSetFirms({
      searchText: inputValue,
      ...paginationParams,
    });

    if (searchResponse.ok) {
      const factSetFirms = searchResponse.data.data.map(fromFactSetFirmDetailsToOption);
      callback(factSetFirms);
    } else {
      callback([]);
    }
  };

  void loadOptions();
}, 350);

/**
 * An Async Select used for querying and selecting firms from the FactSet database.
 *
 * @selectedFirmId - the selected/default value for the input
 * @disabled - whether or not the input field is disabled
 * @renderMenuListFooter - render prop that allows additional UI to be displayed at the bottom of the select/menu list.
 * @onChange - callback function invoked when the input value changes (or when a firm is selected)
 */
const FactSetFirmSelect: React.FC<Props> = ({
  selectedFirmId,
  disabled,
  renderMenuListFooter,
  onChange,
}) => {
  return (
    <AsyncSelect
      isSearchable
      disabled={disabled}
      styledConfig={{
        option: (args, controlProps, styles) => ({
          ...styles,
          padding: 0,
        }),
      }}
      placeholder="Search by Name..."
      loadOptions={handleLoadOptions}
      onChange={selectedFirmId => onChange(selectedFirmId)}
      noOptionsMessage={({ inputValue }: { inputValue: string }) => {
        if (inputValue) {
          return `No organizations matching ${inputValue} found.`;
        }

        return 'Please start typing an organization name see results';
      }}
      renderOption={props => {
        const {
          label,
          value,
          firm: { description, entityType, industryType },
        } = props;
        const isSelectedOption = selectedFirmId ? value === selectedFirmId : false;

        return (
          <SOption aria-label={`Fact Set Firm - ${label}`} isSelected={isSelectedOption}>
            <SOptionColumn>
              <SOptionFactSetFirmName isSelected={isSelectedOption}>{label}</SOptionFactSetFirmName>
              {description && (
                <SOptionFactSetFirmDescription isSelected={isSelectedOption}>
                  <span>{description}</span>
                </SOptionFactSetFirmDescription>
              )}
            </SOptionColumn>
            <SOptionColumn>
              <SOptionIndustryAndEntityTypes>
                {[entityType, industryType].filter(val => !!val).join(' ● ')}
              </SOptionIndustryAndEntityTypes>
            </SOptionColumn>
          </SOption>
        );
      }}
      renderMenuList={children => (
        <React.Fragment>
          <div>{children}</div>
          {renderMenuListFooter && renderMenuListFooter()}
        </React.Fragment>
      )}
      renderSingleSelectedOption={selectedOption => <span>{selectedOption.label}</span>}
    />
  );
};

export default FactSetFirmSelect;
