import { apiTypes, Banner, ServerErrors, TreeGrid } from '@cmg/common';
import React from 'react';

import { UUID } from '../../../../types/common';
import { FactSetFirmHierarchy } from '../../../../types/domain/firm/FactSetFirmHierarchy';
import LoadingIndicator from '../../indicators/loading-indicator/LoadingIndicator';
import { createColumns } from './FactSetFirmHierarchyTreeGridColumns';
import { createTreeGridRowsFromFactSetFirmHierarchy } from './utils/hierarchyUtils';

type Props = {
  /**
   * A FactSetFirmHierarchy entity, or null
   */
  hierarchy: FactSetFirmHierarchy | null;
  /**
   * Used to highlight the Firm Relation in the hierarchy
   * with the matching ID.
   */
  activeFirmId: UUID | string | null;
  /**
   * When true, displays a loading indicator
   */
  loading: boolean;
  /**
   * A server error, or null
   */
  error: apiTypes.GenericServerError | null;
};

/**
 * Displays a FactSetFirmHierarchy entity in the TreeGrid component.
 */
export const FactSetFirmHierarchyTreeGrid: React.FC<Props> = ({
  hierarchy,
  activeFirmId,
  loading,
  error,
}) => {
  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return (
      <Banner variant="error" showIcon={false}>
        <ServerErrors error={error} />
      </Banner>
    );
  }

  return (
    <TreeGrid
      striped={true}
      columns={createColumns({ activeFirmId })}
      rows={createTreeGridRowsFromFactSetFirmHierarchy(hierarchy || [])}
      getDataPath={data => data.hierarchy}
      groupDefaultExpanded={true}
      isCollapsible={false}
    />
  );
};

export default FactSetFirmHierarchyTreeGrid;
