import { TableGroupHeader, TableGroupHeaderCell } from '@cmg/design-system';
import { FC } from 'react';

export type Props = Readonly<{
  readonly groupHeader: string;
}>;

export const PermissionsTableGroupHeader: FC<Props> = ({ groupHeader }) => {
  return (
    <TableGroupHeader>
      <TableGroupHeaderCell colSpan={5}>{groupHeader}</TableGroupHeaderCell>
    </TableGroupHeader>
  );
};
