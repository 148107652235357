import { apiTypes, reduxUtil } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { History } from 'history';
import { AnyAction, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import identityApiClient, { ChangePasswordResponse } from '../../../common/api/identityApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import routeFactory from '../../../common/util/routeFactory';
import { ChangePassword } from '../../../types/domain/self/ChangePassword';

/**
 * ACTION TYPES
 */
enum ActionTypes {
  CHANGE_PASSWORD_REQUEST = 'identity/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = 'identity/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE = 'identity/CHANGE_PASSWORD_FAILURE',
  RESET_STATE = 'identity/CHANGE_RESET_PASSWORD_STATE',
}

/**
 * ACTION CREATORS
 */
export const resetState = () => ({
  type: ActionTypes.RESET_STATE,
});

export const changePassword = (params: ChangePassword, history: History) => ({
  type: ActionTypes.CHANGE_PASSWORD_REQUEST,
  payload: {
    params,
    history,
  },
});

export const changePasswordSuccess = () => ({
  type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailure = (params: { error: apiTypes.GenericServerError }) => ({
  type: ActionTypes.CHANGE_PASSWORD_FAILURE,
  payload: {
    error: params.error,
  },
});

type Actions = {
  [ActionTypes.RESET_STATE]: ReturnType<typeof resetState>;
  [ActionTypes.CHANGE_PASSWORD_REQUEST]: ReturnType<typeof changePassword>;
  [ActionTypes.CHANGE_PASSWORD_SUCCESS]: ReturnType<typeof changePasswordSuccess>;
  [ActionTypes.CHANGE_PASSWORD_FAILURE]: ReturnType<typeof changePasswordFailure>;
};

/**
 * REDUCERS
 */
const { createReducer } = reduxUtil;

export type ReducerState = {
  error: apiTypes.GenericServerError | null;
  submitting: boolean;
};

export const initialState = {
  error: null,
  submitting: false,
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.CHANGE_PASSWORD_REQUEST]: () => null,
  [ActionTypes.CHANGE_PASSWORD_SUCCESS]: () => null,
  [ActionTypes.CHANGE_PASSWORD_FAILURE]: (curState, { payload }) => payload.error,
});

const submittingReducer = createReducer<ReducerState['submitting'], Actions>(
  initialState.submitting,
  {
    [ActionTypes.CHANGE_PASSWORD_REQUEST]: () => true,
    [ActionTypes.CHANGE_PASSWORD_SUCCESS]: () => false,
    [ActionTypes.CHANGE_PASSWORD_FAILURE]: () => false,
  }
);

// This reducer acts on the entire state of this duck. Has access to duck state and must return duck state.
const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_STATE]: () => ({ ...initialState }),
});

const combinedReducers = combineReducers<ReducerState>({
  error: errorReducer,
  submitting: submittingReducer,
});

export default createDucksReducer(combinedReducers);

// Combines our individual slice reducers and the cross slice reducer.
export function createDucksReducer(combinedReducers) {
  return (state: ReducerState = initialState, action: AnyAction) =>
    crossSliceReducer(combinedReducers(state, action), action);
}

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.changePassword;
export const selectError = (state: RootState) => selectState(state).error;
export const selectSubmitting = (state: RootState) => selectState(state).submitting;

/**
 * SAGAS
 */
export function* changePasswordSubmitSaga({
  payload,
}: Actions[ActionTypes.CHANGE_PASSWORD_REQUEST]): SagaIterator {
  const response: ChangePasswordResponse = yield call(
    identityApiClient.changePassword,
    payload.params
  );
  if (response.ok) {
    yield put(changePasswordSuccess());
    payload.history.push(routeFactory.profile.getUrlPath());
    SnackbarManager.success('Password successfully changed!');
  } else {
    yield put(changePasswordFailure({ error: response.data.error }));
  }
}

export function* changePasswordSaga() {
  yield takeLatest<Actions[ActionTypes.CHANGE_PASSWORD_REQUEST]>(
    ActionTypes.CHANGE_PASSWORD_REQUEST,
    changePasswordSubmitSaga
  );
}
