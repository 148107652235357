import { DangerButton, Icon, SecondaryButton, SuccessButton } from '@cmg/common';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

const SNameStyles = css`
  white-space: pre-wrap;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  flex: 1;
`;

export const SName = styled.div`
  ${SNameStyles}
`;

export const StyledLink = styled(Link)`
  ${SNameStyles}
`;

export const SNameWrapper = styled.div`
  display: flex;
  align-items: normal;
  min-width: 180px;
  max-width: 220px;
  justify-content: space-between;
  max-width: 180px;
  position: relative;

  ${StyledLink} {
    width: 50%;
  }
`;

export const SInvestorRole = styled.div`
  width: 40px;
  margin-left: auto;
`;

export const SMatchScoreWrapper = styled.div`
  min-width: 80px;
  height: 25px;
  display: flex;
  align-items: center;
`;

export const SMatchScoreNumber = styled.div`
  margin-left: 8px;
`;

export const SOtherNamesWrapper = styled.div`
  ul {
    li {
      flex-wrap: nowrap;
    }
  }
`;

export const SWebDomainsWrapper = styled.div`
  flex-wrap: nowrap;
`;

export const StyledStatusIcon = styled(Icon)`
  position: absolute;
  left: -18px;
  color: ${({ theme }) => theme.color.red2};
`;

export const StyledIcon = styled(Icon)`
  margin-left: 5px;
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
`;

export const StyledDangerButton = styled(DangerButton)`
  width: 100px;
`;

export const StyledSuccessButton = styled(SuccessButton)`
  width: 100px;
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  width: 100px;
`;
