import { Button, TableCell, TableGroupHeaderCell } from '@cmg/design-system';
import React, { useCallback } from 'react';

import { useToggleRowCells } from '../../../../account-detail/api-keys/api-keys-create/components/api-key-permissions-table/hooks/useToggleRowCells';
import {
  PermissionDetail,
  PermissionLevel,
  UndefinedPermissionDetail,
} from '../../../../shared/auth/constants';

export type Props = Readonly<{
  readonly groupName: string;
  readonly availablePermissions: (PermissionDetail | UndefinedPermissionDetail)[];
  readonly isInGroupHeader: boolean;
  readonly onAdd?: (permissions: string[]) => void;
  readonly onRemove?: (permissions: string[]) => void;
}>;

export const ToggleRowCells: React.FC<Props> = ({
  availablePermissions,
  groupName,
  isInGroupHeader,
  onRemove,
  onAdd,
}) => {
  const { selectByAccessLevel } = useToggleRowCells({
    availablePermissions,
    groupName,
    onRemove,
    onAdd,
  });

  const Cell: React.FC = useCallback(
    ({ children }) => {
      if (isInGroupHeader) {
        return <TableGroupHeaderCell>{children}</TableGroupHeaderCell>;
      }

      return (
        <TableCell component="th" scope="col">
          {children}
        </TableCell>
      );
    },
    [isInGroupHeader]
  );

  return (
    <React.Fragment>
      <Cell>
        <Button variant="text" onClick={() => selectByAccessLevel(null)} sx={{ paddingY: 0 }}>
          Select All No Access
        </Button>
      </Cell>
      <Cell>
        <Button variant="text" onClick={() => selectByAccessLevel([PermissionLevel.READ])}>
          Select All Read Access
        </Button>
      </Cell>
      <Cell>
        <Button
          variant="text"
          onClick={() => selectByAccessLevel([PermissionLevel.FULL, PermissionLevel.READ])}
        >
          Select All Full Access
        </Button>
      </Cell>
    </React.Fragment>
  );
};
