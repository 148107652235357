import { accessTokenExpirationRequestInterceptor } from '@cmg/auth';
import { apiTypes, apiUtil, UnpackPromise, urlUtil } from '@cmg/common';
import axios, { AxiosResponse } from 'axios';

import { Account, AccountPermission, MyAccountUpdate } from '../../types/domain/account/account';
import { ApiKey, ApiKeyCreate } from '../../types/domain/api-keys/api-key';
import {
  AzureAdConfigurationUpdate,
  AzureAdOpenIdMetadata,
} from '../../types/domain/identity-provider/configurations/azureAd';
import {
  OpenIdConnectConfigurationUpdate,
  OpenIdConnectMetadata,
} from '../../types/domain/identity-provider/configurations/openIdConnect';
import {
  Saml2PConfigurationUpdate,
  Saml2PMetadata,
} from '../../types/domain/identity-provider/configurations/saml2p';
import { IdentityProviderType } from '../../types/domain/identity-provider/constants';
import {
  IdentityProvider,
  IdentityProviderAzure,
  IdentityProviderOpenIdConnect,
  IdentityProviderSaml,
} from '../../types/domain/identity-provider/identityProvider';
import {
  PasswordPolicy,
  PasswordPolicyUpdate,
} from '../../types/domain/password-policy/passwordPolicy';
import { Role } from '../../types/domain/role/role';
import { UserStatus } from '../../types/domain/user/constants';
import { User, UserCreate, UserUpdate } from '../../types/domain/user/user';
import UserBasic from '../../types/domain/user/UserBasic';
import { accessTokenRequestInterceptor, responseErrorInterceptor } from './interceptors';
import { AccountFilters } from './systemManagementApiClient';
import { getExcelDownload } from './util/axiosDownloadUtil';

/**
 * The management client
 **/
const client = axios.create({
  baseURL: '/api/v1',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

client.interceptors.request.use(accessTokenExpirationRequestInterceptor);
client.interceptors.request.use(accessTokenRequestInterceptor);
client.interceptors.response.use(undefined, responseErrorInterceptor);

/**
 * API Calls
 */

/**
 * Retrieve my customer account
 */
export const getMyAccount = () => {
  return client
    .get<Account>('/myAccount')
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetMyAccountResponse = UnpackPromise<ReturnType<typeof getMyAccount>>;

/**
 * Retrieve my customer account users
 */
export type GetMyAccountUsersParams = apiTypes.ListParams & AccountFilters;
export const getMyAccountUsers = (params: GetMyAccountUsersParams) => {
  const querystring = urlUtil.queryStringify({
    includeTotals: true,
    orderField: 'lastName',
    orderDirection: apiTypes.SortDirection.ASC,
    ...params,
  });
  return client
    .get<{
      data: UserBasic[];
      pagination: apiTypes.Pagination;
    }>(`/users${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetMyAccountUsersResponse = UnpackPromise<ReturnType<typeof getMyAccountUsers>>;

export const downloadMyAccountUsers = () => {
  return getExcelDownload({
    client,
    url: `/users/export`,
  });
};
export type DownloadMyAccountUsersResponse = UnpackPromise<
  ReturnType<typeof downloadMyAccountUsers>
>;

/**
 * Retrieve a single account user from customer account
 */
export const getMyAccountUser = (userId: string) => {
  return client
    .get<User>(`/users/${userId}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetMyAccountUserResponse = UnpackPromise<ReturnType<typeof getMyAccountUser>>;

/**
 * Retrieve a list of account roles for my customer account
 */
export type AccountRolesFilters = {
  searchText?: string;
};
export type GetMyAccountRolesParams = apiTypes.ListParams & AccountRolesFilters;
export const getMyAccountRoles = (params: GetMyAccountRolesParams) => {
  const querystring = urlUtil.queryStringify({
    includeTotals: true,
    orderField: 'name',
    orderDirection: apiTypes.SortDirection.ASC,
    perPage: 100,
    ...params,
  });
  return client
    .get<{
      data: Role[];
      pagination: apiTypes.Pagination;
    }>(`/roles${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetMyAccountRolesResponse = UnpackPromise<ReturnType<typeof getMyAccountRoles>>;

/**
 * Retrieve an account role for my customer account
 */
export const getMyAccountRole = (roleId: string) => {
  return client
    .get<Role>(`/roles/${roleId}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetMyAccountRoleResponse = UnpackPromise<ReturnType<typeof getMyAccountRole>>;

/**
 * Retrieve a list of users with the provided role
 */
export type GetMyAccountRoleUsersParams = apiTypes.ListParams;
export const getMyAccountRoleUsers = (roleId: string, params: GetMyAccountRoleUsersParams) => {
  const querystring = urlUtil.queryStringify({
    includeTotals: true,
    orderField: 'lastName',
    orderDirection: apiTypes.SortDirection.ASC,
    ...params,
  });
  return client
    .get<{
      data: UserBasic[];
      pagination: apiTypes.Pagination;
    }>(`/roles/${roleId}/users${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetMyAccountRoleUsersResponse = UnpackPromise<ReturnType<typeof getMyAccountRoleUsers>>;

/**
 * Retrieve a list of API Keys for my customer account
 */
export type GetMyApiKeysParams = apiTypes.ListParams;
export const getMyApiKeys = (listParams: apiTypes.ListParams) => {
  const querystring = urlUtil.queryStringify({
    includeTotals: true,
    ...listParams,
  });
  return client
    .get<{
      data: ApiKey[];
      pagination: apiTypes.Pagination;
    }>(`/myAccount/apiKeys${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetMyApiKeysResponse = UnpackPromise<ReturnType<typeof getMyApiKeys>>;

/**
 * Retrieve a single API key for my customer account
 */
export const getMyApiKey = (apiKeyId: string) => {
  return client
    .get<ApiKey>(`/myAccount/apiKeys/${apiKeyId}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetMyApiKeyResponse = UnpackPromise<ReturnType<typeof getMyApiKey>>;

/**
 * Retrieve a list of available permissions for my customer account
 */
export const getMyAccountPermissions = () => {
  return client
    .get<AccountPermission[]>(`/permissions`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetMyAccountPermissionsResponse = UnpackPromise<
  ReturnType<typeof getMyAccountPermissions>
>;

/**
 * Retrieve a specific identity provider for my customer account
 */
export const getMyAccountIdentityProvider = (providerType: IdentityProviderType) => {
  return client
    .get<IdentityProvider>(`/myAccount/identityProviders/${providerType}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetMyAccountIdentityProviderResponse = UnpackPromise<
  ReturnType<typeof getMyAccountIdentityProvider>
>;

/**
 * Update my customer account AzureAdOpenId identity provider configuration
 */
export const updateMyAccountAzureAdConfiguration = (configuration: AzureAdConfigurationUpdate) => {
  return client
    .put<AzureAdConfigurationUpdate, AxiosResponse<IdentityProviderAzure>>(
      '/myAccount/identityProviders/azureAdOpenId',
      configuration
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateMyAccountAzureAdConfigurationResponse = UnpackPromise<
  ReturnType<typeof updateMyAccountAzureAdConfiguration>
>;

/**
 * Update my customer account Saml2p identity provider configuration
 */
export const updateMyAccountSamlConfiguration = (configurations: Saml2PConfigurationUpdate) => {
  return client
    .put<Saml2PConfigurationUpdate, AxiosResponse<IdentityProviderSaml>>(
      '/myAccount/identityProviders/saml2p',
      configurations
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateMyAccountSamlConfigurationResponse = UnpackPromise<
  ReturnType<typeof updateMyAccountSamlConfiguration>
>;

/**
 * Update my customer account OpenID Connect identity provider configuration
 */
export const updateMyAccountOpenIdConnectConfiguration = (
  configurations: OpenIdConnectConfigurationUpdate
) => {
  return client
    .put<OpenIdConnectConfigurationUpdate, AxiosResponse<IdentityProviderOpenIdConnect>>(
      `/myAccount/identityProviders/openIdConnect`,
      configurations
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateMyAccountOpenIdConnectConfigurationResponse = UnpackPromise<
  ReturnType<typeof updateMyAccountOpenIdConnectConfiguration>
>;

/*
 * Retrieve my customer account AzureAdOpenId meta data
 * metadata is used by the account administrator to configure their azure ad open id identity provider.
 * metadata will always display when adding/updating the identity provider configuration
 */
export const getMyAccountAzureAdOpenIdMetadata = () => {
  return client
    .get<AzureAdOpenIdMetadata>('/myAccount/identityProviders/azureAdOpenId/metadata')
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetMyAccountAzureAdOpenIdMetadataResponse = UnpackPromise<
  ReturnType<typeof getMyAccountAzureAdOpenIdMetadata>
>;

/**
 * Retrieve my customer account Saml2p meta data.
 * metadata is used by the account administrator to configure their saml2p identity provider.
 * metadata will always display when adding/updating the identity provider configuration.
 */
export const getMyAccountSaml2pMetadata = () => {
  return client
    .get<Saml2PMetadata>('/myAccount/identityProviders/saml2p/metadata')
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetMyAccountSaml2pMetadataResponse = UnpackPromise<
  ReturnType<typeof getMyAccountSaml2pMetadata>
>;

/*
 * Retrieve my customer account openIdConnect meta data
 * metadata is used by the account administrator to configure their open id connect identity provider.
 * metadata will always display when adding/updating the identity provider configuration
 */
export const getMyAccountOpenIdConnectMetadata = () => {
  return client
    .get<OpenIdConnectMetadata>('/myAccount/identityProviders/openIdConnect/metadata')
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetMyAccountOpenIdConnectMetadataResponse = UnpackPromise<
  ReturnType<typeof getMyAccountOpenIdConnectMetadata>
>;

/**
 * Creates my customer account user
 * */
export const createMyAccountUser = (user: UserCreate, sendInvite: boolean) => {
  return client
    .post<UserCreate, AxiosResponse<User>>(`/users?sendInvite=${sendInvite}`, user)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type CreateMyAccountUserResponse = UnpackPromise<ReturnType<typeof createMyAccountUser>>;

/**
 * Update my customer account user
 */
export const updateMyAccountUser = (userId: string, user: UserUpdate = {}) => {
  return client
    .put<UserUpdate, AxiosResponse<User>>(`/users/${userId}`, user)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateMyAccountUserResponse = UnpackPromise<ReturnType<typeof updateMyAccountUser>>;

/**
 * Update my customer account user status
 */
export const updateMyAccountUserStatus = (userId: string, status: UserStatus) => {
  return client
    .put<{ status: UserStatus }, AxiosResponse<void>>(`/users/${userId}/status`, { status })
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateMyAccountUserStatusResponse = UnpackPromise<
  ReturnType<typeof updateMyAccountUserStatus>
>;

/**
 * Resend invite email to my customer account user
 */
export const resendMyAccountUserInviteEmail = (userId: string) => {
  return client
    .post<void>(`/users/${userId}/invites`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type ResendMyAccountUserInviteEmailResponse = UnpackPromise<
  ReturnType<typeof resendMyAccountUserInviteEmail>
>;

/**
 * Unlock my customer account user
 */
export const unlockMyAccountUser = (userId: string) => {
  return client
    .post<void>(`/users/${userId}/unlock`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UnlockMyAccountUserResponse = UnpackPromise<ReturnType<typeof unlockMyAccountUser>>;

/**
 * Reset my customer user password
 */
export const resetMyAccountUserPasswordRequest = (userId: string) => {
  return client
    .post<void>(`/users/${userId}/resetPasswordRequests`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type ResetMyAccountUserPasswordResponse = UnpackPromise<
  ReturnType<typeof resetMyAccountUserPasswordRequest>
>;

/**
 * Change my customer user email
 */
export const changeMyAccountUserEmailRequest = (userId: string, email: string) => {
  return client
    .put<{ email: string }, AxiosResponse<void>>(`/users/${userId}/email`, { email })
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type ChangeMyAccountUserEmailResponse = UnpackPromise<
  ReturnType<typeof changeMyAccountUserEmailRequest>
>;

/**
 * Update my customer account
 */
export const updateMyAccount = (account: MyAccountUpdate) => {
  return client
    .put<MyAccountUpdate, AxiosResponse<Account>>('/myAccount', account)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateMyAccountResponse = UnpackPromise<ReturnType<typeof updateMyAccount>>;

/**
 * Update my customer Password Policy
 */
export const updateMyAccountPasswordPolicy = (payload: PasswordPolicyUpdate) => {
  return client
    .put<PasswordPolicyUpdate, AxiosResponse<PasswordPolicy>>('/myAccount/passwordPolicy', payload)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateMyAccountPasswordPolicyResponse = UnpackPromise<
  ReturnType<typeof updateMyAccountPasswordPolicy>
>;

/**
 * Regenerates my customer API Key
 */
export const regenerateMyApiKey = (apiKeyId: string) => {
  return client
    .put<ApiKey>(`/myAccount/apiKeys/${apiKeyId}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type PutRegenerateMyApiKeyResponse = UnpackPromise<ReturnType<typeof regenerateMyApiKey>>;

/**
 * Revokes my customer API Key
 */
export const revokeMyApiKey = (apiKeyId: string) => {
  return client
    .delete(`/myAccount/apiKeys/${apiKeyId}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type DeleteMyApiKeyResponse = UnpackPromise<ReturnType<typeof revokeMyApiKey>>;

/**
 * Creates my customer API Key
 */
export const createMyApiKey = (apiKey: ApiKeyCreate) => {
  return client
    .post<ApiKeyCreate, AxiosResponse<ApiKey>>('/myAccount/apiKeys', apiKey)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type CreateMyApiKeyResponse = UnpackPromise<ReturnType<typeof createMyApiKey>>;
