import styled from 'styled-components/macro';

export const SContainer = styled.div`
  text-align: center;
  width: 90%;
`;

export const SEmailLink = styled.a`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const SFooter = styled.div`
  padding: 16px 0;
  text-align: center;
`;
