import { apiTypes, ServerErrors } from '@cmg/common';
import { Alert, AlertTitle, Grid } from '@cmg/design-system';

export type ServerErrorAlertProps = {
  title: string;
  error: apiTypes.GenericServerError | null | string;
};

export const ServerErrorAlert: React.VFC<ServerErrorAlertProps> = ({ title, error }) => {
  if (!error) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Alert severity="error" aria-label={title}>
        <AlertTitle>{title}</AlertTitle>
        {typeof error == 'string' ? error : <ServerErrors error={error} />}
      </Alert>
    </Grid>
  );
};
