import { DropdownSelect, FlexContainer, Popover, Tag } from '@cmg/common';
import { ColDef } from 'ag-grid-community';
import React from 'react';
import { Link } from 'react-router-dom';

import Status from '../../../../common/components/indicators/status/Status';
import routeFactory from '../../../../common/util/routeFactory';
import { UUID } from '../../../../types/common';
import { firmRoleTypeLabels } from '../../../../types/domain/firm/constants';
import { FirmLimited } from '../../../../types/domain/firm/FirmLimited';
import { firmStatusLabels } from '../../../../types/domain/firm-status/constants';
import { FirmStatus } from '../../../../types/domain/firm-status/FirmStatus';
import { recordStatusLabels } from '../../../../types/domain/record-status/constants';
import { RecordStatus } from '../../../../types/domain/record-status/RecordStatus';
import { RolodexMetadata } from '../../../../types/domain/rolodex/Metadata';
import { OnPlatformIcon } from '../../shared/OnPlatformIcon';
import {
  SPopoverContentWrapper,
  StyledAdditionalActionsIconButton,
  StyledUltimateParentIcon,
} from './FirmListGridColumns.styles';

export const FirmNameCellRenderer: React.FC<{
  data: FirmLimited;
}> = ({ data: firm }) => (
  <FlexContainer direction="row" gap={4} alignItems="center">
    <OnPlatformIcon isOnPlatform={firm.isOnPlatform} />
    <Link
      to={routeFactory.rolodexFirmDetail.getUrlPath({
        firmId: firm.id,
      })}
    >
      {firm.displayName}
    </Link>
  </FlexContainer>
);

export const UltimateParentIndicatorCellRenderer: React.FC<{
  data: FirmLimited;
}> = ({ data: firm }) =>
  firm.id === firm.ultimateParentId ? (
    <Popover
      variant="TOOLTIP"
      trigger="hover"
      content={
        <SPopoverContentWrapper>{firm.displayName} is an Ultimate Parent.</SPopoverContentWrapper>
      }
    >
      <span>
        <StyledUltimateParentIcon name="circle" variant="solid" size="1x" />
      </span>
    </Popover>
  ) : null;

export const EntityTypeCellRenderer: React.FC<{
  data;
  metadata: RolodexMetadata;
}> = ({ data, metadata }) => {
  const entityType = metadata.entityTypes.find(type => type.value === data.value);

  return <span>{entityType ? entityType.label : data.value}</span>;
};

export const LinkFrequencyCellRenderer: React.FC<{
  data: FirmLimited;
}> = ({ data }) => {
  const { linkFrequency } = data.linkFrequencyData;

  return <span>{linkFrequency > 0 ? linkFrequency : null}</span>;
};

export const IndustryTypeCellRenderer: React.FC<{
  data;
  metadata: RolodexMetadata;
}> = ({ data, metadata }) => {
  const industryType = metadata.industryTypes.find(type => type.value === data.value);

  return <span>{industryType ? industryType.label : data.value}</span>;
};

export const StatusCellRenderer: React.FC<{
  data: FirmLimited;
}> = ({ data: firm }) => {
  const label = firmStatusLabels[firm.status];

  return (
    <Status
      isActive={firm.status === FirmStatus.ACTIVE}
      activeLabel={label}
      inactiveLabel={label}
      inactiveIsNeutral={true}
    />
  );
};

export const RecordStatusCellRenderer: React.FC<{
  data: FirmLimited;
}> = ({ data: firm }) => {
  const label = recordStatusLabels[firm.deletionStatus];

  return (
    <Status
      isActive={firm.deletionStatus === RecordStatus.EFFECTIVE}
      activeLabel={label}
      inactiveLabel={label}
      inactiveIsNeutral={true}
    />
  );
};

export const RolesCellRenderer: React.FC<{
  data: FirmLimited;
}> = ({ data: firm }) =>
  firm.roles && (
    <React.Fragment>{firm.roles.map(role => firmRoleTypeLabels[role]).join(', ')}</React.Fragment>
  );

export const ControlsCellRenderer: React.FC<{
  data: FirmLimited;
  showHierarchyModal: (firmId: UUID) => void;
}> = ({ data: firm, showHierarchyModal }) => (
  <DropdownSelect
    label={<StyledAdditionalActionsIconButton size="large" icon={{ name: 'ellipsis-v' }} />}
    items={[{ label: 'View Organization Hierarchy', value: null }]}
    multiple={false}
    searchable={false}
    onChange={() => {
      showHierarchyModal(firm.id);
    }}
    popoverVariant="LIGHT"
  />
);

export const FactSetConnectedCellRenderer: React.FC<{
  data: FirmLimited;
}> = ({ data: firm }) => (!firm.isFactSetConnected ? <Tag>Local</Tag> : null);

export const createColumns = ({
  showHierarchyModal,
  metadata,
}: {
  showHierarchyModal: (firmId: UUID) => void;
  metadata: RolodexMetadata;
}): ColDef[] => [
  {
    field: 'controls',
    headerName: '',
    sortable: false,
    cellRendererFramework: params => (
      <ControlsCellRenderer data={params.data} showHierarchyModal={showHierarchyModal} />
    ),
    suppressColumnsToolPanel: true,
    minWidth: 50,
    maxWidth: 50,
  },
  {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    cellRendererFramework: FirmNameCellRenderer,
    suppressColumnsToolPanel: true,
  },
  {
    field: 'ultimateParentId',
    headerName: '',
    width: 60,
    sortable: false,
    cellRendererFramework: UltimateParentIndicatorCellRenderer,
    suppressColumnsToolPanel: true,
  },
  {
    field: 'key',
    headerName: 'ID',
    sortable: false,
  },
  {
    field: 'linkFrequency',
    headerName: 'Link Frequency',
    cellRendererFramework: LinkFrequencyCellRenderer,
    sortable: false,
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: false,
    width: 250,
  },
  {
    field: 'entityType',
    headerName: 'Entity Type',
    sortable: false,
    cellRendererFramework: data => <EntityTypeCellRenderer data={data} metadata={metadata} />,
  },
  {
    field: 'industryType',
    headerName: 'Industry Type',
    sortable: false,
    cellRendererFramework: data => <IndustryTypeCellRenderer data={data} metadata={metadata} />,
  },
  {
    field: 'roles',
    headerName: 'Roles',
    sortable: false,
    cellRendererFramework: RolesCellRenderer,
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    cellRendererFramework: StatusCellRenderer,
  },
  {
    field: 'recordStatus',
    headerName: 'Entity Status',
    sortable: false,
    cellRendererFramework: RecordStatusCellRenderer,
  },
  {
    field: 'isFactSetConnected',
    headerName: '',
    sortable: false,
    cellRendererFramework: FactSetConnectedCellRenderer,
    minWidth: 70,
    maxWidth: 70,
  },
  {
    field: 'createdBySubdomain',
    headerName: 'Created by Subdomain',
    sortable: true,
    flex: 1,
    maxWidth: 220,
    cellStyle: {
      textAlign: 'center',
    },
  },
];
