import { useAuth } from '@cmg/auth';
import {
  AccountCircleOutlinedIcon,
  AppBarMenu,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
} from '@cmg/design-system';
import { VFC } from 'react';

import routeFactory from '../../../../util/routeFactory';

export const IdentityAppBarSecondarySection: VFC = () => {
  const { oidcUserFamilyName, oidcUserGivenName, oidcUserEmail, oidcUserId } = useAuth();
  if (!oidcUserId) {
    return null;
  }

  return (
    <AppBarMenu
      id="account-settings-menu"
      label="Account & Settings"
      icon={<AccountCircleOutlinedIcon />}
      MenuListProps={{
        subheader: <ListSubheader>Account</ListSubheader>,
      }}
      renderItems={({ closeMenu }) => [
        <MenuItem key="account-info" divider>
          <ListItemIcon>
            <AccountCircleOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            primary={`${oidcUserGivenName} ${oidcUserFamilyName}`}
            secondary={oidcUserEmail}
          />
        </MenuItem>,
        <ListItem key="log-out" disablePadding role="menuitem" onClick={closeMenu}>
          <ListItemButton href={routeFactory.oidcLogout.getUrlPath()}>
            <ListItemText primary="Log Out" />
          </ListItemButton>
        </ListItem>,
      ]}
    />
  );
};
