import { apiTypes, Banner, Icon, PrimaryButton, ServerErrors, TextInputField } from '@cmg/common';
import { Box, Checkbox, Link, Tooltip } from '@cmg/design-system';
import {
  passwordExpiredFormSelector,
  passwordExpiredPasswordSelector,
  passwordExpiredSubmitSelector,
} from '@cmg/e2e-selectors';
import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { MFALoginUserPassFailureBody } from '../../../../../../common/api/identityApiClient';
import {
  SButtonsSection,
  SFormSection,
  SHeading,
  SInnerSwitchWrapper,
  SSwitchWrapper,
} from './MFAForm.styles';

export const MFASchema = yup.object().shape({
  securityCode: yup.string().required('This field is required.'),
});

type MFAFormValues = {
  securityCode: string;
};

type handlerValue = {
  rememberDevice: boolean;
};

type MFAValues = MFAFormValues & handlerValue;

type OwnProps = {
  checked: boolean;
  onChange: () => void;
  onSubmitMFAForm: (values: MFAValues) => void;
  mfaError: MFALoginUserPassFailureBody | apiTypes.GenericServerError | null;
  submitting: boolean;
  resendCode: () => void;
};
type State = { securityCode: string };
type Props = OwnProps & FormikProps<MFAFormValues>;

/**
 * Render expired password form for password and confirmPassword
 */
export class MFAFormComponent extends React.Component<Props, State> {
  render() {
    const { checked, onChange, mfaError, submitting, resendCode } = this.props;
    return (
      <Form data-test-id={passwordExpiredFormSelector.testId}>
        <SHeading>Two Factor Authentication</SHeading>
        <p>
          A security code was sent to your registered email. Please input code below to continue.
          <Link
            onClick={resendCode}
            underline="none"
            sx={{ ml: '3px', pb: '18px', color: '#48B2FA', display: 'inline-block' }}
          >
            Didn't Receive Code?
          </Link>
        </p>
        {mfaError && (
          <Banner variant="error" showIcon={false}>
            {/* note: the login response varies based on the code that is returned from the server,
              details and target are not returned so we add them here as a one-off */}
            <ServerErrors
              error={{
                target: '',
                details: [],
                ...mfaError,
              }}
            />
          </Banner>
        )}
        <SFormSection>
          <TextInputField
            name="securityCode"
            prefix={<Icon name="key" />}
            placeholder="Security Code"
            data-test-id={passwordExpiredPasswordSelector.testId}
            size="large"
            fullWidth
          />
        </SFormSection>
        <SSwitchWrapper>
          <div>
            <SInnerSwitchWrapper>
              <Checkbox
                checked={checked}
                onChange={onChange}
                sx={{
                  color: 'white',
                  '&.Mui-checked': {
                    color: '#48b2fa',
                  },
                }}
              />
              <div>Remember this browser for 30 days</div>
            </SInnerSwitchWrapper>
          </div>
          <Box sx={{ cursor: 'pointer' }}>
            <Tooltip
              title="'Remember this browser' means you will not be asked for a verification code when logging in–as long as you have logged in within 30 days."
              placement="top-start"
              variant="info"
            >
              <span>ⓘ</span>
            </Tooltip>
          </Box>
        </SSwitchWrapper>
        <SButtonsSection>
          <PrimaryButton
            variant="high-contrast"
            type="submit"
            fullWidth
            size="large"
            loading={submitting}
            data-test-id={passwordExpiredSubmitSelector.testId}
          >
            Verify
          </PrimaryButton>
        </SButtonsSection>
      </Form>
    );
  }
}

/**
 * Form that captures username and password for a database style oidc login.
 */
export default withFormik<OwnProps, MFAFormValues>({
  enableReinitialize: false,
  isInitialValid: false,
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: MFASchema,
  mapPropsToValues: () => ({
    securityCode: '',
  }),
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmitMFAForm({ ...values, rememberDevice: formikBag.props.checked });
  },
})(MFAFormComponent);
