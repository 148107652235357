/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TraitList_AccountTraitsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TraitList_AccountTraitsQuery = {
  readonly __typename?: 'Query';
  readonly accountTraits: ReadonlyArray<{
    readonly __typename?: 'AccountTrait';
    readonly code: string;
    readonly name: string;
    readonly description: string;
    readonly permissions: ReadonlyArray<string>;
    readonly accountCount: number;
  }>;
};

export const TraitList_AccountTraitsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TraitList_AccountTraits' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountTraits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useTraitList_AccountTraitsQuery__
 *
 * To run a query within a React component, call `useTraitList_AccountTraitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraitList_AccountTraitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraitList_AccountTraitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTraitList_AccountTraitsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TraitList_AccountTraitsQuery,
    TraitList_AccountTraitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TraitList_AccountTraitsQuery, TraitList_AccountTraitsQueryVariables>(
    TraitList_AccountTraitsDocument,
    options
  );
}
export function useTraitList_AccountTraitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TraitList_AccountTraitsQuery,
    TraitList_AccountTraitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TraitList_AccountTraitsQuery, TraitList_AccountTraitsQueryVariables>(
    TraitList_AccountTraitsDocument,
    options
  );
}
export function useTraitList_AccountTraitsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TraitList_AccountTraitsQuery,
        TraitList_AccountTraitsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TraitList_AccountTraitsQuery,
    TraitList_AccountTraitsQueryVariables
  >(TraitList_AccountTraitsDocument, options);
}
export type TraitList_AccountTraitsQueryHookResult = ReturnType<
  typeof useTraitList_AccountTraitsQuery
>;
export type TraitList_AccountTraitsLazyQueryHookResult = ReturnType<
  typeof useTraitList_AccountTraitsLazyQuery
>;
export type TraitList_AccountTraitsSuspenseQueryHookResult = ReturnType<
  typeof useTraitList_AccountTraitsSuspenseQuery
>;
export type TraitList_AccountTraitsQueryResult = Apollo.QueryResult<
  TraitList_AccountTraitsQuery,
  TraitList_AccountTraitsQueryVariables
>;
