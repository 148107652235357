import { apiTypes, reduxUtil } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import identityApiClient, {
  FetchLoginInfoResponse,
  LoginUserPassFailureBody,
  LoginUserPassResponse,
  LoginUserPassResponseCode,
  MFALoginUserPassFailureBody,
  MFALoginUserPassResponse,
  ResendCodeResponse,
  ResetPasswordResponse,
} from '../../../common/api/identityApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { LoginInfo } from '../../../types/domain/login-info/loginInfo';
import { PasswordReset } from '../../../types/domain/self/PasswordReset';

/**
 * ACTION TYPES
 */
enum ActionTypes {
  LOGIN_USER_PASS_REQUEST = 'identity/LOGIN_USER_PASS_REQUEST',
  LOGIN_USER_PASS_FAILURE = 'identity/LOGIN_USER_PASS_FAILURE',
  LOGIN_USER_PASS_EXPIRED = 'identity/LOGIN_USER_PASS_EXPIRED',
  EXPIRED_PASSWORD_REQUEST = 'identity/EXPIRED_PASSWORD_REQUEST',
  EXPIRED_PASSWORD_SUCCESS = 'identity/EXPIRED_PASSWORD_SUCCESS',
  EXPIRED_PASSWORD_FAILURE = 'identity/EXPIRED_PASSWORD_FAILURE',
  FETCH_LOGIN_INFO_REQUEST = 'identity/FETCH_LOGIN_INFO_REQUEST',
  FETCH_LOGIN_INFO_SUCCESS = 'identity/FETCH_LOGIN_INFO_SUCCESS',
  FETCH_LOGIN_INFO_FAILURE = 'identity/FETCH_LOGIN_INFO_FAILURE',
  MULTI_FACTOR_AUTHENTICATION = 'identity/MULTI_FACTOR_AUTHENTICATION',
  MFA_USER_PASS_REQUEST = 'identity/MFA_USER_PASS_REQUEST',
  MFA_USER_PASS_FAILURE = 'identity/MFA_USER_PASS_FAILURE',
  MFA_RESEND_CODE_REQUEST = 'identity/MFA_RESEND_CODE_REQUEST',
}

/**
 * ACTION CREATORS
 */
export const loginUserPass = (params: {
  username: string;
  password: string;
  returnUrl: string;
}) => ({
  type: ActionTypes.LOGIN_USER_PASS_REQUEST,
  payload: {
    username: params.username,
    password: params.password,
    returnUrl: params.returnUrl,
  },
});

export const loginUserPassFailed = (params: {
  error: apiTypes.GenericServerError | LoginUserPassFailureBody;
}) => ({
  type: ActionTypes.LOGIN_USER_PASS_FAILURE,
  payload: {
    error: params.error,
  },
});

export const resetPassword = (params: PasswordReset) => ({
  type: ActionTypes.EXPIRED_PASSWORD_REQUEST,
  payload: params,
});

export const resetPasswordSuccess = () => ({
  type: ActionTypes.EXPIRED_PASSWORD_SUCCESS,
});

export const resendCode = () => ({
  type: ActionTypes.MFA_RESEND_CODE_REQUEST,
});

export const resetPasswordFailure = (error: apiTypes.GenericServerError) => ({
  type: ActionTypes.EXPIRED_PASSWORD_FAILURE,
  payload: {
    error,
  },
});

export const fetchLoginInfo = (params: { returnUrl: string }) => ({
  type: ActionTypes.FETCH_LOGIN_INFO_REQUEST,
  payload: {
    returnUrl: params.returnUrl,
  },
});

export const fetchLoginInfoSuccess = (params: { loginInfo: LoginInfo }) => ({
  type: ActionTypes.FETCH_LOGIN_INFO_SUCCESS,
  payload: {
    loginInfo: params.loginInfo,
  },
});

export const fetchLoginInfoFailure = (params: { error: apiTypes.GenericServerError }) => ({
  type: ActionTypes.FETCH_LOGIN_INFO_FAILURE,
  payload: {
    error: params.error,
  },
});

export const multiFactorAuthentication = (params: { mfaEnabled: string }) => ({
  type: ActionTypes.MULTI_FACTOR_AUTHENTICATION,
  payload: {
    mfaEnabled: params.mfaEnabled === LoginUserPassResponseCode.REQUIRE_TWO_FACTOR,
  },
});

export const mfaLoginUserPass = (params: {
  code: string;
  rememberDevice: boolean;
  returnUrl: string;
}) => ({
  type: ActionTypes.MFA_USER_PASS_REQUEST,
  payload: {
    code: params.code,
    rememberDevice: params.rememberDevice,
    returnUrl: params.returnUrl,
  },
});

export const mfaLoginUserFailure = (params: {
  error: apiTypes.GenericServerError | MFALoginUserPassFailureBody;
}) => ({
  type: ActionTypes.MFA_USER_PASS_FAILURE,
  payload: {
    error: params.error,
  },
});

type Actions = {
  [ActionTypes.LOGIN_USER_PASS_REQUEST]: ReturnType<typeof loginUserPass>;
  [ActionTypes.LOGIN_USER_PASS_FAILURE]: ReturnType<typeof loginUserPassFailed>;
  [ActionTypes.EXPIRED_PASSWORD_REQUEST]: ReturnType<typeof resetPassword>;
  [ActionTypes.EXPIRED_PASSWORD_SUCCESS]: ReturnType<typeof resetPasswordSuccess>;
  [ActionTypes.EXPIRED_PASSWORD_FAILURE]: ReturnType<typeof resetPasswordFailure>;
  [ActionTypes.FETCH_LOGIN_INFO_REQUEST]: ReturnType<typeof fetchLoginInfo>;
  [ActionTypes.FETCH_LOGIN_INFO_SUCCESS]: ReturnType<typeof fetchLoginInfoSuccess>;
  [ActionTypes.FETCH_LOGIN_INFO_FAILURE]: ReturnType<typeof fetchLoginInfoFailure>;
  [ActionTypes.MULTI_FACTOR_AUTHENTICATION]: ReturnType<typeof multiFactorAuthentication>;
  [ActionTypes.MFA_USER_PASS_REQUEST]: ReturnType<typeof mfaLoginUserPass>;
  [ActionTypes.MFA_USER_PASS_FAILURE]: ReturnType<typeof mfaLoginUserFailure>;
  [ActionTypes.MFA_RESEND_CODE_REQUEST]: ReturnType<typeof resendCode>;
};

/**
 * REDUCERS
 */
const { createReducer } = reduxUtil;

type ResetPasswordState = {
  error: apiTypes.GenericServerError | null;
  submitting: boolean;
  success: boolean;
};

type LoginInfoState = {
  data: LoginInfo | null;
  error: apiTypes.GenericServerError | null;
};

type MFALoginInfoState = {
  mfaEnabled: boolean;
  error: apiTypes.GenericServerError | MFALoginUserPassFailureBody | null;
  mfaSubmitting: boolean;
};

export type ReducerState = {
  error: LoginUserPassFailureBody | apiTypes.GenericServerError | null;
  submitting: boolean;
  resetPassword: ResetPasswordState;
  loginInfo: LoginInfoState;
  mfaLoginInfo: MFALoginInfoState;
};

export const initialState = {
  error: null,
  submitting: false,
  resetPassword: {
    error: null,
    success: false,
    submitting: false,
  },
  loginInfo: {
    data: null,
    error: null,
  },
  mfaLoginInfo: {
    mfaEnabled: false,
    error: null,
    mfaSubmitting: false,
  },
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.LOGIN_USER_PASS_REQUEST]: () => null,
  [ActionTypes.LOGIN_USER_PASS_FAILURE]: (errorState, { payload }) => payload.error,
});

const submittingReducer = createReducer<ReducerState['submitting'], Actions>(
  initialState.submitting,
  {
    [ActionTypes.LOGIN_USER_PASS_REQUEST]: () => true,
    [ActionTypes.LOGIN_USER_PASS_FAILURE]: () => false,
  }
);

const resetPasswordErrorReducer = createReducer<ReducerState['resetPassword']['error'], Actions>(
  initialState.resetPassword.error,
  {
    [ActionTypes.EXPIRED_PASSWORD_REQUEST]: () => null,
    [ActionTypes.EXPIRED_PASSWORD_SUCCESS]: () => null,
    [ActionTypes.EXPIRED_PASSWORD_FAILURE]: (curState, { payload }) => payload.error,
  }
);

const resetPasswordSubmittingReducer = createReducer<
  ReducerState['resetPassword']['submitting'],
  Actions
>(initialState.resetPassword.submitting, {
  [ActionTypes.EXPIRED_PASSWORD_REQUEST]: () => true,
  [ActionTypes.EXPIRED_PASSWORD_SUCCESS]: () => false,
  [ActionTypes.EXPIRED_PASSWORD_FAILURE]: () => false,
});

const resetPasswordSuccessReducer = createReducer<
  ReducerState['resetPassword']['success'],
  Actions
>(initialState.resetPassword.success, {
  [ActionTypes.EXPIRED_PASSWORD_REQUEST]: () => false,
  [ActionTypes.EXPIRED_PASSWORD_SUCCESS]: () => true,
  [ActionTypes.EXPIRED_PASSWORD_FAILURE]: () => false,
});

const resetPasswordReducers = combineReducers<ResetPasswordState>({
  error: resetPasswordErrorReducer,
  success: resetPasswordSuccessReducer,
  submitting: resetPasswordSubmittingReducer,
});

const loginInfoDataReducer = createReducer<ReducerState['loginInfo']['data'], Actions>(
  initialState.loginInfo.data,
  {
    [ActionTypes.FETCH_LOGIN_INFO_SUCCESS]: (curState, action) => {
      return action.payload.loginInfo;
    },
  }
);

const loginInfoErrorReducer = createReducer<ReducerState['loginInfo']['error'], Actions>(
  initialState.loginInfo.error,
  {
    [ActionTypes.FETCH_LOGIN_INFO_REQUEST]: () => null,
    [ActionTypes.FETCH_LOGIN_INFO_SUCCESS]: () => null,
    [ActionTypes.FETCH_LOGIN_INFO_FAILURE]: (curState, { payload }) => payload.error,
  }
);

const loginInfoReducers = combineReducers<LoginInfoState>({
  error: loginInfoErrorReducer,
  data: loginInfoDataReducer,
});

const mfaLoginInfoMFAEnabledReducer = createReducer<
  ReducerState['mfaLoginInfo']['mfaEnabled'],
  Actions
>(initialState.mfaLoginInfo.mfaEnabled, {
  [ActionTypes.MULTI_FACTOR_AUTHENTICATION]: (curState, action) => {
    return action.payload.mfaEnabled;
  },
});

const mfaLoginInfoErrorReducer = createReducer<ReducerState['mfaLoginInfo']['error'], Actions>(
  initialState.mfaLoginInfo.error,
  {
    [ActionTypes.MFA_USER_PASS_FAILURE]: (curState, { payload }) => payload.error,
  }
);
const mfaSubmittingReducer = createReducer<ReducerState['submitting'], Actions>(
  initialState.submitting,
  {
    [ActionTypes.MFA_USER_PASS_REQUEST]: () => true,
    [ActionTypes.MFA_USER_PASS_FAILURE]: () => false,
  }
);

const mfaloginInfoReducers = combineReducers<MFALoginInfoState>({
  error: mfaLoginInfoErrorReducer,
  mfaEnabled: mfaLoginInfoMFAEnabledReducer,
  mfaSubmitting: mfaSubmittingReducer,
});

export default combineReducers<ReducerState>({
  error: errorReducer,
  submitting: submittingReducer,
  resetPassword: resetPasswordReducers,
  loginInfo: loginInfoReducers,
  mfaLoginInfo: mfaloginInfoReducers,
});

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.login;
export const selectError = (state: RootState) => selectState(state).error;
export const selectSubmitting = (state: RootState) => selectState(state).submitting;
export const selectResetPasswordError = (state: RootState) =>
  selectState(state).resetPassword.error;
export const selectResetPasswordSuccess = (state: RootState) =>
  selectState(state).resetPassword.success;
export const selectResetPasswordSubmitting = (state: RootState) =>
  selectState(state).resetPassword.submitting;
export const selectLoginInfo = (state: RootState) => selectState(state).loginInfo.data;
export const selectLoginInfoError = (state: RootState) => selectState(state).loginInfo.error;
export const selectMfaEnabled = (state: RootState) => selectState(state).mfaLoginInfo.mfaEnabled;
export const selectMfaError = (state: RootState) => selectState(state).mfaLoginInfo.error;
export const selectMfaSubmitting = (state: RootState) =>
  selectState(state).mfaLoginInfo.mfaSubmitting;

/**
 * SAGAS
 */

/**
 * Login with username/password strategy and redirect if successful
 */
export function* loginUserPassSaga({
  payload,
}: Actions[ActionTypes.LOGIN_USER_PASS_REQUEST]): SagaIterator {
  const { username, password, returnUrl } = payload;
  const response: LoginUserPassResponse = yield call(identityApiClient.loginUserPass, {
    username,
    password,
    returnUrl,
  });
  if (response.ok && response.data.code === LoginUserPassResponseCode.REQUIRE_TWO_FACTOR) {
    yield put(multiFactorAuthentication({ mfaEnabled: response.data.code }));
  } else if (response.ok) {
    window.location.href = response.data.returnUrl;
  } else if ('error' in response.data) {
    // generic error from api
    yield put(loginUserPassFailed({ error: response.data.error }));
  } else {
    // specialized error from api
    // TODO - add logic that pivots on the various response codes (two factor vs invalid creds etc...)
    yield put(loginUserPassFailed({ error: response.data }));
  }
}

export function* resetPasswordSubmitSaga({
  payload,
}: Actions[ActionTypes.EXPIRED_PASSWORD_REQUEST]): SagaIterator {
  const response: ResetPasswordResponse = yield call(identityApiClient.resetPassword, payload);
  if (response.ok) {
    yield put(resetPasswordSuccess());
  } else {
    yield put(resetPasswordFailure(response.data.error));
  }
}

export function* fetchLoginInfoSaga({
  payload,
}: Actions[ActionTypes.FETCH_LOGIN_INFO_REQUEST]): SagaIterator {
  const response: FetchLoginInfoResponse = yield call(identityApiClient.fetchLoginInfo, payload);
  if (response.ok) {
    // When only one external provider is configured and locallogin is disabled
    // navigate the user to the external provider url
    const data = response.data;
    if (!data.localLoginEnabled && data.externalProviders.length === 1) {
      window.location.href = response.data.externalProviders[0].url;
    }
    yield put(fetchLoginInfoSuccess({ loginInfo: response.data }));
  } else {
    yield put(fetchLoginInfoFailure({ error: response.data.error }));
  }
}

/**
 * Login with securityCode and rememberDevice strategy and redirect if successful
 */
export function* mfaLoginUserPassSaga({
  payload,
}: Actions[ActionTypes.MFA_USER_PASS_REQUEST]): SagaIterator {
  const { code, rememberDevice, returnUrl } = payload;
  const response: MFALoginUserPassResponse = yield call(identityApiClient.mfaLoginUserPass, {
    code,
    rememberDevice,
    returnUrl,
  });
  if (response.ok) {
    window.location.href = response.data.returnUrl;
  } else if ('error' in response.data) {
    // generic error from api
    yield put(mfaLoginUserFailure({ error: response.data.error }));
  } else {
    // specialized error from api
    // TODO - add logic that pivots on the various response codes (two factor vs invalid creds etc...)
    yield put(mfaLoginUserFailure({ error: response.data }));
  }
}

/**
 * Resend Code for MFA
 */
export function* mfaResendCodeSaga(): SagaIterator {
  const response: ResendCodeResponse = yield call(identityApiClient.resendCode);
  if (response.ok) {
    SnackbarManager.success('Code was resent to your registered email');
  } else if ('error' in response.data) {
    yield put(mfaLoginUserFailure({ error: response.data.error }));
  } else {
    yield put(mfaLoginUserFailure({ error: response.data }));
  }
}

export function* loginSaga() {
  yield takeLatest<Actions[ActionTypes.LOGIN_USER_PASS_REQUEST]>(
    ActionTypes.LOGIN_USER_PASS_REQUEST,
    loginUserPassSaga
  );
  yield takeLatest<Actions[ActionTypes.EXPIRED_PASSWORD_REQUEST]>(
    ActionTypes.EXPIRED_PASSWORD_REQUEST,
    resetPasswordSubmitSaga
  );
  yield takeLatest<Actions[ActionTypes.FETCH_LOGIN_INFO_REQUEST]>(
    ActionTypes.FETCH_LOGIN_INFO_REQUEST,
    fetchLoginInfoSaga
  );
  yield takeLatest<Actions[ActionTypes.MFA_USER_PASS_REQUEST]>(
    ActionTypes.MFA_USER_PASS_REQUEST,
    mfaLoginUserPassSaga
  );
  yield takeLatest<Actions[ActionTypes.MFA_RESEND_CODE_REQUEST]>(
    ActionTypes.MFA_RESEND_CODE_REQUEST,
    mfaResendCodeSaga
  );
}
