import styled from 'styled-components/macro';

export const SHeading = styled.div`
  font-size: ${({ theme }) => theme.text.size.large};
  margin: 20px 0 0px;
`;

export const SSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

export const SInnerSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  & > div {
    margin-right: 4px;
  }
`;

export const SFormSection = styled.div`
  margin-bottom: 15px;
`;

export const SButtonsSection = styled.div`
  padding-top: 25px;
`;
