import { Banner, ServerErrors } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import FirmDetailsEditor from '../../../../../common/components/form/firm-details-editor/FirmDetailsEditor';
import LoadingOverlay from '../../../../../common/components/indicators/loading-overlay/LoadingOverlay';
import { getFirmAddressColumns } from '../../../../../common/components/layout/firm-record-panel/firmAddressColumns';
import { getFirmContactColumns } from '../../../../../common/components/layout/firm-record-panel/firmContactColumns';
import { getFirmIdentifierColumns } from '../../../../../common/components/layout/firm-record-panel/firmIdentifierColumns';
import { getFirmNameColumns } from '../../../../../common/components/layout/firm-record-panel/firmNameColumns';
import { getFirmRoleColumns } from '../../../../../common/components/layout/firm-record-panel/firmRoleColumns';
import { getFirmSectorColumns } from '../../../../../common/components/layout/firm-record-panel/firmSectorColumns';
import FirmRecordPanel from '../../../../../common/components/layout/firm-record-panel/GenericFirmRecordPanel';
import routeFactory from '../../../../../common/util/routeFactory';
import { UUID } from '../../../../../types/common';
import { FirmDataSource } from '../../../../../types/domain/firm/constants';
import { Firm } from '../../../../../types/domain/firm/Firm';
import { FirmAddress } from '../../../../../types/domain/firm/FirmAddress';
import { FirmContact } from '../../../../../types/domain/firm/FirmContact';
import { FirmIdentifier } from '../../../../../types/domain/firm/FirmIdentifier';
import { FirmName } from '../../../../../types/domain/firm/FirmName';
import { FirmRole } from '../../../../../types/domain/firm/FirmRole';
import { FirmSector } from '../../../../../types/domain/firm/FirmSector';
import { FirmLinkageSummaryPanel } from '../../../firm-detail/components/panels/FirmLinkageSummaryPanel';
import {
  fetchMetadata,
  selectMetadata,
  selectMetadataError,
  selectMetadataLoading,
} from '../../../shared/ducks';
import {
  fetchMatchFirmDetail,
  selectMatchFirmDetail,
  selectMatchFirmDetailError,
  selectMatchFirmDetailLoading,
} from '../../ducks';
import MatchDetailHeader from './match-detail-header/MatchDetailHeader';
import MatchFirmHierarchy from './match-firm-hierarchy/MatchFirmHierarchy';
import { SBannerWrapper, StyledIconButton } from './MatchDetailPanel.styles';
import SlideoutSidebar from './slideout-sidebar/SlideoutSidebar';

const mapStateToProps = state => ({
  firmLoading: selectMatchFirmDetailLoading(state),
  firmError: selectMatchFirmDetailError(state),
  firm: selectMatchFirmDetail(state),
  metadataLoading: selectMetadataLoading(state),
  metadataError: selectMetadataError(state),
  metadata: selectMetadata(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchMatchFirmDetail,
      fetchMetadata,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  show: boolean;
  width: number;
  crmIntegrationId: string;
  crmInvestorFirmId: string;
  matchFirmId?: UUID | string;
  matchFirmType?: FirmDataSource;
  cmgEntityKey?: string | null;
};
export type Props = OwnProps & RouteComponentProps & StateProps & DispatchProps;

/**
 * A view that displays details for a Firm Match, which may
 * be either a CMG Firm or a FactSet Firm.
 */
export const RolodexCrmMatchDetailRouteComponent: React.FC<Props> = ({
  show,
  width,
  crmIntegrationId,
  crmInvestorFirmId,
  cmgEntityKey,
  matchFirmId,
  matchFirmType,
  firmLoading,
  firmError,
  firm,
  metadata,
  history,
  actions,
}) => {
  React.useEffect(() => {
    matchFirmId &&
      matchFirmType &&
      actions.fetchMatchFirmDetail({ id: matchFirmId, type: matchFirmType });
  }, [actions, matchFirmId, matchFirmType]);

  React.useEffect(() => {
    actions.fetchMetadata();
  }, [actions]);

  if (firmError) {
    return (
      <SlideoutSidebar width={width} show={show}>
        <div>
          <StyledIconButton
            asComponent={Link}
            size="large"
            icon={{ name: 'times', variant: 'solid' }}
            to={routeFactory.rolodexCrmEntityMatcher.getUrlPath({
              crmIntegrationId,
              crmInvestorFirmId,
            })}
          />
          <SBannerWrapper>
            <Banner variant="error" showIcon={false}>
              <ServerErrors error={firmError} />
            </Banner>
          </SBannerWrapper>
        </div>
      </SlideoutSidebar>
    );
  }

  if (firmLoading) {
    return (
      <SlideoutSidebar width={width} show={show}>
        <LoadingOverlay message="Loading..." variant="TRANSPARENT" />
      </SlideoutSidebar>
    );
  }

  return (
    <SlideoutSidebar width={width} show={show}>
      {matchFirmId && matchFirmType && !firmError && !firmLoading && firm && (
        <div>
          <MatchDetailHeader
            crmIntegrationId={crmIntegrationId}
            crmInvestorFirmId={crmInvestorFirmId}
            matchFirmId={matchFirmId}
            cmgEntityKey={cmgEntityKey}
          />
          <MatchFirmHierarchy
            onClickFirmName={row => {
              // If the matchFirmType is CMG, we can assume
              // that the item clicked in the hierarchy is a CMG firm -
              // CMG hierarchies only show CMG firms.
              //
              // If matchFirmType isn't CMG (aka it's not in Rolodex),
              // we'll check the linkedCmgEntityId field.  If it's truthy,
              // the firm exists in CMG - and clicking this name should
              // load the details of the firm as it exists in Rolodex.
              //
              // If matchFirmType is CustomerCreated it should be treated as CMG firms
              // just that they won't have hierarchy at all.
              const firmExistsInRolodex =
                matchFirmType === FirmDataSource.CMG ||
                matchFirmType === FirmDataSource.CUSTOMER_CREATED ||
                ('linkedCmgEntityId' in row && !!row.linkedCmgEntityId);

              const linkMatchFirmId =
                'linkedCmgEntityId' in row ? row.linkedCmgEntityId ?? row.factSetFirmId : row.id;

              history.push(
                routeFactory.rolodexCrmEntityMatcherWithMatchDetails.getUrlPath({
                  crmIntegrationId: crmIntegrationId,
                  crmInvestorFirmId: crmInvestorFirmId,
                  matchFirmId: linkMatchFirmId,
                  matchFirmType: firmExistsInRolodex ? FirmDataSource.CMG : FirmDataSource.FACT_SET,
                })
              );
            }}
            matchFirmId={matchFirmId}
            matchFirmType={matchFirmType}
          />
          <FirmDetailsEditor
            isEditable={false}
            firmType={firm.firmType}
            firmDetails={firm.details}
            metadata={metadata}
          />
          <FirmRecordPanel<FirmName>
            isEditable={false}
            title="Names"
            columns={getFirmNameColumns({ isEditable: false })}
            rows={firm.names || []}
          />
          <FirmRecordPanel<FirmIdentifier>
            isEditable={false}
            title="Identifiers"
            columns={getFirmIdentifierColumns()}
            rows={firm.identifiers || []}
          />
          <FirmRecordPanel<FirmSector>
            isEditable={false}
            title="Sector Classification Schemes"
            columns={getFirmSectorColumns()}
            rows={(firm as Firm).sectors || []}
          />
          <FirmRecordPanel<FirmRole>
            isEditable={false}
            title="Roles"
            columns={getFirmRoleColumns()}
            rows={(firm as Firm).roles || []}
          />
          <FirmRecordPanel<FirmAddress>
            isEditable={false}
            title="Addresses"
            columns={getFirmAddressColumns()}
            rows={firm.addresses || []}
          />
          <FirmRecordPanel<FirmContact>
            isEditable={false}
            title="Contact Information"
            columns={getFirmContactColumns()}
            rows={firm.contacts || []}
          />
          {firm.linkFrequencyData && (
            <FirmLinkageSummaryPanel linkFrequencyData={firm.linkFrequencyData} />
          )}
        </div>
      )}
    </SlideoutSidebar>
  );
};

const RolodexCrmMatchDetailWithRouter = withRouter(RolodexCrmMatchDetailRouteComponent);

export default connect(mapStateToProps, mapDispatchToProps)(RolodexCrmMatchDetailWithRouter);
