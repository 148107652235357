import groupBy from 'lodash/groupBy';
import React from 'react';

import { PermissionDetail, UndefinedPermissionDetail } from '../../auth/constants';
import { noAccessLevelPermissionByEntity } from './PermissionsTable.model';

type Props = {
  groupName: string;
  accountEntities: string[];
  permissionsByGroup: { [key: string]: (PermissionDetail | UndefinedPermissionDetail)[] };
  renderRow: (params: {
    entity: string;
    description: string;
    entityPermissions: string[];
  }) => React.ReactNode;
  renderGroupHeader: (groupHeader: string) => React.ReactNode;
};

/**
 * PermissionGroup renders a group of permission rows
 * by permission group.
 * it exposes renderProp as an optional prop and provides it
 * with the necessary data for the consumer to render a permission row
 */
export const PermissionGroup: React.FC<Props> = ({
  accountEntities,
  groupName,
  permissionsByGroup,
  renderRow,
  renderGroupHeader,
}) => {
  // get group's permissions and then group by entity
  const permissionsGroupedByEntity = groupBy<PermissionDetail | UndefinedPermissionDetail>(
    permissionsByGroup[groupName],
    p => p.entity
  );

  // filter entities granted to account
  const entityLabels = Object.keys(permissionsGroupedByEntity).filter(entity =>
    accountEntities.includes(entity)
  );

  const getRowPermissionsNamesByEntity = (entity: string) =>
    [
      noAccessLevelPermissionByEntity(permissionsGroupedByEntity[entity]),
      ...permissionsGroupedByEntity[entity],
    ].map(({ name }) => name);

  // iterate over the entities in the group
  return (
    <React.Fragment>
      {entityLabels.length > 0 && renderGroupHeader(groupName)}
      {/* iterates and renders each permission row under the group */}
      {entityLabels.map((entity, entityIndex) => {
        // render each permission level within entity, with a faux no-access one first, then other levels
        const rowPermissionsNames = getRowPermissionsNamesByEntity(entity);

        return (
          <React.Fragment key={entityIndex}>
            {renderRow({
              entity,
              description: `View or manage ${entity}`,
              entityPermissions: rowPermissionsNames,
            })}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default PermissionGroup;
