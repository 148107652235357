import { ButtonGroup, PrimaryButton, SecondaryButton } from '@cmg/common';
import React from 'react';

import { FirmMatchSource } from '../../../../../types/domain/firm-match/FirmMatchSource';
import { SHeader, SSummary } from './CrmFirmMatchListHeader.styles';

type Props = {
  /**
   * flag for custom matching view
   */
  isCustom: boolean;
  /**
   * toggle custom/default matching in parent container
   */
  toggleCustom: (value: boolean) => void;
  /**
   * totals object from each source (CMG|FACT_SET)
   */
  totals: { [key: string]: number };
};

/**
 * Show a CRM Record of an Account with CRM Integration,
 */
export const CrmFirmMatchListHeader: React.FC<Props> = ({ isCustom, toggleCustom, totals }) => {
  return (
    <SHeader>
      <ButtonGroup>
        {isCustom && (
          <React.Fragment>
            <SecondaryButton onClick={() => toggleCustom(false)}>Default Matching</SecondaryButton>
            <PrimaryButton>Custom Matching</PrimaryButton>
          </React.Fragment>
        )}
        {!isCustom && (
          <React.Fragment>
            <PrimaryButton>Default Matching</PrimaryButton>
            <SecondaryButton onClick={() => toggleCustom(true)}>Custom Matching</SecondaryButton>
          </React.Fragment>
        )}
      </ButtonGroup>
      <SSummary>
        {`Matching Results: ${totals[FirmMatchSource.CMG] || 0} CMG Orgs, ${
          totals[FirmMatchSource.FACT_SET] || 0
        } FactSet Orgs`}
      </SSummary>
    </SHeader>
  );
};

export default CrmFirmMatchListHeader;
