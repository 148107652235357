import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import { Panel, SecondaryButton } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import SimpleFirmHierarchyTreeGrid from '../../../../../common/components/lists/simple-firm-hierarchy-tree-grid/SimpleFirmHierarchyTreeGrid';
import { createTreeGridRowsFromHierarchy } from '../../../../../common/components/lists/simple-firm-hierarchy-tree-grid/utils/hierarchyUtils';
import routeFactory from '../../../../../common/util/routeFactory';
import { UUID } from '../../../../../types/common';
import { FirmRelation } from '../../../../../types/domain/firm/FirmRelation';
import {
  fetchFirmHierarchy,
  selectFirmHierarchy,
  selectFirmHierarchyError,
  selectFirmHierarchyLoading,
  selectMetadata,
} from '../../../shared/ducks';
import { openChangeParentOrganizationModal } from '../change-parent-organization-modal/ChangeParentOrganizationModal';

const mapStateToProps = state => ({
  hierarchy: selectFirmHierarchy(state),
  metadata: selectMetadata(state),
  error: selectFirmHierarchyError(state),
  loading: selectFirmHierarchyLoading(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchFirmHierarchy,
      openChangeParentOrganizationModal,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  firmId: UUID;
};
type Props = OwnProps & StateProps & DispatchProps;

export const FirmHierarchyPanelComponent: React.FC<Props> = ({
  firmId,
  hierarchy,
  metadata,
  loading,
  error,
  actions,
}) => {
  React.useEffect(() => {
    actions.fetchFirmHierarchy({ firmId });
  }, [actions, firmId]);

  const history = useHistory();
  const { userPermissions } = useAuth();
  const canEdit = checkPermissions(userPermissions, [permissionsByEntity.Firm.FULL]);

  const rows = hierarchy ? createTreeGridRowsFromHierarchy(hierarchy) : [];
  const firmRow = rows.find(row => row.node.id === firmId);

  // We want to expand tree grid rows from the top of the tree down
  // to the target firm (the firm whose ID matches props.firmId)
  const expandedGroups = firmRow ? firmRow.hierarchy.slice(0, -1) : [];

  return (
    <Panel>
      <Panel.Header
        title="Organization Hierarchy"
        rightContent={
          canEdit ? (
            <SecondaryButton onClick={() => actions.openChangeParentOrganizationModal()}>
              Change Parent Organization
            </SecondaryButton>
          ) : null
        }
      />
      <SimpleFirmHierarchyTreeGrid
        error={error}
        loading={loading}
        onClickFirmName={firm =>
          history.push(
            routeFactory.rolodexFirmDetail.getUrlPath({ firmId: (firm as FirmRelation).id })
          )
        }
        activeFirmId={firmId}
        hierarchy={hierarchy}
        metadata={metadata}
        expandedGroups={expandedGroups}
        isCollapsible={true}
        showHeader
      />
    </Panel>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmHierarchyPanelComponent);
