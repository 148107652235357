import styled from 'styled-components/macro';

export const SHeader = styled.header`
  background-color: ${({ theme }) => theme.background.color.white};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px 25px 20px;
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};
`;

export const STitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 10px;

  > * {
    margin: 0 20px;
  }
`;

export const STitleHeader = styled.h2`
  margin: 0 30px 0 0;
  color: ${({ theme }) => theme.text.color.dark};
`;

export const SColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SBottomContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const SDetail = styled.div`
  margin: 5px 15px 0 0;
  display: flex;
`;

export const SDetailLabel = styled.div`
  color: ${({ theme }) => theme.color.gray3};
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const SDetailValue = styled.div`
  margin-left: 15px;
`;
