import { cmgSectorScheme, Option } from '@cmg/common';

export enum FirmNameType {
  COMMON = 'COMMON',
  LEGAL = 'LEGAL',
  SHORT = 'SHORT',
  TRADE_DBA = 'TRADE_DBA',
  FORMER = 'FORMER',
  OTHER = 'OTHER',
}

export const firmNameTypeLabels = {
  [FirmNameType.COMMON]: 'Common Name',
  [FirmNameType.LEGAL]: 'Legal Name',
  [FirmNameType.SHORT]: 'Short Name',
  [FirmNameType.TRADE_DBA]: 'Trade DBA Name',
  [FirmNameType.FORMER]: 'Former Name',
  [FirmNameType.OTHER]: 'Other',
};

export const firmNameTypeOptions = Object.keys(FirmNameType).map(
  nameType =>
    ({
      label: firmNameTypeLabels[nameType],
      value: nameType,
    } as Option<FirmNameType>)
);

export enum FirmIdentifierType {
  LEI = 'LEI',
  CIK = 'CIK',
  CRD = 'CRD',
  MPID = 'MPID',
  FACT_SET = 'FACT_SET',
  MORNING_STAR = 'MORNING_STAR',
  OTHER = 'OTHER',
  ISIN = 'ISIN',
  CUSIP = 'CUSIP',
  TICKER = 'TICKER',
  DTC = 'DTC',
}

export const firmIdentifierTypeLabels = {
  [FirmIdentifierType.LEI]: 'LEI',
  [FirmIdentifierType.CIK]: 'CIK',
  [FirmIdentifierType.CRD]: 'CRD',
  [FirmIdentifierType.MPID]: 'MPID',
  [FirmIdentifierType.FACT_SET]: 'FactSet',
  [FirmIdentifierType.MORNING_STAR]: 'Morningstar',
  [FirmIdentifierType.OTHER]: 'Other',
  [FirmIdentifierType.ISIN]: 'ISIN',
  [FirmIdentifierType.CUSIP]: 'CUSIP',
  [FirmIdentifierType.TICKER]: 'Ticker',
  [FirmIdentifierType.DTC]: 'DTC',
};

export const firmIdentifierTypeOptions = Object.keys(FirmIdentifierType).map(
  identifier =>
    ({
      label: firmIdentifierTypeLabels[identifier],
      value: identifier,
    } as Option<FirmIdentifierType>)
);

export enum FirmSectorSchemeType {
  MORNING_STAR = 'MORNING_STAR',
}

export const firmSectorSchemeTypeLabels = {
  [FirmSectorSchemeType.MORNING_STAR]: 'Morningstar',
};

export const firmSectorSchemeTypeOptions = Object.keys(FirmSectorSchemeType).map(
  scheme =>
    ({
      label: firmSectorSchemeTypeLabels[scheme],
      value: scheme,
    } as Option<FirmSectorSchemeType>)
);

export enum FirmRoleType {
  AUDITOR = 'AUDITOR',
  COUNSEL = 'COUNSEL',
  INVESTOR = 'INVESTOR',
  ISSUER = 'ISSUER',
  UNDERWRITER = 'UNDERWRITER',
  LENDER = 'LENDER',
  SPONSOR = 'SPONSOR',
  ADVISOR = 'ADVISOR',
}

export const firmRoleTypeLabels = {
  [FirmRoleType.AUDITOR]: 'Auditor',
  [FirmRoleType.COUNSEL]: 'Counsel',
  [FirmRoleType.INVESTOR]: 'Investor',
  [FirmRoleType.ISSUER]: 'Issuer',
  [FirmRoleType.UNDERWRITER]: 'Underwriter',
  [FirmRoleType.LENDER]: 'Lender',
  [FirmRoleType.SPONSOR]: 'Sponsor',
  [FirmRoleType.ADVISOR]: 'Advisor',
};

export const firmRoleTypeOptions = Object.keys(FirmRoleType).map(
  scheme =>
    ({
      label: firmRoleTypeLabels[scheme],
      value: scheme,
    } as Option<FirmRoleType>)
);

export enum FirmAddressType {
  HEADQUARTERS = 'HEADQUARTERS',
  PREVIOUS = 'PREVIOUS',
  OTHER = 'OTHER',
}

export const firmAddressTypeLabels = {
  [FirmAddressType.HEADQUARTERS]: 'Headquarters',
  [FirmAddressType.PREVIOUS]: 'Previous Address',
  [FirmAddressType.OTHER]: 'Other',
};

export const firmAddressTypeOptions = Object.keys(FirmAddressType).map(
  scheme =>
    ({
      label: firmAddressTypeLabels[scheme],
      value: scheme,
    } as Option<FirmAddressType>)
);

export enum FirmContactType {
  URL = 'URL',
  EMAIL = 'EMAIL',
  PROSPECTUS_PHONE_NUMBER = 'PROSPECTUS_PHONE_NUMBER',
  SYNDICATE_WIRES_DISTRIBUTION_EMAIL = 'SYNDICATE_WIRES_DISTRIBUTION_EMAIL',
}

export const firmContactTypeLabels = {
  [FirmContactType.URL]: 'URL',
  [FirmContactType.EMAIL]: 'Email',
  [FirmContactType.PROSPECTUS_PHONE_NUMBER]: 'US ECM Prospectus Phone Number',
  [FirmContactType.SYNDICATE_WIRES_DISTRIBUTION_EMAIL]: 'Syndicate Wires Distribution Email',
};

export const firmContactTypeOptions = Object.keys(FirmContactType).map(
  scheme =>
    ({
      label: firmContactTypeLabels[scheme],
      value: scheme,
    } as Option<FirmContactType>)
);

export const cmgSectorSchemeOptions = cmgSectorScheme.map(sector => {
  return {
    label: sector.title,
    options: sector.children.map(child => ({
      label: child.title,
      value: Number(child.value),
    })),
  };
});

export enum FirmDataSource {
  CMG = 'CMG',
  CUSTOMER_CREATED = 'CUSTOMER_CREATED',
  FACT_SET = 'FACT_SET',
  MORNING_STAR = 'MORNING_STAR',
}

export const firmDataSourceLabels = {
  [FirmDataSource.CMG]: 'CMG',
  [FirmDataSource.CUSTOMER_CREATED]: 'Customer Created',
  [FirmDataSource.FACT_SET]: 'FactSet',
  [FirmDataSource.MORNING_STAR]: 'Morning Star',
};

export enum TraversalType {
  ANCESTOR = 'ANCESTOR',
  DESCENDANT = 'DESCENDANT',
  FULL = 'FULL',
}
