import { apiTypes, duckPartFactory } from '@cmg/common';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import {
  GetCrmIntegrationParams,
  GetCrmIntegrationResponse,
  GetFirmHierarchyResponse,
  GetFirmsParams,
  GetFirmsResponse,
  GetIndustryTypeFirmMetadataResponse,
  GetLegalEntityTypeFirmMetadataResponse,
} from '../../../common/api/rolodexApiClient';
import * as rolodexApiClient from '../../../common/api/rolodexApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { CrmIntegration } from '../../../types/domain/entity-matcher/CrmIntegration';
import { TraversalType } from '../../../types/domain/firm/constants';
import { FirmHierarchy } from '../../../types/domain/firm/FirmHierarchy';
import { FirmLimited } from '../../../types/domain/firm/FirmLimited';
import { RolodexMetadata } from '../../../types/domain/rolodex/Metadata';

export const fetchMetadataDuckParts = duckPartFactory.makeAPIDuckParts<undefined, RolodexMetadata>({
  prefix: 'ROLODEX/SHARED/METADATA',
});

export const fetchFirmHierarchyDuckParts = duckPartFactory.makeAPIDuckParts<
  { firmId: string; traversalType: TraversalType },
  {
    hierarchy: FirmHierarchy;
  }
>({
  prefix: 'ROLODEX/FIRM_HIERARCHY',
});

export const fetchFirmListDuckParts = duckPartFactory.makeAPIDuckParts<
  GetFirmsParams,
  {
    data: FirmLimited[];
    pagination: apiTypes.Pagination;
  }
>({
  prefix: 'ROLODEX/FIRM_LIST',
});

export const fetchCrmIntegrationDuckParts = duckPartFactory.makeAPIDuckParts<
  GetCrmIntegrationParams,
  CrmIntegration
>({
  prefix: 'ROLODEX/CRM_INTEGRATION',
});

/**
 * ACTION CREATORS
 */

export const fetchMetadata = () => fetchMetadataDuckParts.actionCreators.request(undefined);
type fetchMetadataAction = ReturnType<typeof fetchMetadata>;

export const fetchFirmHierarchy = ({
  firmId,
  traversalType = TraversalType.FULL,
}: {
  firmId: string;
  traversalType?: TraversalType;
}) => fetchFirmHierarchyDuckParts.actionCreators.request({ firmId, traversalType });
type fetchFirmHierarchyAction = ReturnType<typeof fetchFirmHierarchy>;

export const fetchFirmList = fetchFirmListDuckParts.actionCreators.request;
type fetchFirmListAction = ReturnType<typeof fetchFirmList>;

export const fetchCrmIntegration = fetchCrmIntegrationDuckParts.actionCreators.request;
type fetchCrmIntegrationAction = ReturnType<typeof fetchCrmIntegration>;

/**
 * REDUCERS
 */

export const initialState = {
  metadata: fetchMetadataDuckParts.initialState,
  hierarchy: fetchFirmHierarchyDuckParts.initialState,
  firms: fetchFirmListDuckParts.initialState,
  crmIntegration: fetchCrmIntegrationDuckParts.initialState,
};

export type ReducerState = typeof initialState;

export default combineReducers<ReducerState>({
  metadata: fetchMetadataDuckParts.reducer,
  hierarchy: fetchFirmHierarchyDuckParts.reducer,
  firms: fetchFirmListDuckParts.reducer,
  crmIntegration: fetchCrmIntegrationDuckParts.reducer,
});

/**
 * SELECTORS
 */

const selectState = (state: RootState) => state.rolodexShared;

const metadataSelectors = fetchMetadataDuckParts.makeSelectors(
  state => selectState(state).metadata
);

export const selectMetadataLoading = metadataSelectors.selectLoading;
export const selectMetadataError = metadataSelectors.selectError;
export const selectMetadata = (state: RootState) => {
  const successBody = metadataSelectors.selectData(state);
  return successBody ? successBody : { industryTypes: [], entityTypes: [] };
};

const firmHierarchySelectors = fetchFirmHierarchyDuckParts.makeSelectors(
  state => selectState(state).hierarchy
);
export const selectFirmHierarchyLoading = firmHierarchySelectors.selectLoading;
export const selectFirmHierarchyError = firmHierarchySelectors.selectError;
export const selectFirmHierarchy = createSelector(firmHierarchySelectors.selectData, data =>
  data ? data.hierarchy : null
);

const firmListSelectors = fetchFirmListDuckParts.makeSelectors(state => selectState(state).firms);
export const selectFirmsLoading = firmListSelectors.selectLoading;
export const selectFirmsError = firmListSelectors.selectError;
export const selectFirms = (state: RootState) => {
  const successBody = firmListSelectors.selectData(state);
  return successBody ? successBody.data : [];
};
export const selectFirmsPagination = (state: RootState) => {
  const successBody = firmListSelectors.selectData(state);
  return successBody ? successBody.pagination : undefined;
};

const crmIntegrationSelectors = fetchCrmIntegrationDuckParts.makeSelectors(
  state => selectState(state).crmIntegration
);
export const selectCrmIntegration = (state: RootState) => {
  return crmIntegrationSelectors.selectData(state) || undefined;
};

/**
 * SAGAS
 */

export function* fetchMetadataSaga() {
  const [legalEntityTypeMetadataResponse, industryTypeMetadataResponse]: [
    GetLegalEntityTypeFirmMetadataResponse,
    GetIndustryTypeFirmMetadataResponse
  ] = yield Promise.all([
    rolodexApiClient.getLegalEntityTypeFirmMetadata(),
    rolodexApiClient.getIndustryTypeFirmMetadata(),
  ]);

  if (legalEntityTypeMetadataResponse.ok && industryTypeMetadataResponse.ok) {
    yield put(
      fetchMetadataDuckParts.actionCreators.success({
        entityTypes: legalEntityTypeMetadataResponse.data,
        industryTypes: industryTypeMetadataResponse.data,
      })
    );
  }
}

export function* fetchFirmHierarchySaga({ payload }: fetchFirmHierarchyAction): SagaIterator {
  const response: GetFirmHierarchyResponse = yield call(rolodexApiClient.getFirmHierarchy, {
    firmId: payload.firmId,
    traversalType: payload.traversalType,
  });

  if (response.ok) {
    yield put(fetchFirmHierarchyDuckParts.actionCreators.success({ hierarchy: response.data }));
  } else {
    yield put(fetchFirmHierarchyDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* fetchFirmListSaga({ payload }: fetchFirmListAction): SagaIterator {
  const response: GetFirmsResponse = yield call(rolodexApiClient.getFirms, payload);

  if (response.ok) {
    yield put(fetchFirmListDuckParts.actionCreators.success(response.data));
  } else {
    yield put(fetchFirmListDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* fetchCrmIntegrationSaga({ payload }: fetchCrmIntegrationAction): SagaIterator {
  const response: GetCrmIntegrationResponse = yield call(
    rolodexApiClient.getCrmIntegration,
    payload
  );

  if (response.ok) {
    yield put(fetchCrmIntegrationDuckParts.actionCreators.success(response.data));
  } else {
    yield put(fetchCrmIntegrationDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* rolodexSharedSaga() {
  yield takeLatest<fetchMetadataAction>(
    fetchMetadataDuckParts.actionTypes.REQUEST,
    fetchMetadataSaga
  );
  yield takeLatest<fetchFirmHierarchyAction>(
    fetchFirmHierarchyDuckParts.actionTypes.REQUEST,
    fetchFirmHierarchySaga
  );
  yield takeLatest<fetchFirmListAction>(
    fetchFirmListDuckParts.actionTypes.REQUEST,
    fetchFirmListSaga
  );
  yield takeLatest<fetchCrmIntegrationAction>(
    fetchCrmIntegrationDuckParts.actionTypes.REQUEST,
    fetchCrmIntegrationSaga
  );
}
