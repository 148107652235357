import { DangerButton, PrimaryButton, SecondaryButton } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

import DetailHeader from '../../../../../../common/components/layout/detail-header/DetailHeader';

export const SButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const ButtonStyles = css`
  min-width: 70px;
  margin: 0 0 0 10px;
`;

export const StyledPrimaryLinkButton = styled(PrimaryButton)`
  ${ButtonStyles}
`;

export const StyledSecondaryOpenButton = styled(SecondaryButton)`
  ${ButtonStyles}
`;

export const SSticky = styled.div`
  position: sticky;
  top: 0;
`;

export const StyledDetailHeaderColumn = styled(DetailHeader.Column)`
  margin-left: 20px;
`;

export const StyledDetailHeaderTitleWrapper = styled(DetailHeader.TitleWrapper)`
  display: flex;
  align-items: center;
`;

export const StyledDangerButton = styled(DangerButton)`
  width: 100px;
`;

export const SIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 60px;
`;
