import { Grid, Stack } from '@cmg/design-system';
import { ReactNode, VFC } from 'react';

export type IdentityPageContentProps = {
  /**
   * Local Highlights
   */
  localHighlights?: Readonly<ReactNode>;
  /**
   * Grid content
   */
  gridContent: Readonly<ReactNode>;
};

export const IdentityPageContent: VFC<IdentityPageContentProps> = ({
  localHighlights,
  gridContent,
}) => {
  return (
    <Stack direction="column">
      {localHighlights}
      <Grid container px={4} spacing={3}>
        {gridContent}
      </Grid>
    </Stack>
  );
};
