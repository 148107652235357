import { SubNavigation } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';

import routeFactory from '../../common/util/routeFactory';
import { UUID } from '../../types/common';
import { selectFirmDisplayName } from './firm-detail/ducks';

const mapStateToProps = state => ({
  firmDisplayName: selectFirmDisplayName(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type RolodexFirmBreadcrumbComponentRouteComponentProps = RouteComponentProps<{ firmId: UUID }>;
type Props = StateProps;

export const RolodexFirmBreadcrumbComponent: React.FC<
  Props & RolodexFirmBreadcrumbComponentRouteComponentProps
> = ({ match, firmDisplayName }) => (
  <React.Fragment>
    {firmDisplayName && (
      <SubNavigation.Breadcrumb>
        <SubNavigation.Link exact to={routeFactory.rolodexFirmDetail.getUrlPath(match.params)}>
          {firmDisplayName.value}
        </SubNavigation.Link>
      </SubNavigation.Breadcrumb>
    )}
  </React.Fragment>
);

const ConnectedFirmBreadcrumb = connect(mapStateToProps)(RolodexFirmBreadcrumbComponent);

export const CreateFirmBreadcrumbComponent = () => (
  <SubNavigation.Breadcrumb>
    <SubNavigation.Link to={routeFactory.rolodexFirmCreate.getUrlPath()}>
      New Organization
    </SubNavigation.Link>
  </SubNavigation.Breadcrumb>
);

export const ImportFirmBreadcrumbComponent = () => (
  <SubNavigation.Breadcrumb>
    <SubNavigation.Link to={routeFactory.rolodexFirmImport.getUrlPath()}>
      New Organization
    </SubNavigation.Link>
  </SubNavigation.Breadcrumb>
);

/**
 * Displays the firm's name as a route link to the firm/organization route, or
 * "New Organization" on the Create Org routes.
 *
 * Used within Rolodex sub nav.
 */
const RolodexFirmBreadcrumb = () => {
  return (
    <React.Fragment>
      <Route path={routeFactory.rolodexFirmDetail.routePath} component={ConnectedFirmBreadcrumb} />
      <Route
        path={routeFactory.rolodexFirmCreate.routePath}
        component={CreateFirmBreadcrumbComponent}
      />
      <Route
        path={routeFactory.rolodexFirmImport.routePath}
        component={ImportFirmBreadcrumbComponent}
      />
    </React.Fragment>
  );
};

export default RolodexFirmBreadcrumb;
