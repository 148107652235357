import { TableSkeleton } from '@cmg/design-system';
import React, { FC, Fragment, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import routeFactory from '../../../../../common/util/routeFactory';
import { PageGridSection } from '../../../../../design-system/body-sections/PageGridSection';
import { IdentityPageContent } from '../../../../../design-system/IdentityPageContent';
import { HighlightsSkeleton } from '../../../../../design-system/loading/HighlightsSkeleton';
import {
  fetchApiKey,
  regenerateApiKey,
  resetApiKeyDetailState,
  resetApiKeyErrors,
  revokeApiKey,
  selectApiKey,
  selectError,
  selectRegenerating,
} from '../../../../account-detail/api-keys/api-keys-detail/ducks';
import ApiKeyModal from '../../../../account-detail/api-keys/shared/ApiKeyModal';
import {
  fetchAccountPermissions,
  selectAccount,
  selectAccountPermissions,
  selectAccountPermissionsLoading,
} from '../../../../account-detail/shared/ducks';
import { PermissionsTable } from '../shared/PermissionsTable';
import { PermissionsTableGroupHeader } from '../shared/PermissionsTableGroupHeader';
import { PermissionsTableHeader } from '../shared/PermissionsTableHeader';
import { PermissionsTableRow } from '../shared/PermissionsTableRow';
import { ApiKeyDetailHighlights } from './highlights/ApiKeyDetailHighlights';

const mapStateToProps = state => ({
  apiKey: selectApiKey(state),
  loading: selectAccountPermissionsLoading(state),
  regenerating: selectRegenerating(state),
  accountPermissions: selectAccountPermissions(state),
  account: selectAccount(state),
  error: selectError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchApiKey,
      regenerateApiKey,
      revokeApiKey,
      fetchAccountPermissions,
      resetApiKeyDetailState,
      resetApiKeyErrors,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RouteProps = RouteComponentProps<{
  apiKeyId: string;
}>;
export type Props = RouteProps & StateProps & DispatchProps;

export const ApiKeyDetailRouteComponent: FC<Props> = ({
  apiKey,
  account,
  accountPermissions,
  regenerating,
  loading,
  actions,
  match,
  history,
  error,
}) => {
  const { apiKeyId } = match.params;

  useEffect(() => {
    actions.fetchAccountPermissions(undefined);
    actions.fetchApiKey({ apiKeyId });

    return () => {
      actions.resetApiKeyDetailState();
    };
  }, [actions, apiKeyId]);

  const handleRedirectToList = useCallback(() => {
    history.push(
      routeFactory.accountApiKeys.getUrlPath({
        accountSubdomain: account!.subdomain,
      })
    );
  }, [account, history]);

  if (loading) {
    return (
      <IdentityPageContent
        localHighlights={<HighlightsSkeleton aria-label="Loading ApiKey Highlights" />}
        gridContent={
          <PageGridSection
            size="large"
            title="Permissions"
            content={<TableSkeleton aria-label="Loading Permissions" />}
          />
        }
      />
    );
  }

  if (!apiKey || !account) {
    return null;
  }

  return (
    <IdentityPageContent
      localHighlights={
        <ApiKeyDetailHighlights
          apiKey={apiKey}
          isLoading={regenerating || loading}
          rengenerateApiKeyError={error}
          onRevokeApiKey={() => actions.revokeApiKey({ apiKeyId: apiKey.id, history })}
          onRegenerateApiKey={() => actions.regenerateApiKey({ apiKeyId: apiKey.id })}
          onResetRegenerateApiKeyErrors={actions.resetApiKeyErrors}
        />
      }
      gridContent={
        <PageGridSection
          size="large"
          title="Permissions"
          content={
            <Fragment>
              <PermissionsTable
                accountPermissions={accountPermissions}
                selectedPermissions={apiKey.permissions}
                renderTableHeader={() => <PermissionsTableHeader />}
                renderGroupHeader={groupHeader => (
                  <PermissionsTableGroupHeader groupHeader={groupHeader} />
                )}
                renderRow={props => <PermissionsTableRow {...props} isInEditMode={false} />}
              />
              <ApiKeyModal onHide={handleRedirectToList} />
            </Fragment>
          }
        />
      }
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeyDetailRouteComponent);
