import { apiTypes, CenteredPage } from '@cmg/common';
import React, { useState } from 'react';

import { MFALoginUserPassFailureBody } from '../../../../../../common/api/identityApiClient';
import MFAForm from '../mfaForm/MFAForm';

type Props = {
  onSubmitMFAForm: (values: { securityCode: string; rememberDevice: boolean }) => void;
  mfaError: MFALoginUserPassFailureBody | apiTypes.GenericServerError | null;
  submitting: boolean;
  resendCode: () => void;
};

/**
 * Styles and lays out the the various login options in a centered box.
 */

const MFAScreen: React.FC<Props> = ({ onSubmitMFAForm, mfaError, submitting, resendCode }) => {
  const [checked, setChecked] = useState(true);
  return (
    <CenteredPage>
      {/* TODO - as we introduce more login options, we will add them here */}
      <MFAForm
        onSubmitMFAForm={onSubmitMFAForm}
        checked={checked}
        onChange={() => {
          setChecked(!checked);
        }}
        mfaError={mfaError}
        submitting={submitting}
        resendCode={resendCode}
      />
    </CenteredPage>
  );
};

export default MFAScreen;
