import {
  AdminFirmBasicInformationRead,
  AdminFirmBasicInformationUpdate,
} from '@capital-markets-gateway/api-client-rolodex';
import { duckPartFactory, reduxUtil } from '@cmg/common';
import isEqual from 'lodash/isEqual';
import { AnyAction, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import * as rolodexApiClient from '../../../common/api/rolodexApiClient';
import {
  ConnectFactSetFirmToCMGFirmResponse,
  CreateFirmAddressResponse,
  CreateFirmContactResponse,
  CreateFirmIdentifierResponse,
  CreateFirmNameResponse,
  CreateFirmRoleResponse,
  CreateFirmSectorResponse,
  DisconnectFactSetFirmFromCMGFirmResponse,
  GetFactSetFirmHierarchyResponse,
  GetFirmHierarchyResponse,
  GetFirmResponse,
  GetFirmVersionHistoryResponse,
  GetFirmVersionResponse,
  SetFirmDisplayNameResponse,
  SetFirmFinraNameResponse,
  SetFirmMarketingNameResponse,
  SetFirmParentResponse,
  UpdateFirmAddressResponse,
  UpdateFirmContactResponse,
  UpdateFirmDetailsResponse,
  UpdateFirmIdentifierResponse,
  UpdateFirmNameResponse,
  UpdateFirmRoleResponse,
  UpdateFirmSectorResponse,
} from '../../../common/api/rolodexApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { UUID } from '../../../types/common';
import { TraversalType } from '../../../types/domain/firm/constants';
import { FactSetFirmHierarchy } from '../../../types/domain/firm/FactSetFirmHierarchy';
import { Firm } from '../../../types/domain/firm/Firm';
import { FirmAddress } from '../../../types/domain/firm/FirmAddress';
import { FirmContact } from '../../../types/domain/firm/FirmContact';
import { FirmHierarchy } from '../../../types/domain/firm/FirmHierarchy';
import { FirmIdentifier } from '../../../types/domain/firm/FirmIdentifier';
import { FirmName } from '../../../types/domain/firm/FirmName';
import { FirmRole } from '../../../types/domain/firm/FirmRole';
import { FirmSector } from '../../../types/domain/firm/FirmSector';
import { FirmVersion } from '../../../types/domain/firm/FirmVersion';
import { fetchFirmHierarchy } from '../shared/ducks';
import { closeConnectWithFactSetModal } from './components/connect-with-factset-modal/ConnectWithFactSetModal';
import { closeDisconnectWithFactSetModal } from './components/disconnect-with-factset-modal/DisconnectWithFactSetModal';
import { openFirmHistoryModal } from './components/firm-history-modal/FirmHistoryModal';
import { openFirmVersionDiffModal } from './components/firm-version-modal/FirmVersionDiffModal';

const { createReducer } = reduxUtil;

enum Operation {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum FirmDetailSection {
  DETAILS = 'details',
  ADDRESSES = 'addresses',
  NAMES = 'names',
  IDENTIFIERS = 'identifiers',
  CONTACTS = 'contacts',
  SECTORS = 'sectors',
  ROLES = 'roles',
}

export const NewRecordTempId = {
  [FirmDetailSection.ADDRESSES]: `${FirmDetailSection.ADDRESSES}-new-record-temp-id`,
  [FirmDetailSection.NAMES]: `${FirmDetailSection.NAMES}-new-record-temp-id`,
  [FirmDetailSection.IDENTIFIERS]: `${FirmDetailSection.IDENTIFIERS}-new-record-temp-id`,
  [FirmDetailSection.CONTACTS]: `${FirmDetailSection.CONTACTS}-new-record-temp-id`,
  [FirmDetailSection.SECTORS]: `${FirmDetailSection.SECTORS}-new-record-temp-id`,
  [FirmDetailSection.ROLES]: `${FirmDetailSection.ROLES}-new-record-temp-id`,
};

/**
 * ACTION TYPES
 */
enum ActionTypes {
  RESET_STATE = 'ROLODEX/FIRM_DETAIL/RESET_STATE',
  RESET_CONNECT_WITH_FACTSET_MODAL_STATE = 'ROLODEX/FIRM_DETAIL/RESET_CONNECT_WITH_FACTSET_MODAL_STATE',
  EDIT_RECORD = 'ROLODEX/FIRM_DETAIL/EDIT_RECORD',
  CANCEL_EDIT_RECORD = 'ROLODEX/FIRM_DETAIL/CANCEL_EDIT_RECORD',
  RECORD_UPDATE_REQUEST_START = 'ROLODEX/FIRM_DETAIL/RECORD_UPDATE_REQUEST_START',
  RECORD_UPDATE_REQUEST_COMPLETE = 'ROLODEX/FIRM_DETAIL/RECORD_UPDATE_REQUEST_COMPLETE',
}

/**
 * DUCK PARTS
 */

export const fetchFirmDuckParts = duckPartFactory.makeAPIDuckParts<
  { id: string },
  {
    firm: Firm;
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FIRM',
});

export const fetchFirmHistoryDuckParts = duckPartFactory.makeAPIDuckParts<
  { id: string },
  {
    versions: FirmVersion[];
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FIRM_HISTORY',
});

export const fetchFirmVersionDiffDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    id: string;
    fromVersion: number;
    toVersion: number;
  },
  {
    from: Firm;
    to: Firm;
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FIRM_VERSION_DIFF',
});

export const updateFirmDetailsDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    firmId: UUID;
    id: UUID;
    body: AdminFirmBasicInformationUpdate;
  },
  {
    details: AdminFirmBasicInformationRead;
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/UPDATE_FIRM_DETAILS',
});

export const firmAddressesDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    firmId: UUID;
    operation: Operation;
    address: FirmAddress;
  },
  {
    updatedId?: UUID;
    addresses: FirmAddress[];
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FIRM_ADDRESSES',
});

export const firmNamesDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    firmId: UUID;
    operation: Operation;
    name: FirmName;
  },
  {
    updatedId?: UUID;
    names: FirmName[];
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FIRM_NAMES',
});

export const firmDisplayNameDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    firmId: UUID;
    nameId: UUID;
  },
  {}
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FIRM_DISPLAY_NAME',
});

export const firmMarketingNameDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    firmId: UUID;
    nameId: UUID;
    isMarketingName: boolean;
  },
  {}
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FIRM_MARKETING_NAME',
});

export const firmFinraNameDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    firmId: UUID;
    nameId: UUID;
    isFinraName: boolean;
  },
  {}
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FIRM_FINRA_NAME',
});

export const firmIdentifiersDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    firmId: UUID;
    operation: Operation;
    identifier: FirmIdentifier;
  },
  {
    updatedId?: UUID;
    identifiers: FirmIdentifier[];
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FIRM_IDENTIFIERS',
});

export const firmContactsDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    firmId: UUID;
    operation: Operation;
    contact: FirmContact;
  },
  {
    updatedId?: UUID;
    contacts: FirmContact[];
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FIRM_CONTACTS',
});

export const firmSectorsDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    firmId: UUID;
    operation: Operation;
    sector: FirmSector;
  },
  {
    updatedId?: UUID;
    sectors: FirmSector[];
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FIRM_SECTORS',
});

export const firmRolesDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    firmId: UUID;
    operation: Operation;
    role: FirmRole;
  },
  {
    updatedId?: UUID;
    roles: FirmRole[];
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FIRM_ROLES',
});

export const fetchFirmDescendantsDuckParts = duckPartFactory.makeAPIDuckParts<
  { firmId: UUID },
  {
    descendants: FirmHierarchy;
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FETCH_FIRM_DESCENDANTS',
});

export const setFirmParentDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    firmId: UUID;
    relationId: UUID;
    parentId: UUID | null;
  },
  {}
>({
  prefix: 'ROLODEX/FIRM_DETAIL/SET_FIRM_PARENT',
});

export const fetchFactSetFirmHierarchyDuckParts = duckPartFactory.makeAPIDuckParts<
  { id: string },
  {
    factSetFirmHierarchy: FactSetFirmHierarchy;
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/FACTSET_FIRM_HIERARCHY',
});

export const getConnectFactSetFirmWithCMGFirmPreviewDiffDuckParts =
  duckPartFactory.makeAPIDuckParts<
    { firmId: UUID; factSetId: string },
    {
      from: Firm;
      to: Firm;
    }
  >({
    prefix: 'ROLODEX/FIRM_DETAIL/GET_CONNECT_FIRM_FROM_FACTSET_PREVIEW_DIFF',
  });

export const connectFactSetFirmWithCMGFirmDuckParts = duckPartFactory.makeAPIDuckParts<
  { factSetFirmId: string; cmgFirmId: UUID },
  {
    firm: Firm;
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/CONNECT_FACTSET_FIRM_WITH_CMG_FIRM',
});

export const getDisconnectFactSetFirmFromCMGFirmPreviewDiffDuckParts =
  duckPartFactory.makeAPIDuckParts<
    {
      firmId: UUID;
    },
    {
      from: Firm;
      to: Firm;
    }
  >({
    prefix: 'ROLODEX/FIRM_DETAIL/GET_DISCONNECT_FIRM_FROM_FACTSET_PREVIEW_DIFF',
  });

export const disconnectFactSetFirmFromCMGFirmDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    firmId: UUID;
  },
  {
    firm: Firm;
  }
>({
  prefix: 'ROLODEX/FIRM_DETAIL/DISCONNECT_FACTSET_FIRM_WITH_CMG_FIRM',
});

/**
 * ACTION CREATORS
 */

export const resetState = () => ({
  type: ActionTypes.RESET_STATE,
});

export const resetConnectWithFactSetModalState = () => ({
  type: ActionTypes.RESET_CONNECT_WITH_FACTSET_MODAL_STATE,
});

export const editRecord = (payload: { recordId: UUID }) => ({
  type: ActionTypes.EDIT_RECORD,
  payload,
});

export const cancelEditRecord = (payload: { recordId: UUID }) => ({
  type: ActionTypes.CANCEL_EDIT_RECORD,
  payload,
});

export const recordUpdateRequestStart = (payload: { recordId: UUID }) => ({
  type: ActionTypes.RECORD_UPDATE_REQUEST_START,
  payload,
});

export const recordUpdateRequestComplete = (payload: { recordId: UUID }) => ({
  type: ActionTypes.RECORD_UPDATE_REQUEST_COMPLETE,
  payload,
});

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.EDIT_RECORD]: ReturnType<typeof editRecord>;
  [ActionTypes.CANCEL_EDIT_RECORD]: ReturnType<typeof cancelEditRecord>;
  [ActionTypes.RECORD_UPDATE_REQUEST_START]: ReturnType<typeof recordUpdateRequestStart>;
  [ActionTypes.RECORD_UPDATE_REQUEST_COMPLETE]: ReturnType<typeof recordUpdateRequestComplete>;
  [ActionTypes.RESET_STATE]: ReturnType<typeof resetState>;
  [ActionTypes.RESET_CONNECT_WITH_FACTSET_MODAL_STATE]: ReturnType<
    typeof resetConnectWithFactSetModalState
  >;
};

const createCRUDActions = function <TPayload>(duckParts) {
  type Action = { type: string; payload: TPayload & { operation: Operation } };
  const createAction = (payload: TPayload) =>
    duckParts.actionCreators.request({ operation: Operation.CREATE, ...payload }) as Action;

  const updateAction = (payload: TPayload) =>
    duckParts.actionCreators.request({ operation: Operation.UPDATE, ...payload }) as Action;

  return {
    actions: {
      create: createAction,
      update: updateAction,
    },
  };
};

export const fetchFirm = fetchFirmDuckParts.actionCreators.request;
type fetchFirmAction = ReturnType<typeof fetchFirm>;

export const fetchFirmHistory = fetchFirmHistoryDuckParts.actionCreators.request;
type fetchFirmHistoryAction = ReturnType<typeof fetchFirmHistory>;

export const fetchFirmVersionDiff = fetchFirmVersionDiffDuckParts.actionCreators.request;
type fetchFirmVersionDiffAction = ReturnType<typeof fetchFirmVersionDiff>;

export const updateFirmDetails = updateFirmDetailsDuckParts.actionCreators.request;
type updateFirmDetailsAction = ReturnType<typeof updateFirmDetails>;

const firmAddressCRUDActions = createCRUDActions<{
  firmId: UUID;
  address: FirmAddress;
}>(firmAddressesDuckParts);
export const createFirmAddress = firmAddressCRUDActions.actions.create;
export const updateFirmAddress = firmAddressCRUDActions.actions.update;
type createFirmAddressAction = ReturnType<typeof createFirmAddress>;
type updateFirmAddressAction = ReturnType<typeof updateFirmAddress>;

const firmNameCRUDActions = createCRUDActions<{
  firmId: UUID;
  name: FirmName;
}>(firmNamesDuckParts);
export const createFirmName = firmNameCRUDActions.actions.create;
export const updateFirmName = firmNameCRUDActions.actions.update;
type createFirmNameAction = ReturnType<typeof createFirmName>;
type updateFirmNameAction = ReturnType<typeof updateFirmName>;

const firmIdentifierCRUDActions = createCRUDActions<{
  firmId: UUID;
  identifier: FirmIdentifier;
}>(firmIdentifiersDuckParts);
export const createFirmIdentifier = firmIdentifierCRUDActions.actions.create;
export const updateFirmIdentifier = firmIdentifierCRUDActions.actions.update;
type createFirmIdentifierAction = ReturnType<typeof createFirmIdentifier>;
type updateFirmIdentifierAction = ReturnType<typeof updateFirmIdentifier>;

const firmContactCRUDActions = createCRUDActions<{
  firmId: UUID;
  contact: FirmContact;
}>(firmContactsDuckParts);
export const createFirmContact = firmContactCRUDActions.actions.create;
export const updateFirmContact = firmContactCRUDActions.actions.update;
type createFirmContactAction = ReturnType<typeof createFirmContact>;
type updateFirmContactAction = ReturnType<typeof updateFirmContact>;

const firmSectorCRUDActions = createCRUDActions<{
  firmId: UUID;
  sector: FirmSector;
}>(firmSectorsDuckParts);
export const createFirmSector = firmSectorCRUDActions.actions.create;
export const updateFirmSector = firmSectorCRUDActions.actions.update;
type createFirmSectorAction = ReturnType<typeof createFirmSector>;
type updateFirmSectorAction = ReturnType<typeof updateFirmSector>;

const firmRoleCRUDActions = createCRUDActions<{
  firmId: UUID;
  role: FirmRole;
}>(firmRolesDuckParts);
export const createFirmRole = firmRoleCRUDActions.actions.create;
export const updateFirmRole = firmRoleCRUDActions.actions.update;
type createFirmRoleAction = ReturnType<typeof createFirmRole>;
type updateFirmRoleAction = ReturnType<typeof updateFirmRole>;

export const setFirmDisplayName = firmDisplayNameDuckParts.actionCreators.request;
type setFirmDisplayNameAction = ReturnType<typeof setFirmDisplayName>;

export const setFirmMarketingName = firmMarketingNameDuckParts.actionCreators.request;
type setFirmMarketingNameAction = ReturnType<typeof setFirmMarketingName>;

export const setFirmFinraName = firmFinraNameDuckParts.actionCreators.request;
type setFirmFinraNameAction = ReturnType<typeof setFirmFinraName>;

export const fetchFirmDescendants = fetchFirmDescendantsDuckParts.actionCreators.request;
type fetchFirmDescendantsAction = ReturnType<typeof fetchFirmDescendants>;

export const setFirmParent = setFirmParentDuckParts.actionCreators.request;
type setFirmParentAction = ReturnType<typeof setFirmParent>;

export const fetchFactSetFirmHierarchy = fetchFactSetFirmHierarchyDuckParts.actionCreators.request;
type fetchFactSetFirmHierarchyAction = ReturnType<typeof fetchFactSetFirmHierarchy>;

export const getConnectFactSetFirmWithCMGFirmPreviewDiff =
  getConnectFactSetFirmWithCMGFirmPreviewDiffDuckParts.actionCreators.request;
type getConnectFactSetFirmWithCMGFirmPreviewDiffAction = ReturnType<
  typeof getConnectFactSetFirmWithCMGFirmPreviewDiff
>;

export const connectFactSetFirmWithCMGFirm =
  connectFactSetFirmWithCMGFirmDuckParts.actionCreators.request;
type connectFactSetFirmWithCMGFirmAction = ReturnType<typeof connectFactSetFirmWithCMGFirm>;

export const getDisconnectFactSetFirmFromCMGFirmPreviewDiff =
  getDisconnectFactSetFirmFromCMGFirmPreviewDiffDuckParts.actionCreators.request;
type getDisconnectFactSetFirmFromCMGFirmPreviewDiffAction = ReturnType<
  typeof getDisconnectFactSetFirmFromCMGFirmPreviewDiff
>;

export const disconnectFactSetFirmFromCMGFirm =
  disconnectFactSetFirmFromCMGFirmDuckParts.actionCreators.request;
type disconnectFactSetFirmFromCMGFirmAction = ReturnType<typeof disconnectFactSetFirmFromCMGFirm>;

/**
 * REDUCERS
 */
export const initialState = {
  firm: fetchFirmDuckParts.initialState,
  firmParent: setFirmParentDuckParts.initialState,
  firmDescendants: fetchFirmDescendantsDuckParts.initialState,
  firmHistory: fetchFirmHistoryDuckParts.initialState,
  firmVersionDiff: fetchFirmVersionDiffDuckParts.initialState,
  details: updateFirmDetailsDuckParts.initialState,
  addresses: firmAddressesDuckParts.initialState,
  names: firmNamesDuckParts.initialState,
  displayName: firmDisplayNameDuckParts.initialState,
  marketingName: firmMarketingNameDuckParts.initialState,
  identifiers: firmIdentifiersDuckParts.initialState,
  contacts: firmContactsDuckParts.initialState,
  sectors: firmSectorsDuckParts.initialState,
  roles: firmRolesDuckParts.initialState,
  factSetFirmHierarchy: fetchFactSetFirmHierarchyDuckParts.initialState,
  connectFactSetFirmPreviewDiff: getConnectFactSetFirmWithCMGFirmPreviewDiffDuckParts.initialState,
  connectFactSetFirm: connectFactSetFirmWithCMGFirmDuckParts.initialState,
  disconnectFactSetFirmPreviewDiff:
    getDisconnectFactSetFirmFromCMGFirmPreviewDiffDuckParts.initialState,
  disconnectFactSetFirm: disconnectFactSetFirmFromCMGFirmDuckParts.initialState,
  updatingRecordIds: [],
  editingRecordIds: [],
};

export type ReducerState = {
  firm: typeof fetchFirmDuckParts.initialState;
  firmParent: typeof setFirmParentDuckParts.initialState;
  firmDescendants: typeof fetchFirmDescendantsDuckParts.initialState;
  firmHistory: typeof fetchFirmHistoryDuckParts.initialState;
  firmVersionDiff: typeof fetchFirmVersionDiffDuckParts.initialState;
  details: typeof updateFirmDetailsDuckParts.initialState;
  addresses: typeof firmAddressesDuckParts.initialState;
  names: typeof firmNamesDuckParts.initialState;
  displayName: typeof firmDisplayNameDuckParts.initialState;
  marketingName: typeof firmMarketingNameDuckParts.initialState;
  identifiers: typeof firmIdentifiersDuckParts.initialState;
  contacts: typeof firmContactsDuckParts.initialState;
  sectors: typeof firmSectorsDuckParts.initialState;
  roles: typeof firmRolesDuckParts.initialState;
  factSetFirmHierarchy: typeof fetchFactSetFirmHierarchyDuckParts.initialState;
  connectFactSetFirmPreviewDiff: typeof getConnectFactSetFirmWithCMGFirmPreviewDiffDuckParts.initialState;
  connectFactSetFirm: typeof connectFactSetFirmWithCMGFirmDuckParts.initialState;
  disconnectFactSetFirmPreviewDiff: typeof getDisconnectFactSetFirmFromCMGFirmPreviewDiffDuckParts.initialState;
  disconnectFactSetFirm: typeof disconnectFactSetFirmFromCMGFirmDuckParts.initialState;
  updatingRecordIds: UUID[];
  editingRecordIds: UUID[];
};

export const updatingRecordIdsReducer = createReducer<ReducerState['updatingRecordIds'], Actions>(
  initialState.updatingRecordIds,
  {
    [ActionTypes.RECORD_UPDATE_REQUEST_START]: (
      state: ReducerState['updatingRecordIds'],
      action: ReturnType<typeof recordUpdateRequestStart>
    ) => [...state, action.payload.recordId],
    [ActionTypes.RECORD_UPDATE_REQUEST_COMPLETE]: (
      state: ReducerState['updatingRecordIds'],
      action: ReturnType<typeof recordUpdateRequestComplete>
    ) => state.filter(recordId => recordId !== action.payload.recordId),
  }
);

export const editingRecordIdsReducer = createReducer<ReducerState['editingRecordIds'], Actions>(
  initialState.editingRecordIds,
  {
    [ActionTypes.EDIT_RECORD]: (state, { payload }) => [...state, payload.recordId],
    [ActionTypes.CANCEL_EDIT_RECORD]: (state, { payload }) =>
      state.filter(editingRecord => !isEqual(editingRecord, payload.recordId)),
  }
);

// This reducer acts on the entire state of this duck. Has access to duck state and must return duck state.
const crossSliceReducer = reduxUtil.createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_STATE]: () => ({ ...initialState }),
  [ActionTypes.RESET_CONNECT_WITH_FACTSET_MODAL_STATE]: state => ({
    ...state,
    factSetFirmHierarchy: fetchFactSetFirmHierarchyDuckParts.initialState,
    connectFactSetFirmPreviewDiff:
      getConnectFactSetFirmWithCMGFirmPreviewDiffDuckParts.initialState,
  }),
});

const combinedReducers = combineReducers<ReducerState>({
  firm: fetchFirmDuckParts.reducer,
  firmParent: setFirmParentDuckParts.reducer,
  firmDescendants: fetchFirmDescendantsDuckParts.reducer,
  firmHistory: fetchFirmHistoryDuckParts.reducer,
  firmVersionDiff: fetchFirmVersionDiffDuckParts.reducer,
  details: updateFirmDetailsDuckParts.reducer,
  addresses: firmAddressesDuckParts.reducer,
  names: firmNamesDuckParts.reducer,
  displayName: firmDisplayNameDuckParts.reducer,
  marketingName: firmMarketingNameDuckParts.reducer,
  identifiers: firmIdentifiersDuckParts.reducer,
  contacts: firmContactsDuckParts.reducer,
  sectors: firmSectorsDuckParts.reducer,
  roles: firmRolesDuckParts.reducer,
  factSetFirmHierarchy: fetchFactSetFirmHierarchyDuckParts.reducer,
  connectFactSetFirmPreviewDiff: getConnectFactSetFirmWithCMGFirmPreviewDiffDuckParts.reducer,
  connectFactSetFirm: connectFactSetFirmWithCMGFirmDuckParts.reducer,
  disconnectFactSetFirmPreviewDiff: getDisconnectFactSetFirmFromCMGFirmPreviewDiffDuckParts.reducer,
  disconnectFactSetFirm: disconnectFactSetFirmFromCMGFirmDuckParts.reducer,
  updatingRecordIds: updatingRecordIdsReducer,
  editingRecordIds: editingRecordIdsReducer,
});

// Combines our individual slice reducers and the cross slice reducer.
export default function duckReducer(state: ReducerState = initialState, action: AnyAction) {
  const intermediateState = combinedReducers(state, action);
  return crossSliceReducer(intermediateState, action);
}

/**
 * SELECTORS
 */

const selectState = (state: RootState) => state.rolodexFirmDetail;

const firmSelectors = fetchFirmDuckParts.makeSelectors(state => selectState(state).firm);
export const selectFirmLoading = firmSelectors.selectLoading;
export const selectFirmError = firmSelectors.selectError;
export const selectFirm = createSelector(firmSelectors.selectData, data => {
  return {
    id: data ? data.firm.id : null,
    key: data ? data.firm.key : null,
    isFactSetConnected: data ? data.firm.isFactSetConnected : false,
    isFirmMappedToCmgAccount: data ? data.firm.isFirmMappedToCmgAccount : false,
    relations: data ? data.firm.relations : null,
    firmType: data ? data.firm.firmType : null,
    linkFrequencyData: data ? data.firm.linkFrequencyData : null,
    isOnPlatform: data?.firm.isOnPlatform ?? null,
  };
});

const firmHistorySelectors = fetchFirmHistoryDuckParts.makeSelectors(
  state => selectState(state).firmHistory
);
export const selectFirmHistoryLoading = firmHistorySelectors.selectLoading;
export const selectFirmHistory = createSelector(firmHistorySelectors.selectData, data =>
  data ? data.versions : []
);

const firmVersionDiffSelectors = fetchFirmVersionDiffDuckParts.makeSelectors(
  state => selectState(state).firmVersionDiff
);
export const selectFirmVersionDiffLoading = firmVersionDiffSelectors.selectLoading;
export const selectFirmVersionDiff = createSelector(firmVersionDiffSelectors.selectData, data =>
  data ? data : null
);

const firmDetailsSelectors = updateFirmDetailsDuckParts.makeSelectors(
  state => selectState(state).details
);
export const selectFirmDetailsLoading = firmDetailsSelectors.selectLoading;
export const selectFirmDetailsError = firmDetailsSelectors.selectError;
export const selectFirmDetailsDto = firmDetailsSelectors.selectData;
export const selectFirmDetails = createSelector(selectFirmDetailsDto, data =>
  data ? data.details : null
);

const firmAddressSelectors = firmAddressesDuckParts.makeSelectors(
  state => selectState(state).addresses
);
export const selectFirmAddressesLoading = firmAddressSelectors.selectLoading;
export const selectFirmAddressesError = firmAddressSelectors.selectError;
export const selectFirmAddresses = createSelector(firmAddressSelectors.selectData, data =>
  data ? data.addresses : []
);

const firmNameSelectors = firmNamesDuckParts.makeSelectors(state => selectState(state).names);
export const selectFirmNamesLoading = firmNameSelectors.selectLoading;
export const selectFirmNamesError = firmNameSelectors.selectError;
export const selectFirmNames = createSelector(firmNameSelectors.selectData, data =>
  data ? data.names : []
);
export const selectFirmDisplayName = createSelector(selectFirmNames, names =>
  names.find(name => name.isDisplayName)
);
export const selectFirmMarketingName = createSelector(selectFirmNames, names =>
  names.find(name => name.isMarketingName)
);

const firmIdentifierSelectors = firmIdentifiersDuckParts.makeSelectors(
  state => selectState(state).identifiers
);
export const selectFirmIdentifiersLoading = firmIdentifierSelectors.selectLoading;
export const selectFirmIdentifiersError = firmIdentifierSelectors.selectError;
export const selectFirmIdentifiers = createSelector(firmIdentifierSelectors.selectData, data =>
  data ? data.identifiers : []
);

const firmContactSelectors = firmContactsDuckParts.makeSelectors(
  state => selectState(state).contacts
);
export const selectFirmContactsLoading = firmContactSelectors.selectLoading;
export const selectFirmContactsError = firmContactSelectors.selectError;
export const selectFirmContacts = createSelector(firmContactSelectors.selectData, data =>
  data ? data.contacts : []
);

const firmSectorSelectors = firmSectorsDuckParts.makeSelectors(state => selectState(state).sectors);
export const selectFirmSectorsLoading = firmSectorSelectors.selectLoading;
export const selectFirmSectorsError = firmSectorSelectors.selectError;
export const selectFirmSectors = createSelector(firmSectorSelectors.selectData, data =>
  data ? data.sectors : []
);

const firmRoleSelectors = firmRolesDuckParts.makeSelectors(state => selectState(state).roles);
export const selectFirmRolesLoading = firmRoleSelectors.selectLoading;
export const selectFirmRolesError = firmRoleSelectors.selectError;
export const selectFirmRoles = createSelector(firmRoleSelectors.selectData, data =>
  data ? data.roles : []
);

export const selectUpdatingRecordIds = createSelector(
  selectState,
  state => state.updatingRecordIds
);

export const selectEditingRecordIds = createSelector(selectState, state => state.editingRecordIds);

export const selectIsEditingRecords = createSelector(
  selectEditingRecordIds,
  editingRecordIds => editingRecordIds.length > 0
);

const firmDisplayNameSelectors = firmDisplayNameDuckParts.makeSelectors(
  state => selectState(state).displayName
);
export const selectFirmDisplayNameLoading = firmDisplayNameSelectors.selectLoading;
export const selectFirmDisplayNameError = firmDisplayNameSelectors.selectError;

const firmMarketingNameSelectors = firmMarketingNameDuckParts.makeSelectors(
  state => selectState(state).marketingName
);
export const selectFirmMarketingNameLoading = firmMarketingNameSelectors.selectLoading;
export const selectFirmMarketingNameError = firmMarketingNameSelectors.selectError;

const firmDescendantSelectors = fetchFirmDescendantsDuckParts.makeSelectors(
  state => selectState(state).firmDescendants
);
export const selectFirmDescendantsLoading = firmDescendantSelectors.selectLoading;
export const selectFirmDescendantsError = firmDescendantSelectors.selectError;
export const selectFirmDescendants = createSelector(firmDescendantSelectors.selectData, data =>
  data ? data.descendants : []
);

const firmParentSelectors = setFirmParentDuckParts.makeSelectors(
  state => selectState(state).firmParent
);
export const selectFirmParentLoading = firmParentSelectors.selectLoading;
export const selectFirmParentError = firmParentSelectors.selectError;

const factSetFirmHierarchySelectors = fetchFactSetFirmHierarchyDuckParts.makeSelectors(
  state => selectState(state).factSetFirmHierarchy
);
export const selectFactSetFirmHierarchyLoading = factSetFirmHierarchySelectors.selectLoading;
export const selectFactSetFirmHierarchyError = factSetFirmHierarchySelectors.selectError;
export const selectFactSetFirmHierarchy = createSelector(
  factSetFirmHierarchySelectors.selectData,
  data => (data ? data.factSetFirmHierarchy : null)
);

const connectFactSetFirmPreviewDiffSelectors =
  getConnectFactSetFirmWithCMGFirmPreviewDiffDuckParts.makeSelectors(
    state => selectState(state).connectFactSetFirmPreviewDiff
  );
export const selectConnectFactSetFirmPreviewDiffLoading =
  connectFactSetFirmPreviewDiffSelectors.selectLoading;
export const selectConnectFactSetFirmPreviewDiffError =
  connectFactSetFirmPreviewDiffSelectors.selectError;
export const selectConnectFactSetFirmPreviewDiff = createSelector(
  connectFactSetFirmPreviewDiffSelectors.selectData,
  data => data
);

const connectFactSetFirmSelectors = connectFactSetFirmWithCMGFirmDuckParts.makeSelectors(
  state => selectState(state).connectFactSetFirm
);
export const selectConnectFactSetFirmLoading = connectFactSetFirmSelectors.selectLoading;
export const selectConnectFactSetFirmError = connectFactSetFirmSelectors.selectError;

const disconnectFirmPreviewDiffSelectors =
  getDisconnectFactSetFirmFromCMGFirmPreviewDiffDuckParts.makeSelectors(
    state => selectState(state).disconnectFactSetFirmPreviewDiff
  );
export const selectDisconnectFactSetFirmPreviewDiffLoading =
  disconnectFirmPreviewDiffSelectors.selectLoading;
export const selectDisconnectFactSetFirmPreviewDiffError =
  disconnectFirmPreviewDiffSelectors.selectError;
export const selectDisconnectFactSetFirmPreviewDiff = createSelector(
  disconnectFirmPreviewDiffSelectors.selectData,
  data => data
);

const disconnectFactSetFirmSelectors = disconnectFactSetFirmFromCMGFirmDuckParts.makeSelectors(
  state => selectState(state).disconnectFactSetFirm
);
export const selectDisconnectFromFactSetLoading = disconnectFactSetFirmSelectors.selectLoading;
export const selectDisconnectFromFactSetError = disconnectFactSetFirmSelectors.selectError;

/**
 * SAGAS
 */

export function* fetchFirmSaga({ payload }: fetchFirmAction): SagaIterator {
  const response: GetFirmResponse = yield call(rolodexApiClient.getFirm, payload.id);

  if (response.ok) {
    const firm = response.data;
    const firmDetails: AdminFirmBasicInformationRead = {
      ...firm.details,
      firmCategoryType: firm.firmType,
    };
    yield put(updateFirmDetailsDuckParts.actionCreators.success({ details: firmDetails }));
    yield put(firmAddressesDuckParts.actionCreators.success({ addresses: firm.addresses || [] }));
    yield put(firmNamesDuckParts.actionCreators.success({ names: firm.names || [] }));
    yield put(
      firmIdentifiersDuckParts.actionCreators.success({ identifiers: firm.identifiers || [] })
    );
    yield put(firmContactsDuckParts.actionCreators.success({ contacts: firm.contacts || [] }));
    yield put(firmSectorsDuckParts.actionCreators.success({ sectors: firm.sectors || [] }));
    yield put(firmRolesDuckParts.actionCreators.success({ roles: firm.roles || [] }));
    yield put(fetchFirmDuckParts.actionCreators.success({ firm }));
  } else {
    yield put(fetchFirmDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* fetchFirmHistorySaga({ payload }: fetchFirmHistoryAction): SagaIterator {
  yield put(openFirmHistoryModal());

  const response: GetFirmVersionHistoryResponse = yield call(
    rolodexApiClient.getFirmVersionHistory,
    payload.id
  );

  if (response.ok) {
    const versions = response.data;
    yield put(fetchFirmHistoryDuckParts.actionCreators.success({ versions }));
  } else {
    yield put(fetchFirmHistoryDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* fetchFirmVersionDiffSaga({ payload }: fetchFirmVersionDiffAction): SagaIterator {
  yield put(openFirmVersionDiffModal());

  const { id, fromVersion, toVersion } = payload;

  const fromVersionResponse: GetFirmVersionResponse = yield call(
    rolodexApiClient.getFirmVersion,
    id,
    fromVersion
  );
  const toVersionResponse: GetFirmVersionResponse = yield call(
    rolodexApiClient.getFirmVersion,
    id,
    toVersion
  );

  if (fromVersionResponse.ok || toVersionResponse.ok) {
    yield put(
      fetchFirmVersionDiffDuckParts.actionCreators.success({
        from: fromVersionResponse.data as Firm,
        to: toVersionResponse.data as Firm,
      })
    );
  } else {
    yield put(
      fetchFirmVersionDiffDuckParts.actionCreators.failure(
        fromVersionResponse.data.error || toVersionResponse.data.error
      )
    );
  }
}

export function* updateFirmDetailsSaga({ payload }: updateFirmDetailsAction): SagaIterator {
  const currentDetails: AdminFirmBasicInformationRead = yield select(selectFirmDetails);

  const response: UpdateFirmDetailsResponse = yield call(
    rolodexApiClient.updateFirmDetails,
    payload
  );

  if (response.ok) {
    // The entity status and status fields on firm details entities
    // are toggled via toggle buttons - they are outside of the details form.
    // If these fields match in the current state details and in the
    // payload details objects, we can assume that details are being
    // updated via the details form.
    const isEditingDetailsForm =
      currentDetails.recordStatus === payload.body.recordStatus &&
      currentDetails.status === payload.body.status;

    if (isEditingDetailsForm) {
      yield put(cancelEditRecord({ recordId: FirmDetailSection.DETAILS }));
    }
    yield put(updateFirmDetailsDuckParts.actionCreators.success({ details: response.data }));
  } else {
    yield put(updateFirmDetailsDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* setFirmDisplayNameSaga({ payload }: setFirmDisplayNameAction): SagaIterator {
  const response: SetFirmDisplayNameResponse = yield call(rolodexApiClient.setDisplayName, payload);

  if (response.ok) {
    const names = yield select(selectFirmNames);

    yield put(
      firmNamesDuckParts.actionCreators.success({
        names: names.map(name => ({
          ...name,
          isDisplayName: name.id === payload.nameId,
        })),
        updatedId: payload.nameId,
      })
    );
    yield put(firmDisplayNameDuckParts.actionCreators.success({}));
    yield put(fetchFirmHierarchy({ firmId: payload.firmId }));
  } else {
    yield put(firmDisplayNameDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* setFirmMarketingNameSaga({ payload }: setFirmMarketingNameAction): SagaIterator {
  const response: SetFirmMarketingNameResponse = yield call(
    rolodexApiClient.setMarketingName,
    payload
  );

  if (response.ok) {
    const names = yield select(selectFirmNames);

    yield put(
      firmNamesDuckParts.actionCreators.success({
        names: names.map(name => ({
          ...name,
          isMarketingName: name.id === payload.nameId && payload.isMarketingName,
        })),
        updatedId: payload.nameId,
      })
    );
    yield put(firmMarketingNameDuckParts.actionCreators.success({}));
    yield put(fetchFirmHierarchy({ firmId: payload.firmId }));
  } else {
    yield put(firmMarketingNameDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* setFirmFinraNameSaga({ payload }: setFirmFinraNameAction): SagaIterator {
  const response: SetFirmFinraNameResponse = yield call(rolodexApiClient.setFinraName, payload);

  if (response.ok) {
    const names = yield select(selectFirmNames);

    yield put(
      firmNamesDuckParts.actionCreators.success({
        names: names.map(name => ({
          ...name,
          isFinraName: name.id === payload.nameId && payload.isFinraName,
        })),
        updatedId: payload.nameId,
      })
    );
    yield put(firmFinraNameDuckParts.actionCreators.success({}));
    yield put(fetchFirmHierarchy({ firmId: payload.firmId }));
  } else {
    yield put(firmFinraNameDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* fetchFirmDescendantsSaga({ payload }: fetchFirmDescendantsAction): SagaIterator {
  const response: GetFirmHierarchyResponse = yield call(rolodexApiClient.getFirmHierarchy, {
    firmId: payload.firmId,
    traversalType: TraversalType.DESCENDANT,
  });

  if (response.ok) {
    yield put(fetchFirmDescendantsDuckParts.actionCreators.success({ descendants: response.data }));
  } else {
    yield put(fetchFirmDescendantsDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* setFirmParentSaga({ payload }: setFirmParentAction): SagaIterator {
  const response: SetFirmParentResponse = yield call(rolodexApiClient.setFirmParent, payload);

  if (response.ok) {
    yield put(fetchFirmDuckParts.actionCreators.request({ id: payload.firmId }));
    yield put(setFirmParentDuckParts.actionCreators.success({}));
  } else {
    yield put(setFirmParentDuckParts.actionCreators.failure(response.data.error));
  }
}

function* sendCreateFirmAddressRequest(payload: { firmId: UUID; address: FirmAddress }) {
  yield put(recordUpdateRequestStart({ recordId: NewRecordTempId[FirmDetailSection.ADDRESSES] }));

  const response: CreateFirmAddressResponse = yield call(
    rolodexApiClient.createFirmAddress,
    payload
  );

  yield put(
    recordUpdateRequestComplete({ recordId: NewRecordTempId[FirmDetailSection.ADDRESSES] })
  );

  if (response.ok) {
    yield put(cancelEditRecord({ recordId: NewRecordTempId[FirmDetailSection.ADDRESSES] }));

    const addresses = yield select(selectFirmAddresses);
    yield put(
      firmAddressesDuckParts.actionCreators.success({
        addresses: [...addresses, response.data],
        updatedId: payload.address.id,
      })
    );
  } else {
    yield put(firmAddressesDuckParts.actionCreators.failure(response.data.error));
  }
}

function* sendUpdateFirmAddressRequest(payload: { firmId: UUID; address: FirmAddress }) {
  yield put(recordUpdateRequestStart({ recordId: payload.address.id }));
  const response: UpdateFirmAddressResponse = yield call(
    rolodexApiClient.updateFirmAddress,
    payload
  );

  yield put(recordUpdateRequestComplete({ recordId: payload.address.id }));
  if (response.ok) {
    const addresses = yield select(selectFirmAddresses);
    yield put(cancelEditRecord({ recordId: payload.address.id }));
    yield put(
      firmAddressesDuckParts.actionCreators.success({
        updatedId: payload.address.id,
        addresses: addresses.map(address =>
          address.id === payload.address.id ? response.data : address
        ),
      })
    );
  } else {
    yield put(firmAddressesDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* firmAddressSaga({ payload }: createFirmAddressAction | updateFirmAddressAction) {
  const { operation } = payload;

  switch (operation) {
    case Operation.CREATE:
      yield sendCreateFirmAddressRequest(payload);
      break;
    case Operation.UPDATE:
      yield sendUpdateFirmAddressRequest(payload);
      break;
  }
}

function* sendCreateFirmNameRequest(payload: { firmId: UUID; name: FirmName }) {
  yield put(recordUpdateRequestStart({ recordId: NewRecordTempId[FirmDetailSection.NAMES] }));

  const response: CreateFirmNameResponse = yield call(rolodexApiClient.createFirmName, payload);

  yield put(recordUpdateRequestComplete({ recordId: NewRecordTempId[FirmDetailSection.NAMES] }));

  if (response.ok) {
    yield put(cancelEditRecord({ recordId: NewRecordTempId[FirmDetailSection.NAMES] }));

    const names = yield select(selectFirmNames);
    yield put(
      firmNamesDuckParts.actionCreators.success({
        names: [...names, response.data],
        updatedId: payload.name.id,
      })
    );
  } else {
    yield put(firmNamesDuckParts.actionCreators.failure(response.data.error));
  }
}

function* sendUpdateFirmNameRequest(payload: { firmId: UUID; name: FirmName }) {
  yield put(recordUpdateRequestStart({ recordId: payload.name.id }));
  const response: UpdateFirmNameResponse = yield call(rolodexApiClient.updateFirmName, payload);

  yield put(recordUpdateRequestComplete({ recordId: payload.name.id }));
  if (response.ok) {
    const names = yield select(selectFirmNames);
    yield put(cancelEditRecord({ recordId: payload.name.id }));
    yield put(
      firmNamesDuckParts.actionCreators.success({
        updatedId: payload.name.id,
        names: names.map(name => (name.id === payload.name.id ? response.data : name)),
      })
    );
  } else {
    yield put(firmNamesDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* firmNamesSaga({ payload }: createFirmNameAction | updateFirmNameAction) {
  const { operation } = payload;

  switch (operation) {
    case Operation.CREATE:
      yield sendCreateFirmNameRequest(payload);
      break;
    case Operation.UPDATE:
      yield sendUpdateFirmNameRequest(payload);
      break;
  }
}

function* sendCreateFirmIdentifierRequest(payload: { firmId: UUID; identifier: FirmIdentifier }) {
  yield put(recordUpdateRequestStart({ recordId: NewRecordTempId[FirmDetailSection.IDENTIFIERS] }));

  const response: CreateFirmIdentifierResponse = yield call(
    rolodexApiClient.createFirmIdentifier,
    payload
  );

  yield put(
    recordUpdateRequestComplete({ recordId: NewRecordTempId[FirmDetailSection.IDENTIFIERS] })
  );
  if (response.ok) {
    yield put(cancelEditRecord({ recordId: NewRecordTempId[FirmDetailSection.IDENTIFIERS] }));

    const identifiers = yield select(selectFirmIdentifiers);
    yield put(
      firmIdentifiersDuckParts.actionCreators.success({
        updatedId: payload.identifier.id,
        identifiers: [...identifiers, response.data],
      })
    );
  } else {
    yield put(firmIdentifiersDuckParts.actionCreators.failure(response.data.error));
  }
}

function* sendUpdateFirmIdentifierRequest(payload: { firmId: UUID; identifier: FirmIdentifier }) {
  yield put(recordUpdateRequestStart({ recordId: payload.identifier.id }));
  const response: UpdateFirmIdentifierResponse = yield call(
    rolodexApiClient.updateFirmIdentifier,
    payload
  );

  yield put(recordUpdateRequestComplete({ recordId: payload.identifier.id }));
  if (response.ok) {
    const identifiers = yield select(selectFirmIdentifiers);
    yield put(cancelEditRecord({ recordId: payload.identifier.id }));
    yield put(
      firmIdentifiersDuckParts.actionCreators.success({
        identifiers: identifiers.map(identifier =>
          identifier.id === payload.identifier.id ? response.data : identifier
        ),
        updatedId: payload.identifier.id,
      })
    );
  } else {
    yield put(firmIdentifiersDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* firmIdentifiersSaga({
  payload,
}: createFirmIdentifierAction | updateFirmIdentifierAction) {
  const { operation } = payload;

  switch (operation) {
    case Operation.CREATE:
      yield sendCreateFirmIdentifierRequest(payload);
      break;
    case Operation.UPDATE:
      yield sendUpdateFirmIdentifierRequest(payload);
      break;
  }
}

function* sendCreateFirmContactRequest(payload: { firmId: UUID; contact: FirmContact }) {
  yield put(recordUpdateRequestStart({ recordId: NewRecordTempId[FirmDetailSection.CONTACTS] }));

  const response: CreateFirmContactResponse = yield call(
    rolodexApiClient.createFirmContact,
    payload
  );

  yield put(recordUpdateRequestComplete({ recordId: NewRecordTempId[FirmDetailSection.CONTACTS] }));

  if (response.ok) {
    yield put(cancelEditRecord({ recordId: NewRecordTempId[FirmDetailSection.CONTACTS] }));

    const contacts = yield select(selectFirmContacts);
    yield put(
      firmContactsDuckParts.actionCreators.success({
        updatedId: payload.contact.id,
        contacts: [...contacts, response.data],
      })
    );
  } else {
    yield put(firmContactsDuckParts.actionCreators.failure(response.data.error));
  }
}

function* sendUpdateFirmContactRequest(payload: { firmId: UUID; contact: FirmContact }) {
  yield put(recordUpdateRequestStart({ recordId: payload.contact.id }));
  const response: UpdateFirmContactResponse = yield call(
    rolodexApiClient.updateFirmContact,
    payload
  );

  yield put(recordUpdateRequestComplete({ recordId: payload.contact.id }));
  if (response.ok) {
    const contacts = yield select(selectFirmContacts);
    yield put(cancelEditRecord({ recordId: payload.contact.id }));
    yield put(
      firmContactsDuckParts.actionCreators.success({
        contacts: contacts.map(contact =>
          contact.id === payload.contact.id ? response.data : contact
        ),
        updatedId: payload.contact.id,
      })
    );
  } else {
    yield put(firmContactsDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* firmContactsSaga({ payload }: createFirmContactAction | updateFirmContactAction) {
  const { operation } = payload;

  switch (operation) {
    case Operation.CREATE:
      yield sendCreateFirmContactRequest(payload);
      break;
    case Operation.UPDATE:
      yield sendUpdateFirmContactRequest(payload);
      break;
  }
}

function* sendCreateFirmSectorRequest(payload: { firmId: UUID; sector: FirmSector }) {
  yield put(recordUpdateRequestStart({ recordId: NewRecordTempId[FirmDetailSection.SECTORS] }));

  const response: CreateFirmSectorResponse = yield call(rolodexApiClient.createFirmSector, payload);

  yield put(recordUpdateRequestComplete({ recordId: NewRecordTempId[FirmDetailSection.SECTORS] }));

  if (response.ok) {
    yield put(cancelEditRecord({ recordId: NewRecordTempId[FirmDetailSection.SECTORS] }));

    const sectors = yield select(selectFirmSectors);
    yield put(
      firmSectorsDuckParts.actionCreators.success({
        updatedId: payload.sector.id,
        sectors: [...sectors, response.data],
      })
    );
  } else {
    yield put(firmSectorsDuckParts.actionCreators.failure(response.data.error));
  }
}

function* sendUpdateFirmSectorRequest(payload: { firmId: UUID; sector: FirmSector }) {
  yield put(recordUpdateRequestStart({ recordId: payload.sector.id }));
  const response: UpdateFirmSectorResponse = yield call(rolodexApiClient.updateFirmSector, payload);

  yield put(recordUpdateRequestComplete({ recordId: payload.sector.id }));
  if (response.ok) {
    const sectors = yield select(selectFirmSectors);
    yield put(cancelEditRecord({ recordId: payload.sector.id }));
    yield put(
      firmSectorsDuckParts.actionCreators.success({
        sectors: sectors.map(sector => (sector.id === payload.sector.id ? response.data : sector)),
        updatedId: payload.sector.id,
      })
    );
  } else {
    yield put(firmSectorsDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* firmSectorsSaga({ payload }: createFirmSectorAction | updateFirmSectorAction) {
  const { operation } = payload;

  switch (operation) {
    case Operation.CREATE:
      yield sendCreateFirmSectorRequest(payload);
      break;
    case Operation.UPDATE:
      yield sendUpdateFirmSectorRequest(payload);
      break;
  }
}
function* sendCreateFirmRoleRequest(payload: { firmId: UUID; role: FirmRole }) {
  yield put(recordUpdateRequestStart({ recordId: NewRecordTempId[FirmDetailSection.ROLES] }));

  const response: CreateFirmRoleResponse = yield call(rolodexApiClient.createFirmRole, payload);

  yield put(recordUpdateRequestComplete({ recordId: NewRecordTempId[FirmDetailSection.ROLES] }));

  if (response.ok) {
    yield put(cancelEditRecord({ recordId: NewRecordTempId[FirmDetailSection.ROLES] }));

    const roles = yield select(selectFirmRoles);
    yield put(
      firmRolesDuckParts.actionCreators.success({
        roles: [...roles, response.data],
        updatedId: payload.role.id,
      })
    );
  } else {
    yield put(firmRolesDuckParts.actionCreators.failure(response.data.error));
  }
}

function* sendUpdateFirmRoleRequest(payload: { firmId: UUID; role: FirmRole }) {
  yield put(recordUpdateRequestStart({ recordId: payload.role.id }));
  const response: UpdateFirmRoleResponse = yield call(rolodexApiClient.updateFirmRole, payload);

  yield put(recordUpdateRequestComplete({ recordId: payload.role.id }));
  if (response.ok) {
    const roles = yield select(selectFirmRoles);
    yield put(cancelEditRecord({ recordId: payload.role.id }));
    yield put(
      firmRolesDuckParts.actionCreators.success({
        roles: roles.map(role => (role.id === payload.role.id ? response.data : role)),
        updatedId: payload.role.id,
      })
    );
  } else {
    yield put(firmRolesDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* firmRolesSaga({ payload }: createFirmRoleAction | updateFirmRoleAction) {
  const { operation } = payload;

  switch (operation) {
    case Operation.CREATE:
      yield sendCreateFirmRoleRequest(payload);
      break;
    case Operation.UPDATE:
      yield sendUpdateFirmRoleRequest(payload);
      break;
  }
}

export function* fetchFactSetFirmHierarchySaga({
  payload,
}: fetchFactSetFirmHierarchyAction): SagaIterator {
  const response: GetFactSetFirmHierarchyResponse = yield call(
    rolodexApiClient.getFactSetFirmHierarchy,
    { firmId: payload.id }
  );

  if (response.ok) {
    yield put(
      fetchFactSetFirmHierarchyDuckParts.actionCreators.success({
        factSetFirmHierarchy: response.data,
      })
    );
  } else {
    yield put(fetchFactSetFirmHierarchyDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* getConnectFactSetFirmWithCMGFirmPreviewDiffSaga({
  payload,
}: getConnectFactSetFirmWithCMGFirmPreviewDiffAction): SagaIterator {
  const [diffFromResponse, diffToResponse]: [GetFirmResponse, ConnectFactSetFirmToCMGFirmResponse] =
    yield all([
      call(rolodexApiClient.getFirm, payload.firmId),
      call(rolodexApiClient.connectFactSetFirmWithCMGFirm, {
        cmgFirmId: payload.firmId,
        factSetFirmId: payload.factSetId,
        isDryRun: true,
      }),
    ]);

  if (diffFromResponse.ok && diffToResponse.ok) {
    yield put(
      getConnectFactSetFirmWithCMGFirmPreviewDiffDuckParts.actionCreators.success({
        from: diffFromResponse.data,
        to: diffToResponse.data,
      })
    );
  } else if (!diffFromResponse.ok) {
    yield put(
      getConnectFactSetFirmWithCMGFirmPreviewDiffDuckParts.actionCreators.failure(
        diffFromResponse.data.error
      )
    );
  } else if (!diffToResponse.ok) {
    yield put(
      getConnectFactSetFirmWithCMGFirmPreviewDiffDuckParts.actionCreators.failure(
        diffToResponse.data.error
      )
    );
  }
}

export function* connectFactSetFirmWithCMGFirmSaga({
  payload,
}: connectFactSetFirmWithCMGFirmAction): SagaIterator {
  const response: ConnectFactSetFirmToCMGFirmResponse = yield call(
    rolodexApiClient.connectFactSetFirmWithCMGFirm,
    {
      cmgFirmId: payload.cmgFirmId,
      factSetFirmId: payload.factSetFirmId,
      isDryRun: false,
    }
  );

  if (response.ok) {
    yield put(fetchFirmDuckParts.actionCreators.request({ id: payload.cmgFirmId }));
    yield put(
      connectFactSetFirmWithCMGFirmDuckParts.actionCreators.success({
        firm: response.data,
      })
    );
    yield put(closeConnectWithFactSetModal());
  } else {
    yield put(connectFactSetFirmWithCMGFirmDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* getDisconnectFactSetFirmFromCMGFirmPreviewDiffSaga({
  payload,
}: getDisconnectFactSetFirmFromCMGFirmPreviewDiffAction): SagaIterator {
  const [diffFromResponse, diffToResponse]: [
    GetFirmResponse,
    DisconnectFactSetFirmFromCMGFirmResponse
  ] = yield all([
    call(rolodexApiClient.getFirm, payload.firmId),
    call(rolodexApiClient.disconnectFactSetFirmFromCMGFirm, {
      firmId: payload.firmId,
      isDryRun: true,
    }),
  ]);

  if (diffFromResponse.ok && diffToResponse.ok) {
    yield put(
      getDisconnectFactSetFirmFromCMGFirmPreviewDiffDuckParts.actionCreators.success({
        from: diffFromResponse.data,
        to: diffToResponse.data,
      })
    );
  } else if (!diffFromResponse.ok) {
    yield put(
      getDisconnectFactSetFirmFromCMGFirmPreviewDiffDuckParts.actionCreators.failure(
        diffFromResponse.data.error
      )
    );
  } else if (!diffToResponse.ok) {
    yield put(
      getDisconnectFactSetFirmFromCMGFirmPreviewDiffDuckParts.actionCreators.failure(
        diffToResponse.data.error
      )
    );
  }
}

export function* disconnectFactSetFirmFromCMGFirmSaga({
  payload,
}: disconnectFactSetFirmFromCMGFirmAction): SagaIterator {
  const response: DisconnectFactSetFirmFromCMGFirmResponse = yield call(
    rolodexApiClient.disconnectFactSetFirmFromCMGFirm,
    {
      firmId: payload.firmId,
      isDryRun: false,
    }
  );

  if (response.ok) {
    yield put(fetchFirmDuckParts.actionCreators.request({ id: payload.firmId }));
    yield put(
      disconnectFactSetFirmFromCMGFirmDuckParts.actionCreators.success({
        firm: response.data,
      })
    );
    yield put(closeDisconnectWithFactSetModal());
  } else {
    yield put(
      disconnectFactSetFirmFromCMGFirmDuckParts.actionCreators.failure(response.data.error)
    );
  }
}

export function* rolodexDetailSaga() {
  yield takeLatest<fetchFirmAction>(fetchFirmDuckParts.actionTypes.REQUEST, fetchFirmSaga);
  yield takeLatest<fetchFirmHistoryAction>(
    fetchFirmHistoryDuckParts.actionTypes.REQUEST,
    fetchFirmHistorySaga
  );
  yield takeLatest<fetchFirmVersionDiffAction>(
    fetchFirmVersionDiffDuckParts.actionTypes.REQUEST,
    fetchFirmVersionDiffSaga
  );
  yield takeLatest<updateFirmDetailsAction>(
    updateFirmDetailsDuckParts.actionTypes.REQUEST,
    updateFirmDetailsSaga
  );
  yield takeLatest<createFirmAddressAction | updateFirmAddressAction>(
    firmAddressesDuckParts.actionTypes.REQUEST,
    firmAddressSaga
  );
  yield takeLatest<createFirmNameAction | updateFirmNameAction>(
    firmNamesDuckParts.actionTypes.REQUEST,
    firmNamesSaga
  );
  yield takeLatest<createFirmIdentifierAction | updateFirmIdentifierAction>(
    firmIdentifiersDuckParts.actionTypes.REQUEST,
    firmIdentifiersSaga
  );
  yield takeLatest<createFirmContactAction | updateFirmContactAction>(
    firmContactsDuckParts.actionTypes.REQUEST,
    firmContactsSaga
  );
  yield takeLatest<createFirmSectorAction | updateFirmSectorAction>(
    firmSectorsDuckParts.actionTypes.REQUEST,
    firmSectorsSaga
  );
  yield takeLatest<createFirmRoleAction | updateFirmRoleAction>(
    firmRolesDuckParts.actionTypes.REQUEST,
    firmRolesSaga
  );
  yield takeLatest<setFirmDisplayNameAction>(
    firmDisplayNameDuckParts.actionTypes.REQUEST,
    setFirmDisplayNameSaga
  );
  yield takeLatest<setFirmMarketingNameAction>(
    firmMarketingNameDuckParts.actionTypes.REQUEST,
    setFirmMarketingNameSaga
  );
  yield takeLatest<setFirmFinraNameAction>(
    firmFinraNameDuckParts.actionTypes.REQUEST,
    setFirmFinraNameSaga
  );
  yield takeLatest<fetchFirmDescendantsAction>(
    fetchFirmDescendantsDuckParts.actionTypes.REQUEST,
    fetchFirmDescendantsSaga
  );
  yield takeLatest<setFirmParentAction>(
    setFirmParentDuckParts.actionTypes.REQUEST,
    setFirmParentSaga
  );
  yield takeLatest<fetchFactSetFirmHierarchyAction>(
    fetchFactSetFirmHierarchyDuckParts.actionTypes.REQUEST,
    fetchFactSetFirmHierarchySaga
  );
  yield takeLatest<getConnectFactSetFirmWithCMGFirmPreviewDiffAction>(
    getConnectFactSetFirmWithCMGFirmPreviewDiffDuckParts.actionTypes.REQUEST,
    getConnectFactSetFirmWithCMGFirmPreviewDiffSaga
  );
  yield takeLatest<connectFactSetFirmWithCMGFirmAction>(
    connectFactSetFirmWithCMGFirmDuckParts.actionTypes.REQUEST,
    connectFactSetFirmWithCMGFirmSaga
  );
  yield takeLatest<getDisconnectFactSetFirmFromCMGFirmPreviewDiffAction>(
    getDisconnectFactSetFirmFromCMGFirmPreviewDiffDuckParts.actionTypes.REQUEST,
    getDisconnectFactSetFirmFromCMGFirmPreviewDiffSaga
  );
  yield takeLatest<disconnectFactSetFirmFromCMGFirmAction>(
    disconnectFactSetFirmFromCMGFirmDuckParts.actionTypes.REQUEST,
    disconnectFactSetFirmFromCMGFirmSaga
  );
}
