import { accountRoleListScreenSelector } from '@cmg/e2e-selectors';
import memoize from 'memoize-one';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import { useDocumentTitle } from '../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../../common/util/routeFactory';
import { PageGridSection } from '../../../../design-system/body-sections/PageGridSection';
import { IdentityPageContent } from '../../../../design-system/IdentityPageContent';
import { Role } from '../../../../types/domain/role/role';
import RolesList from '../../../account-detail/role-list/components/RolesList';
import {
  fetchAccountRolesList,
  selectError,
  selectLoading,
  selectRoles,
} from '../../../account-detail/role-list/ducks';
import { selectAccount } from '../../../account-detail/shared/ducks';

const mapStateToProps = (state, ownProps: RouteProps) => ({
  roles: selectRoles(state),
  loading: selectLoading(state),
  error: selectError(state),
  account: selectAccount(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchAccountRolesList,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RouteProps = RouteComponentProps;
export type Props = RouteProps & StateProps & DispatchProps;

export type AccountRolesFilters = {
  searchText?: string;
};

const applyNameFilter = memoize((roles: Role[], name?: string) => {
  return name ? roles.filter(role => role.name.toLowerCase().includes(name.toLowerCase())) : roles;
});

export const AccountRoleListRoute: FC<Props> = ({ roles, loading, error, actions, account }) => {
  const [filters, setFilters] = useState<AccountRolesFilters>({});

  useDocumentTitle(routeFactory.accountRoles.getDocumentTitle({ accountName: account?.name }));

  useEffect(() => {
    actions.fetchAccountRolesList({ page: 1, perPage: 100 });
  }, [actions]);

  const handleChangeFilter = (filters: AccountRolesFilters) => setFilters(filters);

  if (!account) {
    return null;
  }

  return (
    <div data-test-id={accountRoleListScreenSelector.testId}>
      <IdentityPageContent
        gridContent={
          <PageGridSection
            size="large"
            title="Roles List"
            content={
              <RolesList
                roles={applyNameFilter(roles, filters.searchText)}
                loading={loading}
                error={error}
                accountSubdomain={account.subdomain}
                filters={filters}
                onChangeFilters={handleChangeFilter}
              />
            }
          />
        }
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountRoleListRoute);
