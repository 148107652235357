import React from 'react';

import { FirmMatchRow } from '../../../../../../types/domain/firm-match/FirmMatch';

type Props = {
  /**
   * The row data - a Firm Match object.
   */
  data: FirmMatchRow;
};
/**
 * Renders progress bar for FirmMatch score
 */
export const LinkFrequencyCellRenderer: React.FC<Props> = ({ data: firmMatch }) => {
  if (
    firmMatch.linkFrequencyData === null ||
    firmMatch.linkFrequencyData.linkFrequency === null ||
    firmMatch.linkFrequencyData.linkFrequency === 0
  ) {
    return <div />;
  }

  return <React.Fragment>{firmMatch.linkFrequencyData.linkFrequency}</React.Fragment>;
};

export default LinkFrequencyCellRenderer;
