import { Grid } from '@cmg/design-system';
import { FC } from 'react';

export const FormLayout: FC = ({ children }) => {
  return (
    <Grid container px={1}>
      {children}
    </Grid>
  );
};
