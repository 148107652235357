import { useMemo } from 'react';

import { AccountPermission } from '../../../../../types/domain/account/account';

export type UsePermissionCellProps = Readonly<{
  readonly name: string;
  readonly selectedPermissions: { [key: string]: string };
  readonly accountPermissions: AccountPermission[];
}>;

export type UsePermissionCellResult = Readonly<
  | {
      readonly isHidden: false;
      readonly isChecked: boolean;
      readonly accessLevel: string;
      readonly entity: string;
      readonly label: string;
    }
  | {
      readonly isHidden: true;
    }
>;

export function usePermissionCell({
  name,
  selectedPermissions,
  accountPermissions,
}: UsePermissionCellProps): UsePermissionCellResult {
  // split name "read:permission_entity" into access level and entity name
  const [accessLevel, entity] = useMemo(() => name.split(':'), [name]);
  // get metadata for this permission string
  const permissionInfo = useMemo(
    () => accountPermissions.find(p => p.name === name),
    [accountPermissions, name]
  );

  const label = useMemo(
    () => `Permission - ${entity} - ${accessLevel?.length ? accessLevel : 'no access'}`,
    [accessLevel, entity]
  );

  // don't show permission if it doesn't exist in account's permissions, let no access remain
  if (accessLevel && !permissionInfo) {
    return {
      isHidden: true,
    };
  }

  // if no access, then make sure value is undefined | '', otherwise it should equal the whole permission name string
  const isChecked = accessLevel
    ? selectedPermissions[entity] === name
    : !selectedPermissions[entity];

  return {
    isHidden: false,
    isChecked,
    accessLevel,
    entity,
    label,
  };
}
