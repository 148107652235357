import { assets, LinkButton } from '@cmg/common';
import styled from 'styled-components/macro';

import barImage from '../../common/assets/cmg-bar.png';

export const SFormDescription = styled.span`
  font-size: ${({ theme }) => theme.text.size.m};
  font-weight: ${({ theme }) => theme.text.weight.light};
  white-space: pre-line;
`;

export const SFooter = styled.div`
  padding: 16px 0;
  text-align: left;
`;

export const SDiv = styled.div`
  display: flex;
  width: 100vw;
  font-family: 'Roboto Slab', serif;
  font-weight: ${({ theme }) => theme.text.weight.regular};
  color: rgba(255, 255, 255, 0.1);
`;

export const SLeft = styled.div`
  font-family: ${({ theme }) => theme.text.family.regular};
  width: 55vw;
  background-color: ${({ theme }) => theme.text.color.white};
`;
export const SRight = styled.div`
  width: 45vw;
  min-height: 100vh;
  background-image: url(${assets.terrainBgImage1600});
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  text-align: left;
  font-weight: ${({ theme }) => theme.text.weight.light};
  color: ${({ theme }) => theme.text.color.darkGray};
`;

export const SSectionTitle = styled.span`
  font-size: 32px;
  line-height: 39px;
  color: ${({ theme }) => theme.color.white};
`;

export const SSectionTitleDescription = styled.p`
  font-size: 18px;
  line-height: 26px;
  font-family: ${({ theme }) => theme.text.family.regular};
  color: ${({ theme }) => theme.color.white};
`;

export const SSectionSubtitle = styled.span`
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;
  color: ${({ theme }) => theme.text.color.link};
`;

export const SSectionSubtitleDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.text.family.regular};
  color: ${({ theme }) => theme.color.white};
`;

export const CMGBarImage = styled.img.attrs({
  src: barImage,
  alt: 'CMG bar image',
})`
  width: 126px;
  height: 4px;
  display: block;
  margin-top: 23px;
  margin-bottom: 38px;
`;

export const SLinkButton = styled(LinkButton)`
  padding: 0;
`;
