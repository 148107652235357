import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as yup from 'yup';

import { getFirmContactColumns } from '../../../../../common/components/layout/firm-record-panel/firmContactColumns';
import FirmRecordPanel from '../../../../../common/components/layout/firm-record-panel/GenericFirmRecordPanel';
import { UUID } from '../../../../../types/common';
import { FirmContact } from '../../../../../types/domain/firm/FirmContact';
import {
  cancelEditRecord,
  createFirmContact,
  editRecord,
  FirmDetailSection,
  selectEditingRecordIds,
  selectFirmContacts,
  selectFirmContactsError,
  selectUpdatingRecordIds,
  updateFirmContact,
} from '../../ducks';

const mapStateToProps = state => ({
  contacts: selectFirmContacts(state),
  error: selectFirmContactsError(state),
  editingRecordIds: selectEditingRecordIds(state),
  updatingRecordIds: selectUpdatingRecordIds(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createFirmContact,
      updateFirmContact,
      editRecord,
      cancelEditRecord,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  firmId: UUID;
};
export type Props = OwnProps & StateProps & DispatchProps;

export const FirmContactsValidationSchema = yup.object().shape({
  type: yup.string().nullable().required('A contact type is required'),
  value: yup.string().nullable().required('A contact value is required'),
  recordStatus: yup.string().nullable().required('A status type is required'),
});

export const FirmContactsRecordPanelComponent: React.FC<Props> = ({
  firmId,
  error,
  editingRecordIds,
  updatingRecordIds,
  contacts,
  actions,
}) => {
  return (
    <FirmRecordPanel<FirmContact>
      sectionId={FirmDetailSection.CONTACTS}
      title="Contact Information"
      addRecordButtonLabel="Add Contact"
      error={error}
      editingRecordIds={editingRecordIds}
      updatingRecordIds={updatingRecordIds}
      onCreate={contact => actions.createFirmContact({ firmId, contact })}
      onUpdate={contact => actions.updateFirmContact({ firmId, contact })}
      onEdit={recordId => actions.editRecord({ recordId })}
      onCancelEdit={recordId => actions.cancelEditRecord({ recordId })}
      columns={getFirmContactColumns()}
      rows={contacts}
      validationSchema={FirmContactsValidationSchema}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmContactsRecordPanelComponent);
