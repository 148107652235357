import { InvestorFirmAdminRead as CrmInvestorFirm } from '@capital-markets-gateway/api-client-rolodex';
import { DataGrid } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { UUID } from '../../../../../types/common';
import { FirmMatch, FirmMatchRow } from '../../../../../types/domain/firm-match/FirmMatch';
import { getFirmMatchFromCrmInvestorFirm } from './CrmFirmMatchList.model';
import { createColumns } from './CrmFirmMatchListGridColumns';

const SGrid = styled.div`
  width: 100%;
  height: 100%;

  /* overriding ag-grid theme styles from DataGrid for pinned rows */
  &&& {
    .ag-floating-top-viewport .ag-cell {
      background-color: ${({ theme }) => theme.color.gray10};
      color: ${({ theme }) => theme.text.color.dark};
      font-size: ${({ theme }) => theme.text.size.mediumSmall};
    }
  }
`;

export type Props = {
  crmIntegrationId: UUID;
  crmInvestorFirmId: UUID;
  crmInvestorFirm: CrmInvestorFirm;
  loading: boolean;
  linkedFirmMatch?: FirmMatch;
  firmMatches: FirmMatch[];
};

/**
 * Displays the a list of the firms in an Account's CRM data.
 * Header displays CRM Investor Firm details
 */
const CrmFirmMatchList: React.FC<Props> = ({
  crmIntegrationId,
  crmInvestorFirmId,
  crmInvestorFirm,
  loading,
  linkedFirmMatch,
  firmMatches,
}) => {
  const { cmgEntityKey, firmType } = crmInvestorFirm;

  // pass various ids to column definition cell renderers
  const columns = createColumns({
    crmIntegrationId,
    crmInvestorFirmId,
    cmgEntityKey: cmgEntityKey || undefined,
    firmType,
  });

  // crmInvestorFirm data pinned at top
  // linked firm row as 2nd in list (if linked row exists)
  // linked firm row is filtered out of firmMatches in duck selector
  const pinnedRows: FirmMatchRow[] = [
    getFirmMatchFromCrmInvestorFirm(crmInvestorFirm),
    ...(linkedFirmMatch ? [linkedFirmMatch] : []),
  ];

  return (
    <SGrid>
      <DataGrid<FirmMatchRow>
        totalPages={1}
        loading={loading}
        extended={{
          fillViewport: true,
          withMargin: false,
          hidePagination: true,
          hideColumnResize: true,
          hideColumnSelector: true,
        }}
        overlayNoRowsTemplate="No Matching Entities"
        domLayout="normal"
        columns={columns}
        rows={firmMatches}
        resizeBy="text"
        getRowsPinnedToTop={() => pinnedRows}
        gridOptions={{
          suppressCellSelection: true,
          suppressRowClickSelection: true,
          suppressPaginationPanel: true,
          getRowHeight: () => 80,
          getRowStyle: () => ({ cursor: 'default' }),
          onModelUpdated: event => {
            event.columnApi.autoSizeAllColumns();
          },
        }}
      />
    </SGrid>
  );
};

export default CrmFirmMatchList;
