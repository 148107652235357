import { Banner, ServerErrors } from '@cmg/common';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import LoadingIndicator from '../../../common/components/indicators/loading-indicator/LoadingIndicator';
import routeFactory from '../../../common/util/routeFactory';
import { AccountRemapRoute } from '../account-remap/AccountRemapRoute';
import {
  selectAccount,
  selectUpdateError,
  selectUpdateSubmitting,
  updateAccount,
} from '../shared/ducks';
import RolodexMappingPanel from './components/RolodexMappingPanel';
import RolodexPanel from './components/RolodexPanel';
import {
  fetchRolodexFirmRequest,
  selectRolodexFirm,
  selectRolodexFirmError,
  selectRolodexFirmLoading,
} from './ducks';

const mapStateToProps = state => ({
  /**
   * The current firm, or null
   */
  rolodexFirm: selectRolodexFirm(state),
  /**
   * A server error, or null
   */
  error: selectRolodexFirmError(state),
  /**
   * Rolodex firm is loading
   */
  isLoading: selectRolodexFirmLoading(state),
  /**
   * The current account, or null
   */
  account: selectAccount(state),
  /**
   * Update Cmg Entity Key is loading
   */
  isSubmitting: selectUpdateSubmitting(state),
  /**
   * Update Cmg Entity key server error or null
   */
  updateError: selectUpdateError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchRolodexFirm: fetchRolodexFirmRequest,
      updateAccount,
    },
    dispatch
  ),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & DispatchProps;

export const AccountRolodexRouteComponent: React.FC<Props> = ({
  rolodexFirm,
  error,
  updateError,
  isLoading,
  isSubmitting,
  account,
  actions,
}) => {
  useEffect(() => {
    if (account && account.cmgEntityKey) {
      actions.fetchRolodexFirm({ accountId: account.cmgEntityKey });
    }
  }, [actions, account]);

  if (!account?.cmgEntityKey) {
    return (
      <React.Fragment>
        <Banner variant="error" showIcon={false}>
          This account is not mapped to Rolodex.
        </Banner>
        <RolodexMappingPanel
          accountId={account!.id}
          onSubmit={values => actions.updateAccount(values)}
          isSubmitting={isSubmitting}
          error={updateError}
        />
      </React.Fragment>
    );
  }
  if (error) {
    return (
      <Banner variant="error" showIcon={false}>
        <ServerErrors error={error} />
      </Banner>
    );
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <Route exact path={routeFactory.accountRemap.routePath} component={AccountRemapRoute} />
      <RolodexPanel firm={rolodexFirm!} account={account} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountRolodexRouteComponent);
