import { AccessControl, permissionsByEntity } from '@cmg/auth';
import { apiTypes, Panel, SuccessButton } from '@cmg/common';
import {
  accountHeaderSelector,
  accountListCreateAccountSelector,
  accountListScreenSelector,
} from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useDebouncedCallback } from 'use-debounce';

import { AccountFilters } from '../../../common/api/systemManagementApiClient';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../common/util/routeFactory';
import { AccountStatus } from '../../../types/domain/account/constants';
import AccountSubNavigation from '../../account-detail/AccountSubNavigation';
import AccountCreateRoute from '../account-create/AccountCreateRoute';
import AccountList from './components/AccountList';
import {
  fetchAccountList,
  selectAccounts,
  selectError,
  selectLoading,
  selectPagination,
} from './ducks';

const mapStateToProps = state => ({
  loading: selectLoading(state),
  accounts: selectAccounts(state),
  pagination: selectPagination(state),
  error: selectError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchAccountList,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = RouteComponentProps & StateProps & DispatchProps;

const defaultFilters: AccountFilters = { status: AccountStatus.ACTIVE };

export const AccountListRouteComponent: React.FC<Props> = ({
  loading,
  accounts,
  pagination,
  error,
  actions,
}) => {
  const [filters, setFilters] = React.useState<AccountFilters>(defaultFilters);
  const [shouldDebounceFetch, setShouldDebounceFetch] = React.useState<boolean | undefined>();
  const debouncedFetch = useDebouncedCallback(actions.fetchAccountList, 300);

  useDocumentTitle(routeFactory.adminAccounts.getDocumentTitle());

  React.useEffect(() => {
    if (shouldDebounceFetch) {
      debouncedFetch({ page: 1, perPage: 25, ...filters });
    } else {
      actions.fetchAccountList({ page: 1, perPage: 25, ...filters });
    }
  }, [actions, debouncedFetch, filters, shouldDebounceFetch]);

  const handleChangePage = (params: apiTypes.ListParams) => {
    actions.fetchAccountList({
      ...filters,
      ...params,
    });
  };

  const handleChangeFilter = (filters: AccountFilters, debounce?: boolean) => {
    setFilters(filters);
    setShouldDebounceFetch(debounce);
  };

  return (
    <React.Fragment>
      <AccessControl requiredPermissions={[permissionsByEntity.GlobalAccount.FULL]}>
        <Route path={routeFactory.adminAllAccounts.routePath} component={AccountSubNavigation} />
        <Route exact path={routeFactory.adminAccountNew.routePath} component={AccountCreateRoute} />
      </AccessControl>
      <Panel testId={accountListScreenSelector.testId}>
        <Panel.Header
          title="Accounts"
          rightContent={
            <AccessControl requiredPermissions={[permissionsByEntity.GlobalAccount.FULL]}>
              <SuccessButton
                asComponent={Link}
                to={routeFactory.adminAccountNew.getUrlPath()}
                testId={accountListCreateAccountSelector.testId}
              >
                Create Account
              </SuccessButton>
            </AccessControl>
          }
          testId={accountHeaderSelector.testId}
        />
        {pagination && (
          <AccountList
            loading={loading}
            accounts={accounts}
            pagination={pagination}
            filters={filters}
            error={error}
            onChangeFilters={handleChangeFilter}
            onChangePage={handleChangePage}
          />
        )}
      </Panel>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountListRouteComponent);
