import { traitListDescriptionSelector, traitListNameSelector } from '@cmg/e2e-selectors';
import { ColDef } from 'ag-grid-community';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../../common/util/routeFactory';
import { Trait } from '../../../../../types/domain/trait/Trait';

export const NameRenderer: React.FC<{ data: Trait }> = ({ data }) => (
  <Link
    to={routeFactory.adminTraitDetail.getUrlPath({ traitCode: data.code })}
    data-test-id={traitListNameSelector.testId}
  >
    {data.name}
  </Link>
);

export const DescriptionRenderer: React.FC<{ data: Trait }> = ({ data }) => {
  return <span data-test-id={traitListDescriptionSelector.testId}>{data.description}</span>;
};

const columns: ColDef[] = [
  {
    field: 'name',
    headerName: 'Trait',
    cellRendererFramework: NameRenderer,
    sort: 'asc',
  },
  { field: 'description', headerName: 'Description', cellRendererFramework: DescriptionRenderer },
  {
    field: 'accountCount',
    headerName: 'Accounts with Trait',
  },
];

export default columns;
