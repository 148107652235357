import styled from 'styled-components/macro';

import DetailHeader from '../../../../../common/components/layout/detail-header/DetailHeader';

export const StyledDetailHeaderTitleWrapper = styled(DetailHeader.TitleWrapper)`
  align-items: center;
`;

export const SRecordCountWrapper = styled.div`
  width: 150px;
  margin-left: 20px;
  margin-top: 5px;
  text-align: right;
`;

export const StyledRecordCountDetailHeaderTitle = styled(DetailHeader.Title)`
  margin-right: 0;
`;

export const StyledTitleDetailHeaderColumn = styled(DetailHeader.Column)`
  flex: 0 1 auto;
`;

export const StyledRecordSectionWrapper = styled(DetailHeader.Row)`
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: flex-end;

  ${({ theme }) => theme.mediaQuery.largeUp} {
    flex: 1 0 auto;
  }
`;
