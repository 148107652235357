import { CRMFileTypes } from '../../../../../types/domain/crm/constants';

export type CRMFilesUploadFormValues = {
  crmFileType: CRMFileTypes;
  file: File | null;
  skip: number;
};

export type ProcessedSoFar = {
  total: number;
  moreToUpload: boolean;
};

export const FILENAME_REGEX = /^[\w\s-.]+$/i; // alphanumeric, hyphen, spaces, and periods

export const crmFileTypeAcceptableColumnHeaders = {
  [CRMFileTypes.INVESTORS]:
    '"FirmKey", "Name", "Description", "InvestorType", "InvestorSubType", "Address1", "Address2", "PhoneNumber1", "PhoneNumber2", "FaxNumber1", "FaxNumber2", "Email1", "Email2", "Website1", "Website2", "Cik1", "Cik2", "Lei", "Crd", "FactSet", "Status"',
  [CRMFileTypes.COVERAGES]:
    '"InvestorFirmKey", "RelationshipType", "RelationshipStatus", "EmployeeKey", "Name", "Titles", "JobFunction", "Address1", "Address2", "PhoneNumber1,"PhoneNumber2", "FaxNumber1", "FaxNumber2", "Department", "Email1", "Email2", "Status"',
  [CRMFileTypes.CONTACTS]:
    '"FirmKey", "EmployeeKey", "Name", "Titles", "Address1", "Address2", "PhoneNumber1", "PhoneNumber2", "FaxNumber1", "FaxNumber2", "Email1", "Email2", "Status"',
};

export const crmFileTypeColumnNote = {
  [CRMFileTypes.INVESTORS]: [
    'The "Status" column is a string value that must be either "EFFECTIVE" or "DELETED".',
    'The "FirmKey" and "Name" columns are required.',
  ],
  [CRMFileTypes.COVERAGES]: [
    'The "Titles" column can be a comma-separated list of titles. For example, "CEO, CFO, CIO".',
    'The "RelationshipType" column is a string value. If the column is present then the value must be either "PRIMARY" or "SECONDARY".',
    'The "JobFunction" column is an string value that can be empty or "SALES", "TRADING", "SYNDICATE".',
    'The "Status" and "RelationshipStatus" columns are string values. If the columns are present then the values must be either "EFFECTIVE" or "DELETED".',
    'The "InvestorFirmKey" and "EmployeeKey" columns are required.',
  ],
  [CRMFileTypes.CONTACTS]: [
    'The "Titles" column can be a comma-separated list of titles. For example, "CEO, CFO, CIO".',
    'The "Status" column is a string value that must be either "EFFECTIVE" or "DELETED".',
    'The "FirmKey", "EmployeeKey", and "Name" columns are required.',
  ],
};

export enum FileInputErrorType {
  FILE_SIZE = 'FILE_SIZE',
  FILE_TYPE = 'FILE_TYPE',
  UNSUPPORTED_FILENAME = 'UNSUPPORTED_FILENAME',
}

export type FileInputError = Readonly<{
  fileName: string;
  errorType: FileInputErrorType;
}>;

export const errorLabels = {
  [FileInputErrorType.UNSUPPORTED_FILENAME]: (filename: string) =>
    `The document file name is not supported: ${filename} the document name should only contain alphanumeric characters, hyphen, spaces, and periods.`,
  [FileInputErrorType.FILE_TYPE]: (filename: string) =>
    `The document file type is not supported: ${filename} Only CSV files are currently supported.`,
  [FileInputErrorType.FILE_SIZE]: (
    filename: string
  ) => `The document is too large to be uploaded: ${filename} Files larger than 30MB are not
    currently supported.`,
};

export const MAX_FILE_SIZE = 30 * 1024 * 1024; // 30 MB
