import { CenteredPage, PrimaryButton } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import SendIcon from '../../../common/assets/send.svg';
import { SFormDescription, SLinkButton } from '../InvestorSignupRoute.styles';
import { SContainer, SEmailLink, SFooter } from './SuccessScreenRoute.styles';

type Props = RouteComponentProps;

const SuccessScreenRoute: React.FC<Props> = () => {
  return (
    <CenteredPage hideFooter hideHeader>
      <SContainer>
        <img src={SendIcon} alt="Send icon" />
        <h1>Thank you!</h1>
        <SFormDescription>
          <p>
            We are processing your request. Stay tuned for an account activation email. If you don’t
            hear from us shortly, please check your spam folder or email{' '}
            <SEmailLink href="mailto:support@cmgx.io">support@cmgx.io</SEmailLink>
          </p>
          <p>Meanwhile, you can learn more about us.</p>
        </SFormDescription>
        <PrimaryButton size="large" href="https://cmgx.io" variant="high-contrast">
          Visit CMG Website
        </PrimaryButton>
        <SFooter>
          Having an issue?{' '}
          <SLinkButton href="mailto:support@cmgx.io" variant="high-contrast">
            Get in touch
          </SLinkButton>
        </SFooter>
      </SContainer>
    </CenteredPage>
  );
};

export default SuccessScreenRoute;
