// Info Boxes are used to display information in label: value format and provide a consistent
// means of doing so across the app.
import React from 'react';

import { SInfoBox, SInfoBoxes, SInfoItem, SInfoLabel, SInfoValue } from './InfoBoxes.styles';

/**
 * Responsible for positioning the child InfoBox components.
 */
export const InfoBoxes: React.FC = ({ children }) => <SInfoBoxes>{children}</SInfoBoxes>;

/**
 * Provides styling for background, border, and internal spacing.
 * Responsible for positioning child InfoItem components.
 */
export const InfoBox: React.FC<{ className?: string }> = ({ children, className }) => (
  <SInfoBox className={className}>{children}</SInfoBox>
);

/**
 * Responsible for positioning child InfoLabel/InfoValue components.
 */
export const InfoItem: React.FC<{ className?: string }> = ({ children, className }) => (
  <SInfoItem className={className}>{children}</SInfoItem>
);

/**
 * Provides styling for the label portion of a label/value pair.
 */
export const InfoLabel: React.FC<{ className?: string }> = ({ children, className }) => (
  <SInfoLabel className={className}>{children}</SInfoLabel>
);

/**
 * Provides styling for the value portion of a label/value pair.
 */
export const InfoValue: React.FC<{ className?: string }> = ({ children, className }) => (
  <SInfoValue className={className}>{children}</SInfoValue>
);
