import { fileUtil, timeUtil } from '@cmg/common';
import { TextField } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import { ChangeEvent, FC, Fragment, useCallback, useState } from 'react';

import { FormFieldLayout } from '../../../../../../design-system/body-sections/form/FormFieldLayout';
import { FormGroupHeading } from '../../../../../../design-system/body-sections/form/FormGroupHeading';
import { FormLayout } from '../../../../../../design-system/body-sections/form/FormLayout';
import {
  CertificateMetadata,
  Saml2PMetadata,
} from '../../../../../../types/domain/identity-provider/configurations/saml2p';
import { SamlProviderFormValues } from './utils';

export type Props = Readonly<{
  readonly metadata: Saml2PMetadata | null;
  readonly certificateMetadata: CertificateMetadata | null;
}>;

export const ProviderDetailsSectionEdit: FC<Props> = ({ metadata, certificateMetadata }) => {
  const [isUploadingCertificate, setIsUploadingCertificate] = useState(false);
  const formik = useFormikContext<SamlProviderFormValues>();

  const { setFieldValue } = formik;
  const changeCertificate = useCallback(
    async (file: File | null) => {
      setIsUploadingCertificate(true);

      const base64String = file ? await fileUtil.fileToBase64(file) : '';

      setFieldValue('certificateFile', file);
      setFieldValue('configuration.certificate', base64String);

      setIsUploadingCertificate(false);
    },
    [setFieldValue]
  );

  return (
    <FormLayout>
      <FormFieldLayout>
        <TextField disabled label="Metadata URL" value={metadata?.metadataUrl} />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField disabled label="SAML Version" value={metadata?.samlVersion} />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField disabled label="Assertion Consumer URL" value={metadata?.assertionConsumerUrl} />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField disabled label="Single Logout URL" value={metadata?.singleLogoutUrl} />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField disabled label="Consumer Binding" value={metadata?.consumerBinding} />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField disabled label="Entity ID" value={metadata?.entityId} />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField disabled label="NameID Format" value={metadata?.nameIdFormat} />
      </FormFieldLayout>
      {certificateMetadata && (
        <Fragment>
          <FormGroupHeading title="Your SAML Identity Provider Certificate" />
          <FormFieldLayout>
            <TextField disabled label="Issuer" value={certificateMetadata.issuer} />
          </FormFieldLayout>
          <FormFieldLayout>
            <TextField disabled label="Subject" value={certificateMetadata.subject} />
          </FormFieldLayout>
          <FormFieldLayout>
            <TextField disabled label="Thumbprint" value={certificateMetadata.thumbprint} />
          </FormFieldLayout>
          <FormFieldLayout>
            <TextField
              disabled
              label="Valid Until"
              value={timeUtil.formatAsDisplayDateTime(certificateMetadata.validUntil)}
            />
          </FormFieldLayout>
        </Fragment>
      )}
      <FormFieldLayout>
        <TextField
          required
          label="Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          required
          label="Entity Id"
          name="configuration.entityId"
          value={formik.values.configuration.entityId}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.configuration?.entityId)}
          helperText={formik.errors.configuration?.entityId}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          required
          label="Single SignOn Service Url"
          name="configuration.signOnUrl"
          value={formik.values.configuration.signOnUrl}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.configuration?.signOnUrl)}
          helperText={formik.errors.configuration?.signOnUrl}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          label="Single Logout Service Url"
          name="configuration.logoutUrl"
          value={formik.values.configuration.logoutUrl}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.configuration?.logoutUrl)}
          helperText={formik.errors.configuration?.logoutUrl}
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          required
          disabled={isUploadingCertificate}
          type="file"
          label="Certificate"
          name="certificateFile"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const file = e.target?.files?.length ? e.target.files[0] : null;
            void changeCertificate(file);
          }}
          error={formik.touched.certificateFile && Boolean(formik.errors.certificateFile)}
          helperText={formik.touched.certificateFile && formik.errors.certificateFile}
        />
      </FormFieldLayout>
    </FormLayout>
  );
};
