import { CenteredPage, urlUtil } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import externalRouteFactory from '../../../common/util/externalRouteFactory';
import ResetPasswordForm from './components/ResetPasswordForm';
import { resetPassword, resetState, selectError, selectSubmitting, selectSuccess } from './ducks';

// TODO: create task for "uplifting" ResetPassword, or erase this route, if forgot-password is enough

const mapStateToProps = (state, ownProps: RouteComponentProps) => {
  const queryParams = urlUtil.queryParse<{ code?: string; userId?: string }>(
    ownProps.location.search
  );

  return {
    error: selectError(state),
    success: selectSuccess(state),
    submitting: selectSubmitting(state),
    userId: queryParams.userId, // user we are setting password for, pairs with the token
    token: queryParams.code, // password reset token
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetPassword,
      resetState,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RouteProps = RouteComponentProps;
type Props = RouteProps & StateProps & DispatchProps;

/**
 * Displays a centered reset password form. You can get to this route from a reset password
 * email.
 * If you come here without having the necessary info to perform a reset, then it
 * redirects to login.
 */
export class ResetPasswordRouteComponent extends React.Component<Props> {
  componentWillUnmount() {
    this.props.actions.resetState();
  }

  render() {
    const { error, submitting, success, actions, userId, token } = this.props;

    // Didn't come here directly from email, so kick em out
    if (!userId || !token) {
      return <Redirect to={externalRouteFactory.ecmSpa.getUrl()} />;
    }

    return (
      <CenteredPage title="Password reset">
        <ResetPasswordForm
          onSubmit={actions.resetPassword}
          error={error}
          success={success}
          submitting={submitting}
          token={token}
          userId={userId}
        />
      </CenteredPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordRouteComponent);
