import { isSystemSubdomain } from '@cmg/auth';
import { apiTypes, routeUtil, urlUtil } from '@cmg/common';
import History from 'history';

import { getAppSettings } from '../../common/config/appSettings';
import { ErrorCode } from '../../features/identity-management/error/constants';
import { UUID } from '../../types/common';
import { FirmDataSource } from '../../types/domain/firm/constants';

const { createRoute } = routeUtil;

const prependAccountDetailRoutePath = (restOfRoutePath: string) => {
  return isSystemSubdomain()
    ? `/admin/accounts/:accountSubdomain${restOfRoutePath}`
    : `/account${restOfRoutePath}`;
};

const prependAccountDetailUrlPath = (accountSubdomain: string, restOfUrl: string) => {
  return isSystemSubdomain()
    ? `/admin/accounts/${accountSubdomain}${restOfUrl}`
    : `/account${restOfUrl}`;
};

/**
 * Instead of hardcoding SPA urls throughout the application this factory object provides
 * a configuration key for react-router <Route/> along with a method to generate each of
 * the urls for use in <a/> and <Link/> components.
 */
const routeFactory = {
  root: createRoute({
    routePath: '/',
    getUrlPath: () => '/',
  }),

  // ----- OIDC - logging into the identity SPA

  oidcLogin: createRoute({
    routePath: '/oidc-login',
    getUrlPath: () => '/oidc-login',
    getRouteState: (params: { location: History.Location }) => ({
      from: params.location,
    }),
  }),

  oidcLogout: createRoute({
    routePath: '/oidc-logout',
    getUrlPath: () => '/oidc-logout',
  }),

  oidcLoginCallback: createRoute({
    routePath: '/oidc-login-callback',
  }),

  oidcLogoutCallback: createRoute({
    routePath: '/oidc-logout-callback',
  }),

  loggedOut: createRoute({
    routePath: '/logged-out',
    getUrlPath: () => `/logged-out`,
    getAbsoluteUrlPath: () => `${getAppSettings().client.basename}/logged-out`,
  }),

  // ----- Identity

  login: createRoute({
    routePath: '/login',
    getUrlPath: () => '/login',
  }),

  firstTimeActivation: createRoute({
    routePath: '/activate-account',
  }),

  forgotPassword: createRoute({
    routePath: '/forgot-password',
    getUrlPath: () => `/forgot-password`,
  }),

  resetPassword: createRoute({
    routePath: '/reset-password',
  }),

  error: createRoute({
    routePath: '/error',
    getUrlPath: ({ errorCode }: { errorCode?: apiTypes.ServiceErrorCode | ErrorCode }) =>
      `/error${urlUtil.queryStringify({ errorCode })}`,
  }),

  changePassword: createRoute({
    routePath: '/profile/change-password',
    getUrlPath: () => '/profile/change-password',
  }),

  unlocked: createRoute({
    routePath: '/unlocked',
  }),

  investorSignup: createRoute({
    routePath: '/inviteme',
    getUrlPath: ({ token }: { token?: string }) => `/inviteme/${token}`,
  }),

  investorSignupSuccess: createRoute({
    routePath: '/inviteme-success',
    getUrlPath: () => '/inviteme-success',
  }),

  // ----- Rolodex

  rolodex: createRoute({
    routePath: '/rolodex',
    getUrlPath: () => `/rolodex`,
  }),

  rolodexFirms: createRoute({
    routePath: '/rolodex/firms',
    getUrlPath: () => `/rolodex/firms`,
  }),

  rolodexFirmDetail: createRoute({
    routePath: '/rolodex/firms/detail/:firmId',
    getUrlPath: (params: { firmId: string }) => `/rolodex/firms/detail/${params.firmId}`,
  }),

  rolodexFirmNew: createRoute({
    routePath: '/rolodex/firms/new',
    getUrlPath: () => `/rolodex/firms/new`,
  }),

  rolodexFirmImport: createRoute({
    routePath: '/rolodex/firms/new/import',
    getUrlPath: () => `/rolodex/firms/new/import`,
  }),

  rolodexFirmCreate: createRoute({
    routePath: '/rolodex/firms/new/create',
    getUrlPath: () => `/rolodex/firms/new/create`,
  }),

  rolodexCrmIntegrations: createRoute({
    routePath: '/rolodex/crm_integrations',
    getUrlPath: () => `/rolodex/crm_integrations`,
  }),

  rolodexCrmIntegrationDetail: createRoute({
    routePath: '/rolodex/crm_integrations/:crmIntegrationId/details',
    getUrlPath: (params: { crmIntegrationId: string }) =>
      `/rolodex/crm_integrations/${params.crmIntegrationId}/details`,
  }),

  rolodexCrmEntityMatcher: createRoute({
    routePath: '/rolodex/crm_integrations/:crmIntegrationId/entity-matcher/:crmInvestorFirmId',
    getUrlPath: (params: { crmIntegrationId: string; crmInvestorFirmId: string }) =>
      `/rolodex/crm_integrations/${params.crmIntegrationId}/entity-matcher/${params.crmInvestorFirmId}`,
  }),

  rolodexCrmEntityMatcherWithMatchDetails: createRoute({
    routePath:
      '/rolodex/crm_integrations/:crmIntegrationId/entity-matcher/:crmInvestorFirmId/match-firm-details/:matchFirmType/:matchFirmId',
    getUrlPath: (params: {
      crmIntegrationId: string;
      crmInvestorFirmId: string;
      matchFirmId: UUID | string;
      matchFirmType: FirmDataSource;
    }) =>
      `/rolodex/crm_integrations/${params.crmIntegrationId}/entity-matcher/${params.crmInvestorFirmId}/match-firm-details/${params.matchFirmType}/${params.matchFirmId}`,
  }),

  // ----- Self

  profile: createRoute({
    routePath: '/profile',
    getUrlPath: () => `/profile`,
    getDocumentTitle: () => 'My Profile',
  }),

  // ----- System Administration

  adminAllAccounts: createRoute({
    routePath: '/admin',
    getUrlPath: () => '/admin',
  }),

  adminAccounts: createRoute({
    routePath: '/admin/account-list',
    getUrlPath: () => '/admin/account-list',
    getDocumentTitle: () => 'Accounts',
  }),

  adminDraftAccounts: createRoute({
    routePath: '/admin/draft-account-list',
    getUrlPath: () => '/admin/draft-account-list',
    getDocumentTitle: (params: { traitName?: string }) => `Draft Accounts`,
  }),

  adminAccountNew: createRoute({
    routePath: '/admin/account-list/new',
    getUrlPath: () => '/admin/account-list/new',
    getDocumentTitle: () => 'Create Account',
  }),

  adminTraits: createRoute({
    routePath: '/admin/traits',
    getUrlPath: () => '/admin/traits',
    getDocumentTitle: () => 'Traits',
  }),

  adminTraitDetail: createRoute({
    routePath: '/admin/traits/:traitCode',
    getUrlPath: (params: { traitCode: string }) => `/admin/traits/${params.traitCode}`,
    getDocumentTitle: (params: { traitName?: string }) => `${params.traitName || '...'} | Traits`,
  }),

  // ----- Account Administration (Sys + Customer Admins)
  systemTenant: createRoute({
    routePath: '/admin/accounts/system',
    getUrlPath: () => `/admin/accounts/system`,
    // no getDocumentTitle - the nested routes have titles
  }),

  accountDetail: createRoute({
    routePath: prependAccountDetailRoutePath(''),
    getUrlPath: (params: { accountSubdomain: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, ''),
    // no getDocumentTitle - the nested routes have titles
  }),

  accountUserNew: createRoute({
    routePath: prependAccountDetailRoutePath('/user-list/new'),
    getUrlPath: (params: { accountSubdomain: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, '/user-list/new'),
    getDocumentTitle: (params: { accountName?: string }) =>
      `New User | ${params.accountName || '...'}`,
  }),

  accountUsers: createRoute({
    routePath: prependAccountDetailRoutePath('/user-list'),
    getUrlPath: (params: { accountSubdomain: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, '/user-list'),
    getDocumentTitle: (params: { accountName?: string }) =>
      `Users | ${params.accountName || '...'}`,
  }),

  accountUserDetail: createRoute({
    routePath: prependAccountDetailRoutePath('/users/:userId'),
    getUrlPath: (params: { accountSubdomain: string; userId: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, `/users/${params.userId}`),
    getDocumentTitle: (params: { accountName?: string; userFullName?: string }) =>
      `${params.userFullName || '...'} | Users | ${params.accountName || '...'}`,
  }),

  accountRoles: createRoute({
    routePath: prependAccountDetailRoutePath('/roles'),
    getUrlPath: (params: { accountSubdomain: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, '/roles'),
    getDocumentTitle: (params: { accountName?: string }) =>
      `Roles | ${params.accountName || '...'}`,
  }),

  accountRoleDetail: createRoute({
    routePath: prependAccountDetailRoutePath('/roles/:roleId'),
    getUrlPath: (params: { accountSubdomain: string; roleId: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, `/roles/${params.roleId}`),
    getDocumentTitle: (params: { accountName?: string; roleName?: string }) =>
      `${params.roleName || '...'} | Roles | ${params.accountName || '...'}`,
  }),

  accountRoleUsersDetail: createRoute({
    routePath: prependAccountDetailRoutePath('/roles/:roleId/users'),
    getUrlPath: (params: { accountSubdomain: string; roleId: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, `/roles/${params.roleId}/users`),
    getDocumentTitle: (params: { accountName?: string; roleName?: string }) =>
      `${params.roleName || '...'} | Roles | ${params.accountName || '...'}`,
  }),

  accountSecurity: createRoute({
    routePath: prependAccountDetailRoutePath('/security'),
    getUrlPath: (params: { accountSubdomain: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, '/security'),
    getDocumentTitle: (params: { accountName?: string }) =>
      `Security | ${params.accountName || '...'}`,
  }),

  accountSecurityAzureIdOpenIdProvider: createRoute({
    routePath: prependAccountDetailRoutePath('/security/providers/azureAdOpenId'),
    getUrlPath: (params: { accountSubdomain: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, '/security/providers/azureAdOpenId'),
    getDocumentTitle: (params: { accountName?: string }) =>
      `Security | ${params.accountName || '...'}`,
  }),

  accountSecuritySaml2pProvider: createRoute({
    routePath: prependAccountDetailRoutePath('/security/providers/saml2p'),
    getUrlPath: (params: { accountSubdomain: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, '/security/providers/saml2p'),
    getDocumentTitle: (params: { accountName?: string }) =>
      `Security | ${params.accountName || '...'}`,
  }),

  accountSecurityOpenIdConnectProvider: createRoute({
    routePath: prependAccountDetailRoutePath('/security/providers/openIdConnect'),
    getUrlPath: (params: { accountSubdomain: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, `/security/providers/openIdConnect`),
    getDocumentTitle: (params: { accountName?: string }) =>
      `Security | ${params.accountName || '...'}`,
  }),

  accountSecurityProviderLocalLogin: createRoute({
    routePath: prependAccountDetailRoutePath('/security/providers/local'),
    getUrlPath: (params: { accountSubdomain: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, `/security/providers/local`),
    getDocumentTitle: (params: { accountName?: string }) =>
      `Security | ${params.accountName || '...'}`,
  }),

  accountSubscription: createRoute({
    routePath: prependAccountDetailRoutePath('/subscription'),
    getUrlPath: (params: { accountSubdomain: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, `/subscription`),
    getDocumentTitle: (params: { accountName?: string }) =>
      `Subscription | ${params.accountName || '...'}`,
  }),

  accountApiKeys: createRoute({
    routePath: prependAccountDetailRoutePath('/api-keys'),
    getUrlPath: (params: { accountSubdomain: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, `/api-keys`),
    getDocumentTitle: (params: { accountName?: string }) =>
      `API Keys | ${params.accountName || '...'}`,
  }),
  accountApiKeyDetail: createRoute({
    routePath: prependAccountDetailRoutePath('/api-keys/:apiKeyId'),
    getUrlPath: (params: { accountSubdomain: string; apiKeyId: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, `/api-keys/${params.apiKeyId}`),
    getDocumentTitle: (params: { accountName?: string }) =>
      `API Keys | ${params.accountName || '...'}`,
  }),
  accountApiKeysNew: createRoute({
    routePath: prependAccountDetailRoutePath('/api-keys/newkey'),
    getUrlPath: (params: { accountSubdomain: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, `/api-keys/newkey`),
    getDocumentTitle: (params: { accountName?: string }) =>
      `New API Key | ${params.accountName || '...'}`,
  }),
  accountRemap: createRoute({
    routePath: prependAccountDetailRoutePath('/rolodex/remap-account'),
    getUrlPath: (accountSubdomain: string) =>
      prependAccountDetailUrlPath(accountSubdomain, `/rolodex/remap-account`),
    getDocumentTitle: (params: { accountName?: string }) =>
      `Remap | ${params.accountName || '...'}`,
  }),
  accountRolodex: createRoute({
    routePath: prependAccountDetailRoutePath('/rolodex'),
    getUrlPath: (params: { accountSubdomain: string }) =>
      prependAccountDetailUrlPath(params.accountSubdomain, `/rolodex`),
    getDocumentTitle: (params: { accountName?: string }) =>
      `Rolodex | ${params.accountName || '...'}`,
  }),
  selectDomain: createRoute({
    routePath: '/select-domain',
    getUrlPath: () => '/select-domain',
  }),

  // customer facing crm management
  customerCrmManagement: createRoute({
    routePath: '/crm-management',
    getUrlPath: () => `/crm-management`,
  }),

  customerCrmFilesUpload: createRoute({
    routePath: '/crm-management/files-upload',
    getUrlPath: () => `/crm-management/files-upload`,
    getDocumentTitle: (params: { accountName?: string }) =>
      `CRM Files Upload | ${params.accountName || '...'}`,
  }),

  // sys admin facing crm management
  sysAdminCrmManagement: createRoute({
    routePath: '/rolodex/crm_integrations/:crmIntegrationId/details/crm-management',
    getUrlPath: (params: { crmIntegrationId?: string }) =>
      `/rolodex/crm_integrations/${params.crmIntegrationId}/details/crm-management`,
  }),

  sysAdminCrmFilesUpload: createRoute({
    routePath: '/rolodex/crm_integrations/:crmIntegrationId/details/crm-management/files-upload',
    getUrlPath: (params: { crmIntegrationId?: string }) =>
      `/rolodex/crm_integrations/${params.crmIntegrationId}/details/crm-management/files-upload`,
    getDocumentTitle: (params: { crmIntegrationName?: string }) =>
      `CRM Files Upload | ${params.crmIntegrationName || '...'}`,
  }),

  // ----- Privacy Policy
  privacyPolicy: createRoute({
    routePath: '/privacy-policy',
    getUrlPath: () => `/privacy-policy`,
    getDocumentTitle: () => 'CMG Privacy Policy',
  }),
};

export default routeFactory;
