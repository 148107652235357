import { AccountPermission } from '../../../../types/domain/account/account';
import {
  PermissionDetail,
  permissionDisplay,
  PermissionLevel,
  UndefinedPermissionDetail,
} from '../../auth/constants';

/**
 * Takes a list of permission strings, removes all duplicates and returns
 * the highest access level for each permission
 * @param permissions {string[]} ['read:account','full:account'...]
 * @returns {Object} { [permission name]: permission } for example: { account: 'full:account' }
 */
export const sanitizePermissionsByAccessLevel = (permissions: string[] | null) => {
  const perms = permissions || [];
  return perms.reduce((acc, p) => {
    const [, name] = p.split(':');
    if (!acc[name] || (acc[name] && !acc[name].includes(PermissionLevel.FULL))) {
      acc[name] = p;
    }
    return acc;
  }, {});
};

/*
 * maps fetched account permissions to permissionDisplay info for PermissionDetail or UndefinedPermissionDetail
 */
export const mapPermissionDetails = (
  accountPermissions: AccountPermission[]
): (PermissionDetail | UndefinedPermissionDetail)[] => {
  return accountPermissions.map(
    p =>
      permissionDisplay[p.name] || {
        groupName: p.group || 'N/A',
        permissionName: p.name,
        permissionLevel: p.name.split(':')[0],
        name: p.name,
        entity: p.name,
      }
  );
};

/*
 * create a faux permission for the No Access column given the permissions in that entity
 */
export const noAccessLevelPermissionByEntity = (
  entityPermissions: (PermissionDetail | UndefinedPermissionDetail)[]
) => {
  const { name } = entityPermissions[0] || { name: ':' };

  return {
    name: `:${name.split(':')[1]}`,
  };
};
