import { FC } from 'react';

import { DescriptionList } from '../../../../../../design-system/body-sections/description-list/DescriptionList';
import { DescriptionListItem } from '../../../../../../design-system/body-sections/description-list/DescriptionListItem';
import { AzureAdOpenIdMetadata } from '../../../../../../types/domain/identity-provider/configurations/azureAd';
import { IdentityProviderAzure } from '../../../../../../types/domain/identity-provider/identityProvider';

export type Props = Readonly<{
  readonly provider: IdentityProviderAzure | null;
  readonly metadata: AzureAdOpenIdMetadata | null;
}>;

export const ProviderDetailsSectionReadonly: FC<Props> = ({ provider, metadata }) => {
  return (
    <DescriptionList label="Provider Details">
      <DescriptionListItem label="Redirect URI" value={metadata?.redirectUri} />
      <DescriptionListItem label="Logout URL" value={metadata?.logoutUrl} />
      <DescriptionListItem label="Name" value={provider?.name} />
      <DescriptionListItem label="Client ID" value={provider?.configuration.clientId} />
      <DescriptionListItem label="Azure Tenant ID" value={provider?.configuration.tenantId} />
      <DescriptionListItem
        label="Authorized Group ID"
        value={provider?.configuration.authorizedGroupId}
      />
    </DescriptionList>
  );
};
