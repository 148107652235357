import { CenteredPage, PrimaryButton } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import externalRouteFactory from '../../../common/util/externalRouteFactory';

const SHeading = styled.div`
  font-size: ${({ theme }) => theme.text.size.large};
  margin: 25px 0 40px;
`;

type RouteProps = RouteComponentProps;

export const UnlockedRoute: React.FC<RouteProps> = () => {
  return (
    <CenteredPage>
      <SHeading>Your account has been unlocked</SHeading>
      <PrimaryButton
        href={externalRouteFactory.ecmSpa.getUrl()}
        size="large"
        fullWidth
        variant="high-contrast"
      >
        Return to Login Page
      </PrimaryButton>
    </CenteredPage>
  );
};

export default UnlockedRoute;
