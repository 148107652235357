import React from 'react';
import styled from 'styled-components/macro';

import {
  InfoItem,
  InfoLabel,
  InfoValue,
} from '../../../../common/components/layout/info-box/InfoBoxes';
import { AdminFirmLimitedRead } from '../../../../types/domain/firm/Firm';

const StyledInfoItem = styled(InfoItem)`
  min-height: 34px;
`;
export type OwnProps = {
  /** The Firm object to display */
  firm: AdminFirmLimitedRead;
};

/**
 * Displays Firm Details
 */
export const FirmDetailsComponent: React.FC<OwnProps> = ({ firm }) => {
  if (!firm) {
    return null;
  }

  return (
    <div>
      <StyledInfoItem>
        <InfoLabel>Description</InfoLabel>
        <InfoValue>{firm.description}</InfoValue>
      </StyledInfoItem>
      <StyledInfoItem>
        <InfoLabel>Entity Type</InfoLabel>
        <InfoValue>{firm.entityType}</InfoValue>
      </StyledInfoItem>
      <StyledInfoItem>
        <InfoLabel>Industry Type</InfoLabel>
        <InfoValue>{firm.industryType}</InfoValue>
      </StyledInfoItem>
    </div>
  );
};
