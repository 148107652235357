import { timeUtil } from '@cmg/common';
import { Link } from '@cmg/design-system';
import { FC, Fragment } from 'react';

import { DescriptionList } from '../../../../../../design-system/body-sections/description-list/DescriptionList';
import { DescriptionListHeading } from '../../../../../../design-system/body-sections/description-list/DescriptionListHeading';
import { DescriptionListItem } from '../../../../../../design-system/body-sections/description-list/DescriptionListItem';
import {
  CertificateMetadata,
  Saml2PMetadata,
} from '../../../../../../types/domain/identity-provider/configurations/saml2p';
import { IdentityProviderSaml } from '../../../../../../types/domain/identity-provider/identityProvider';

export type Props = Readonly<{
  readonly provider: IdentityProviderSaml | null;
  readonly metadata: Saml2PMetadata | null;
  readonly certificateMetadata: CertificateMetadata | null;
}>;

export const ProviderDetailsSectionReadonly: FC<Props> = ({
  provider,
  metadata,
  certificateMetadata,
}) => {
  return (
    <DescriptionList label="Provider Details">
      <DescriptionListItem
        label="Metadata URL"
        value={
          <Link
            href={metadata?.metadataUrl}
            component="a"
            target="_blank"
            rel="noopener noreferrer"
          >
            {metadata?.metadataUrl}
          </Link>
        }
      />
      <DescriptionListItem label="SAML Version" value={metadata?.samlVersion} />
      <DescriptionListItem label="Assertion Consumer URL" value={metadata?.assertionConsumerUrl} />
      <DescriptionListItem label="Single Logout URL" value={metadata?.singleLogoutUrl} />
      <DescriptionListItem label="Consumer Binding" value={metadata?.consumerBinding} />
      <DescriptionListItem label="Entity ID" value={metadata?.entityId} />
      <DescriptionListItem label="NameID Format" value={metadata?.nameIdFormat} />
      {certificateMetadata && (
        <Fragment>
          <DescriptionListHeading title="Your SAML Identity Provider Certificate" />
          <DescriptionListItem label="Issuer" value={certificateMetadata.issuer} />
          <DescriptionListItem label="Subject" value={certificateMetadata.subject} />
          <DescriptionListItem label="Thumbprint" value={certificateMetadata.thumbprint} />
          <DescriptionListItem
            label="Valid Until"
            value={timeUtil.formatAsDisplayDateTime(certificateMetadata.validUntil)}
          />
        </Fragment>
      )}
      <DescriptionListItem label="Name" value={provider?.name} />
      <DescriptionListItem label="Entity Id" value={provider?.configuration.entityId} />
      <DescriptionListItem
        label="Single SignOn Service Url"
        value={provider?.configuration.signOnUrl}
      />
      <DescriptionListItem
        label="Single Logout Service Url"
        value={provider?.configuration.logoutUrl}
      />
    </DescriptionList>
  );
};
