import {
  Autocomplete,
  AutocompleteTextFieldProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import { useMemo, VFC } from 'react';

import { FormFieldLayout } from '../../../../../design-system/body-sections/form/FormFieldLayout';
import { FormLayout } from '../../../../../design-system/body-sections/form/FormLayout';
import { Account } from '../../../../../types/domain/account/account';
import {
  accountTypeLabels,
  externalLicenseOptions,
  licenseTypeOptions,
} from '../../../../../types/domain/account/constants';
import { SubscriptionFormValues } from './SubscriptionSection.model';

export type Props = {
  account: Readonly<Account>;
};

export const SubscriptionSectionEdit: VFC<Props> = ({ account }) => {
  const formik = useFormikContext<SubscriptionFormValues>();

  const externalLicensesTextFieldProps: AutocompleteTextFieldProps = useMemo(
    () => ({
      label: 'External Licenses',
      name: 'externalLicenses',
      error: Boolean(formik.errors.externalLicenses),
      helperText: formik.errors.externalLicenses,
      placeholder: 'Assign Licenses...',
    }),
    [formik.errors.externalLicenses]
  );

  return (
    <FormLayout>
      <FormFieldLayout>
        <FormikTextField label="Name" required multiline name="name" />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField label="Subdomain" name="subdomain" value={account.subdomain} disabled />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          label="Account Type"
          name="accountType"
          value={(account.accountType && accountTypeLabels[account.accountType]) ?? ''}
          disabled
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <FormControl>
          <InputLabel required id="subscription-section-license-type-label">
            License Type
          </InputLabel>
          <Select
            required
            labelId="subscription-section-license-type-label"
            label="License Type"
            name="licenseType"
            value={formik.values.licenseType}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.licenseType)}
          >
            {licenseTypeOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {formik.errors.licenseType && (
            <FormHelperText error>{formik.errors.licenseType}</FormHelperText>
          )}
        </FormControl>
      </FormFieldLayout>
      <FormFieldLayout>
        <FormikTextField label="License Limit" required type="number" name="licenseCount" />
      </FormFieldLayout>
      <FormFieldLayout>
        <TextField
          label="Remaining License Count"
          type="number"
          name="remainingLicenseCount"
          value={account.remainingLicenseCount}
          disabled
        />
      </FormFieldLayout>
      <FormFieldLayout>
        <Autocomplete
          multiple
          options={externalLicenseOptions.map(o => o.value)}
          getOptionLabel={optionValue =>
            externalLicenseOptions.find(o => o.value === optionValue)?.label ?? ''
          }
          value={formik.values.externalLicenses}
          onChange={(_e, value) => formik.setFieldValue('externalLicenses', value)}
          TextFieldProps={externalLicensesTextFieldProps}
        />
      </FormFieldLayout>
    </FormLayout>
  );
};
