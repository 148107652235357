import { createContext } from 'react';

import { AppSettings, getAppSettings } from './../appSettings';

export const AppSettingsContext = createContext<AppSettings | undefined>(undefined);

export const AppSettingsContextProvider: React.FC = ({ children }) => {
  return (
    <AppSettingsContext.Provider value={getAppSettings()}>{children}</AppSettingsContext.Provider>
  );
};
