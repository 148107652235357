import { apiTypes, duckPartFactory } from '@cmg/common';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  GetCrmIntegrationsParams,
  GetCrmIntegrationsResponse,
} from '../../../common/api/rolodexApiClient';
import * as rolodexApiClient from '../../../common/api/rolodexApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { CrmIntegration } from '../../../types/domain/entity-matcher/CrmIntegration';

export const fetchCrmIntegrationListDuckParts = duckPartFactory.makeAPIDuckParts<
  GetCrmIntegrationsParams,
  {
    data: CrmIntegration[];
    pagination: apiTypes.Pagination;
  }
>({
  prefix: 'ROLODEX/CRM_INTEGRATION_LIST',
});

/**
 * ACTION CREATORS
 */

export const fetchCrmIntegrationList = fetchCrmIntegrationListDuckParts.actionCreators.request;
type fetchCrmIntegrationListAction = ReturnType<typeof fetchCrmIntegrationList>;

/**
 * REDUCERS
 */

export const initialState = {
  clients: fetchCrmIntegrationListDuckParts.initialState,
};

export type ReducerState = typeof initialState;

export default combineReducers<ReducerState>({
  clients: fetchCrmIntegrationListDuckParts.reducer,
});

/**
 * SELECTORS
 */

const selectState = (state: RootState) => state.rolodexCrmIntegrationList;

const clientListSelectors = fetchCrmIntegrationListDuckParts.makeSelectors(
  state => selectState(state).clients
);
export const selectClientsLoading = clientListSelectors.selectLoading;
export const selectClientsError = clientListSelectors.selectError;

export const selectClients = (state: RootState) => {
  const successBody = clientListSelectors.selectData(state);
  return successBody ? successBody.data : [];
};
export const selectClientsPagination = (state: RootState) => {
  const successBody = clientListSelectors.selectData(state);
  return successBody ? successBody.pagination : undefined;
};

/**
 * SAGAS
 */

export function* fetchCrmIntegrationListSaga({
  payload,
}: fetchCrmIntegrationListAction): SagaIterator {
  const response: GetCrmIntegrationsResponse = yield call(
    rolodexApiClient.getCrmIntegrations,
    payload
  );

  if (response.ok) {
    yield put(fetchCrmIntegrationListDuckParts.actionCreators.success(response.data));
  } else {
    yield put(fetchCrmIntegrationListDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* rolodexCrmIntegrationListSaga() {
  yield takeLatest<fetchCrmIntegrationListAction>(
    fetchCrmIntegrationListDuckParts.actionTypes.REQUEST,
    fetchCrmIntegrationListSaga
  );
}
