import { timeUtil } from '@cmg/common';
import { identitySelectors } from '@cmg/e2e-selectors';
import { differenceInDays, intlFormatDistance } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import Status from '../../../../../common/components/indicators/status/Status';
import routeFactory from '../../../../../common/util/routeFactory';
import { ApiKey } from '../../../../../types/domain/api-keys/api-key';
import { ApiKeyStatus, ApiKeyStatusLabels } from '../../../../../types/domain/api-keys/constants';
import { isExpired } from '../../shared/helpers';

const SExpiringSoonText = styled.span`
  color: ${({ theme }) => theme.text.color.error};
  margin-left: 5px;
`;

export const isKeyActive = (apiKey: ApiKey): boolean =>
  !!apiKey.expiresOn && !isExpired(apiKey.expiresOn);

export const ApiKeyNameCellRendererFramework: React.FC<{
  data: ApiKey;
  accountSubdomain: string;
}> = ({ data: apiKey, accountSubdomain }) => (
  <Link
    replace={false}
    to={routeFactory.accountApiKeyDetail.getUrlPath({
      accountSubdomain,
      apiKeyId: apiKey.id,
    })}
    data-test-id={identitySelectors.accountDetail.apiKeysSelectors.apiKeyListLabel.testId}
  >
    {apiKey.name}
  </Link>
);

export const ApiKeyExpirationDateCellRendererFramework: React.FC<{
  data: ApiKey;
}> = ({ data: apiKey }) => {
  const today = new Date();
  let daysLeftUntilExpirationText = '';
  const isActive = isKeyActive(apiKey);
  let isExpiringSoon = false;

  if (apiKey.expiresOn && isActive) {
    const expirationDay = timeUtil.getDate(apiKey.expiresOn);
    const daysLeftUntilExpiration = differenceInDays(today, expirationDay);
    const daysToDisplayExpirationAlert = -30;
    isExpiringSoon = daysLeftUntilExpiration >= daysToDisplayExpirationAlert;
    daysLeftUntilExpirationText = intlFormatDistance(expirationDay, today, { unit: 'day' });
  }

  const expiresOnText =
    (apiKey.expiresOn && timeUtil.formatAsDisplayDate(apiKey.expiresOn)) || '--/--/----';

  return (
    <React.Fragment>
      <span>{expiresOnText}</span>
      {isExpiringSoon && <SExpiringSoonText>({daysLeftUntilExpirationText})</SExpiringSoonText>}
      {!isActive && <SExpiringSoonText>(EXPIRED)</SExpiringSoonText>}
    </React.Fragment>
  );
};

export const StatusCellRendererFramework: React.FC<{
  data: ApiKey;
}> = ({ data: apiKey }) => (
  <Status
    isActive={isKeyActive(apiKey)}
    activeLabel={ApiKeyStatusLabels[ApiKeyStatus.ACTIVE]}
    inactiveLabel={ApiKeyStatusLabels[ApiKeyStatus.EXPIRED]}
  />
);

export const getApiKeyListGridColumns = (accountSubdomain: string) => [
  {
    field: 'name',
    headerName: 'Name',
    sort: 'asc',
    cellRendererFramework: params => (
      <ApiKeyNameCellRendererFramework {...params} accountSubdomain={accountSubdomain} />
    ),
    suppressColumnsToolPanel: true,
  },
  {
    field: 'generatedOn',
    headerName: 'Created On',
    valueFormatter: ({ data: apiKey }: { data: ApiKey }) =>
      apiKey.generatedOn ? (timeUtil.formatAsDisplayDate(apiKey.generatedOn) as string) : 'ERROR',
    sortable: false,
  },
  {
    field: 'expiresOn',
    headerName: 'Expires On',
    cellRendererFramework: ApiKeyExpirationDateCellRendererFramework,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    cellRendererFramework: StatusCellRendererFramework,
    maxWidth: 90,
    sortable: false,
  },
  {
    field: 'permissions',
    headerName: 'Permissions',
    valueFormatter: ({ data: apiKey }: { data: ApiKey }) =>
      `${apiKey.permissions ? apiKey.permissions.length : '-'}`,
    sortable: false,
  },
];
