import { RegularBreakpoints } from '@cmg/design-system';

export type PageGridSectionSize = 'small' | 'medium' | 'large';

export function usePageGridSectionSizes(
  size: PageGridSectionSize
): Pick<RegularBreakpoints, 'xs' | 'sm' | 'md' | 'lg' | 'xl'> {
  switch (size) {
    case 'large': {
      return {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      };
    }
    case 'medium': {
      return {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 8,
        xl: 6,
      };
    }
    case 'small': {
      return {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 3,
      };
    }
  }
}
