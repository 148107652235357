import {
  AccessLevel,
  PermissionDetail,
  PermissionLevel,
  UndefinedPermissionDetail,
} from '../../../../../shared/auth/constants';

/**
 * filters persmissions by access level
 * when accessLevels is null returns all permissions
 * @param accessLevels Access levels to filter by ordered by priority, the access levels will be checked in order an return the first one that matches
 * @param permissions
 * @returns {Permission[]} list of permissions names
 */
export const filterPermissionsByAccessLevels = (
  accessLevels: AccessLevel[] | null,
  permissions: (PermissionDetail | UndefinedPermissionDetail)[]
) => {
  // if access level = null then return all permissions
  if (!accessLevels) {
    return permissions;
  }
  // what permissions could be toggled based on this access level
  return permissions.filter(permission =>
    accessLevels.includes(permission.permissionLevel as PermissionLevel)
  );
};
