import { FC } from 'react';

import { DescriptionList } from '../../../../../../design-system/body-sections/description-list/DescriptionList';
import { DescriptionListItem } from '../../../../../../design-system/body-sections/description-list/DescriptionListItem';
import { OpenIdConnectMetadata } from '../../../../../../types/domain/identity-provider/configurations/openIdConnect';
import { IdentityProviderOpenIdConnect } from '../../../../../../types/domain/identity-provider/identityProvider';

export type Props = Readonly<{
  readonly provider: IdentityProviderOpenIdConnect | null;
  readonly metadata: OpenIdConnectMetadata | null;
}>;

export const ProviderDetailsSectionReadonly: FC<Props> = ({ provider, metadata }) => {
  return (
    <DescriptionList label="Provider Details">
      <DescriptionListItem label="Redirect URI" value={metadata?.redirectUri} />
      <DescriptionListItem label="Logout URL" value={metadata?.logoutUrl} />
      <DescriptionListItem
        label="Post Logout Redirect URI"
        value={metadata?.postLogoutRedirectUri}
      />
      <DescriptionListItem label="Name" value={provider?.name} />
      <DescriptionListItem label="Client ID" value={provider?.configuration.clientId} />
      <DescriptionListItem label="Authority" value={provider?.configuration.authority} />
    </DescriptionList>
  );
};
