import { useFormik } from 'formik';
import React from 'react';

import {
  AccountRemappingSchema,
  createRemapConfig,
  RemapFormValues,
} from '../components/AccountRemappingModal.model';
import { useRemapAccountsRequest } from './useRemapAccountsRequest';
import { useRemapAccountsState } from './useRemapAccountsState';

export const useRemapAccountFlow = ({ currentAccountSubdomain, onSubmit }) => {
  const [remapError, setRemapError] = React.useState<string | null>(null);
  const {
    onRemapAccounts,
    error: remapRequestError,
    isLoading,
    clearError,
  } = useRemapAccountsRequest();
  const formik = useFormik<RemapFormValues>({
    initialValues: {
      accountConfigs: [],
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: AccountRemappingSchema,
    onSubmit,
  });
  const { values, setFieldValue, validateForm, isValid } = formik;
  const { accounts, accountError, fetchAccountByCmgEntityKey, fetchFirmByCmgEntityKey } =
    useRemapAccountsState({ currentAccountSubdomain });

  const onEntityKeyChange = React.useCallback(
    (cmgEntityKey, baseFieldName) => {
      const handleChange = async () => {
        try {
          if (cmgEntityKey.length !== 9) {
            setFieldValue(`${baseFieldName}.newConfig.cmgEntityName`, '');
            setFieldValue(`${baseFieldName}.newConfig.subdomain`, '');
            setFieldValue(`${baseFieldName}.newConfig.loadingEntityName`, false);
            await validateForm();
            setRemapError(null);
            return;
          }
          setFieldValue(`${baseFieldName}.newConfig.loadingEntityName`, true);
          const account = await fetchAccountByCmgEntityKey(cmgEntityKey);
          const firm = await fetchFirmByCmgEntityKey(cmgEntityKey);

          if (firm) {
            setFieldValue(`${baseFieldName}.newConfig.cmgEntityName`, firm.displayName);
          } else {
            setFieldValue(`${baseFieldName}.newConfig.cmgEntityName`, '');
            setFieldValue(`${baseFieldName}.newConfig.loadingEntityName`, false);
            setRemapError('CMG Entity Key is not found');
            await validateForm();
            return;
          }

          if (!account) {
            setFieldValue(`${baseFieldName}.newConfig.loadingEntityName`, false);
            setFieldValue(`${baseFieldName}.newConfig.subdomain`, '');
            await validateForm();
            setRemapError(null);
            return;
          }
          setFieldValue(`${baseFieldName}.newConfig.loadingEntityName`, false);
          setFieldValue(`${baseFieldName}.newConfig.subdomain`, account.subdomain);
          await validateForm();

          const configSubdomainMismatch =
            account.subdomain !== '' &&
            !!values.accountConfigs.find(config => {
              return config.currentConfig.subdomain !== account.subdomain;
            });

          if (configSubdomainMismatch) {
            setRemapError('The new CMG Entity Key is already associated with an existing account.');
          } else {
            setRemapError(null);
          }
        } catch (error) {
          setRemapError('Failure when trying to remap account');
          setFieldValue(`${baseFieldName}.newConfig.loadingEntityName`, false);
        }
      };
      void handleChange();
    },
    [
      setFieldValue,
      validateForm,
      setRemapError,
      fetchAccountByCmgEntityKey,
      fetchFirmByCmgEntityKey,
      values,
    ]
  );

  const onRemapAccountConfigs = React.useCallback(async () => {
    await onRemapAccounts(values.accountConfigs);
    onSubmit();
  }, [onRemapAccounts, values, onSubmit]);

  React.useEffect(() => {
    (accounts ?? []).forEach((account, index) => {
      if (!account) {
        return;
      }

      const exisingRemapConfig = values.accountConfigs[index];
      if (!exisingRemapConfig) {
        setFieldValue(
          `accountConfigs.${index}`,
          createRemapConfig({
            cmgEntityName: account.name,
            subdomain: account.subdomain,
            cmgEntityKey: account.cmgEntityKey,
            id: account.id,
          })
        );
      }
    });
  }, [values, setFieldValue, accounts]);

  const error = remapError || accountError;

  return {
    onEntityKeyChange,
    formik,
    error,
    isValid,
    onRemapAccountConfigs,
    remapRequestError,
    isLoadingRemapRequest: isLoading,
    clearError,
  };
};
