import React from 'react';

import { FirmMatchRow } from '../../../../../../types/domain/firm-match/FirmMatch';
import { FirmMatchIdentifiers } from '../../../../../../types/domain/firm-match/FirmMatchIdentifiers';

/**
 * Renders Identifiers
 */
export const IdentifiersCellRenderer: React.FC<{
  data: FirmMatchRow;
}> = ({ data: firmMatch }) => {
  if (firmMatch.identifiers) {
    const { lei, crd, cik, mpid, factSet }: FirmMatchIdentifiers = firmMatch.identifiers;

    // display in this order, only show one
    if (lei && lei.length > 0) {
      return <React.Fragment>{`LEI: ${lei.join(', ')}`}</React.Fragment>;
    }

    if (crd && crd.length > 0) {
      return <React.Fragment>{`CRD: ${crd.join(', ')}`}</React.Fragment>;
    }

    if (cik && cik.length > 0) {
      return <React.Fragment>{`CIK: ${cik.join(', ')}`}</React.Fragment>;
    }

    if (mpid) {
      return <React.Fragment>{`MPID: ${mpid}`}</React.Fragment>;
    }

    if (factSet) {
      return <React.Fragment>{`FactSet: ${factSet}`}</React.Fragment>;
    }
  }

  return <div />;
};

export default IdentifiersCellRenderer;
