import { Icon } from '@cmg/common';
import React from 'react';
import styled, { DefaultTheme, ThemeConsumer } from 'styled-components/macro';

const SStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

const SIconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-self: center;
`;

export type Props = {
  activeLabel?: string;
  inactiveLabel?: string;
  isActive: boolean;
  inactiveIsNeutral?: boolean;
};

const getColor = (theme: DefaultTheme, isActive: boolean, inactiveIsNeutral?: boolean) => {
  if (isActive) {
    return theme.brand.color.success;
  } else if (inactiveIsNeutral) {
    return theme.brand.color.dark;
  } else {
    return theme.brand.color.danger;
  }
};

const Status: React.FC<Props> = ({
  activeLabel = 'Active',
  inactiveLabel = 'Inactive',
  isActive,
  inactiveIsNeutral,
}) => (
  <SStatus>
    <SIconWrapper>
      <ThemeConsumer>
        {theme => (
          <Icon
            fixedWidth
            name="circle"
            variant="solid"
            size="xs"
            color={getColor(theme, isActive, inactiveIsNeutral)}
          />
        )}
      </ThemeConsumer>
    </SIconWrapper>
    <div>{isActive ? activeLabel : inactiveLabel}</div>
  </SStatus>
);

export default Status;
