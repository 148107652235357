import { systemSubdomain } from '@cmg/auth';
import { timeUtil } from '@cmg/common';
import {
  userListEmailColumnSelector,
  userListEmployeeKeyColumnSelector,
  userListNameLinkSelector,
} from '@cmg/e2e-selectors';
import { ColDef } from 'ag-grid-community';
import React from 'react';
import { Link } from 'react-router-dom';

import Status from '../../../../../common/components/indicators/status/Status';
import routeFactory from '../../../../../common/util/routeFactory';
import { Account } from '../../../../../types/domain/account/account';
import {
  userAccountConfirmationLabels,
  UserStatus,
  userStatusLabels,
} from '../../../../../types/domain/user/constants';
import UserBasic from '../../../../../types/domain/user/UserBasic';
import { getJobFunctionLabel } from '../../../../shared/model/job-function';

export const FirstNameCellRendererFramework: React.FC<{ user: UserBasic; account: Account }> = ({
  user,
  account,
}) => (
  <Link
    to={routeFactory.accountUserDetail.getUrlPath({
      accountSubdomain: account.subdomain,
      userId: user.id,
    })}
    data-test-id={userListNameLinkSelector.testId}
  >
    <span>{user.firstName}</span>
  </Link>
);

export const LastNameCellRendererFramework: React.FC<{
  user: UserBasic;
  account: Account;
}> = ({ user, account }) => (
  <Link
    to={routeFactory.accountUserDetail.getUrlPath({
      accountSubdomain: account.subdomain,
      userId: user.id,
    })}
    data-test-id={userListNameLinkSelector.testId}
  >
    <span>{user.lastName}</span>
  </Link>
);

export const StatusCellRendererFramework: React.FC<{
  user: UserBasic;
}> = ({ user }) => {
  const label = userStatusLabels[user.status] + (user.isLockedOut ? ' (Locked)' : '');

  return (
    <Status
      isActive={user.status === UserStatus.ACTIVE}
      activeLabel={label}
      inactiveLabel={label}
    />
  );
};

export const ConfirmationStatusCellRendererFramework: React.FC<{
  user: UserBasic;
}> = ({ user }) => (
  <Status
    isActive={user.accountConfirmed}
    activeLabel={userAccountConfirmationLabels.CONFIRMED}
    inactiveLabel={userAccountConfirmationLabels.UNCONFIRMED}
  />
);

const columns: ColDef[] = [
  {
    field: 'lastName',
    headerName: 'Last Name',
    sort: 'asc',
    cellRendererFramework: ({ data }: { data: { user: UserBasic; account: Account } }) => (
      <LastNameCellRendererFramework user={data.user} account={data.account} />
    ),
    suppressColumnsToolPanel: true,
    maxWidth: 300,
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    cellRendererFramework: ({ data }: { data: { user: UserBasic; account: Account } }) => (
      <FirstNameCellRendererFramework user={data.user} account={data.account} />
    ),
    suppressColumnsToolPanel: true,
    maxWidth: 300,
  },
  {
    field: 'email',
    headerName: 'Email',
    cellRendererFramework: ({ data }: { data: { user: UserBasic; account: Account } }) => (
      <div data-test-id={userListEmailColumnSelector.testId}>{data.user.email}</div>
    ),
  },
  {
    field: 'employeeKey',
    headerName: 'Employee Key',
    cellRendererFramework: ({ data }: { data: { user: UserBasic; account: Account } }) => (
      <div data-test-id={userListEmployeeKeyColumnSelector.testId}>{data.user.employeeKey}</div>
    ),
  },
  {
    field: 'jobFunction',
    headerName: 'Job Function',
    valueFormatter: ({ data }: { data: { user: UserBasic; account: Account } }) =>
      getJobFunctionLabel(
        data.user.jobFunction,
        data.account.subdomain === systemSubdomain ? 'SYSTEM' : data.account.accountType
      ),
  },
  {
    field: 'status',
    headerName: 'Status',
    cellRendererFramework: ({ data }: { data: { user: UserBasic } }) => (
      <StatusCellRendererFramework user={data.user} />
    ),
    maxWidth: 200,
  },
  {
    field: 'accountConfirmed',
    headerName: 'Account Confirmation',
    sortable: false,
    cellRendererFramework: ({ data }: { data: { user: UserBasic } }) => (
      <ConfirmationStatusCellRendererFramework user={data.user} />
    ),
    maxWidth: 200,
  },
  {
    field: 'createdOn',
    sortable: false,
    headerName: 'Created On',
    cellRendererFramework: ({ data }: { data: { user: UserBasic } }) => (
      <div>{data.user.createdOn ? timeUtil.formatAsDisplayDateTime(data.user.createdOn) : '-'}</div>
    ),
  },
  {
    field: 'lastLoginTime',
    headerName: 'Last Login',
    comparator: (a, b, rowA, rowB, isInverted) => {
      if (!rowA.data.user.lastLoginTime && !rowB.data.user.lastLoginTime) {
        return 0;
      } else if (!rowA.data.user.lastLoginTime) {
        return 1;
      } else if (!rowB.data.user.lastLoginTime) {
        return -1;
      } else {
        return !isInverted
          ? new Date(rowA.data.user.lastLoginTime).valueOf() -
              new Date(rowB.data.user.lastLoginTime).valueOf()
          : new Date(rowB.data.user.lastLoginTime).valueOf() -
              new Date(rowA.data.user.lastLoginTime).valueOf();
      }
    },
    cellRendererFramework: ({ data }: { data: { user: UserBasic } }) => (
      <div>
        {data.user.lastLoginTime ? timeUtil.formatAsDisplayDateTime(data.user.lastLoginTime) : '-'}
      </div>
    ),
  },
];

export default columns;
