import { DomainObject } from '@cmg/design-system';
import React from 'react';

import CRMManagementDomainHighlights from './domain-highlights/CRMManagementDomainHighlights';
import CRMManagementDomainNav from './domain-nav/CRMManagementDomainNav';

/**
 * Displays CRM Management submenu.
 */
export const CRMManagementDomainObject: React.FC = () => {
  return (
    <DomainObject
      domainHighlights={<CRMManagementDomainHighlights />}
      domainNav={<CRMManagementDomainNav />}
    />
  );
};
