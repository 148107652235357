import React from 'react';
import { Route } from 'react-router-dom';

import routeFactory from '../../common/util/routeFactory';
import RolodexCrmIntegrationDetailRoute from './crm-integration-detail/RolodexCrmIntegrationDetailRoute';
import RolodexCrmIntegrationListRoute from './crm-integration-list/RolodexCrmIntegrationListRoute';
import CRMManagementRoute from './crm-management/CRMManagementRoute';
import RolodexCrmMatchListRoute from './crm-match-list/RolodexCrmMatchListRoute';
import RolodexFirmDetailRoute from './firm-detail/RolodexFirmDetailRoute';
import RolodexFirmListRoute from './firm-list/RolodexFirmListRoute';
import RolodexFirmNewRoute from './firm-new/RolodexFirmNewRoute';
import RolodexSubNavigation from './RolodexSubNavigation';

export const RolodexRoute: React.FC = () => {
  return (
    <React.Fragment>
      <Route path={routeFactory.rolodex.routePath} component={RolodexSubNavigation} />
      <Route exact path={routeFactory.rolodexFirms.routePath} component={RolodexFirmListRoute} />
      <Route
        exact
        path={routeFactory.rolodexFirmDetail.routePath}
        component={RolodexFirmDetailRoute}
      />
      <Route path={routeFactory.rolodexFirmNew.routePath} component={RolodexFirmNewRoute} />
      <Route
        exact
        path={routeFactory.rolodexCrmIntegrations.routePath}
        component={RolodexCrmIntegrationListRoute}
      />
      <Route
        exact
        path={routeFactory.rolodexCrmIntegrationDetail.routePath}
        component={RolodexCrmIntegrationDetailRoute}
      />
      <Route
        path={routeFactory.rolodexCrmEntityMatcher.routePath}
        component={RolodexCrmMatchListRoute}
      />
      <Route path={routeFactory.sysAdminCrmManagement.routePath} component={CRMManagementRoute} />
    </React.Fragment>
  );
};

export default RolodexRoute;
