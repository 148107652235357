import { getAppSettings } from '../../../../common/config/appSettings';

export const displayIdentityVerificationUrl = (verificationUrl: string) => {
  const appSettings = getAppSettings();
  const returnUrl = `&returnUrl=${encodeURIComponent(appSettings.client.basename)}`;
  prompt('Test Url', `${verificationUrl}${returnUrl}`);
};

export const redirectToIdentityVerification = (verificationUrl: string) => {
  const returnUrl = `&returnUrl=${encodeURIComponent(window.location.pathname)}`;

  window.location.href = `${verificationUrl}${returnUrl}`;
};
