import { useMemo } from 'react';

import {
  IdentityProviderType,
  identityProviderTypeDisplay,
} from '../../../../../types/domain/identity-provider/constants';
import { AccountIdentityProvider } from '../../../../../types/domain/identity-provider/identityProvider';

export function useUnconfiguredProviderOptions(
  configuredProviders: readonly AccountIdentityProvider[] | null
) {
  return useMemo(() => {
    const unconfiguredProviderTypes = Object.values(IdentityProviderType).filter(providerType =>
      (configuredProviders || []).every(
        configuredProvider => configuredProvider.providerType !== providerType
      )
    );

    return unconfiguredProviderTypes.map(type => ({
      type,
      label: identityProviderTypeDisplay[type],
    }));
  }, [configuredProviders]);
}
