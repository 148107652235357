import { errorUtil } from '@cmg/common';
import React from 'react';

import { Account } from '../../../../types/domain/account/account';
import { useFetchAccount } from './useFetchFirm';

export const useRemapAccountsState = ({
  currentAccountSubdomain,
}: {
  currentAccountSubdomain: string;
}) => {
  const [accounts, setAccounts] = React.useState<Account[]>([]);
  const [accountError, setAccountError] = React.useState<string | null>(null);
  const {
    fetchAccountByCmgEntityKey: fetchAccountByCmgEntityKeyCall,
    fetchAccountBySubdomain: fetchAccountBySubdomainCall,
    fetchFirmByCmgEntityKey,
    loading,
  } = useFetchAccount();

  const fetchAccountByCmgEntityKey = React.useCallback(
    async (cmgEntityKey: string) => {
      try {
        return await fetchAccountByCmgEntityKeyCall(cmgEntityKey);
      } catch (error) {
        errorUtil.assertIsError(error);
        const { message } = error;
        setAccountError(message);
      }
    },
    [setAccountError, fetchAccountByCmgEntityKeyCall]
  );

  React.useEffect(() => {
    const fetchAccountBySubdomain = async (subdomain: string) => {
      try {
        const account = await fetchAccountBySubdomainCall(subdomain);
        setAccounts(accs => {
          const accounts = [...(accs ?? []), account];
          return [...new Map(accounts.map(account => [account?.subdomain, account])).values()];
        });
        return account;
      } catch (error) {
        errorUtil.assertIsError(error);
        const { message } = error;
        setAccountError(message);
      }
    };
    void fetchAccountBySubdomain(currentAccountSubdomain);
  }, [currentAccountSubdomain, fetchAccountBySubdomainCall, setAccounts, setAccountError]);

  return {
    accounts,
    accountError,
    loadingAccount: loading,
    fetchAccountByCmgEntityKey,
    fetchFirmByCmgEntityKey,
  };
};
