import routeFactory from '../../../../common/util/routeFactory';

export const accountDetailRoutes = {
  subscription: routeFactory.accountSubscription.routePath,
  users: [routeFactory.accountUsers.routePath, routeFactory.accountUserDetail.routePath],
  roles: routeFactory.accountRoles.routePath,
  security: routeFactory.accountSecurity.routePath,
  apiKeys: routeFactory.accountApiKeys.routePath,
  rolodex: routeFactory.accountRolodex.routePath,
};

export type AccountDetailRouteName = keyof typeof accountDetailRoutes;
