import { apiTypes } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UserList from '../../shared/components/user-list/UserList';
import { selectAccount, selectAccountType } from '../../shared/ducks';
import {
  fetchAccountRoleUserList,
  selectRoleUserList,
  selectRoleUserListError,
  selectRoleUserListLoading,
} from '../ducks';

const mapStateToProps = state => ({
  account: selectAccount(state),
  accountType: selectAccountType(state),
  isUserListLoading: selectRoleUserListLoading(state),
  users: selectRoleUserList(state) || undefined,
  error: selectRoleUserListError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchAccountRoleUserList,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  roleId: string;
};
export type Props = OwnProps & StateProps & DispatchProps;

export const UsersForRoleList: React.FC<Props> = ({
  isUserListLoading,
  account,
  accountType,
  users,
  error,
  actions,
  roleId,
}) => {
  React.useEffect(() => {
    if (account) {
      actions.fetchAccountRoleUserList({
        roleId,
        page: 1,
        perPage: 25,
      });
    }
  }, [actions, account, roleId]);

  const handleChangePage = (params: apiTypes.ListParams) => {
    actions.fetchAccountRoleUserList({
      roleId,
      ...params,
    });
  };

  if (!account) {
    return null;
  }

  return (
    <UserList
      loading={isUserListLoading}
      account={account}
      accountType={accountType}
      users={users!.users}
      pagination={users!.pagination}
      error={!!error}
      onChangePage={handleChangePage}
    />
  );
};

// TODO remove defaultProps - preferred approach is JS default params (https://github.com/reactjs/rfcs/pull/107)
UsersForRoleList.defaultProps = {
  users: {
    users: [],
    pagination: {
      perPage: 1,
      activePage: 1,
      totalCount: 0,
      totalPages: 0,
      hasNext: false,
      hasPrevious: false,
    },
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersForRoleList);
