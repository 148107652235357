import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { apiTypes } from '@cmg/common';
import { Button, Typography } from '@cmg/design-system';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useDocumentTitle } from '../../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../../../common/util/routeFactory';
import { ServerErrorAlert } from '../../../../../design-system/alert/ServerErrorAlert';
import { PageGridSection } from '../../../../../design-system/body-sections/PageGridSection';
import { IdentityPageContent } from '../../../../../design-system/IdentityPageContent';
import { ApiKeyListGrid } from '../../../../account-detail/api-keys/api-keys-list/components/ApiKeyListGrid';
import {
  fetchApiKeyList,
  selectApiKeys,
  selectError,
  selectLoading,
  selectPagination,
} from '../../../../account-detail/api-keys/api-keys-list/ducks';
import { selectAccount } from '../../../../account-detail/shared/ducks';

const mapStateToProps = state => ({
  keys: selectApiKeys(state),
  pagination: selectPagination(state),
  loading: selectLoading(state),
  error: selectError(state),
  account: selectAccount(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchApiKeyList,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = StateProps & DispatchProps;

export const ApiKeyListRouteComponent: React.FC<Props> = ({
  keys,
  pagination,
  loading,
  error,
  actions,
  account,
}) => {
  const canAddApiKey = useCheckPermissions(
    [
      permissionsByEntity.ApiKey.FULL,
      // @todo remove the ability to create api keys from system admins once this feature is turned on for the account admins
      permissionsByEntity.GlobalApiKey.FULL,
    ],
    false
  );

  useDocumentTitle(routeFactory.accountApiKeys.getDocumentTitle({ accountName: account?.name }));

  React.useEffect(() => {
    actions.fetchApiKeyList({ page: 1, perPage: 25 });
  }, [actions]);

  const handleChangePage = (params: apiTypes.ListParams) => {
    actions.fetchApiKeyList(params);
  };

  if (!account) {
    return null;
  }

  return (
    <IdentityPageContent
      gridContent={
        <PageGridSection
          size="large"
          title="API Keys"
          actions={
            canAddApiKey ? (
              <Button
                href={routeFactory.accountApiKeysNew.getUrlPath({
                  accountSubdomain: account.subdomain,
                })}
                variant="contained"
                color="primary"
              >
                Create New API Key
              </Button>
            ) : undefined
          }
          error={error && <ServerErrorAlert title="Error" error="Could not load API Keys..." />}
          content={
            <Fragment>
              <Typography>
                Use your CMG API Keys to access the same API's that power the CMG tools. These keys
                give developers access to make changes to the account through the API, so keep it
                safe and don't share it publicly.
              </Typography>
              <ApiKeyListGrid
                accountSubdomain={account.subdomain}
                keys={keys}
                loading={loading}
                pagination={pagination}
                onChangePage={handleChangePage}
              />
            </Fragment>
          }
        />
      }
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeyListRouteComponent);
