import { AccountType } from '../../../../../../src/types/domain/account/constants';
import {
  buySideJobOptions,
  JobFunction,
  sellSideJobOptions,
  systemJobOptions,
} from '../../../../../../src/types/domain/user/constants';

export const getJobFunctionOptions = (accountType: string | null) => {
  if (!accountType) {
    return [];
  }
  if (accountType === 'SYSTEM') {
    return systemJobOptions;
  }
  if (accountType === AccountType.BUY_SIDE) {
    return buySideJobOptions;
  }
  if (accountType === AccountType.SELL_SIDE) {
    return sellSideJobOptions;
  }
};

export const getFlatJobFunctionOptions = (accountType: string | null) => {
  const options = getJobFunctionOptions(accountType) ?? [];
  return options.reduce((currResult, nextVal) => {
    return [
      ...currResult,
      ...nextVal.options.map(option => ({
        label: option.label,
        value: option.value,
        group: nextVal.label,
      })),
    ];
  }, new Array<{ label: string; value: JobFunction; group: string }>());
};
