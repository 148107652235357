import React from 'react';

import {
  firmIdentifierTypeLabels,
  firmIdentifierTypeOptions,
} from '../../../../types/domain/firm/constants';
import {
  recordStatusLabels,
  recordStatusOptions,
} from '../../../../types/domain/record-status/constants';
import { StyledSelectField, StyledTextInputField } from './inputs/RecordInputs.styles';

/**
 * The column configuration for the Firm Identifiers Record Table
 */
export const getFirmIdentifierColumns = () => [
  {
    field: 'type',
    headerName: 'Type',
    editCellRendererFramework: (row, disabled) => (
      <StyledSelectField name="type" options={firmIdentifierTypeOptions} disabled={disabled} />
    ),
    cellRendererFramework: row => firmIdentifierTypeLabels[row.type],
  },
  {
    field: 'value',
    headerName: 'Identifier',
    editCellRendererFramework: (row, disabled) => (
      <StyledTextInputField name="value" disabled={disabled} />
    ),
  },
  {
    field: 'recordStatus',
    headerName: 'Status',
    editCellRendererFramework: (row, disabled) => (
      <StyledSelectField name="recordStatus" options={recordStatusOptions} disabled={disabled} />
    ),
    cellRendererFramework: row => recordStatusLabels[row.recordStatus],
  },
];
