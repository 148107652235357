import { Option } from '@cmg/common';

import { AccountType } from '../account/constants';

export enum UserNotificationFormat {
  EMAIL_AND_INTERNAL = 'EMAIL_AND_INTERNAL',
  EMAIL = 'EMAIL',
  INTERNAL = 'INTERNAL',
}

export const userAccountConfirmationLabels = {
  CONFIRMED: 'Confirmed',
  UNCONFIRMED: 'Not Confirmed',
};

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export const userStatusLabels = {
  [UserStatus.ACTIVE]: 'Active',
  [UserStatus.DISABLED]: 'Disabled',
};

export const userStatusOptions = [
  {
    label: userStatusLabels[UserStatus.ACTIVE],
    value: UserStatus.ACTIVE,
  } as Option<UserStatus>,
  {
    label: userStatusLabels[UserStatus.DISABLED],
    value: UserStatus.DISABLED,
  } as Option<UserStatus>,
];

export const accountConfirmedOptions = [
  {
    label: 'Confirmed',
    value: true,
  },
  {
    label: 'Not Confirmed',
    value: false,
  },
];

export enum ConfigurationStatus {
  NOT_REQUIRED = 'Not Required',
  NOT_CONFIGURED = 'Not Configured',
  CONFIGURED = 'Configured',
}

export enum JobFunction {
  ANALYST = 'ANALYST',
  PORTFOLIO_MANAGER = 'PORTFOLIO_MANAGER',
  TRADER = 'TRADER',
  SYNDICATE = 'SYNDICATE',
  ADMIN = 'ADMIN',
  LEGAL_COMPLIANCE = 'LEGAL_COMPLIANCE',
  TECHNOLOGY = 'TECHNOLOGY',
  ASSISTANT = 'ASSISTANT',
  SUPPORT = 'SUPPORT',
  ECM = 'ECM',
  SALES = 'SALES',
  SALES_SUPERVISOR = 'SALES_SUPERVISOR',
  SALES_TRADER = 'SALES_TRADER',
  SALES_TRADER_SUPERVISOR = 'SALES_TRADER_SUPERVISOR',
  ADMINISTRATOR = 'ADMINISTRATOR',
  ECM_SURVEILLANCE = 'ECM_SURVEILLANCE',
  OTHER = 'OTHER',
  SYNDICATE_OPERATIONS = 'SYNDICATE_OPERATIONS',
  SYNDICATE_ACCOUNTING = 'SYNDICATE_ACCOUNTING',
  PRODUCT_CONTROL = 'PRODUCT_CONTROL',
}

export const buySideJobFunctionLabels = {
  [JobFunction.ANALYST]: 'Analyst',
  [JobFunction.PORTFOLIO_MANAGER]: 'Portfolio Manager',
  [JobFunction.TRADER]: 'Trader',
  [JobFunction.SYNDICATE]: 'Syndicate',
  [JobFunction.ADMINISTRATOR]: 'Buy Side Admin',
  [JobFunction.LEGAL_COMPLIANCE]: 'Legal/Compliance',
  [JobFunction.TECHNOLOGY]: 'Technology',
  [JobFunction.ASSISTANT]: 'Assistant',
  [JobFunction.SUPPORT]: 'Support',
  [JobFunction.OTHER]: 'Other',
};

export const sellSideJobFunctionLabels = {
  [JobFunction.SYNDICATE]: 'Syndicate',
  [JobFunction.ECM]: 'ECM',
  [JobFunction.SALES]: 'Sales',
  [JobFunction.SALES_SUPERVISOR]: 'Sales Supervisor',
  [JobFunction.SALES_TRADER]: 'Sales Trader',
  [JobFunction.SALES_TRADER_SUPERVISOR]: 'Sales Trader Supervisor',
  [JobFunction.LEGAL_COMPLIANCE]: 'Legal/Compliance',
  [JobFunction.ADMINISTRATOR]: 'Administrator',
  [JobFunction.ASSISTANT]: 'Assistant',
  [JobFunction.SUPPORT]: 'Support',
  [JobFunction.TECHNOLOGY]: 'Technology',
  [JobFunction.ECM_SURVEILLANCE]: 'ECM Surveillance',
  [JobFunction.SYNDICATE_OPERATIONS]: 'Syndicate Operations',
  [JobFunction.SYNDICATE_ACCOUNTING]: 'Syndicate Accounting',
  [JobFunction.PRODUCT_CONTROL]: 'Product Control',
  [JobFunction.OTHER]: 'Other',
};

export const systemJobFunctionLabels = {
  [JobFunction.ADMINISTRATOR]: 'Administrator',
  [JobFunction.OTHER]: 'Other',
};

export const buySideJobOptions = [
  {
    label: 'Buy Side Job Functions',
    options: Object.keys(buySideJobFunctionLabels)
      .map(
        jobFunction =>
          ({
            label: buySideJobFunctionLabels[jobFunction],
            value: jobFunction,
          } as Option<JobFunction>)
      )
      .sort((a: Option<JobFunction>, b: Option<JobFunction>) => (a.label >= b.label ? 1 : -1)),
  },
];

export const sellSideJobOptions = [
  {
    label: 'Sell Side Job Functions',
    options: Object.keys(sellSideJobFunctionLabels)
      .map(
        jobFunction =>
          ({
            label: sellSideJobFunctionLabels[jobFunction],
            value: jobFunction,
          } as Option<JobFunction>)
      )
      .sort((a: Option<JobFunction>, b: Option<JobFunction>) => (a.label >= b.label ? 1 : -1)),
  },
];

export const systemJobOptions = [
  {
    label: 'System Job Functions',
    options: Object.keys(systemJobFunctionLabels)
      .map(
        jobFunction =>
          ({
            label: systemJobFunctionLabels[jobFunction],
            value: jobFunction,
          } as Option<JobFunction>)
      )
      .sort((a: Option<JobFunction>, b: Option<JobFunction>) => (a.label >= b.label ? 1 : -1)),
  },
];

export const jobFunctionLabels = {
  [AccountType.BUY_SIDE]: buySideJobFunctionLabels,
  [AccountType.SELL_SIDE]: sellSideJobFunctionLabels,
  SYSTEM: systemJobFunctionLabels,
};

export const getFullName = (user: { firstName?: string; lastName?: string }) =>
  [user && user.firstName, user && user.lastName].filter(namePart => !!namePart).join(' ');
