import { duckPartFactory, reduxUtil } from '@cmg/common';
import { AnyAction, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import {
  CreateFirmResponse,
  GetFactSetFirmHierarchyResponse,
  ImportFactSetFirmResponse,
} from '../../../common/api/rolodexApiClient';
import * as rolodexApiClient from '../../../common/api/rolodexApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { FirmNameType } from '../../../types/domain/firm/constants';
import { FactSetFirmHierarchy } from '../../../types/domain/firm/FactSetFirmHierarchy';
import { Firm } from '../../../types/domain/firm/Firm';

export const fetchFactSetFirmHierarchyDuckParts = duckPartFactory.makeAPIDuckParts<
  { id: string },
  {
    hierarchy: FactSetFirmHierarchy;
  }
>({
  prefix: 'ROLODEX/FACTSET_FIRM_HIERARCHY',
});

export const importFactSetFirmDuckParts = duckPartFactory.makeAPIDuckParts<
  { factsetId: string },
  {
    firm: Firm;
  }
>({
  prefix: 'ROLODEX/IMPORT_FACTSET_FIRM',
});

export const createFirmDuckParts = duckPartFactory.makeAPIDuckParts<
  { type: FirmNameType; value: string },
  {
    firm: Firm;
  }
>({
  prefix: 'ROLODEX/CREATE_FIRM',
});

/**
 * ACTION TYPES
 */
enum ActionTypes {
  RESET_STATE = 'ROLODEX/RESET_STATE',
  PREVIEW_FACTSET_FIRM_IMPORT = 'ROLODEX/PREVIEW_FACTSET_FIRM_IMPORT',
}

/**
 * ACTION CREATORS
 */
export const resetState = () => ({
  type: ActionTypes.RESET_STATE,
});

export const previewFactSetFirmImport = (payload: { id: string | null }) => ({
  type: ActionTypes.PREVIEW_FACTSET_FIRM_IMPORT,
  payload,
});
type previewFactSetFirmImportAction = ReturnType<typeof previewFactSetFirmImport>;

export const fetchFactSetFirmHierarchy = fetchFactSetFirmHierarchyDuckParts.actionCreators.request;
type fetchFactSetFirmHierarchyAction = ReturnType<typeof fetchFactSetFirmHierarchy>;

export const importFactSetFirm = importFactSetFirmDuckParts.actionCreators.request;
type importFactSetFirmAction = ReturnType<typeof importFactSetFirm>;

export const createFirm = createFirmDuckParts.actionCreators.request;
type createFirmAction = ReturnType<typeof createFirm>;

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.RESET_STATE]: ReturnType<typeof resetState>;
  [ActionTypes.PREVIEW_FACTSET_FIRM_IMPORT]: ReturnType<typeof previewFactSetFirmImport>;
};

/**
 * REDUCERS
 */
export const initialState = {
  selectedFirmId: null,
  hierarchy: fetchFactSetFirmHierarchyDuckParts.initialState,
  importedFirm: importFactSetFirmDuckParts.initialState,
  createdFirm: createFirmDuckParts.initialState,
};

const selectedFirmIdReducer = reduxUtil.createReducer<ReducerState['selectedFirmId'], Actions>(
  initialState.selectedFirmId,
  {
    [ActionTypes.PREVIEW_FACTSET_FIRM_IMPORT]: (state, { payload }) => payload.id,
  }
);

export type ReducerState = {
  selectedFirmId: string | null;
  hierarchy: typeof initialState.hierarchy;
  importedFirm: typeof initialState.importedFirm;
  createdFirm: typeof initialState.createdFirm;
};

// This reducer acts on the entire state of this duck. Has access to duck state and must return duck state.
const crossSliceReducer = reduxUtil.createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_STATE]: () => ({ ...initialState }),
});

const combinedReducers = combineReducers<ReducerState>({
  selectedFirmId: selectedFirmIdReducer,
  hierarchy: fetchFactSetFirmHierarchyDuckParts.reducer,
  importedFirm: importFactSetFirmDuckParts.reducer,
  createdFirm: createFirmDuckParts.reducer,
});

// Combines our individual slice reducers and the cross slice reducer.
export default function duckReducer(state: ReducerState = initialState, action: AnyAction) {
  const intermediateState = combinedReducers(state, action);
  return crossSliceReducer(intermediateState, action);
}

/**
 * SELECTORS
 */

const selectState = (state: RootState) => state.rolodexFirmNew;

const firmHierarchySelectors = fetchFactSetFirmHierarchyDuckParts.makeSelectors(
  state => selectState(state).hierarchy
);
export const selectFactSetFirmHierarchyLoading = firmHierarchySelectors.selectLoading;
export const selectFactSetFirmHierarchyError = firmHierarchySelectors.selectError;
export const selectFactSetFirmHierarchy = createSelector(firmHierarchySelectors.selectData, data =>
  data ? data.hierarchy : null
);

export const selectSelectedFirmId = (state: RootState) => selectState(state).selectedFirmId;
export const selectSelectedFirm = createSelector(
  (state: RootState) => selectSelectedFirmId(state),
  (state: RootState) => selectFactSetFirmHierarchy(state),
  (selectedFirmId, hierarchy) =>
    hierarchy ? hierarchy.find(({ factSetFirmId }) => factSetFirmId === selectedFirmId) : null
);
export const selectCanImportSelectedFirm = createSelector(
  (state: RootState) => selectSelectedFirm(state),
  selectedFirm => (selectedFirm ? !selectedFirm.linkedToEntityMasterFirm : false)
);

const importedFirmSelectors = importFactSetFirmDuckParts.makeSelectors(
  state => selectState(state).importedFirm
);
export const selectImportedFactSetFirmLoading = importedFirmSelectors.selectLoading;
export const selectImportedFactSetFirmError = importedFirmSelectors.selectError;
export const selectImportedFactSetFirm = createSelector(importedFirmSelectors.selectData, data =>
  data ? data.firm : null
);

const createdFirmSelectors = createFirmDuckParts.makeSelectors(
  state => selectState(state).createdFirm
);
export const selectCreatedFirmLoading = createdFirmSelectors.selectLoading;
export const selectCreatedFirmError = createdFirmSelectors.selectError;
export const selectCreatedFirm = createSelector(createdFirmSelectors.selectData, data =>
  data ? data.firm : null
);

/**
 * SAGAS
 */

export function* fetchFactSetFirmHierarchySaga({
  payload,
}: fetchFactSetFirmHierarchyAction): SagaIterator {
  const response: GetFactSetFirmHierarchyResponse = yield call(
    rolodexApiClient.getFactSetFirmHierarchy,
    { firmId: payload.id }
  );

  if (response.ok) {
    yield put(
      fetchFactSetFirmHierarchyDuckParts.actionCreators.success({ hierarchy: response.data })
    );
  } else {
    yield put(fetchFactSetFirmHierarchyDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* previewFactSetFirmImportSaga({ payload }: previewFactSetFirmImportAction) {
  if (payload.id) {
    yield put(fetchFactSetFirmHierarchy({ id: payload.id }));
  }
}

export function* importFactSetFirmSaga({ payload }: importFactSetFirmAction): SagaIterator {
  const response: ImportFactSetFirmResponse = yield call(
    rolodexApiClient.importFactSetFirm,
    payload
  );

  if (response.ok) {
    yield put(importFactSetFirmDuckParts.actionCreators.success({ firm: response.data }));
  } else {
    yield put(importFactSetFirmDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* createFirmSaga({ payload }: createFirmAction): SagaIterator {
  const response: CreateFirmResponse = yield call(rolodexApiClient.createFirm, payload);

  if (response.ok) {
    yield put(createFirmDuckParts.actionCreators.success({ firm: response.data }));
  } else {
    yield put(createFirmDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* rolodexFirmNewSaga() {
  yield takeLatest<fetchFactSetFirmHierarchyAction>(
    fetchFactSetFirmHierarchyDuckParts.actionTypes.REQUEST,
    fetchFactSetFirmHierarchySaga
  );
  yield takeLatest<previewFactSetFirmImportAction>(
    ActionTypes.PREVIEW_FACTSET_FIRM_IMPORT,
    previewFactSetFirmImportSaga
  );
  yield takeLatest<importFactSetFirmAction>(
    importFactSetFirmDuckParts.actionTypes.REQUEST,
    importFactSetFirmSaga
  );
  yield takeLatest<createFirmAction>(createFirmDuckParts.actionTypes.REQUEST, createFirmSaga);
}
